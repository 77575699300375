import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ModificationQuestion = ({ setDirty, setSaveError }) => {
  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
  }, []);

  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Changes or Additions?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            variant="h5"
            component="span"
            style={{ whiteSpace: "pre-line" }}
          >
            Do you need to modify the order to be different than the base plan?{" "}
            {"\n"}
            Has the customer requested changes or additions?
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModificationQuestion;
