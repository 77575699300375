import React, { useEffect } from "react";
import RolesTableComponent from "../../../components/RolesTableComponent/RolesTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const RolesTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const companyID = useSelector((state) =>
    state.user.user.user ? state.user.user.user.companyID : null
  );

  if (clientRoles === "N") {
    history.push("/dashboard");
  }

  useEffect(() => {
    //for help button
    setPageLink("Roles");

    //for drawer menu
    setPageID(8);
  }, []);

  return (
    <div>
      <RolesTableComponent companyID={companyID} />
    </div>
  );
};

export default RolesTable;
