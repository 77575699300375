import React from "react";
import "./App.css";
import LoginPage from "./pages/LoginPage/LoginPage";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import OtpPage from "./pages/OtpPage/OtpPage";
import UndefinedPage from "./pages/UndefinedPage/UndefinedPage";
import MainPage from "./pages/MainPage/MainPage";
import EndUserAgreementPage from "./pages/EndUserAgreementPage/EndUserAgreementPage";
import { useSelector } from "react-redux";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import AgreementURLpage from "./pages/AgreementURLpage/AgreementURLpage";
import OrderRedirectionPage from "./pages/OrderRedirectionPage/OrderRedirectionPage";
import { CookiesProvider } from "react-cookie";

function App() {
  /* Get values from redux store*/
  const jwtToken = useSelector((state) =>
    state.user.user ? state.user.user.jwtToken : ""
  );

  const requestOTP = useSelector((state) =>
    state.user.user ? state.user.user.requestOTP : false
  );

  const agreementApproved = useSelector((state) =>
    state.user.user ? state.user.user.user.agreementApproved : false
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );
  /*Render function*/
  return (
    <CookiesProvider>
      <Router>
        <Switch>
          <Route
            exact
            path="/order-redirection"
            render={() => <OrderRedirectionPage />}
          />
          <Route
            exact
            path="/agreement-paper"
            render={() => <AgreementURLpage />}
          />
          <Route
            exact
            path="/login"
            render={() => {
              if (jwtToken !== "" && requestOTP) {
                return <Redirect to="/otp" />;
              } else if (jwtToken !== "" && !requestOTP && agreementApproved) {
                return <Redirect to="/Dashboard" />;
              } else if (jwtToken !== "" && !requestOTP && !agreementApproved) {
                return <Redirect to="/end-user-agreement" />;
              } else if (jwtToken === "") {
                return <LoginPage />;
              }
            }}
          />
          <Route
            exact
            path="/forgot-password"
            render={() => {
              if (jwtToken !== "") {
                return <Redirect to="/login" />;
              } else return <ForgotPasswordPage />;
            }}
          />
          <Route
            exact
            path="/reset-password"
            render={() => {
              if (jwtToken !== "") {
                return <Redirect to="/login" />;
              } else return <ResetPasswordPage source="resetPassword" />;
            }}
          />
          <Route
            exact
            path="/activation"
            render={() => {
              if (jwtToken !== "") {
                return <Redirect to="/login" />;
              } else return <ResetPasswordPage source="activation" />;
            }}
          />
          <Route
            exact
            path="/verify-email"
            render={() => {
              if (jwtToken !== "") {
                return <Redirect to="/login" />;
              } else return <VerificationPage />;
            }}
          />
          <Route
            exact
            path="/otp"
            render={() => {
              if (jwtToken === "") {
                return <Redirect to="/login" />;
              } else if (!requestOTP && !agreementApproved) {
                return <Redirect to="/end-user-agreement" />;
              } else if (!requestOTP && agreementApproved) {
                return <Redirect to="/" />;
              } else return <OtpPage />;
            }}
          />
          <Route
            exact
            path="/end-user-agreement"
            render={() => {
              if (jwtToken === "") {
                return <Redirect to="/login" />;
              } else if (jwtToken !== "" && requestOTP) {
                return <Redirect to="/otp" />;
              } else if (agreementApproved || companyType === "L") {
                return <Redirect to="/" />;
              } else {
                return <EndUserAgreementPage />;
              }
            }}
          />
          <Route
            path="/"
            render={() => {
              if (jwtToken === "") {
                return <Redirect to="/login" />;
              } else if (requestOTP) {
                return <Redirect to="/otp" />;
              } else {
                return <MainPage />;
              }
            }}
          />
          <Route path="/Dashboard" />
          <Route component={UndefinedPage} />
        </Switch>
      </Router>
    </CookiesProvider>
  );
}

export default App;
