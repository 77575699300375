import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the system data of the products */

const baseUrl = apiConfig + "/systemdata";

const getAllAmps = () => {
  return fetchWrapper.get(baseUrl + "/GetAllAmps");
};

const getAllBusinessUnits = () => {
  return fetchWrapper.get(baseUrl + "/GetAllBusinessUnits");
};

const getAllControllerType = () => {
  return fetchWrapper.get(baseUrl + "/GetAllControllerType");
};

const getAllGangNumber = () => {
  return fetchWrapper.get(baseUrl + "/GetAllGangNumber");
};

const getAllGatewayType = () => {
  return fetchWrapper.get(baseUrl + "/GetAllGatewayType");
};

const getAllPackaging = () => {
  return fetchWrapper.get(baseUrl + "/GetAllPackaging");
};

const getAllPowerSupply = () => {
  return fetchWrapper.get(baseUrl + "/GetAllPowerSupply");
};

const getProductCategoryByID = (data) => {
  return fetchWrapper.get(
    baseUrl + "/GetProductCategoryByBusinessUnit/" + data
  );
};

const getAllSwitchAssemblyType = () => {
  return fetchWrapper.get(baseUrl + "/GetAllSwitchAssemblyType");
};

const getAllSwitchColor = () => {
  return fetchWrapper.get(baseUrl + "/GetAllSwitchColor");
};

const getAllSwitchStyle = () => {
  return fetchWrapper.get(baseUrl + "/GetAllSwitchStyle");
};

const getAllVolts = () => {
  return fetchWrapper.get(baseUrl + "/GetAllVolts");
};

const getAllProductCategory = () => {
  return fetchWrapper.get(baseUrl + "/GetAllProductCategory");
};

const updateEndUserAgreement = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateEndUserAgreement", data);
};

const getOnHoldBanner = () => {
  return fetchWrapper.get(baseUrl + "/GetCompanyOnHoldBannerText");
};

const getSystemDataVariables = () => {
  return fetchWrapper.get(baseUrl + "/GetSystemVariablesData");
};

const updateAllSystemVariables = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateAllSystemVariables", data);
};

const updateMiddlewareValuesInDatabase = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateMiddlewareFields", data);
};

const getRecaptchaKey = () => {
  return fetchWrapper.getAnon(baseUrl + "/GetRecaptchaKey");
};

export const systemDataServices = {
  getAllAmps,
  getAllBusinessUnits,
  getAllControllerType,
  getAllGangNumber,
  getAllGatewayType,
  getAllPackaging,
  getAllPowerSupply,
  getProductCategoryByID,
  getAllSwitchAssemblyType,
  getAllSwitchColor,
  getAllSwitchStyle,
  getAllVolts,
  getAllProductCategory,
  updateEndUserAgreement,
  getOnHoldBanner,
  getSystemDataVariables,
  updateMiddlewareValuesInDatabase,
  updateAllSystemVariables,
  getRecaptchaKey,
};
