import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import logo from "../../assets/Levven_logo.png";
import { useSelector, useDispatch } from "react-redux";
import { accountServices } from "../../services/account.services";
import { userTypes } from "../../redux/types/userTypes";

const OtpPage = () => {
  /*Local varibales*/
  const [otp, setOtp] = useState("");
  const classes = useStyles();
  const [submited, setSubmited] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [message, setMessage] = useState("");
  const [resend, setResend] = useState(false);

  /* Get values from redux store*/
  const userEmail = useSelector((state) =>
    state.user.user ? state.user.user.user.userName : ""
  );

  /*Declare dispatch to dispatch redux actions*/
  const dispatch = useDispatch();

  /*Functions*/
  /*Send the otp code to the api and change the requestOTP redux state to false*/
  const dispatchCode = (data) => {
    setSubmited(true);
    return (dispatch) => {
      accountServices
        .sendOtpCode(data)
        .then((response) => {
          if (response) {
            if (!response.data) {
              setSubmited(false);
              setOtp("");
              setMessage("You have entered a wrong code");
            } else {
              dispatch({
                type: userTypes.UPDATE_OTP,
                payload: false,
              });
            }
          }
        })
        .catch((error) => {
          setOtpError(error.response.data.error.error);
          setSubmited(false);
          setOtp("");
        });
    };
  };

  /*Request the otp code from api*/
  const requestOtp = (data) => {
    accountServices
      .requestOtpCode(data)
      .then(() => setOtpError(""))
      .catch((error) => {
        setOtpError(error.response.data.message);
      });
  };

  const logout = () => {
    //function that clears redux values and logs out
    return (dispatch) => {
      accountServices.logout().then(() => {
        dispatch({
          type: userTypes.LOGOUT_USER,
        });
      });
    };
  };

  useEffect(() => {
    requestOtp({ email: userEmail });
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      if (!submited) {
        dispatch(dispatchCode({ email: userEmail, code: otp }));
      }
    }
  }, [otp]);

  //to change title of page
  useEffect(() => {
    document.title = "Levven Electronics - Customer Portal - OTP";
  }, []);

  /*Render function*/
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="100vh"
    >
      <CustomPaper>
        <img
          src={logo}
          alt="Levven Logo"
          width="141"
          style={{ marginBottom: "3rem", objectFit: "contain" }}
        />

        <Grid container alignSelf="center" spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ height: "4rem" }}
          >
            <Typography
              component="span"
              variant="h5"
              className={classes.otpText}
              sx={{ fontWeight: "500" }}
            >
              Verification Code
            </Typography>
          </Grid>

          {otpError === "" ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  align="center"
                  color="#303030"
                  component="span"
                  variant="body2"
                >
                  {resend
                    ? "A verification code has been resent to your email"
                    : "Please enter the verification code sent to your email."}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ height: "5rem" }}
              >
                <div className={classes.otpbox} style={{ marginTop: "10px" }}>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    separator={<span>-</span>}
                    inputStyle={classes.boxes}
                    shouldAutoFocus={true}
                    isDisabled={otp.length >= 6 ? true : false}
                    isInputNum={true}
                  />
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                align="center"
                color="red"
                component="span"
                variant="body2"
              >
                {otpError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {otp.length >= 6 ? (
              <CircularProgress
                className={classes.loading}
                sx={{ color: "#0077c6" }}
              />
            ) : (
              <>
                {message === "" ? (
                  <Typography
                    align="center"
                    color="#303030"
                    component="span"
                    variant="body1"
                    mt={1}
                  >
                    Didn't receive a code?
                  </Typography>
                ) : (
                  <Typography color="red" variant="body2" component="span">
                    {message}
                  </Typography>
                )}
                <Typography
                  align="center"
                  color="#0077c6"
                  mt={1}
                  variant="body1"
                  className={classes.resendCodeText}
                  onClick={() => {
                    requestOtp({ email: userEmail });
                    setResend(true);
                  }}
                >
                  Resend the code
                </Typography>
                <Typography
                  color="red"
                  variant="caption"
                  onClick={() => dispatch(logout())}
                  className={classes.resendCodeText}
                >
                  Logout
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </CustomPaper>
    </Grid>
  );
};

/* Styles */
const useStyles = makeStyles({
  loading: {
    marginTop: "30px",
  },
  otpbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resendCodeText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  boxes: {
    fontSize: "2.5rem",
    borderRadius: "3px",
    border: "1px solid #000",

    "@media screen and (max-width: 1000px)": {
      fontSize: "2rem",
    },

    "@media screen and (max-width: 700px)": {
      fontSize: "1.7rem",
    },
  },
});

export default OtpPage;
