import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/orders";

const getOrdersList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetOrdersList", data);
};

const draftOrderEmaiSent = (data) => {
  return fetchWrapper.post(baseUrl + "/DraftOrderEmaiSent", data);
};

const createOrder = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateOrder", data);
};

const deleteOrders = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteOrder", data);
};

const getAllSubmittedOrInProcessOrdersByShippingAddressID = (
  shippingID,
  orderID
) => {
  return fetchWrapper.get(
    baseUrl +
      "/GetAllSubmittedOrInProcessOrdersByShippingAddressID/" +
      shippingID +
      "/" +
      orderID
  );
};

const getOrderByID = (data) => {
  return fetchWrapper.post(baseUrl + "/GetOrderByID", data);
};

const updateOrder = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateOrder", data);
};

const submitOrder = (data) => {
  return fetchWrapper.post(baseUrl + "/ChangeOrderStatus", data);
};

const sendApprovalEmail = (data) => {
  return fetchWrapper.post(baseUrl + "/SendLevvenOrderApproveEmail", data);
};

const getOrdersByInstallerID = (companyID) => {
  return fetchWrapper.get(baseUrl + "/GetOrdersByInstallerID/" + companyID);
};

const getOrdersByAgreementID = (agreementID) => {
  return fetchWrapper.get(baseUrl + "/GetOrdersByAgreementID/" + agreementID);
};

const getOrdersAndTakeoffsByAgreementID = (agreementID) => {
  return fetchWrapper.get(
    baseUrl + "/GetOrdersAndTakeoffsByAgreementID/" + agreementID
  );
};

const checkIfJobNumberExists = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckIfJobNumberExists", data);
};

const getLastFourTakeoffsAndOrders = (data) => {
  return fetchWrapper.get(baseUrl + "/GetLastFourTakeoffsAndOrders/" + data);
};

const getRecalculatedOrderTotal = (data) => {
  return fetchWrapper.post(baseUrl + "/GetRecalculatedOrderTotal", data);
};

const duplicateOrder = (data) => {
  return fetchWrapper.post(baseUrl + "/DuplicateOrder", data);
};

export const orderServices = {
  getOrdersList,
  deleteOrders,
  createOrder,
  getAllSubmittedOrInProcessOrdersByShippingAddressID,
  getOrderByID,
  updateOrder,
  submitOrder,
  sendApprovalEmail,
  getOrdersByInstallerID,
  getOrdersByAgreementID,
  getOrdersAndTakeoffsByAgreementID,
  checkIfJobNumberExists,
  getLastFourTakeoffsAndOrders,
  getRecalculatedOrderTotal,
  duplicateOrder,
  draftOrderEmaiSent,
};
