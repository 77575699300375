import React from "react";
import { AlignJustify, Plus } from "react-feather";
import { useHistory, Link } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import "./style.css";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

export default function DashboardCard({
  title = "Recent",
  cardIcon = <AlignJustify />,
  blue = false,
  green = false,
  viewText = "",
  viewLink = "",
  addText = "",
  addLink = "",
  subtitles = [],
  rows = [],
  takeoff = false,
  readOnly = false,
  order = false,
  canViewBoth = true,
  recordViewLink = "",
  companyType = "",
}) {
  const history = useHistory();

  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  const viewRecordDetails = (obj) => {
    history.push({
      pathname: recordViewLink,
      state: obj,
    });
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const encryptedDate = await encryptObject(contextMenuData);

    window.open(
      "" + recordViewLink + "?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <div class={"card " + (canViewBoth ? "grid-span-1" : "grid-span-2") + ""}>
      <div class="card-header">
        <div className="card-header-section-1">
          {/* <div
            className={`card-icon card-icon-${
              blue ? "blue" : green ? "green" : ""
            }`}
          >
            {cardIcon}
          </div> */}
          <div class="card-title">
            <p>{title}</p>
          </div>
        </div>
      </div>
      <div className="card-content">
        <table>
          <thead>
            <tr>
              {subtitles.map((sub, index) => {
                return <th>{sub}</th>;
              })}
            </tr>
          </thead>
          <tbody onContextMenu={handleContextMenu}>
            {takeoff ? (
              <>
                {" "}
                {rows.map((row, index) => {
                  return (
                    <>
                      <tr
                        onContextMenu={() => {
                          setContextMenuData({
                            id: row.takeoffID,
                            name: row.name,
                            doesTakeoff:
                              row.agreement.takeoff === companyType
                                ? true
                                : companyType === "L"
                                ? true
                                : false,
                            agreementID: row.agreementID,
                            fromDashboard: true,
                          });
                        }}
                        onClick={() =>
                          viewRecordDetails({
                            id: row.takeoffID,
                            name: row.name,
                            doesTakeoff:
                              row.agreement.takeoff === companyType
                                ? true
                                : companyType === "L"
                                ? true
                                : false,
                            agreementID: row.agreementID,
                            fromDashboard: true,
                          })
                        }
                      >
                        <td>{row.agreement.builder.name}</td>
                        <td>{row.planName}</td>
                        <td>
                          {row.modificationDate
                            ? convertUTC(row.modificationDate)
                            : null}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : order ? (
              <>
                {" "}
                {rows.map((row, index) => {
                  return (
                    <>
                      <tr
                        onContextMenu={() => {
                          setContextMenuData({
                            orderID: row.orderID,
                            orderName: row.purchaser.company.name,
                            orderStatus: row.status,
                            purchaserCompanyID: row.purchaser.companyID,
                            jobName: row.jobName,
                            jobNum: row.jobNum,
                            purchaseOrderNbr: row.purchaseOrderNbr,
                            requestedDate: row.requestedDeliveryDate
                              ? row.requestedDeliveryDate
                              : "",
                            fromDashboard: true,
                          });
                        }}
                        onClick={() =>
                          viewRecordDetails({
                            orderID: row.orderID,
                            orderName: row.purchaser.company.name,
                            orderStatus: row.status,
                            purchaserCompanyID: row.purchaser.companyID,
                            jobName: row.jobName,
                            jobNum: row.jobNum,
                            purchaseOrderNbr: row.purchaseOrderNbr,
                            requestedDate: row.requestedDeliveryDate
                              ? row.requestedDeliveryDate
                              : "",
                            fromDashboard: true,
                          })
                        }
                      >
                        <td>
                          {" "}
                          {row.takeoff
                            ? row.takeoff.agreement.builder.name
                            : row.purchaser.company.type === "B"
                            ? row.purchaser.company.name
                            : "N/A"}
                        </td>
                        <td>{row.jobNum}</td>
                        <td>
                          {" "}
                          {row.modificationDate
                            ? convertUTC(row.modificationDate)
                            : null}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : null}
            <>
              <ContextMenu
                contextMenu={contextMenu}
                handleClose={handleCloseContextMenu}
                openInNewTab={openInNewTab}
              />
            </>
          </tbody>
        </table>
      </div>

      <div className="card-header-section-2">
        <div className="links">
          <CustomButton toggle={() => history.push(viewLink)}>
            {viewText}
          </CustomButton>
          {!readOnly ? (
            <CustomButton
              startIcon={<Plus />}
              toggle={() => history.push(addLink)}
            >
              {addText}
            </CustomButton>
          ) : null}

          {/* <Link to={viewLink}>{viewText}</Link>
            <Link to={addLink}>
              <Plus />
              {addText}
            </Link> */}
        </div>
      </div>
    </div>
  );
}
