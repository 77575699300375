import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, RotateCw, Copy } from "react-feather";
import Settings from "@mui/icons-material/Settings";
import CustomButton from "../CustomButton/CustomButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { companyServices } from "../../services/company.services";
import { agreementServices } from "../../services/agreement.services";
import { orderServices } from "../../services/orders.service";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { accountServices } from "../../services/account.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Example from "./example";
import CustomModal from "../CustomModal/CustomModal";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ backgroundColor: "#0077c6" }}
          align="center"
        >
          {rowCount > 0 && numSelected !== 0 ? (
            <Checkbox
              checkedIcon={
                <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) =>
          headCell.visibility === true ? (
            <TableCell
              key={headCell.id}
              align={
                headCell.numeric ? "right" : headCell.center ? "center" : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: "white", backgroundColor: "#0077c6" }}
              // onClick={createSortHandler(headCell.id)}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export default function RMATableComponent({ tableID = 3 }) {
  //used to send data to next page
  const history = useHistory();

  //used to retrieve sent data from previous page
  const location = useLocation();

  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("");

  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //for page in table
  const [page, setPage] = React.useState(0);

  //for rows per page in table
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  //make page load until all data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds are data to be displayed in rows
  const [rows, setRows] = React.useState([]);

  //to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  const [duplicateLoading, setDuplicateLoading] = React.useState(false);

  //for holding error messages
  const [error, setError] = React.useState("");

  //number of selected rows
  var numSelected = selected.length;

  //total number of records without pagination
  const [numberRecords, setNumberRecords] = React.useState(0);

  //make delete button load while delete in progress
  const [loading, setLoading] = React.useState(false);

  //to refetch data from database
  const [resubmit, setResubmit] = React.useState(false);

  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //in case of error while deleting
  const [deleteError, setDeleteError] = React.useState("");

  //to display success snackbar
  const [success, setSuccess] = React.useState(false);

  //to display alert dialog before deleting
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  const [dateError, setDateError] = React.useState("");

  const [headCells, setHeadCells] = React.useState([]);
  const [headCellsEdit, setHeadCellsEdit] = React.useState([]);

  //if order columns saved successfully
  const [openSnackbar2, setOpenSnackbar2] = React.useState(false);

  //filters
  const [createdBy, setCreatedBy] = React.useState([]);
  const [companyName, setCompanyName] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [requestDateFrom, setRequestDateFrom] = React.useState(null);
  const [requestDateTo, setRequestDateTo] = React.useState(null);
  const [orderIdFilter, setOrderIdFilter] = React.useState("");
  const [soNumber, setSoNumber] = React.useState("");

  /**Loading for edit columns */
  const [editColumnsLoading, setEditColumnsLoading] = React.useState(false);

  //hold filters selected
  const [selectedCompanyName, setSelectedCompanyName] = React.useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [poNumber, setPONumber] = React.useState("");

  const [changeColumns, setChangeColumns] = React.useState(false);

  /**To display duplicate button for In Process, Submitted, Shipped and Invoiced Orders */
  const [uneditableOrderSelected, setUneditableOrderSelected] = React.useState(
    []
  );

  //if order deleted from tabs
  const [openSnackbar, setOpenSnackbar] = React.useState(
    location.state
      ? location.state.openSnackbar
        ? location.state.openSnackbar
        : false
      : false
  );

  const [openOrderSuccess, setOpenOrderSuccess] = React.useState(
    location.state
      ? location.state.openOrderSuccess
        ? location.state.openOrderSuccess
        : false
      : false
  );

  //get values from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.rma : ""
  );

  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : 0
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  /*Check Status*/
  const orderStatusCheck = (status) => {
    if (status === "N") {
      return "Open";
    } else if (status === "D") {
      return "Draft";
    } else if (status === "F") {
      return "Final";
    } else if (status === "T") {
      return "Submitted";
    } else if (status === "I") {
      return "In Process";
    } else if (status === "S") {
      return "Shipped";
    } else if (status === "V") {
      return "Invoiced";
    } else return "";
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  const getValuesForFilters = async () => {
    //function to fetch all filter data to be displayed

    await companyServices
      .getAllRMACompanies()
      .then((response) => {
        setCompanyName(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    await accountServices
      .getUsersForCreatedByRMA(companyID)
      .then((response) => {
        setCreatedBy(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const handleDateSubmit = async (date) => {
    const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

    if (!regex.test(date)) {
      return "Invalid Date Format";
    }

    const searchDate = moment(date).isValid()
      ? moment(date).format("MM/DD/YYYY")
      : "Invalid Date Format";

    return searchDate;
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const getValuesFromDatabase = async (column = "", direction = "") => {
    //function to retrieve the row data for table

    //we filter the filter data to send to the backend since the filters are saved as objects

    var companyArray = [];
    var statusArray = [];
    var createdByArray = [];

    selectedCompanyName.forEach((company) => {
      companyArray.push(company.companyID);
    });

    selectedStatus.forEach((status) => {
      statusArray.push(status.id);
    });

    selectedCreatedBy.forEach((status) => {
      createdByArray.push(status.userID);
    });

    var handleDateErrorDateTo = "";
    var handleDateErrorDateFrom = "";
    var handleDateErrorRequestDateFrom = "";
    var handleDateErrorRequestDateTo = "";

    if (dateTo !== null) handleDateErrorDateTo = await handleDateSubmit(dateTo);
    if (dateFrom !== null)
      handleDateErrorDateFrom = await handleDateSubmit(dateFrom);
    if (requestDateFrom !== null)
      handleDateErrorRequestDateFrom = await handleDateSubmit(requestDateFrom);
    if (requestDateFrom !== null)
      handleDateErrorRequestDateTo = await handleDateSubmit(requestDateTo);

    if (
      handleDateErrorDateTo === "Invalid Date Format" ||
      handleDateErrorDateFrom === "Invalid Date Format" ||
      handleDateErrorRequestDateFrom === "Invalid Date Format" ||
      handleDateErrorRequestDateTo === "Invalid Date Format"
    ) {
      if (handleDateErrorDateTo === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorDateFrom === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorRequestDateFrom === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Delivery Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorRequestDateTo === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Delivery Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      await setPageLoad(false);
    } else {
      await setRows([]);
      await setSelected([]);
      await setUneditableOrderSelected([]);
      var data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: {
          PONumber: poNumber,
          Company: companyArray,
          createdBy: createdByArray,
          Status: statusArray,
          OrderID: orderIdFilter ? orderIdFilter : 0,
          LevvenSONumber: soNumber,
          creationDateFrom:
            dateFrom === "" || dateFrom === null
              ? null
              : moment(new Date(dateFrom)).format("MM/DD/YYYY"),
          creationDateTo:
            dateTo === "" || dateTo === null
              ? null
              : moment(new Date(dateTo)).format("MM/DD/YYYY"),
          requestedDeliveryDateFrom:
            requestDateFrom === "" || requestDateFrom === null
              ? null
              : moment(new Date(requestDateFrom)).format("MM/DD/YYYY"),
          requestedDeliveryDateTo:
            requestDateTo === "" || requestDateTo === null
              ? null
              : moment(new Date(requestDateTo)).format("MM/DD/YYYY"),
        },
        isLevven: true,
        companyID: companyID,
        orderDirection: direction !== "" ? direction : orderDirection,
        orderColumn: column !== "" ? column : orderColumn,
        IsRMA: true,
      };
      await orderServices
        .getOrdersList(data)
        .then((response) => {
          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.order);

          setPageLoad(false);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  //function to update table preferences
  const updateUserTablePreferences = async () => {
    setEditColumnsLoading(true);
    await accountServices
      .updateUserTablePreferences({
        userId: userID,
        tableId: tableID,
        columns: headCellsEdit,
        SortColumn: orderColumn,
        SortDirection: orderDirection,
      })
      .then((response) => {
        setResubmit(!resubmit);
        setOpenSnackbar2(true);
        setEditColumnsLoading(false);
        setChangeColumns(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setEditColumnsLoading(false);
      });
  };

  const getTableColumns = async () => {
    await accountServices
      .getTableColumnsBasedOnUserPreferences({
        userId: userID,
        tableId: tableID,
      })
      .then((response) => {
        const copy1 = response.data.columns.map((item) => ({ ...item }));
        const copy2 = response.data.columns.map((item) => ({ ...item }));
        setHeadCells(copy1);
        setHeadCellsEdit(copy2);
        if (orderColumn === "" && orderDirection === "") {
          setOrderColumn(response.data.sortingColumn);
          setOrderDirection(response.data.sortingDirection);

          getValuesFromDatabase(
            response.data.sortingColumn,
            response.data.sortingDirection
          );
        } else {
          getValuesFromDatabase();
        }
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  useEffect(() => {
    setError("");

    window.scrollTo(0, 0); //to scroll to top of page
    setPageLoad(true);

    setRows([]);

    getValuesForFilters();
    getTableColumns();

    return () => {
      setRows([]);
    };
  }, [page, rowsPerPage, resubmit]);

  const submitFilterData = (event) => {
    //function to submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //for selecting all rows
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => {
        if (
          n.status === "T" ||
          n.status === "I" ||
          n.status === "V" ||
          n.status === "S"
        ) {
          setUneditableOrderSelected((value) => [...value, n.orderID]);
        }
        return n.orderID;
      });

      setSelected(newSelecteds);
      return;
    }
    setUneditableOrderSelected([]);
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  //change page in table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const clearFilterData = () => {
    //function to clear form data
    setOrderIdFilter("");
    setSoNumber("");
    setPONumber("");
    setSelectedCreatedBy([]);
    setDateFrom(null);
    setRequestDateFrom(null);
    setDateTo(null);
    setRequestDateTo(null);
    setSelectedCompanyName([]);
    setSelectedStatus([]);
    setResubmit(!resubmit);
  };

  //change rowsPerPage of table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecords = () => {
    //for deleting records selected
    setLoading(true);
    setDeleteRecord(false);
    orderServices
      .deleteOrders({ Orders: selected, IsRMA: true })
      .then(() => {
        setLoading(false);
        setSelected([]);
        setUneditableOrderSelected([]);
        setResubmit(!resubmit);
      })
      .catch((error) => {
        setLoading(false);
        setUneditableOrderSelected([]);
        setResubmit(!resubmit);
        setSelected([]);
        setDeleteError(error.response.data.error.error);
      });
  };

  const viewOrderData = (
    id,
    name,
    status,
    compID,
    jobName,
    jobNum,
    purchaseOrderNb,
    requestedDate
  ) => {
    //to view order data and go to order information
    history.push({
      pathname: "/rma-information",
      state: {
        orderID: id,
        orderName: name,
        orderStatus: status,
        purchaserCompanyID: compID,
        jobName: jobName,
        jobNum: jobNum,
        purchaseOrderNbr: purchaseOrderNb,
        requestedDate: requestedDate ? requestedDate : "",
      },
    });
  };

  //check if row selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const openInNewTab = async () => {
    const objectToBeSent = {
      orderID: contextMenuData.orderID,
      orderName: contextMenuData.purchaser.company.name,
      orderStatus: contextMenuData.status,
      purchaserCompanyID: contextMenuData.purchaser.companyID,
      jobName: contextMenuData.jobName,
      jobNum: contextMenuData.jobNum,
      purchaseOrderNbr: contextMenuData.purchaseOrderNbr,
      requestedDate: contextMenuData.requestedDeliveryDate
        ? contextMenuData.requestedDeliveryDate
        : "",
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/rma-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  const duplicateOrder = async (id) => {
    setDuplicateLoading(true);

    const data = { OrderID: id, IsRMA: true, CreatedByID: userID };

    await orderServices
      .duplicateOrder(data)
      .then((response) => {
        setDuplicateLoading(false);
        viewOrderData(
          response.data.orderID,
          response.data.purchaser.company.name,
          response.data.status,
          response.data.purchaser.companyID,
          response.data.jobName,
          response.data.jobNum,
          response.data.purchaseOrderNbr,
          response.data.requestedDeliveryDate
        );
      })
      .catch((error) => {
        setDuplicateLoading(false);
        setError(error.response.data.error.error);
      });
  };

  return (
    <>
      <SnackBar
        open={openSnackbar}
        message="RMA Deleted Successfully"
        severity="success"
        handleClose={() => setOpenSnackbar(false)}
      />
      <SnackBar
        open={openSnackbar2}
        message="List Columns Altered Successfully"
        severity="success"
        handleClose={() => setOpenSnackbar2(false)}
      />
      <SnackBar
        open={openOrderSuccess}
        message="Saved Successfully"
        severity="success"
        handleClose={() => setOpenOrderSuccess(false)}
      />
      <SnackBar
        open={success}
        message="Order(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Order Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteRecords}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {dateError ? (
        <AlertSnackBar
          message={dateError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDateError("");
          }}
          open={dateError !== ""}
        />
      ) : (
        ""
      )}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box>
        <Paper
          sx={{
            mb: 2,
          }}
        >
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", marginRight: "10px" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", marginRight: "10px" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                RMAs
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>

                {rolePermission === "M" &&
                uneditableOrderSelected.length === 0 ? (
                  <>
                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecord(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
                {rolePermission === "M" && selected.length === 1 ? (
                  <CustomButton
                    startIcon={<Copy />}
                    size="medium"
                    loading={duplicateLoading}
                    tableButton={true}
                    toggle={() => duplicateOrder(selected[0])}
                  >
                    Duplicate
                  </CustomButton>
                ) : (
                  ""
                )}
                <Tooltip title="Customize View">
                  <IconButton onClick={() => setChangeColumns(true)}>
                    <Settings fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<RotateCw />}
                  size="medium"
                  tableButton={true}
                  toggle={() => clearFilterData()}
                >
                  Refresh
                </CustomButton>

                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>

                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    toggle={() => history.push("/create-rma")}
                  >
                    New RMA
                  </CustomButton>
                ) : (
                  ""
                )}
                <Tooltip title="Customize View">
                  <IconButton onClick={() => setChangeColumns(true)}>
                    <Settings fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilterData} noValidate>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        fullWidth
                        value={soNumber}
                        onChange={(e) => setSoNumber(e.target.value)}
                        size="small"
                        label="Sales Order Number"
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "new-password",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <CustomDatePickerComponent
                        label="Delivery Date From"
                        value={requestDateFrom}
                        setValue={setRequestDateFrom}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <CustomDatePickerComponent
                        label="Delivery Date To"
                        value={requestDateTo}
                        setValue={setRequestDateTo}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        value={poNumber}
                        onChange={(e) => setPONumber(e.target.value)}
                        size="small"
                        label="Service Case/Ticket Number"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "new-password",
                        }}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={companyName}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        value={selectedCompanyName}
                        onChange={(event, value) => {
                          setSelectedCompanyName(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={createdBy}
                        getOptionLabel={(option) =>
                          option.userName ? option.userName : ""
                        }
                        value={selectedCreatedBy}
                        onChange={(event, value) => {
                          setSelectedCreatedBy(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Created By"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <CustomDatePickerComponent
                        label="Creation Date From"
                        value={dateFrom}
                        setValue={setDateFrom}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <CustomDatePickerComponent
                        label="Creation Date To"
                        value={dateTo}
                        setValue={setDateTo}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        fullWidth
                        value={orderIdFilter}
                        onChange={(e) => setOrderIdFilter(e.target.value)}
                        size="small"
                        label="RMA ID"
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "new-password",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={[
                          { id: "D", name: "Draft" },
                          { id: "T", name: "Submitted" },
                          { id: "I", name: "In Process" },
                          { id: "S", name: "Shipped" },
                          { id: "V", name: "Invoiced" },
                        ]}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        value={selectedStatus}
                        onChange={(event, value) => {
                          setSelectedStatus(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ width: "100%", whiteSpace: "nowrap" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={numSelected}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                headCells={headCells}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="15" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="15"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no RMAs to display. "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push({ pathname: "/create-rma" })
                          }
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.orderID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                        key={row.orderID}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ paddingRight: "10px" }}
                          align="center"
                        >
                          {rolePermission === "M" ? (
                            <Checkbox
                              onClick={(event) => {
                                if (
                                  row.status === "T" ||
                                  row.status === "S" ||
                                  row.status === "I" ||
                                  row.status === "V"
                                ) {
                                  if (isItemSelected === true) {
                                    var index = uneditableOrderSelected.indexOf(
                                      row.orderID
                                    );
                                    if (
                                      uneditableOrderSelected.length === 1 &&
                                      index === 0
                                    ) {
                                      setUneditableOrderSelected([]);
                                    } else if (index !== -1) {
                                      var newArray = uneditableOrderSelected;
                                      newArray = newArray.slice(index, 1);
                                      setUneditableOrderSelected(newArray);
                                    }
                                  } else {
                                    setUneditableOrderSelected((n) => [
                                      ...n,
                                      row.orderID,
                                    ]);
                                  }
                                }
                                handleClick(event, row.orderID);
                              }}
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : null}
                        </TableCell>
                        {headCells.map((headCell) => {
                          if (headCell.visibility)
                            return (
                              <TableCell
                                padding="normal"
                                onContextMenu={() => {
                                  setContextMenuData(row);
                                }}
                                align={
                                  headCell.columnID === 3 ||
                                  headCell.columnID === 5
                                    ? "center"
                                    : headCell.columnID === 1
                                    ? "left"
                                    : ""
                                }
                                sx={{
                                  textAlign:
                                    headCell.columnID === 8 ? "center" : "",
                                }}
                                // sx={{ display: "none" }}
                                onClick={() =>
                                  viewOrderData(
                                    row.orderID,
                                    row.purchaser.company.name,
                                    row.status,
                                    row.purchaser.companyID,
                                    row.jobName,
                                    row.jobNum,
                                    row.purchaseOrderNbr,
                                    row.requestedDeliveryDate
                                  )
                                }
                              >
                                {headCell.columnID === 1 ? (
                                  row.navNo
                                ) : headCell.columnID === 2 ? (
                                  convertUTC(row.requestedDeliveryDate)
                                ) : headCell.columnID === 3 ? (
                                  row.purchaseOrderNbr
                                ) : headCell.columnID === 4 ? (
                                  row.purchaser.company.name
                                ) : headCell.columnID === 5 ? (
                                  row.createdBy.userName
                                ) : headCell.columnID === 6 ? (
                                  convertUTC(row.creationDate)
                                ) : headCell.columnID === 7 ? (
                                  row.orderID
                                ) : (
                                  <span
                                    style={{
                                      padding: "5px 10px",
                                      borderRadius: "25px",
                                      textAlign: "center",
                                      marginLeft: "-25px",
                                      backgroundColor:
                                        row.status === "D"
                                          ? "rgba(100, 76, 159, 0.2)"
                                          : row.status === "N"
                                          ? "rgba(255, 193, 7, 0.2)"
                                          : row.status === "F"
                                          ? "rgba(179, 27, 27 , 0.4)"
                                          : row.status === "T"
                                          ? "rgba(84, 184, 122, 0.3)"
                                          : row.status === "I"
                                          ? "rgb(64, 184, 194, 0.2)"
                                          : row.status === "S"
                                          ? "rgb(28, 128, 0, 0.1)"
                                          : "rgba(150, 150, 150, 0.3)",
                                      color:
                                        row.status === "D"
                                          ? "rgba(100, 76, 159, 1)"
                                          : row.status === "N"
                                          ? "rgba(255, 193, 7, 1)"
                                          : row.status === "F"
                                          ? "rgba(179, 27, 27 , 1)"
                                          : row.status === "T"
                                          ? "rgb(84, 184, 122, 1)"
                                          : row.status === "I"
                                          ? "rgb(64, 184, 194, 1)"
                                          : row.status === "S"
                                          ? "rgb(28, 128, 0, 1)"
                                          : "rgba(150, 150, 150, 1)",
                                    }}
                                  >
                                    {orderStatusCheck(row.status)}
                                  </span>
                                )}
                              </TableCell>
                            );
                          else return null;
                        })}
                      </TableRow>
                    );
                  })
                )}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {changeColumns ? (
        <>
          <CustomModal openModal={changeColumns} width="70%">
            <Grid
              container
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "20px",
                justifyContent: "center",
                // textAlign: "justify",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span" align="left">
                  Customize RMA View
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#0077c6",
                    padding: "16px 0",
                  }}
                >
                  <Grid container>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>

                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      style={{
                        margin: "auto",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Show/Hide
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Sort
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="white"
                        style={{ fontWeight: "500" }}
                      >
                        Sort Direction
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "0px 0px 20px 20px",
                justifyContent: "center",
                // textAlign: "justify",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DndProvider backend={HTML5Backend}>
                  <Example
                    steps={headCellsEdit}
                    setSteps={setHeadCellsEdit}
                    sorting={orderColumn}
                    setSorting={setOrderColumn}
                    direction={orderDirection}
                    setDirection={setOrderDirection}
                  />
                </DndProvider>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "25px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "30px",
                  }}
                >
                  <CustomButton
                    disable={editColumnsLoading}
                    toggle={() => {
                      var tmp = headCells.map((item) => ({ ...item }));
                      setHeadCellsEdit(tmp);
                      setChangeColumns(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    loading={editColumnsLoading}
                    toggle={() => {
                      updateUserTablePreferences();
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
