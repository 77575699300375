import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Home, Truck } from "react-feather";
import DashboardCard from "./DashboardCard";
import { orderServices } from "../../services/orders.service";
import "./style.css";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { useSelector } from "react-redux";
import { moment } from "moment";

export default function DashboardComponent() {
  //holds the rows for the takeoff
  const [takeoffRows, setTakeoffRows] = useState([]);

  //holds the rows for the order
  const [orderRows, setOrderRows] = useState([]);

  //For page loading
  const [pageLoad, setPageLoad] = useState(false);

  //Holds errors if any
  const [error, setError] = useState("");

  //Fetching user data needed
  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : 0
  );

  const userFirstName = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const userLastName = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  const companyName = useSelector((state) =>
    state.user.user ? state.user.user.user.company.name : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  //User's permission in viewing takeoff list and orders list
  const takeoffPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : "N"
  );

  const orderPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : "N"
  );

  const canViewBoth = orderPermission !== "N" && takeoffPermission !== "N";

  const fetchData = async () => {
    setPageLoad(true);
    await orderServices
      .getLastFourTakeoffsAndOrders(companyID)
      .then((response) => {
        setTakeoffRows(response.data.takeoffs);
        setOrderRows(response.data.orders);
        setPageLoad(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    setTakeoffRows([]);
    setOrderRows([]);

    fetchData();
  }, []);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  //function to convert date format
  const convertDate = () => {
    const date = new Date();
    const d = date.toString();
    const m = date.toLocaleString("default", { month: "long" });
    return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
  };

  return (
    <>
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={handleCloseAlertSnackbar}
          open={error !== ""}
        />
      ) : null}
      {pageLoad ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </div>
      ) : (
        <div class="grid-container">
          <div class="grid-span-2 card user-information-card">
            <div className="page-title">
              <h1>Dashboard</h1>
            </div>
            <p>
              <span class="user-name">
                {userFirstName + " " + userLastName}
              </span>
              {", " + companyName}
            </p>
            <p>{convertDate()}</p>
          </div>
          {takeoffPermission !== "N" ? (
            <DashboardCard
              blue
              takeoff
              canViewBoth={canViewBoth}
              // cardIcon={<Home />}
              title="Recent Takeoffs"
              viewText="View All Takeoffs"
              addText="Add Takeoff"
              viewLink="/Takeoff"
              readOnly={takeoffPermission === "R"}
              addLink="/create-takeoff"
              subtitles={["Builder", "Plan Name", "Date Modified"]}
              rows={takeoffRows}
              recordViewLink={"/takeoff-information"}
              companyType={companyType}
            />
          ) : null}

          {orderPermission !== "N" ? (
            <DashboardCard
              blue
              order
              canViewBoth={canViewBoth}
              // cardIcon={<Truck />}
              title="Recent Orders"
              viewText="View All Orders"
              readOnly={orderPermission === "R"}
              addText="Add Order"
              viewLink="/Orders"
              addLink="/create-order"
              subtitles={["Builder", "Job Number", "Date Modified"]}
              rows={orderRows}
              recordViewLink={"/order-information"}
              companyType={companyType}
            />
          ) : null}
        </div>
      )}
    </>
  );
}
