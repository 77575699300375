import { useState, useCallback } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
const style = {
  width: "100%",
};
export const Container = ({ steps, setSteps }) => {
  {
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = steps[dragIndex];
        setSteps(
          update(steps, {
            $splice: [
              [dragIndex, 1],
              [
                hoverIndex,
                0,
                {
                  stepID: dragCard.stepID,
                  title: dragCard.title,
                },
              ],
            ],
          })
        );
      },
      [steps]
    );
    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          description={card.title}
          moveCard={moveCard}
        />
      );
    };
    return (
      <>
        <div style={style}>{steps.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};
