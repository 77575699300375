import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement */

const baseUrl = apiConfig + "/invoices";

const getInvoicesLinesByOrderID = (data) => {
  return fetchWrapper.post(baseUrl + "/GetInvoicesLinesByOrderID", data);
};

export const invoicesServices = {
  getInvoicesLinesByOrderID,
};
