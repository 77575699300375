import { Breadcrumbs, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TakeoffForm from "../../../components/TakeoffForm/TakeoffForm";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { X } from "react-feather";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateTakeoffPage = ({ setPageID, setPageLink }) => {
  const history = useHistory();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate("/takeoff");
  
    } else {
  
      history.push("/takeoff");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  const takeoffRef = React.useRef(null);

  /*Get Takeoff permission from Redux*/
  const takeoff = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  if (takeoff === "N") {
    history.push("/dashboard");
  }

  const duplicate = location.state
    ? location.state.duplicate
      ? location.state.duplicate
      : false
    : false;

  const takeoffID = location.state
    ? location.state.takeoffID
      ? location.state.takeoffID
      : 0
    : 0;

  const agreementID = location.state
    ? location.state.agreement
      ? location.state.agreement
      : 0
    : 0;

  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    //for drawer menu select
    setPageID(4);

    //for help button
    setPageLink("Takeoffs");
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            onClick={() => handleItemClick()}
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            Takeoffs
          </Link>
          <Typography key="2" color="text.primary" sx={{ fontSize: "24px" }}>
            New Takeoff
          </Typography>
        </Breadcrumbs>
        <CustomButton
          startIcon={<X />}
          toggle={async () => {
            await takeoffRef.current.setPristine();
            history.push("/Takeoff");
          }}
        >
          Cancel
        </CustomButton>
      </div>
      <div style={{ height: "40px" }} />
      <TakeoffForm
        create={true}
        setFormChanged={setFormChanged}
        ref={takeoffRef}
        duplicate={duplicate}
        receivedTakeoffID={takeoffID}
        agreementID={agreementID}
      />
    </Paper></>
  );
};

export default CreateTakeoffPage;
