import { userTypes } from "../types/userTypes";

const initialState = {};

/*This is the user reducer where all user states are modified */

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.GET_USER_DATA:
      return { ...state, user: action.payload };
    case userTypes.REGISTER_NEW_DEVICE:
      return {
        ...state,
        user: {
          ...state.user,
          deviceInfo: action.payload.deviceInfo,
          deviceToken: action.payload.deviceToken,
        },
      };

    case userTypes.UPDATE_OTP:
      return {
        ...state,
        user: { ...state.user, requestOTP: action.payload },
      };
    case userTypes.UPDATE_END_USER_AGREEMENT:
      return {
        ...state,
        user: {
          ...state.user,
          user: { ...state.user.user, agreementApproved: action.payload },
        },
      };
    case userTypes.SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload,
      };
    case userTypes.LOGOUT_USER:
      return initialState;
    case userTypes.UNAUTH_USER:
      return { sessionExpired: true };
    case userTypes.UPDATE_USER_INFO:
      return { ...state, user: { ...state.user, user: action.payload } };
    case userTypes.UPDATE_USER_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          user: { ...state.user.user, company: action.payload },
        },
      };
    case userTypes.UPDATE_USER_ALLOW_COOKIES:
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            acceptedCookies: action.payload.acceptedCookies,
            rememberMyChoiceCookies: action.payload.rememberMyChoiceCookies,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default userReducer;
