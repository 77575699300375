import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Modal from "../CustomModal/CustomModal";
import { useEffect } from "react";
import { Grid, IconButton, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { Image } from "react-feather";
import { productServices } from "../../services/product.service";
import CustomButton from "../CustomButton/CustomButton";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const headCells = [
  {
    id: "image",
    numeric: true,
    disablePadding: false,
    label: "Image",
    center: true,
  },
  {
    id: "productNumber",
    numeric: false,
    disablePadding: false,
    label: "Product Number",
    center: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
    center: false,
  },
  {
    id: "Quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
    center: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductTakeoff({
  titleText,
  rows = [],
  activeStep,
  rolePermission,
  pageLoad = false,
  orders = false,
  loading = false,
  setTotalSwitches,
  setTotalWallPlates,
  numSwitches,
  numWallPlates,
  totalSwitches = 0,
  totalWallPlates = 0,
  setTotalSwitchesOrder,
  setTotalWallPlatesOrder,
  totalQuickMount = 0,
  setTotalQuickMount,
  setFormChanged,
}) {
  //holds total number of records (without pagination)
  const [numberRecords, setNumberRecords] = React.useState(0);

  //in case of error while deleting
  const [deActError, setDeActError] = React.useState("");

  //to display snackbar
  const [success, setSuccess] = React.useState(false);

  //holds success message
  const [message, setMessage] = React.useState("");

  //holds image
  const [image, setImage] = React.useState();

  //holds error while fetching image
  const [imageError, setImageError] = React.useState("");

  const [imageLoad, setImageLoad] = React.useState(false);

  //holds image description
  const [imageDescription, setImageDescription] = React.useState("");

  const [imageSpecification, setImageSpecification] = React.useState("");

  //to display image
  const [displayImage, setDisplayImage] = React.useState(false);

  const inputRef = React.useRef();

  useEffect(() => {
    //to focus and select first field in table when clicking next
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [activeStep, inputRef.current]);

  useEffect(() => {
    setNumberRecords(rows.length);
  }, [numberRecords, activeStep, rows]);

  /*Fetch Image File*/
  const fetchImageFile = async (id, path, description, specifications) => {
    setImageLoad(true);
    if (path === null) return;
    setImageDescription(description);
    setImageSpecification(specifications);
    setDisplayImage(true);
    if (path) {
      await productServices
        .getImageFile(path, id)
        .then((response) => {
          setImage(URL.createObjectURL(response.data));
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setImageError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
        });
    }
    setImageLoad(false);
  };

  return (
    <>
      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />

      {deActError ? (
        <AlertSnackBar
          message={deActError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeActError("");
          }}
          open={deActError !== ""}
        />
      ) : null}

      {displayImage ? (
        <>
          <Modal openModal={displayImage} width="600px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                padding: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="div">
                  {imageDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!imageLoad ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {image ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <img
                              src={image}
                              alt="product"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                margin: "auto",
                                alignSelf: "center",
                                border: "1px solid #c4c4c4",
                                borderRadius: 8,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                ) : !imageError ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <CustomAlert>{imageError}</CustomAlert>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Typography variant="h7">
                          <b>Specifications</b>
                        </Typography>
                        <div
                          style={{
                            textAlign: "left",
                            textJustify: "inter-word",
                          }}
                        >
                          <Typography variant="body2" component="span">
                            {imageSpecification}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setImage(null);
                      setDisplayImage(false);
                      setImageDescription("");
                      setImageSpecification("");
                    }}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <div style={{ marginLeft: "10px" }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                marginTop: "10px",
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {titleText?.step?.title}
            </Typography>
            <Typography
              sx={{
                flex: "1 1 100%",
                marginTop: "12px",
                marginBottom: "16px",
                fontSize: "1rem",
              }}
              variant="caption"
              display="block"
              id="tableTitle2"
              component="div"
            >
              {titleText?.step?.description}
            </Typography>
          </div>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead rowCount={numberRecords} />

              <TableBody>
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="9" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : null}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="6"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      There are no products to match these filters
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.product.productID}
                        sx={{ height: "130px" }}
                      >
                        <TableCell align="center">
                          {row.productImage ? (
                            <img
                              focusable="false"
                              tabIndex="-1"
                              onClick={() =>
                                fetchImageFile(
                                  row.product.productID,
                                  row.product.imagePath
                                    ? row.product.imagePath
                                    : "",
                                  row.product.description,
                                  row.product.specifications
                                )
                              }
                              src={row.productImage}
                              alt={row.product.partNbr}
                              style={{
                                height: "100px",
                                objectFit: "cover",
                                alignSelf: "center",
                                borderRadius: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <>
                              <IconButton
                                tabIndex="-1"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchImageFile(
                                    row.product.productID,
                                    row.product.imagePath
                                      ? row.product.imagePath
                                      : "",
                                    row.product.description,
                                    row.product.specifications
                                  )
                                }
                              >
                                <Image focusable="false" />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell>{row.product.partNbr}</TableCell>
                        <TableCell>{row.product.description}</TableCell>

                        <TableCell align="center">
                          <TextField
                            type="number"
                            inputProps={{
                              style: { textAlign: "right" },
                              min: 0,
                            }}
                            disabled={loading || rolePermission !== "M"}
                            key={index + " " + activeStep}
                            variant="standard"
                            name={"quantity " + index + " " + activeStep + ""}
                            placeholder={"0"}
                            defaultValue={rows[index].quantity}
                            onKeyPress={(event) => {
                              if (event?.key === "-" || event?.key === "+") {
                                event.preventDefault();
                              }
                            }}
                            inputRef={
                              !orders && rows.length > 0 && index === 0
                                ? inputRef
                                : null
                            }
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              var value = 0;
                              var value2 = 0;
                              var newValue = 0;
                              var newValue2 = 0;
                              /**IDs used are from the database since they will not be changing */

                              /*If not accessing from Order*/
                              if (!orders) {
                                //subtract old value and add new value to total switch number and plate position number
                                //calculations are made based on type of product
                                if (
                                  rows[index].product.category.categoryCode ===
                                  1
                                ) {
                                  value =
                                    rows[index].quantity *
                                    rows[index].product.switchAssemblyType.qty;
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  value =
                                    rows[index].quantity *
                                    parseInt(
                                      rows[index].product.gangNbr.description
                                    );
                                }

                                if (
                                  rows[index].product.category.categoryCode ===
                                  1
                                ) {
                                  newValue =
                                    e.target.value *
                                    rows[index].product.switchAssemblyType.qty;
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  newValue =
                                    e.target.value *
                                    parseInt(
                                      rows[index].product.gangNbr.description
                                    );
                                }
                              } else {
                                /**If accessing from Order */
                                //subtract old value and add new value to total switch number and plate position number
                                if (
                                  rows[index].product.category.categoryCode ===
                                    1 ||
                                  rows[index].product.category.categoryCode ===
                                    3
                                ) {
                                  value =
                                    rows[index].quantity *
                                    rows[index].product.switchAssemblyType.qty;

                                  if (
                                    rows[index].product.switchStyle
                                      .switchStyleCode === 2
                                  ) {
                                    value2 =
                                      rows[index].quantity *
                                      rows[index].product.switchAssemblyType
                                        .qty;
                                  }
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  value =
                                    rows[index].quantity *
                                    parseInt(
                                      rows[index].product.gangNbr.description
                                    );
                                }

                                if (
                                  rows[index].product.category.categoryCode ===
                                    1 ||
                                  rows[index].product.category.categoryCode ===
                                    3
                                ) {
                                  newValue =
                                    e.target.value *
                                    rows[index].product.switchAssemblyType.qty;

                                  if (
                                    rows[index].product.switchStyle
                                      .switchStyleCode === 2
                                  ) {
                                    newValue2 =
                                      e.target.value *
                                      rows[index].product.switchAssemblyType
                                        .qty;
                                  }
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  newValue =
                                    e.target.value *
                                    parseInt(
                                      rows[index].product.gangNbr.description
                                    );
                                }
                              }
                              rows[index].quantity = isNaN(
                                parseInt(e.target.value)
                              )
                                ? null
                                : parseInt(e.target.value);

                              /**If not accessing from Order */
                              if (!orders) {
                                var tmp =
                                  rows[index].product.category.categoryCode ===
                                  1
                                    ? numSwitches
                                    : rows[index].product.category
                                        .categoryCode === 5
                                    ? numWallPlates
                                    : 0;
                                tmp = tmp - value;
                                tmp = tmp + newValue;
                                if (
                                  rows[index].product.category.categoryCode ===
                                  1
                                ) {
                                  setTotalSwitches(tmp);
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  setTotalWallPlates(tmp);
                                }
                              } else {
                                /**If accessing from Order */
                                var tmp2 =
                                  rows[index].product.category.categoryCode ===
                                    1 ||
                                  rows[index].product.category.categoryCode ===
                                    3
                                    ? totalSwitches
                                    : rows[index].product.category
                                        .categoryCode === 5
                                    ? totalWallPlates
                                    : 0;
                                tmp2 = tmp2 - value;
                                tmp2 = tmp2 + newValue;
                                if (
                                  rows[index].product.category.categoryCode ===
                                    1 ||
                                  rows[index].product.category.categoryCode ===
                                    3
                                ) {
                                  setTotalSwitchesOrder(tmp2);
                                } else if (
                                  rows[index].product.category.categoryCode ===
                                  5
                                ) {
                                  setTotalWallPlatesOrder(tmp2);
                                }

                                if (
                                  (rows[index].product.category.categoryCode ===
                                    1 ||
                                    rows[index].product.category
                                      .categoryCode === 3) &&
                                  rows[index].product.switchStyle
                                    .switchStyleCode === 2
                                ) {
                                  var tmp3 = totalQuickMount;
                                  tmp3 = tmp3 - value2;
                                  tmp3 = tmp3 + newValue2;
                                  setTotalQuickMount(tmp3);
                                }
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {orders === false && (
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="body1"
                            component="span"
                            fontWeight={500}
                          >
                            Total Number of Switches:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            inputProps={{
                              style: { textAlign: "right", fontWeight: 500 },
                              tabIndex: "-1",
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            value={numSwitches}
                            tabIndex="-1"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell align="center" tabIndex="-1">
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="body1"
                            component="span"
                            fontWeight={500}
                          >
                            Total Number of Switch Plate Positions:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            InputProps={{ disableUnderline: true }}
                            inputProps={{
                              style: { textAlign: "right", fontWeight: 500 },
                              tabIndex: "-1",
                            }}
                            variant="standard"
                            value={numWallPlates}
                            tabIndex="-1"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
