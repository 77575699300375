import { Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CompanyForm from "../../../components/CompanyForm/CompanyForm";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Save, X } from "react-feather";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateCompanyPage = ({ setPageID, setPageLink }) => {
  const history = useHistory();

  const clientCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientCompany : ""
  );

  /*Redirection*/
  if (clientCompany !== "M") {
    history.push("/dashboard");
  }

  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate("/companies");
  
    } else {
  
      history.push("/companies");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  

  /*Agreement Form Ref*/
  const companyRef = useRef(null);

  useEffect(() => {
    //for drawer menu select
    setPageID(6);

    //for help button
    setPageLink("Companies");
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
    
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            onClick={() => handleItemClick()}

            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            Companies
          </Link>
          <Typography key="5" color="text.primary" sx={{ fontSize: "24px" }}>
            New Company
          </Typography>
        </Breadcrumbs>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CustomButton
            startIcon={<Save />}
            toggle={() => companyRef.current.save()}
            loading={tabButtonLoading}
          >
            Save
          </CustomButton>
          <div style={{ width: "10px" }} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await companyRef.current.setPristine();
              history.push("/Companies");
            }}
          >
            Cancel
          </CustomButton>
        </div>
      </div>
      <div style={{ height: "40px" }} />
      <CompanyForm
        create={true}
        setTabButtonLoading={setTabButtonLoading}
        setFormChanged={setFormChanged}
        ref={companyRef}
      />
    </Paper></>
  );
};

export default CreateCompanyPage;
