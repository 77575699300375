import * as React from "react";
import Typography from "@mui/material/Typography";
import CountryForm from "./CountryForm";
import { Grid, Paper } from "@mui/material";
import StateProvinceTableComponent from "../StateProvinceTableComponent/StateProvinceTableComponent";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { X, Trash2 } from "react-feather";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useState } from "react";
import SnackBar from "../SnackBar/SnackBar";
import { countryServices } from "../../services/country.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";

export default function Cards({ setPageID, setPageLink, fromCreate = false }) {
  //Countries form along with the state provinces table

  const location = useLocation();
  const history = useHistory();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //for button loading
  const [buttonLoading, setButtonLoading] = React.useState(false);

  //to display dialog before deleting
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //fetch data from redux
  const userSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.systemGenerated : ""
  );
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const roleName = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  //fetch data sent from previous page
  const countryID = fromURL
    ? decryptedObject
      ? decryptedObject.countryID
        ? decryptedObject.countryID
        : 0
      : 0
    : location.state
    ? location.state.countryID
      ? location.state.countryID
      : 0
    : 0;
  const [countryName, setCountryName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.countryName
          ? decryptedObject.countryName
          : 0
        : 0
      : location.state
      ? location.state.countryName
        ? location.state.countryName
        : ""
      : ""
  );
  const [openSnackBar, setOpenSnackBar] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.openSnackBar
          ? decryptedObject.openSnackBar
          : false
        : false
      : location.state?.openSnackBar
      ? true
      : false
  );

  const cancel = () => {
    //go back to system preferences tabs and ignore all changes
    history.push({
      pathname: "/system-preferences",
      state: {
        systemGenerated: userSystemGenerated,
        isLevven: companyType === "L" ? true : false,
        tab: 4,
      },
    });
  };

  //function delete
  const deleteCountry = () => {
    setButtonLoading(true);
    setDeleteRecord(false);
    const data = { Countries: [countryID] };
    countryServices
      .deleteCountries(data)
      .then((response) => {
        setButtonLoading(false);
        history.push({
          pathname: "/system-preferences",
          state: {
            systemGenerated: userSystemGenerated,
            isLevven: companyType === "L" ? true : false,
            tab: 1,
            success: true,
          },
        });
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };
  const countries = () => {
    //go back to countries tab in system preferences
    history.push({
      pathname: "/system-preferences",
      state: {
        systemGenerated: userSystemGenerated,
        isLevven: companyType === "L" ? true : false,
        tab: 1,
      },
    });
  };

  /*Redirection*/
  if (roleName !== "Levven Admin" || countryID === 0) {
    history.push("/Dashboard");
  }

  React.useEffect(() => {
    //for drawer menu select
    setPageID(-1);

    //for help button
    setPageLink("SystemConfig");
  }, []);

  return (
    <>
      {deleteRecord ? (
        <AlertDialogSlide
          title="Country Deletion"
          textContent="Are you sure you want to delete these records?"
          save={deleteCountry}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={() => setOpenSnackBar(false)}
        />
      ) : null}
      <Paper
        elevation={0}
        sx={{
          border: "1px rgba(86,87,89, 0.2) solid",
          borderRadius: "3px",
          padding: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="always"
              key="3"
              color="inherit"
              onClick={countries}
              sx={{
                fontSize: "24px",
                color: "#0077c6",
                cursor: "pointer",
              }}
            >
              Countries
            </Link>
            <Typography key="4" color="text.primary" sx={{ fontSize: "24px" }}>
              {countryName}
            </Typography>
          </Breadcrumbs>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CustomButton
              size="medium"
              startIcon={<Trash2 />}
              toggle={() => setDeleteRecord(true)}
              loading={buttonLoading}
            >
              Delete
            </CustomButton>
            <div style={{ width: "10px" }}></div>
            <CustomButton startIcon={<X />} toggle={cancel}>
              Cancel
            </CustomButton>
          </div>
        </div>

        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ borderBottom: "1px solid #969696", paddingBottom: "20px" }}
            >
              Country Information
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CountryForm create={false} countryID={countryID} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                borderBottom: "1px solid #969696",
                paddingBottom: "20px",
                marginBottom: "40px",
              }}
            >
              State/Province
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <StateProvinceTableComponent countryID={countryID} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
