import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertSnackBar({
  open,
  message,
  severity,
  handleClose,
}) {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" }}
        style={{
          backgroundColor: "#f9c7c7",
          color: "#7c2932",
          borderRadius: "3px",
          width: "100%",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
