import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the system data of the products */

const baseUrl = apiConfig + "/takeoffwizard";

const getTakeoffWizardList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetTakeoffWizard", data);
};

const deleteTakeoffWizard = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteTakeoffWizard", data);
};

const UpdateTakeoffWizardStepSequence = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateTakeoffWizardStepSequence", data);
};

const createTakeoffWizard = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateTakeoffWizard", data);
};

const updateTakeoffWizardData = (takeoffWizardID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateTakeoffWizard/" +  takeoffWizardID, data);
};

const getTakeoffByID = (takeoffWizardID) => {
  return fetchWrapper.get(baseUrl + "/GetTakeoffWizardByID/" +  takeoffWizardID);
};

export const takeoffWizardServices = {
  getTakeoffWizardList,
  deleteTakeoffWizard,
  UpdateTakeoffWizardStepSequence,
  createTakeoffWizard,
  updateTakeoffWizardData,
  getTakeoffByID
};
