import React, { useState, useEffect, useRef } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CircularProgress, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { helpTextServices } from "../../services/helpText.services";
import ReactHtmlParser, { domToReact } from "react-html-parser";
import "../../App.css";
import { useHistory } from "react-router-dom";
import CustomAlert from "../CustomAlert/CustomAlert";
import ScriptTag from "react-script-tag";
import { useSelector } from "react-redux";

const HelpPage = ({ setPageID }) => {
  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  const takeoffWiz = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoffWiz : ""
  );

  const clientCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientCompany : ""
  );

  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  const takeoff = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const orders = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const agreements = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const roleType = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const rma = useSelector((state) =>
    state.user.user ? state.user.user.user.role.rma : ""
  );

  const roleSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.role.systemGenerated : ""
  );

  const roleName = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  //boolean to open section for specific page in help
  const [openUsers, setOpenUsers] = useState(false);
  const [openRoles, setOpenRoles] = useState(false);
  const [openTakeoffs, setOpenTakeoffs] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openCompanies, setOpenCompanies] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openTakeoffSetup, setOpenTakeoffSetup] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openSystemConfig, setOpenSystemConfig] = useState(false);

  //holds text to display in help
  const [text, setText] = useState("");

  //holds which page is selected in the help
  const [selected, setSelected] = useState(0);

  //for page loading
  const [pageLoad, setPageLoad] = useState(true);

  //in case of error
  const [error, setError] = useState("");

  const userPages = [2, 12, 13];
  const systemConfigPages = [11, 15];

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const helpTextID = parseInt(params.get("source"));

  useEffect(() => {
    setPageID(-2);
    setError("");

    //assign selected part of section in help
    async function assignSelected() {
      if (helpTextID === 0) {
        setSelected(0);
      } else {
        setSelected(helpTextID);
      }
    }

    //function to get the help text
    async function getHelpText(id) {
      if (id === 0) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (
        ownUsers === "N" &&
        clientUsers === "N" &&
        (id === 2 || id === 12 || id === 13)
      ) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (ownRoles === "N" && clientRoles === "N" && id === 3) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (agreements === "N" && id === 4) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (takeoff === "N" && id === 5) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (orders === "N" && id === 6) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if ((clientCompany === "N" || companyType !== "L") && id === 7) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (takeoffWiz === "N" && id === 8) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (takeoffWiz === "N" && id === 9) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (
        (companyType !== "L" ||
          roleType !== "Levven Admin" ||
          roleSystemGenerated !== true) &&
        id === 10
      ) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (roleName !== "Levven Admin" && (id === 11 || id === 15)) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      if (rma === "N" && id === 18) {
        setPageLoad(false);
        setError("Invalid Help Section");
        return;
      }

      await helpTextServices
        .getHelpTextByID(id)
        .then(async (response) => {
          var result = response.data.text;
          setText(result);

          setPageLoad(false);
        })
        .catch(async (error) => {
          // setHelpError(true);
          await setError(error.response.data.error.error);

          setPageLoad(false);
        });
    }

    async function openSection(id) {
      if (userPages.includes(id)) {
        setOpenUsers(true);
      }

      if (systemConfigPages.includes(id)) {
        setOpenSystemConfig(true);
      }
    }

    assignSelected();

    openSection(helpTextID);

    if (!isNaN(helpTextID)) {
      getHelpText(helpTextID);
    } else {
      if (params.get("source") !== null) {
        setError("Invalid Help Section.");
        setPageLoad(false);
      } else {
        setPageLoad(false);
      }
    }
  }, [helpTextID]);

  //functions to handle click of specific section
  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickSystemConfig = () => {
    setOpenSystemConfig(!openSystemConfig);
  };

  //in case new help text is selected
  const selectHelpText = (id) => {
    window.open("/help?source=" + id + "", "_self");
  };
  return (
    <>
      {pageLoad ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress sx={{ color: "#0077c6" }} />
        </div>
      ) : (
        <>
          {" "}
          <Paper>
            <Box
              sx={{
                width: "100%",
                paddingTop: "30px",
                paddingRight: "30px",
                paddingBottom: "30px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={4}
                  sx={{
                    maxHeight: 800,
                    overflowY: "auto",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      borderRadius: "8px",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Avenue User Guide
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "#cde2f5",
                        },
                        "&:active": {
                          backgroundColor: "#fafafa",
                        },
                        marginTop: "2px",
                        backgroundColor: selected === 1 ? "#cde2f5" : "#fff",
                        borderRight:
                          selected === 1 ? "3px solid #0077c6" : "none",
                      }}
                      onClick={() => selectHelpText(1)}
                    >
                      <ListItemText primary="Dashboard" />
                    </ListItemButton>
                  </List>
                  {ownUsers !== "N" || clientUsers !== "N" ? (
                    <>
                      <ListItemButton
                        onClick={() => {
                          handleClickUsers();
                          selectHelpText(2);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 2 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 2 ? "3px solid #0077c6" : "none",
                        }}
                      >
                        <ListItemText primary="Users" />
                        {openUsers ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openUsers} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              "&:hover": {
                                backgroundColor: "#cde2f5",
                              },
                              "&:active": {
                                backgroundColor: "#fafafa",
                              },
                              marginTop: "2px",
                              backgroundColor:
                                selected === 12 ? "#cde2f5" : "#fff",
                              borderRight:
                                selected === 12 ? "3px solid #0077c6" : "none",
                            }}
                            onClick={() => selectHelpText(12)}
                          >
                            <ListItemText primary="Changing Password" />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              "&:hover": {
                                backgroundColor: "#cde2f5",
                              },
                              "&:active": {
                                backgroundColor: "#fafafa",
                              },
                              marginTop: "2px",
                              backgroundColor:
                                selected === 13 ? "#cde2f5" : "#fff",
                              borderRight:
                                selected === 13 ? "3px solid #0077c6" : "none",
                            }}
                            onClick={() => selectHelpText(13)}
                          >
                            <ListItemText primary="User Activation" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </>
                  ) : null}
                  {ownRoles !== "N" || clientRoles !== "N" ? (
                    <>
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 3 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 3 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(3)}
                      >
                        <ListItemText primary="Roles" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {agreements !== "N" ? (
                    <>
                      <ListItemButton
                        onClick={() => {
                          // handleClickAgreements();
                          selectHelpText(4);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 4 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 4 ? "3px solid #0077c6" : "none",
                        }}
                      >
                        <ListItemText primary="Agreements" />
                        {/* {openAgreements ? <ExpandLess /> : <ExpandMore />} */}
                      </ListItemButton>
                    </>
                  ) : null}

                  {/* <Collapse in={openAgreements} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{
                            pl: 4,
                            "&:hover": {
                              backgroundColor: "#cde2f5",
                            },
                            "&:active": {
                              backgroundColor: "#fafafa",
                            },
                            marginTop: "2px",
                            backgroundColor:
                              selected === 14 ? "#cde2f5" : "#fff",
                            borderRight:
                              selected === 14 ? "3px solid #0077c6" : "none",
                          }}
                          onClick={() => selectHelpText(14)}
                        >
                          <ListItemText primary="Default Controller Prices" />
                        </ListItemButton>
                      </List>
                    </Collapse> */}

                  {takeoff !== "N" ? (
                    <>
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 5 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 5 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(5)}
                      >
                        <ListItemText primary="Takeoffs" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {orders !== "N" ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 6 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 6 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(6)}
                      >
                        <ListItemText primary="Orders" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {clientCompany !== "N" && companyType === "L" ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 7 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 7 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(7)}
                      >
                        <ListItemText primary="Companies" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {takeoffWiz !== "N" ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 8 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 8 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(8)}
                      >
                        <ListItemText primary="Products" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {takeoffWiz !== "N" && companyType === "L" ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 9 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 9 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(9)}
                      >
                        <ListItemText primary="Takeoff Setup" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {companyType === "L" &&
                  roleType === "Levven Admin" &&
                  roleSystemGenerated === true ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 10 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 10 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(10)}
                      >
                        <ListItemText primary="Logs" />
                      </ListItemButton>
                    </>
                  ) : null}
                  {roleName === "Levven Admin" ? (
                    <>
                      {" "}
                      <ListItemButton
                        onClick={() => {
                          handleClickSystemConfig();
                          selectHelpText(11);
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 11 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 11 ? "3px solid #0077c6" : "none",
                        }}
                      >
                        <ListItemText primary="System Configurations" />
                        {openSystemConfig ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        in={openSystemConfig}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              "&:hover": {
                                backgroundColor: "#cde2f5",
                              },
                              "&:active": {
                                backgroundColor: "#fafafa",
                              },
                              marginTop: "2px",
                              backgroundColor:
                                selected === 15 ? "#cde2f5" : "#fff",
                              borderRight:
                                selected === 15 ? "3px solid #0077c6" : "none",
                            }}
                            onClick={() => selectHelpText(15)}
                          >
                            <ListItemText primary="Countries" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </>
                  ) : null}

                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "#cde2f5",
                      },
                      "&:active": {
                        backgroundColor: "#fafafa",
                      },
                      marginTop: "2px",
                      backgroundColor: selected === 16 ? "#cde2f5" : "#fff",
                      borderRight:
                        selected === 16 ? "3px solid #0077c6" : "none",
                    }}
                    onClick={() => selectHelpText(16)}
                  >
                    <ListItemText primary="How to use a List/Table" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "#cde2f5",
                      },
                      "&:active": {
                        backgroundColor: "#fafafa",
                      },
                      marginTop: "2px",
                      backgroundColor: selected === 17 ? "#cde2f5" : "#fff",
                      borderRight:
                        selected === 17 ? "3px solid #0077c6" : "none",
                    }}
                    onClick={() => selectHelpText(17)}
                  >
                    <ListItemText primary="Address Verification" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "#cde2f5",
                      },
                      "&:active": {
                        backgroundColor: "#fafafa",
                      },
                      marginTop: "2px",
                      backgroundColor: selected === 18 ? "#cde2f5" : "#fff",
                      borderRight:
                        selected === 18 ? "3px solid #0077c6" : "none",
                    }}
                    onClick={() => selectHelpText(18)}
                  >
                    <ListItemText primary="RMA" />
                  </ListItemButton>

                  {roleType === "Levven Admin" ? (
                    <>
                      {" "}
                      <ListItemButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#cde2f5",
                          },
                          "&:active": {
                            backgroundColor: "#fafafa",
                          },
                          marginTop: "2px",
                          backgroundColor: selected === 19 ? "#cde2f5" : "#fff",
                          borderRight:
                            selected === 19 ? "3px solid #0077c6" : "none",
                        }}
                        onClick={() => selectHelpText(19)}
                      >
                        <ListItemText primary="Deleted Orders" />
                      </ListItemButton>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={8}
                  sx={{
                    justifyContent: "center",
                    paddingLeft: "30px",
                  }}
                >
                  {error ? (
                    <>
                      {" "}
                      <div
                        style={{
                          textAlign: "center",
                          color: "red",
                        }}
                      >
                        <CustomAlert>{error}</CustomAlert>
                      </div>
                    </>
                  ) : (
                    <div>
                      <div>
                        {ReactHtmlParser(text, {
                          transform: function (node, index) {
                            if (
                              node.type === "script" &&
                              node.name === "script"
                            ) {
                              return (
                                <ScriptTag
                                  type={
                                    node.attribs
                                      ? node.attribs.type
                                        ? node.attribs.type
                                        : ""
                                      : ""
                                  }
                                  src={
                                    node.attribs
                                      ? node.attribs.src
                                        ? node.attribs.src
                                        : ""
                                      : ""
                                  }
                                  async={
                                    node.attribs
                                      ? node.attribs.async !== null
                                        ? true
                                        : false
                                      : false
                                  }
                                />
                              );
                            }
                            if (node.type === "img" && node.name === "img") {
                              return (
                                <img
                                  alt={
                                    node.attribs
                                      ? node.attribs.alt
                                        ? node.attribs.alt
                                        : ""
                                      : ""
                                  }
                                  className={
                                    node.attribs
                                      ? node.attribs.class
                                        ? node.attribs.class
                                        : ""
                                      : ""
                                  }
                                />
                              );
                            }
                          },
                        })}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};
export default HelpPage;
