import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Filter,
  Trash2,
  Plus,
  RotateCw,
  Clipboard,
  Edit3,
} from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TableFooter, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { companyServices } from "../../services/company.services";
import { takeoffServices } from "../../services/takeoff.services";
import { agreementServices } from "../../services/agreement.services";
import { orderServices } from "../../services/orders.service";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { accountServices } from "../../services/account.services";
import CustomModal from "../CustomModal/CustomModal";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { useReviewOrder } from "../../hooks/useReviewOrder";
import Underline from "../Underline/Underline";

const headCells = [
  {
    numeric: false,
    disablePadding: false,
    label: "Product No.",
    center: false,
    id: "ProductNumber",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Product Name",
    center: false,
    id: "ProductName",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Unit Price",
    center: true,
    id: "UnitPrice",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    center: true,
    id: "Quantity",
  },
  {
    numeric: true,
    disablePadding: false,
    label: "Total Price",
    center: true,
    id: "TotalPrice",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OrderReviewPage({
  orderID,
  orderType,
  currency,
  isRMA = false,
  fromTabs = false,
}) {
  const [
    pageLoad,
    numberRecords,
    rows,
    totalNumberSwitches,
    totalNumberPlatePositions,
    totalPriceOrder,
    error,
    emptyError,
  ] = useReviewOrder(orderID);

  return (
    <>
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") emptyError();
          }}
          open={error !== ""}
        />
      ) : null}
      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        {fromTabs === false ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h4" component="span">
                {isRMA === false ? "Review Your Order" : "Review Your RMA"}
              </Typography>
              <br />
              <Typography variant="h5" component="span">
                Confirm all quantities and prices.
              </Typography>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Paper
              sx={{
                mb: 2,
              }}
            >
              <TableContainer className={"table-container"}>
                <Table
                  stickyHeader
                  sx={{ width: "100%", whiteSpace: "nowrap" }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {pageLoad === true ? (
                      <TableRow>
                        <TableCell colSpan="5" style={{ textAlign: "center" }}>
                          <CircularProgress sx={{ color: "#0077c6" }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {numberRecords === 0 && pageLoad === false ? (
                      <TableRow>
                        <TableCell
                          colSpan="5"
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          It seems there are no products to display.
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {rows &&
                          rows.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.orderID + "-" + row.productID}
                              >
                                <TableCell>{row.product.partNbr}</TableCell>
                                <TableCell>{row.product.description}</TableCell>

                                <TableCell align="center">
                                  {currency === "CAD" ? "C$" : "$"}{" "}
                                  {row.priceChanged === true
                                    ? parseFloat(
                                        parseFloat(
                                          row.editedSwitchUnitPrice *
                                            row.switchQuantity
                                        ) + row.editedUnitPrice
                                      ).toFixed(2)
                                    : parseFloat(
                                        parseFloat(
                                          row.switchUnitPrice *
                                            row.switchQuantity
                                        ) + row.unitPrice
                                      ).toFixed(2)}
                                </TableCell>
                                <TableCell align="center">
                                  {row.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {currency === "CAD" ? "C$" : "$"}{" "}
                                  {parseFloat(row.total).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {rows.length !== 0 ? (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"Subtotal Row"}
                          >
                            <TableCell
                              style={{ fontWeight: "500", paddingLeft: "16px" }}
                            >
                              Subtotal:
                            </TableCell>
                            <TableCell></TableCell>

                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "500", paddingLeft: "16px" }}
                            >
                              {currency === "CAD" ? "C$" : "$"}{" "}
                              {totalPriceOrder}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {orderType === "Project Pack for Home" ? (
                <Grid container spacing={1} style={{ padding: "10px 0px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ fontWeight: "500", paddingLeft: "16px" }}
                  >
                    Total Number of Switches: {totalNumberSwitches}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ fontWeight: "500", paddingLeft: "16px" }}
                  >
                    Total Number of Switch Plate Positions:{" "}
                    {totalNumberPlatePositions}
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
