import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { helpTextServices } from "../../services/helpText.services";
import ReactQuill, { Quill } from "react-quill";
import CustomButton from "../CustomButton/CustomButton";
import { Save } from "react-feather";
import update from "immutability-helper";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import CustomAlert from "../CustomAlert/CustomAlert";
import { makeStyles } from "@mui/styles";
import CustomModal from "../CustomModal/CustomModal";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const useStyles = makeStyles((theme) => ({
  topDiv: {
    display: "flex",
    width: "100%",
    gap: "1em",

    "@media(max-width: 900px)": {
      display: "block",
    },
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    overflowX: "auto",
    gap: "1em",
    "@media(max-width: 500px)": {
      display: "block",
    },
  },
  buttonGrid2: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80px",
    gap: "1em",
    "@media(max-width: 500px)": {
      display: "block",
    },
  },
  interDiv: {
    display: "none",
    "@media(max-width: 500px)": {
      display: "block",
      height: "10px",
    },
  },
}));

const HelpTextEditor = ({ setFormChanged, setDirty, setPristine }) => {
  const classes = useStyles();

  //holds help text pages
  const [rows, setRows] = useState([]);

  //for page loading
  const [pageLoad, setPageLoad] = useState(false);

  //to hold errors
  const [error, setError] = useState("");
  const [saveError, setSaveError] = useState("");

  //holds which row has been selected in dropdown
  const [selectedRow, setSelectedRow] = useState(null);

  //holds text to change
  const [text, setText] = useState("");

  //for button loading
  const [buttonLoading, setButtonLoading] = useState(false);

  //to display snackbar on success
  const [success, setSuccess] = useState(false);

  //if change has happpened in a text
  const [changeHappened, setChangeHappened] = useState(false);

  //variable to parse when embedding video
  const [parseVideo, setParseVideo] = useState(null);

  //variable to display parsing of video
  const [insertVideo, setInsertVideo] = useState(false);

  //loading for parsing video
  const [parseLoading, setParseLoading] = useState(false);

  const [selectionIndex, setSelectionIndex] = useState(0);

  //In case of error while parsing video
  const [parseError, setParseError] = useState("");

  var helpTextRef = useRef();

  const displayCodeTextEditor = () => {
    setSelectionIndex(
      helpTextRef
        ? helpTextRef.current
          ? helpTextRef.current.editor.selection.savedRange.index
          : 0
        : 0
    );
    setInsertVideo((insertVideo) => !insertVideo);
  };

  //settings for text editor
  const modules = useMemo(
    () => ({
      toolbar: {
        handlers: {
          code: displayCodeTextEditor,
        },
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["clean"],
          ["link", "video"],
          ["code"],
        ],
      },
    }),
    []
  );

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
    "link",
    "video",
  ];

  const getListOfHelpText = async () => {
    //function to get list of help text
    setPageLoad(true);
    await helpTextServices
      .getListOfHelpTexts()
      .then((response) => {
        setRows(response.data);
        setPageLoad(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    setRows([]);
    setError("");

    getListOfHelpText();
  }, []);

  const updateCurrentList = async (textToUpdate) => {
    //we get the index of the object in the array that is being saved
    const index = await rows.findIndex(
      (x) => x.helpTextCode === selectedRow.helpTextCode
    );

    //we update the text attribute in the array at index 'index'
    await setRows(update(rows, { [index]: { text: { $set: textToUpdate } } }));
  };

  const saveHelpText = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    //function to save the help text in case of no errors

    if (selectedRow === null || text === null || text === "") {
      setButtonLoading(false);
      if (selectedRow === null) {
        setSaveError(
          "Make sure you selected the Help Text you wish to modify."
        );
        return;
      }

      if (text === null || text === "") {
        setSaveError("Help Text cannot be empty.");
        return;
      }
    } else {
      const data = { HelpTextCode: selectedRow.helpTextCode, Text: text };

      //we update the object to it's new value
      await updateCurrentList(text);

      //we update the value in the database
      await helpTextServices
        .updateHelpText(data)
        .then(() => {
          setSuccess(true);
          setChangeHappened(false);
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setPristine();
          setButtonLoading(false);
        })
        .catch((error) => {
          setSaveError(
            error.response.data
              ? error.response.data.error.error
              : "An Error has occurred while attempting to save."
          );
          setButtonLoading(false);
        });
    }
  };

  const parseCodeToVideo = async () => {
    setParseLoading(true);
    var tmpVariable = parseVideo;

    if (
      selectedRow === null ||
      selectedRow === undefined
    ) {
      setParseError("You have not selected a help section");
      setParseLoading(false);
      return;
    }
    if (
      tmpVariable === "" ||
      tmpVariable === null ||
      tmpVariable === undefined
    ) {
      setParseError("No code has been entered");
      setParseLoading(false);
      return;
    }

    if (helpTextRef.current) {
      tmpVariable = tmpVariable.replaceAll("\n", " ");

      helpTextRef.current.getEditor().insertText(selectionIndex, tmpVariable);
    } else {
      setParseError("Please select where you wish your code do be added");
      setParseLoading(false);
      return;
    }

    setParseLoading(false);
    setInsertVideo(false);
    setParseVideo(null);
  };
  return (
    <>
      {insertVideo ? (
        <CustomModal openModal={insertVideo}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h5" component="span">
                Embed Code
              </Typography>
            </Grid>
            {parseLoading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.buttonGrid2}
                >
                  <CircularProgress sx={{ color: "#0077c6" }} />
                  <div className={classes.interDiv}></div>
                  <Typography variant="body1" component="span">
                    Parsing your Code...
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    multiline
                    variant="outlined"
                    size="medium"
                    name="Code Parser"
                    key={"CodeParser"}
                    fullWidth
                    value={parseVideo}
                    onChange={(e) => setParseVideo(e.target.value)}
                    label="Insert Code Here"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.buttonGrid}
                >
                  <CustomButton
                    toggle={() => {
                      setInsertVideo(false);
                      setParseVideo(null);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div className={classes.interDiv}></div>
                  <CustomButton toggle={parseCodeToVideo}>
                    Insert Code
                  </CustomButton>
                </Grid>
              </>
            )}
          </Grid>
        </CustomModal>
      ) : null}
      {parseError ? (
        <AlertSnackBar
          message={parseError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setParseError("");
          }}
          open={parseError !== ""}
        />
      ) : null}
      <SnackBar
        open={success}
        message="Saved Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {saveError ? (
        <AlertDialogSlide
          title="Error"
          textContent={saveError}
          unsave={() => setSaveError("")}
          unsaveText="Close"
        />
      ) : (
        ""
      )}
      {pageLoad ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress sx={{ color: "#0077c6" }} />
        </div>
      ) : error ? (
        <div
          style={{
            textAlign: "center",
            color: "red",
          }}
        >
          <CustomAlert>{error}</CustomAlert>
        </div>
      ) : (
        <div>
          <form onSubmit={saveHelpText}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Autocomplete
                  id="combo-box-demo"
                  componentName="Field1"
                  sx={{ width: "100%", marginBottom: "1em" }}
                  options={rows}
                  value={selectedRow ? selectedRow : {}}
                  getOptionLabel={(option) =>
                    option.description ? option.description : ""
                  }
                  onChange={(event, value) => {
                    if (changeHappened) {
                      if (
                        window.confirm(
                          "You have not saved your changes. Are you sure you wish to continue?"
                        )
                      ) {
                        setSelectedRow(value);
                        setChangeHappened(false);
                        if (value === null) {
                          setText("");
                        } else {
                          setText(value.text);
                        }
                      }
                    } else {
                      setSelectedRow(value);
                      if (value === null) {
                        setText("");
                      } else {
                        setText(value.text);
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Help Text Description"
                      name="Field1"
                      fullWidth
                      disabled={buttonLoading}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReactQuill
                  theme="snow"
                  ref={helpTextRef}
                  readOnly={buttonLoading || selectedRow === null}
                  value={text}
                  onKeyDown={(e) => {
                    setChangeHappened(true);
                  }}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setText(e);
                  }}
                  modules={modules}
                  formats={formats}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  overflowX: "auto",
                }}
              >
                <CustomButton
                  startIcon={<Save />}
                  loading={buttonLoading}
                  type="submit"
                >
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
};

export default HelpTextEditor;
