import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, Grid } from "@mui/material";
import CustomAlert from "../CustomAlert/CustomAlert";
import OrderProducts from "../OrderProducts/OrderProducts";
import CustomButton from "../CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import { Save, Send, Trash2, X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divStyle2: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
  buttonStyle2: {
    display: "flex",
    textAlign: "right",
    flexDirection: "row",
    justifyContent: "flex-end",
    overflow: "auto",

    "@media(max-width: 1000px)": {
      display: "block",
    },
  },
}));

export default function ProductQuantitiesAccordion({
  rows,
  setRows,
  defControllerQty,
  setDefControllerQty,
  isCompPP,
  steps,
  isRMA = false,
  saveQuantities,
  pageLoad = true,
  setPristine,
  error = "",
  totalSwitches,
  defaultPriceRanges,
  rolePermission,
  orderStatusInTabs,
  totalWallPlates,
  priceResetLoading,
  setPriceResetLoading,
  defUPC,
  setGetError,
  totalOrderPrice,
  openedAccordion,
  openDrawerMenu,
  buttonLoading,
  setDeleteRecord,
  setOpenedAccordion,
  setTotalOrderPrice,
  setTotalSwitches,
  orderType,
  maxDimmer = 0,
  defaultController,
  getPriceByRange,
  setChangeMade,
  specialNegotiated,
  setTotalWallPlates,
  changeMade,
  loading,
  totalQuickMount,
  setTotalQuickMount,
  isLevvenAdmin,
  currency,
  setFormChanged,
  opened = [],
  setOpened,
  setDirty,
  orderID,
  getError,
  submitLoading,
  purchaseOrderNumberInTabs,
  requestedDateInTabs,
  setSubmissionWarningOpen,
  setShowWarning3,
  submitOrderPermission,
  setCanSubmitAlert,
  setCanSubmitModal,
  companyTypeForWarningCheck,
  isReseller = false,
  orderVersion,
}) {
  /**Get Company type */
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const classes = useStyles();

  const [changesMade, setChangesMade] = React.useState(false);

  const history = useHistory();

  var refInput = React.useRef(null);
  var refTextField = React.useRef(null);

  //to re-render page
  React.useEffect(() => {
    setOpenedAccordion(openedAccordion);
  }, [changesMade]);

  //to handle which accordion sections to open and close when user clicks view all and close all (which accordions have values)
  const handleAccordionOpen = (index) => {
    if (openedAccordion.includes(index)) {
      var id = openedAccordion.indexOf(index);
      openedAccordion.splice(id, 1);
    } else {
      setOpenedAccordion((prevOpenedAccordion) => [
        ...prevOpenedAccordion,
        index,
      ]);
    }
  };

  //to solve cursor bug where the cursor wuold return to start of line on input of edit price
  React.useEffect(() => {
    if (
      refInput.current !== undefined &&
      refInput.current !== null &&
      refTextField.current !== null &&
      refTextField.current !== undefined
    ) {
      refTextField.current.setSelectionRange(
        refInput.current,
        refInput.current
      );
    }
  }, [rows]);

  return pageLoad === true ? (
    <div style={{ textAlign: "center" }}>
      <CircularProgress sx={{ color: "#0077c6" }} />
    </div>
  ) : error ? (
    <div
      style={{
        textAlign: "center",
        color: "red",
        marginTop: "50px",
        marginBottom: "30px",
      }}
    >
      {error !== "" ? <CustomAlert>{error}</CustomAlert> : null}
    </div>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={11} md={12} lg={12}>
          <div>
            {steps.map((step, index) => {
              return (
                <Accordion
                  key={step + "-" + index}
                  elevation={0}
                  expanded={openedAccordion.includes(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={step + "-content"}
                    id={step + "-id"}
                    onClick={async () => {
                      await handleAccordionOpen(index);
                      setChangesMade(!changesMade);
                    }}
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <Typography style={{ paddingLeft: "40px" }}>
                      {step}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      marginTop: "-10px",
                      marginBottom: "-10px",
                      marginLeft: "-16px",
                      marginRight: "-16px",
                    }}
                  >
                    <OrderProducts
                      isCompPP={isCompPP}
                      isLevvenAdmin={isLevvenAdmin}
                      rows={rows[index]}
                      allRows={rows}
                      activeStep={index}
                      titleText={step}
                      orders={true}
                      orderVersion={orderVersion}
                      defaultController={defaultController}
                      getPriceByRange={getPriceByRange}
                      totalSwitches={totalSwitches}
                      defUPC={defUPC}
                      isAssembly={
                        rows[index][0]
                          ? rows[index][0].product.category.categoryCode === 1
                            ? true
                            : false
                          : false
                      }
                      isSwitch={
                        rows[index][0]
                          ? rows[index][0].product.category.categoryCode === 3
                            ? true
                            : false
                          : false
                      }
                      setTotalSwitchesOrder={setTotalSwitches}
                      defaultPriceRanges={defaultPriceRanges}
                      currency={currency}
                      refInput={refInput}
                      setChangeMade={setChangeMade}
                      totalOrderPrice={totalOrderPrice}
                      setTotalOrderPrice={setTotalOrderPrice}
                      setGetError={setGetError}
                      specialNegotiated={specialNegotiated}
                      setDirty={setDirty}
                      orderStatusInTabs={orderStatusInTabs}
                      changeMade={changeMade}
                      totalWallPlates={totalWallPlates}
                      setTotalWallPlatesOrder={setTotalWallPlates}
                      orderType={orderType}
                      totalQuickMount={totalQuickMount}
                      defControllerQty={defControllerQty}
                      setDefControllerQty={setDefControllerQty}
                      refTextField={refTextField}
                      rolePermission={rolePermission}
                      setTotalQuickMount={setTotalQuickMount}
                      setFormChanged={setFormChanged}
                      maxDimmer={maxDimmer}
                      setOpenedAccordion={setOpenedAccordion}
                      priceResetLoading={priceResetLoading}
                      setPriceResetLoading={setPriceResetLoading}
                      openedAccordion={openedAccordion}
                      orderID={orderID}
                      opened={opened}
                      setOpened={setOpened}
                      setRows={setRows}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.buttonStyle2}
        >
          {getError === "" &&
          rolePermission === "M" &&
          (orderStatusInTabs === "N" ||
            orderStatusInTabs === "D" ||
            orderStatusInTabs === "F") ? (
            <>
              {" "}
              <CustomButton
                startIcon={<Send />}
                loading={submitLoading}
                toggle={async () => {
                  if (
                    purchaseOrderNumberInTabs === "" ||
                    requestedDateInTabs === "" ||
                    requestedDateInTabs === "Invalid Date Format"
                  ) {
                    setSubmissionWarningOpen(true);
                    return;
                  } else {
                    if (
                      totalQuickMount !== totalWallPlates &&
                      isRMA === false &&
                      isReseller === false
                    ) {
                      setShowWarning3(true);
                      return;
                    }
                    submitOrderPermission
                      ? setCanSubmitAlert(true)
                      : setCanSubmitModal(true);
                  }
                }}
              >
                {submitOrderPermission
                  ? isRMA === false
                    ? "Place Order"
                    : "Place RMA"
                  : "Send to Purchasing for Approval"}
              </CustomButton>
            </>
          ) : null}
          {pageLoad ||
          rolePermission !== "M" ||
          (orderStatusInTabs !== "N" && orderStatusInTabs !== "D") ? null : (
            <>
              <div className={classes.divStyle2} />
              <CustomButton
                startIcon={<Save />}
                loading={buttonLoading}
                toggle={() => saveQuantities(false)}
              >
                Save
              </CustomButton>
            </>
          )}
          {(orderStatusInTabs === "N" || orderStatusInTabs === "D") &&
          rolePermission === "M" ? (
            <>
              <div className={classes.divStyle2} />
              <CustomButton
                startIcon={<Trash2 />}
                loading={loading}
                toggle={() => setDeleteRecord(true)}
              >
                Delete
              </CustomButton>
            </>
          ) : null}
          <div className={classes.divStyle2} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await setFormChanged(false);
              await sessionStorage.removeItem("unsavedChanges");

              await setPristine();
              history.push(isRMA === false ? "/orders" : "/RMA");
            }}
          >
            Cancel
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
}
