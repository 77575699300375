import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { PlusCircle, X } from "react-feather";
import update from "immutability-helper";

const headCells = [
  {
    id: "changeProductFrom",
    numeric: false,
    disablePadding: false,
    label: "Change From",
    addDefaultSwitch: true,
  },
  // {
  //   id: "changeProductFrom2",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "",
  //   addDefaultSwitch: false,
  // },
  {
    id: "changeProductTo",
    numeric: false,
    disablePadding: false,
    label: "Ugrade To",
    addDefaultSwitch: false,
  },
  {
    id: "changeProductTo2",
    numeric: false,
    disablePadding: false,
    label: "",
    addDefaultSwitch: false,
  },
  {
    id: "maximumAllowed",
    numeric: true,
    disablePadding: false,
    label: "Max Quantity",
    addDefaultSwitch: false,
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
    addDefaultSwitch: false,
  },
  {
    id: "more/less",
    numeric: false,
    disablePadding: false,
    label: "More/Less",
    addDefaultSwitch: false,
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
            {/* {headCell.addDefaultSwitch === true
              ? props.defaultSwitch !== null && props.defaultSwitch !== {}
                ? props.defaultSwitch.product.switch.switchStyle.description +
                  " " +
                  props.defaultSwitch.product.switch.switchColor.description
                : null
              : null} */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ChangeProductsTable({
  arrayFrom = [],
  arrayTo = [],
  setDirty,
  selectedFrom = {},
  selectedTo = {},
  setSelectedFrom,
  setSelectedTo,
  maxQuantity,
  rows = [],
  title = "",
  setRows,
  defaultSwitch,
  arrayFrom2,
  arrayTo2,
  pageLoad,
  valueToAdd,
  agreementDefaultSwitch,
  selectedFrom2,
  selectedTo2,
  setSelectedFrom2,
  setSelectedTo2,
  fromTabs = false,
  setFormChanged,
  setValueToAdd,
}) {
  const [itemsToBeDisabled, setItemsToBeDisabled] = useState([]);

  /**-----------------------Functions that handle the disabled values in the From dropdown */

  function addValueToBeDisabled(
    Color,
    Style,
    Controller,
    SwitchAssemblyType,
    Index
  ) {
    //Add Object to Array so we can check it
    const disableItem = {
      Index,
      Color, //switchColorCode
      Style, //switchStyleCode
      Controller, //ControllerTypeCode
      SwitchAssemblyType, //SwitchAssemblyTypeCode
    };

    setItemsToBeDisabled((items) => [...items, disableItem]);
  }

  function checkShouldDisable(Controller, SwitchAssemblyType) {
    if (defaultSwitch === null || defaultSwitch === undefined) return false;

    //We check if any object in the array satisfies the condition we return true so it gets disbaled else if not we return false
    const result = itemsToBeDisabled.filter(
      (x) =>
        x.Color === defaultSwitch.product.switch.switchColor.switchColorCode &&
        x.Style === defaultSwitch.product.switch.switchStyle.switchStyleCode &&
        x.Controller === Controller &&
        x.SwitchAssemblyType === SwitchAssemblyType
    );
    if (result.length === 0) return false;
    else return true;
  }

  function deleteValueToBeDisabled(IndexToSearch) {
    //we check if Item found in array we get it's index
    //returns -1 if item not found
    //and we delete it if found
    const index = itemsToBeDisabled.findIndex((x) => x.Index === IndexToSearch);
    if (index !== -1) {
      var temporaryArray = itemsToBeDisabled;
      temporaryArray.splice(index, 1);
      setItemsToBeDisabled(temporaryArray);
    } else return;
  }

  async function updateValuesToBeDisabled(
    Index,
    Color,
    Style,
    Controller,
    SwitchAssemblyType
  ) {
    //we check if value in the add section of the make changes/additions, we remove it from there
    if (
      defaultSwitch !== null &&
      defaultSwitch !== undefined &&
     
      selectedFrom !== null &&
      selectedFrom !== undefined &&
   
      defaultSwitch.product.switch.switchColor.switchColorCode === Color &&
      defaultSwitch.product.switch.switchStyle.switchStyleCode === Style &&
      selectedFrom?.product?.controller.controllerTypeCode === Controller &&
      selectedFrom?.product?.switchAssemblyType.switchAssemblyTypeCode ===
        SwitchAssemblyType
    ) {
      setSelectedFrom(null);
    }

    //We get the index of the object we wish to update and we update it
    const tmpIndex = itemsToBeDisabled.findIndex((x) => x.Index === Index);

    //we update the array and get a copy of it
    var result = update(itemsToBeDisabled, {
      [tmpIndex]: {
        Color: { $set: Color },
        Style: { $set: Style },
        Controller: { $set: Controller },
        SwitchAssemblyType: { $set: SwitchAssemblyType },
      },
    });

    // we set the array to the updated value
    setItemsToBeDisabled(result);
  }

  /**------------------------------------------------------------------------------------- */

  const [changeHappened, setChangeHappened] = useState(false);

  const addValues = () => {
    if (
   
      selectedFrom !== null &&
 
      selectedTo !== null &&
     
      selectedFrom2 !== null &&
  
      selectedTo2 !== null &&
      valueToAdd !== null &&
      valueToAdd !== ""
    ) {
      const maxLength = rows.length;

      setRows((prevRows) => [
        ...prevRows,
        {
          From: selectedFrom,
          To: selectedTo,
          FromSwitchStyleColor: selectedFrom2,
          ToSwitchStyleColor: selectedTo2,
          quantity: valueToAdd,
        },
      ]);

      addValueToBeDisabled(
        selectedTo2.switchColorCode,
        selectedTo2.switchStyleCode,
        selectedTo.controllerTypeCode,
        selectedTo.switchAssemblyTypeCode,
        maxLength
      );

      setValueToAdd("");
      setSelectedFrom(null);
      setSelectedTo(null);
      setSelectedFrom2(null);
      setSelectedTo2(null);
      setChangeHappened(!changeHappened);
    }
  };

  const deleteValue = (index) => {
    var tmp = rows;
    tmp.splice(index, 1);
    deleteValueToBeDisabled(index);
    setRows(tmp);
    setChangeHappened(!changeHappened);
  };

  const getSwitchAssemblyType = (code) => {
    if (code === 1) {
      return "Single-pole";
    } else if (code === 2) {
      return "3-way";
    } else if (code === 3) {
      return "4-way";
    } else {
      return "";
    }
  };

  const getControllerType = (code) => {
    if (code === 1) {
      return "On/off";
    } else if (code === 2) {
      return "Dimmer";
    } else if (code === 3) {
      return "Convertible";
    } else {
      return "";
    }
  };

  return (
    <>
      {pageLoad ? (
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </div>
      ) : (
        <>
          {" "}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              {title !== "" ? (
                <>
                  <Toolbar
                    sx={{
                      overflow: "auto",
                      pl: { sm: 2 },
                      pr: { xs: 1, sm: 1 },
                    }}
                  >
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      {title}
                    </Typography>
                  </Toolbar>
                </>
              ) : null}
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead defaultSwitch={defaultSwitch} />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {/* <TableCell width={"17%"}>
                            <Autocomplete
                              id="quantityFrom"
                              options={arrayFrom2}
                              error={
                                rows[index].FromSwitchStyleColor === {} ||
                                rows[index].FromSwitchStyleColor === null
                              }
                              getOptionLabel={(option) =>
                                option.product.switch.switchStyle.description +
                                " " +
                                option.product.switch.switchColor.description
                              }
                              // getOptionDisabled={(option) =>
                              //   rows[index].To?.controllerTypeCode ===
                              //     option.product.controller
                              //       .controllerTypeCode &&
                              //   rows[index].To?.switchAssemblyTypeCode ===
                              //     option.product.switchAssemblyType
                              //       .switchAssemblyTypeCode
                              // }
                              value={rows[index].FromSwitchStyleColor}
                              onChange={(event, value) => {
                                setDirty(); setFormChanged(true);
                                sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                                var tmp = rows;
                                tmp[index].FromSwitchStyleColor = value;
                                setRows(tmp);
                                setChangeHappened(!changeHappened);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Change From"
                                  fullWidth
                                  error={rows[index].FromSwitchStyleColor === null}
                                  autoComplete="off"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                  size="small"
                                />
                              )}
                            />
                          </TableCell> */}
                          <TableCell width={"17%"}>
                            <Autocomplete
                              id="quantityFrom"
                              options={arrayFrom}
                              error={
                              
                                rows[index].From === null
                              }
                              getOptionLabel={(option) =>
                                option.isDimmer
                                  ? getSwitchAssemblyType(
                                      option.product.switchAssemblyType
                                        .switchAssemblyTypeCode
                                    ) +
                                    " " +
                                    getControllerType(
                                      option.product.controller
                                        .controllerTypeCode
                                    ) +
                                    " (at the price of On/off)"
                                  : getSwitchAssemblyType(
                                      option.product.switchAssemblyType
                                        .switchAssemblyTypeCode
                                    ) +
                                    " " +
                                    getControllerType(
                                      option.product.controller
                                        .controllerTypeCode
                                    )
                              }
                              getOptionDisabled={(option) =>
                                rows[index].To?.controllerTypeCode ===
                                  option.product.controller
                                    .controllerTypeCode &&
                                rows[index].To?.switchAssemblyTypeCode ===
                                  option.product.switchAssemblyType
                                    .switchAssemblyTypeCode
                              }
                              value={rows[index].From}
                              onChange={(event, value) => {
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                                var tmp = rows;
                                tmp[index].From = value;
                                tmp[index].FromSwitchStyleColor = value;
                                setRows(tmp);
                                setChangeHappened(!changeHappened);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Change From"
                                  fullWidth
                                  error={rows[index].From === null}
                                  autoComplete="off"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                  size="small"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell width={"17%"}>
                            <Autocomplete
                              id="quantityTo2"
                              options={arrayTo2}
                              getOptionLabel={(option) =>
                                option.description ? option.description : ""
                              }
                              value={rows[index].ToSwitchStyleColor}
                              error={
                               
                                rows[index].ToSwitchStyleColor === null
                              }
                              onChange={(event, value) => {
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                                updateValuesToBeDisabled(
                                  index,
                                  value.switchColorCode,
                                  value.switchStyleCode,
                                  rows[index].To.controllerTypeCode,
                                  rows[index].To.switchAssemblyTypeCode
                                );
                                var tmp = rows;
                                tmp[index].ToSwitchStyleColor = value;
                                if (
                                  rows[index].To?.controllerTypeCode ===
                                    rows[index].From?.product?.controller
                                      .controllerTypeCode &&
                                  rows[index].To?.switchAssemblyTypeCode ===
                                    rows[index].From?.product
                                      ?.switchAssemblyType
                                      .switchAssemblyTypeCode
                                ) {
                                  tmp[index].To = null;
                                }

                                setRows(tmp);
                                setChangeHappened(!changeHappened);
                              }}
                              // getOptionDisabled={(option) =>
                              //   option.switchStyleCode ===
                              //     defaultSwitch?.product?.switch.switchStyle
                              //       .switchStyleCode &&
                              //   option.switchColorCode ===
                              //     defaultSwitch?.product?.switch.switchColor
                              //       .switchColorCode
                              // }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="To"
                                  fullWidth
                                  error={
                                    rows[index].ToSwitchStyleColor === null
                                  }
                                  autoComplete="off"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                  size="small"
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell width={"17%"}>
                            <Autocomplete
                              id="quantityTo"
                              options={arrayTo}
                              getOptionLabel={(option) =>
                                option.description ? option.description : ""
                              }
                              value={rows[index].To}
                              error={
                              rows[index].To === null
                              }
                              onChange={(event, value) => {
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                                var tmp = rows;
                                tmp[index].To = value;

                                updateValuesToBeDisabled(
                                  index,
                                  rows[index].ToSwitchStyleColor
                                    .switchColorCode,
                                  rows[index].ToSwitchStyleColor
                                    .switchStyleCode,
                                  value.controllerTypeCode,
                                  value.switchAssemblyTypeCode
                                );

                                setRows(tmp);
                                setChangeHappened(!changeHappened);
                              }}
                              getOptionDisabled={(option) =>
                                option.controllerTypeCode ===
                                  rows[index].From?.product?.controller
                                    .controllerTypeCode &&
                                option.switchAssemblyTypeCode ===
                                  rows[index].From?.product?.switchAssemblyType
                                    .switchAssemblyTypeCode &&
                                rows[index].ToSwitchStyleColor !== null &&
                               
                                rows[index].ToSwitchStyleColor !== undefined &&
                                defaultSwitch !== null &&
                        
                                rows[index].ToSwitchStyleColor
                                  .switchColorCode ===
                                  defaultSwitch.product.switch.switchColor
                                    .switchColorCode
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="To"
                                  fullWidth
                                  error={rows[index].To === null}
                                  autoComplete="off"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                  size="small"
                                />
                              )}
                            />
                          </TableCell>

                          <TableCell width={"17%"} align="center">
                            <Typography
                              variant="body2"
                              component="span"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {rows[index]
                                ? rows[index].From
                                  ? rows[index].From.product.switchAssemblyType
                                      .switchAssemblyTypeCode === 1 &&
                                    rows[index].From.product.controller
                                      .controllerType.controllerTypeCode === 1
                                    ? rows[index].From.order.takeoff.agreement
                                        .dimmerAtControllerPrice
                                      ? "" +
                                        rows[index].From.quantity +
                                        "\n(Original quantity=" +
                                        maxQuantity +
                                        ", Dimmers at the price of on/off=" +
                                        (maxQuantity -
                                          rows[index].From.quantity) +
                                        ")"
                                      : rows[index].From.quantity
                                    : rows[index].From.quantity
                                  : null
                                : null}
                            </Typography>
                          </TableCell>
                          <TableCell width={"17%"} align={"center"}>
                            <TextField
                              inputProps={{
                                style: { textAlign: "right" },
                                min: 0,
                              }}
                              error={
                                rows[index].quantity === "" ||
                                rows[index].quantity === null ||
                                parseInt(rows[index].quantity) >
                                  parseInt(rows[index].From.quantity)
                              }
                              variant="standard"
                              type="number"
                              placeholder="Quantity"
                              value={rows[index].quantity}
                              onChange={(e) => {
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                                var tmp = rows;
                                if (e.target.value === "") {
                                  tmp[index].quantity = null;
                                } else {
                                  tmp[index].quantity = parseInt(
                                    e.target.value
                                  );
                                }
                                setRows(tmp);
                                setChangeHappened(!changeHappened);
                              }}
                            />
                          </TableCell>
                          <TableCell width={"12%"}>
                            <IconButton onClick={() => deleteValue(index)}>
                              <X />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow key={"rowToAdd"} sx={{ cursor: "pointer" }}>
                      {/* <TableCell width={"17%"}>
                        <Autocomplete
                          id="quantityFromAdd2"
                          options={arrayFrom2}
                          getOptionLabel={(option) =>
                            arrayFrom2.length > 0
                              ? option.product.switch.switchStyle.description +
                                " " +
                                option.product.switch.switchColor.description
                              : null
                          }
                          // getOptionDisabled={(option) => {
                          //   if (
                          //     selectedTo?.controllerTypeCode ===
                          //       option.product.controller.controllerTypeCode &&
                          //     selectedTo?.switchAssemblyTypeCode ===
                          //       option.product.switchAssemblyType
                          //         .switchAssemblyTypeCode
                          //   ) {
                          //     return true;
                          //   }

                          //   if (
                          //     itemsToBeDisabled.includes(
                          //       getSwitchAssemblyType(
                          //         option.product?.switchAssemblyType
                          //           ?.switchAssemblyTypeCode
                          //       ) +
                          //         " " +
                          //         getControllerType(
                          //           option.product?.controller
                          //             ?.controllerTypeCode
                          //         )
                          //     )
                          //   ) {
                          //     return true;
                          //   }
                          // }}
                          value={selectedFrom2}
                          onChange={(event, value) => {
                            setDirty(); setFormChanged(true);
                            sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            if (fromTabs) {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                            setSelectedFrom2(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="From"
                              fullWidth
                              autoComplete="off"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              size="small"
                            />
                          )}
                        />
                      </TableCell> */}
                      <TableCell width={"17%"}>
                        <Autocomplete
                          id="quantityFromAdd"
                          options={arrayFrom}
                          getOptionLabel={(option) =>
                            arrayFrom.length > 0
                              ? option.isDimmer
                                ? getSwitchAssemblyType(
                                    option?.product?.switchAssemblyType
                                      ?.switchAssemblyTypeCode
                                  ) +
                                  " " +
                                  getControllerType(
                                    option?.product?.controller
                                      ?.controllerTypeCode
                                  ) +
                                  " (at the price of On/off)"
                                : getSwitchAssemblyType(
                                    option.product.switchAssemblyType
                                      .switchAssemblyTypeCode
                                  ) +
                                  " " +
                                  getControllerType(
                                    option.product.controller.controllerTypeCode
                                  )
                              : null
                          }
                          getOptionDisabled={(option) => {
                            if (
                              selectedTo?.controllerTypeCode ===
                                option.product.controller.controllerTypeCode &&
                              selectedTo?.switchAssemblyTypeCode ===
                                option.product.switchAssemblyType
                                  .switchAssemblyTypeCode
                            ) {
                              return true;
                            }

                            if (
                              checkShouldDisable(
                                option.product.controller.controllerTypeCode,
                                option.product.switchAssemblyType
                                  .switchAssemblyTypeCode
                              )
                            ) {
                              return true;
                            }
                          }}
                          value={selectedFrom}
                          onChange={async (event, value) => {
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            if (fromTabs) {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }

                            var defaultSwitchIndex = await arrayTo2.findIndex(
                              (x) =>
                                x.switchStyleCode ===
                                  agreementDefaultSwitch?.style &&
                                x.switchColorCode ===
                                  agreementDefaultSwitch?.color
                            );

                            setSelectedFrom(value);
                            setSelectedFrom2(value);
                            if (defaultSwitch !== -1)
                              setSelectedTo2(arrayTo2[defaultSwitchIndex]);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="From"
                              fullWidth
                              autoComplete="off"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell width={"17%"}>
                        <Autocomplete
                          id="quantityToAdd2"
                          options={arrayTo2}
                          getOptionLabel={(option) =>
                            arrayTo2.length > 0
                              ? option.description
                                ? option.switchStyleCode ===
                                    agreementDefaultSwitch?.style &&
                                  option.switchColorCode ===
                                    agreementDefaultSwitch?.color
                                  ? option.description + " (Default)"
                                  : option.description
                                : ""
                              : null
                          }
                          value={selectedTo2}
                          onChange={(event, value) => {
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            if (
                              selectedTo?.controllerTypeCode ===
                                selectedFrom?.product?.controller
                                  .controllerTypeCode &&
                              selectedTo?.switchAssemblyTypeCode ===
                                selectedFrom?.product?.switchAssemblyType
                                  .switchAssemblyTypeCode
                            ) {
                              setSelectedTo(null);
                            }
                            setSelectedTo2(value);

                            if (fromTabs) {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          // getOptionDisabled={(option) =>
                          //   option.switchStyleCode ===
                          //     defaultSwitch?.product?.switch.switchStyle
                          //       .switchStyleCode &&
                          //   option.switchColorCode ===
                          //     defaultSwitch?.product?.switch.switchColor
                          //       .switchColorCode
                          // }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="To"
                              fullWidth
                              autoComplete="off"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell width={"17%"}>
                        <Autocomplete
                          id="quantityToAdd"
                          options={arrayTo}
                          getOptionLabel={(option) =>
                            arrayTo.length > 0
                              ? option.description
                                ? option.description
                                : ""
                              : null
                          }
                          value={selectedTo}
                          onChange={(event, value) => {
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            setSelectedTo(value);
                            if (fromTabs) {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          getOptionDisabled={(option) =>
                            option.controllerTypeCode ===
                              selectedFrom?.product?.controller
                                .controllerTypeCode &&
                            option.switchAssemblyTypeCode ===
                              selectedFrom?.product?.switchAssemblyType
                                .switchAssemblyTypeCode &&
                            selectedTo2 !== null &&
                          
                            selectedTo2 !== undefined &&
                            defaultSwitch !== null &&
                            
                            selectedTo2.switchColorCode ===
                              defaultSwitch.product.switch.switchColor
                                .switchColorCode
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="To"
                              fullWidth
                              autoComplete="off"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              size="small"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell width={"17%"} align="center">
                        <Typography
                          variant="body2"
                          component="span"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {selectedFrom
                            ? selectedFrom.product.switchAssemblyType
                                .switchAssemblyTypeCode === 1 &&
                              selectedFrom.product.controller.controllerType
                                .controllerTypeCode === 1
                              ? selectedFrom.order.takeoff.agreement
                                  .dimmerAtControllerPrice
                                ? "" +
                                  selectedFrom.quantity +
                                  "\n(Original quantity=" +
                                  maxQuantity +
                                  ", Dimmers at the price of on/off=" +
                                  (maxQuantity - selectedFrom.quantity) +
                                  ")"
                                : selectedFrom.quantity
                              : selectedFrom.quantity
                            : null}
                        </Typography>
                      </TableCell>
                      <TableCell width={"17%"} align={"center"}>
                        <TextField
                          inputProps={{
                            style: { textAlign: "right" },
                            min: 0,
                          }}
                          variant="standard"
                          type="number"
                          placeholder="Quantity"
                          value={valueToAdd}
                          onChange={(e) => {
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            if (fromTabs) {
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                            setValueToAdd(parseInt(e.target.value));
                          }}
                        />
                      </TableCell>
                      <TableCell width={"12%"}>
                        <IconButton onClick={addValues}>
                          <PlusCircle />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
