import CryptoJS from "crypto-js";

const securityKey = "F4aOOONXLX5n4ALgeCn2ouXzsk3gVwFf";

export const encryptObject = async (data) => {
  //We stringify the object so we can encrypt it
  const objectAsString = JSON.stringify(data);

  var encryptedData = await CryptoJS.AES.encrypt(
    objectAsString,
    securityKey
  ).toString();

  return encryptedData;
};

export const decryptObject = (data) => {
  //we decrypt the data

  var decryptedUrlData = decodeURIComponent(data);

  var decryptedData = CryptoJS.AES.decrypt(
    decryptedUrlData,
    securityKey
  ).toString(CryptoJS.enc.Utf8);
  var receivedObject = null;

  try {
    receivedObject = JSON.parse(decryptedData);
  } catch (error) {
    return {
      error: true,
      returnObject: null,
    };
  }

  if (receivedObject === null || typeof receivedObject !== "object") {
    return {
      error: true,
      returnObject: null,
    };
  }

  return {
    error: false,
    returnObject: receivedObject,
  };
};

export const encryptLocalStorageObject = async (data) => {
  //We stringify the object so we can encrypt it
  const objectAsString = JSON.stringify(data);

  var encryptedData = await CryptoJS.AES.encrypt(
    objectAsString,
    securityKey
  ).toString();

  return encryptedData;
};

export const decryptLocalStorageObject = (data) => {
  //we decrypt the data

  var decryptedUrlData = data;

  var decryptedData = CryptoJS.AES.decrypt(
    decryptedUrlData,
    securityKey
  ).toString(CryptoJS.enc.Utf8);
  var receivedObject = null;

  try {
    receivedObject = JSON.parse(decryptedData);
  } catch (error) {
    return {
      error: true,
      returnObject: null,
    };
  }

  if (receivedObject === null || typeof receivedObject !== "object") {
    return {
      error: true,
      returnObject: null,
    };
  }

  return {
    error: false,
    returnObject: receivedObject,
  };
};
