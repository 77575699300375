import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import UserForm from "../UserForm/UserForm";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import UserDevices from "../UserDevices/UserDevices";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import {
  RefreshCcw,
  Save,
  Trash2,
  UserCheck,
  UserPlus,
  UserX,
  X,
} from "react-feather";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";
import { adminServices } from "../../services/admin.services";
import SnackBar from "../SnackBar/SnackBar";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import ChangeUserPasswordForm from "../ChangeUserPasswordForm/ChangeUserPasswordForm";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import { accountServices } from "../../services/account.services";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserTabs({ setPageID, setPageLink, location }) {
  const history = useHistory();
  // const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;
  console.log("location", location);
  console.log("fromURL", fromURL);
  console.log("decryptedObject", decryptedObject);
  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //for security

  const [locationToReceive, setLocationToReceive] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.locationToReceive
          ? decryptedObject.locationToReceive
          : null
        : null
      : location.state
      ? location.state.locationToReceive
        ? location.state.locationToReceive
        : null
      : null
  );
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const [userID, setUserID] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.userID
          ? decryptedObject.userID
          : 0
        : 0
      : location.state
      ? location.state.userID
      : 0
  );
  const [companyID, setCompanyID] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.companyID
          ? decryptedObject.companyID
          : 0
        : 0
      : location.state
      ? location.state.companyID
      : 0
  );

  useEffect(() => {
    if (
      (fromURL
        ? decryptedObject
          ? decryptedObject.userID
            ? decryptedObject.userID
            : 0
          : 0
        : location.state
        ? location.state.userID
        : 0) === 0 ||
      (fromURL
        ? decryptedObject
          ? decryptedObject.companyID
            ? decryptedObject.companyID
            : 0
          : 0
        : location.state
        ? location.state.companyID
        : 0) === 0
    ) {
      console.log(
        "invalid data from use effect",
        fromURL,
        decryptedObject,
        location
      );
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    }
  }, []);

  //holds tabs value
  const [value, setValue] = useState(0);

  //displays dialog before deactivating user
  const [deactivateRecord, setDeactivateRecord] = useState(false);

  //for permissions
  const [adminManage, setAdminManage] = useState(false);
  const [companyAdmin, setCompanyAdmin] = useState(false);

  const [userNameInTabs, setUserNameInTabs] = useState("");

  //makes delete button loading while delete in progress
  const [buttonLoading, setButtonLoading] = useState(false);

  const [userName, setUserName] = useState("");

  //to display snackbar
  const [success, setSuccess] = useState(false);

  //holds snackbar message
  const [message, setMessage] = useState("");

  //holds user status
  const [uStatus, setUStatus] = useState("");

  //for loading on Delete
  const [deleteLoading, setDeleteLoading] = useState(false);

  //checks if any change has been made
  const [formChanged, setFormChanged] = useState(false);

  //holds delete error if any during the delete process
  const [deleteError, setDeleteError] = useState("");

  //makes save button load while save in progress
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  //getting values from previous page

  const userFirstName = fromURL
    ? decryptedObject
      ? decryptedObject.fname
        ? decryptedObject.fname
        : ""
      : ""
    : location.state
    ? location.state.fname
      ? location.state.fname
      : ""
    : "";

  const linkSource = fromURL
    ? decryptedObject
      ? decryptedObject.linkSource
        ? decryptedObject.linkSource
        : ""
      : ""
    : location.state
    ? location.state.linkSource
      ? location.state.linkSource
      : ""
    : "";

  const dataFromUrl = fromURL
    ? decryptedObject
      ? decryptedObject.dataObject
        ? decryptedObject.dataObject
        : null
      : null
    : null;

  const userLastName = fromURL
    ? decryptedObject
      ? decryptedObject.lname
        ? decryptedObject.lname
        : ""
      : ""
    : location.state
    ? location.state.lname
      ? location.state.lname
      : ""
    : "";

  const microUserID = fromURL
    ? decryptedObject
      ? decryptedObject.microUserID
        ? decryptedObject.microUserID
        : ""
      : ""
    : location.state
    ? location.state.microUserID
      ? location.state.microUserID
      : ""
    : "";

  const sourceCompanyNameLink = fromURL
    ? decryptedObject
      ? decryptedObject.sourceCompanyNameLink
        ? decryptedObject.sourceCompanyNameLink
        : ""
      : ""
    : location.state
    ? location.state.sourceCompanyNameLink
      ? location.state.sourceCompanyNameLink
      : ""
    : "";

  const fromCompanyRole = fromURL
    ? decryptedObject
      ? decryptedObject.fromCompanyRole
        ? decryptedObject.fromCompanyRole
        : false
      : false
    : location.state
    ? location.state.fromCompanyRole
      ? location.state.fromCompanyRole
      : false
    : false;

  const [userFullname, setUserFullname] = useState(
    userFirstName + " " + userLastName
  );

  const source = fromURL
    ? decryptedObject
      ? decryptedObject.source
        ? decryptedObject.source
        : ""
      : ""
    : location.state
    ? location.state.source
      ? location.state.source
      : ""
    : "";

  /*Holds the condition if the user entering is a levven admin to enable or disable fields according to condition*/
  const isALevvenAdmin = fromURL
    ? decryptedObject
      ? decryptedObject.roleDescription
        ? decryptedObject.roleDescription === "Levven Admin"
          ? true
          : false
        : false
      : false
    : location.state
    ? location.state.roleDescription
      ? location.state.roleDescription === "Levven Admin"
        ? true
        : false
      : false
    : false;
  const self = fromURL
    ? decryptedObject
      ? decryptedObject.self
        ? decryptedObject.self
        : false
      : false
    : location.state
    ? location.state.self
      ? location.state.self
      : false
    : false;

  const systemGenerated = fromURL
    ? decryptedObject
      ? decryptedObject.systemGenerated
        ? decryptedObject.systemGenerated
        : false
      : false
    : location.state
    ? location.state.systemGenerated
      ? location.state.systemGenerated
      : false
    : false;

  const [userStatus, setUserStatus] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.userStatus
          ? decryptedObject.userStatus
          : ""
        : ""
      : location.state
      ? location.state.userStatus
        ? location.state.userStatus
        : ""
      : ""
  );

  const username = fromURL
    ? decryptedObject
      ? decryptedObject.username
        ? decryptedObject.username
        : ""
      : ""
    : location.state
    ? location.state.username
      ? location.state.username
      : ""
    : "";

  const fromRoles = fromURL
    ? decryptedObject
      ? decryptedObject.fromRoles
        ? decryptedObject.fromRoles
        : false
      : false
    : location.state
    ? location.state.fromRoles
      ? location.state.fromRoles
      : false
    : false;

  const fromCompany = fromURL
    ? decryptedObject
      ? decryptedObject.fromCompany
        ? decryptedObject.fromCompany
        : false
      : false
    : location.state
    ? location.state.fromCompany
      ? location.state.fromCompany
      : false
    : false;

  const sourceCompany = fromURL
    ? decryptedObject
      ? decryptedObject.sourceCompany
        ? decryptedObject.sourceCompany
        : ""
      : ""
    : location.state
    ? location.state.sourceCompany
      ? location.state.sourceCompany
      : ""
    : "";

  const sourceCompanyName = fromURL
    ? decryptedObject
      ? decryptedObject.sourceCompanyName
        ? decryptedObject.sourceCompanyName
        : ""
      : ""
    : location.state
    ? location.state.sourceCompanyName
      ? location.state.sourceCompanyName
      : ""
    : "";

  const sourceCompanyLink = fromURL
    ? decryptedObject
      ? decryptedObject.sourceCompanyLink
        ? decryptedObject.sourceCompanyLink
        : ""
      : ""
    : location.state
    ? location.state.sourceCompanyLink
      ? location.state.sourceCompanyLink
      : ""
    : "";

  const rolesID = fromURL
    ? decryptedObject
      ? decryptedObject.roleID
        ? decryptedObject.roleID
        : ""
      : ""
    : location.state
    ? location.state.roleID
      ? location.state.roleID
      : ""
    : "";

  const roleName = fromURL
    ? decryptedObject
      ? decryptedObject.roleName
        ? decryptedObject.roleName
        : ""
      : ""
    : location.state
    ? location.state.roleName
      ? location.state.roleName
      : ""
    : "";

  const companyName = fromURL
    ? decryptedObject
      ? decryptedObject.companyName
        ? decryptedObject.companyName
        : ""
      : ""
    : location.state
    ? location.state.companyName
      ? location.state.companyName
      : ""
    : "";

  const userSystemGenerated = fromURL
    ? decryptedObject
      ? decryptedObject.userSystemGenerated
        ? decryptedObject.userSystemGenerated
        : false
      : false
    : location.state
    ? location.state.userSystemGenerated
      ? location.state.userSystemGenerated
      : false
    : false;

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.openSnackBar
          ? decryptedObject.openSnackbar
            ? true
            : false
          : false
        : false
      : location
      ? location.state
        ? location.state.openSnackBar
          ? true
          : false
        : false
      : false
  );

  /*Get current user ID from Redux*/

  const currentUserRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );

  const roleID = useSelector((state) =>
    state.user.user ? state.user.user.user.role.roleID : ""
  );

  //get permission to view/manage users of client company
  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  //get permission to view/manage users of own company
  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const currentUserFirstName = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const currentUserLastName = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  const rolePermission = useSelector((state) =>
    state.user.user
      ? companyID === 0
        ? state.user.user.user.role.clientUsers
        : state.user.user.user.role.ownUsers
      : ""
  );

  const currentUserRoleName = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  /*User Form Ref*/
  const userRef = useRef(null);

  useEffect(() => {
    //for help button text
    setPageLink("Users");

    //for drawer menu selected page
    if (self === true && fromCompany === false && fromRoles === false) {
      setPageID(-1);
    } else {
      if (fromCompany === true) {
        if (companyID === compID) {
          setPageID(-1);
        } else {
          setPageID(6);
        }
      } else if (fromRoles === true) {
        if (companyID === compID) {
          setPageID(2);
        } else {
          setPageID(8);
        }
      } else {
        if (compID === companyID) {
          setPageID(1);
        } else {
          setPageID(7);
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log("self changed", self, userFirstName);
    window.scrollTo(0, 0);
    setUserFullname(userFirstName + " " + userLastName);

    if (!self) {
      if (clientUsers === "M") {
        setAdminManage(true);
      }

      //if we want to edit a levven admin and we are a levven user
      //we cant unless our role is also levven admin
      if (
        (ownUsers === "M" && isALevvenAdmin === false) ||
        (ownUsers === "M" &&
          isALevvenAdmin === true &&
          currentUserRoleName === "Levven Admin")
      ) {
        setCompanyAdmin(true);
      }
    }
  }, [userID, self]);

  const activateUser = () => {
    //function to activate user(s)
    setButtonLoading(true);
    //if user inactive, we are reactivating him, else we are activating him
    const data = {
      AdminID: currentUserID,
      Users: [
        { Email: username, CompanyID: companyID === 0 ? compID : companyID },
      ],
      ReactivateUser: userStatus === "I" ? true : false,
      ResendActivationEmail: userStatus === "P" ? true : false,
    };

    adminServices
      .activateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUStatus(userStatus === "I" ? "A" : "P");
        setUserStatus(userStatus === "I" ? "A" : "P");
        setSuccess(true);
        setMessage(
          userStatus === "P"
            ? "Activation Email Sent Successfully"
            : "User Activated Successfully"
        );
      })
      .catch((error) => {
        setButtonLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  const deactivateUser = () => {
    //function to deactivate user(s)
    const data = {
      AdminID: currentUserID,

      Users: [
        { Email: username, CompanyID: companyID === 0 ? compID : companyID },
      ],
    };
    setButtonLoading(true);
    setDeactivateRecord(false);

    adminServices
      .deactivateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUStatus("I");
        setUserStatus("I");
        setSuccess(true);
        setMessage("User Deactivated Successfully");
      })
      .catch((error) => {
        setButtonLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleRouteClick = (route) => {
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };

  const handleConfirm = () => {
    sessionStorage.removeItem("unsavedChanges");
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    if (tempVal === "/create-user") {
      addUser(source === "Users" ? 0 : companyID);
    } else history.push(tempVal);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const toCompanies = () => {
    //link to go to company page relative to which company page we came from
    if (sourceCompanyLink === "/Companies") {
      history.push({
        pathname: sourceCompanyLink,
      });
    } else {
      history.push({
        pathname: sourceCompanyLink,
        state: {
          source: currentUserFirstName + " " + currentUserLastName,
          companyID: companyID,
          companyName: sourceCompanyName,
          myCompany: true,
        },
      });
    }
  };

  const toCompanyInformation = () => {
    //go to company information page we came from
    history.push({
      pathname: "/company-information",
      state: {
        path: "company-information",
        companyID: companyID,
        companyName: sourceCompanyName,
        source: sourceCompany,
      },
    });
  };

  const toRoles = () => {
    //link to go to company page relative to which role page we came from
    history.push({
      pathname: !fromCompanyRole ? sourceCompanyLink : "/company-information",
      state: {
        path: "company-information",
        companyID: companyID,
        companyName: companyName,
        source: companyID === compID ? "" : "Companies",
        myCompany: companyID === compID,
        tab: 3,
      },
    });
  };

  const toRoleInformation = () => {
    //go to role information page we came from
    history.push({
      pathname: "/role-information",
      state: {
        roleID: rolesID,
        roleName: roleName,
        source: companyID === compID ? "My Roles" : "Roles",
        linkSource: companyID === compID ? "/my-roles" : "/Roles",
        titleText: roleName + " at " + companyName,
        companyID: companyID,
        selfRole: rolesID === roleID ? true : false,
        systemGenerated: userSystemGenerated,
      },
    });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const addUser = (companyID) => {
    //function to go to create user
    history.push({
      pathname: "/create-user",
      state: {
        companyID: companyID,
      },
    });
  };

  const deleteUser = async () => {
    setDeleteLoading(true);
    await accountServices
      .deleteUsers({
        Users: [
          { Email: username, CompanyID: companyID === 0 ? compID : companyID },
        ],
      })
      .then((response) => {
        history.push({
          pathname:
            sourceCompany === "Companies"
              ? "/company-information"
              : sourceCompany === "Roles" || sourceCompany === "My Roles"
              ? "/role-information"
              : sourceCompanyLink
              ? sourceCompanyLink
              : source === "Users"
              ? "/Users"
              : "/my-users",
          state: fromURL
            ? {
                ...decryptedObject,
                previousPageSnackbar: "User deleted Successfully",
                tab: sourceCompany === "Companies" ? 2 : 1,
              }
            : {
                ...location.state,
                previousPageSnackbar: "User deleted Successfully",
                tab: sourceCompany === "Companies" ? 2 : 1,
              },
        });
        setDeleteLoading(false);
      })
      .catch((error) => {
        setDeleteLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      <SnackBar
        open={success}
        message={message !== "" ? message : "Saved Successfully"}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />
      {deactivateRecord ? (
        <AlertDialogSlide
          title="User Deactivation"
          textContent={"Are you sure you want to deactivate this record?"}
          save={deactivateUser}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeactivateRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      <Paper>
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {fromCompany ? (
                <Link
                  underline="always"
                  key="1"
                  color="inherit"
                  onClick={toCompanies}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {sourceCompany}
                </Link>
              ) : (
                ""
              )}
              {fromCompany ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={toCompanyInformation}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {sourceCompanyName}
                </Link>
              ) : (
                ""
              )}
              {fromRoles ? (
                <Link
                  underline="always"
                  key="1"
                  color="inherit"
                  onClick={toRoles}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {sourceCompany}
                </Link>
              ) : (
                ""
              )}
              {fromRoles ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={toRoleInformation}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {sourceCompanyName}
                </Link>
              ) : (
                ""
              )}

              {self === true && fromRoles === false && fromCompany === false ? (
                ""
              ) : fromRoles === false && fromCompany === false ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() =>
                    history.push({
                      pathname: source === "Users" ? "/Users" : "/my-users",
                    })
                  }
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {source}
                </Link>
              ) : (
                ""
              )}

              {fromRoles === true || fromCompany === true ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() => {
                    history.push({
                      pathname: fromCompany
                        ? "/company-information"
                        : "/role-information",
                      state: {
                        companyID: companyID,
                        companyName: companyName,
                        myCompany: self ? true : false,
                        source:
                          self && fromCompany
                            ? "My Company"
                            : !self && fromCompany
                            ? "Companies"
                            : self && fromRoles
                            ? "My Roles"
                            : "Roles",
                        tab: fromCompany ? 2 : 1,
                        roleID: rolesID,
                        roleName: roleName,
                        linkSource:
                          companyID === compID ? "/my-roles" : "/Roles",
                        titleText: roleName + " at " + companyName,
                        selfRole: rolesID === roleID ? true : false,
                        systemGenerated: userSystemGenerated,
                      },
                    });
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {source}
                </Link>
              ) : (
                ""
              )}

              {value !== 0 ? (
                <Link
                  underline="always"
                  key="4"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();
                    } else {
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {userFullname}
                </Link>
              ) : (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {userFullname}
                </Typography>
              )}
              {value === 1 ? (
                <Typography
                  key="5"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Devices
                </Typography>
              ) : (
                ""
              )}
              {value === 2 ? (
                <Typography
                  key="6"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Change Password
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {value === 0 &&
              !self &&
              (adminManage || companyAdmin) &&
              (systemGenerated === false ||
                (systemGenerated === true && companyID === 0)) ? (
                <>
                  <CustomButton
                    startIcon={<UserPlus />}
                    toggle={() => handleRouteClick("/create-user")}
                  >
                    New User
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                </>
              ) : null}
              {value === 0 && (adminManage || companyAdmin || self) ? (
                <CustomButton
                  startIcon={<Save />}
                  toggle={() =>
                    userRef.current.invoices
                      ? userRef.current.checkUserNotifInvoices()
                      : userRef.current.userNotNotifInvoices()
                  }
                  loading={tabButtonLoading}
                >
                  Save
                </CustomButton>
              ) : null}

              {value === 0 &&
              !self &&
              rolePermission === "M" &&
              !systemGenerated ? (
                <>
                  <div style={{ width: "10px" }} />

                  <CustomButton
                    startIcon={<Trash2 />}
                    toggle={() => deleteUser()}
                    loading={deleteLoading}
                  >
                    Delete
                  </CustomButton>
                  <div style={{ width: "10px" }} />

                  {userStatus === "A" ? (
                    <div>
                      <CustomButton
                        startIcon={<UserX />}
                        toggle={() => setDeactivateRecord(true)}
                        loading={buttonLoading}
                      >
                        Deactivate
                      </CustomButton>
                    </div>
                  ) : userStatus === "I" ||
                    userStatus === "N" ||
                    userStatus === "P" ? (
                    <div>
                      <CustomButton
                        startIcon={
                          userStatus === "P" ? <RefreshCcw /> : <UserCheck />
                        }
                        toggle={activateUser}
                        loading={buttonLoading}
                      >
                        {userStatus === "P"
                          ? "Resend Activation Email"
                          : "Activate"}
                      </CustomButton>
                    </div>
                  ) : null}
                </>
              ) : null}

              {value === 0 ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<X />}
                    toggle={async () => {
                      await userRef.current.setPristine();
                      if (fromRoles || fromCompany) {
                        if (
                          dataFromUrl !== null &&
                          dataFromUrl !== undefined &&
                          sourceCompanyNameLink !== ""
                        )
                          history.push({
                            pathname: sourceCompanyNameLink,
                            state: {
                              ...dataFromUrl,
                              previousPageSnackbar: "",
                              tab: fromCompany ? 2 : fromRoles ? 1 : 0,
                            },
                          });
                        else if (locationToReceive !== null) {
                          history.push({
                            pathname: sourceCompanyNameLink,
                            state: {
                              ...locationToReceive,
                              previousPageSnackbar: "",
                              tab: fromCompany ? 2 : fromRoles ? 1 : 0,
                            },
                          });
                        } else {
                          history.goBack();
                        }
                      } else if (source === "My Users")
                        history.push("/my-users");
                      else history.push("/users");
                    }}
                  >
                    Cancel
                  </CustomButton>
                </>
              ) : null}
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Profile" {...a11yProps(0)} style={{ margin: "50" }} />
              <Tab label="Devices" {...a11yProps(1)} style={{ margin: "50" }} />
              {self ? (
                <Tab
                  label="Change Password"
                  {...a11yProps(2)}
                  style={{ margin: "50" }}
                />
              ) : (
                ""
              )}
              {!self && currentUserRole === "Levven Admin" ? (
                <Tab
                  label="Change Password"
                  {...a11yProps(3)}
                  style={{ margin: "50" }}
                />
              ) : (
                ""
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UserForm
              receivedCompanyID={companyID}
              receivedUserID={userID}
              adminManage={adminManage}
              companyAdmin={companyAdmin}
              currentUserRoleName={currentUserRoleName}
              isALevvenAdmin={isALevvenAdmin}
              sameUser={self}
              userStatus={userStatus}
              setUserStatus={setUserStatus}
              setOpenSnackBar={setOpenSnackBar}
              setTabButtonLoading={setTabButtonLoading}
              setUserFullname={setUserFullname}
              setMessage={setMessage}
              ref={userRef}
              setFormChanged={setFormChanged}
              setUsernameForTabs={setUserNameInTabs}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserDevices userID={microUserID} />
          </TabPanel>
          {self ? (
            <TabPanel value={value} index={2}>
              <ChangePasswordForm setFormChanged={setFormChanged} />
            </TabPanel>
          ) : null}

          {!self && currentUserRole === "Levven Admin" ? (
            <TabPanel value={value} index={2}>
              <ChangeUserPasswordForm
                setFormChanged={setFormChanged}
                userNameInTabs={userNameInTabs}
              />
            </TabPanel>
          ) : null}
        </Box>
      </Paper>
    </>
  );
}
