import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import SnackBar from "../SnackBar/SnackBar";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { companyServices } from "../../services/company.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { agreementServices } from "../../services/agreement.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

//Holds the columns of the table header
//id represents the column id and is generally used for the sorting
const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Builder",
    numeric: false,
    disablePadding: false,
    label: "Builder",
  },
  {
    id: "Installer",
    numeric: false,
    disablePadding: false,
    label: "Installer",
  },
  {
    id: "RealEstateType",
    numeric: false,
    disablePadding: false,
    label: "Building Type",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolePermission,
  } = props;
  const createSortHandler = (property) => {
    //calls the sort function from inside the AgreementTable Component
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AgreementTable({ setPageLink, setPageID }) {
  const location = useLocation();
  const history = useHistory();

  //holds permission for the page
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  //holds takeoff permission
  const rolePermissionTakeoff = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  //redirect
  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  useEffect(() => {
    //for drawer menu
    setPageID(3);
    //for help button
    setPageLink("Agreements");
  }, []);

  //for sorting
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //state to save selected values in
  const [selected, setSelected] = React.useState([]);

  //state for the page in the table
  const [page, setPage] = React.useState(0);

  //state to save how many rows in the table to have per page
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //state that holds all values to be displayed in the table
  const [rows, setRows] = React.useState([]);

  //state to display the filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //state holding the errors from the database when retreiving data
  const [error, setError] = React.useState("");

  //value holding how many selected values there are in the table
  var numSelected = selected.length;

  //state holding total number of records in the database that can be displayed in the table
  const [numberRecords, setNumberRecords] = React.useState(0);

  //state to make button load on submission
  const [loading, setLoading] = React.useState(false);

  //states for Filters
  const [nameSearch, setNameSearch] = React.useState("");
  const [builders, setBuilders] = React.useState([]);
  const [installers, setInstallers] = React.useState([]);
  const [realEstate, setRealEstate] = React.useState([
    { code: "R", name: "Residential" },
    { code: "C", name: "Commercial" },
  ]);

  //state used to refetch values from the database in case of update or filter submission
  const [resubmit, setResubmit] = React.useState(false);

  //state to make page load while we fetch the data
  const [pageLoad, setPageLoad] = React.useState(false);

  //state that holds the error to be display in the dialog box when deleting record
  const [deleteError, setDeleteError] = React.useState("");

  //state to save filter values in
  const [selectedBuilders, setSelectedBuilders] = React.useState([]);
  const [selectedInstallers, setSelectedInstallers] = React.useState([]);
  const [selectedRealEstate, setSelectedRealEstate] = React.useState([]);

  //state for success in deleting values
  const [success, setSuccess] = React.useState(false);

  //state to display "Are you sure you want to delete these values" dialog box
  const [deleteRecords, setDeleteRecords] = React.useState(false);

  // we are getting values sent from the previous page
  const [agreementSuccess, setAgreementSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //get data from Redux
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );

  const convertRealEstateType = (ret) => {
    //Since values in database are saved as R and C, we are converting them
    if (ret === "R") {
      return "Residential";
    } else if (ret === "C") {
      return "Commercial";
    }
  };

  const fetchFilterData = () => {
    //get the values to fill the filters

    //if Levven, we get all builders and installer that have agreements, else we only get the builders and installers
    //the company has agreements with to display them in filters

    if (companyType === "L") {
      var data = { CompanyType: "B", IsLevven: true, CompanyID: companyID };
      companyServices
        .getChildCompaniesForAgreementByType(data)
        .then((response) => {
          setBuilders(response.data);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
        });

      var data2 = { CompanyType: "I", IsLevven: true, CompanyID: companyID };

      companyServices
        .getChildCompaniesForAgreementByType(data2)
        .then((response) => {
          setInstallers(response.data);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
        });
    } else {
      if (companyType === "B") {
        var data3 = { CompanyType: "I", IsLevven: false, CompanyID: companyID };

        companyServices
          .getChildCompaniesForAgreementByType(data3)
          .then((response) => {
            setInstallers(response.data);
          })
          .catch((error) => {
            setError(error.response.data.error.error);
          });
      } else if (companyType === "I") {
        var data4 = { CompanyType: "B", IsLevven: false, CompanyID: companyID };
        companyServices
          .getChildCompaniesForAgreementByType(data4)
          .then((response) => {
            setBuilders(response.data);
          })
          .catch((error) => {
            setError(error.response.data.error.error);
          });
      }
    }
  };

  const getValuesFromDatabase = () => {
    //submit the filters to database and get values depending on them

    //we get the filter data we need to sent to the database since the filters are saved as objects
    var array = [];

    selectedBuilders.forEach((country) => {
      return array.push(country.companyID);
    });

    var array2 = [];

    selectedInstallers.forEach((type) => {
      return array2.push(type.companyID);
    });

    var array3 = [];

    selectedRealEstate.forEach((type) => {
      return array3.push(type.code);
    });

    //if levven is viewing agreements, all agreements are displayed, else, only agreements related to the company viewing the table
    if (companyType === "L") {
      const data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: {
          Name: nameSearch,
          Builders: array,
          Installers: array2,
          RealEstateTypes: array3,
        },
        isLevven: true,
        orderDirection: orderDirection,
        orderColumn: orderColumn,
      };

      agreementServices
        .getAgreements(data)
        .then((response) => {
          setPageLoad(false);

          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.agreements);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    } else {
      const data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: {
          Name: nameSearch,
          Builders: array,
          Installers: array2,
          RealEstateTypes: array3,
        },
        isLevven: false,
        CompanyID: companyID,
        orderDirection: orderDirection,
        orderColumn: orderColumn,
      };

      agreementServices
        .getCompanyAgreements(data)
        .then((response) => {
          setPageLoad(false);

          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.agreements);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    //we get the data for the rows as well as the filters

    if (agreementSuccess === true) {
      //if we deleted an agreement from the agreement Tabs to display the snackbar
      setSuccess(true);
      setAgreementSuccess(false);
    }

    setRows([]);
    window.scrollTo(0, 0); //scroll to top of page
    setPageLoad(true);
    setError("");
    setSelected([]);

    fetchFilterData();
    getValuesFromDatabase();
  }, [page, rowsPerPage, resubmit]);

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //select all rows from table
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.agreementID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const viewAgreementData = (id, name) => {
    //we are taken to a page to view agreement data
    if (id === 0) {
      history.push({
        pathname: "/create-agreement",
        state: {
          agreementID: id,
          agreementName: "New Agreement",
          create: true,
        },
      });
    } else {
      history.push({
        pathname: "/agreement-information",
        state: {
          agreementID: id,
          agreementName: name,
          create: false,
        },
      });
    }
  };

  const deleteValues = () => {
    //delete agreements
    setLoading(true);
    setDeleteRecords(false);
    const data = { Agreements: selected };
    agreementServices
      .deleteAgreement(data)
      .then(() => {
        setLoading(false);
        setResubmit(!resubmit);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
        setResubmit(!resubmit);
      });
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const clearFilterData = () => {
    //function to clear current filter data
    setNameSearch("");
    setSelectedBuilders([]);
    setSelectedInstallers([]);
    setSelectedRealEstate([]);
    setResubmit(!resubmit);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //check if specific record is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const submitForm = (event) => {
    //function to submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  const goToNewTakeoff = () => {
    //function to go to New Takeoff Creation page from Agreement table
    if (selected.length === 1) {
      history.push({
        pathname: "/create-takeoff",
        state: {
          agreement: selected[0],
        },
      });
    } else {
      history.push({
        pathname: "/create-takeoff",
      });
    }
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      agreementID: contextMenuData.agreementID,
      agreementName: contextMenuData.name,
      create: false,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/agreement-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Agreement(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecords ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteValues}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecords(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Agreements
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermissionTakeoff === "M" && selected.length === 1 ? (
                  <>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                      toggle={() => goToNewTakeoff()}
                    >
                      New Takeoff
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
                {rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecords(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermissionTakeoff === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                      toggle={() => goToNewTakeoff()}
                    >
                      New Takeoff
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
                {rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                      toggle={() => viewAgreementData(0)}
                    >
                      New Agreement
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitForm}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        value={nameSearch}
                        onChange={(e) => setNameSearch(e.target.value)}
                        size="small"
                        label="Agreement Name"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                    {companyType === "L" || companyType === "I" ? (
                      <Grid item xs={8} md={5} lg={3}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={builders}
                          fullWidth
                          value={selectedBuilders}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            setSelectedBuilders(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Builders"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {companyType === "L" || companyType === "B" ? (
                      <Grid item xs={8} md={5} lg={3}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={installers}
                          fullWidth
                          value={selectedInstallers}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            setSelectedInstallers(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Installers"
                              autoComplete="off"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={realEstate}
                        fullWidth
                        value={selectedRealEstate}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          setSelectedRealEstate(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Building Type"
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Agreements to display. "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={() => viewAgreementData(0)}
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.agreementID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        tabIndex={-1}
                        key={row.agreementID}
                      >
                        <>
                          <TableCell
                            padding="checkbox"
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                          >
                            {rolePermission === "M" ? (
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.agreementID)
                                }
                                checkedIcon={
                                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                                }
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={() =>
                              viewAgreementData(row.agreementID, row.name)
                            }
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewAgreementData(row.agreementID, row.name)
                            }
                          >
                            {row.builder.name}
                          </TableCell>
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewAgreementData(row.agreementID, row.name)
                            }
                          >
                            {row.installer ? row.installer.name : ""}
                          </TableCell>
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewAgreementData(row.agreementID, row.name)
                            }
                          >
                            {convertRealEstateType(row.realEstateType)}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
