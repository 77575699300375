import { Alert } from "@mui/material";
import React from "react";

export const CustomBanner = ({ bannerText }) => {
  return (
    <Alert
      style={{
        justifyContent: "center",
        marginBottom: "20px",
        backgroundColor: "#d32f2f",
        color: "#fff",
        borderRadius: "3px",
      }}
      variant="filled"
      severity="error"
    >
      {bannerText}
    </Alert>
  );
};
