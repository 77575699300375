import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/productprice";

const getProductPrices = (data) => {
  return fetchWrapper.post(baseUrl + "/GetProductPriceList", data);
};

const deletePrices = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteProductPrice", data);
};

const getCustomerGroupPrices = (data) => {
  return fetchWrapper.post(baseUrl + "/GetCustomerSpecificPriceList", data);
};

export const productpricesServices = {
  getProductPrices,
  deletePrices,
  getCustomerGroupPrices
};

