import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";
/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/email";

const getEmailTemplates = () => {
  return fetchWrapper.get(baseUrl + "/GetEmailTemplates");
};

const updateEmailTemplates = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateEmailTemplates", data);
};

const sendRequestLevvenOrderModificationEmail = (data) => {
  return fetchWrapper.post(baseUrl + "/SendRequestLevvenOrderModificationEmail", data);
};


export const emailTemplatesServices = {
  getEmailTemplates,
  updateEmailTemplates,
  sendRequestLevvenOrderModificationEmail
};
