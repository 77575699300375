import React from "react";
import { useState, useEffect, useRef } from "react";
import CardContent from "@mui/material/CardContent";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { Grid } from "@mui/material";
import { systemDataServices } from "../../services/systemData.services";
import { Save } from "react-feather";
import SnackBar from "../SnackBar/SnackBar";
import validator from "validator";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { AirlineSeatLegroomReducedSharp } from "@material-ui/icons";

export const MiddlewareVariables = ({
  setFormChanged,
  setDirty,
  setPristine,
}) => {
  /**holds errors */
  const [error, setError] = useState("");

  /**For Log Clean Runtime */
  const [logCleanRuntime, setLogCleanRuntime] = useState(null);
  const [logCleanRuntimeError, setLogCleanRuntimeError] = useState(false);
  const [periodicity, setPeriodicity] = useState(null);

  /**For Retention Field */
  const [retention, setRetention] = useState(null);
  const [retentionError, setRetentionError] = useState("");
  const [periodicityError, setPeriodicityError] = useState("");

  /**For button loading */
  const [buttonLoading, setButtonLoading] = useState(false);

  /**For page load on fetch */
  const [loading, setLoading] = useState(false);

  /**to refetch values from database */
  const [refetch, setRefetch] = useState(false);

  /**variable for sync maintenance mode*/
  const [middlewareStatus, setMiddlewareStatus] = useState(false);

  /**holds ref to time picker */
  const timeRef = useRef(null);

  /**Function called on submit */
  const saveValues = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    if (logCleanRuntime === null || logCleanRuntime === "") {
      setLogCleanRuntimeError(true);
      setError("Log Clean Runtime field cannot be empty");
    }

    if (retention === "" || retention === null) {
      setRetentionError("Retention field cannot be empty");
      setError("Retention field cannot be empty");
    }
    if (periodicity === "" || periodicity === null) {
      setPeriodicityError("Periodicity field cannot be empty");
      setError("Periodicity field cannot be empty");
    }
    if (periodicity > 24 || periodicity < 1) {
      setPeriodicityError("Periodicity field cannot be less than 1 or greater than 24");
      setError("Periodicity field cannot be less than 1 or greater than 24");
    }
    if (parseInt(retention) < 1) {
      setRetentionError("Retention days has to be greater or equal to 1");
      setError("Retention days has to be greater or equal to 1");
    }
    if (
      logCleanRuntime !== null &&
      logCleanRuntime !== "" &&
      retention !== "" &&
      retention !== null &&
      parseInt(retention) >= 1
    ) {
      var dateNow = new Date();
      var timeChosen = await logCleanRuntime.split(":");
      await dateNow.setHours(timeChosen[0]);
      await dateNow.setMinutes(timeChosen[1]);
      await dateNow.setSeconds(0);
      await dateNow.setMilliseconds(0);

      await systemDataServices
        .updateMiddlewareValuesInDatabase({
          Retention: retention,
          LogCleanRuntime: dateNow.toISOString(),
          MiddlewareStatus: middlewareStatus,
          OrderCreationFailureNotificationOccurence: periodicity,
        })
        .then(() => {
          setRefetch((refetch) => !refetch);
          setPristine();
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setButtonLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  /**Convert UTC string to local date time */
  const convertUTCtoLocalDateTime = async (datetime) => {
    var date = new Date(datetime + "Z");

    const d = date.toString();
    if (d === "Invalid Date") {
      return "";
    }
    const m = d.substring(16, 21);
    await setLogCleanRuntime(m);
  };

  /**Function called on page load */
  const getValues = async () => {
    setLoading(true);

    await systemDataServices
      .getSystemDataVariables()
      .then(async (response) => {

        await convertUTCtoLocalDateTime(response.data.logCleanRuntime);
        setRetention(response.data.retention);
        setMiddlewareStatus(response.data.middlewareStatus);
        setPeriodicity(response?.data?.orderCreationFailureNotificationOccurence)
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getValues();
  }, [refetch]);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  return (
    <div>
      {error ? (
        <>
          <AlertSnackBar
            message={error}
            handleClose={handleCloseAlertSnackbar}
            open={error !== ""}
          />
        </>
      ) : null}
      {loading ? (
        <>
          {" "}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        </>
      ) : (
        <>
          {" "}
          <form onSubmit={saveValues}>
            <CardContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        checked={middlewareStatus}
                        onChange={() => setMiddlewareStatus(!middlewareStatus)}
                        control={<Switch />}
                        label={
                          middlewareStatus
                            ? "Middleware Sync is on"
                            : "Middleware Sync is off"
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      borderBottom: "1px solid #969696",
                      paddingBottom: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    Log Cleanup
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    sx={{
                      width: "100%",
                      height: "auto",
                    }}
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 1 min
                    }}
                    inputRef={timeRef}
                    value={logCleanRuntime}
                    helperText={
                      logCleanRuntimeError
                        ? "Log Clean Runtime field cannot be empty"
                        : ""
                    }
                    label="Log Clean Runtime"
                    error={logCleanRuntimeError}
                    fullWidth
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setLogCleanRuntime(e.target.value);
                      if (logCleanRuntimeError) setLogCleanRuntimeError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    sx={{
                      width: "100%",
                      height: "auto",
                    }}
                    type="number"
                    inputProps={{
                      min: 1,
                    }}
                    onKeyDown={(e) => {
                      if (e.code === "Minus") {
                        e.preventDefault();
                      }
                    }}
                    value={retention}
                    helperText={retentionError}
                    label="Retention Days"
                    error={retentionError}
                    fullWidth
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setRetention(e.target.value);
                      if (retentionError) setRetentionError("");
                    }}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={2} mt={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      borderBottom: "1px solid #969696",
                      paddingBottom: "20px",
                      marginBottom: "20px",
                    }}
                  >
                   Sync Errors Email
                  </Typography>
                </Grid>
             
               
                <Grid item xs={12} md={6} lg={6}>
                <Tooltip arrow title="Periodicity to send the email to warn the Admin of errors in the Sync preventing orders of being synced to NAV">
                  <TextField
                    sx={{
                      width: "100%",
                      height: "auto",
                    }}
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 24,
                    }}
                    value={periodicity}
                    helperText={periodicityError}
                    label="Email Periodicity (Hours)"
                    error={periodicityError}
                    fullWidth
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      let newValue = e.target.value; 
                      if (newValue < 1) {

                        newValue = 1;
                      } else if (newValue > 24) {

                        newValue = 24;
                      }
                      setPeriodicity(newValue); 
                      if (periodicityError) setPeriodicityError("");
                    }}
                  /></Tooltip>

                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <CustomButton
                    loading={buttonLoading}
                    type="submit"
                    size="medium"
                    startIcon={<Save />}
                  >
                    Save
                  </CustomButton>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </>
      )}
    </div>
  );
};
