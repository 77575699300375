import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import EndUserAgreementEdit from "../EndUserAgreementEdit/EndUserAgreementEdit";
import CountriesManagement from "../CountriesManagement/CountriesManagement";
import EmailTemplates from "../EmailTemplates/EmailTemplates";
import SnackBar from "../SnackBar/SnackBar";
import { SystemConfigurations } from "../SystemConfigurations/SystemConfigurations";
import CountryForm from "../CountryForm/CountryForm";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import HelpTextEditor from "../HelpTextTableEditor/HelpTextTableEditor";
import { VideoManager } from "../VideoManager/VideoManager";
import { MiddlewareVariables } from "../MiddlewareVariables/MiddlewareVariables";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SystemPreferences({ setPageLink }) {
  //used to send data to next page
  const history = useHistory();

  //used to retrieve sent data from previous page
  const location = useLocation();

  //getting state data from previous page
  const isLevven = location.state
    ? location.state.isLevven
      ? location.state.isLevven
      : false
    : false;

  const tab = location.state
    ? location.state.tab
      ? location.state.tab
      : 0
    : 0;
  const [success, setSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  const roleName = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const systemGenerated = location.state
    ? location.state.systemGenerated
      ? location.state.systemGenerated
      : false
    : false;

  if (roleName !== "Levven Admin") {
    history.push("/Dashboard");
  }
  //holds which tab we are in
  const [value, setValue] = useState(0);

  //displays snackbar
  const [open, setOpen] = useState(false);

  const [createCountry, setCreateCountry] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    //for help button
    setPageLink("SystemConfig");
  }, []);

  useEffect(() => {
    /**If we wish to open specific tab when coming from previous page */
    setValue(tab);
  }, []);

  const handleClose = (event, reason) => {
    //to close snackbar

    setOpen(false);
    setSuccess(false);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue)
    } else {
      setValue(newValue);
    }
  };

  const handleConfirm = () => {
    sessionStorage.removeItem("unsavedChanges");

    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
  };

  const handleClosePopUp = () => {
    setOpenDialog(false);
  };
  return (
    <>
    <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClosePopUp}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {/* {Prompt} */}
      <SnackBar
        open={success}
        message="Countrie(s) Deleted Successfully"
        severity="success"
        handleClose={handleClose}
      />
      <SnackBar
        open={open}
        message={"This is a success message"}
        handleClose={handleClose}
      />
      <Paper>
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
           
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="System Configuration"
                {...a11yProps(0)}
                style={{ margin: "50" }}
              />
              <Tab
                label="Email Templates"
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />
              <Tab
                label="Middleware"
                {...a11yProps(2)}
                style={{ margin: "50" }}
              />
              <Tab
                label="End-User-Agreement"
                {...a11yProps(3)}
                style={{ margin: "50" }}
              />
              <Tab
                label="Countries"
                {...a11yProps(4)}
                style={{ margin: "50" }}
              />

              <Tab
                label="Help Text"
                {...a11yProps(5)}
                style={{ margin: "50" }}
              />

              {/* <Tab
                label="Video Management"
                {...a11yProps(5)}
                style={{ margin: "50" }}
              /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <SystemConfigurations
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmailTemplates
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MiddlewareVariables
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EndUserAgreementEdit
              setAlert={setOpen}
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            {!createCountry ? (
              <CountriesManagement setCreateCountry={setCreateCountry} />
            ) : (
              <>
                <CountryForm
                  create={true}
                  setCreateCountry={setCreateCountry}
                  setFormChanged={setFormChanged}
                  setDirty={setDirty}
                  setPristine={setPristine}
                />
              </>
            )}
          </TabPanel>

          <TabPanel value={value} index={5}>
            <HelpTextEditor
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel>

          {/* <TabPanel value={value} index={5}>
            <VideoManager
              setFormChanged={setFormChanged}
              setDirty={setDirty}
              setPristine={setPristine}
            />
          </TabPanel> */}
        </Box>
      </Paper>
    </>
  );
}
