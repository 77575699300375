import React from "react";
import UsersTableComponent from "../../../components/UsersTableComponent/UsersTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const MyUsersTable = ({ rolesTable = false, setPageLink, setPageID }) => {
  const history = useHistory();

  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const companyID = useSelector((state) =>
    state.user.user.user ? state.user.user.user.companyID : null
  );

  if (ownUsers === "N") {
    history.push("/dashboard");
  }

  React.useEffect(() => {
    //for help button
    setPageLink("Users");

    //for drawer menu
    setPageID(1);
  }, []);

  return (
    <div>
      <UsersTableComponent
        companyID={companyID}
        rolesTable={rolesTable}
        titleText={"My Users"}
      />
    </div>
  );
};

export default MyUsersTable;
