import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement */

const baseUrl = apiConfig + "/logs";

const getLogsList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetLogsList", data);
};

const getLogDetailsList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetLogDetailsList", data);
};

const deleteLogsList = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteLogsList", data);
};

const deleteLogDetailsList = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteLogDetailsList", data);
};

const createLogForAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateLogForAddress", data);
};

export const logsService = {
  getLogsList,
  getLogDetailsList,
  deleteLogsList,
  deleteLogDetailsList,
  createLogForAddress,
};
