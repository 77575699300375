import React, { useState } from "react";
import SnackBar from "../SnackBar/SnackBar";
import { CircularProgress, TextField, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { Grid } from "@mui/material";
import { accountServices } from "../../services/account.services";
import { systemDataServices } from "../../services/systemData.services";
import { Save } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const EndUserAgreementEdit = ({ setFormChanged, setDirty, setPristine }) => {
  //Holds end user agreement text
  const [euaText, setEuaText] = useState("");

  //true if there is an error on the end user agreement text
  const [euaTextError, setEuaTextError] = useState(false);

  //for loading
  const [loading, setLoading] = React.useState(true);

  //for button loading
  const [buttonLoading, setButtonLoading] = React.useState(false);

  //Error
  const [saveError, setSaveError] = useState("");

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    //get end user agreement text
    accountServices
      .getEndUserAgreement()
      .then((response) => {
        setEuaText(response.data);
        setSaveError("");
        setLoading(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setLoading(false);
      });
  }, []);

  const submitText = (event) => {
    //submit new text to save
    event.preventDefault();
    setButtonLoading(true);
    if (euaText.trim() === "") setEuaTextError(true);
    if (euaText.trim() === "") {
      setButtonLoading(false);
      return;
    } else {
      systemDataServices
        .updateEndUserAgreement({ EndUserAgreement: euaText.toString() })
        .then((response) => {
          setEuaText(response.data.endUserAgreement);
          setOpenSnackBar(true);
          setSaveError("");
          setButtonLoading(false);
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setPristine();
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setButtonLoading(false);
        });
    }
  };
  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="80vh"
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </Grid>
      ) : (
        <>
          <form onSubmit={submitText}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    borderBottom: "1px solid #969696",
                    paddingBottom: "20px",
                  }}
                >
                  End User Agreement
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  multiline
                  value={euaText}
                  label="End User Agreement text"
                  helperText={
                    euaTextError ? "End User Agreement field is empty" : null
                  }
                  error={euaTextError}
                  sx={{ width: "100%", height: "auto" }}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setEuaText(e.target.value);
                    if (euaTextError) setEuaTextError(false);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <CustomButton
                  type="submit"
                  loading={buttonLoading}
                  startIcon={<Save />}
                >
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {saveError !== "" ? (
            <>
              <AlertSnackBar
                message={saveError}
                handleClose={handleCloseAlertSnackbar}
                open={saveError !== ""}
              />
            </>
          ) : null}
          {openSnackBar ? (
            <SnackBar
              open={openSnackBar}
              message="Saved Successfully"
              severity="success"
              handleClose={handleSnackBarClose}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default EndUserAgreementEdit;
