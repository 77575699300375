import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Divider,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import Underline from "../../components/Underline/Underline";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { agreementServices } from "../../services/agreement.services";
import LevvenLogo from "../../assets/Levven_logo.png";
import { Check } from "react-feather";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const AgreementURLpage = () => {
  const history = useHistory();

  /* Get values from redux store*/
  const jwtToken = useSelector((state) =>
    state.user.user ? state.user.user.jwtToken : ""
  );

  //holds permission for the page
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : 0
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const [agreement, setAgreement] = useState();
  const [agreementFound, setAgreementFound] = useState(false);
  const [saveError, setSaveError] = useState("");

  //loading
  const [loading, setLoading] = useState(true);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const agreementID = params.get("id");

  // /**check if user has permission to view the agreement */
  function checkUserPermissions(agreement) {
    /**If user has permission N for agreements then they cant view the agreement */
    try {
      if (rolePermission === "N") throw new Error();

      /**If user is not Levven user and is neither the builder nor the installer in the agreement then they have no access to view the agreement */
      if (
        companyType !== "L" &&
        companyID !== agreement.builder.companyID &&
        (agreement.installer === null ||
          (agreement.installer !== null &&
            companyID !== agreement.installer.companyID))
      )
        throw new Error();

      setAgreement(agreement);
      setAgreementFound(true);
      setLoading(false);
    } catch (error) {
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: "You do not have permission to view this agreement",
        },
      });
    }
  }

  const getDataOfAgreement = () => {
    setLoading(true);
    agreementServices
      .getAgreementByID({
        IsEncrypted: true,
        AgreementID: decodeURIComponent(agreementID),
      })
      .then((response) => {
        if (response.data.agreement === null) {
          setAgreementFound(false);
        } else {
          checkUserPermissions(response.data.agreement);
        }
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setLoading(false);
      });
  };

  function checkPermissionsForAgreement() {
    if (jwtToken === "" || jwtToken === null || jwtToken === undefined) {
      history.push({
        pathname: "/login",
        state: {
          ToAgreementUrlPage: true,
          AgreementUrlPageID: agreementID,
        },
      });
    } else {
      getDataOfAgreement();
    }
  }

  useEffect(() => {
    //to change title of page
    document.title = "Levven Electronics - Customer Portal - Agreement Link";
    checkPermissionsForAgreement();
  }, []);

  return loading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="80vh"
    >
      <CircularProgress sx={{ color: "#0077c6" }} />
    </Grid>
  ) : agreementFound && agreement && agreement !== null ? (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <Link to="/Dashboard" style={{ textDecoration: "none" }}>
          <img
            src={LevvenLogo}
            alt=""
            width="141px"
            height="30px"
            style={{ marginBottom: "10px" }}
          />
        </Link>
      </div>

      <Container>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="stretch"
          style={{ padding: "0 150px" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper
              elevation={1}
              sx={{
                border: "1px solid #c4c4c4",
                padding: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ textAlign: "center", alignSelf: "flex-start" }}
              >
                <Typography variant="h5">
                  {agreement !== null ? agreement.name : null}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  elevation={1}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      textAlign="center"
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        fontWeight={"500"}
                        variant="h5"
                        component="span"
                      >
                        Overview
                      </Typography>
                      <Underline color="#0077C6" />
                    </Grid>
                    {agreement && agreement != null ? (
                      agreement.installer && agreement.installer !== null ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="body2">
                              {" "}
                              This agreement is between the builder{" "}
                              <b>{" " + agreement.builder.name + " "}</b>
                              and the installer{" "}
                              <b>{agreement.installer.name}</b> .{" "}
                            </Typography>
                          </Grid>
                        </>
                      ) : null
                    ) : null}
                    {agreement && agreement != null ? (
                      !agreement.installer && agreement.installer === null ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="body2">
                              {" "}
                              This agreement is for the builder{" "}
                              <b>{" " + agreement.builder.name + "."}</b>
                            </Typography>
                          </Grid>
                        </>
                      ) : null
                    ) : null}
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Typography variant="body2" fontWeight={"500"}>
                        {" "}
                        Default shipping method :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Typography variant="body2">
                        {agreement && agreement !== null
                          ? agreement.defaultShippingMethodCode === "P"
                            ? "Prepaid"
                            : agreement.defaultShippingMethodCode === "C"
                            ? "Prepaid and charge"
                            : agreement.defaultShippingMethodCode === "L"
                            ? "Ship with other goods"
                            : null
                          : null}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Typography variant="body2" fontWeight={"500"}>
                        {" "}
                        Real Estate type :{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Typography variant="body2">
                        {agreement && agreement !== null
                          ? agreement.realEstateType === "R"
                            ? "Residential"
                            : agreement.realEstateType === "C"
                            ? "Commertial"
                            : null
                          : null}{" "}
                      </Typography>
                    </Grid>

                    {agreement && agreement != null ? (
                      agreement.builderAudioAgreement ? (
                        <>
                          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                            <Typography variant="body2" fontWeight={500}>
                              Audio Included :
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Check />
                          </Grid>
                        </>
                      ) : null
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper
                  elevation={1}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      textAlign="center"
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        fontWeight={"500"}
                        variant="h5"
                        component="span"
                      >
                        Additional Pricing and Terms
                      </Typography>
                      <Underline color="#0077C6" />
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Typography variant="body2" fontWeight={500}>
                        {" "}
                        Builder Price for Levven Q :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Typography variant="body2">
                        {agreement && agreement != null
                          ? agreement.builder.country.description === "USA"
                            ? " $" + agreement.builderLevvenQPrice
                            : "C$ " + agreement.builderLevvenQPrice
                          : null}{" "}
                      </Typography>
                    </Grid>
                    {agreement && agreement != null ? (
                      agreement.installer && agreement.installer !== null ? (
                        <>
                          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                            <Typography variant="body2" fontWeight={500}>
                              {" "}
                              Default Controller Price:
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Typography variant="body2">
                              {agreement.builder.country.cur === "USD"
                                ? "$ " + agreement.installer.specialPrice
                                : "C$ " + agreement.installer.specialPrice}
                            </Typography>
                          </Grid>
                        </>
                      ) : null
                    ) : null}
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Typography variant="body2" fontWeight={500}>
                        {" "}
                        Payment terms :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Typography variant="body2">
                        {agreement && agreement !== null
                          ? agreement.paymentTerms === "S"
                            ? "Standard Terms"
                            : agreement.paymentTerms === "M"
                            ? "Management Special"
                            : null
                          : null}{" "}
                      </Typography>
                    </Grid>{" "}
                    {/* warrantyPeriod */}
                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                      <Typography variant="body2" fontWeight={500}>
                        {" "}
                        Warranty period :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Typography variant="body2">
                        {agreement && agreement != null
                          ? agreement.warrantyPeriod !== null
                            ? agreement.warrantyPeriod + " year(s)"
                            : null
                          : null}{" "}
                      </Typography>
                    </Grid>
                    {/* {agreement && agreement != null ? (
                      agreement.specialNegotiatedProjectPrice ? (
                        <>
                          <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                            <Typography variant="body2" fontWeight={500}>
                              {" "}
                              Special Negotiated Project Price?
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Typography variant="body2">
                              <Check />
                            </Typography>
                          </Grid>
                        </>
                      ) : null
                    ) : null} */}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid style={{ height: "100%" }}>
              <Paper
                elevation={1}
                sx={{
                  border: "1px solid #c4c4c4",
                  padding: "20px",
                  height: "100%",
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    textAlign="center"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={"500"}
                      component="span"
                    >
                      Default Products
                    </Typography>
                    <Underline color="#0077C6" />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" fontWeight={500}>
                      {" "}
                      Switch Style :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2">
                      {" "}
                      {agreement && agreement !== null
                        ? agreement.defaultSwitchStyle !== null
                          ? agreement.defaultSwitchStyle.description
                          : null
                        : null}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" fontWeight={500}>
                      {" "}
                      Switch Color :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" y>
                      {" "}
                      {agreement && agreement !== null
                        ? agreement.defaultSwitchColor !== null
                          ? agreement.defaultSwitchColor.description
                          : null
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" fontWeight={500}>
                      Switch:
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2">
                      {agreement && agreement != null
                        ? agreement.defaultSwitch !== null
                          ? agreement.defaultSwitch.partNbr
                          : "Not found"
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" fontWeight={500}>
                      Coated Switch:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2">
                      {agreement && agreement != null
                        ? agreement.defaultCoatedSwitch !== null
                          ? agreement.defaultCoatedSwitch.partNbr
                          : "Not Found"
                        : null}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2" fontWeight={500}>
                      Controller :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="body2">
                      {agreement && agreement != null
                        ? agreement.defaultController !== null
                          ? agreement.defaultController.partNbr
                          : null
                        : null}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ height: "10px" }}
                  ></Grid>
                  {agreement && agreement != null ? (
                    agreement.coatedSwitch ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body2" fontWeight={500}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Check style={{ marginRight: "10px" }} />
                            <p>
                              This builder requires the option of conformally
                              coated
                            </p>
                          </div>
                        </Typography>
                      </Grid>
                    ) : (
                      ""
                    )
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body2" fontWeight={500}>
                      {" "}
                      {agreement && agreement != null ? (
                        agreement.dimmerUpgradeQuantity !== null ? (
                          <Typography variant="body2" fontWeight={500}>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Check style={{ marginRight: "10px" }} />
                              {agreement.dimmerUpgradeQuantity} dimmers are
                              offered at same price as on/off controllers{" "}
                            </div>
                          </Typography>
                        ) : null
                      ) : null}{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  ) : !agreementFound ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
      }}
    >
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <CustomAlert>Agreement Not Found</CustomAlert>
      </Grid>
    </Grid>
  ) : null;
};

export default AgreementURLpage;
