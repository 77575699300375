import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const TakeoffStep = ({
  setDirty,
  takeoffs,
  takeoff,
  setTakeoff,
  takeoffError,
  setTakeoffError,
  setShippingMethod,
  setAgreement,
  orderID,
}) => {
  return (
    <div style={{ marginTop: "50px", width: "100%", marginBottom: "50px" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Select Takeoff (Base Plan)
          </Typography>
        </Grid>
        {/* Takeoff */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Autocomplete
            id="combo-box-demo"
            componentName="Field2"
            options={takeoffs}
            value={takeoff ? takeoff : ""}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={(event, value) => {
              if (value === null) {
                setShippingMethod();
              }
              setTakeoff(value);
              if (value && value !== null) setAgreement(value.agreement);
              if (takeoffError) setTakeoffError(false);
              setDirty();
            }}
            disabled={orderID !== 0}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Field2"
                label="Takeoff"
                fullWidth
                size="small"
                error={takeoffError}
                helperText={takeoffError ? "Takeoff field is empty" : ""}
                type="text"
                required
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
        Is this order for a model home?
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: windowWidth > 700 ? "400px" : "",
                  margin: "auto 0",
                  overflow: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  style={{ fontWeight: 100 }}
                >
                  Is this order for a Model Home?
                </Typography>
                <Checkbox
                  checked={forModelHome}
                  onClick={() => {
                    setForModelHome(!forModelHome);
                    setDirty();
                  }}
                  disabled={orderID !== 0}
                />
              </div>
            </Grid>
            <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: windowWidth > 700 ? "400px" : "",
                  margin: "auto 0",
                  overflow: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  component="span"
                  style={{
                    fontWeight: 100,
                  }}
                >
                  Apply special negotiatied Project Price?
                </Typography>
                <Checkbox
                  checked={specialNegotiated}
                  onClick={() => {
                    setSpecialNegotiated(!specialNegotiated);
                    setDirty();
                  }}
                  disabled={orderID !== 0}
                />
              </div>
            </Grid>
            <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
            {canCreateCompPP === true ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: windowWidth > 700 ? "400px" : "",
                    margin: "auto 0",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      fontWeight: 100,
                    }}
                  >
                    Is this a complementary Project Pack?
                  </Typography>
                  <Checkbox
                    checked={isCompPP}
                    onClick={() => {
                      setIsCompPP(!isCompPP);
                      setDirty();
                    }}
                    disabled={orderID !== 0}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Grid> */}
        <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
      </Grid>
    </div>
  );
};

export default TakeoffStep;
