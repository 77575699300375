import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import { Trash2, X } from "react-feather";
import ShippingAddressForm from "../ShippingAddressForm/ShippingAddressForm";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useSelector } from "react-redux";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//function to change tabs values
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ShippingAddressTabs({ setPageID, setPageLink }) {
  //used to send data to the next page
  const history = useHistory();

  //used to retrieve data sent from the previous page
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //fetch data from previous page
  const self = fromURL
    ? decryptedObject
      ? decryptedObject.self
        ? decryptedObject.self
        : false
      : false
    : location.state
    ? location.state.self
      ? location.state.self
      : false
    : false;

  //getting permission in redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? self === true
        ? state.user.user.user.role.ownCompany
        : state.user.user.user.role.clientCompany
      : ""
  );

  //fetch data from previous page
  const companyID = fromURL
    ? decryptedObject
      ? decryptedObject.companyID
        ? decryptedObject.companyID
        : ""
      : ""
    : location.state
    ? location.state.companyID
      ? location.state.companyID
      : ""
    : "";

  if (companyID === "") {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  //checks if a field has been changed in the shipping address form
  const [formChanged, setFormChanged] = useState(false);

  //to know which tab we are in
  const [value, setValue] = useState(0);

  //for delete button loading while delete process in underway
  const [loading, setLoading] = useState(false);

  //for saving the error in case of any during the shipping address deletion process
  const [deleteError, setDeleteError] = useState("");

  //to display the alert dialog before delete
  const [deleteRecord, setDeleteRecord] = useState(false);

  //getting data from state from previous page
  const shippingAddressID = fromURL
    ? decryptedObject
      ? decryptedObject.shippingAddressID
        ? decryptedObject.shippingAddressID
        : 0
      : 0
    : location.state
    ? location.state.shippingAddressID
      ? location.state.shippingAddressID
      : 0
    : 0;

  const companyName = fromURL
    ? decryptedObject
      ? decryptedObject.companyName
        ? decryptedObject.companyName
        : ""
      : ""
    : location.state
    ? location.state.companyName
      ? location.state.companyName
      : ""
    : "";

  const dataFromUrl = fromURL
    ? decryptedObject
      ? decryptedObject.dataObject
        ? decryptedObject.dataObject
        : null
      : null
    : null;

  const myCompany = fromURL
    ? decryptedObject
      ? decryptedObject.myCompany
        ? decryptedObject.myCompany
        : false
      : false
    : location.state
    ? location.state.myCompany
      ? location.state.myCompany
      : false
    : false;

  const handleChange = (event, newValue) => {
    //to change tabs
    setValue(newValue);
  };

  //used to access data and functions in child component
  const shippingRef = useRef(null);

  useEffect(() => {
    //for help button
    setPageLink("Companies");

    //for drawer menu
    if (self) {
      setPageID(-1);
    } else {
      setPageID(6);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deleteShippingAddress = () => {
    //delete selected shipping addresses by providing an array of their ID's
    setLoading(true);
    setDeleteRecord(false);
    const data = { ShippingAddresses: [shippingAddressID] };

    shippingaddressServices
      .deleteShippingAddress(data)
      .then((response) => {
        setLoading(false);
        history.push({
          pathname: "/company-information",
          state: {
            companyID: companyID,
            companyName: companyName,
            myCompany: myCompany,
            source: "Companies",
            tab: 1,
            success: true,
          },
        });
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };

  return (
    <Paper>
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Shipping Address Deletion"
          textContent={"Are you sure you want to delete this records?"}
          save={deleteShippingAddress}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      <Box
        sx={{
          width: "100%",
          paddingTop: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "auto",
          }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="always"
              key="1"
              color="inherit"
              onClick={() =>
                history.push({
                  pathname: self ? "/company-information" : "/Companies",
                  state: {
                    companyID: companyID,
                    companyName: companyName,
                    myCompany: myCompany,
                    source: "Companies",
                  },
                })
              }
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              {self ? "My Company" : "Companies"}
            </Link>

            {companyName ? (
              <Link
                underline="always"
                key="2"
                color="inherit"
                onClick={() =>
                  history.push({
                    pathname: "/company-information",
                    state: {
                      companyID: companyID,
                      companyName: companyName,
                      myCompany: myCompany,
                      source: "Companies",
                    },
                  })
                }
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                {companyName}
              </Link>
            ) : (
              ""
            )}

            <Link
              underline="always"
              key="3"
              color="inherit"
              onClick={() =>
                history.push({
                  pathname: "/company-information",
                  state: {
                    companyID: companyID,
                    companyName: companyName,
                    myCompany: myCompany,
                    source: "Companies",
                    tab: 1,
                  },
                })
              }
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              Shipping Addresses
            </Link>

            <Typography key="4" color="text.primary" sx={{ fontSize: "24px" }}>
              Details
            </Typography>
          </Breadcrumbs>
          {value === 0 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {rolePermission === "M" ? (
                <CustomButton
                  startIcon={<Trash2 />}
                  size="medium"
                  loading={loading}
                  toggle={() => setDeleteRecord(true)}
                >
                  Delete
                </CustomButton>
              ) : null}

              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  await shippingRef.current.setPristine();
                  history.push({
                    pathname: "/company-information",
                    state: {
                      companyID: companyID,
                      companyName: companyName,
                      myCompany: myCompany,
                      source: "Companies",
                      tab: 1,
                    },
                  });
                }}
              >
                Cancel
              </CustomButton>
            </div>
          ) : (
            ""
          )}
        </div>

        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs
            variant={"scrollable"}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Information"
              {...a11yProps(0)}
              style={{ margin: "50" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ShippingAddressForm
            shippingAddressID={shippingAddressID}
            create={false}
            companyID={companyID}
            permission={rolePermission}
            setFormChanged={setFormChanged}
            ref={shippingRef}
            companyName={companyName}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
}
