import { orderTypes } from "../types/orderTypes";

const initialState = {};

/*This is the user reducer where all user states are modified */

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderTypes.REDIRECT_TO_ORDER:
      return {
        ...state,
        toOrderID: action.payload.toOrderID,
        toOrderTab: action.payload.toOrderTab,
      };
    case orderTypes.RESET_TO_ORDER_ID:
      return {
        ...state,
        toOrderID: action.payload.toOrderID,
        toOrderTab: action.payload.toOrderTab,
      };
    default:
      return { ...state };
  }
};

export default orderReducer;
