import React, { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../../components/CustomButton/CustomButton";
import { accountServices } from "../../services/account.services";
import { useSelector, useDispatch } from "react-redux";
import { userTypes } from "../../redux/types/userTypes";
import { Typography } from "@mui/material";

export default function EndUserAgreementPage() {
  /*Local variables*/
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [agreementError, setAgreementError] = useState("");
  const [messageArray, setMessageArray] = useState([]);

  /* Get values from redux store*/
  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  /*Declare dispatch to dispatch redux actions*/
  const dispatch = useDispatch();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (true) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  /*Get the user agreement on the first render*/
  useEffect(() => {
    accountServices
      .getEndUserAgreement()
      .then((response) => {
        if (response) {
          setMessageArray(response.data.split("\n"));
          setMessageError("");
        }
      })
      .catch((error) => {
        setMessageError(error.response.data.error.error);
      });
  }, []);

  /*Accept user agreement and modify the field in redux store*/
  const acceptUserAgreement = () => {
    return (dispatch) => {
      accountServices
        .acceptEndUserAgreement({ userID: userID })
        .then(() => {
          dispatch({
            type: userTypes.UPDATE_END_USER_AGREEMENT,
            payload: true,
          });
        })
        .catch((error) => {
          setAgreementError(error.response.data.message);
        });
    };
  };

  /*Reject the user agreement and automatically logout the user*/
  const rejectUserAgreement = () => {
    return (dispatch) => {
      accountServices
        .logout()
        .then(() => {
          dispatch({
            type: userTypes.LOGOUT_USER,
          });
        })
        .catch((error) => {
          setAgreementError(error.response.data.message);
        });
    };
  };

  //to change title of page
  useEffect(() => {
    document.title =
      "Levven Electronics - Customer Portal - End User Agreement";
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Kindly read and approve the following End User Agreement to access
          Levven System.
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                // textAlign: "justify",
                // textJustify: "inter - word",
              }}
            >
              <p>
                {messageError !== ""
                  ? messageError
                  : messageArray &&
                    messageArray.map((paragraph) => {
                      return (
                        <div>
                          <p>{paragraph}</p>
                          <div style={{ height: "10px" }} />
                        </div>
                      );
                    })}
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton toggle={() => dispatch(rejectUserAgreement())}>
            I Do Not Agree
          </CustomButton>
          <CustomButton toggle={() => dispatch(acceptUserAgreement())}>
            I Agree
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
