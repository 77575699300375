import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../../CustomButton/CustomButton";
import SnackBar from "../../SnackBar/SnackBar";
import AlertDialogSlide from "../../AlertDialogSlide/AlertDialogSlide";
import { orderQuantitiesServices } from "../../../services/orderQuantities.service";
import { orderServices } from "../../../services/orders.service";
import { accountServices } from "../../../services/account.services";
import CustomModal from "../../CustomModal/CustomModal";
import { orderNotesServices } from "../../../services/orderNotes.services";
import { systemDataServices } from "../../../services/systemData.services";
import CustomAlert from "../../CustomAlert/CustomAlert";
import AlertSnackBar from "../../AlertSnackBar/AlertSnackBar";
import { invoicesServices } from "../../../services/invoices.services";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronsDown, ChevronUp } from "react-feather";
import { Grid } from "@mui/material";
import ProductQuantitiesAccordion from "./ProductQuantitiesAccordion";

export default function EditUnitPricesStep({
  openDrawerMenu,
  windowWidth,
  pageLoad,
  setPageLoad,
  orderID,
  filterDefaultControllerProducts,
  getLevvenQPosition,
  checkIfAccordionOpened,
  getPriceByRange,
  totalSwitches,
  setTotalSwitches,
  totalWallPlates,
  setTotalWallPlates,
  totalQuickMount,
  setTotalQuickMount,
  isRMA = false,
  totalOrderPrice,
  setTotalOrderPrice,
  rows,
  setRows,
  steps,
  setSteps,
  getError,
  setGetError,
  opened,
  setOpened,
  defUPC,
  setDefUPC,
  openedAccordion,
  setOpenedAccordion,
  specialNegotiated,
  setSpecialNegotiated,
  defaultPriceRanges,
  setDefaultPriceRanges,
  defaultController,
  setDefaultController,
  maxDimmer,
  setMaxDimmer,
  currency,
  setFormChanged,
  setCurrency,
  orderIsCompPP,
  setOrderIsCompPP,
  orderType,
  setOrderType,
  hasDefaultController,
  setHasDefaultController,
  levvenQ,
  setLevvenQ,
  isReseller = false,
}) {
  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  /**Get User Role */
  const userRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  //holds error during deletion
  const [deleteError, setDeleteError] = useState("");

  //holds errors if any
  const [error, setError] = useState("");

  const [priceResetLoading, setPriceResetLoading] = useState(false);

  /*Open and Close the modal containing the user that can submit order*/
  const [canSubmitModal, setCanSubmitModal] = useState(false);

  /*Open and Close the alert dialog when submitting an order*/
  const [canSubmitAlert, setCanSubmitAlert] = useState(false);

  const [resubmit, setResubmit] = useState(false);

  //changes if any change happened in the page
  const [changeMade, setChangeMade] = useState(false);

  const [showWarning2, setShowWarning2] = useState(false);
  const [showWarning3, setShowWarning3] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  /**In Case of Error while checking for edit order */
  const [editOrderError, setEditOrderError] = useState("");

  /*Warning dialog on submission*/
  const [submissionWarningOpen, setSubmissionWarningOpen] = useState(false);

  /*Flag to open and close the submission snackbar*/
  const [submitSnackBarOpen, setSubmitSnackBarOpen] = useState(false);

  //if changed happened to add product
  const [changed, setChanged] = React.useState(false);
  useEffect(() => {}, [changed]);

  const getDefaultUPCByRange = (qty, priceRanges) => {
    //function to return the default unit price for default controller
    if (orderIsCompPP === false) {
      if (qty !== 0) {
        for (var i = 0; i < priceRanges.length; i++) {
          if (priceRanges[i].to === null) {
            return priceRanges[i].price;
          } else if (qty >= priceRanges[i].from && qty <= priceRanges[i].to) {
            return priceRanges[i].price;
          }
        }
        return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    //function that updates the default unit price for default controller depending on change of the total number of switches
    if (pageLoad === false) {
      if (specialNegotiated === false) {
        getPriceByRange(totalSwitches, defaultPriceRanges, orderIsCompPP);
      }
    }
  }, [totalSwitches]);

  const updateUnitPrices = async () => {
    //updates unit prices of all products that have the default unit price for default controller on change of the default unit price for default controller
    var tmpArray = rows;
    var tmp = totalOrderPrice;
    console.log(rows);
    for (var i = 0; i < hasDefaultController.length; i++) {
      var previous = 0;

      if (
        tmpArray[hasDefaultController[i].index1][hasDefaultController[i].index2]
          .quantity !== null
      ) {
        // tmpArray[hasDefaultController[i].index1][
        //   hasDefaultController[i].index2
        // ].quantity.unitPrice = defUPC;

        //If price changed we keep value of new price changed else w eupdate it to newest default upc
        // tmpArray[hasDefaultController[i].index1][
        //   hasDefaultController[i].index2
        // ].quantity.editedUnitPrice =
        //   tmpArray[hasDefaultController[i].index1][
        //     hasDefaultController[i].index2
        //   ].quantity.priceChanged === false
        //     ? defUPC
        //     : tmpArray[hasDefaultController[i].index1][
        //         hasDefaultController[i].index2
        //       ].quantity.editedUnitPrice;

        /*------------*/
        if (
          tmpArray[hasDefaultController[i].index1][
            hasDefaultController[i].index2
          ].product.category.categoryCode === 1
        ) {
          if (
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].product.controller.productID === defaultController.productID ||
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].isDimmer === true
          ) {
            previous =
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total;
            if (
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.priceChanged === true
            ) {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.editedUnitPrice
                    // (tmpArray[hasDefaultController[i].index1][
                    //   hasDefaultController[i].index2
                    // ].quantity.editedUnitPrice +
                    //   tmpArray[hasDefaultController[i].index1][
                    //     hasDefaultController[i].index2
                    //   ].quantity.switchQuantity *
                    //     tmpArray[hasDefaultController[i].index1][
                    //       hasDefaultController[i].index2
                    //     ].quantity.editedSwitchUnitPrice)
                  ) * 100
                ) / 100;
            } else {
              console.log(tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity);

              console.log("Unit price: "+ tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.unitPrice);

              console.log("Edited unit price: "+ tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.editedUnitPrice);

              console.log("Switch quantity: "+ tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.switchQuantity );

              console.log("Switch unit price: "+tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.switchUnitPrice);

              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (
                        tmpArray[hasDefaultController[i].index1][
                          hasDefaultController[i].index2
                        ].quantity.unitPrice)
                  ) * 100
                ) / 100;
            }
          } else {
            previous =
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total;

            if (
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.priceChanged === true
            ) {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.editedUnitPrice
                    // (tmpArray[hasDefaultController[i].index1][
                    //   hasDefaultController[i].index2
                    // ].quantity.editedUnitPrice +
                    //   tmpArray[hasDefaultController[i].index1][
                    //     hasDefaultController[i].index2
                    //   ].quantity.switchQuantity *
                    //     tmpArray[hasDefaultController[i].index1][
                    //       hasDefaultController[i].index2
                    //     ].quantity.editedSwitchUnitPrice)
                  ) * 100
                ) / 100;
            } else {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.unitPrice)
                  ) * 100
                ) / 100;
            }
          }
        } else if (
          tmpArray[hasDefaultController[i].index1][
            hasDefaultController[i].index2
          ].product.productID === defaultController.productID
        ) {
          previous =
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total;

          if (
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.priceChanged === true
          ) {
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.quantity
                  ) *
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.editedUnitPrice
                ) * 100
              ) / 100;
          } else {
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.quantity
                  ) * defUPC
                ) * 100
              ) / 100;
          }
        }

        tmp = tmp - parseFloat(previous);
        tmp =
          tmp +
          parseFloat(
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total
          );
      }
    }
    setTotalOrderPrice(tmp);
    setRows(tmpArray);
    setPriceResetLoading(false);
  };

  useEffect(() => {
    //function that updates unit price values on change of default unit price
    if (pageLoad === false) {
      if (!specialNegotiated) {
        updateUnitPrices();
      }
    }
  }, [defUPC]);

  useEffect(() => {
    //function for rerendering so values are changes and updated on the display
    setRows(rows);
  }, [changeMade]);

  return (
    <>
      <SnackBar
        open={submitSnackBarOpen}
        message={"Order Submitted Successfully"}
        severity="success"
        handleClose={() => {
          setSubmitSnackBarOpen(false);
        }}
      />

      {editOrderError ? (
        <AlertDialogSlide
          title="Error"
          textContent={editOrderError}
          save={() => {
            setEditOrderError("");
          }}
          saveText="Close"
        />
      ) : (
        ""
      )}

      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}

      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Enter Your Quantities
          </Typography>
          <br />
          <Typography variant="h5" component="span">
            Insert your desired quantites for each product:
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!getError && pageLoad === false ? (
            <div
              style={{
                display: windowWidth >= 700 ? "flex" : "block",
                justifyContent: "right",
                margin: "0px 15px 25px 0px",
                gap: "15px",
              }}
            >
              <CustomButton
                startIcon={<ChevronsDown />}
                toggle={() => {
                  var tmp = [...opened];
                  setOpenedAccordion(tmp);
                }}
              >
                View Quantities
              </CustomButton>

              {windowWidth < 700 ? <div style={{ height: "10px" }} /> : null}
              <CustomButton
                startIcon={<ChevronUp />}
                toggle={() => setOpenedAccordion([])}
              >
                Collapse All
              </CustomButton>
            </div>
          ) : null}

          {pageLoad === false ? (
            <Grid container spacing={2} style={{ marginBottom: "30px" }}>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Number of Switches: {totalSwitches}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Number of Switch Plate Positions: {totalWallPlates}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Number of Quick Mount Switches: {totalQuickMount}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Price of {isRMA === false ? "Order" : "RMA"}:{" "}
                  {currency === "CAD" ? "C$" : "$"}{" "}
                  {parseFloat(Math.round(totalOrderPrice * 100) / 100).toFixed(
                    2
                  )}{" "}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          <ProductQuantitiesAccordion
            isReseller={isReseller}
            priceResetLoading={priceResetLoading}
            setPriceResetLoading={setPriceResetLoading}
            isCompPP={orderIsCompPP}
            isLevvenAdmin={
              userRole === "Levven Admin" && currentCompanyType === "L"
            }
            steps={steps}
            rows={rows}
            setRows={setRows}
            pageLoad={pageLoad}
            changeMade={changeMade}
            setChangeMade={setChangeMade}
            rolePermission={"M"}
            setPageLoad={setPageLoad}
            error={getError}
            totalOrderPrice={totalOrderPrice}
            orderStatusInTabs={"N"}
            setTotalOrderPrice={setTotalOrderPrice}
            defaultController={defaultController}
            windowWidth={windowWidth}
            setGetError={setGetError}
            specialNegotiated={specialNegotiated}
            defaultPriceRanges={defaultPriceRanges}
            defUPC={defUPC}
            getPriceByRange={getDefaultUPCByRange}
            setDirty={() => {}}
            currency={currency}
            orderType={orderType}
            totalSwitches={totalSwitches}
            setTotalSwitches={setTotalSwitches}
            totalWallPlates={totalWallPlates}
            setPristine={() => {}}
            setTotalWallPlates={setTotalWallPlates}
            totalQuickMount={totalQuickMount}
            opened={opened}
            setOpened={setOpened}
            openedAccordion={openedAccordion}
            setOpenedAccordion={setOpenedAccordion}
            setTotalQuickMount={setTotalQuickMount}
            setFormChanged={setFormChanged}
            orderID={orderID}
            maxDimmer={maxDimmer}
            getError={getError}
          />
        </Grid>
      </Grid>
    </>
  );
}
