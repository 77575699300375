import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the system data of the products */

const baseUrl = apiConfig + "/TakeoffQuantity";

const updateTakeoffQuantity = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateTakeoffQuantity", data);
};

const getTakeoffQuantitiesByTakeoffID = (data) => {
  return fetchWrapper.post(baseUrl + "/GetTakeoffQuantitiesByTakeoffID", data);
};

const duplicateTakeoffQuantities = (data) => {
  return fetchWrapper.post(baseUrl + "/DuplicateTakeoffQuantities", data);
};

const updateExistingTakeoffQuantities = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateExistingTakeoffQuantity", data);
};

export const takeoffQuantityServices = {
  updateTakeoffQuantity,
  getTakeoffQuantitiesByTakeoffID,
  duplicateTakeoffQuantities,
  updateExistingTakeoffQuantities,
};
