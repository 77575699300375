import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import UsersTableComponent from "../UsersTableComponent/UsersTableComponent";
import RolesTableComponent from "../RolesTableComponent/RolesTableComponent";
import ShippingAddressTableComponent from "../ShippingAddressTableComponent/ShippingAddressTableComponent";
import CustomButton from "../CustomButton/CustomButton";
import {
  Plus,
  RefreshCcw,
  Save,
  Trash2,
  UserCheck,
  UserX,
} from "react-feather";
import CompanyForm from "../CompanyForm/CompanyForm";
import { companyServices } from "../../services/company.services";
import UserForm from "../UserForm/UserForm";
import RoleForm from "../RoleForm/RoleForm";
import ShippingAddressForm from "../ShippingAddressForm/ShippingAddressForm";
import SnackBar from "../SnackBar/SnackBar";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { adminServices } from "../../services/admin.services";
import { X } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";
import session from "redux-persist/lib/storage/session";

function TabPanel(props) {
  //displays the tabs in the component
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//function to change tabs values
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompaniesTabs({ setPageID, setPageLink }) {
  const location = useLocation();
  const history = useHistory();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //get which tab to display
  const tab = fromURL
    ? decryptedObject
      ? decryptedObject.tab
        ? decryptedObject.tab
        : 0
      : 0
    : location.state
      ? location.state.tab
        ? location.state.tab
        : 0
      : 0;

  //get value from page we are coming from
  const companyID = fromURL
    ? decryptedObject
      ? decryptedObject.companyID
        ? decryptedObject.companyID
        : 0
      : 0
    : location.state
      ? location.state.companyID
        ? location.state.companyID
        : 0
      : 0;

  const countryID = fromURL
    ? decryptedObject
      ? decryptedObject.countryID
        ? decryptedObject.countryID
        : 0
      : 0
    : location.state
      ? location.state.countryID
        ? location.state.countryID
        : 0
      : 0;

  const [companyName, setCompanyName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.companyName
          ? decryptedObject.companyName
          : ""
        : ""
      : location.state
        ? location.state.companyName
          ? location.state.companyName
          : ""
        : ""
  );

  const myCompany = fromURL
    ? decryptedObject
      ? decryptedObject.myCompany
        ? decryptedObject.myCompany
        : false
      : false
    : location.state
      ? location.state.myCompany
        ? location.state.myCompany
        : false
      : false;

  const source = fromURL
    ? decryptedObject
      ? decryptedObject.source
        ? decryptedObject.source
        : ""
      : ""
    : location.state
      ? location.state.source
        ? location.state.source
        : ""
      : "";

  const countryCode = fromURL
    ? decryptedObject
      ? decryptedObject.countryCode
        ? decryptedObject.countryCode
        : ""
      : ""
    : location.state
      ? location.state.countryCode
        ? location.state.countryCode
        : ""
      : "";

  //get data from redux
  const clientCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientCompany : ""
  );

  const ownCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownCompany : ""
  );

  //redirection
  if ((companyID === 0 || companyName === "") && source === "") {
    history.push({
      pathname: "/dashboard",
      state: { redirectionError: FrontEndErrors["frontend_error1"] },
    });
  } else if (
    (myCompany && ownCompany === "N") ||
    (!myCompany && clientCompany === "N")
  ) {
    history.push({
      pathname: "/dashboard",
      state: { redirectionError: FrontEndErrors["frontend_error1"] },
    });
  }

  //holds which tab we want to display
  const [value, setValue] = useState(tab);

  //permissions
  const [companyAdmin, setCompanyAdmin] = useState(false);
  const [adminManage, setAdminManage] = useState(false);
  const [adminManageRole, setAdminManageRole] = useState(false);
  const [createAddress, setCreateAddress] = useState(false);
  const [receivedUserID, setReceivedUserID] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [roleName, setRoleName] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //make delete button load when deleting data
  const [buttonLoading, setButtonLoading] = useState(false);

  //make save button load when saving data
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  //if shipping address creation is saved to redisplay the table
  const [saved, setSaved] = useState(false);

  const [userFullName, setUserFullname] = useState("");

  //for display of users and roles form and tabs inside the company tabs
  const [createChangeUser, setCreateChangeUser] = useState(false);
  const [createChangeRole, setCreateChangeRole] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [createRole, setCreateRole] = useState(false);

  //if user or role deleted from user tabs or roles tabs
  const [success, setSuccess] = useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  //holds delete error message if any
  const [deleteError, setDeleteError] = useState("");

  //display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = useState(false);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );

  /*Get current user ID from Redux*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const [previousPageSnackbar, setPreviousPageSnackbar] = useState(
    location.state
      ? location.state.previousPageSnackbar
        ? location.state.previousPageSnackbar
        : ""
      : ""
  );

  /*Company Form Ref*/
  const companyRef = useRef(null);

  /*User Form Ref*/
  const userRef = useRef(null);

  /*Role Form Ref*/
  const roleRef = useRef(null);

  /*Shipping Form Ref*/
  const shippingRef = useRef(null);

  //get data from redux
  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const companyUsers = useSelector((state) =>
    state.user.user
      ? myCompany === true
        ? state.user.user.user.role.ownUsers
        : state.user.user.user.role.clientUsers
      : "N"
  );

  const companyRoles = useSelector((state) =>
    state.user.user
      ? myCompany === true
        ? state.user.user.user.role.ownRoles
        : state.user.user.user.role.clientRoles
      : "N"
  );

  useEffect(() => {
    //assign permissions for form
    window.scrollTo(0, 0);
    if (tab === 0) {
      setValue(0);
    }

    if (clientCompany === "M") {
      setAdminManage(true);
    }
    if (clientRoles === "M") {
      setAdminManageRole(true);
    }
    if (ownCompany === "M") {
      setCompanyAdmin(true);
    }
  }, [source]);

  const deleteCompany = () => {
    //delete companies based on array of their ID's
    const data = { Companies: [companyID] };
    setDeleteRecord(false);
    setButtonLoading(true);
    companyServices
      .deleteCompanies(data)
      .then((response) => {
        setButtonLoading(false);

        history.push({
          pathname: "/companies",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setButtonLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  const handleChange = (event, newValue) => {
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue)

    } else {
      setValue(newValue);
      setCreateUser(false);
      setCreateRole(false);
      setCreateAddress(false);
    }
  };


  const [tempVal, setTempVal] = useState(0);

  const handleRouteClick = (route) => {
    
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };
  

  const handleConfirm = () => {
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    sessionStorage.removeItem("unsavedChanges");
    if (tempVal === "/create-company") {
      history.push("/create-company");
    } 
      
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const handleSnackBarClosePreviousPage = (event, reason) => {
    setPreviousPageSnackbar("");
  };

  useEffect(() => {
    if (saved === true) {
      setSaved(false);
    }
    setCreateAddress(false);
  }, [saved]);

  useEffect(() => {
    //for drawer menu selected page
    if (myCompany === true) {
      setPageID(-1);
    } else {
      setPageID(6);
    }
    //for help button
    setPageLink("Companies");
  }, []);

  const activateUser = () => {
    //function to activate user(s)
    setButtonLoading(true);
    //if user inactive, we are reactivating him, else we are activating him
    const data = {
      AdminID: currentUserID,
      Users: [{ Email: username, CompanyID: companyID }],
      ReactivateUser: userStatus === "I" ? true : false,
      ResendActivationEmail: userStatus === "P" ? true : false,
    };

    adminServices
      .activateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUserStatus(userStatus === "I" ? "A" : "P");
        setMessage(
          userStatus === "P"
            ? "Activation Email Sent Successfully"
            : "User Activated Successfully"
        );
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };

  const deactivateUser = () => {
    setButtonLoading(true);
    //function to deactivate user(s)
    const data = {
      AdminID: currentUserID,
      Users: [{ Email: username, CompanyID: companyID }],
    };

    adminServices
      .deactivateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUserStatus("I");
        setMessage("User Deactivated Successfully");
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };

  /*Use Effects*/
  useEffect(() => {
    if (createChangeUser) {
      setUserFullname("");
      setUsername("");
    }
  }, [createChangeUser]);

  useEffect(() => {
    setUserFullname("");
    setUsername("");
  }, [value]);

  const handleCancel = async () => {
    //handle the user clicking Cancel in companies tabs
    if (value === 0) {
      await companyRef.current.setPristine();
      history.push("/companies");
    } else if (value === 1) {
      await shippingRef.current.setPristine();
      setCreateAddress(false);
      setFormChanged(false);
    } else if (value === 2) {
      await userRef.current.setPristine();
      setCreateUser(false);
      setUsername("");
      setFormChanged(false);
    } else if (value === 3) {
      await roleRef.current.setPristine();
      setCreateRole(false);
      setFormChanged(false);
    }
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      <Paper>
        {openSnackBar ? (
          <SnackBar
            open={openSnackBar}
            message={message !== "" ? message : "Saved Successfully"}
            severity="success"
            handleClose={handleSnackBarClose}
          />
        ) : null}
        {previousPageSnackbar ? (
          <SnackBar
            open={previousPageSnackbar !== ""}
            message={previousPageSnackbar}
            severity="success"
            handleClose={handleSnackBarClosePreviousPage}
          />
        ) : null}
        <SnackBar
          open={success}
          message={"Shipping Address Deleted Successfully"}
          severity="success"
          handleClose={() => {
            setSuccess(false);
          }}
        />
        {deleteRecord ? (
          <AlertDialogSlide
            title="Warning"
            textContent={"Are you sure you want to delete this record?"}
            save={deleteCompany}
            saveText="Yes"
            deleteDialog={true}
            unsave={() => setDeleteRecord(false)}
            unsaveText="No"
          />
        ) : null}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {!myCompany ? (
                <Link
                  underline="always"
                  key="1"
                  color="inherit"
                  href="/Companies"
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {source}
                </Link>
              ) : (
                ""
              )}
              ,
              {value !== 0 ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm()


                      setUserFullname("");
                      setUsername("");
                      setFormChanged(false);

                    } else {
                      setValue(0);
                      setUserFullname("");
                      setUsername("");
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {companyName}
                </Link>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {companyName}
                </Typography>
              )}
              {value === 1 && createAddress === false ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Shipping Addresses
                </Typography>
              ) : value === 1 && createAddress === true ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm()


                      setCreateAddress(false);
                      setUserFullname("");
                      setUsername("");
                      setFormChanged(false);

                    } else {
                      setValue(0);
                      setCreateAddress(false);
                      setUserFullname("");
                      setUsername("");
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Shipping Addresses
                </Link>
              ) : (
                ""
              )}
              {value === 2 && createUser === false ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Users
                </Typography>
              ) : value === 2 && createUser === true ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm()

                      setCreateUser(false);
                      setUserFullname("");
                      setUsername("");
                      setFormChanged(false);

                    } else {
                      setValue(0);
                      setCreateUser(false);
                      setUserFullname("");
                      setUsername("");
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Users
                </Link>
              ) : (
                ""
              )}
              {value === 2 && createUser === true ? (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {!createChangeUser ? userFullName : "New User"}
                </Typography>
              ) : (
                ""
              )}
              {value === 1 && createAddress === true ? (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  New Shipping Address
                </Typography>
              ) : (
                ""
              )}
              {value === 3 && createRole === false ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Roles
                </Typography>
              ) : value === 3 && createRole === true ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm()
                      setCreateRole(false);
                      setUserFullname("");
                      setUsername("");
                      setFormChanged(false);

                    } else {
                      setCreateRole(false);
                      setUserFullname("");
                      setUsername("");
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Roles
                </Link>
              ) : (
                ""
              )}
              {value === 3 && createRole === true ? (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {!createChangeRole ? roleName : "New Role"}
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {value === 0 && adminManage && !myCompany ? (
                <>
                  <Link  style={{ textDecoration: "none" }}>
                    <CustomButton
                      startIcon={<Plus />}
                      toggle={()=>handleRouteClick("/create-company")}

                    >
                      New Company
                    </CustomButton>
                  </Link>
                  <div style={{ width: "10px" }} />
                </>
              ) : null}
              {(value === 0 && adminManage) ||
                (value === 2 && createUser) ||
                (value === 3 && createRole) ? (
                <CustomButton
                  startIcon={<Save />}
                  toggle={() =>
                    value === 0
                      ? companyRef.current.checkRelatedOrders()
                      : value === 2
                        ? userRef.current.checkUserNotifInvoices()
                        : roleRef.current.save()
                  }
                  loading={tabButtonLoading}
                >
                  Save
                </CustomButton>
              ) : null}

              {value === 0 && adminManage && !myCompany ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Trash2 />}
                    toggle={() => setDeleteRecord(true)}
                    loading={buttonLoading}
                  >
                    Delete
                  </CustomButton>
                </>
              ) : null}
              {value === 2 && adminManage && username !== "" ? (
                <>
                  <div style={{ width: "10px" }} />
                  {userStatus === "A" ? (
                    <div>
                      <CustomButton
                        startIcon={<UserX />}
                        toggle={deactivateUser}
                        loading={buttonLoading}
                      >
                        Deactivate
                      </CustomButton>
                    </div>
                  ) : userStatus === "I" ||
                    userStatus === "N" ||
                    userStatus === "P" ? (
                    <div>
                      <CustomButton
                        startIcon={
                          userStatus === "P" ? <RefreshCcw /> : <UserCheck />
                        }
                        toggle={activateUser}
                        loading={buttonLoading}
                      >
                        {userStatus === "P"
                          ? "Resend Activation Email"
                          : "Activate"}
                      </CustomButton>
                    </div>
                  ) : null}
                </>
              ) : null}
              {(value === 0 && !myCompany) ||
                (value === 1 && createAddress) ||
                (value === 2 && createUser) ||
                (value === 3 && createRole) ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton startIcon={<X />} toggle={handleCancel}>
                    Cancel
                  </CustomButton>
                </>
              ) : null}
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Profile" {...a11yProps(0)} style={{ margin: "50" }} />
              <Tab
                label="Shipping Address"
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />

              {companyUsers !== "N" ? (
                <Tab label="Users" {...a11yProps(2)} style={{ margin: "50" }} />
              ) : null}
              {companyRoles !== "N" ? (
                <Tab label="Roles" {...a11yProps(3)} style={{ margin: "50" }} />
              ) : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CompanyForm
              adminManage={adminManage}
              companyAdmin={companyAdmin}
              receivedCompanyID={companyID}
              setOpenSnackBar={setOpenSnackBar}
              companyCountryCode={countryCode}
              setTabButtonLoading={setTabButtonLoading}
              setCompanyNameForTabs={setCompanyName}
              setFormChanged={setFormChanged}
              ref={companyRef}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!createAddress ? (
              <ShippingAddressTableComponent
                companyID={companyID}
                setCreateAddress={setCreateAddress}
                companyName={companyName}
                dataObject={dataObject ? dataObject : location.state}
              />
            ) : (
              <>
                <ShippingAddressForm
                  setSaved={setSaved}
                  create={true}
                  companyID={companyID}
                  countryIdentification={countryID}
                  setFormChanged={setFormChanged}
                  ref={shippingRef}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {companyUsers !== "N" ? (
              !createUser ? (
                <UsersTableComponent
                  setCreateUser={setCreateUser}
                  companyTable={true}
                  companyName={companyName}
                  companyID={companyID}
                  setCreateChangeUser={setCreateChangeUser}
                  locationToBeSent={fromURL ? decryptedObject : location?.state}
                  myCompany={myCompany}
                  dataObject={dataObject ? dataObject : location.state}
                />
              ) : (
                <>
                  <UserForm
                    create={createChangeUser}
                    createUser={createUser}
                    setCreateChangeUser={setCreateChangeUser}
                    receivedCompanyID={companyID}
                    receivedUserID={receivedUserID}
                    setReceivedUserID={setReceivedUserID}
                    setOpenSnackBar={setOpenSnackBar}
                    adminManage={adminManage}
                    setFormChanged={setFormChanged}
                    userStatus={userStatus}
                    setUserStatus={setUserStatus}
                    setTabButtonLoading={setTabButtonLoading}
                    setUserFullname={setUserFullname}
                    setUsernameForTabs={setUsername}
                    setMessage={setMessage}
                    ref={userRef}
                  />
                </>
              )
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {companyRoles !== "N" ? (
              !createRole ? (
                <RolesTableComponent
                  setCreateRole={setCreateRole}
                  companyRole={true}
                  compName={companyName}
                  myRoles={myCompany ? true : false}
                  companyID={companyID}
                  setCreateChangeRole={setCreateChangeRole}
                  locationToBeSent={fromURL ? decryptedObject : location?.state}
                  dataObject={dataObject ? dataObject : location.state}
                />
              ) : (
                <>
                  <RoleForm
                    create={createChangeRole}
                    createRole={createRole}
                    receivedCompanyID={companyID}
                    adminManage={adminManageRole}
                    setCreateChangeRole={setCreateChangeRole}
                    setOpenSnackBar={setOpenSnackBar}
                    setRoleName={setRoleName}
                    setMessage={setMessage}
                    setFormChanged={setFormChanged}
                    setTabButtonLoading={setTabButtonLoading}
                    ref={roleRef}
                  />
                </>
              )
            ) : null}
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
