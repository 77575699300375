import axios from "axios";
import { store } from "../redux/store";
import { orderTypes } from "../redux/types/orderTypes";
import { userTypes } from "../redux/types/userTypes";
import { accountServices } from "../services/account.services";

/*This file contains all the default api functions*/
/*State is used to get the user token for api calls requiring authentication*/

/*Logout function if unauthorized*/
const logoutUnauthorized = (id = 0) => {
  //function that clears redux values and logs out
  return async (dispatch) => {
    await accountServices.logout().then(() => {
      dispatch({
        type: userTypes.UNAUTH_USER,
      });

      if (id !== 0) {
        dispatch({
          type: orderTypes.REDIRECT_TO_ORDER,
          payload: id,
        });
        window.location.replace("/login");
      }
    });
  };
};

const get = (url, redirectID = 0) => {
  const state = store.getState();
  return axios
    .get(url, {
      headers: {
        Authorization: state.user.user
          ? "Bearer " + state.user.user.jwtToken
          : "",
      },
    })
    .catch((error) => {
      if (error.response.data.error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized(redirectID));
        return Promise.reject(error);
      } else {
        throw error;
      }
    });
};

const getAnon = (url, redirectID = 0) => {
  const state = store.getState();
  return axios
    .get(url, {
      headers: {
        Authorization: "",
      },
    })
    .catch((error) => {
      if (error.response.data.error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized(redirectID));
        return Promise.reject(error);
      } else {
        throw error;
      }
    });
};

const getFormData = (url, header) => {
  const state = store.getState();
  return axios.get(url, header).catch((error) => {
    var reader = new FileReader();
    reader.onload = function () {
      if (JSON.parse(reader.result).error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized());
        return Promise.reject(error);
      } else {
        throw error;
      }
    };
    reader.readAsText(error.response.data);
    throw error;
  });
};

const post = (url, data) => {
  const state = store.getState();
  return axios
    .post(url, data, {
      headers: {
        Authorization: state.user.user
          ? "Bearer " + state.user.user.jwtToken
          : "",
      },
    })
    .catch((error) => {
      if (error.response.data.error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized());
        return Promise.reject(error);
      } else {
        throw error;
      }
    });
};

const postFormData = (url, data, header) => {
  const state = store.getState();
  return axios.post(url, data, header).catch((error) => {
    if (error.response.data.error.errorCode === "errUnauth") {
      store.dispatch(logoutUnauthorized());
      return Promise.reject(error);
    } else {
      throw error;
    }
  });
};

const put = (url, data) => {
  const state = store.getState();
  return axios
    .put(url, data, {
      headers: {
        Authorization: state.user.user
          ? "Bearer " + state.user.user.jwtToken
          : "",
      },
    })
    .catch((error) => {
      if (error.response.data.error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized());
        return Promise.reject(error);
      } else {
        throw error;
      }
    });
};

const _delete = (url) => {
  const state = store.getState();
  return axios
    .delete(url, {
      headers: {
        Authorization: state.user.user
          ? "Bearer " + state.user.user.jwtToken
          : "",
      },
    })
    .catch((error) => {
      if (error.response.data.error.errorCode === "errUnauth") {
        store.dispatch(logoutUnauthorized());
        return Promise.reject(error);
      } else {
        throw error;
      }
    });
};

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  postFormData,
  getFormData,
  getAnon,
};
