import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";
import { Image } from "react-feather";
import { productServices } from "../../services/product.service";
import CustomButton from "../CustomButton/CustomButton";
import Modal from "../CustomModal/CustomModal";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const headCells = [
  {
    id: "image",
    numeric: true,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "productNumber",
    numeric: false,
    disablePadding: true,
    label: "Product Number",
  },
  {
    id: "product",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TakeoffQuantitiesTable({
  takeoffID,
  switchesNum,
  setSwitchesNum,
  platePositionNum,
  setPlatePositionNum,
  setIsQuickMount,
  rows,
  setFormChanged,
  setDirty,
  setPristine,
  setRows,
}) {
  //for permission from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  //for sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  //holds the page we are in in the table
  const [page, setPage] = React.useState(0);

  //holds the rows per page in the table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [imageSpecification, setImageSpecification] = React.useState("");

  //holds error while fetching image
  const [imageError, setImageError] = React.useState("");

  const [imageLoad, setImageLoad] = React.useState(false);

  const [displayImage, setDisplayImage] = React.useState(false);
  const [image, setImage] = React.useState();
  const [imageDescription, setImageDescription] = React.useState("");

  //holds the total number of records from the database that match the filters
  const [numberRecords, setNumberRecords] = React.useState(0);

  //holds an error if any while fetching data
  const [error, setError] = React.useState("");

  //makes page laod while fetching data is done
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds the characters allowed to be entered when checking in textfield
  const [numberArray, setNumberArray] = React.useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);

  const checkIfQuickMount = async (array) => {
    //function to check if a product is quick mount
    for (var i = 0; i < array.length; i++) {
      if (
        (array[i].takeoffQuantity.product.category.categoryCode === 1 ||
          array[i].takeoffQuantity.product.category.categoryCode === 3) &&
        array[i].takeoffQuantity.product.switchStyle.switchStyleCode === 2
      ) {
        setIsQuickMount(true);
        break;
      }
    }
  };

  const getDataFromDatabase = () => {
    //function to fetch data from the database
    var data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
      TakeoffID: takeoffID,
    };

    setPageLoad(true);

    takeoffQuantityServices
      .getTakeoffQuantitiesByTakeoffID(data)
      .then(async (response) => {
        await setNumberRecords(response.data.totalNumberOfRecords);
        await setRows(response.data.quantities);

        await checkIfQuickMount(response.data.quantities);

        if (response.data.quantities.length > 0) {
          setSwitchesNum(
            response.data.quantities[0].takeoffQuantity.takeoff.switchesNum
          );
          setPlatePositionNum(
            response.data.quantities[0].takeoffQuantity.takeoff
              .switchPlatePositionsNum
          );
        }

        setPageLoad(false);
      })
      .catch((error) => {
        setError(
          (prevError) => prevError + "\n" + error.response.data.error.error
        );
        setPageLoad(false);
      });
  };

  const updateQuantities = async (value, index) => {
    var oldValue, newValue, tmp;

    if (rows[index].takeoffQuantity.product.categoryCode === 1) {
      //in case assembly

      oldValue =
        parseInt(rows[index].takeoffQuantity.defaultQuantity) *
        parseInt(rows[index].takeoffQuantity.product.switchAssemblyType.qty);

      newValue =
        parseInt(value) *
        parseInt(rows[index].takeoffQuantity.product.switchAssemblyType.qty);

      tmp = parseInt(switchesNum);

      tmp = tmp - oldValue;
      tmp = tmp + newValue;

      setSwitchesNum(tmp);
    } else if (rows[index].takeoffQuantity.product.categoryCode === 3) {
      //in switch

      oldValue = parseInt(rows[index].takeoffQuantity.defaultQuantity);
      newValue = parseInt(value);

      tmp = parseInt(switchesNum);

      tmp = tmp - oldValue;
      tmp = tmp + newValue;

      setSwitchesNum(tmp);
    } else if (rows[index].takeoffQuantity.product.categoryCode === 5) {
      //in case wall plate

      oldValue =
        parseInt(rows[index].takeoffQuantity.defaultQuantity) *
        parseInt(rows[index].takeoffQuantity.product.gangNbr.description);

      newValue =
        parseInt(value) *
        parseInt(rows[index].takeoffQuantity.product.gangNbr.description);

      tmp = parseInt(platePositionNum);

      tmp = tmp - oldValue;
      tmp = tmp + newValue;

      setPlatePositionNum(tmp);
    }
  };

  /*Fetch Image File*/
  const fetchImageFile = async (id, path, description, specification) => {
    setImageLoad(true);
    setImageError("");
    if (path === null) return;
    setImageDescription(description);
    setImageSpecification(specification);
    setDisplayImage(true);
    if (path !== "") {
      await productServices
        .getImageFile(path, id)
        .then(async (response) => {
          await setImage(await URL.createObjectURL(response.data));
          await setImageLoad(false);
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setImageError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
          setImageLoad(false);
        });
    } else {
      setImageLoad(false);
    }
  };

  useEffect(() => {
    //we get the data for the rows
    setRows([]);
    window.scrollTo(0, 0);
    setError("");

    getDataFromDatabase();
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    //function for sorting
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {displayImage ? (
        <>
          <Modal openModal={displayImage} width="600px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                padding: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="div">
                  {imageDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!imageLoad ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {image ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <img
                              src={image}
                              alt="product"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                margin: "auto",
                                alignSelf: "center",
                                border: "1px solid #c4c4c4",
                                borderRadius: 8,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {imageError ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <CustomAlert>{imageError}</CustomAlert>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Typography variant="h7">
                                    <b>Specifications</b>
                                  </Typography>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      textJustify: "inter-word",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      {imageSpecification}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography variant="h7">
                                  <b>Specifications</b>
                                </Typography>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textJustify: "inter-word",
                                  }}
                                >
                                  <Typography variant="body2" component="span">
                                    {imageSpecification}
                                  </Typography>
                                </div>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setImage(null);
                      setDisplayImage(false);
                      setImageDescription("");
                      setImageSpecification("");
                    }}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Products/Quantities
            </Typography>
          </Toolbar>

          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {
                        "It seems there are no Takeoff Quantities related to this Takeoff"
                      }
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.takeoffQuantity.id}
                        sx={{ height: "130px" }}
                      >
                        <TableCell align="center" width={"10%"}>
                          {row.productImage ? (
                            <img
                              onClick={() =>
                                fetchImageFile(
                                  row.takeoffQuantity.product.productID,
                                  row.takeoffQuantity.product.imagePath
                                    ? row.takeoffQuantity.product.imagePath
                                    : "",
                                  row.takeoffQuantity.product.description,
                                  row.takeoffQuantity.product.specifications
                                )
                              }
                              src={row.productImage}
                              alt={row.takeoffQuantity.product.partNbr}
                              style={{
                                height: "100px",
                                objectFit: "cover",
                                alignSelf: "center",
                                borderRadius: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <>
                              <IconButton
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchImageFile(
                                    row.takeoffQuantity.product.productID,
                                    row.takeoffQuantity.product.imagePath
                                      ? row.takeoffQuantity.product.imagePath
                                      : "",
                                    row.takeoffQuantity.product.description,
                                    row.takeoffQuantity.product.specifications
                                  )
                                }
                              >
                                <Image />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.takeoffQuantity.product.partNbr}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.takeoffQuantity.product.description}
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            inputProps={{
                              style: { textAlign: "right" },
                              min: 0,
                            }}
                            variant="standard"
                            placeholder="0"
                            type="number"
                            defaultValue={row.takeoffQuantity.defaultQuantity}
                            onKeyPress={(e) => {
                              if (numberArray.indexOf(e.key) === -1) {
                                e.preventDefault();
                              }
                            }}
                            onChange={async (e) => {
                              //function that updates rows in array
                              async function updateRows(x) {
                                var tmp = rows;
                                if (x !== "") {
                                  tmp[index].takeoffQuantity.defaultQuantity =
                                    parseInt(x);
                                } else {
                                  tmp[index].takeoffQuantity.defaultQuantity =
                                    null;
                                }
                                setRows(tmp);
                              }

                              //if value is not empty
                              if (e.target.value !== "") {
                                await updateQuantities(
                                  parseInt(e.target.value),
                                  index
                                );
                                await updateRows(e.target.value);
                              } else {
                                //if value is empty
                                await updateQuantities(0, index);
                                await updateRows(e.target.value);
                              }

                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              setDirty();
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
