import React, { useState, useEffect } from "react";
import { orderQuantitiesServices } from "../services/orderQuantities.service";

export function useReviewOrder(orderID) {
  const [pageLoad, setPageLoad] = useState(false);
  const [numberRecords, setNumberRecords] = useState(0);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState("");
  const [totalNumberSwitches, setTotalNumberSwitches] = useState(0);
  const [totalNumberPlatePositions, setTotalNumberPlatePositions] = useState(0);
  const [totalPriceOrder, setTotalPriceOrder] = useState(0);

  function emptyError() {
    setError("");
  }

  useEffect(() => {
    const fetchData = (orderID) => {
      setPageLoad(true);
      orderQuantitiesServices
        .getOrderQuantitiesForOrderReview(orderID)
        .then((response) => {
          setTotalNumberSwitches(response.data.totalNumberOfSwitches);
          setTotalNumberPlatePositions(
            response.data.totalNumberOfSwitchPlatePositions
          );
          setTotalPriceOrder(response.data.totalPriceOfOrder);
          setRows(response.data.orderQuantities);
          setNumberRecords(response.data.orderQuantities.length);
          setPageLoad(false);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    };

    fetchData(orderID);
  }, [orderID]);

  return [
    pageLoad,
    numberRecords,
    rows,
    totalNumberSwitches,
    totalNumberPlatePositions,
    totalPriceOrder,
    error,
    emptyError,
  ];
}
