import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomButton from "../CustomButton/CustomButton";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* This component is used to dislay alerts */
export default function AlertDialogSlide({
  title,
  textContent,
  save,
  unsave,
  error,
  unsaveText,
  saveText,
  disableUnsave = false,
  children,
  saveButtonLoading = false,
  open = true,
  deleteDialog = false,
  unsaveLoading = false,
  devicePopup = false,
  disableSave = false,
}) {
  //-1 => yes
  //1 => no
  const [value, setValue] = React.useState(1);

  const handleArrowClick = () => {
    //on delete we can navigate between the right and left arrow keys
    //to change focus between yes and no buttons

    setValue(value * -1);
  };

  return (
    <div>
      <Dialog
        onKeyDown={(event) => {
          if (event?.key === "ArrowLeft" || event?.key === "ArrowRight") {
            //choose which button to focus
            handleArrowClick();
          } else if (deleteDialog && event?.key === "Enter") {
            //Select focused option
            if (value === -1) {
              setValue(1);
              save();
            } else if (value === 1) {
              setValue(1);
              unsave();
            }
          }
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <div>{children}</div>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={devicePopup ? { whiteSpace: "pre-line" } : {}}
          >
            {textContent}
          </DialogContentText>
          {error !== "" ? (
            <Typography mt={2} color="red">
              {error}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions onMouseOver={() => setValue(0)}>
          {unsave ? (
            <CustomButton
              focused={deleteDialog && value === 1 ? true : false}
              deleteDialog={deleteDialog}
              disable={disableUnsave}
              toggle={unsave}
              loading={unsaveLoading}
            >
              {unsaveText}
            </CustomButton>
          ) : null}
          {save ? (
            <CustomButton
              focused={deleteDialog && value === -1 ? true : false}
              deleteDialog={deleteDialog}
              toggle={save}
              disable={disableSave}
              loading={saveButtonLoading}
            >
              {saveText}
            </CustomButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
