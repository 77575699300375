import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, TextField, CircularProgress } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { emailTemplatesServices } from "../../services/emailTemplates.services";
import React, { useState, useRef, useEffect } from "react";
import SnackBar from "../SnackBar/SnackBar";
import { Save } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import CustomAlert from "../CustomAlert/CustomAlert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divStyle1: {
    width: "10px",
    height: "",
    "@media(max-width: 900px)": {
      width: "",
      height: "10px",
    },
  },
  buttonStyle1: {
    display: "flex",
    overflowX: "auto",
    flexDirection: "row",

    "@media(max-width: 900px)": {
      display: "block",
      overflowX: "auto",
      flexDirection: "row",
    },
  },
}));

export default function EmailTemplates({
  setFormChanged,
  setDirty,
  setPristine,
}) {
  const classes = useStyles();
  //pass reset
  const [passResetSubject, setPassResetSubject] = useState("");
  const [passResetIDSubject, setPassResetIDSubject] = useState("");
  const [passResetSubjectError, setpassResetSubjectError] = useState(false);
  const [passReset, setPassReset] = useState("");
  const [passResetID, setPassResetID] = useState("");
  const [passResetError, setpassResetError] = useState(false);

  // OTP request
  const [OTPrequestSubject, setOTPrequestSubject] = useState("");
  const [OTPrequestIDSubject, setOTPrequestIDSubject] = useState("");
  const [OTPrequestSubjectError, setOTPrequestSubjectError] = useState(false);
  const [OTPrequest, setOTPrequest] = useState("");
  const [OTPrequestID, setOTPrequestID] = useState("");
  const [OTPrequestError, setOTPrequestError] = useState(false);

  // user creation
  const [userCreationSubject, setUserCreationSubject] = useState("");
  const [userCreationIDSubject, setUserCreationIDSubject] = useState("");
  const [userCreationSubjectError, setuserCreationSubjectError] =
    useState(false);
  const [userCreation, setUserCreation] = useState("");
  const [userCreationID, setUserCreationID] = useState("");
  const [userCreationError, setuserCreationError] = useState(false);

  // Management Special Email
  const [managementSpecialEmailSubject, setManagementSpecialEmailSubject] =
    useState("");
  const [managementSpecialEmailIDSubject, setManagementSpecialEmailIDSubject] =
    useState("");
  const [
    managementSpecialSubjectEmailError,
    setManagementSpecialSubjectEmailError,
  ] = useState(false);
  const [managementSpecialEmail, setManagementSpecialEmail] = useState("");
  const [managementSpecialEmailID, setManagementSpecialEmailID] = useState("");
  const [managementSpecialEmailError, setManagementSpecialEmailError] =
    useState(false);

  //company welcome
  const [companyWelcomeSubject, setcompanyWelcomeSubject] = useState("");
  const [companyWelcomeIDSubject, setcompanyWelcomeIDSubject] = useState("");
  const [companyWelcomeSubjectError, setcompanyWelcomeSubjectError] =
    useState(false);
  const [companyWelcome, setcompanyWelcome] = useState("");
  const [companyWelcomeID, setcompanyWelcomeID] = useState("");
  const [companyWelcomeError, setcompanyWelcomeError] = useState(false);

  //levven sales assignment
  const [levvenSalesAssignmentSubject, setlevvenSalesAssignmentSubject] =
    useState("");
  const [levvenSalesAssignmentIDSubject, setlevvenSalesAssignmentIDSubject] =
    useState("");
  const [
    levvenSalesAssignmentSubjectError,
    setlevvenSalesAssignmentSubjectError,
  ] = useState(false);
  const [levvenSalesAssignment, setlevvenSalesAssignment] = useState("");
  const [levvenSalesAssignmentID, setlevvenSalesAssignmentID] = useState("");
  const [levvenSalesAssignmentError, setlevvenSalesAssignmentError] =
    useState(false);

  // Order Approval Email
  const [orderApprovalSubjectEmail, setOrderApprovalSubjectEmail] =
    useState("");
  const [orderApprovalSubjectEmailID, setOrderApprovalSubjectEmailID] =
    useState("");
  const [orderApprovalSubjectEmailError, setOrderApprovalSubjectEmailError] =
    useState(false);
  const [orderApprovalEmail, setOrderApprovalEmail] = useState("");
  const [orderApprovalEmailID, setOrderApprovalEmailID] = useState("");
  const [orderApprovalEmailError, setOrderApprovalEmailError] = useState(false);

  //Notification of Shipment
  const [
    notificationOfShipmentSubjectEmail,
    setNotificationOfShipmentSubjectEmail,
  ] = useState("");
  const [
    notificationOfShipmentSubjectEmailID,
    setNotificationOfShipmentSubjectEmailID,
  ] = useState("");
  const [
    notificationOfShipmentSubjectEmailError,
    setNotificationOfShipmentSubjectEmailError,
  ] = useState(false);
  const [notificationOfShipmentEmail, setNotificationOfShipmentEmail] =
    useState("");
  const [notificationOfShipmentEmailID, setNotificationOfShipmentEmailID] =
    useState("");
  const [
    notificationOfShipmentEmailError,
    setNotificationOfShipmentEmailError,
  ] = useState(false);

  //Notification of Invoice
  const [
    notificationOfInvoiceSubjectEmail,
    setNotificationOfInvoiceSubjectEmail,
  ] = useState("");
  const [
    notificationOfInvoiceSubjectEmailID,
    setNotificationOfInvoiceSubjectEmailID,
  ] = useState("");
  const [
    notificationOfInvoiceSubjectEmailError,
    setNotificationOfInvoiceSubjectEmailError,
  ] = useState(false);
  const [notificationOfInvoiceEmail, setNotificationOfInvoiceEmail] =
    useState("");
  const [notificationOfInvoiceEmailID, setNotificationOfInvoiceEmailID] =
    useState("");
  const [notificationOfInvoiceEmailError, setNotificationOfInvoiceEmailError] =
    useState(false);

  //Notification of RMA
  const [notificationOfRMAEmail, setNotificationOfRMAEmail] = useState("");
  const [notificationOfRMAEmailID, setNotificationOfRMAEmailID] = useState("");
  const [notificationOfRMAEmailError, setNotificationOfRMAEmailError] =
    useState(false);

  //Notification of Submit
  const [
    notificationOfSubmitSubjectEmail,
    setNotificationOfSubmitSubjectEmail,
  ] = useState("");
  const [
    notificationOfSubmitSubjectEmailID,
    setNotificationOfSubmitSubjectEmailID,
  ] = useState("");
  const [
    notificationOfSubmitSubjectEmailError,
    setNotificationOfSubmitSubjectEmailError,
  ] = useState(false);
  const [notificationOfSubmitEmail, setNotificationOfSubmitEmail] =
    useState("");
  const [notificationOfSubmitEmailID, setNotificationOfSubmitEmailID] =
    useState("");
  const [notificationOfSubmitEmailError, setNotificationOfSubmitEmailError] =
    useState(false);

  /**Order Fulfillment */
  const [orderFulfillmentEmail, setOrderFulfillmentEmail] = useState("");
  const [orderFulfillmentID, setOrderFulfillmentID] = useState("");
  const [orderFulfillmentError, setOrderFulfillmentError] = useState(false);
  const [orderFulfillmentSubjectEmail, setOrderFulfillmentSubjectEmail] =
    useState("");
  const [orderFulfillmentSubjectID, setOrderFulfillmentSubjectID] =
    useState("");
  const [orderFulfillmentSubjectError, setOrderFulfillmentSubjectError] =
    useState(false);

  // Draft Order Ready To Order
  const [draftOrderSubject, setDraftOrderSubject] = useState("");
  const [draftOrderIDSubject, setDraftOrderIDSubject] = useState("");
  const [draftOrderSubjectError, setDraftOrderSubjectError] = useState(false);
  const [draftOrder, setDraftOrder] = useState("");
  const [draftOrderID, setDraftOrderID] = useState("");
  const [draftOrderError, setDraftOrderError] = useState(false);

  /*Loading*/
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  // cursor position
  const [selectionStart, setSelectionStart] = React.useState();

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //Error
  const [error, setError] = useState("");
  const [getError, setGetError] = useState("");

  const [addOn, setAddOn] = useState("body");

  //Refs for email template textfields
  const managementSpecialEmailRef = useRef();
  const passwordResetRef = useRef();
  const otpRequestRef = useRef();
  const userCreationRef = useRef();
  const companyWelcomeRef = useRef();
  const levvenSalesAssignmentRef = useRef();
  const orderApprovalEmailRef = useRef();
  const notificationOfShipmentRef = useRef();
  const notificationOfInvoiceRef = useRef();
  const draftOrderRef = useRef();
  const notificationOfRMARef = useRef();
  const notificationOfSubmitRef = useRef();
  const orderFulfillmentRef = useRef();
  const managementSpecialEmailSubjectRef = useRef();
  const passwordResetSubjectRef = useRef();
  const otpRequestSubjectRef = useRef();
  const userCreationSubjectRef = useRef();
  const companyWelcomeSubjectRef = useRef();
  const levvenSalesAssignmentSubjectRef = useRef();
  const orderApprovalEmailSubjectRef = useRef();
  const notificationOfShipmentSubjectRef = useRef();
  const notificationOfInvoiceSubjectRef = useRef();
  const notificationOfRMASubjectRef = useRef();
  const notificationOfSubmitSubjectRef = useRef();
  const orderFulfillmentSubjectRef = useRef();
  const draftOrderSubjectRef = useRef();

  //functions to update selection start
  const updateSelectionStartDO = () => {
    setSelectionStart(draftOrderRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartL = () => {
    setSelectionStart(levvenSalesAssignmentRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartC = () => {
    setSelectionStart(companyWelcomeRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartU = () => {
    setSelectionStart(userCreationRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartO = () => {
    setSelectionStart(otpRequestRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartP = () => {
    setSelectionStart(passwordResetRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartM = () => {
    setSelectionStart(managementSpecialEmailRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartOA = () => {
    setSelectionStart(orderApprovalEmailRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartNoS = () => {
    setSelectionStart(notificationOfShipmentRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartNoI = () => {
    setSelectionStart(notificationOfInvoiceRef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartNoR = () => {
    setSelectionStart(notificationOfRMARef.current.selectionStart);
    setAddOn("body");
  };
  const updateSelectionStartNoT = () => {
    setSelectionStart(notificationOfSubmitRef.current.selectionStart);
    setAddOn("body");
  };
  const updateOrderFulfillment = () => {
    setSelectionStart(orderFulfillmentRef.current.selectionStart);
    setAddOn("body");
  };

  const updateSelectionStartSubjectL = () => {
    setSelectionStart(levvenSalesAssignmentSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectC = () => {
    setSelectionStart(companyWelcomeSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectDO = () => {
    setSelectionStart(draftOrderSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectU = () => {
    setSelectionStart(userCreationSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectO = () => {
    setSelectionStart(otpRequestSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectP = () => {
    setSelectionStart(passwordResetSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectM = () => {
    setSelectionStart(managementSpecialEmailSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectOA = () => {
    setSelectionStart(orderApprovalEmailSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectNoS = () => {
    setSelectionStart(notificationOfShipmentSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectNoI = () => {
    setSelectionStart(notificationOfInvoiceSubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectNoR = () => {
    setSelectionStart(notificationOfRMASubjectRef.current.selectionStart);
    setAddOn("subject");
  };
  const updateSelectionStartSubjectNoT = () => {
    setSelectionStart(notificationOfSubmitSubjectRef.current.selectionStart);
    setAddOn("subject");
  };

  const updateOrderFulfillmentSubject = () => {
    setSelectionStart(orderFulfillmentSubjectRef.current.selectionStart);
    setAddOn("subject");
  };

  //fetch data
  const getEmailTemplateTexts = () => {
    emailTemplatesServices
      .getEmailTemplates()
      .then((response) => {
        if (response) {
          response.data.forEach((element) => {
            if (element.templateDescription === "Reset password") {
              setPassReset(element.templateBody);
              setPassResetID(element.applicationEmailTemplateId);
              setPassResetSubject(element.templateSubject);
            }
            if (element.templateDescription === "Levven Order Approval Email") {
              setOrderApprovalEmail(element.templateBody);
              setOrderApprovalEmailID(element.applicationEmailTemplateId);
              setOrderApprovalSubjectEmail(element.templateSubject);
            }
            if (element.templateDescription === "Request OTP Email") {
              setOTPrequest(element.templateBody);
              setOTPrequestID(element.applicationEmailTemplateId);
              setOTPrequestSubject(element.templateSubject);
            }
            if (element.templateDescription === "Created user email") {
              setUserCreation(element.templateBody);
              setUserCreationID(element.applicationEmailTemplateId);
              setUserCreationSubject(element.templateSubject);
            }
            if (
              element.templateDescription ===
              "Management Special Account Levven Accounting Notification"
            ) {
              setManagementSpecialEmail(element.templateBody);
              setManagementSpecialEmailID(element.applicationEmailTemplateId);
              setManagementSpecialEmailSubject(element.templateSubject);
            }

            if (element.templateDescription === "Company welcome email") {
              setcompanyWelcome(element.templateBody);
              setcompanyWelcomeID(element.applicationEmailTemplateId);
              setcompanyWelcomeSubject(element.templateSubject);
            }
            if (
              element.templateDescription ===
              "Levven Sales Company Assigned Notification"
            ) {
              setlevvenSalesAssignment(element.templateBody);
              setlevvenSalesAssignmentID(element.applicationEmailTemplateId);
              setlevvenSalesAssignmentSubject(element.templateSubject);
            }
            if (element.templateDescription === "Notification of Shipment") {
              setNotificationOfShipmentEmail(element.templateBody);
              setNotificationOfShipmentEmailID(
                element.applicationEmailTemplateId
              );
              setNotificationOfShipmentSubjectEmail(element.templateSubject);
            }
            if (element.templateDescription === "Notification of Invoice") {
              setNotificationOfInvoiceEmail(element.templateBody);
              setNotificationOfInvoiceEmailID(
                element.applicationEmailTemplateId
              );
              setNotificationOfInvoiceSubjectEmail(element.templateSubject);
            }
            // if (element.templateDescription === "Notification of RMA") {
            //   setNotificationOfRMAEmail(element.templateBody);
            //   setNotificationOfRMAEmailID(element.applicationEmailTemplateId);
            // }
            if (
              element.templateDescription === "Notification of Order Submission"
            ) {
              setNotificationOfSubmitEmail(element.templateBody);
              setNotificationOfSubmitEmailID(
                element.applicationEmailTemplateId
              );
              setNotificationOfSubmitSubjectEmail(element.templateSubject);
            }
            if (element.templateDescription === "Levven Order Fulfillment") {
              setOrderFulfillmentEmail(element.templateBody);
              setOrderFulfillmentID(element.applicationEmailTemplateId);
              setOrderFulfillmentSubjectEmail(element.templateSubject);
            }

            if (
              element.templateDescription ===
              "Levven Draft Order Ready To Order"
            ) {
              setDraftOrder(element.templateBody);
              setDraftOrderID(element.applicationEmailTemplateId);
              setDraftOrderSubject(element.templateSubject);
            }
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setGetError(error.response.data.error.error);
      });
  };

  //function update for email templates
  const updateEmailTemplatesText = (array) => {
    var data = {
      templates: array,
    };
    emailTemplatesServices
      .updateEmailTemplates(data)
      .then((response) => {
        setOpenSnackBar(true);
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        setError(error.response.data.error.error);
      });
  };

  useEffect(() => {
    getEmailTemplateTexts();
  }, []);

  const saveButton = async (e) => {
    //function to update email template text
    e.preventDefault();
    setButtonLoading(true);

    if (OTPrequest === "") setOTPrequestError(true);
    if (orderApprovalEmail === "") setOrderApprovalEmailError(true);
    if (levvenSalesAssignment === "") setlevvenSalesAssignmentError(true);
    if (companyWelcome === "") setcompanyWelcomeError(true);
    if (userCreation === "") setuserCreationError(true);
    if (managementSpecialEmail === "") setManagementSpecialEmailError(true);
    if (notificationOfShipmentEmail === "")
      setNotificationOfShipmentEmailError(true);
    if (notificationOfInvoiceEmail === "")
      setNotificationOfInvoiceEmailError(true);
    if (notificationOfRMAEmail === "") setNotificationOfRMAEmailError(true);
    if (notificationOfSubmitEmail === "")
      setNotificationOfSubmitEmailError(true);
    if (orderFulfillmentEmail === "") setOrderFulfillmentError(true);
    if (draftOrder === "") setDraftOrderError(true);

    if (OTPrequestSubject === "") setOTPrequestSubjectError(true);
    if (orderApprovalSubjectEmail === "")
      setOrderApprovalSubjectEmailError(true);
    if (levvenSalesAssignmentSubject === "")
      setlevvenSalesAssignmentSubjectError(true);
    if (companyWelcomeSubject === "") setcompanyWelcomeSubjectError(true);
    if (userCreationSubject === "") setuserCreationSubjectError(true);
    if (managementSpecialEmailSubject === "")
      setManagementSpecialSubjectEmailError(true);
    if (notificationOfShipmentSubjectEmail === "")
      setNotificationOfShipmentSubjectEmailError(true);
    if (notificationOfInvoiceSubjectEmail === "")
      setNotificationOfInvoiceSubjectEmailError(true);
    if (notificationOfSubmitSubjectEmail === "")
      setNotificationOfSubmitSubjectEmailError(true);
    if (orderFulfillmentSubjectEmail === "")
      setOrderFulfillmentSubjectError(true);
    if (draftOrderSubject === "") setDraftOrderSubjectError(true);
    if (
      orderApprovalEmail === "" ||
      OTPrequest === "" ||
      passReset === "" ||
      draftOrderSubject === "" ||
      draftOrder === "" ||
      userCreation === "" ||
      levvenSalesAssignment === "" ||
      companyWelcome === "" ||
      managementSpecialEmail === "" ||
      orderFulfillmentEmail === "" ||
      orderApprovalSubjectEmail === "" ||
      OTPrequestSubject === "" ||
      passResetSubject === "" ||
      userCreationSubject === "" ||
      levvenSalesAssignmentSubject === "" ||
      companyWelcomeSubject === "" ||
      managementSpecialEmailSubject === "" ||
      orderFulfillmentSubjectEmail === ""
      //notificationOfShipmentEmail === ""
    ) {
      setButtonLoading(false);
      return;
    } else {
      var array = [
        {
          templateBody: companyWelcome,
          templateID: companyWelcomeID,
          templateSubject: companyWelcomeSubject,
        },
        {
          templateBody: managementSpecialEmail,
          templateID: managementSpecialEmailID,
          templateSubject: managementSpecialEmailSubject,
        },
        {
          templateBody: userCreation,
          templateID: userCreationID,
          templateSubject: userCreationSubject,
        },
        {
          templateBody: OTPrequest,
          templateID: OTPrequestID,
          templateSubject: OTPrequestSubject,
        },
        {
          templateBody: passReset,
          templateID: passResetID,
          templateSubject: passResetSubject,
        },
        {
          templateBody: levvenSalesAssignment,
          templateID: levvenSalesAssignmentID,
          templateSubject: levvenSalesAssignmentSubject,
        },
        {
          templateBody: orderApprovalEmail,
          templateID: orderApprovalEmailID,
          templateSubject: orderApprovalSubjectEmail,
        },
        {
          templateBody: notificationOfShipmentEmail,
          templateID: notificationOfShipmentEmailID,
          templateSubject: notificationOfShipmentSubjectEmail,
        },
        {
          templateBody: notificationOfInvoiceEmail,
          templateID: notificationOfInvoiceEmailID,
          templateSubject: notificationOfInvoiceSubjectEmail,
        },
        {
          templateBody: notificationOfSubmitEmail,
          templateID: notificationOfSubmitEmailID,
          templateSubject: notificationOfSubmitSubjectEmail,
        },
        {
          templateBody: orderFulfillmentEmail,
          templateID: orderFulfillmentID,
          templateSubject: orderFulfillmentSubjectEmail,
        },
        {
          templateBody: draftOrder,
          templateID: draftOrderID,
          templateSubject: draftOrderSubject,
        },
      ];

      updateEmailTemplatesText(array);

      // updateEmailTemplatesText(
      //   notificationOfRMAEmailID,
      //   notificationOfRMAEmail
      // );
      sessionStorage.removeItem("unsavedChanges");

      setFormChanged(false);
      setPristine();
    }
  };
  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setError("");
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  //function to add string at the position of the cursor
  function addstr(str, index, stringtoadd) {
    return (
      str.substring(0, index) +
      " " +
      stringtoadd +
      " " +
      str.substring(index, str.length)
    );
  }

  return (
    <>
      {error ? (
        <>
          <AlertSnackBar
            message={error}
            handleClose={handleCloseAlertSnackbar}
            open={error !== ""}
          />
        </>
      ) : (
        " "
      )}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="80vh"
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </Grid>
      ) : getError ? (
        <div
          style={{
            textAlign: "center",
            color: "red",
            marginTop: "50px",
            marginBottom: "30px",
          }}
        >
          <CustomAlert>{getError}</CustomAlert>
        </div>
      ) : (
        <>
          <form onSubmit={saveButton}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Company Welcome
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                       
                        setDirty();
                        if (addOn === "body")
                          setcompanyWelcome(
                            addstr(
                              companyWelcome,
                              selectionStart,
                              "{RecipientContactName}"
                            )
                          );
                        else if (addOn === "subject")
                          setcompanyWelcomeSubject(
                            addstr(
                              companyWelcomeSubject,
                              selectionStart,
                              "{RecipientContactName}"
                            )
                          );
                      }}
                    >
                      Insert Recipient Contact Name
                    </CustomButton>

                    <div className={classes.divStyle1}></div>

                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setcompanyWelcome(
                            addstr(companyWelcome, selectionStart, "<br>")
                          );
                        else if (addOn === "subject")
                          setcompanyWelcomeSubject(
                            addstr(
                              companyWelcomeSubject,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectC}
                    inputRef={companyWelcomeSubjectRef}
                    label="Company Welcome Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={companyWelcomeSubject}
                    error={companyWelcomeSubjectError}
                    helperText={
                      companyWelcomeSubjectError
                        ? "Company Welcome Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setcompanyWelcomeSubject(e.target.value);
                      if (companyWelcomeSubjectError)
                        setcompanyWelcomeSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={companyWelcomeRef}
                    label="Company Welcome"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={companyWelcome}
                    error={companyWelcomeError}
                    helperText={
                      companyWelcomeError
                        ? "Company Welcome field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setcompanyWelcome(e.target.value);
                      if (companyWelcomeError) setcompanyWelcomeError(false);
                    }}
                    onSelect={updateSelectionStartC}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Levven Sales Assignment
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setlevvenSalesAssignment(
                            addstr(
                              levvenSalesAssignment,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                        else if (addOn === "subject")
                          setlevvenSalesAssignmentSubject(
                            addstr(
                              levvenSalesAssignmentSubject,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setlevvenSalesAssignment(
                            addstr(
                              levvenSalesAssignment,
                              selectionStart,
                              "{SenderCompanyName}"
                            )
                          );
                        else if (addOn === "subject")
                          setlevvenSalesAssignmentSubject(
                            addstr(
                              levvenSalesAssignmentSubject,
                              selectionStart,
                              "{SenderCompanyName}"
                            )
                          );
                      }}
                    >
                      Insert Sender Company Name
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setlevvenSalesAssignment(
                            addstr(
                              levvenSalesAssignment,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setlevvenSalesAssignmentSubject(
                            addstr(
                              levvenSalesAssignmentSubject,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectL}
                    inputRef={levvenSalesAssignmentSubjectRef}
                    label="Levven Sales Assignment Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={levvenSalesAssignmentSubject}
                    error={levvenSalesAssignmentSubjectError}
                    helperText={
                      levvenSalesAssignmentSubjectError
                        ? "Levven Sales Assignment Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setlevvenSalesAssignmentSubject(e.target.value);
                      if (levvenSalesAssignmentSubjectError)
                        setlevvenSalesAssignmentSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={levvenSalesAssignmentRef}
                    label="Levven Sales Assignment"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={levvenSalesAssignment}
                    error={levvenSalesAssignmentError}
                    helperText={
                      levvenSalesAssignmentError
                        ? "Levven Sales Assignment field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setlevvenSalesAssignment(e.target.value);
                      if (levvenSalesAssignmentError)
                        setlevvenSalesAssignmentError(false);
                    }}
                    onSelect={updateSelectionStartL}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                User Creation/Verification
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setUserCreation(
                            addstr(userCreation, selectionStart, "{username}")
                          );
                        else if (addOn === "subject")
                          setUserCreationSubject(
                            addstr(
                              userCreationSubject,
                              selectionStart,
                              "{username}"
                            )
                          );
                      }}
                    >
                      Insert Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setUserCreation(
                            addstr(userCreation, selectionStart, "{receiver}")
                          );
                        else if (addOn === "subject")
                          setUserCreationSubject(
                            addstr(
                              userCreationSubject,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setUserCreation(
                            addstr(userCreation, selectionStart, "<br>")
                          );
                        else if (addOn === "subject")
                          setUserCreationSubject(
                            addstr(userCreationSubject, selectionStart, "<br>")
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectU}
                    inputRef={userCreationSubjectRef}
                    label="User Creation Template Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={userCreationSubject}
                    error={userCreationSubjectError}
                    helperText={
                      userCreationSubjectError
                        ? "User Creation Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setUserCreationSubject(e.target.value);
                      if (userCreationSubjectError)
                        setuserCreationSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={userCreationRef}
                    label="User Creation Template"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={userCreation}
                    error={userCreationError}
                    helperText={
                      userCreationError ? "User Creation field is empty" : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setUserCreation(e.target.value);
                      if (userCreationError) setuserCreationError(false);
                    }}
                    onSelect={updateSelectionStartU}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                OTP Request
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOTPrequest(
                            addstr(OTPrequest, selectionStart, "{username}")
                          );
                        else if (addOn === "subject")
                          setOTPrequestSubject(
                            addstr(
                              OTPrequestSubject,
                              selectionStart,
                              "{username}"
                            )
                          );
                      }}
                    >
                      Insert Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOTPrequest(
                            addstr(OTPrequest, selectionStart, "{otpCode}")
                          );
                        else if (addOn === "subject")
                          setOTPrequestSubject(
                            addstr(
                              OTPrequestSubject,
                              selectionStart,
                              "{otpCode}"
                            )
                          );
                      }}
                    >
                      Insert OTP Code
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOTPrequest(
                            addstr(OTPrequest, selectionStart, "<br>")
                          );
                        else if (addOn === "subject")
                          setOTPrequestSubject(
                            addstr(OTPrequestSubject, selectionStart, "<br>")
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectO}
                    inputRef={otpRequestSubjectRef}
                    label="OTP Request Template Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={OTPrequestSubject}
                    error={OTPrequestSubjectError}
                    helperText={
                      OTPrequestSubjectError
                        ? "OTP Request Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOTPrequestSubject(e.target.value);
                      if (OTPrequestSubjectError)
                        setOTPrequestSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={otpRequestRef}
                    label="OTP Request Template"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={OTPrequest}
                    error={OTPrequestError}
                    helperText={
                      OTPrequestError ? "OTP Request field is empty" : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOTPrequest(e.target.value);
                      if (OTPrequestError) setOTPrequestError(false);
                    }}
                    onSelect={updateSelectionStartO}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Password Reset
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setPassReset(
                            addstr(passReset, selectionStart, "{username}")
                          );
                        else if (addOn === "subject")
                          setPassResetSubject(
                            addstr(
                              passResetSubject,
                              selectionStart,
                              "{username}"
                            )
                          );
                      }}
                    >
                      Insert Username
                    </CustomButton>

                    <div className={classes.divStyle1}></div>

                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setPassReset(
                            addstr(passReset, selectionStart, "<br>")
                          );
                        else if (addOn === "subject")
                          setPassResetSubject(
                            addstr(passResetSubject, selectionStart, "<br>")
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    inputRef={passwordResetSubjectRef}
                    onSelect={updateSelectionStartSubjectP}
                    label="Password Reset Template Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={passResetSubject}
                    error={passResetSubjectError}
                    helperText={
                      passResetSubjectError
                        ? "Password Reset Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setPassResetSubject(e.target.value);
                      if (passResetSubjectError)
                        setpassResetSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={passwordResetRef}
                    label="Password Reset Template"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={passReset}
                    error={passResetError}
                    helperText={
                      passResetError ? "Password Reset field is empty" : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setPassReset(e.target.value);
                      if (passResetError) setpassResetError(false);
                    }}
                    onSelect={updateSelectionStartP}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Management Special Email
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setManagementSpecialEmail(
                            addstr(
                              managementSpecialEmail,
                              selectionStart,
                              "{RecipientCompanyName}"
                            )
                          );
                        else if (addOn === "subject")
                          setManagementSpecialEmailSubject(
                            addstr(
                              managementSpecialEmailSubject,
                              selectionStart,
                              "{RecipientCompanyName}"
                            )
                          );
                      }}
                    >
                      Insert Company
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setManagementSpecialEmail(
                            addstr(
                              managementSpecialEmail,
                              selectionStart,
                              "{LevvenAgreement}"
                            )
                          );
                        else if (addOn === "subject")
                          setManagementSpecialEmailSubject(
                            addstr(
                              managementSpecialEmailSubject,
                              selectionStart,
                              "{LevvenAgreement}"
                            )
                          );
                      }}
                    >
                      Insert Levven Agreement
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setManagementSpecialEmail(
                            addstr(
                              managementSpecialEmail,
                              selectionStart,
                              " {LevvenAgreementURL}"
                            )
                          );
                        else if (addOn === "subject")
                          setManagementSpecialEmailSubject(
                            addstr(
                              managementSpecialEmailSubject,
                              selectionStart,
                              " {LevvenAgreementURL}"
                            )
                          );
                      }}
                    >
                      Insert Levven Agreement URL
                    </CustomButton>
                    <div className={classes.divStyle1}></div>

                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setManagementSpecialEmail(
                            addstr(
                              managementSpecialEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setManagementSpecialEmailSubject(
                            addstr(
                              managementSpecialEmailSubject,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectM}
                    inputRef={managementSpecialEmailSubjectRef}
                    label="Management Special Email Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={managementSpecialEmailSubject}
                    error={managementSpecialSubjectEmailError}
                    helperText={
                      managementSpecialSubjectEmailError
                        ? "Management Special Email Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setManagementSpecialEmailSubject(e.target.value);
                      if (managementSpecialSubjectEmailError)
                        setManagementSpecialSubjectEmailError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={managementSpecialEmailRef}
                    label="Management Special Email"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={managementSpecialEmail}
                    error={managementSpecialEmailError}
                    helperText={
                      managementSpecialEmailError
                        ? "Management Special Email field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setManagementSpecialEmail(e.target.value);
                      if (managementSpecialEmailError)
                        setManagementSpecialEmailError(false);
                    }}
                    onSelect={updateSelectionStartM}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Order Approval Email
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderApprovalEmail(
                            addstr(
                              orderApprovalEmail,
                              selectionStart,
                              "{sender}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderApprovalSubjectEmail(
                            addstr(
                              orderApprovalSubjectEmail,
                              selectionStart,
                              "{sender}"
                            )
                          );
                      }}
                    >
                      Insert Sender Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderApprovalEmail(
                            addstr(
                              orderApprovalEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderApprovalSubjectEmail(
                            addstr(
                              orderApprovalSubjectEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>

                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderApprovalEmail(
                            addstr(orderApprovalEmail, selectionStart, "<br>")
                          );
                        else if (addOn === "subject")
                          setOrderApprovalSubjectEmail(
                            addstr(
                              orderApprovalSubjectEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    {/* <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderApprovalEmail(
                            addstr(
                              orderApprovalEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderApprovalSubjectEmail(
                            addstr(
                              orderApprovalSubjectEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                      }}
                    >
                      Insert Transaction Type
                    </CustomButton> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    inputRef={orderApprovalEmailSubjectRef}
                    onSelect={updateSelectionStartSubjectOA}
                    label="Order Approval Email Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={orderApprovalSubjectEmail}
                    error={orderApprovalSubjectEmailError}
                    helperText={
                      orderApprovalSubjectEmailError
                        ? "Order Approval Email Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOrderApprovalSubjectEmail(e.target.value);
                      if (orderApprovalSubjectEmailError)
                        setOrderApprovalSubjectEmailError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={orderApprovalEmailRef}
                    label="Order Approval Email"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={orderApprovalEmail}
                    error={orderApprovalEmailError}
                    helperText={
                      orderApprovalEmailError
                        ? "Order Approval Email field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOrderApprovalEmail(e.target.value);
                      if (orderApprovalEmailError)
                        setOrderApprovalEmailError(false);
                    }}
                    onSelect={updateSelectionStartOA}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Notification of Shipment
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                      }}
                    >
                      Insert NAV SO No.
                    </CustomButton>

                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenShipmentNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenShipmentNumber}"
                            )
                          );
                      }}
                    >
                      Insert NAV Shipment Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                      }}
                    >
                      Insert Purchase Order Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                      }}
                    >
                      Insert Transaction Type
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                      }}
                    >
                      Insert Order Creator
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfShipmentEmail(
                            addstr(
                              notificationOfShipmentEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfShipmentSubjectEmail(
                            addstr(
                              notificationOfShipmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                      }}
                    >
                      Insert Requested Date
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectNoS}
                    inputRef={notificationOfShipmentSubjectRef}
                    label="Notification of Shipment Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfShipmentSubjectEmail}
                    error={notificationOfShipmentSubjectEmailError}
                    helperText={
                      notificationOfShipmentSubjectEmailError
                        ? "Notification of Shipment Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfShipmentSubjectEmail(e.target.value);
                      if (notificationOfShipmentSubjectEmailError)
                        setNotificationOfShipmentSubjectEmailError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={notificationOfShipmentRef}
                    label="Notification of Shipment"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfShipmentEmail}
                    error={notificationOfShipmentEmailError}
                    helperText={
                      notificationOfShipmentEmailError
                        ? "Notification of Shipment field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfShipmentEmail(e.target.value);
                      if (notificationOfShipmentEmailError)
                        setNotificationOfShipmentEmailError(false);
                    }}
                    onSelect={updateSelectionStartNoS}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Notification of Invoice
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                      }}
                    >
                      Insert NAV SO No.
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenInvoiceNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenInvoiceNumber}"
                            )
                          );
                      }}
                    >
                      Insert NAV Invoice Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                      }}
                    >
                      Insert Purchase Order Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                      }}
                    >
                      Insert Transaction Type
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                      }}
                    >
                      Insert Order Creator
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfInvoiceEmail(
                            addstr(
                              notificationOfInvoiceEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfInvoiceSubjectEmail(
                            addstr(
                              notificationOfInvoiceSubjectEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                      }}
                    >
                      Insert Requested Date
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectNoI}
                    inputRef={notificationOfInvoiceSubjectRef}
                    label="Notification of Invoices Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfInvoiceSubjectEmail}
                    error={notificationOfInvoiceSubjectEmailError}
                    helperText={
                      notificationOfInvoiceSubjectEmailError
                        ? "Notification of Invoice Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfInvoiceSubjectEmail(e.target.value);
                      if (notificationOfInvoiceSubjectEmailError)
                        setNotificationOfInvoiceSubjectEmailError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={notificationOfInvoiceRef}
                    label="Notification of Invoices"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfInvoiceEmail}
                    error={notificationOfInvoiceEmailError}
                    helperText={
                      notificationOfInvoiceEmailError
                        ? "Notification of Invoices field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfInvoiceEmail(e.target.value);
                      if (notificationOfInvoiceEmailError)
                        setNotificationOfInvoiceEmailError(false);
                    }}
                    onSelect={updateSelectionStartNoI}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {/* <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Notification of RMA
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        setNotificationOfRMAEmail(
                          addstr(notificationOfRMAEmail, selectionStart, "<br>")
                        );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        setNotificationOfRMAEmail(
                          addstr(
                            notificationOfRMAEmail,
                            selectionStart,
                            "{receiver}"
                          )
                        );
                      }}
                    >
                      Insert Receiver Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        setNotificationOfRMAEmail(
                          addstr(
                            notificationOfRMAEmail,
                            selectionStart,
                            "{LevvenOrderID}"
                          )
                        );
                      }}
                    >
                      Insert NAV SO No.
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        setNotificationOfRMAEmail(
                          addstr(
                            notificationOfRMAEmail,
                            selectionStart,
                            "{LevvenPurchaseOrderNumber}"
                          )
                        );
                      }}
                    >
                      Insert Purchase Order Number
                    </CustomButton>
                  </div>
                </Grid>
                <TextField
                  multiline
                  inputRef={notificationOfRMARef}
                  label="Notification of RMA"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={notificationOfRMAEmail}
                  error={notificationOfRMAEmailError}
                  helperText={
                    notificationOfRMAEmailError
                      ? "Notification of RMA field is empty"
                      : ""
                  }
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                    setDirty();
                    setNotificationOfRMAEmail(e.target.value);
                    if (notificationOfRMAEmailError)
                      notificationOfRMAEmail(false);
                  }}
                  onSelect={updateSelectionStartNoR}
                />
              </Grid>
            </CardContent> */}
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Notification of Submit
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver Username
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                      }}
                    >
                      Insert Order ID
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                      }}
                    >
                      Insert Purchase Order Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                      }}
                    >
                      Insert Transaction Type
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                      }}
                    >
                      Insert Order Creator
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setNotificationOfSubmitEmail(
                            addstr(
                              notificationOfSubmitEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                        else if (addOn === "subject")
                          setNotificationOfSubmitSubjectEmail(
                            addstr(
                              notificationOfSubmitSubjectEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                      }}
                    >
                      Insert Requested Date
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    inputRef={notificationOfSubmitSubjectRef}
                    onSelect={updateSelectionStartSubjectNoT}
                    label="Notification of Submit Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfSubmitSubjectEmail}
                    error={notificationOfSubmitSubjectEmailError}
                    helperText={
                      notificationOfSubmitSubjectEmailError
                        ? "Notification of Submit Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfSubmitSubjectEmail(e.target.value);
                      if (notificationOfSubmitSubjectEmailError)
                        setNotificationOfSubmitSubjectEmailError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={notificationOfSubmitRef}
                    label="Notification of Submit"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={notificationOfSubmitEmail}
                    error={notificationOfSubmitEmailError}
                    helperText={
                      notificationOfSubmitEmailError
                        ? "Notification of Submit field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setNotificationOfSubmitEmail(e.target.value);
                      if (notificationOfSubmitEmailError)
                        setNotificationOfSubmitEmailError(false);
                    }}
                    onSelect={updateSelectionStartNoT}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Order Fulfillment
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "<br>"
                            )
                          );
                      }}
                    >
                      Insert New Line
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                      }}
                    >
                      Nav SO Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{LevvenPurchaseOrderNumber}"
                            )
                          );
                      }}
                    >
                      Avenue PO Number
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{SenderCompanyName}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{SenderCompanyName}"
                            )
                          );
                      }}
                    >
                      Customer Name
                    </CustomButton>

                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{Country}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{Country}"
                            )
                          );
                      }}
                    >
                      Customer Country
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{LevvenTransactionType}"
                            )
                          );
                      }}
                    >
                      Insert Transaction Type
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                      }}
                    >
                      Insert Order Creator
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      sx={{ whiteSpace: "normal", height: "fit" }}
                      editTableColumnsButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                        position: "absolute",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setOrderFulfillmentEmail(
                            addstr(
                              orderFulfillmentEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                        else if (addOn === "subject")
                          setOrderFulfillmentSubjectEmail(
                            addstr(
                              orderFulfillmentSubjectEmail,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                      }}
                    >
                      Insert Requested Date
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    inputRef={orderFulfillmentSubjectRef}
                    onSelect={updateOrderFulfillmentSubject}
                    label="Order Fulfillment Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={orderFulfillmentSubjectEmail}
                    error={orderFulfillmentSubjectError}
                    helperText={
                      orderFulfillmentSubjectError
                        ? "Order Fulfillment Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOrderFulfillmentSubjectEmail(e.target.value);
                      if (orderFulfillmentSubjectError)
                        setOrderFulfillmentSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={orderFulfillmentRef}
                    label="Order Fulfillment"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={orderFulfillmentEmail}
                    error={orderFulfillmentError}
                    helperText={
                      orderFulfillmentError
                        ? "Order Fulfillment field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setOrderFulfillmentEmail(e.target.value);
                      if (orderFulfillmentError)
                        setOrderFulfillmentError(false);
                    }}
                    onSelect={updateOrderFulfillment}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  borderBottom: "1px solid #969696",
                  paddingBottom: "20px",
                }}
              >
                Draft Order Ready To Order
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className={classes.buttonStyle1}>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(
                              draftOrder,
                              selectionStart,
                              "{RecipientCompanyName}"
                            )
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{RecipientCompanyName}"
                            )
                          );
                      }}
                    >
                      Insert Customer Company
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(draftOrder, selectionStart, "{receiver}")
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{receiver}"
                            )
                          );
                      }}
                    >
                      Insert Receiver First Name
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(
                              draftOrder,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{LevvenOrderCreator}"
                            )
                          );
                      }}
                    >
                      Insert Creator First Name
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(draftOrder, selectionStart, "{OrderType}")
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{OrderType}"
                            )
                          );
                      }}
                    >
                      Insert Order Type
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(
                              draftOrder,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{LevvenOrderID}"
                            )
                          );
                      }}
                    >
                      Insert Order ID
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(
                              draftOrder,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{LevvenOrderRequestDate}"
                            )
                          );
                      }}
                    >
                      Insert Requested Delivery Date
                    </CustomButton>
                    <div className={classes.divStyle1}></div>
                    <CustomButton
                      style={{
                        color: "#0077c6",
                        backgroundColor: "#D3D3D3",
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "3px",
                        borderRadius: "3px",
                      }}
                      toggle={() => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                        setDirty();
                        if (addOn === "body")
                          setDraftOrder(
                            addstr(draftOrder, selectionStart, "{senderEmail}")
                          );
                        else if (addOn === "subject")
                          setDraftOrderSubject(
                            addstr(
                              draftOrderSubject,
                              selectionStart,
                              "{senderEmail}"
                            )
                          );
                      }}
                    >
                      Insert Creator Email
                    </CustomButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    onSelect={updateSelectionStartSubjectDO}
                    inputRef={draftOrderSubjectRef}
                    label="Draft Order Ready To Order Template Subject"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={draftOrderSubject}
                    error={draftOrderSubjectError}
                    helperText={
                      OTPrequestSubjectError
                        ? "Draft Order Ready To Order Subject field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setDraftOrderSubject(e.target.value);
                      if (draftOrderSubjectError)
                        setDraftOrderSubjectError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    inputRef={draftOrderRef}
                    label="Draft Order Ready To Order Template"
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    value={draftOrder}
                    error={draftOrderError}
                    helperText={
                      draftOrderError
                        ? "Draft Order Ready To Order field is empty"
                        : ""
                    }
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                      setDirty();
                      setDraftOrder(e.target.value);
                      if (draftOrderError) setDraftOrderError(false);
                    }}
                    onSelect={updateSelectionStartDO}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <CustomButton
                    type="submit"
                    loading={buttonLoading}
                    startIcon={<Save />}
                  >
                    Save
                  </CustomButton>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </>
      )}
    </>
  );
}
