/* This file contains predefined variables to avoid typo while dispatching*/

const GET_USER_DATA = "GET_USER_DATA";
const REGISTER_NEW_DEVICE = "REGISTER_NEW_DEVICE";
const UPDATE_OTP = "UPDATE_OTP";
const UPDATE_END_USER_AGREEMENT = "UPDATE_END_USER_AGREEMENT";
const LOGOUT_USER = "LOGOUT_USER";
const UNAUTH_USER = "UNAUTHORIZED_USER";
const SET_LOGGED_IN = "SET_LOGGED_IN";
const UPDATE_USER_INFO = "UPDATE_USER_INFO";
const UPDATE_USER_COMPANY = "UPDATE_USER_COMPANY";
const UPDATE_USER_ALLOW_COOKIES = "UPDATE_USER_ALLOW_COOKIES";

export const userTypes = {
  GET_USER_DATA,
  REGISTER_NEW_DEVICE,
  UPDATE_OTP,
  UPDATE_END_USER_AGREEMENT,
  LOGOUT_USER,
  SET_LOGGED_IN,
  UNAUTH_USER,
  UPDATE_USER_INFO,
  UPDATE_USER_COMPANY,
  UPDATE_USER_ALLOW_COOKIES,
};
