import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

/* This components wraps the Material UI Paper component and customizes it */
const CustomPaper = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      {children}
    </Paper>
  );
};

/* Styles */
const useStyles = makeStyles({
  paper: {
    border: "1px rgba(86,87,89, 0.2) solid",
    width: "25rem",
    borderRadius: "3px",
    margin: "20px",
    padding: "40px",
  },
});

export default CustomPaper;
