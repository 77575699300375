import React from "react";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";

const ConfirmationPopup = ({ handleOpenDialog, handleAgree, openDialog }) => {
  return (
    <div className="userConfirmationDialog">
      <Dialog
        open={openDialog}
        onClose={() => handleOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Leave Page?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You have unsaved changes. Are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAgree}>Leave</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationPopup;
