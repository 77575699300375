import { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import { Breadcrumbs, Link, Paper, StepButton, Tooltip } from "@mui/material";
import ProductTakeoff from "../ProductTakeoff/ProductTakeoff";
import { useLocation, useHistory } from "react-router-dom";
import { takeoffServices } from "../../services/takeoff.services";
import { productServices } from "../../services/product.service";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";

export default function TakeoffStepper({ setPageID, setPageLink }) {
  const location = useLocation();
  const history = useHistory();

  //get the data from redux
  const agreementID = location.state
    ? location.state.agreementID
      ? location.state.agreementID
      : 0
    : 0;

  const takeoffID = location.state
    ? location.state.takeoffID
      ? location.state.takeoffID
      : 0
    : 0;

  const takeoffName = location.state
    ? location.state.takeoffName
      ? location.state.takeoffName
      : ""
    : "";

  const create = location.state
    ? location.state.create
      ? location.state.create
      : false
    : false;

  const takeoff = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  useEffect(() => {
    //for help button
    setPageLink("Takeoffs");

    //for drawer menu
    setPageID(4);
  }, []);

  if (
    takeoff === "N" ||
    (agreementID === 0 && takeoffID === 0 && takeoffName === "")
  ) {
    history.push("/Dashboard");
  }

  //holds the step we are on
  const [activeStep, setActiveStep] = useState(0);

  //makes page load while data is being fetched
  const [pageLoad, setPageLoad] = useState(false);

  //loading display when deleting takeoff
  const [deleteLoading, setDeleteLoading] = useState(false);

  //before delete display dialog
  const [deleteWarning, setDeleteWarning] = useState(false);

  //holds the error if any while fetching data
  const [error, setError] = useState("");

  //makes finish button load while data is being sent
  const [loading, setLoading] = useState(false);

  //holds if any change happened in the page
  const [formChanged, setFormChanged] = useState(false);

  //hold the data to be displayed in the page
  var [steps, setSteps] = useState([]);
  var [rows, setRows] = useState([]);

  //holds the data to be calculated
  var [totalSwitches, setTotalSwitches] = useState(0);
  var [totalWallPlates, setTotalWallPlates] = useState(0);

  //holds error if any while saving data
  const [createError, setCreateError] = useState("");

  const getProduct = async (data) => {
    //function to fetch products of each takeoff wizard filter
    try {
      const response = await productServices.getProductsByTakeoffWizardStep(
        data
      );
      setTotalSwitches(
        response.data.length > 0 ? response.data[0].totalNumberOfSwitches : 0
      );
      setTotalWallPlates(
        response.data.length > 0 ? response.data[0].totalSwitchPlatePosition : 0
      );
      return response.data;
    } catch (error) {
      setError(error.response.data.error.error);
    }
  };

  const getTakeoffIDs = async () => {
    //function to fetch steps of takeoff wizard and the products for each
    setPageLoad(true);
    takeoffServices
      .getTakeoffSteps({ AgreementID: agreementID, TakeoffID: takeoffID })
      .then(async (response) => {
        setSteps((steps) => [
          ...steps,
          ...response.data.map((element) => element),
        ]);
        if (response.length === 0) {
          setError("There are no filters related to the specified agreement.");
        }
        const products = await Promise.all(
          response.data.map((element) =>
            getProduct({
              StepID: element.step.stepID,
              TakeoffID: takeoffID,
            })
          )
        );
        setRows((rows) => [...rows, ...products]);
        setPageLoad(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  const calculation = () => {
    //function to calculate total number of switches and wall plates
    var quickMount = false;

    var ts = 0;
    var twp = 0;
    //function to calculate the total number of switches and wall plates and compares them

    //IDs used are from Database
    for (var i = 0; i < rows.length; i++) {
      for (var j = 0; j < rows[i].length; j++) {
        if (rows[i][j].quantity !== null && rows[i][j] !== "") {
          if (
            rows[i][j].product.category.categoryCode === 1 &&
            rows[i][j].product.switchAssemblyType !== null
          ) {
            ts +=
              rows[i][j].quantity * rows[i][j].product.switchAssemblyType.qty;
            if (
              rows[i][j].product.switchStyle.switchStyleCode === 2 &&
              quickMount === false
            ) {
              quickMount = true;
            }
          } else if (
            rows[i][j].product.category.categoryCode === 1 &&
            rows[i][j].product.switchAssemblyType === null
          ) {
            ts += rows[i][j].quantity;
            if (
              rows[i][j].product.switchStyle.switchStyleCode === 2 &&
              quickMount === false
            ) {
              quickMount = true;
            }
          } else if (
            rows[i][j].product.category.categoryCode === 5 &&
            rows[i][j].product.gangNbr !== null
          ) {
            twp +=
              rows[i][j].quantity *
              parseInt(rows[i][j].product.gangNbr.description);
          } else if (
            rows[i][j].product.category.categoryCode === 2 &&
            rows[i][j].product.gangNbr === null
          ) {
            twp += rows[i][j].quantity;
          }
        }
        if (rows[i][j].quantity === "") {
          rows[i][j].quantity = null;
        }
      }
    }
    setTotalSwitches(0);
    setTotalWallPlates(0);
    setTotalSwitches(ts);
    setTotalWallPlates(twp);
    if (
      (quickMount && totalSwitches === totalWallPlates) ||
      (!quickMount && totalWallPlates === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleNext = () => {
    //function responsible for going next in steps
    if (steps.length - 1 !== activeStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinish = () => {
    setLoading(true);
    //function responsible for handling the data when the Finish button is clicked
    if (calculation()) {
      setFormChanged(false);
      sessionStorage.removeItem("unsavedChanges");
      takeoffQuantityServices
        .updateTakeoffQuantity({
          ProductQuantities: rows,
          TakeoffID: takeoffID,
          SwitchesNum: totalSwitches,
          SwitchPlatePositionsNum: totalWallPlates,
        })
        .then(() => {
          setLoading(false);
          history.push({
            pathname: "/takeoff-information",
            state: {
              id: takeoffID,
              name: takeoffName,
              agreementID: agreementID,
              openSnackBar: true,
              doesTakeoff: true,
              tab: 1,
            },
          });
        })
        .catch((error) => {
          setLoading(false);
          setCreateError(error.response.data.error.error);
        });
    } else {
      setLoading(false);
      setCreateError(
        "The number of Switches (" +
          totalSwitches +
          ") is not equal to the Switch Plate Positions (" +
          totalWallPlates +
          ")"
      );
    }
  };

  const handleBack = () => {
    //function to go back
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      if (formChanged) {
        if (
          window.confirm(
            "You have unsaved changes. Are you sure you want to leave?"
          )
        ) {
          setFormChanged(false);
          history.push({
            pathname: "/takeoff-information",
            state: {
              id: takeoffID,
              name: takeoffName,
              agreementID: agreementID,
              openSnackBar: false,
              doesTakeoff: true,
            },
          });
        }
      } else {
        history.push({
          pathname: "/takeoff-information",
          state: {
            id: takeoffID,
            name: takeoffName,
            agreementID: agreementID,
            openSnackBar: false,
            doesTakeoff: true,
          },
        });
      }
    }
  };
  const goToStep = (index) => {
    //function to go to step on step click
    setActiveStep(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSteps([]);
    setRows([]);

    async function getData() {
      await getTakeoffIDs();
    }

    getData();
  }, []);

  const deleteTakeoff = async () => {
    /**Function to delete Takeoff */
    await setDeleteLoading(true);

    await takeoffServices
      .deleteTakeoff({ TakeoffList: [takeoffID] })
      .then((response) => {
        setDeleteLoading(false);
        history.push("/Takeoff");
      })
      .catch((error) => {
        setDeleteLoading(false);
        setCreateError(error.response.data.error.error);
      });
  };

  const handleCancelButtonClick = async () => {
    /**Function on Cancel button click */
    if (create) {
      setDeleteWarning(true);
    } else history.goBack();
  };

  return (
    <Paper elevation={0} sx={{ padding: "30px" }}>
      {createError ? (
        <AlertDialogSlide
          title="Error"
          textContent={createError}
          save={() => setCreateError("")}
          saveText="Ok"
        />
      ) : null}
      {deleteWarning ? (
        <AlertDialogSlide
          title="Error"
          textContent={
            "Are you sure you wish to cancel your changes? Doing so will cause previous changes to be lost. "
          }
          unsave={() => setDeleteWarning(false)}
          unsaveText="No"
          saveText={"Yes"}
          save={async () => {
            await setDeleteWarning(false);
            await deleteTakeoff();
          }}
        />
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "auto",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            onClick={() => history.push("/Takeoff")}
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            Takeoffs
          </Link>

          <Link
            underline="always"
            key="2"
            color="inherit"
            onClick={() =>
              history.push({
                pathname: "/takeoff-information",
                state: {
                  id: takeoffID,
                  name: takeoffName,
                  agreementID: agreementID,
                  openSnackBar: false,
                  doesTakeoff: true,
                },
              })
            }
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            {takeoffName}
          </Link>

          <Typography key="3" color="text.primary" sx={{ fontSize: "24px" }}>
            Products/Quantities
          </Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ width: "100%", mt: 7 }}>
        {error !== "" || (pageLoad === false && steps.length === 0) ? (
          <>
            <CustomAlert>
              {error !== ""
                ? error
                : steps.length === 0
                ? "It seems there are no steps in the takeoff wizard to match the agreement chosen."
                : ""}{" "}
              <Typography
                variant="p"
                sx={{
                  textDecoration: "underline",
                  color: "#0077c6",
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push({
                    pathname: "/takeoff-information",
                    state: {
                      id: takeoffID,
                      name: takeoffName,
                      agreementID: agreementID,
                    },
                  })
                }
              >
                Click here to return to the Takeoff Information page.
              </Typography>
            </CustomAlert>
          </>
        ) : (
          <>
            <Stepper activeStep={activeStep} nonLinear>
              {steps.map((label, index) => {
                return (
                  <Step key={label.step.title}>
                    <Tooltip
                      title={label.step.title}
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: "100%",
                            lineHeight: "15px",
                          },
                        },
                      }}
                    >
                      <StepButton
                        color="inherit"
                        onClick={() => goToStep(index)}
                      ></StepButton>
                    </Tooltip>
                  </Step>
                );
              })}
            </Stepper>
          </>
        )}

        <>
          {error === "" ? (
            <div style={{ padding: "20px 0", marginTop: "20px" }}>
              <ProductTakeoff
                rows={rows[activeStep]}
                activeStep={activeStep}
                titleText={steps[activeStep]}
                pageLoad={pageLoad}
                calculation={calculation}
                rolePermission={takeoff}
                loading={loading}
                setError={setError}
                setTotalSwitches={setTotalSwitches}
                setTotalWallPlates={setTotalWallPlates}
                numSwitches={totalSwitches}
                numWallPlates={totalWallPlates}
                setFormChanged={setFormChanged}
              />
            </div>
          ) : null}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <CustomButton
              loading={deleteLoading}
              toggle={handleCancelButtonClick}
              tabIndex="-1"
            >
              Cancel
            </CustomButton>
            <CustomButton
              disable={error || loading}
              toggle={handleBack}
              stepper={true}
              tabIndex="-1"
            >
              Back
            </CustomButton>
            <Box sx={{ flex: "1 1 auto" }} />
            <CustomButton
              toggle={handleNext}
              disable={
                (activeStep === steps.length - 1 && !error) || loading || error
              }
            >
              Next
            </CustomButton>
            {takeoff === "M" ? (
              <CustomButton
                toggle={handleFinish}
                loading={loading}
                disable={
                  error || loading === true || activeStep !== steps.length - 1
                }
                stepper={true}
              >
                Finish
              </CustomButton>
            ) : (
              ""
            )}
          </Box>
        </>
      </Box>
    </Paper>
  );
}
