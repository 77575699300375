import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { accountServices } from "../../services/account.services";
import { useSelector } from "react-redux";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const ChangePasswordForm = () => {
  //Component to Change password

  //for button loading
  const [loading, setLoading] = useState(false);

  //holds current passsword, new password and confirm new password
  const [curPass, setCurPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confNewPass, setConfNewPass] = useState("");

  //In case of error
  const [saveError, setSaveError] = useState("");

  //opens success snackbar
  const [openSnackBar, setOpenSnackBar] = useState(false);

  /*Get user email from Redux*/
  const userEmail = useSelector((state) =>
    state.user ? state.user.user.user.userName : ""
  );

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  /*Calling the reset password API*/
  const resetPassword = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = {
      Email: userEmail,
      OldPassword: curPass,
      Password: newPass,
      ConfirmPassword: confNewPass,
    };
    accountServices
      .changePassword(data)
      .then((response) => {
        setLoading(false);
        setSaveError("");
        setCurPass("");
        sessionStorage.removeItem("unsavedChanges");

        setNewPass("");
        setConfNewPass("");
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={resetPassword}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h6">Change Your Password</Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={curPass}
              onChange={(e) => {
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                setCurPass(e.target.value);
              }}
              label="Old Password"
              size="small"
              fullWidth
              required
              type="password"
              disabled={loading ? true : false}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={newPass}
              onChange={(e) => {
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                setNewPass(e.target.value);
              }}
              label="New Password"
              size="small"
              fullWidth
              required
              type="password"
              disabled={loading ? true : false}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={confNewPass}
              onChange={(e) => {
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                setConfNewPass(e.target.value);
              }}
              label="Confirm New Password"
              size="small"
              fullWidth
              required
              type="password"
              disabled={loading ? true : false}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="left">
            <Typography variant="caption" component="span">
              <span style={{ whiteSpace: "pre-line" }}>
                <strong> Password requirements:</strong> {"\n"} - Minimum 6
                characters
                {"\n"} - At least 1 uppercase and 1 lowercase letter {"\n"} - At
                least 1 number and 1 symbol ($&?) character
              </span>
            </Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton type="submit" loading={loading}>
              Save
            </CustomButton>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
        </Grid>
      </form>
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      {saveError !== "" ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
    </div>
  );
};

export default ChangePasswordForm;
