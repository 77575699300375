import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import RMATableComponent from "../../../components/RMATableComponent/RMATableComponent";

const RMATable = ({ setPageLink, setPageID }) => {
  useEffect(() => {
    //for help button
    setPageLink("RMA");

    //for drawer menu
    setPageID(11);
  }, []);

  return (
    <div>
      <RMATableComponent />
    </div>
  );
};

export default RMATable;
