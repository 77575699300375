import React, { useState, useEffect } from "react";
import CustomButton from "../CustomButton/CustomButton";
import { Grid, TextField, Typography, CircularProgress } from "@mui/material";
import { countryServices } from "../../services/country.services";
import { useHistory } from "react-router";
import SnackBar from "../SnackBar/SnackBar";
import { useLocation } from "react-router-dom";
import { Save, X } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const CountryForm = ({
  create = false,
  countryID = 0,
  setCreateCountry,
  setFormChanged,
  setDirty,
  setPristine,
}) => {
  /*Loading*/
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(false);

  /* Holds states for form */
  const [countryDescription, setCountryDescription] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [countryCurrency, setcountryCurrency] = useState("");

  /* states that hold if there is an error on a specific field */
  const [countryDescriptionError, setCountryDescriptionError] = useState(false);
  const [countryCurrencyError, setcountryCurrencyError] = useState(false);
  const [countryCodeError, setcountryCodeError] = useState(false);

  /* Holds error if any */
  const [saveError, setSaveError] = useState("");

  const history = useHistory();

  /*Create Country API*/
  const createCountry = (e) => {
    e.preventDefault();
    var error = errorHandling();
    setButtonLoading(true);

    if (error === "") {
      const data = {
        Description: countryDescription,
        CountryCode: countryCode,
        Cur: countryCurrency,
      };

      if (create) {
        countryServices
          .createCountry(data)
          .then((response) => {
            setOpenSnackBar(true);
            setButtonLoading(false);
            setFormChanged(false);
            sessionStorage.removeItem("unsavedChanges");

            setPristine();
            history.push({
              pathname: "/country-information",
              state: {
                countryID: response.data.countryID,
                countryName: response.data.description,
                openSnackBar: true,
              },
            });
          })
          .catch((error) => {
            setButtonLoading(false);

            setSaveError(error.response.data.error.error);
          });
      } else {
        setLoading(true);
        updateCountry();
        setButtonLoading(false);
      }
    }
  };

  /*Check if there are errors on save*/
  const errorHandling = () => {
    var error = "";
    if (countryDescription === "") {
      setCountryDescriptionError(true);
      error = "Please Fill Out the Country Description Field";
    }
    if (countryCode === "") {
      setcountryCodeError(true);
      error = "Please Fill Out the Country Code Field";
    }
    if (countryCurrency === "") {
      setcountryCurrencyError(true);
      error = "Please Fill Out the Country Currency Field";
    }
    setSaveError(error);
    return error;
  };

  /*Initial useEffect*/
  useEffect(() => {
    if (!create && countryID !== 0) {
      /*Fetch Country Data*/
      countryServices
        .getCountryByID(countryID)
        .then((response) => {
          setCountryDescription(response.data.description);
          setcountryCode(response.data.countryCode);
          setcountryCurrency(response.data.cur);
          setSaveError("");
          setLoading(false);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setLoading(false);
        });
    }
  }, []);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  /*Update country API*/
  const updateCountry = () => {
    var data = {
      Description: countryDescription,
      CountryCode: countryCode,
      Cur: countryCurrency,
    };
    countryServices
      .updateCountryByID(countryID, data)
      .then((response) => {
        setOpenSnackBar(true);
        setLoading(false);
        setButtonLoading(false);
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        setPristine();
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setLoading(false);
        setButtonLoading(false);
      });
  };

  return (
    <>
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={() => setOpenSnackBar(false)}
        />
      ) : null}

      {saveError ? (
        <>
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        </>
      ) : (
        " "
      )}

      {/*Main Grid*/}
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="80vh"
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </Grid>
      ) : (
        <>
          {create ? (
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                borderBottom: "1px solid #969696",
                paddingBottom: "20px",
              }}
            >
              New Country
            </Typography>
          ) : (
            ""
          )}

          <Grid container width="100%">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/*Form Grid*/}
              <form onSubmit={createCountry} autoComplete="off">
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>

                  {/*Country Description input*/}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Country Description"
                      fullWidth
                      required
                      value={countryDescription}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      error={countryDescriptionError}
                      helperText={
                        countryDescriptionError
                          ? "Country Description Is Empty"
                          : ""
                      }
                      onChange={(e) => {
                        setFormChanged(true);
                        setDirty();
                        sessionStorage.setItem(
                          "unsavedChanges",
                          JSON.stringify(true)
                        );
                        setCountryDescription(e.target.value);
                        if (countryDescriptionError)
                          setCountryDescriptionError(false);
                      }}
                    />
                  </Grid>
                  {/*Country Code input*/}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Country Code"
                      fullWidth
                      required
                      value={countryCode}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      error={countryCodeError}
                      helperText={
                        countryCodeError ? "Country Code Is Empty" : ""
                      }
                      onChange={(e) => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                          "unsavedChanges",
                          JSON.stringify(true)
                        );
                        setDirty();
                        setcountryCode(e.target.value);
                        if (countryCodeError) setcountryCodeError(false);
                      }}
                    />
                  </Grid>

                  {/*Country Currency input*/}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Country Currency"
                      fullWidth
                      required
                      value={countryCurrency}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      error={countryCurrencyError}
                      helperText={
                        countryCurrencyError ? "Country Currency Is Empty" : ""
                      }
                      onChange={(e) => {
                        setFormChanged(true);
                        sessionStorage.setItem(
                          "unsavedChanges",
                          JSON.stringify(true)
                        );
                        setDirty();
                        setcountryCurrency(e.target.value);
                        if (countryCurrencyError)
                          setcountryCurrencyError(false);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {create ? (
                        <>
                          <CustomButton
                            startIcon={<X />}
                            toggle={() => setCreateCountry(false)}
                          >
                            Cancel
                          </CustomButton>
                          <div style={{ width: "10px" }} />
                        </>
                      ) : null}
                      <CustomButton
                        type="submit"
                        loading={buttonLoading}
                        startIcon={<Save />}
                      >
                        Save
                      </CustomButton>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default CountryForm;
