import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { companyServices } from "../../services/company.services";

const CompanyStep = ({
  setDirty,
  setSaveError,
  setTakeoffs,
  resetFields,
  company,
  setCompany,
  companyError,
  setCompanyError,
  purchaserEmail,
  setPurchaserEmail,
  purchaserEmailError,
  setPurchaserEmailError,
  purchasers,
  orderID,
  companyID,
}) => {
  /*Company*/
  const [companies, setCompanies] = useState([]);

  /*Fetch All companies when there is no company ID received*/
  const fetchAllCompanies = () => {
    companyServices
      .getAllCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
      });
  };

  // fetch parent child companies
  const fetchParentChildCompanies = () => {
    console.log(companyID);
    companyServices
      .getParentChildCompaniesByCompanyID(companyID)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
    // fetchAllCompanies();
    fetchParentChildCompanies();
  }, []);
  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Hello
          </Typography>
          <br />
          <Typography variant="h5" component="span">
            Before starting, please specify the purchaser company:
          </Typography>
        </Grid>
        {/*Company*/}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Autocomplete
            options={companies}
            value={company ? company : ""}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={(event, value) => {
              setCompany(value);
              if (!value || value === null) {
                setTakeoffs([]);
              }
              if (company?.companyID !== value?.companyID) {
                resetFields();
              }
              if (companyError) setCompanyError(false);
              setDirty();
              sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
            }}
            disabled={orderID !== 0}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                fullWidth
                size="small"
                required
                error={companyError}
                helperText={companyError ? "Company field is empty" : ""}
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
        {/* Purchasing email */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Autocomplete
            id="combo-box-demo"
            componentName="Field2"
            options={purchasers}
            getOptionLabel={(option) => (option ? option.userName : "")}
            value={purchaserEmail ? purchaserEmail : ""}
            onChange={(event, value) => {
              setPurchaserEmail(value);
              if (purchaserEmailError) setPurchaserEmailError(false);
              setDirty();
              sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
            }}
            disabled={orderID !== 0}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Field2"
                label="Purchasing Email"
                fullWidth
                size="small"
                required
                error={purchaserEmailError}
                helperText={
                  purchaserEmailError ? "Purchasing Email field is empty" : ""
                }
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid>
      </Grid>
    </div>
  );
};

export default CompanyStep;
