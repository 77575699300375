import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Modal from "../CustomModal/CustomModal";
import { useEffect } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { AlertTriangle, Image, Info, RefreshCcw } from "react-feather";
import { productServices } from "../../services/product.service";
import CustomButton from "../CustomButton/CustomButton";
import CustomAlert from "../CustomAlert/CustomAlert";
import update from "immutability-helper";

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead sx={{ backgroundColor: "#1e8fce" }}>
      <TableRow>
        <TableCell align="right"></TableCell>
        {props.headCells.map((headCell) =>
          headCell.display ? (
            <TableCell
              key={headCell.id}
              align={
                headCell.center || headCell.numeric
                  ? "center"
                  : headCell.right
                  ? "right"
                  : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: "white",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: headCell.right ? "20px" : "",
              }}
            >
              {headCell.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

const SWITCH_UNIT_PRICE = "Switch Unit Price";
const CONTROLLER_UNIT_PRICE = "Unit Price";

export default function OrderProducts({
  rows = [],
  setRows,
  allRows,
  activeStep,
  defaultController = {},
  changeMade,
  defControllerQty,
  setDefControllerQty,
  getPriceByRange,
  pageLoad = false,
  loading = false,
  isAssembly = false,
  isLevvenAdmin,
  setChangeMade,
  defUPC = 0,
  maxDimmer = 0,
  setGetError,
  setDirty,
  priceResetLoading,
  setPriceResetLoading,
  totalSwitches = 0,
  refInput,
  orderStatusInTabs,
  refTextField,
  specialNegotiated,
  totalWallPlates = 0,
  totalOrderPrice,
  setTotalOrderPrice,
  defaultPriceRanges,
  orderType,
  currency,
  rolePermission,
  setTotalSwitchesOrder,
  setTotalWallPlatesOrder,
  totalQuickMount = 0,
  setTotalQuickMount,
  setFormChanged,
  opened,
  setOpened,
  orderID,
  isCompPP,
  orderVersion,
}) {
  //
  ///NOTE: rows[index].product.category.categoryCode === 1 := isAssembly==true
  //

  //To display DIalog if Admin changes price but Quantity is empty
  const [quantityChangeAlert, setQuantityChangeAlert] = React.useState(false);

  //to display snackbar
  const [success, setSuccess] = React.useState(false);

  //holds success message
  const [message, setMessage] = React.useState("");
  //holds image
  const [image, setImage] = React.useState();

  //for image error handling
  const [imageError, setImageError] = React.useState("");

  //holds image description
  const [imageDescription, setImageDescription] = React.useState("");

  //to display image
  const [displayImage, setDisplayImage] = React.useState(false);

  const [imageSpecification, setImageSpecification] = React.useState("");

  //to track if levvenQ value changed from 1
  const [levvenQChange, setLevvenQChange] = React.useState(false);

  const [imageLoad, setImageLoad] = React.useState(false);

  //holds temporary value if yes is pressed
  var [tempValue, setTempValues] = React.useState({});

  //holds temporary values if priceChanged is true and user wants to edit price
  const [priceChangedValues, setPriceChangedValues] = React.useState({});

  //displays alert dialog in case user clicks reset prices
  const [resetPricesAlert, setResetPricesAlert] = React.useState(false);

  //holds cursor location
  var cursorLocationSUP = React.useRef();
  var cursorLocationCUP = React.useRef();
  var cursorLocationUP = React.useRef();

  const headCells = [
    {
      id: "image",
      numeric: true,
      disablePadding: true,
      label: "Image",
      center: true,
      display: true,
      right: false,
    },
    {
      id: "productNumber",
      numeric: false,
      disablePadding: true,
      label: "Product No.",
      center: false,
      display: true,
      right: false,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      center: false,
      display: true,
      right: false,
    },
    {
      id: "upc",
      numeric: false,
      disablePadding: true,
      label: "UPC",
      center: true,
      display: orderType === "S",
      right: false,
    },
    {
      id: "eachCaseQty",
      numeric: false,
      disablePadding: true,
      label: "Each/Case Quantity",
      center: true,
      display: orderType === "S",
      right: false,
    },
    {
      id: "unitPrice",
      numeric: false,
      disablePadding: true,
      label: "Unit Price",
      center: true,
      display: isAssembly === false || orderVersion != 1,
      right: false,
    },
    {
      id: "unitPriceSwitch",
      numeric: false,
      disablePadding: true,
      label: "Switch Unit Price",
      center: true,
      display: isAssembly && orderVersion == 1,
      right: false,
    },
    {
      id: "unitPriceController",
      numeric: false,
      disablePadding: true,
      label: "Controller Unit Price",
      center: true,
      display: isAssembly && orderVersion == 1,
      right: false,
    },
    {
      id: "Quantity",
      numeric: true,
      disablePadding: true,
      label: "Quantity",
      center: false,
      display: true,
      right: false,
    },
    {
      id: "TotalPrice",
      numeric: false,
      disablePadding: true,
      label: "Total Price",
      center: false,
      display: true,
      right: true,
    },
    {
      id: "Action",
      numeric: false,
      disablePadding: true,
      label: "Action",
      center: true,
      display: isLevvenAdmin === true && isCompPP === false,
      right: true,
    },
  ];

  /*Fetch Image File*/
  const fetchImageFile = async (id, path, description, specification) => {
    setImageLoad(true);
    if (path === null) return;
    setImageDescription(description);
    setImageSpecification(specification);
    setDisplayImage(true);
    if (path) {
      await productServices
        .getImageFile(path, id)
        .then((response) => {
          setImage(URL.createObjectURL(response.data));
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setImageError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
        });
    }

    setImageLoad(false);
  };

  const createQuantity = async (index, e) => {
    //If there is originally no quantity in the row
    //we get a value of null from the backend
    //if the user inserts a value, we wish to create the object in the same way it is when coming from the backend

    //the objects have different values if quantity is for Assembly, Controller or Switch
    if (rows[index].product.category.categoryCode === 1 && orderVersion == 1) {
      rows[index].quantity = {
        orderID: orderID,
        productID: rows[index].product.productID,
        productSequence: 0,

        isDimmer: rows[index].isDimmer,
        quantity: parseInt(e.target.value),
        switchQuantity: parseInt(rows[index].product.switchAssemblyType.qty),
        switchUnitPrice: rows[index].switchPriceInCaseOfAssembly.unitPrice,
        total: 0,
        editedSwitchUnitPrice:
          rows[index].switchPriceInCaseOfAssembly.unitPrice,
        editedUnitPrice:
          // rows[index].product.controller.productID ===
          //   defaultController.productID ||
          rows[index].isDimmer === true
            ? defUPC
            : rows[index].productPrice.unitPrice,
        priceChanged: false,
        unitPrice:
          // rows[index].product.controller.productID ===
          //   defaultController.productID ||
          rows[index].isDimmer === true
            ? defUPC
            : rows[index].productPrice.unitPrice,
        updatedFromNav: false,
      };
    } else if (rows[index].product.category.categoryCode === 4) {
      rows[index].quantity = {
        orderID: orderID,
        productID: rows[index].product.productID,
        productSequence: 0,
        isDimmer: rows[index].isDimmer,
        quantity: parseInt(e.target.value),
        switchQuantity: 0,
        switchUnitPrice: 0,
        total: 0,
        editedSwitchUnitPrice: 0,
        editedUnitPrice:
          // rows[index].product.productID === defaultController.productID
          //   ? defUPC
          //   :
          rows[index].productPrice.unitPrice,
        priceChanged: false,
        unitPrice:
          // rows[index].product.productID === defaultController.productID
          //   ? defUPC
          //   :
          rows[index].productPrice.unitPrice,
        updatedFromNav: false,
      };
    } else {
      rows[index].quantity = {
        orderID: orderID,
        productID: rows[index].product.productID,
        productSequence: 0,
        editedSwitchUnitPrice: 0,
        editedUnitPrice: rows[index].productPrice.unitPrice,
        priceChanged: false,
        isDimmer: rows[index].isDimmer,
        quantity: parseInt(e.target.value),
        switchQuantity: 0,
        switchUnitPrice: 0,
        total: 0,
        unitPrice: rows[index].productPrice.unitPrice,
        updatedFromNav: false,
      };
    }
  };

  const updateTotalValues = async (index, e, fromReset = false) => {
    //in this function, we assign calues to the Quantities, Total Price of Order, Total # of switches
    //and Total # of switch plate positions

    //we do so by subtracting the old values (before the change has happened) and then adding the new values
    //this way we don't loop through everything each time

    var value = 0;
    var value2 = 0;
    var newValue = 0;
    var newValue2 = 0;

    if (rows[index].quantity !== null) {
      //subtract old value and add new value to total switch number and plate position number
      if (
        rows[index].product.category.categoryCode === 1
        //  &&
        // orderVersion == 1
      ) {
        value =
          rows[index].quantity.quantity *
          rows[index].product.switchAssemblyType.qty;

        if (rows[index].product.switchStyle.switchStyleCode === 2) {
          value2 =
            rows[index].quantity.quantity *
            rows[index].product.switchAssemblyType.qty;
        }
      } else if (rows[index].product.category.categoryCode === 3) {
        value = rows[index].quantity.quantity;
        if (rows[index].product.switchStyle.switchStyleCode === 2) {
          value2 = rows[index].quantity.quantity;
        }
      } else if (rows[index].product.category.categoryCode === 5) {
        value =
          rows[index].quantity.quantity *
          parseInt(rows[index].product.gangNbr.description);
      }
    }

    //if the row had no quantity we create a quantity for it
    //if new row has no quantity we empty the quantity from it
    if (rows[index].quantity === null) {
      createQuantity(index, e);
    } else if (e.target.value === "") {
      rows[index].quantity = null;
    }

    if (e.target.value !== "") {
      if (
        rows[index].product.category.categoryCode === 1
        // &&
        // orderVersion == 1
      ) {
        newValue =
          parseInt(e.target.value) * rows[index].product.switchAssemblyType.qty;

        if (rows[index].product.switchStyle.switchStyleCode === 2) {
          newValue2 =
            parseInt(e.target.value) *
            rows[index].product.switchAssemblyType.qty;
        }
      } else if (rows[index].product.category.categoryCode === 3) {
        newValue = parseInt(e.target.value);
        if (rows[index].product.switchStyle.switchStyleCode === 2) {
          newValue2 = parseInt(e.target.value);
        }
      } else if (rows[index].product.category.categoryCode === 5) {
        newValue =
          parseInt(e.target.value) *
          parseInt(rows[index].product.gangNbr.description);
      }
    }

    //assign the quantity value
    if (e.target.value !== "") {
      rows[index].quantity.quantity = parseInt(e.target.value);
    }

    var tmp2 =
      rows[index].product.category.categoryCode === 1 ||
      // && orderVersion == 1
      rows[index].product.category.categoryCode === 3
        ? totalSwitches
        : rows[index].product.category.categoryCode === 5
        ? totalWallPlates
        : 0;
    tmp2 = tmp2 - value;
    tmp2 = tmp2 + newValue;
    if (
      rows[index].product.category.categoryCode === 1 ||
      //  && orderVersion == 1
      rows[index].product.category.categoryCode === 3
    ) {
      setTotalSwitchesOrder(tmp2);
    } else if (rows[index].product.category.categoryCode === 5) {
      setTotalWallPlatesOrder(tmp2);
    }

    if (
      (rows[index].product.category.categoryCode === 1 ||
        //  && orderVersion == 1
        rows[index].product.category.categoryCode === 3) &&
      rows[index].product.switchStyle.switchStyleCode === 2
    ) {
      var tmp3 = totalQuickMount;
      tmp3 = tmp3 - value2;
      tmp3 = tmp3 + newValue2;
      setTotalQuickMount(tmp3);
    }
    if (fromReset === false) setRows(allRows);
  };

  const calculateRowTotal = (e, index) => {
    //function used to calculate total of row who is being changed
    if (e.target.value === "") {
      return;
    }

    var tmp = allRows;

    if (rows[index].product.category.categoryCode === 1 && orderVersion == 1) {
      if (
        allRows[activeStep][index].quantity !== null &&
        allRows[activeStep][index].quantity.priceChanged === true
      ) {
        tmp[activeStep][index].quantity.total =
          Math.round(
            parseFloat(
              parseInt(e.target.value) *
                (tmp[activeStep][index].quantity.editedUnitPrice +
                  tmp[activeStep][index].quantity.switchQuantity *
                    tmp[activeStep][index].quantity.editedSwitchUnitPrice)
            ) * 100
          ) / 100;
      } else if (
        // rows[index].product.controller.productID ===
        //   defaultController.productID ||
        rows[index].isDimmer === true
      ) {
        if (specialNegotiated === false) {
          tmp[activeStep][index].quantity.total =
            Math.round(
              parseFloat(
                parseInt(e.target.value) *
                  (getPriceByRange(totalSwitches, defaultPriceRanges) +
                    tmp[activeStep][index].quantity.switchQuantity *
                      tmp[activeStep][index].quantity.switchUnitPrice)
              ) * 100
            ) / 100;
        } else {
          tmp[activeStep][index].quantity.total =
            Math.round(
              parseFloat(
                parseInt(e.target.value) *
                  (defUPC +
                    tmp[activeStep][index].quantity.switchQuantity *
                      tmp[activeStep][index].quantity.switchUnitPrice)
              ) * 100
            ) / 100;
        }
      } else {
        tmp[activeStep][index].quantity.total =
          Math.round(
            parseFloat(
              parseInt(e.target.value) *
                (tmp[activeStep][index].quantity.unitPrice +
                  tmp[activeStep][index].quantity.switchQuantity *
                    tmp[activeStep][index].quantity.switchUnitPrice)
            ) * 100
          ) / 100;
      }
    }
    // else if (rows[index].product.productID === defaultController.productID) {
    //   if (
    //     allRows[activeStep][index].quantity !== null &&
    //     allRows[activeStep][index].quantity.priceChanged === true
    //   ) {
    //     tmp[activeStep][index].quantity.total =
    //       Math.round(
    //         parseFloat(
    //           parseInt(e.target.value) *
    //             tmp[activeStep][index].quantity.editedUnitPrice
    //         ) * 100
    //       ) / 100;
    //   } else if (specialNegotiated) {
    //     tmp[activeStep][index].quantity.total =
    //       Math.round(parseFloat(parseInt(e.target.value) * defUPC) * 100) / 100;
    //   } else {
    //     tmp[activeStep][index].quantity.total =
    //       Math.round(
    //         parseFloat(
    //           parseInt(e.target.value) *
    //             getPriceByRange(totalSwitches, defaultPriceRanges)
    //         ) * 100
    //       ) / 100;
    //   }
    // }
    else {
      if (
        allRows[activeStep][index].quantity !== null &&
        allRows[activeStep][index].quantity.priceChanged === true
      ) {
        tmp[activeStep][index].quantity.total =
          Math.round(
            parseFloat(
              parseInt(e.target.value) *
                tmp[activeStep][index].quantity.editedUnitPrice
            ) * 100
          ) / 100;
      } else {
        tmp[activeStep][index].quantity.total =
          Math.round(
            parseFloat(
              parseInt(e.target.value) *
                tmp[activeStep][index].quantity.unitPrice
            ) * 100
          ) / 100;
      }
    }
    setRows(tmp);
  };

  const changeLevvenQFrom1 = async () => {
    //for LevvenQ special conditions apply so we create a new function for it
    const event = { target: { value: tempValue.value } };
    setFormChanged(true);
    sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
    setDirty();
    var previousTotal = rows[tempValue.index].quantity
      ? rows[tempValue.index].quantity.total
      : 0;

    await updateTotalValues(tempValue.index, event);
    await calculateRowTotal(event, tempValue.index);

    var currentValue = rows[tempValue.index].quantity
      ? rows[tempValue.index].quantity.total
      : 0;

    var tmpPrice = totalOrderPrice;
    tmpPrice = tmpPrice - previousTotal;
    tmpPrice = tmpPrice + currentValue;

    await setTotalOrderPrice(tmpPrice);

    setChangeMade(!changeMade);
    setLevvenQChange(false);
  };

  async function calculateTotalPriceRow(index, value, isAssembly, priceType) {
    //Calculates the total price for the row we are editing
    var tmp = allRows;
    var total = 0;
    if (isAssembly === true && orderVersion == 1) {
      if (priceType === SWITCH_UNIT_PRICE) {
        var SUP =
          parseFloat(value) * tmp[activeStep][index].quantity.switchQuantity; //Holds value for Switch Unit Price

        var CUP = 0; //Holds value for Controller Unit Price

        if (
          tmp[activeStep][index].quantity !== null &&
          tmp[activeStep][index].quantity.priceChanged === true
        ) {
          CUP =
            Math.round(
              parseFloat(tmp[activeStep][index].quantity.editedUnitPrice) * 100
            ) / 100;
        } else {
          if (
            // rows[index].product.controller.productID ===
            //   defaultController.productID ||
            rows[index].isDimmer === true ||
            allRows[activeStep][index].quantity.priceChanged === false
          ) {
            //If Product has Special Negotiated Price or Default Unit Price applicable to it and price has not been changed
            //we set the value to it else we set the value within the object to it
            if (specialNegotiated === false) {
              CUP =
                Math.round(
                  parseFloat(
                    getPriceByRange(totalSwitches, defaultPriceRanges)
                  ) * 100
                ) / 100;
            } else {
              CUP = Math.round(parseFloat(defUPC) * 100) / 100;
            }
          } else {
            CUP =
              Math.round(
                parseFloat(tmp[activeStep][index].quantity.unitPrice) * 100
              ) / 100;
          }
        }

        total = (SUP + CUP) * tmp[activeStep][index].quantity.quantity;
        return total;
      } else if (priceType === CONTROLLER_UNIT_PRICE) {
        var SUP2 =
          tmp[activeStep][index].quantity.editedSwitchUnitPrice *
          parseFloat(allRows[activeStep][index].quantity.switchQuantity); //Holds value for Switch Unit Price

        var CUP2 = Math.round(parseFloat(value) * 100) / 100; //Hodls value for Controller Unit Price

        total = (SUP2 + CUP2) * tmp[activeStep][index].quantity.quantity;
        return total;
      }
    } else {
      // if (priceType === CONTROLLER_UNIT_PRICE) {
      var UP = Math.round(parseFloat(value) * 100) / 100; //Hodls value for Controller Unit Price
      total = UP * tmp[activeStep][index].quantity.quantity;
      return total;
      // }
    }
  }

  async function updateProductPrice(value, index, isAssembly, priceType) {
    //this function is used to edit the value for the price of a row

    //In case assembly we check if we are changing the Switch Unit Price or Controller Unit Price
    //If not assembly we change the Unit Price of the product
    //and we set the priceChanged flag to true so we use the new price instead of the old price

    if (isAssembly === true && orderVersion == 1) {
      //Check if product is Assembly so it has a switch and controller unit price
      if (priceType === SWITCH_UNIT_PRICE) {
        //Check if price being chaned is that of Switch Unit Price
        if (value === "" || value === null) {
          //Check if textfield is empty we write 0 in textfield since fied cannot be empty (a.k.a null)

          //We get old total for Row & new total for Row and calculate the new Total for the Order
          const oldTotal = allRows[activeStep][index].quantity.total;

          const newTotal = await calculateTotalPriceRow(
            index,
            0.0,
            isAssembly,
            priceType
          );

          var newOrderPrice = totalOrderPrice;
          newOrderPrice = newOrderPrice - oldTotal;
          newOrderPrice = newOrderPrice + newTotal;

          //we set the new values in the object
          var newAllRows = await update(allRows, {
            [activeStep]: {
              [index]: {
                quantity: {
                  editedSwitchUnitPrice: {
                    $set: parseFloat("0.00"),
                  },
                  total: {
                    $set: newTotal,
                  },
                  priceChanged: {
                    $set: true,
                  },
                },
              },
            },
          });

          setRows(newAllRows);
          setTotalOrderPrice(newOrderPrice);
        } else {
          //If value is entered we add it to the Switch Unit Price property in the quantity object

          //We get old total for Row & new total for Row and calculate the new Total for the Order
          const oldTotal = allRows[activeStep][index].quantity.total;

          const newTotal = await calculateTotalPriceRow(
            index,
            value,
            isAssembly,
            priceType
          );

          var newOrderPrice2 = totalOrderPrice;
          newOrderPrice2 = newOrderPrice2 - oldTotal;
          newOrderPrice2 = newOrderPrice2 + newTotal;

          //we set the new values in the object
          var newAllRows2 = await update(allRows, {
            [activeStep]: {
              [index]: {
                quantity: {
                  editedSwitchUnitPrice: {
                    $set: parseFloat(value),
                  },
                  total: {
                    $set: newTotal,
                  },
                  priceChanged: {
                    $set: true,
                  },
                },
              },
            },
          });

          setRows(newAllRows2);
          setTotalOrderPrice(newOrderPrice2);
        }
      } else if (priceType === CONTROLLER_UNIT_PRICE) {
        //Price being changed is that of Controller Unit Price
        if (value === "" || value === null) {
          //Check if textfield is empty we write 0 in textfield since fied cannot be empty (a.k.a null)

          //We get old total for Row & new total for Row and calculate the new Total for the Order
          const oldTotal = allRows[activeStep][index].quantity.total;

          const newTotal = await calculateTotalPriceRow(
            index,
            0.0,
            isAssembly,
            priceType
          );

          var newOrderPrice3 = totalOrderPrice;
          newOrderPrice3 = newOrderPrice3 - oldTotal;
          newOrderPrice3 = newOrderPrice3 + newTotal;

          //we set the new values in the object
          var newAllRows3 = await update(allRows, {
            [activeStep]: {
              [index]: {
                quantity: {
                  editedUnitPrice: {
                    $set: parseFloat("0.00"),
                  },
                  total: {
                    $set: newTotal,
                  },
                  priceChanged: {
                    $set: true,
                  },
                },
              },
            },
          });

          setRows(newAllRows3);
          setTotalOrderPrice(newOrderPrice3);
        } else {
          //If value is entered we add it to the Unit Price property in the quantity object
          //We get old total for Row & new total for Row and calculate the new Total for the Order
          const oldTotal = allRows[activeStep][index].quantity.total;

          const newTotal = await calculateTotalPriceRow(
            index,
            value,
            isAssembly,
            priceType
          );

          var newOrderPrice4 = totalOrderPrice;
          newOrderPrice4 = newOrderPrice4 - oldTotal;
          newOrderPrice4 = newOrderPrice4 + newTotal;

          //we set the new values in the object
          var newAllRows4 = await update(allRows, {
            [activeStep]: {
              [index]: {
                quantity: {
                  editedUnitPrice: {
                    $set: parseFloat(value),
                  },
                  total: {
                    $set: newTotal,
                  },
                  priceChanged: {
                    $set: true,
                  },
                },
              },
            },
          });

          setRows(newAllRows4);
          setTotalOrderPrice(newOrderPrice4);
        }
      }
    } else {
      // if (priceType === CONTROLLER_UNIT_PRICE) {
      if (value === "" || value === null) {
        //Check if textfield is empty we write 0 in textfield since fied cannot be empty (a.k.a null)
        //We get old total for Row & new total for Row and calculate the new Total for the Order
        const oldTotal = isAssembly
          ? allRows[activeStep][index].productPrice.unitPrice
          : allRows[activeStep][index].quantity.total;
        const newTotal = await calculateTotalPriceRow(
          index,
          0.0,
          isAssembly,
          priceType
        );
        var newOrderPrice5 = totalOrderPrice;
        newOrderPrice5 = newOrderPrice5 - oldTotal;
        newOrderPrice5 = newOrderPrice5 + newTotal;

        //we set the new values in the object
        var newAllRows5 = await update(allRows, {
          [activeStep]: {
            [index]: {
              quantity: {
                editedUnitPrice: {
                  $set: parseFloat("0.00"),
                },
                total: {
                  $set: newTotal,
                },
                priceChanged: {
                  $set: true,
                },
              },
            },
          },
        });

        setRows(newAllRows5);
        setTotalOrderPrice(newOrderPrice5);
      } else {
        //If value is entered we add it to the Switch Unit Price property in the quantity object

        //We get old total for Row & new total for Row and calculate the new Total for the Order
        const oldTotal = allRows[activeStep][index].quantity.total;
        const newTotal = await calculateTotalPriceRow(
          index,
          value,
          isAssembly,
          priceType
        );
        var newOrderPrice6 = totalOrderPrice;
        newOrderPrice6 = newOrderPrice6 - oldTotal;
        newOrderPrice6 = newOrderPrice6 + newTotal;
        //we set the new values in the object
        var newAllRows6 = await update(allRows, {
          [activeStep]: {
            [index]: {
              quantity: {
                editedUnitPrice: {
                  $set: parseFloat(value),
                },
                total: {
                  $set: newTotal,
                },
                priceChanged: {
                  $set: true,
                },
              },
            },
          },
        });

        setRows(newAllRows6);
        setTotalOrderPrice(newOrderPrice6);
      }
      // }
    }
  }

  function resetRowValues() {
    //If user wishes to reset the value of the row's prices, we recaculate the prices according to the initial values (pre-change)
    if (priceChangedValues !== null) {
      setPriceResetLoading(true);
      const index = priceChangedValues.index; //Holds the index for the product we are changing
      const quantity = priceChangedValues.quantity;

      const previousTotal = rows[index].quantity
        ? rows[index].quantity.total
        : 0; //holds previous total (total from edited prices so we change it)

      var tmp = allRows;

      if (
        rows[index].product.category.categoryCode === 1 &&
        orderVersion == 1
      ) {
        if (
          // rows[index].product.controller.productID ===
          //   defaultController.productID ||
          rows[index].isDimmer === true
        ) {
          if (specialNegotiated === false) {
            tmp[activeStep][index].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(quantity) *
                    (getPriceByRange(totalSwitches, defaultPriceRanges) +
                      tmp[activeStep][index].quantity.switchQuantity *
                        tmp[activeStep][index].quantity.switchUnitPrice)
                ) * 100
              ) / 100;
          } else {
            tmp[activeStep][index].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(quantity) *
                    (defUPC +
                      tmp[activeStep][index].quantity.switchQuantity *
                        tmp[activeStep][index].quantity.switchUnitPrice)
                ) * 100
              ) / 100;
          }
        } else {
          tmp[activeStep][index].quantity.total =
            Math.round(
              parseFloat(
                parseInt(quantity) *
                  (tmp[activeStep][index].quantity.unitPrice +
                    tmp[activeStep][index].quantity.switchQuantity *
                      tmp[activeStep][index].quantity.switchUnitPrice)
              ) * 100
            ) / 100;
        }
      }
      //  else if (
      //   rows[index].product.productID === defaultController.productID
      // ) {
      //   if (specialNegotiated) {
      //     tmp[activeStep][index].quantity.total =
      //       Math.round(parseFloat(parseInt(quantity) * defUPC) * 100) / 100;
      //   } else {
      //     tmp[activeStep][index].quantity.total =
      //       Math.round(
      //         parseFloat(
      //           parseInt(quantity) *
      //             getPriceByRange(totalSwitches, defaultPriceRanges)
      //         ) * 100
      //       ) / 100;
      //   }
      // }
      else {
        tmp[activeStep][index].quantity.total =
          Math.round(
            parseFloat(
              parseInt(quantity) * tmp[activeStep][index].quantity.unitPrice
            ) * 100
          ) / 100;
      }

      const newTotal = tmp[activeStep][index].quantity.total;

      var orderTotalChange = totalOrderPrice;
      orderTotalChange = orderTotalChange - previousTotal;
      orderTotalChange = orderTotalChange + newTotal;

      setTotalOrderPrice(orderTotalChange);

      tmp[activeStep][index].quantity.editedSwitchUnitPrice =
        tmp[activeStep][index].quantity.switchUnitPrice;
      tmp[activeStep][index].quantity.editedUnitPrice =
        tmp[activeStep][index].quantity.unitPrice;
      tmp[activeStep][index].quantity.priceChanged = false;

      setRows(tmp);
      setQuantityChangeAlert(false);
      setResetPricesAlert(false);
      setPriceChangedValues({});
      setPriceResetLoading(false);
    }
  }

  return (
    <>
      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />

      {levvenQChange ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "One (1) Levven Q gateway is required for each installation. You have changed the quantity of Levven Q gateways, which may contravene your partner agreement. Are you sure you wish to proceed?"
          }
          save={() => setLevvenQChange(false)}
          saveText="Yes"
          unsave={() => changeLevvenQFrom1()}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {quantityChangeAlert ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "You have previoulsy changed the Controller Unit price for this Product. Changing the quantity now will recalculate the Controller Unit price based on the new total number of Switches. Do you wish to proceed?"
          }
          save={() => resetRowValues()}
          saveText="Proceed"
          unsave={() => setQuantityChangeAlert(false)}
          unsaveText="Cancel"
        />
      ) : (
        ""
      )}
      {resetPricesAlert ? (
        <AlertDialogSlide
          title="Restore Price?"
          textContent={
            "Click “Restore” to reset this line item to its default pricing. You can edit the price later if required."
          }
          save={() => resetRowValues()}
          saveButtonLoading={priceResetLoading}
          saveText="Restore"
          disableUnsave={priceResetLoading}
          unsave={() => setResetPricesAlert(false)}
          unsaveText="Cancel"
        />
      ) : (
        ""
      )}

      {displayImage ? (
        <>
          <Modal openModal={displayImage} width="600px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                padding: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="div">
                  {imageDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!imageLoad ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {image ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <img
                              src={image}
                              alt="product"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                margin: "auto",
                                alignSelf: "center",
                                border: "1px solid #c4c4c4",
                                borderRadius: 8,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                ) : !imageError ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <CustomAlert>{imageError}</CustomAlert>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Typography variant="h7">
                          <b>Specifications</b>
                        </Typography>
                        <div
                          style={{
                            textAlign: "left",
                            textJustify: "inter-word",
                          }}
                        >
                          <Typography variant="body2" component="span">
                            {imageSpecification}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setImage(null);
                      setDisplayImage(false);
                      setImageDescription("");
                      setImageSpecification("");
                    }}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={headCells} rowCount={rows.length} />

              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="11" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : null}
                {rows.length === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="11"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      There are no products to match these filters
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.product.productID}
                        sx={{ height: "130px" }}
                      >
                        <TableCell align="right" width={"1%"}>
                          {rows[index].quantity !== null &&
                          rows[index].quantity.updatedFromNav === true ? (
                            <Tooltip
                              title={"This line has been modified by Levven"}
                              arrow
                            >
                              <IconButton>
                                <Info color="#98C848" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell
                          align="center"
                          padding={"none"}
                          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          {row.productImage ? (
                            <img
                              onClick={() =>
                                fetchImageFile(
                                  row.product.productID,
                                  row.product.imagePath
                                    ? row.product.imagePath
                                    : "",
                                  row.product.description,
                                  row.product.specifications
                                )
                              }
                              src={row.productImage}
                              alt={row.product.partNbr}
                              style={{
                                height: "100px",
                                objectFit: "cover",
                                alignSelf: "center",
                                borderRadius: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <>
                              <IconButton
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchImageFile(
                                    row.product.productID,
                                    row.product.imagePath
                                      ? row.product.imagePath
                                      : "",
                                    row.product.description,
                                    row.product.specifications
                                  )
                                }
                              >
                                <Image />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          padding={"none"}
                          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          {row.product.partNbr}
                        </TableCell>
                        <TableCell
                          padding={"none"}
                          sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                        >
                          {row.isDimmer === false
                            ? row.product.description
                            : "Dimmer at Price of On/Off Controller"}
                        </TableCell>
                        {orderType === "S" ? (
                          <>
                            {" "}
                            <TableCell
                              padding={"none"}
                              align={"center"}
                              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                            >
                              {row.product.upc !== null &&
                              row.product.upc !== "null"
                                ? row.product.upc
                                : ""}
                            </TableCell>
                            <TableCell
                              padding={"none"}
                              align={"center"}
                              sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                            >
                              {row.product.eachCaseQty
                                ? row.product.eachCaseQty
                                : ""}
                            </TableCell>
                          </>
                        ) : null}

                        {(isLevvenAdmin === false || isCompPP === true) &&
                        (rows[index].quantity === null ||
                          (rows[index].quantity !== null &&
                            rows[index].quantity.updatedFromNav === false)) ? (
                          <>
                            {" "}
                            {isAssembly && orderVersion == 1 ? (
                              <>
                                {" "}
                                <TableCell
                                  padding={"none"}
                                  align="center"
                                  sx={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  {/*If price as been changed, we display the edited price else we display inital value*/}
                                  {currency === "CAD" ? "C$" : "$"}{" "}
                                  {rows[index].quantity !== null &&
                                  rows[index].quantity.priceChanged === true
                                    ? rows[index].quantity.editedSwitchUnitPrice
                                    : rows[index].quantity === null
                                    ? rows[index].switchPriceInCaseOfAssembly
                                      ? parseFloat(
                                          rows[index]
                                            .switchPriceInCaseOfAssembly
                                            .unitPrice
                                        ).toFixed(2)
                                      : "0.00"
                                    : parseFloat(
                                        rows[index].quantity.switchUnitPrice
                                      ).toFixed(2)}
                                </TableCell>{" "}
                                <TableCell
                                  padding={"none"}
                                  align="center"
                                  sx={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  {/*If price as been changed, we display the edited price, else if default unit price for controller
                                  applies we display the value else we display inital value*/}
                                  {currency === "CAD" ? "C$" : "$"}{" "}
                                  {rows[index].quantity !== null &&
                                  rows[index].quantity.priceChanged === true
                                    ? rows[index].quantity.editedUnitPrice
                                    : // rows[index].product.controller
                                    //     .productID ===
                                    //     defaultController.productID ||
                                    rows[index].isDimmer === true
                                    ? defUPC
                                    : rows[index].quantity === null
                                    ? parseFloat(
                                        rows[index].productPrice?.unitPrice
                                      ).toFixed(2)
                                    : rows[index].quantity.unitPrice !== null
                                    ? parseFloat(
                                        rows[index].quantity?.unitPrice
                                      ).toFixed(2)
                                    : rows[index].productPrice?.unitPrice}
                                </TableCell>
                              </>
                            ) : (
                              <TableCell
                                padding={"none"}
                                align="center"
                                sx={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                {/*If price as been changed, we display the edited price, else if default unit price for controller
                                  applies we display the value else we display inital value*/}
                                {currency === "CAD" ? "C$" : "$"}{" "}
                                {
                                  // defaultController !== null &&
                                  (
                                    rows[index].quantity !== null &&
                                    rows[index].quantity.priceChanged === true
                                      ? rows[index].quantity.editedUnitPrice
                                      : // rows[index].product.productID ===
                                        //     defaultController.productID ||
                                        rows[index].isDimmer === true
                                  )
                                    ? defUPC
                                    : rows[index].quantity === null
                                    ? parseFloat(
                                        rows[index].productPrice?.unitPrice
                                      ).toFixed(2)
                                    : rows[index].quantity?.unitPrice !== null
                                    ? parseFloat(
                                        rows[index].quantity?.unitPrice
                                      ).toFixed(2)
                                    : rows[index].productPrice?.unitPrice
                                }
                              </TableCell>
                            )}
                          </>
                        ) : null}
                        {isCompPP === false &&
                        isLevvenAdmin === true &&
                        rows[index].quantity !== null &&
                        rows[index].quantity.updatedFromNav === true ? (
                          <>
                            {isAssembly && orderVersion == 1 ? (
                              <>
                                <TableCell
                                  padding={"none"}
                                  align="center"
                                  sx={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                  colSpan="2"
                                >
                                  <TextField
                                    sx={{ width: "100%" }}
                                    variant="standard"
                                    type="text"
                                    value={parseFloat(
                                      rows[index].quantity.total /
                                        rows[index].quantity.quantity
                                    ).toFixed(2)}
                                    disabled
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                        min: 0,
                                        maxLength: 15,
                                        display: "decimal",
                                        step: "0.01",
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <p style={{ marginTop: "2px" }}>
                                            {currency === "CAD" ? "C$" : "$"}
                                          </p>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </TableCell>
                              </>
                            ) : (
                              <TableCell
                                padding={"none"}
                                align="center"
                                sx={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  type="text"
                                  value={parseFloat(
                                    rows[index].quantity.unitPrice
                                  ).toFixed(2)}
                                  disabled
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                      min: 0,
                                      maxLength: 15,
                                      display: "decimal",
                                      step: "0.01",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <p style={{ marginTop: "2px" }}>
                                          {currency === "CAD" ? "C$" : "$"}
                                        </p>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            )}
                          </>
                        ) : null}

                        {(isLevvenAdmin === false || isCompPP === true) &&
                        rows[index].quantity !== null &&
                        rows[index].quantity.updatedFromNav === true ? (
                          <>
                            {isAssembly && orderVersion == 1 ? (
                              <TableCell
                                padding={"none"}
                                align="center"
                                sx={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                                colSpan="2"
                              >
                                <TextField
                                  sx={{ width: "100%" }}
                                  variant="standard"
                                  type="text"
                                  value={parseFloat(
                                    rows[index].quantity.total /
                                      rows[index].quantity.quantity
                                  ).toFixed(2)}
                                  disabled
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                      min: 0,
                                      maxLength: 15,
                                      display: "decimal",
                                      step: "0.01",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <p style={{ marginTop: "2px" }}>
                                          {currency === "CAD" ? "C$" : "$"}
                                        </p>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                padding={"none"}
                                align="center"
                                sx={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  type="text"
                                  value={parseFloat(
                                    rows[index].quantity.unitPrice
                                  ).toFixed(2)}
                                  disabled
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                      min: 0,
                                      maxLength: 15,
                                      display: "decimal",
                                      step: "0.01",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <p style={{ marginTop: "2px" }}>
                                          {currency === "CAD" ? "C$" : "$"}
                                        </p>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            )}
                          </>
                        ) : null}

                        {isCompPP === false &&
                        isLevvenAdmin === true &&
                        (rows[index].quantity === null ||
                          (rows[index].quantity !== null &&
                            rows[index].quantity.updatedFromNav === false)) ? (
                          <>
                            {" "}
                            {/*If Levven Admin is true we display the textfields*/}
                            {isAssembly && orderVersion == 1 ? (
                              <>
                                {" "}
                                <TableCell
                                  align="center"
                                  padding={"none"}
                                  width="8%"
                                  sx={{
                                    padding: "10px",
                                  }}
                                >
                                  <TextField
                                    variant="standard"
                                    key={
                                      "SUPK" +
                                      row.product.productID +
                                      " " +
                                      activeStep
                                    }
                                    name={
                                      "SUP" +
                                      row.product.productID +
                                      " " +
                                      activeStep
                                    }
                                    type="text"
                                    onKeyPress={(event) => {
                                      const okArray = [
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "0",
                                        ".",
                                      ];
                                      if (
                                        okArray.includes(event.key) === false
                                      ) {
                                        event.preventDefault();
                                      }

                                      const valueAsString =
                                        rows[
                                          index
                                        ].quantity.editedUnitPrice.toString();

                                      //for the cursor bug
                                      if (
                                        event.key === "." &&
                                        (valueAsString[
                                          event.target.selectionStart
                                        ] === "." ||
                                          !valueAsString.includes("."))
                                      ) {
                                        refTextField.current.setSelectionRange(
                                          event.target.selectionStart + 1,
                                          event.target.selectionStart + 1
                                        );
                                        event.preventDefault();
                                      } else if (event.key === ".") {
                                        event.preventDefault();
                                      }
                                    }}
                                    onFocus={(e) => {
                                      refTextField.current = e.target;
                                    }}
                                    value={
                                      rows[index].quantity !== null
                                        ? parseFloat(
                                            rows[index].quantity
                                              .editedSwitchUnitPrice
                                          ).toFixed(2)
                                        : rows[index]
                                            .switchPriceInCaseOfAssembly
                                        ? parseFloat(
                                            rows[index]
                                              .switchPriceInCaseOfAssembly
                                              .unitPrice
                                          ).toFixed(2)
                                        : "0.00"
                                    }
                                    onChange={(e) => {
                                      refInput.current =
                                        e.target.selectionStart;

                                      if (e.target.value.length > 7) {
                                        return;
                                      }
                                      if (rows[index].quantity !== null) {
                                        updateProductPrice(
                                          e.target.value,
                                          index,
                                          true,
                                          SWITCH_UNIT_PRICE
                                        );
                                        setFormChanged(true);
                                        sessionStorage.setItem(
                                          "unsavedChanges",
                                          JSON.stringify(true)
                                        );
                                        setDirty();
                                      }
                                    }}
                                    disabled={
                                      isLevvenAdmin === false ||
                                      isCompPP === true ||
                                      loading ||
                                      rolePermission !== "M" ||
                                      (orderStatusInTabs !== "N" &&
                                        orderStatusInTabs !== "D") ||
                                      rows[index].quantity === null ||
                                      rows[index].quantity === undefined
                                    }
                                    onBlur={() => {
                                      refInput.current = null;
                                      refTextField.current = null;
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                        min: 0,
                                        maxLength: 15,
                                        display: "decimal",
                                        step: "0.01",
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <p style={{ marginTop: "2px" }}>
                                            {currency === "CAD" ? "C$" : "$"}
                                          </p>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </TableCell>{" "}
                                <TableCell
                                  padding={"none"}
                                  width="8%"
                                  align="center"
                                  sx={{
                                    padding: "10px",
                                  }}
                                >
                                  <TextField
                                    variant="standard"
                                    type="text"
                                    onKeyPress={(event) => {
                                      const okArray = [
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "0",
                                        ".",
                                      ];
                                      if (
                                        okArray.includes(event.key) === false
                                      ) {
                                        event.preventDefault();
                                      }

                                      const valueAsString =
                                        rows[
                                          index
                                        ].quantity.editedUnitPrice.toString();

                                      if (
                                        event.key === "." &&
                                        (valueAsString[
                                          event.target.selectionStart
                                        ] === "." ||
                                          !valueAsString.includes("."))
                                      ) {
                                        refTextField.current.setSelectionRange(
                                          event.target.selectionStart + 1,
                                          event.target.selectionStart + 1
                                        );
                                        event.preventDefault();
                                      } else if (event.key === ".") {
                                        event.preventDefault();
                                      }
                                    }}
                                    onFocus={(e) => {
                                      refTextField.current = e.target;
                                    }}
                                    onBlur={() => {
                                      refInput.current = null;
                                      refTextField.current = null;
                                    }}
                                    value={
                                      rows[index].quantity !== null
                                        ? parseFloat(
                                            rows[index].quantity.editedUnitPrice
                                          ).toFixed(2)
                                        : rows[index].product.controller !==
                                            null ||
                                          // &&
                                          // rows[index].product.controller
                                          //   .productID ===
                                          //   defaultController.productID
                                          rows[index].isDimmer === true
                                        ? parseFloat(defUPC).toFixed(2)
                                        : rows[index].productPrice
                                        ? parseFloat(
                                            rows[index].productPrice.unitPrice
                                          ).toFixed(2)
                                        : "0.00"
                                    }
                                    onChange={(e) => {
                                      refInput.current =
                                        e.target.selectionStart;

                                      if (e.target.value.length > 7) {
                                        return;
                                      }

                                      if (rows[index].quantity !== null) {
                                        updateProductPrice(
                                          e.target.value,
                                          index,
                                          true,
                                          CONTROLLER_UNIT_PRICE
                                        );
                                        setFormChanged(true);
                                        sessionStorage.setItem(
                                          "unsavedChanges",
                                          JSON.stringify(true)
                                        );
                                        setDirty();
                                      }
                                    }}
                                    disabled={
                                      isLevvenAdmin === false ||
                                      isCompPP === true ||
                                      loading ||
                                      rolePermission !== "M" ||
                                      (orderStatusInTabs !== "N" &&
                                        orderStatusInTabs !== "D") ||
                                      rows[index].quantity === null ||
                                      rows[index].quantity === undefined
                                    }
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                        min: 0,
                                        maxLength: 7,
                                        display: "decimal",
                                        step: "0.01",
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <p style={{ marginTop: "2px" }}>
                                            {currency === "CAD" ? "C$" : "$"}
                                          </p>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </TableCell>
                              </>
                            ) : (
                              <TableCell
                                padding={"none"}
                                align="center"
                                width="8%"
                                sx={{
                                  padding: "10px",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  type="text"
                                  onKeyPress={(event) => {
                                    const okArray = [
                                      "1",
                                      "2",
                                      "3",
                                      "4",
                                      "5",
                                      "6",
                                      "7",
                                      "8",
                                      "9",
                                      "0",
                                      ".",
                                    ];
                                    if (okArray.includes(event.key) === false) {
                                      event.preventDefault();
                                    }
                                    const valueAsString =
                                      rows[
                                        index
                                      ].quantity.editedUnitPrice.toString();

                                    if (
                                      event.key === "." &&
                                      (valueAsString[
                                        event.target.selectionStart
                                      ] === "." ||
                                        !valueAsString.includes("."))
                                    ) {
                                      refTextField.current.setSelectionRange(
                                        event.target.selectionStart + 1,
                                        event.target.selectionStart + 1
                                      );
                                      event.preventDefault();
                                    } else if (event.key === ".") {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={
                                    rows[index].quantity // && orderVersion == 1
                                      ? parseFloat(
                                          rows[index].quantity.editedUnitPrice
                                        ).toFixed(2)
                                      : orderVersion == 1 &&
                                        // JSON.stringify(defaultController) !==
                                        //   "{}" &&
                                        // rows[index].product.productID ===
                                        // defaultController.productID ||
                                        rows[index].isDimmer === true
                                      ? parseFloat(defUPC).toFixed(2)
                                      : rows[index].productPrice
                                      ? parseFloat(
                                          rows[index].productPrice.unitPrice
                                        ).toFixed(2)
                                      : "0.00"
                                  }
                                  onFocus={(e) => {
                                    refTextField.current = e.target;
                                  }}
                                  onBlur={() => {
                                    refInput.current = null;
                                    refTextField.current = null;
                                  }}
                                  onChange={(e) => {
                                    refInput.current = e.target.selectionStart;

                                    if (e.target.value.length > 7) {
                                      return;
                                    }
                                    if (rows[index].quantity !== null) {
                                      updateProductPrice(
                                        e.target.value,
                                        index,
                                        false,
                                        CONTROLLER_UNIT_PRICE
                                      );
                                      setFormChanged(true);
                                      sessionStorage.setItem(
                                        "unsavedChanges",
                                        JSON.stringify(true)
                                      );
                                      setDirty();
                                    }
                                  }}
                                  disabled={
                                    isLevvenAdmin === false ||
                                    isCompPP === true ||
                                    loading ||
                                    rolePermission !== "M" ||
                                    (orderStatusInTabs !== "N" &&
                                      orderStatusInTabs !== "D") ||
                                    rows[index].quantity === null ||
                                    rows[index].quantity === undefined
                                  }
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                      min: 0,
                                      maxLength: 15,
                                      display: "decimal",
                                      step: "0.01",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <p style={{ marginTop: "2px" }}>
                                          {currency === "CAD" ? "C$" : "$"}
                                        </p>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            )}
                          </>
                        ) : null}

                        <TableCell
                          align="center"
                          padding={"none"}
                          sx={{ padding: "10px" }}
                        >
                          <TextField
                            type="number"
                            placeholder={
                              loading ||
                              rolePermission !== "M" ||
                              (orderStatusInTabs !== "N" &&
                                orderStatusInTabs !== "D") ||
                              rows[index].isDimmer === true
                                ? 0
                                : "0"
                            }
                            inputProps={{
                              style: { textAlign: "right" },
                              min: 0,
                            }}
                            disabled={
                              (rows[index].quantity !== null &&
                                rows[index].quantity.updatedFromNav === true) ||
                              loading ||
                              rolePermission !== "M" ||
                              (orderStatusInTabs !== "N" &&
                                orderStatusInTabs !== "D") ||
                              rows[index].isDimmer === true
                            }
                            helperText={
                              rows[index].isDimmer === true
                                ? "Agreement Deal Quantity"
                                : ""
                            }
                            key={row.product.productID + " " + activeStep}
                            variant="standard"
                            name={"quantity " + index + " " + activeStep + ""}
                            value={
                              rows[index].quantity
                                ? rows[index].quantity.quantity
                                : ""
                            }
                            onKeyPress={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "e" ||
                                event?.key === "." ||
                                event?.key === "*"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            onBlur={() => {
                              if (
                                orderType === "P" &&
                                rows[index].product.category.categoryCode ===
                                  6 &&
                                (rows[index].quantity === null ||
                                  rows[index].quantity.quantity !== 1)
                              ) {
                                setTempValues({ index: index, value: "1" });
                                setLevvenQChange(true);
                              }
                            }}
                            onChange={async (e) => {
                              console.log("quantity changed");
                              //If product is the Dimmer false product since the dimmer true product cannot be modified
                              if (e.target.value.length > 7) {
                                e.preventDefault();
                              }

                              if (rows[index].isDimmer === false) {
                                if (
                                  e.target.value === "" ||
                                  parseInt(e.target.value) === 0
                                ) {
                                  /**If the field was empty and we are adding a value in it, we add the accordion section */
                                  /**To sections of accordion that are opened on button click */
                                  if (opened.includes(activeStep)) {
                                    var found = false;
                                    for (var i = 0; i < rows.length; i++) {
                                      if (i !== index) {
                                        if (
                                          rows[i].quantity !== null &&
                                          rows[i].quantity.quantity !== 0
                                        ) {
                                          found = true;
                                          break;
                                        }
                                      }
                                    }
                                    if (found === false) {
                                      var array = opened;
                                      var id = array.indexOf(activeStep);
                                      array.splice(id, 1);
                                      setOpened(array);
                                    }
                                  }
                                } else {
                                  if (!opened.includes(activeStep)) {
                                    setOpened((opened) => [
                                      ...opened,
                                      activeStep,
                                    ]);
                                  }
                                }
                                if (
                                  rows[index].isDimmer === false ||
                                  (rows[index].isDimmer === true &&
                                    (parseInt(e.target.value) <= maxDimmer ||
                                      e.target.value === ""))
                                ) {
                                  console.log(defaultController);
                                  if (
                                    defaultController &&
                                    defaultController.productID &&
                                    rows[index] &&
                                    rows[index].product &&
                                    rows[index].product.productID ===
                                      defaultController.productID
                                  ) {
                                    setDefControllerQty(
                                      e.target.value
                                        ? parseInt(e.target.value)
                                        : 0
                                    );
                                  }

                                  /*We subtract old value and add new value while updating prices*/
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                  setDirty();
                                  var previousTotal = rows[index].quantity
                                    ? rows[index].quantity.total
                                    : 0;

                                  updateTotalValues(index, e);
                                  calculateRowTotal(e, index);

                                  var currentValue = rows[index].quantity
                                    ? rows[index].quantity.total
                                    : 0;

                                  var tmpPrice = totalOrderPrice;
                                  tmpPrice = tmpPrice - previousTotal;
                                  tmpPrice = tmpPrice + currentValue;

                                  await setTotalOrderPrice(tmpPrice);

                                  setChangeMade(!changeMade);
                                }
                              }
                              // }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          padding={"none"}
                          sx={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingRight: "20px",
                          }}
                        >
                          {currency === "CAD" ? "C$" : "$"}{" "}
                          {/* TODO: check the total ptice , quantity * unit price
                          
                          check the unit price for new assembly */}
                          {rows[index].quantity != null
                            ? parseFloat(
                                Math.round(
                                  parseFloat(rows[index].quantity.total) * 100
                                ) / 100
                              ).toFixed(2)
                            : "0.00"}
                        </TableCell>
                        {isLevvenAdmin === true && isCompPP === false ? (
                          <TableCell
                            align="center"
                            padding={"none"}
                            sx={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingRight: "20px",
                            }}
                          >
                            <Tooltip title="Restore Original Price">
                              <IconButton
                                disabled={
                                  (rows[index].quantity !== null &&
                                    rows[index].quantity.updatedFromNav ===
                                      true) ||
                                  loading ||
                                  rolePermission !== "M" ||
                                  (orderStatusInTabs !== "N" &&
                                    orderStatusInTabs !== "D") ||
                                  allRows[activeStep][index].quantity ===
                                    null ||
                                  (allRows[activeStep][index].quantity !==
                                    null &&
                                    allRows[activeStep][index].quantity
                                      .priceChanged === false)
                                }
                                onClick={() => {
                                  setPriceChangedValues({
                                    index: index,
                                    quantity:
                                      allRows[activeStep][index].quantity !==
                                      null
                                        ? allRows[activeStep][index].quantity
                                            .quantity
                                        : 0,
                                  });
                                  setResetPricesAlert(true);
                                }}
                              >
                                <RefreshCcw />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
