const helpIdentifier = new Map();

helpIdentifier.set("Dashboard", 1); //Dashboard Page

helpIdentifier.set("Users", 2); //All Pages Related To Users

helpIdentifier.set("Roles", 3); //All Oages Related To Roles

helpIdentifier.set("Agreements", 4); //All Pages Related To Agreements

helpIdentifier.set("Takeoffs", 5); //All Pages Related To Takeoff

helpIdentifier.set("Orders", 6); //All Pages Related To Orders

helpIdentifier.set("Companies", 7); //All Pages Related To Companies

helpIdentifier.set("Products", 8); //All Pages Related To Products

helpIdentifier.set("TakeoffWizard", 9); //All Pages Related To Takeoff Wizard

helpIdentifier.set("Logs", 10); //All Pages Related To Logs

helpIdentifier.set("SystemConfig", 11); //System Configuration Pages

helpIdentifier.set("RMA", 18);

helpIdentifier.set("Deleted Orders", 19);


export function getHelpIdentifier(link) {
  var result = helpIdentifier.get(link);

  return result;
}
