import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import Underline from "../Underline/Underline";
import validator from "validator";
import { accountServices } from "../../services/account.services";
import { countryServices } from "../../services/country.services";
import { adminServices } from "../../services/admin.services";
import { companyServices } from "../../services/company.services";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Compass, Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import CustomModal from "../CustomModal/CustomModal";
import { customerServices } from "../../services/customer.services";
import { orderServices } from "../../services/orders.service";
import { systemDataServices } from "../../services/systemData.services";
import { melissaServices } from "../../services/melissa.services";
import { getMelissaLog } from "../../helpers/melissa-api-logs";
import { userTypes } from "../../redux/types/userTypes";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useLogs } from "../../hooks/useLogs";
import InfoSnackbar from "../InfoSnackbar/InfoSnackbar";

/*Global Variables*/
const companyTypes = ["Builder", "Installer", "Reseller"];

const CompanyForm = forwardRef(
  (
    {
      adminManage = false,
      companyCountryCode = "",
      create = false,
      companyAdmin = false,
      receivedCompanyID = 0,
      setOpenSnackBar,
      setTabButtonLoading,
      setCompanyNameForTabs,
      setFormChanged,
    },
    ref
  ) => {
    /*Company Object*/
    const [company, setCompany] = useState();
    /*Selectors data*/
    const [countries, setCountries] = useState([]);
    const [stateProvince, setStateProvince] = useState([]);
    const [parentCompanies, setParentCompanies] = useState([]);
    const [levvenSales, setLevvenSales] = useState([]);
    const [customersFromNav, setCustomersFromNav] = useState([]);
    const [contactsFromNav, setContactsFromNav] = useState([]);
    const [shippingMethods, setShippingMethods] = useState([
      "Prepaid and charge",
      "Prepaid",
      "Local pickup",
    ]);
    /*Company Status*/
    const [status, setStatus] = useState("");
    /*Company Name*/
    const [companyName, setCompanyName] = useState("");
    const [customerGroupNb, setCustomerGroupNb] = useState("");
    const [companyNameError, setCompanyNameError] = useState(false);
    const [customerGroupNbError, setCustomerGroupNbError] = useState(false);
    /*Company Type*/
    const [companyType, setCompanyType] = useState("");
    const [companyTypeError, setCompanyTypeError] = useState(false);
    /*Company Email*/
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyEmailError, setCompanyEmailError] = useState(false);
    const [notValidCompanyEmail, setNotValidCompanyEmail] = useState(false);
    /*Have Parent Company*/
    const [isParentCompany, setisParentCompany] = useState(false);
    /*Parent Company*/
    const [parentCompany, setParentCompany] = useState();
    const [parentCompanyError, setParentCompanyError] = useState(false);
    /*Country*/
    const [country, setCountry] = useState();
    const [countryError, setCountryError] = useState(false);
    /*Office State/Province*/
    const [officeStateProvince, setOfficeStateProvince] = useState();
    const [officeStateProvinceError, setOfficeStateProvinceError] =
      useState(false);
    /*City*/
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    /*Address*/
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false);
    /*Address 2*/
    const [street2, setStreet2] = useState("");
    /*Zip Code*/
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState(false);
    /*Phone Number*/
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [validPhoneError, setValidPhoneError] = useState(false);
    /*Shipping method*/
    const [shippingMethod, setShippingMethod] = useState("Prepaid and charge");
    const [shippingMethodError, setShippingMethodError] = useState(false);
    /*Levven Sales ID*/
    const [levvenSalesUser, setLevvenSalesUser] = useState();
    const [levvenSalesUserError, setLevvenSalesUserError] = useState(false);
    /*Trade Margin on Levven Q*/
    const [tradeMargin, setTradeMargin] = useState(0.0);
    const [tradeMarginError, setTradeMarginError] = useState(false);
    /*Multiplier on published builder Levven audio price.*/
    const [multiplierOnPublished, setMultiplierOnPublished] = useState(1.0);
    const [multiplierOnPublishedError, setMultiplierOnPublishedError] =
      useState(false);
    /*Trade multiplier on published Levven controls pricing.*/
    const [tradeMultiplier, setTradeMultiplier] = useState(1.0);
    const [tradeMultiplierError, setTradeMultiplierError] = useState(false);
    /*Special commercial project controller price.*/
    const [specialCommercial, setSpecialCommercial] = useState("0.00");
    const [specialCommercialError, setSpecialCommercialError] = useState(false);
    /*Prepaid amount*/
    const [prepaidAmount, setPrepaidAmount] = useState("0.00");
    const [prepaidAmountError, setPrepaidAmountError] = useState(false);
    /*Number of annual installation*/
    const [nbOfAnnualInstallation, setNbOfAnnualInstallation] = useState("");
    const [nbOfAnnualInstallationError, setNbOfAnnualInstallationError] =
      useState(false);

    //to call the save after all new values have been set
    const [melissaSave, setMelissaSave] = useState(false);
    /*Customer NAV Number*/
    const [customerNavNo, setCustomerNavNo] = useState();
    /*Contact NAV Number*/
    const [contactNavNo, setContactNavNo] = useState();
    /*Orders related to Installer*/
    const [relatedOrders, setRelatedOrders] = useState([]);
    const [ordersAlert, setOrdersAlert] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [salesInfoChanged, setSalesInfoChanged] = useState(false);
    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [createdDialog, setCreatedDialog] = useState(false);
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    /*Holds the address verification level*/
    const [addressLevel, setAddressLevel] = useState("");

    /*Checks if the address has been changed to call the Melissa API*/
    const [addressChanged, setAddressChanged] = useState(false);

    const [melissaInfoSnackbar, setMelissaInfoSnackbar] = useState(false);
    const [melissaMessageSnackbar, setMelissaMessageSnackbar] = useState(false);
    const [melissaSeveritySnackbar, setMelissaSeveritySnackbar] =
      useState(false);

    //holds addresses returned for autocomplete of addresses
    const [suggestions, setSuggestions] = useState([]);

    //holds autocomplete address

    const [addressValue, setAddressValue] = useState("");
    const [addressPicked, setAddressPicked] = useState(null);
    const [addressVerified, setAddressVerified] = useState(false);

    /**holds temporary values for country */
    const [holdTempCountryValues, setHoldTempCountryValues] = useState(null);

    /**checks if country changed */
    const [countryChanged, setCountryChanged] = useState(false);
    /*Checks if the address verification is enables to call the Melissa API*/
    const [verifyAddress, setVerifyAddress] = useState(false);

    //hook to call log function
    const [
      logLoading,
      logError,
      handleLogCloseError,
      logUnsaveLoading,
      addLogAndLogDetail,
    ] = useLogs();

    /**Holds address to log */
    const [AddressToLog, setAddressToLog] = useState("");

    /*Holds the formatted Melissa Address returned*/
    const [formattedAddress, setFormattedAddress] = useState("");

    /*Holds the Melissa Result*/
    const [melissaResults, setMelissaResults] = useState([]);

    /*Holds the verification level of the Melissa API*/
    const [addressVerificationLevel, setAddressVerificationLevel] =
      useState("");

    //Melissa Creadits Error Dialog
    //to display the dialog in case no credits or melissa side error, we give the User to option to continue
    const [melissaCreditsDialog, setMelissaCreaditsDialog] = useState(false);

    /*To display warning about company type builder*/
    const [builderWarning, setBuilderWarning] = useState(false);

    const history = useHistory();

    /*Declare dispatch to dispatch redux actions*/
    const dispatch = useDispatch();

    /*Check if Levven Admin for Sales Info modification*/
    const [isLevvenAdmin, setIsLevvenAdmin] = useState(false);

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );

    /*Get current company ID from Redux*/
    const currentCompanyID = useSelector((state) =>
      state.user.user ? state.user.user.user.company.companyID : ""
    );

    /*Check if current user is system generated*/
    const userSystemGenerated = useSelector((state) =>
      state.user.user ? state.user.user.user.systemGenerated : ""
    );

    /*Get current company type*/
    const currentCompanyType = useSelector((state) =>
      state.user.user ? state.user.user.user.company.type : ""
    );

    /*get permission to see if user can view balance*/
    const canViewACC = useSelector((state) =>
      state.user.user ? state.user.user.user.role.canViewACC : false
    );

    /**get client & current company permissions */
    const companyPermission = useSelector((state) =>
      state.user.user ? state.user.user.user.role.clientCompany : ""
    );

    /**get client users permission if user can create users for created company */
    const clientUsers = useSelector((state) =>
      state.user.user ? state.user.user.user.role.clientUsers : ""
    );

    const fetchVerifyAddress = async () => {
      //we fetch the variable from the database that checks if we
      //want to apply address validation to the adddress
      systemDataServices
        .getSystemDataVariables()
        .then((response) => {
          setVerifyAddress(response.data.applyAddressValidation);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    const fetchAddresses = (address) => {
      if (verifyAddress === true && country !== null) {
        melissaServices
          .getAddressLookup({
            Address: address,
            Country: country.countryCode,
          })
          .then((response) => {
            setSuggestions(response.data.addresses);
          })
          .catch((error) => {
            const logDetail = {
              Status: "Melissa Error",
              Description: `Address Autocomplete fail, address sent:{Country:${country.description}, Address:${address}}`,
              AdditionalInfo: error.response.data.error.error,
              LogDetailTimeStamp: new Date().toISOString(),
            };

            addLogAndLogDetail(
              "Melissa",
              "Melissa Address Autocomplete",
              new Date().toISOString(),
              new Date().toISOString(),
              [logDetail],
              "onError"
            );
          });
      }
    };

    /*Functions*/
    /*Fetch company data by ID*/
    const fetchCompanyDataById = (saved = false) => {
      companyServices
        .getCompanyByID(receivedCompanyID)
        .then((response) => {
          const companyData = response?.data;

          if (receivedCompanyID === currentCompanyID && saved) {
            dispatch({
              type: userTypes.UPDATE_USER_COMPANY,
              payload: companyData,
            });
          }

          setCompany(companyData);
          setStatus(companyData.status);
          setCompanyName(companyData.name);
          setCustomerGroupNb(companyData.navCustomer?.customerGroupNo);
          setCompanyNameForTabs(companyData.name);
          setCompanyType(companyTypeCheck(companyData.type));
          setCompanyEmail(companyData.email);
          setParentCompany(companyData.parentCompany);
          setisParentCompany(companyData.isParent);
          setCountry(companyData.country);
          // setOfficeStateProvince(companyData.stateProvince);
          // setCity(companyData.city);
          // setStreet(companyData.streetAddress);
          // setStreet2(companyData.secondAddress);
          // setZipCode(companyData.zipPostCode);
          setPhoneNumber(companyData.phoneNumber);
          setShippingMethod(
            shipMethodCheckReversed(companyData.shippingMethod)
          );

          setAddressValue(
            `${companyData.streetAddress}, ${companyData.city}, ${companyData.stateProvince?.stateProvinceCode} ${companyData.zipPostCode}`
          );
          setAddressPicked({
            address: `${companyData.streetAddress}, ${companyData.city}, ${companyData.stateProvince?.stateProvinceCode} ${companyData.zipPostCode}`,
            address1: companyData.streetAddress,
            address2: companyData.secondAddress,
            city: companyData.city,
            zipPostCode: companyData.zipPostCode,
            country: companyData.country,
            stateProvince: companyData.stateProvince,
          });
          setLevvenSalesUser(companyData.levvenSales);
          setTradeMargin(companyData.marginLevvenQ);
          setMultiplierOnPublished(companyData.multiplierAudio);
          setTradeMultiplier(companyData.multiplierControls);
          setSpecialCommercial(parseFloat(companyData.specialPrice).toFixed(2));
          setAddressLevel(companyData.addressVerificationLevel);
          setAddressVerificationLevel(companyData.addressVerificationLevel);
          setPrepaidAmount(parseFloat(companyData.prepaidShipAmt).toFixed(2));
          setNbOfAnnualInstallation(companyData.nbrInstall);
          setCustomerNavNo(companyData.navCustomer);
          setContactNavNo(companyData.navContact);
        })
        .catch((error) => setSaveError(error.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Fetch available countries*/
    const fetchCountries = () => {
      countryServices
        .getCountries()
        .then((response) => {
          setCountries(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch State/Province depending on the chosen country*/
    const fetchStateProvince = (countryID) => {
      countryServices
        .getStateProvince(countryID)
        .then((response) => {
          setStateProvince(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Parent Companies*/
    const fetchParentCompanies = () => {
      companyServices
        .getParentCompanies({ ExcludedCompanyID: receivedCompanyID })
        .then((response) => {
          setParentCompanies(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Levven Sales*/
    const fetchLevvenSalesUsers = () => {
      accountServices
        .getLevvenSales({ GetAssignedLevenUsers: false })
        .then((response) => {
          setLevvenSales(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Orders related to the installer ID*/
    const getOrdersRelatedToInstaller = async () => {
      setButtonLoading(true);
      setTabButtonLoading(true);
      orderServices
        .getOrdersByInstallerID(receivedCompanyID)
        .then(async (response) => {
          if (response.data.length !== 0) {
            setRelatedOrders(response.data);
            setButtonLoading(false);
            setTabButtonLoading(false);
          } else if (addressChanged && verifyAddress) {
            //We call the melissa API to check the address verification
            setButtonLoading(true);
            setTabButtonLoading(true);
            setMelissaMessageSnackbar("Checking This Address...");
            setMelissaSeveritySnackbar("Loading_Icon");
            setMelissaInfoSnackbar(true);

            if (country.description === "" || country.description === null) {
              setButtonLoading(false);
              setTabButtonLoading(false);
              setCountryError(true);
              setMelissaMessageSnackbar(
                "Address format not OK, please re-enter."
              );
              setMelissaSeveritySnackbar("Error_Icon");
              setMelissaInfoSnackbar(true);
              return;
            }

            var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

            if (regex.test(addressValue) === false) {
              setButtonLoading(false);
              setTabButtonLoading(false);
              setAddressError(true);
              setMelissaMessageSnackbar(
                "Address format not OK, please re-enter."
              );
              setMelissaSeveritySnackbar("Error_Icon");
              setMelissaInfoSnackbar(true);
              return;
            }

            var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

            if (editAddress_1.length !== 3) {
              setButtonLoading(false);
              setTabButtonLoading(false);
              setAddressError(true);
              setMelissaMessageSnackbar(
                "Address format not OK, please re-enter."
              );
              setMelissaSeveritySnackbar("Error_Icon");
              setMelissaInfoSnackbar(true);
              return;
            }

            var streetAddressToBeSent_1 = editAddress_1[0].trim();
            var cityToBeSent_1 = editAddress_1[1].trim();

            var editAddress_StateProvince_Zip = editAddress_1[2]
              .trim()
              .split(" ");

            if (editAddress_StateProvince_Zip.length < 2) {
              setButtonLoading(false);
              setAddressError(true);
              setTabButtonLoading(false);
              setMelissaMessageSnackbar(
                "Address format not OK, please re-enter."
              );
              setMelissaSeveritySnackbar("Error_Icon");
              setMelissaInfoSnackbar(true);
              return;
            }

            var StateProvinceToBeSent_1 =
              editAddress_StateProvince_Zip[0].trim();

            var ZipPostCodeToBeSent_1 = "";

            editAddress_StateProvince_Zip.map((value, index) => {
              if (index !== 0 && value !== null && value !== undefined) {
                ZipPostCodeToBeSent_1 += " " + value;
                return "";
              } else {
                return "";
              }
            });

            const data = {
              Street1: streetAddressToBeSent_1,
              Street2: "", //instStreet2,
              City: cityToBeSent_1,
              StateProvince: StateProvinceToBeSent_1,
              Country: country.description,
              ZipCode: ZipPostCodeToBeSent_1,
            };

            await melissaServices
              .validateAddress(data)
              .then(async (response) => {
                setMelissaInfoSnackbar(false);
                var descriptions = [];
                var addresslvl = "";

                //Melissa API returns the address along with any corrections it made
                setFormattedAddress(
                  response.data.result.records[0].formattedAddress
                );
                setCity(response.data.result.records[0].locality);
                setCountry(response.data.country);
                setOfficeStateProvince(response.data.stateProvince);
                setStreet(response.data.result.records[0].addressLine1);
                // if (street2)
                //   setStreet2(response.data.result.records[0].addressLine2);
                var zip = await response.data.result.records[0].postalCode;
                if (zip.indexOf("-") === -1) {
                  setZipCode(response.data.result.records[0].postalCode);
                  setAddressValue(
                    `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                  );
                } else {
                  if (zipCode.indexOf("-") === -1) {
                    var part1 = zip.split("-");
                    setZipCode(part1[0]);
                    setAddressValue(
                      `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                    );
                  } else {
                    setZipCode(response.data.result.records[0].postalCode);
                    setAddressValue(
                      `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                    );
                  }
                }
                await response.data.result.records[0].results
                  .split(",")
                  .forEach((code) => {
                    if (getMelissaLog(code) !== "") {
                      descriptions.push(getMelissaLog(code));
                    }
                    //if we receive one of these codes, then we have mid to high verification level
                    if (
                      code === "AV11" ||
                      code === "AV12" ||
                      code === "AV13" ||
                      code === "AV14" ||
                      code === "AV21" ||
                      code === "AV22" ||
                      code === "AV23" ||
                      code === "AV24" ||
                      code === "AV25"
                    ) {
                      setAddressVerificationLevel(code);
                      addresslvl = code;
                    }
                  });

                if (
                  addresslvl === "AV11" ||
                  addresslvl === "AV12" ||
                  addresslvl === "AV13" ||
                  addresslvl === "AV14" ||
                  addresslvl === "AV21" ||
                  addresslvl === "AV22" ||
                  addresslvl === "AV23" ||
                  addresslvl === "AV24" ||
                  addresslvl === "AV25"
                ) {
                  setMelissaMessageSnackbar(
                    "Address adjusted/verified, thank you."
                  );
                  setMelissaSeveritySnackbar("Success_Icon");
                  setMelissaInfoSnackbar(true);
                  setAddressChanged(false);
                  //if we have mid to high verification level, we directly go to save the company else we display the dialog asking the user if they want to proceed or edit the address
                  setMelissaSave(true);
                } else {
                  setMelissaMessageSnackbar(
                    "The address could not be verified at least up to the zip or post code level."
                  );
                  setMelissaSeveritySnackbar("Warning_Icon");
                  setMelissaInfoSnackbar(true);
                  setMelissaResults(descriptions);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                }
              })
              .catch((error) => {
                setButtonLoading(false);
                setTabButtonLoading(false);
                if (error.response.data.error.errorCode === "err369") {
                  setAddressToLog(`{
                    Address: ${streetAddressToBeSent_1},
                    City: ${cityToBeSent_1},
                    Country: ${country.description},
                    State Province: ${StateProvinceToBeSent_1},
                    ZipCode: ${ZipPostCodeToBeSent_1},
                  }`);
                  setMelissaCreaditsDialog(true);
                  setMelissaMessageSnackbar(
                    "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                  );
                  setMelissaSeveritySnackbar("Warning_Icon");
                  setMelissaInfoSnackbar(true);
                } else {
                  setMelissaInfoSnackbar(false);
                  setSaveError(error.response.data.error.error);
                }
              });
          } else save();
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setButtonLoading(false);
          setTabButtonLoading(false);
        });
    };

    useEffect(() => {
      if (melissaSave === true) {
        save();
        setMelissaSave(false);
      }
    }, [melissaSave]);

    useEffect(() => {
      if (
        companyType === "Builder" &&
        customerNavNo !== null &&
     customerNavNo !== undefined &&
        contactNavNo !== null &&
        contactNavNo !== undefined && 
        contactNavNo.levvenClassification !== "B"
      ) {
        setBuilderWarning(true);
      }
    }, [companyType, contactNavNo, customerNavNo]);

    /*Save Changes*/
    const save = (event) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      setMelissaResults([]);
      setAddressChanged(false);
      /*Conditions to check if there are any errors*/
      if (companyName === "") setCompanyNameError(true);
      if (companyType === "" || companyType === null) setCompanyTypeError(true);
      if (companyEmail === "") setCompanyEmailError(true);
      else if (!validator.isEmail(companyEmail)) setNotValidCompanyEmail(true);
      if (isParentCompany && parentCompany) setParentCompanyError(true);
      if (!country || country === null) setCountryError(true);

      if (phoneNumber === "") setPhoneNumberError(true);
      if (phoneNumber.length !== 12) setValidPhoneError(true);
      if (
        companyType !== "Levven" &&
        (shippingMethod === "" || shippingMethod === null)
      )
        setShippingMethodError(true);
      if (
        (!levvenSalesUser || levvenSalesUser === null) &&
        company?.type !== "L"
      )
        setLevvenSalesUserError(true);
      if (companyType === "Installer" && tradeMargin === "")
        setTradeMarginError(true);
      if (
        (companyType === "Installer" || companyType === "Builder") &&
        (multiplierOnPublished === "" || multiplierOnPublished < 0.01)
      )
        setMultiplierOnPublishedError(true);
      if (
        (companyType === "Installer" || companyType === "Builder") &&
        (tradeMultiplier === "" || tradeMultiplier < 0.01)
      )
        setTradeMultiplierError(true);
      if (companyType === "Installer" && specialCommercial === "")
        setSpecialCommercialError(true);
      if (addressValue === null || addressValue === "") setAddressError(true);
      if (
        prepaidAmount === "" ||
        prepaidAmount === null ||
        isNaN(prepaidAmount)
      )
        setPrepaidAmountError(true);
      if (nbOfAnnualInstallation === "") setNbOfAnnualInstallationError(true);
      if (customerGroupNb === "") setCustomerGroupNbError(true);

      /*If there is any error the function returns and highlights the field containing the error*/

      if (country.description === "" || country.description === null) {
        setCountryError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      }

      var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\-\. ]+\,[a-zA-Z0-9\- ]+$/g;

      if (regex.test(addressValue) === false) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Address Format not Valid");
        return;
      }

      var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

      if (editAddress_1.length !== 3) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");

        return;
      }

      var streetAddressToBeSent_1 = editAddress_1[0].trim();
      var cityToBeSent_1 = editAddress_1[1].trim();

      var editAddress_StateProvince_Zip = editAddress_1[2].trim().split(" ");

      if (editAddress_StateProvince_Zip.length < 2) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      }

      var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

      var StateProvinceToBeSentCodeIndex = stateProvince.findIndex(
        (x) => x.stateProvinceCode === StateProvinceToBeSent_1
      );

      if (StateProvinceToBeSentCodeIndex === -1) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Invalid State Province");
        return;
      }

      var ZipPostCodeToBeSent_1 = "";

      editAddress_StateProvince_Zip.map((value, index) => {
        if (index !== 0 && value !== null && value !== undefined) {
          ZipPostCodeToBeSent_1 += " " + value;
          return "";
        } else {
          return "";
        }
      });

      if (
        companyName === "" ||
        companyType === "" ||
        companyType === null ||
        companyEmail === "" ||
        !validator.isEmail(companyEmail) ||
        (isParentCompany && parentCompany) ||
        !country ||
        country === null ||
        addressValue === null ||
        addressValue === "" ||
        phoneNumber === "" ||
        phoneNumber.length !== 12 ||
        (companyType !== "Levven" &&
          (shippingMethod === "" || shippingMethod === null)) ||
        ((!levvenSalesUser || levvenSalesUser === null) &&
          company?.type !== "L") ||
        (companyType === "Installer" && tradeMargin === "") ||
        ((companyType === "Installer" || companyType === "Builder") &&
          (multiplierOnPublished === "" || multiplierOnPublished < 0.01)) ||
        ((companyType === "Installer" || companyType === "Builder") &&
          (tradeMultiplier === "" || tradeMultiplier < 0.01)) ||
        (companyType === "Installer" && specialCommercial === "") ||
        prepaidAmount === "" ||
        isNaN(prepaidAmount) ||
        nbOfAnnualInstallation === ""
      ) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        if (!create) {
          /*If the page is in edit mode*/
          companyServices
            .updateCompanyData(company.companyID, {
              ModifiedByID: currentUserID,
              Name: companyName,
              Type: companyType.charAt(0),
              Email: companyEmail,
              PhoneNumber: phoneNumber,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              CountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : country && country !== null
                    ? country.countryID
                    : null,
              StateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : officeStateProvince && officeStateProvince !== null
                    ? officeStateProvince.stateProvinceID
                    : stateProvince[StateProvinceToBeSentCodeIndex]
                      .stateProvinceID,
              ZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : zipCode !== "" && zipCode !== null
                    ? zipCode
                    : ZipPostCodeToBeSent_1,
              StreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : street !== "" && street !== null
                    ? street
                    : streetAddressToBeSent_1,
              SecondAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : street2 !== "" && street2 !== null
                    ? street2
                    : editAddress_1[1] + ", " + editAddress_1[2],
              City:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : city !== "" && city !== null
                    ? city
                    : cityToBeSent_1,
              IsParent: isParentCompany,
              ParentCompanyID:
                parentCompany !== null ? parentCompany.companyID : "",
              NbrInstall: nbOfAnnualInstallation,
              CustomerGroupNo: customerGroupNb,
              LevvenSalesID: levvenSalesUser?.user
                ? levvenSalesUser.user.userID
                : levvenSales.userID
                  ? levvenSales.userID
                  : "",
              MarginLevvenQ:
                tradeMargin === "" || tradeMargin === null || isNaN(tradeMargin)
                  ? 0
                  : tradeMargin,
              MultiplierAudio:
                multiplierOnPublished === "" ? 1 : multiplierOnPublished,
              MultiplierControls: tradeMultiplier === "" ? 1 : tradeMultiplier,
              SpecialPrice:
                specialCommercial === "" ||
                  specialCommercial === null ||
                  isNaN(specialCommercial)
                  ? 0
                  : parseFloat(specialCommercial),
              PrepaidShipAmt:
                prepaidAmount === "" ||
                  prepaidAmount === null ||
                  isNaN(prepaidAmount)
                  ? 0
                  : parseFloat(prepaidAmount),
              CustomerID:
                customerNavNo && customerNavNo !== null
                  ? customerNavNo.customerID
                  : null,
              ContactID:
                contactNavNo && contactNavNo !== null
                  ? contactNavNo.contactID
                  : null,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setSalesInfoChanged(false);
              setAddressChanged("");
              setAddressVerificationLevel("");
              setRelatedOrders([]);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setOpenSnackBar(true);
              setSaveError("");
              fetchCompanyDataById(response.data, (saved) => true);
              setCompanyNameForTabs(companyName);
            })
            .catch((error) => {
              setSaveError(error.response.data.title);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        } else {
          /*If the page is in create mode*/
          adminServices
            .createCompany({
              CreatedByID: currentUserID,
              status: "A",
              Name: companyName,
              Type: companyType.charAt(0),
              Email: companyEmail,
              PhoneNumber: phoneNumber,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              CountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : country && country !== null
                    ? country.countryID
                    : null,
              StateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : officeStateProvince && officeStateProvince !== null
                    ? officeStateProvince.stateProvinceID
                    : stateProvince[StateProvinceToBeSentCodeIndex]
                      .stateProvinceID,
              ZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : zipCode !== "" && zipCode !== null
                    ? zipCode
                    : ZipPostCodeToBeSent_1,
              StreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : street !== "" && street !== null
                    ? street
                    : streetAddressToBeSent_1,
              SecondAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : street2 !== "" && street2 !== null
                    ? street2
                    : editAddress_1[1] + ", " + editAddress_1[2],
              City:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : city !== "" && city !== null
                    ? city
                    : cityToBeSent_1,
              IsParent: isParentCompany,
              ParentCompanyID: parentCompany ? parentCompany.companyID : "",
              NbrInstall: nbOfAnnualInstallation,
              CustomerGroupNo: customerGroupNb,
              LevvenSalesID: levvenSalesUser.user
                ? levvenSalesUser.user.userID
                : levvenSales.userID
                  ? levvenSales.userID
                  : "",
              MarginLevvenQ:
                tradeMargin === "" || tradeMargin === null || isNaN(tradeMargin)
                  ? 0
                  : tradeMargin,
              MultiplierAudio:
                multiplierOnPublished === "" ? 1 : multiplierOnPublished,
              MultiplierControls: tradeMultiplier === "" ? 1 : tradeMultiplier,
              SpecialPrice:
                specialCommercial === "" ||
                  specialCommercial === null ||
                  isNaN(specialCommercial)
                  ? 0
                  : parseFloat(specialCommercial),
              PrepaidShipAmt:
                prepaidAmount === "" ||
                  prepaidAmount === null ||
                  isNaN(prepaidAmount)
                  ? 0
                  : parseFloat(prepaidAmount),
              CustomerID:
                customerNavNo && customerNavNo !== null
                  ? customerNavNo.customerID
                  : null,
              ContactID:
                contactNavNo && contactNavNo !== null
                  ? contactNavNo.contactID
                  : null,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setCompany(response.data);
              setAddressChanged("");
              setAddressVerificationLevel("");
              setSaveError("");
              setButtonLoading(false);
              setTabButtonLoading(false);

              if (clientUsers === "M") setCreatedDialog(true);
              else {
                history.push({
                  pathname: "/company-information",
                  state: {
                    path: "company-information",
                    companyID: response.data?.companyID,
                    companyName: response.data.name,
                    source: "Companies",
                    openSnackBar: true,
                  },
                });
              }
            })
            .catch((error) => {
              setSaveError(error.response.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        }
      }
    };

    /*Check Status*/
    const companyStatusCheck = (status) => {
      if (status === "A") {
        return "Active";
      } else if (status === "H") return "On Hold";
      else return "";
    };

    /*Check Type*/
    const companyTypeCheck = (type) => {
      if (type === "L") {
        return "Levven";
      } else if (type === "B") {
        return "Builder";
      } else if (type === "I") {
        return "Installer";
      } else if (type === "R") {
        return "Reseller";
      }
    };

    /*Check shipping address*/
    const shipMethodCheck = (ship) => {
      if (ship === "Prepaid and charge") {
        return "C";
      } else if (ship === "Prepaid") {
        return "P";
      } else if (ship === "Local pickup") {
        return "L";
      } else if (ship === "Ship with other goods") {
        return "S";
      } else return "";
    };

    /*Check Shipping Method Reversed */
    const shipMethodCheckReversed = (ship) => {
      if (ship === "C") {
        return "Prepaid and charge";
      } else if (ship === "P") {
        return "Prepaid";
      } else if (ship === "L") {
        return "Local pickup";
      } else if (ship === "S") {
        return "Ship with other goods";
      } else return "Prepaid and charge";
    };
    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        return (
          d.substring(8, 10) +
          "/" +
          d.substring(4, 7) +
          "/" +
          d.substring(13, 15) +
          " at " +
          d.substring(16, 21)
        );
      }
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    const getStateProvinces = (id, stateProvince) => {
      //we get state provinces and fill it with value by default in case we are filling the form from the Customer NAV table
      setOfficeStateProvince(null);
      countryServices
        .getStateProvince(id)
        .then(async (response) => {
          await setStateProvince(response.data);

          await response.data.forEach(async (state) => {
            if (state.stateProvinceCode === stateProvince) {
              await setOfficeStateProvince(state);
              return;
            }
          });
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    const assignValuesOnCustomerChange = async (data) => {
      //on Customer change, we get the data and display it in the fields
      if (data) {
        if (data.address) setStreet(data.address);
        if (data.address2) setStreet2(data.address2);
        if (data.city) setCity(data.city);
        if (data.name) setCompanyName(data.name);
        if (data.zipCode) setZipCode(data.zipCode);

        await countries.forEach(async (country) => {
          if (country.countryCode === data.country) {
            setCountry(country);
            await getStateProvinces(country.countryID, data.state);
            return;
          }
        });
      }
    };

    /*Format the phone number correctly*/
    const editPhoneFormat = (onlyNums) => {
      var string1, string2;
      string1 = onlyNums.replace(/[^0-9]/g, "");
      string2 = string1.replace(/[^0-9]/g, "");

      switch (string2.length) {
        case 1:
          const number1 = string2.replace(/(\d{1})/, "$1");
          setPhoneNumber(number1);
          break;
        case 2:
          const number2 = string2.replace(/(\d{2})/, "$1");
          setPhoneNumber(number2);
          break;
        case 3:
          const number3 = string2.replace(/(\d{3})/, "$1");
          setPhoneNumber(number3);
          break;
        case 4:
          const number4 = string2.replace(/(\d{3})(\d{1})/, "$1-$2");
          setPhoneNumber(number4);
          break;
        case 5:
          const number5 = string2.replace(/(\d{3})(\d{2})/, "$1-$2");
          setPhoneNumber(number5);
          break;
        case 6:
          const number6 = string2.replace(/(\d{3})(\d{3})/, "$1-$2");
          setPhoneNumber(number6);
          break;
        case 7:
          const number7 = string2.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
          setPhoneNumber(number7);
          break;
        case 8:
          const number8 = string2.replace(/(\d{3})(\d{3})(\d{2})/, "$1-$2-$3");
          setPhoneNumber(number8);
          break;
        case 9:
          const number9 = string2.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
          setPhoneNumber(number9);
          break;
        case 10:
          const number10 = string2.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          setPhoneNumber(number10);
          break;
        default:
          const number0 = "";
          setPhoneNumber(number0);
          break;
      }
    };

    // const getCustomerAndContactsFromCountry = async (
    //   countryCode,
    //   initialize = true,
    //   refetch = false
    // ) => {
    //   if (initialize) {
    //     setCustomerNavNo(null);
    //     setContactNavNo(null);
    //   }

    //   await customerServices
    //     .getListOfCustomers({
    //       CountryCode: countryCode
    //         ? countryCode
    //         : refetch
    //         ? countryCode
    //         : companyCountryCode,
    //     })
    //     .then((response) => {
    //       setCustomersFromNav(response.data);
    //     })
    //     .catch((error) => setSaveError(error.response.data.error.error));

    //   await customerServices
    //     .getAllCustomerContacts({
    //       CountryCode: countryCode
    //         ? countryCode
    //         : refetch
    //         ? countryCode
    //         : companyCountryCode,
    //     })
    //     .then((response) => {
    //       setContactsFromNav(response.data);
    //     })
    //     .catch((error) => setSaveError(error.response.data.error.error));
    // };

    /*Fetch Customers from NAV*/
    const getCustomersFromNav = async (
      countryCode = undefined,
      refetch = false
    ) => {
      await customerServices
        .getListOfCustomers({
          CountryCode: countryCode,
        })
        .then((response) => {
          setCustomersFromNav(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Fetch Customers Contacts from NAV*/
    const getContactsFromNav = async (
      countryCode = undefined,
      refetch = false
    ) => {
      if (customerNavNo) {
        await customerServices
          .getListOfContactsForCustomer({
            CustomerID: customerNavNo.customerID,
          })
          .then((response) => {
            setContactsFromNav(response.data);
          })
          .catch((error) => setSaveError(error.response.data.error.error));
      } else {
        await customerServices
          .getAllCustomerContacts({
            CountryCode: countryCode,
          })
          .then((response) => {
            setContactsFromNav(response.data);
          })
          .catch((error) => setSaveError(error.response.data.error.error));
      }
    };

    // const getCountryByCountryCode = (countryCode) => {
    //   /*We choose the country to set for the company based on the country code*/
    //   var countryID = countries.findIndex((x) => x.countryCode === countryCode);
    //   if (countryID >= 0)
    //     getCustomerAndContactsFromCountry(
    //       countries[countryID].countryCode,
    //       false
    //     );
    //   if (countryID >= 0) setCountry(countries[countryID]);
    // };

    /*Check orders related to current company if the sales info is changed for an installer company*/
    const checkRelatedOrders = async (e) => {
      if (e) e.preventDefault();
      if (
        (company?.type === "I" || company?.type === "B") &&
        salesInfoChanged
      ) {
        getOrdersRelatedToInstaller();
      } else if (addressChanged && verifyAddress) {
        //we call the melissa API
        setButtonLoading(true);
        setTabButtonLoading(true);
        setMelissaMessageSnackbar("Checking This Address...");
        setMelissaSeveritySnackbar("Loading_Icon");
        setMelissaInfoSnackbar(true);

        if (country.description === "" || country.description === null) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setCountryError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

        if (regex.test(addressValue) === false) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

        if (editAddress_1.length !== 3) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var streetAddressToBeSent_1 = editAddress_1[0].trim();
        var cityToBeSent_1 = editAddress_1[1].trim();

        var editAddress_StateProvince_Zip = editAddress_1[2].trim().split(" ");

        if (editAddress_StateProvince_Zip.length < 2) {
          setButtonLoading(false);
          setAddressError(true);
          setTabButtonLoading(false);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

        var ZipPostCodeToBeSent_1 = "";

        editAddress_StateProvince_Zip.map((value, index) => {
          if (index !== 0 && value !== null && value !== undefined) {
            ZipPostCodeToBeSent_1 += " " + value;
            return "";
          } else {
            return "";
          }
        });

        const data = {
          Street1: streetAddressToBeSent_1,
          Street2: "", //instStreet2,
          City: cityToBeSent_1,
          StateProvince: StateProvinceToBeSent_1,
          Country: country.description,
          ZipCode: ZipPostCodeToBeSent_1,
        };

        await melissaServices
          .validateAddress(data)
          .then(async (response) => {
            var descriptions = [];
            var addresslvl = "";

            //Melissa returns the address along with any corrections (if  any)
            setFormattedAddress(
              response.data.result.records[0].formattedAddress
            );
            setCity(response.data.result.records[0].locality);
            setCountry(response.data.country);
            setOfficeStateProvince(response.data.stateProvince);
            setStreet(response.data.result.records[0].addressLine1);
            // if (street2)
            //   setStreet2(response.data.result.records[0].addressLine2);

            var zip = await response.data.result.records[0].postalCode;

            if (zip.indexOf("-") === -1) {
              setZipCode(response.data.result.records[0].postalCode);
              setAddressValue(
                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
              );
            } else {
              if (zipCode.indexOf("-") === -1) {
                var part1 = zip.split("-");
                setZipCode(part1[0]);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                );
              } else {
                setZipCode(response.data.result.records[0].postalCode);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                );
              }
            }
            await response.data.result.records[0].results
              .split(",")
              .forEach((code) => {
                if (getMelissaLog(code) !== "") {
                  descriptions.push(getMelissaLog(code));
                }

                //if code returned is between these options, we save it in a variable
                if (
                  code === "AV11" ||
                  code === "AV12" ||
                  code === "AV13" ||
                  code === "AV14" ||
                  code === "AV21" ||
                  code === "AV22" ||
                  code === "AV23" ||
                  code === "AV24" ||
                  code === "AV25"
                ) {
                  setAddressVerificationLevel(code);
                  addresslvl = code;
                }
              });

            if (
              addresslvl === "AV11" ||
              addresslvl === "AV12" ||
              addresslvl === "AV13" ||
              addresslvl === "AV14" ||
              addresslvl === "AV21" ||
              addresslvl === "AV22" ||
              addresslvl === "AV23" ||
              addresslvl === "AV24" ||
              addresslvl === "AV25"
            ) {
              //if code is between these options, we have mid to high level of verification so we directly save the values
              //else we ask the user if they want to save directly or edit the address
              setMelissaMessageSnackbar(
                "Address adjusted/verified, thank you."
              );
              setMelissaSeveritySnackbar("Success_Icon");
              setMelissaInfoSnackbar(true);
              setAddressChanged(false);
              setMelissaSave(true);
            } else {
              setMelissaResults(descriptions);
              setMelissaMessageSnackbar(
                "The address could not be verified at least up to the zip or post code level."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
              setMelissaResults(descriptions);
              setButtonLoading(false);
              setTabButtonLoading(false);
            }
          })
          .catch((error) => {
            setButtonLoading(false);
            setTabButtonLoading(false);
            if (error.response.data.error.errorCode === "err369") {
              setAddressToLog(`{
                Address: ${streetAddressToBeSent_1},
                City: ${cityToBeSent_1},
                Country: ${country.description},
                State Province: ${StateProvinceToBeSent_1},
                ZipCode: ${ZipPostCodeToBeSent_1},
              }`);
              setMelissaCreaditsDialog(true);
              setMelissaMessageSnackbar(
                "Address format OK but not verifiable at this time. You can proceed with this unverified address."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
            } else {
              setMelissaInfoSnackbar(false);
              setSaveError(error.response.data.error.error);
            }
          });
      } else {
        save();
      }
    };

    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setMelissaInfoSnackbar(false);
    };

    useImperativeHandle(ref, () => ({
      setPristine,
      checkRelatedOrders,
    }));

    const salesInfoPermission =
      receivedCompanyID === currentCompanyID
        ? false
        : companyPermission === "M"
          ? true
          : companyPermission === "R"
            ? false
            : false;

    /*Initial useEffect*/
    useEffect(() => {
      if (userSystemGenerated && currentCompanyType === "L") {
        setIsLevvenAdmin(true);
      }

      if (create || adminManage) getCustomersFromNav((refetch) => true);
      if (!create && receivedCompanyID !== 0) {
        fetchCompanyDataById();
      }
      fetchVerifyAddress();
      fetchCountries();
      if (company?.type !== "L") {
        fetchParentCompanies();
        fetchLevvenSalesUsers();
      }
    }, [receivedCompanyID, adminManage]);

    /*Fetch states/provinces everytime the country changes*/
    useEffect(() => {
      if (country) fetchStateProvince(country?.countryID);
    }, [country]);

    useEffect(() => {
      getContactsFromNav(null, true);
    }, [customerNavNo]);


    return (
      <>
        {builderWarning ? (
          <AlertDialogSlide
            title="Warning"
            textContent={
              'The contact you have chosen does not belong to the classification "Builder" in NAV. If you choose to continue, and in case you create an order related to an agreement between an Installer and this builder, the system will not be able to synchronize the order. To be able to synchronize the order, the contact should belong to the classification "Builder"'
            }
            unsave={() => setBuilderWarning(false)}
            unsaveText="Ok"
          />
        ) : null}

        {melissaCreditsDialog === true ? (
          <>
            <AlertDialogSlide
              title="Warning"
              textContent={"Address check failed, cancel or continue anyway?"}
              save={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: `{On Company ${create === true ? "creation" : "edit"
                    }, ${create === false
                      ? "CompanyID:" + company?.companyID + ","
                      : ""
                    } Company Name: ${companyName}, Company Email:${companyEmail}, Company Type: ${companyType}}`,
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onSave"
                ).then(() => {
                  save();
                  setMelissaCreaditsDialog(false);
                });
              }}
              saveText="Continue"
              disableUnsave={logLoading === true || buttonLoading === true}
              unsaveLoading={logUnsaveLoading}
              disableSave={logUnsaveLoading === true}
              unsave={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: "",
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onUnsave"
                ).then(() => {
                  setMelissaCreaditsDialog(false);
                });
              }}
              unsaveText="Cancel"
              saveButtonLoading={logLoading || buttonLoading}
            />
            <InfoSnackbar
              open={melissaInfoSnackbar}
              message={melissaMessageSnackbar}
              severity={melissaSeveritySnackbar}
              handleClose={handleSnackBarClose}
            />
          </>
        ) : null}

        {logError !== "" ? (
          <AlertSnackBar
            message={logError}
            handleClose={handleLogCloseError}
            open={logError !== ""}
          />
        ) : null}

        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            {createdDialog ? (
              <CustomModal openModal={createdDialog}>
                <Typography variant="h6" component="span" align="left">
                  {companyName} created successfully.
                </Typography>
                <br />
                <br />
                <Typography variant="body1" component="span" align="left">
                  Would you like to create the company's first user?
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "30px",
                  }}
                >
                  <CustomButton
                    toggle={() => {
                      setCreatedDialog(false);
                      history.push({
                        pathname: "/company-information",
                        state: {
                          path: "company-information",
                          companyID: company?.companyID,
                          companyName: company.name,
                          source: "Companies",
                          openSnackBar: true,
                        },
                      });
                    }}
                  >
                    No
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    toggle={() => {
                      setCreatedDialog(false);
                      history.push({
                        pathname: "/create-user",
                        state: {
                          companyID: company.companyID,
                          companyName: company.name,
                        },
                      });
                    }}
                    loading={buttonLoading}
                  >
                    Yes
                  </CustomButton>
                </div>
              </CustomModal>
            ) : null}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{ borderRight: "2px solid #c4c4c4", paddingRight: "20px" }}
              >
                {/*Form Grid*/}
                <form
                  onSubmit={
                    create || adminManage || companyAdmin
                      ? checkRelatedOrders
                      : ""
                  }
                >
                  <Grid container spacing={6}>
                    {currentCompanyType === "L" ? (
                      <>
                        {" "}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Connection to NAV
                          </Typography>
                          <Underline />
                        </Grid>
                        {/*Status Chip*/}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          {create ? null : (
                            <span
                              style={{
                                padding: "5px 10px",
                                width: "100px",
                                borderRadius: "25px",
                                textAlign: "center",
                                backgroundColor:
                                  status === "A"
                                    ? "rgba(29, 148, 75, 0.2)"
                                    : "rgba(150, 150, 150, 0.3)",
                                color:
                                  status === "A"
                                    ? "rgba(29, 148, 75, 1)"
                                    : "rgba(150, 150, 150, 1)",
                              }}
                            >
                              {companyStatusCheck(status)}
                            </span>
                          )}
                        </Grid>
                        {/*Customer Number*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            componentName="Field6"
                            options={customersFromNav}
                            value={customerNavNo ? customerNavNo : ""}
                            getOptionLabel={(option) =>
                              option
                                ? option.no +
                                ", " +
                                option.name +
                                (option.sourceCountry
                                  ? " (" + option.sourceCountry + ")"
                                  : "")
                                : ""
                            }
                            onChange={(event, value) => {
                              if (create || adminManage) {
                                if (company?.type !== "L") {
                                  if (
                                    customerNavNo?.customerID !==
                                    value?.customerID
                                  ) {
                                    setCustomerNavNo(value);
                                    if (create)
                                      assignValuesOnCustomerChange(value);
                                  }

                                  setContactNavNo();
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            disabled={create || adminManage ? false : true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Field6"
                                label="Customer Name"
                                fullWidth
                                size="small"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/*Customer Contact*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            componentName="Field7"
                            options={contactsFromNav}
                            value={contactNavNo ? contactNavNo : ""}
                            getOptionLabel={(option) =>
                              option
                                ? option.no +
                                ", " +
                                option.name +
                                (option.sourceCountry
                                  ? " (" + option.sourceCountry + ")"
                                  : "") +
                                (option.levvenClassification &&
                                  option.levvenClassification === "B"
                                  ? "*"
                                  : "")
                                : ""
                            }
                            onChange={(event, value) => {
                              if (create || adminManage) {
                                if (company?.type !== "L") {
                                  setContactNavNo(value);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            disabled={create || adminManage ? false : true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Field7"
                                label="Builder Contact"
                                fullWidth
                                size="small"
                                helperText={
                                  "Contacts marked with '*' are classified as Builders in NAV"
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>


                      </>
                    ) : null}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Contact
                      </Typography>
                      <Underline />
                    </Grid>
                    {currentCompanyType !== "L" ? (
                      <>
                        {/*Status Chip*/}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          {create ? null : (
                            <span
                              style={{
                                padding: "5px 10px",
                                width: "100px",
                                borderRadius: "25px",
                                textAlign: "center",
                                backgroundColor:
                                  status === "A"
                                    ? "rgba(29, 148, 75, 0.2)"
                                    : "rgba(150, 150, 150, 0.3)",
                                color:
                                  status === "A"
                                    ? "rgba(29, 148, 75, 1)"
                                    : "rgba(150, 150, 150, 1)",
                              }}
                            >
                              {companyStatusCheck(status)}
                            </span>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    )}

                    {/*Company Name input*/}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Company Name"
                        required
                        fullWidth
                        value={companyName}
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "new-password",
                          maxLength: 100,
                        }}
                        error={companyNameError}
                        helperText={
                          companyEmailError ? "Company Name field is empty" : ""
                        }
                        onChange={(e) => {
                          if (create || adminManage) {
                            setCompanyName(e.target.value);
                            if (companyNameError) setCompanyNameError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/*Company Type input*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        componentName="Field1"
                        options={companyTypes}
                        value={companyType ? companyType : ""}
                        onChange={(event, value) => {
                          if (create) {
                            if (company?.companyID !== currentCompanyID) {
                              setCompanyType(value);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              if (companyTypeError) setCompanyTypeError(false);
                            }
                          }
                        }}
                        disabled={create ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="Field1"
                            label="Company Type"
                            fullWidth
                            size="small"
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            error={companyTypeError}
                            helperText={
                              companyTypeError
                                ? "Company Type field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Company Email input*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Company Email"
                        required
                        fullWidth
                        value={companyEmail}
                        error={companyEmailError || notValidCompanyEmail}
                        helperText={
                          companyEmailError
                            ? "Company Email field is empty"
                            : notValidCompanyEmail
                              ? "Email format is not valid"
                              : ""
                        }
                        onChange={(e) => {
                          if (create || adminManage || companyAdmin) {
                            setCompanyEmail(e.target.value);
                            if (companyEmailError) setCompanyEmailError(false);
                            if (validator.isEmail(companyEmail))
                              setNotValidCompanyEmail(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin ? false : true
                        }
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "new-password",
                        }}
                      />
                    </Grid>
                    {company?.type === "L" ? null : (
                      <>
                        {/*Have Parent Company Checkbox*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isParentCompany}
                                onClick={() => {
                                  if (create || adminManage) {
                                    setisParentCompany(!isParentCompany);
                                    if (!isParentCompany)
                                      setParentCompany(null);
                                    setDirty();
                                    setFormChanged(true);
                                    sessionStorage.setItem(
                                      "unsavedChanges",
                                      JSON.stringify(true)
                                    );
                                  }
                                }}
                                disabled={create || adminManage ? false : true}
                              />
                            }
                            label="Parent Company"
                          />
                        </Grid>
                        {/*Linked to parent company*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            componentName="Field2"
                            options={parentCompanies}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            value={parentCompany ? parentCompany : ""}
                            onChange={(event, value) => {
                              if (create || adminManage) {
                                if (!isParentCompany) {
                                  setParentCompany(value);
                                  if (parentCompanyError)
                                    setParentCompanyError(false);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? isParentCompany
                                  ? true
                                  : false
                                : true
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Field2"
                                label="Linked To Parent Company"
                                fullWidth
                                size="small"
                                required={isParentCompany ? false : null}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                                error={parentCompanyError}
                                helperText={
                                  parentCompanyError
                                    ? "Company Parent field is empty"
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6" component="span">
                          Address
                        </Typography>

                        <Tooltip
                          title={
                            addressLevel === "AV11" ||
                              addressLevel === "AV12" ||
                              addressLevel === "AV13" ||
                              addressLevel === "AV14"
                              ? "Address Partially Verified"
                              : addressLevel === "AV21" ||
                                addressLevel === "AV22" ||
                                addressLevel === "AV23" ||
                                addressLevel === "AV24" ||
                                addressLevel === "AV25"
                                ? "Address Fully Verified"
                                : "Address Not Verified"
                          }
                        >
                          <span>
                            <IconButton disabled>
                              <Compass
                                color={
                                  addressLevel === "AV11" ||
                                    addressLevel === "AV12" ||
                                    addressLevel === "AV13" ||
                                    addressLevel === "AV14"
                                    ? "#FEBF11"
                                    : addressLevel === "AV21" ||
                                      addressLevel === "AV22" ||
                                      addressLevel === "AV23" ||
                                      addressLevel === "AV24" ||
                                      addressLevel === "AV25"
                                      ? "#98C848"
                                      : "#E63846"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Address*/}

                    {/*Country selector*/}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        componentName="Field1"
                        options={countries}
                        value={country ? country : {}}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (country?.description !== value?.description) {
                              setOfficeStateProvince();
                            }
                            setAddressChanged(true);
                            setCountry(value);
                            if (value === null) setStateProvince([]);
                            if (countryError) setCountryError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Office Country"
                            name="Field1"
                            fullWidth
                            size="small"
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            error={countryError}
                            helperText={
                              countryError ? "Country field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        autoSelect
                        options={suggestions.map(
                          (options) =>
                            options.address1 +
                            ", " +
                            options.city +
                            ", " +
                            options.stateProvince.stateProvinceCode +
                            " " +
                            options.zipPostCode
                        )}
                        value={addressValue}
                        disabled={
                          country === null ||
                          country === undefined 
                        }
                        onChange={(event, newValue) => {
                          if (verifyAddress === true) fetchAddresses(newValue);
                          if (addressError) setAddressError(false);
                          var index = suggestions.findIndex(
                            (x) =>
                              x.address1 +
                              ", " +
                              x.city +
                              ", " +
                              x.stateProvince.stateProvinceCode +
                              " " +
                              x.zipPostCode ===
                              newValue
                          );
                          setDirty();
                          setFormChanged(true);
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          if (index !== -1) {
                            setAddressChanged(false);
                            setAddressPicked(suggestions[index]);

                            setAddressValue(
                              suggestions[index].address1 +
                              ", " +
                              suggestions[index].city +
                              ", " +
                              suggestions[index].stateProvince
                                .stateProvinceCode +
                              " " +
                              suggestions[index].zipPostCode
                            );
                            setAddressVerified(true);
                          } else {
                            setAddressVerified(false);
                            setAddressPicked(null);
                            setAddressChanged(true);
                            setAddressValue(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Address"
                            size="small"
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            error={addressError}
                            onChange={(newValue) => {
                              if (verifyAddress === true)
                                fetchAddresses(newValue.target.value);
                            }}
                            helperText={
                              addressError
                                ? "Address field is empty. Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                                : "Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                            }
                            disabled={
                              country === null ||
                              country === undefined 
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Office Phone Number */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Office Phone Number"
                        required
                        fullWidth
                        inputProps={{
                          autocomplete: "new-password",
                          min: 0,
                        }}
                        // onKeyPress={(event) => {
                        //   if (phoneCheck.indexOf(event.key) === -1) {
                        //     event.preventDefault();
                        //   }
                        // }}
                        value={phoneNumber}
                        error={phoneNumberError || validPhoneError}
                        placeholder="999-999-9999"
                        helperText={
                          phoneNumberError
                            ? "Phone Number field is empty"
                            : validPhoneError
                              ? "Invalid Phone Number"
                              : ""
                        }
                        onChange={(e) => {
                          if (
                            (create || adminManage || companyAdmin) &&
                            e.target.value.length < 13
                          ) {
                            if (
                              e.target.value < 0 &&
                              e.target.value.length < 13
                            ) {
                              var tmp = e.target.value * -1;

                              editPhoneFormat(tmp);
                            } else if (e.target.value.length < 13) {
                              editPhoneFormat(e.target.value);
                            }
                            if (phoneNumberError) setPhoneNumberError(false);
                            if (validPhoneError) setValidPhoneError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                        
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin ? false : true
                        }
                      />
                    </Grid>
                    {/* Shipping Method */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {companyType !== "Levven" ? (
                        <Autocomplete
                          id="combo-box-demo"
                          options={shippingMethods}
                          componentName="Field5"
                          value={shippingMethod ? shippingMethod : ""}
                          onChange={(event, value) => {
                            setShippingMethod(value);
                            if (shippingMethodError)
                              setShippingMethodError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }}
                          disabled={create || adminManage ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Field5"
                              label="Shipping Method"
                              fullWidth
                              size="small"
                              error={shippingMethodError}
                              helperText={
                                shippingMethodError
                                  ? "Shipping Method field is empty"
                                  : ""
                              }
                              required
                              type="text"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      ) : null}
                    </Grid>
                    {companyType !== "Levven" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Sales Info
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                        {/*Levven Sales ID*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            componentName="Field6"
                            options={levvenSales}
                            value={levvenSalesUser ? levvenSalesUser : ""}
                            getOptionLabel={(option) =>
                              option.user
                                ? option.user.firstName +
                                " " +
                                option.user.lastName
                                : option.firstName && option.lastName
                                  ? option.firstName + " " + option.lastName
                                  : ""
                            }
                            onChange={(event, value) => {
                              if (create || adminManage) {
                                if (salesInfoPermission) {
                                  setLevvenSalesUser(value);
                                  if (levvenSalesUserError)
                                    setLevvenSalesUserError(false);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? salesInfoPermission
                                  ? false
                                  : true
                                : true
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Field6"
                                label="Levven Sales"
                                fullWidth
                                required={company?.type === "L" ? false : true}
                                size="small"
                                error={levvenSalesUserError}
                                helperText={
                                  levvenSalesUserError
                                    ? "Levven Sales ID field is empty"
                                    : ""
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {/* Trade Margin on Levven Q */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Trade Margin on Levven Q"
                            fullWidth
                            type="number"
                            value={parseFloat(tradeMargin)}
                            error={tradeMarginError}
                            helperText={
                              tradeMarginError
                                ? "Trade Margin field is empty"
                                : ""
                            }
                            required={companyType === "Installer"}
                            onChange={(e) => {
                              var checkDecimals =
                                (e.target.value.indexOf(".") !== -1 &&
                                  parseFloat(e.target.value)
                                    .toString()
                                    .split(".")[1].length <= 12) ||
                                  e.target.value.indexOf(".") === -1
                                  ? true
                                  : false;
                              if (
                                (create || adminManage) &&
                                e.target.value <= 100 &&
                                checkDecimals &&
                                companyType === "Installer" &&
                                salesInfoPermission
                              ) {
                                if (e.target.value < 0) {
                                  setTradeMargin(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setTradeMargin(parseFloat(e.target.value));
                                }
                                if (tradeMarginError)
                                  setTradeMarginError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                                if (!create) setSalesInfoChanged(true);
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? companyType === "Installer"
                                  ? salesInfoPermission
                                    ? false
                                    : true
                                  : true
                                : true
                            }
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: 100,
                                display: "decimal",
                                step: "0.000000000001",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {/* Multiplier On Published... */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Multiplier on Published Builder Levven Audio Price"
                            required={
                              companyType === "Installer" ||
                              companyType === "Builder" ||
                              companyType === "Reseller"
                            }
                            fullWidth
                            type="number"
                            value={parseFloat(multiplierOnPublished)}
                            error={multiplierOnPublishedError}
                            helperText={
                              multiplierOnPublishedError
                                ? multiplierOnPublished === ""
                                  ? "Multiplier on Published Builder Levven Audio Price field is empty"
                                  : "Multiplier on Published Builder Levven Audio Price can't be 0"
                                : ""
                            }
                            onChange={(e) => {
                              var checkDecimals =
                                (e.target.value.indexOf(".") !== -1 &&
                                  parseFloat(e.target.value)
                                    .toString()
                                    .split(".")[1].length <= 12) ||
                                  e.target.value.indexOf(".") === -1
                                  ? true
                                  : false;

                              if (
                                (create || adminManage) &&
                                parseFloat(e.target.value) <= 999999999 &&
                                checkDecimals &&
                                (companyType === "Installer" ||
                                  companyType === "Builder" ||
                                  companyType === "Reseller") &&
                                salesInfoPermission
                              ) {
                                if (parseFloat(e.target.value) < 0) {
                                  setMultiplierOnPublished(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setMultiplierOnPublished(
                                    parseFloat(e.target.value)
                                  );
                                }
                                if (multiplierOnPublishedError)
                                  setMultiplierOnPublishedError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                                if (!create) setSalesInfoChanged(true);
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? companyType === "Installer" ||
                                  companyType === "Builder" ||
                                  companyType === "Reseller"
                                  ? salesInfoPermission
                                    ? false
                                    : true
                                  : true
                                : true
                            }
                            InputProps={{
                              inputProps: {
                                min: 0.01,
                                display: "decimal",
                                step: "0.000000000001",
                              },
                            }}
                          />
                        </Grid>
                        {/* Trade Multiplier On... */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Multiplier on Published Levven Controls Pricing"
                            required={
                              companyType === "Installer" ||
                              companyType === "Builder" ||
                              companyType === "Reseller"
                            }
                            fullWidth
                            value={parseFloat(tradeMultiplier)}
                            type="number"
                            error={tradeMultiplierError}
                            helperText={
                              tradeMultiplierError
                                ? tradeMultiplier === ""
                                  ? "Multiplier on Published Levven Controls Pricing field is empty"
                                  : "Multiplier on Published Levven Controls Pricing can't be 0"
                                : ""
                            }
                            onChange={(e) => {
                              var checkDecimals =
                                (e.target.value.indexOf(".") !== -1 &&
                                  parseFloat(e.target.value)
                                    .toString()
                                    .split(".")[1].length <= 12) ||
                                  e.target.value.indexOf(".") === -1
                                  ? true
                                  : false;
                              if (
                                (create || adminManage) &&
                                parseFloat(e.target.value) <= 999999999 &&
                                checkDecimals &&
                                (companyType === "Installer" ||
                                  companyType === "Builder" ||
                                  companyType === "Reseller") &&
                                salesInfoPermission
                              ) {
                                if (parseFloat(e.target.value) < 0) {
                                  setTradeMultiplier(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setTradeMultiplier(
                                    parseFloat(e.target.value)
                                  );
                                }
                                if (tradeMultiplierError)
                                  setTradeMultiplierError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                                if (!create) setSalesInfoChanged(true);
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? companyType === "Installer" ||
                                  companyType === "Builder" ||
                                  companyType === "Reseller"
                                  ? salesInfoPermission
                                    ? false
                                    : true
                                  : true
                                : true
                            }
                            InputProps={{
                              inputProps: {
                                min: 0.01,
                                display: "decimal",
                                step: "0.000000000001",
                              },
                            }}
                          />
                        </Grid>
                        {/* Special commercial ...*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Special Commercial Project Controller Price"
                            fullWidth
                            type="number"
                            value={specialCommercial}
                            required={companyType === "Installer"}
                            error={specialCommercialError}
                            helperText={
                              specialCommercialError
                                ? "Special Commercial Project Controller Price field is empty"
                                : ""
                            }
                            onChange={(e) => {
                              if (
                                (create || adminManage) &&
                                e.target.value.length < 10 &&
                                companyType === "Installer" &&
                                salesInfoPermission
                              ) {
                                if (parseFloat(e.target.value) < 0) {
                                  setSpecialCommercial(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setSpecialCommercial(
                                    parseFloat(e.target.value)
                                  );
                                }
                                if (specialCommercialError)
                                  setSpecialCommercialError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                                if (!create) setSalesInfoChanged(true);
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? companyType === "Installer"
                                  ? salesInfoPermission
                                    ? false
                                    : true
                                  : true
                                : true
                            }
                            InputProps={{
                              inputProps: {
                                min: 0,
                                display: "decimal",
                                maxLength: 15,
                                step: "0.01",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p style={{ marginTop: "2px" }}>
                                    {country
                                      ? country.cur === "USD"
                                        ? "$"
                                        : "C$"
                                      : ""}
                                  </p>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {/* Prepaid Shipping Amount */}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Prepaid Shipping Amount"
                            required
                            fullWidth
                            type="number"
                            value={prepaidAmount}
                            error={prepaidAmountError}
                            helperText={
                              prepaidAmountError
                                ? "Prepaid Shipping Amount field is empty"
                                : ""
                            }
                            onChange={(e) => {
                              if (
                                (create || adminManage) &&
                                e.target.value.length < 10 &&
                                salesInfoPermission
                              ) {
                                if (parseFloat(e.target.value) < 0) {
                                  setPrepaidAmount(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setPrepaidAmount(parseFloat(e.target.value));
                                }
                                if (prepaidAmountError)
                                  setPrepaidAmountError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? salesInfoPermission
                                  ? false
                                  : true
                                : true
                            }
                            InputProps={{
                              inputProps: {
                                min: 0,
                                maxLength: 15,
                                display: "decimal",
                                step: "0.01",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <p style={{ marginTop: "2px" }}>
                                    {country
                                      ? country.cur === "USD"
                                        ? "$"
                                        : "C$"
                                      : ""}
                                  </p>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {/* Number of annual installations*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Number of Annual Installations"
                            required
                            fullWidth
                            type="number"
                            value={nbOfAnnualInstallation}
                            error={nbOfAnnualInstallationError}
                            helperText={
                              nbOfAnnualInstallationError
                                ? "Number of Annual Installations field is empty"
                                : ""
                            }
                            onChange={(e) => {
                              if (
                                (create || adminManage) &&
                                e.target.value.length < 10 &&
                                salesInfoPermission
                              ) {
                                if (e.target.value < 0) {
                                  setNbOfAnnualInstallation(
                                    e.target.value * -1
                                  );
                                } else {
                                  setNbOfAnnualInstallation(e.target.value);
                                }
                                if (nbOfAnnualInstallationError)
                                  setNbOfAnnualInstallationError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                                if (!create) setSalesInfoChanged(true);
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? salesInfoPermission
                                  ? false
                                  : true
                                : true
                            }
                            inputProps={{ maxLength: 10, min: 0 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    ) : null}
                    {/*Save button*/}
                    {create || adminManage || companyAdmin ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <CustomButton
                          type="submit"
                          loading={buttonLoading}
                          startIcon={<Save />}
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Currency:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company ? company.country.cur : ""}
                      </Typography>
                    </Grid>
                    {canViewACC ? (
                      <>
                        {" "}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="caption"
                            fontWeight={500}
                            component="span"
                          >
                            Balance Due:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography variant="caption" component="span">
                            {company ? company.balanceDue : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="caption"
                            fontWeight={500}
                            component="span"
                          >
                            Available Balance:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography variant="caption" component="span">
                            {company ? company.availBalance : ""}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Creation Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company ? convertUTC(company.creationDate) : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Created By:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company
                          ? company.createdBy
                            ? company.createdBy.firstName +
                            " " +
                            company.createdBy.lastName
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modification Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company ? convertUTC(company.modificationDate) : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modified By:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company
                          ? company.modifiedBy
                            ? company.modifiedBy.firstName +
                            " " +
                            company.modifiedBy.lastName
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                {currentCompanyType === "L"  && (<Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                    marginTop:"2rem"
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Customer Group:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company ? company.navCustomer?.customerGroupNo : ""}
                      </Typography>
                    </Grid>
                  </Grid>

                </Paper>)}
              </Grid>
            </Grid>
          </>
        )}

        {saveError !== "" ? (
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        ) : null}
        {relatedOrders.length !== 0 ? (
          <CustomModal openModal={relatedOrders.length !== 0}>
            <Typography variant="h6" component="span" align="left">
              {" "}
              Warning
            </Typography>
            <br />
            <br />
            <Typography variant="body1" component="span" align="left">
              This Company has one or more open orders. Revising the Sales Info
              section will update these orders records:
            </Typography>
            <br />
            <br />
            {relatedOrders.map((order) => (
              <>
                <Typography variant="body1" component="span" align="left">
                  -{" "}
                  {order.jobName !== ""
                    ? order.orderID + " - " + order.jobName
                    : order.orderID}
                </Typography>
                <br />
              </>
            ))}
            <br />
            <Typography variant="body1" component="span" align="left">
              Are you sure you want to proceed?
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                toggle={() => {
                  setRelatedOrders([]);
                  setOrdersAlert(false);
                }}
              >
                Cancel
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton toggle={save} loading={buttonLoading}>
                Save
              </CustomButton>
            </div>
          </CustomModal>
        ) : null}

        {melissaResults.length !== 0 ? (
          <CustomModal openModal={melissaResults.length !== 0} width="60vw">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span">
                  Address Verification
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {melissaResults.map((description) => (
                  <>
                    <Typography variant="body1" component="span">
                      - {description}
                    </Typography>
                    <br />
                  </>
                ))}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body1" component="span">
                  <b>Address Found</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Address: </strong> {street}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Address 2: </strong> {street2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>City: </strong> {city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>State/Province: </strong>{" "}
                      {officeStateProvince.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Country: </strong> {country.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Zip/Post Code: </strong> {zipCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    toggle={() => {
                      setMelissaResults([]);
                      setAddressChanged(true);
                      setAddressVerificationLevel("");
                    }}
                  >
                    Edit Address
                  </CustomButton>

                  <div style={{ width: "10px" }} />
                  <CustomButton
                    toggle={async () => {
                      save();
                    }}
                    loading={buttonLoading}
                  >
                    Proceed
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        ) : null}
        <InfoSnackbar
          open={melissaInfoSnackbar && melissaCreditsDialog === false}
          message={melissaMessageSnackbar}
          severity={melissaSeveritySnackbar}
          handleClose={handleSnackBarClose}
        />
      </>
    );
  }
);

export default CompanyForm;
