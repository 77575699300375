import { useState, useCallback } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
const style = {
  width: "100%",
};
export const Container = ({
  steps,
  setSteps,
  listRef,
  sorting,
  setSorting,
  direction,
  setDirection,
}) => {
  {
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = steps[dragIndex];
        setSteps(
          update(steps, {
            $splice: [
              [dragIndex, 1],
              [
                hoverIndex,
                0,
                {
                  columnID: dragCard.columnID,
                  numeric: dragCard.numeric,
                  disablePadding: dragCard.disablePadding,
                  label: dragCard.label,
                  center: dragCard.center,
                  id: dragCard.id,
                  visibility: dragCard.visibility,
                },
              ],
            ],
          })
        );
      },
      [steps]
    );
    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          sorting={sorting}
          setSorting={setSorting}
          direction={direction}
          setDirection={setDirection}
          card={card}
          id={card.id}
          description={card.label}
          moveCard={moveCard}
          listRef={listRef}
        />
      );
    };
    return (
      <>
        <div style={style}>{steps.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};
