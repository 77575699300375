import React, { useEffect, useState } from "react";
import UsersTableComponent from "../../../components/UsersTableComponent/UsersTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderTable from "../../../components/OrderTable/OrderTable";
import OrderTableTemp from "../../../components/OrderTable/OrderTableTemp";
import TakeoffTableTemp from "../../../components/TakeoffTable/TakeoffTableTemp";
import TakeoffTable from "../../../components/TakeoffTable/TakeoffTable";

const TakeoffsTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  useEffect(() => {
    //for help button
    setPageLink("Takeoffs");

    //for drawer menu
    setPageID(4);
  }, []);

  return (
    <div>
      <TakeoffTable />
      {/* <TakeoffTableTemp /> */}
    </div>
  );
};

export default TakeoffsTable;
