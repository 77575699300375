import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { orderNotesServices } from "../../services/orderNotes.services";
import { accountServices } from "../../services/account.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { Filter, Trash2, Plus } from "react-feather";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";

//function that returns the data sorted
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//function that handles if we view data in ascending or descending order
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Notes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolePermission,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OrderNotesTableComponent({
  orderID,
  setCreateNote = false,
  orderName,
  orderStatus = "",
  value,
  isRMA = false,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  var numSelected = selected.length;
  const [numberRecords, setNumberRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [resubmit, setResubmit] = React.useState(false);
  const [pageLoad, setPageLoad] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");
  const [deleteRecord, setDeleteRecord] = React.useState(false);
  const [applyFilters, setApplyFilters] = React.useState(false);
  const [noteSearch, setNotesearch] = React.useState("");
  const [dateError, setDateError] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [usernames, setUsernames] = React.useState([]);
  const [usernameInformation, setUsernameInformation] = React.useState([]);
  const [userIDs, setUserIDs] = React.useState([]);
  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  const history = useHistory();

  //we get permission of user from redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? isRMA === false
        ? state.user.user.user.role.orders
        : state.user.user.user.role.rma
      : ""
  );

  const handleDateSubmit = async (date) => {
    const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

    if (!regex.test(date)) {
      return "Invalid Date Format";
    }

    const searchDate = moment(date).isValid()
      ? moment(date).format("MM/DD/YYYY")
      : "Invalid Date Format";

    return searchDate;
  };

  const checkDateError = async () => {
    var handleDateErrorDate = "";

    if (date !== null) handleDateErrorDate = await handleDateSubmit(date);

    if (handleDateErrorDate === "Invalid Date Format") {
      setDateError(
        "Wrong date format for the Date field in the filters. Please make sure you are entering the date as MM/DD/YYYY."
      );

      return true;
    } else {
      return false;
    }
  };

  const getFilterData = async () => {
    await accountServices
      .getUsersForNotesByOrder(orderID)
      .then((response) => {
        setUsernameInformation(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const getDataFromDatabase = async () => {
    var dateErr = await checkDateError();

    if (dateErr) {
      setPageLoad(false);
    } else {
      setUsernameInformation([]);
      setRows([]);
      setSelected([]);
      const data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, keyWord: "" },
        Filters: {
          Note: noteSearch,
          Date:
            date === "" || date === null
              ? null
              : moment(new Date(date)).format("MM/DD/YYYY"),
          Users: userIDs,
        },
        OrderID: orderID,
      };

      await orderNotesServices
        .getOrderNoteListByID(data)
        .then((response) => {
          setNumberRecords(response.data.totalNumberOfRecords);
          setPageLoad(false);
          setRows(response.data.orderNotes);
        })
        .catch((error) => {
          setError(error.response.data.error?.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    //we get the data from the database
    //and we get the data as a whole finally

    setPageLoad(true);
    setError("");

    getFilterData();
    getDataFromDatabase();
  }, [page, rowsPerPage, resubmit, value]);

  const handleRequestSort = (event, property) => {
    //function that handles sorting in row
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    //function that selects all rows in table
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.noteID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const submitFilters = (event) => {
    //we resubmit the filters so we get the filtered data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  const deleteOrderNotes = () => {
    //delete selected notes by providing an array of their ID's
    setLoading(true);
    setDeleteRecord(false);
    const data = { OrderNotes: selected };

    orderNotesServices
      .deleteOrderNote(data)
      .then((response) => {
        setResubmit(!resubmit);
        setLoading(false);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };
  const clearFilterData = () => {
    //function to clear filter data
    setUsernames([]);
    setDate(null);
    setNotesearch("");
    setResubmit(!resubmit);
  };

  const viewNotesData = (noteID, orderID, userName, creationdate) => {
    //function to go to the Order Note tabs to view the order data
    history.push({
      pathname: "/order-note-information",
      state: {
        orderID: orderID,
        noteID: noteID,
        orderName: orderName,
        userName: userName,
        creationdate: creationdate,
        isRMA: isRMA,
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page >= 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      orderID: contextMenuData.orderID,
      noteID: contextMenuData.noteID,
      orderName: orderName,
      userName: contextMenuData.createdBy.userName,
      creationdate: contextMenuData.creationDate,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/order-note-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Note Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Note Deletion"
          textContent={"Are you sure you want to delete these records?"}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
          save={deleteOrderNotes}
        />
      ) : (
        ""
      )}
      {dateError ? (
        <AlertSnackBar
          message={dateError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDateError("");
          }}
          open={dateError !== ""}
        />
      ) : (
        ""
      )}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Notes
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Trash2 />}
                    size="medium"
                    tableButton={true}
                    loading={loading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" &&
                (orderStatus === "N" || orderStatus === "D") ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    toggle={() => setCreateNote(true)}
                  >
                    New Note
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilters}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={usernameInformation}
                        getOptionLabel={(option) =>
                          option.userName ? option.userName : ""
                        }
                        value={usernames}
                        onChange={(event, value) => {
                          setUsernames(value);
                          setUserIDs(value.userID);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Username"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                            }}
                            autoComplete="off"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <CustomDatePickerComponent
                        label="Date"
                        value={date}
                        setValue={setDate}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={noteSearch}
                        onChange={(e) => setNotesearch(e.target.value)}
                        label="Note"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton clearButton={true} size="medium" type="submit">
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : null}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {rows.length === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Notes to display."}
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.noteID);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          sx={{ cursor: "pointer" }}
                          tabIndex={-1}
                          key={row.orderID}
                        >
                          <TableCell
                            padding="checkbox"
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                          >
                            {rolePermission === "M" ? (
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.noteID)
                                }
                                checkedIcon={
                                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                                }
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewNotesData(
                                row.noteID,
                                row.orderID,
                                row.createdBy.userName,
                                row.creationDate
                              )
                            }
                          >
                            {row.createdBy.userName}
                          </TableCell>
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewNotesData(
                                row.noteID,
                                row.orderID,
                                row.createdBy.userName,
                                row.creationDate
                              )
                            }
                          >
                            {convertUTC(row.creationDate)}
                          </TableCell>

                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewNotesData(
                                row.noteID,
                                row.orderID,
                                row.createdBy.userName,
                                row.creationDate
                              )
                            }
                          >
                            {row.note}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
