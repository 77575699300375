import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { store } from "../redux/store";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/product";

const getProducts = (data) => {
  return fetchWrapper.post(baseUrl + "/GetProductList", data);
};

const getBulkProducts = () => {
  return fetchWrapper.get(baseUrl + "/GetBulkProducts");
};

const deleteProducts = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteProducts", data);
};

const updateProductStatus = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateProductStatus", data);
};

const getProductByID = (productID) => {
  return fetchWrapper.get(baseUrl + "/GetProductById/" + productID);
};

const updateProductData = (productID, data) => {
  const state = store.getState();
  return fetchWrapper.postFormData(
    baseUrl + "/UpdateProduct/" + productID,
    data,
    {
      headers: {
        // 'Authorization': "Bearer " + state.user.token,
        "content-type": "multipart/form-data",
        Authorization: state.user.user ? "Bearer " + state.user.user.jwtToken : ""
      },
    }
  );
};

const createProduct = (data) => {
  const state = store.getState();
  return fetchWrapper.postFormData(baseUrl + "/CreateProduct", data, {
    headers: {
      // 'Authorization': "Bearer " + state.user.token,
      "content-type": "multipart/form-data",
      Authorization: state.user.user ? "Bearer " + state.user.user.jwtToken : ""
    },
  });
};

const getProductsByCategory = (data) => {
  return fetchWrapper.get(baseUrl + "/GetProductsByCategory/" + data);
};

const getImageFile = (imgPath, productID) => {
  const state = store.getState();
  return fetchWrapper.getFormData(
    baseUrl + "/GetProductImage/" + imgPath + "/" + productID,
    {
      responseType: "blob",
      Accept: [
        "application/webp",
        "application/png",
        "application/jpg",
        "application/jpeg",
      ],
      headers: { Authorization: state.user.user ? "Bearer " + state.user.user.jwtToken : "" },
    }
  );
};

const getSwitchesByColorAndStyle = (data) => {
  return fetchWrapper.post(baseUrl + "/GetSwitchesByColorAndStyle", data);
};

const getProductsByTakeoffWizardStep = (data) => {
  return fetchWrapper.post(baseUrl + "/GetProductsByTakeoffWizardStep", data);
};

const getControllerByAmpsTypeOnOffAsync = (ampsID) => {
  return fetchWrapper.get(baseUrl + "/GetControllerByAmpsTypeOnOffAsync/" + ampsID);
};


export const productServices = {
  getProducts,
  getBulkProducts,
  deleteProducts,
  getProductByID,
  updateProductData,
  createProduct,
  getProductsByCategory,
  getImageFile,
  getSwitchesByColorAndStyle,
  getProductsByTakeoffWizardStep,
  getControllerByAmpsTypeOnOffAsync,
  updateProductStatus
};
