import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BookOpen,
  ChevronsDown,
  ChevronUp,
  Copy,
  Edit,
  Edit2,
  Edit3,
  Phone,
  Save,
  Send,
  Trash2,
  X,
} from "react-feather";
import SnackBar from "../SnackBar/SnackBar";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import ProductQuantitiesAccordion from "../ProductQuantitiesAccordion/ProductQuantitiesAccordion";
import OrderNotesTableComponent from "../OrderNotesTableComponent/OrderNotesTableComponent";
import OrderNotesForm from "../OrderNotesForm/OrderNotesForm";
import { orderQuantitiesServices } from "../../services/orderQuantities.service";
import OrderForm from "../OrderForm/OrderForm";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { orderServices } from "../../services/orders.service";
import OrderStatus from "../OrderStatus/OrderStatus";
import { accountServices } from "../../services/account.services";
import CustomModal from "../CustomModal/CustomModal";
import { orderNotesServices } from "../../services/orderNotes.services";
import { systemDataServices } from "../../services/systemData.services";
import CustomAlert from "../CustomAlert/CustomAlert";
import { emailTemplatesServices } from "../../services/emailTemplates.services";
import { borderRadius } from "@mui/system";
import ChangeProductsTable from "../OrderCreationStepper/ChangeProductsTable";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import AddProductsTable from "../OrderCreationStepper/AddProductsTable";
import InvoiceLinesTable from "../InvoiceLinesTable/InvoiceLinesTable";
import { invoicesServices } from "../../services/invoices.services";
import { makeStyles } from "@material-ui/core/styles";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import OrderReviewPage from "../OrderCreationStepper/OrderReviewPage";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";
import moment from "moment/moment";
import NotificationOrderAlert from "../NotificationOrderAlert";
import { ForwardToInbox } from "@mui/icons-material";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  divStyle3: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
  buttonStyle3: {
    marginBottom: "30px",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    overflow: "auto",
    flexDirection: "row",

    "@media(max-width: 1000px)": {
      display: "block",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OrderTabs({
  setPageID,
  setPageLink,
  openDrawerMenu,
  windowWidth,
  isRMA = false,
}) {
  //retrieve data from redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? isRMA
        ? state.user.user.user.role.orders
        : state.user.user.user.role.rma
      : ""
  );

  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : 0
  );

  /**Get Company type */
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const submitOrderPermission = useSelector((state) =>
    state.user.user
      ? isRMA === false
        ? state.user.user.user.role.submitOrder
        : state.user.user.user.role.submitRMA
      : false
  );

  const currentUsername = useSelector((state) =>
    state.user.user ? state.user.user.user.userName : ""
  );

  const currentUserFirstname = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const currentUserLastname = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  /*used to check if user can see invoices*/
  const canViewInv = useSelector((state) =>
    state.user.user ? state.user.user.user.role.canViewInv : false
  );

  /**Get User Role */
  const userRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  /*Get current user ID from Redux*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const classes = useStyles();

  //used to send data to next page
  const history = useHistory();

  //used to retrieve data sent from previous page
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  } else {
    fromURL = false;
  }

  const buttonDisplayArray = ["N", "D", "F"];
  const buttonDisplayFinal = ["F"];

  const [arrayFrom2, setArrayFrom2] = useState([]);

  const [arrayTo2, setArrayTo2] = useState([]);

  const [displayOrderSaveDraftNotif, setDisplayOrderSaveDraftNotif] =
    useState(false);

  //retrieving tab value from previous page
  const [value, setValue] = useState(0);

  const [fromCreate, setFromCreate] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.fromCreate
          ? decryptedObject.fromCreate
          : false
        : false
      : location.state
      ? location.state.fromCreate
        ? location.state.fromCreate
        : false
      : false
  );

  //to re fetch order data by id to update requested delivery date
  const [refetchOrderData, setRefetchOrderData] = useState(false);

  const [saveOrderNote, setSaveOrderNote] = useState(false);

  const [jobNumInTabs, setJobNumInTabs] = useState("");
  const [orderFromRedirect, setOrderFromRedirect] = useState(null);

  const [prevTab, setPrevTab] = useState(-1);

  const [orderID, setOrderID] = useState(0);

  const [orderName, setOrderName] = useState("");

  /**Defined who will the emai be sent to in case of Send Draft Order for Submission */
  const [receiver, setReceiver] = useState(null);

  //holds the order status
  const [orderStatusInTabs, setOrderStatusInTabs] = useState("");

  const [companyTypeForWarningCheck, setCompanyTypeForWarningCheck] =
    useState("");

  /*Purchase Order Number*/
  const [purchaseOrderNumberInTabs, setPurchaseOrderNumberInTabs] =
    useState("");

  /*Purchasing Company ID*/
  const [companyIDInTabs, setCompanyIDInTabs] = useState(0);

  //if order note has been deleted, display success
  const [deletedSuccessfully, setDeletedSuccessfully] = useState(false);

  const [openDeliveryDateSnackbar, setOpenDeliveryDateSnackbar] =
    useState(false);

  //highlight PO Number field and Requested Date field
  //if Place Order clicked but Requested Date or PO Number empty and not in form tab
  const [highlightRequestedDate, setHighlightRequestedDate] = useState(false);
  const [highlightPONumber, setHighlightPONumber] = useState(false);

  //to tell user to enter quantities before placing order
  const [fillQtyBeforeSubmit, setFillQtyBeforeSubmit] = useState();

  const [openChangeDateSubmit, setOpenChangeDateSubmit] = useState(false);

  const [changedDate, setChangedDate] = useState(null);

  const [changedDateError, setChangedDateError] = useState(false);

  const [requestedDateInTabs, setRequestedDateInTabs] = useState();

  const [agreementDefaultSwitch, setAgreementDefaultSwitch] = useState(null);

  const [defControllerQty, setDefControllerQty] = useState(0);

  const [addressVerificationLevel, setAddressVerificationLevel] = useState("");

  const [fromDashboard, setFromDashboard] = useState(false);

  const [
    displayAddressVerificationWarning,
    setDisplayAddressVerificationWarning,
  ] = useState(false);

  useEffect(() => {
    setValue(
      location.state ? (location.state.value ? location.state.value : 0) : 0
    );

    setFromDashboard(
      fromURL
        ? decryptedObject
          ? decryptedObject.fromDashboard
            ? decryptedObject.fromDashboard
            : false
          : false
        : location.state
        ? location.state.fromDashboard
          ? location.state.fromDashboard
          : false
        : false
    );

    setPrevTab(
      parseInt(
        fromURL
          ? decryptedObject
            ? decryptedObject.nextTab
              ? decryptedObject.nextTab
              : -1
            : -1
          : location.state
          ? location.state.nextTab
            ? location.state.nextTab
            : -1
          : -1
      )
    );

    setJobNumInTabs(
      fromURL
        ? decryptedObject
          ? decryptedObject.jobNum
            ? decryptedObject.jobNum
            : ""
          : ""
        : location.state
        ? location.state.jobNum
          ? location.state.jobNum
          : ""
        : ""
    );

    setOrderFromRedirect(
      fromURL
        ? decryptedObject
          ? decryptedObject.orderFromRedirect
            ? decryptedObject.orderFromRedirect
            : ""
          : ""
        : location.state
        ? location.state.orderFromRedirect
          ? location.state.orderFromRedirect
          : null
        : null
    );

    setOrderID(
      fromURL
        ? decryptedObject
          ? decryptedObject.orderID
            ? decryptedObject.orderID
            : 0
          : 0
        : location.state
        ? location.state.orderID
          ? location.state.orderID
          : 0
        : 0
    );

    //retrieve data from previous page

    if (
      rolePermission === "N" ||
      (fromURL
        ? decryptedObject
          ? decryptedObject.orderID
            ? decryptedObject.orderID
            : 0
          : 0
        : location.state
        ? location.state.orderID
          ? location.state.orderID
          : 0
        : 0) === 0
    ) {
      history.push("/Dashboard");
    }

    setOrderName(
      fromURL
        ? decryptedObject
          ? decryptedObject.orderName
            ? decryptedObject.orderName
            : ""
          : ""
        : location.state
        ? location.state.orderName
          ? location.state.orderName
          : ""
        : ""
    );

    setOrderStatusInTabs(
      fromURL
        ? decryptedObject
          ? decryptedObject.orderStatusInTabs
            ? decryptedObject.orderStatusInTabs
            : "N"
          : "N"
        : location.state
        ? location.state.orderStatus
          ? location.state.orderStatus
          : "N"
        : "N"
    );

    setPurchaseOrderNumberInTabs(
      fromURL
        ? decryptedObject
          ? decryptedObject.purchaseOrderNbr
            ? decryptedObject.purchaseOrderNbr
            : ""
          : ""
        : location.state
        ? location.state.purchaseOrderNbr
          ? location.state.purchaseOrderNbr
          : ""
        : ""
    );

    setCompanyIDInTabs(
      fromURL
        ? decryptedObject
          ? decryptedObject.purchaserCompanyID
            ? decryptedObject.purchaserCompanyID
            : 0
          : 0
        : location.state
        ? location.state.purchaserCompanyID
          ? location.state.purchaserCompanyID
          : 0
        : 0
    );

    setDeletedSuccessfully(
      fromURL
        ? decryptedObject
          ? decryptedObject.deletedSuccessfully
            ? decryptedObject.deletedSuccessfully
            : false
          : false
        : location.state
        ? location.state.deletedSuccessfully
          ? location.state.deletedSuccessfully
          : false
        : false
    );

    setFillQtyBeforeSubmit(
      fromURL
        ? decryptedObject
          ? decryptedObject.id
            ? decryptedObject.id
            : false
          : false
        : location.state
        ? location.state.openDraftAlert
          ? location.state.openDraftAlert
          : false
        : false
    );

    setRequestedDateInTabs(
      fromURL
        ? decryptedObject
          ? decryptedObject.requestedDate
            ? decryptedObject.requestedDate
            : ""
          : ""
        : location.state
        ? location.state.requestedDate
          ? location.state.requestedDate
          : ""
        : ""
    );
  }, []);

  /*holds the number of days for the order requested delivery date minimum*/
  const [minRequestedDeliveryDate, setMinRequestedDeliveryDate] =
    useState(null);

  const [dateChangedLoading, setDateChangeLoading] = useState(false);

  //used to access data and functions in child components
  const orderRef = useRef(null);

  //used for snackbar display
  const [success, setSuccess] = useState(false);

  //used for snackbar message
  const [message, setMessage] = useState("");

  //holds error during deletion
  const [deleteError, setDeleteError] = useState("");

  //used to display note creation form
  const [createNote, setCreateNote] = useState(false);

  //holds errors if any
  const [error, setError] = useState("");

  const [saved, setSaved] = useState(false);

  //holds initial value for default Unit Price for Controller
  const [initDefUpc, setInitDefUpc] = useState(0);

  const [openLevvenContactsModal, setOpenLevvenContactsModal] = useState(false);

  const [priceResetLoading, setPriceResetLoading] = useState(false);

  //holds errors
  const [saveError, setSaveError] = useState("");

  //makes order quantities page load until values are fetched
  const [pageLoad, setPageLoad] = useState(true);

  const [levvenContactText, setLevvenContactText] = useState([]);
  const [levvenContactErrorText, setLevvenContactErrorText] = useState("");
  const [levvenContactLoading, setLevvenContactLoading] = useState(false);

  //used in case an error occured while fetching the order quantities
  const [getError, setGetError] = useState("");

  //to check if any change has been done
  const [formChanged, setFormChanged] = useState(false);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  /*Open and Close the modal containing the user that can submit order*/
  const [canSubmitModal, setCanSubmitModal] = useState(false);
  const [canSubmitUsers, setCanSubmitUsers] = useState([]);
  const [chosenCanSubmitUser, setChosenCanSubmitUser] = useState();
  const [chosenCanSubmitUserError, setChosenCanSubmitUserError] =
    useState(false);

  //holds the previous quantity of switches for comparison when Controller Unit Price changed and changing quantity
  const [oldQtySwitchesRange, setOldQtySwitchesRange] = useState(0);

  /*Open and Close the alert dialog when submitting an order*/
  const [canSubmitAlert, setCanSubmitAlert] = useState(false);

  //used for calculations
  const [totalSwitches, setTotalSwitches] = useState(0);
  const [totalWallPlates, setTotalWallPlates] = useState(0);
  const [totalQuickMount, setTotalQuickMount] = useState(0);

  //saves default controller price ranges
  const [defaultPriceRanges, setDefaultPriceRanges] = useState([]);

  const [resubmit, setResubmit] = useState(false);

  //holds default controller
  const [defaultController, setDefaultController] = useState({});

  //holds default unit price of controller
  const [defUPC, setDefUPC] = useState(0);

  //holds coordinates of products that have default controller
  const [hasDefaultController, setHasDefaultController] = useState([]);

  //used to open accordion
  const [opened, setOpened] = useState([]);

  //for save button loading
  const [buttonLoading, setButtonLoading] = useState(false);

  //changes if any change happened in the page
  const [changeMade, setChangeMade] = useState(false);

  //holds total price of order
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);

  //if special negotiated is true
  const [specialNegotiated, setSpecialNegotiated] = useState(false);

  const [TwentyAmpsCreated, setTwentyAmpsCreated] = useState(false);

  const [levvenContactError, setLevvenContactError] = useState(false);

  //holds all index of accordions to open
  const [openedAccordion, setOpenedAccordion] = useState([]);

  //to get currency
  const [currency, setCurrency] = useState("");

  //to get currency for invoice
  const [currencyInvoice, setCurrencyInvoice] = useState("");

  const [reopenDialog, setReopenDialog] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [showWarning2, setShowWarning2] = useState(false);
  const [showWarning3, setShowWarning3] = useState(false);

  // send notification alert
  const [alertNotification, setAlertNotification] = useState(false);

  //get Levven Q place in 2D array
  const [levvenQ, setLevvenQ] = useState({});

  //for warning before save
  const [updateRecords, setUpdateRecords] = useState(false);

  const [submitValues, setSubmitValues] = useState(false);

  //to get order type
  const [orderType, setOrderType] = useState("");

  const [loading, setLoading] = useState(false);
  const [reopenLoading, setReopenLoading] = useState(false);
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [maxDimmer, setMaxDimmer] = useState(0);
  const [orderVersion, setOrderVerion] = useState(0);

  /*Email Body*/
  const [emailBody, setEmailBody] = useState();
  const [emailBodyError, setEmailBodyError] = useState(false);
  const [openEmailSection, setOpenEmailSection] = useState(false);

  const [duplicateLoading, setDuplicateLoading] = React.useState(false);

  /**holds value of order fulfillment threshold */
  const [orderFulfillmentThreshold, setOrderFulfillmentThreshold] = useState(0);

  /*Levven Contact Email*/
  const [orderInTabs, setOrderInTabs] = useState();

  //to display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = useState(false);

  const [selectedFrom2, setSelectedFrom2] = useState(null);
  const [selectedTo2, setSelectedTo2] = useState(null);

  /**In Case of Error while checking for edit order */
  const [editOrderError, setEditOrderError] = useState("");

  const [steps, setSteps] = React.useState([]);
  var [rows, setRows] = React.useState([]);

  /*Email Button Loading*/
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);

  /*Warning dialog on submission*/
  const [submissionWarningOpen, setSubmissionWarningOpen] = useState(false);

  /*For Change Products Table*/
  const [arrayFrom, setArrayFrom] = useState([]);
  const [arrayTo, setArrayTo] = useState([]);
  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [valueToAdd, setValueToAdd] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [rowsChange, setRowsChange] = useState([]);
  const [changeLoading, setChangeLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorOnSave, setErrorOnSave] = useState(false);
  const [saveQuantitiesLoading, setSaveQuantitiesLoading] = useState(false);
  const [changeError, setChangeError] = useState("");

  /*Holds the rows for the invoice lines rows*/
  const [invoiceRows, setInvoiceRows] = useState([]);

  /*holds total number of records in databse*/
  const [numberInvoiceLines, setNumberInvoiceLines] = useState(0);

  /*page loading for invoice tab */
  const [invoicePageLoad, setInvoicePageLoad] = useState(false);

  /*holds the sum of the total for the invoice*/
  const [sumOfTotalInvoice, setSumOfTotalInvoice] = useState(0);

  /*holds the sum of the total for the invoice after tax*/
  const [sumOfTotalInvoiceAfterTax, setSumOfTotalInvoiceAfterTax] = useState(0);

  /*holds tax value*/
  const [taxValue, setTaxValue] = useState(0);

  /** holds the page and rows per page for the invoices table */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  /*Flag to open and close the submission snackbar*/
  const [submitSnackBarOpen, setSubmitSnackBarOpen] = useState(false);

  /*Check if date is in the next 2 weeks in the future*/
  const [dateAlertDialogOpen, setDateAlertDialogOpen] = useState(false);

  const [loadingSendNow, setLoadingSendNow] = useState(false);
  const [loadingSend24, setLoadingSend24] = useState(false);

  //holds minDate allowed for change requested date
  const [changeRequestedDateMinDate, setChangeRequestedDateMinDate] =
    useState(null);

  const [dataFetched, setDataFetched] = useState(false);

  //to display modal of change products
  const [changeQuantities, setChangeQuantities] = useState(false);

  //to display order review page
  const [displayOrderReview, setDisplayOrderReview] = useState(false);

  const [addAssemblyRows, setAddAssemblyRows] = useState([]);
  const [addSwitchRows, setAddSwitchRows] = useState([]);

  //holds if this order is a complementary project pack
  const [orderIsCompPP, setOrderIsCompPP] = useState(false);

  //if changed happened to add product
  const [changed, setChanged] = React.useState(false);
  useEffect(() => {}, [changed]);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );

  /**Function to fetch the minimum number of days for the earliest requested delivery date */
  const fetchMinimumRequestedDeliveryDate = async () => {
    await systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        var minDaysToMilliseconds =
          parseInt(response.data.orderFulfillmentThreshold) *
          24 *
          60 *
          60 *
          1000;
        setOrderFulfillmentThreshold(response.data.orderFulfillmentThreshold);
        setMinRequestedDeliveryDate(minDaysToMilliseconds);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  useEffect(() => {
    fetchMinimumRequestedDeliveryDate();
  }, []);

  const notifSavedDraftReadyToOrder = useSelector((state) =>
    companyType !== "L"
      ? state.user.user
        ? state.user.user.user.notifSavedDraftReadyToOrder
        : false
      : orderInTabs && orderInTabs !== null && orderInTabs.purchaser
      ? orderInTabs.purchaser.notifSavedDraftReadyToOrder
      : false
  );

  useEffect(() => {
    /**If we are coming from order redirect page we fill the value of the order in tabs object for the edit order to work */
    if (orderFromRedirect !== null && orderFromRedirect !== undefined) {
      setOrderInTabs(orderFromRedirect);
      console.log("order from redirect", orderInTabs);
    }
  }, []);

  const createNoteTwentyAmps = async () => {
    const data = {
      OrderID: orderID,
      Note: "For safety, the fulfillment staff should NOT pair into the Quick Kit Controller Group the 20A controllers. Pair in field as required.",
      CreatedByID: 0,
      IsTwentyAmps: true,
    };

    await orderNotesServices
      .createOrderNote(data)
      .then((response) => {
        setTwentyAmpsCreated(true);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const checkTwentyAmps = async (array) => {
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].length; j++) {
        if (array[i][j].product.category.categoryCode === 1) {
          if (
            array[i][j].product.controller.amps.ampsCode === 4 &&
            array[i][j].quantity !== null &&
            array[i][j].quantity.quantity !== 0
          ) {
            await createNoteTwentyAmps();
            return;
          }
        }
      }
    }
  };

  const checkIfAccordionOpened = (array, val) => {
    //function to check which accordions to open (which accordions have products that contain quantities)
    for (var i = 0; i < array.length; i++) {
      if (
        array[i].quantity !== null &&
        array[i].quantity.quantity !== null &&
        array[i].quantity.quantity !== 0
      ) {
        setOpened((prevOpened) => [...prevOpened, val]);
        setOpenedAccordion((prevOpened) => [...prevOpened, val]);
        break;
      }
    }
  };

  const getDefaultUPCByRange = (quantity, priceRanges) => {
    const qty = quantity === 0 ? defControllerQty : quantity;
    //function to return the default unit price for default controller
    if (orderIsCompPP === false) {
      if (qty !== 0) {
        for (var i = 0; i < priceRanges.length; i++) {
          if (priceRanges[i].to === null) {
            return priceRanges[i].price;
          } else if (qty >= priceRanges[i].from && qty <= priceRanges[i].to) {
            return priceRanges[i].price;
          }
        }
        return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  /*Get controller default price by total switches number*/
  const getPriceByRange = async (quantity, priceRanges, isCompPP = false) => {
    const qty = quantity === 0 ? defControllerQty : quantity;
    if (isCompPP === false) {
      if (qty !== 0) {
        if (priceRanges.length === 0) {
          setDefUPC(0);
        }
        for (var i = 0; i < priceRanges.length; i++) {
          if (priceRanges[i].to === null) {
            setDefUPC(priceRanges[i].price);
            break;
          } else if (qty >= priceRanges[i].from && qty <= priceRanges[i].to) {
            setDefUPC(priceRanges[i].price);
            break;
          }
        }
      } else {
        setDefUPC(0);
      }
    } else {
      setDefUPC(0);
    }
  };

  const filterDefaultControllerProducts = (ar, defaultController) => {
    //function that saves products that will be affected and affect the default unit price for default controller
    setHasDefaultController([]);
    var i = 0;
    var j = 0;
    ar.forEach((a) => {
      j = 0;
      a.products.forEach((ap) => {
        if (
          (ap.product.category.categoryCode === 1 &&
            ap.product.controller.productID === defaultController.productID) ||
          (ap.product.category.categoryCode === 4 &&
            ap.product.productID === defaultController.productID) ||
          ap.isDimmer === true
        ) {
          setHasDefaultController((hasDefaultController) => [
            ...hasDefaultController,
            { index1: i, index2: j },
          ]);
        }
        j++;
      });
      i++;
    });
  };

  const getLevvenQPosition = (array, type) => {
    //holds position for Levven Q product so we can access it directly later on
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].products.length; j++) {
        if (
          type === "P" &&
          array[i].products[j].product.category.categoryCode === 6
        ) {
          setLevvenQ({ x: i, y: j });
          return;
        }
      }
    }
  };

  useEffect(() => {
    const tabFromLink = params.get("tab") ? parseInt(params.get("tab")) : -1;

    //for drawer menu
    if (isRMA) {
      setPageID(11);
    } else if (!fromDashboard) setPageID(5);
    else setPageID(0);

    if (isRMA) setPageLink("RMA");
    else setPageLink("Orders");

    if (prevTab >= 0 && prevTab <= 4) {
      if (prevTab === 4) {
        if (orderStatusInTabs === "V" && canViewInv) {
          setValue(prevTab);
        }
      } else {
        setValue(prevTab);
      }
    }

    if (tabFromLink >= 0 && tabFromLink <= 4) {
      if (tabFromLink === 4) {
        if (orderStatusInTabs === "V" && canViewInv) {
          setValue(tabFromLink);
        }
      } else {
        setValue(tabFromLink);
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTotalSwitches(0);
    setTotalWallPlates(0);
    setTotalQuickMount(0);
    setTotalOrderPrice(0);
    setInvoiceRows([]);
    setNumberInvoiceLines(0);
    setSumOfTotalInvoice(0);

    if (value === 1) {
      //fetch data from database only if we are on the order quantities tab
      //getting the values from the database
      setPageLoad(true);
      setRows([]);
      setSteps([]);
      setGetError("");
      setDefUPC(0);
      setOpened([]);
      setSubmitValues(false);
      setOpenedAccordion([]);
      setChangeQuantities(false);
      setArrayFrom([]);
      setArrayFrom2([]);
      setArrayTo2([]);
      setArrayTo([]);
      setSelectedFrom(null);
      setSelectedTo(null);
      setValueToAdd(null);
      setMaxQuantity(0);
      setRowsChange([]);
      setSaveQuantitiesLoading(false);
      setSpecialNegotiated(false);
      setDataFetched(false);

      const data = { OrderID: orderID };

      orderQuantitiesServices
        .GetProductListByTakeoffWizardStepForOrder(data)
        .then(async (response) => {
          if (response.data.defaultControllerPrices !== null)
            setDefaultPriceRanges(response.data.defaultControllerPrices);
          if (response.data.defaultController)
            setDefaultController(response.data.defaultController);
          else setDefaultController({});
          setTotalOrderPrice(response.data.order.orderTotal);
          if (response.data.defaultController)
            filterDefaultControllerProducts(
              response.data.quantities,
              response.data.defaultController
            );
          if (
            response.data.isSpecialNegotiated === true &&
            response.data.isCompPP === false
          ) {
            setSpecialNegotiated(true);
            setDefUPC(response.data.specialNegotiatedPrice);
            //we save the special nagotiated value and default UPC in the same variable to not define 7000 variables
            //and the order can either have a default unit price controller or a special negotiated price
          }
          if (response.data.order !== null) {
            setOrderVerion(response.data.order.version);
          }
          if (response.data.order.takeoff !== null) {
            if (
              response.data.order.takeoff.agreement.dimmerAtControllerPrice ===
              true
            ) {
              setMaxDimmer(
                response.data.order.takeoff.agreement.dimmerUpgradeQuantity
              );
            }
          }

          setCurrency(response.data.order.purchaser.company.country.cur);
          setOrderIsCompPP(response.data.isCompPP);

          setOrderType(response.data.order.type);
          await getLevvenQPosition(
            response.data.quantities,
            response.data.order.type
          );

          var tmpArray = [];
          var i = 0;
          setDefControllerQty(response.data.defaultControllerQty);
          await response.data.quantities.forEach(async (steps) => {
            setSteps((step) => [...step, steps.step.title]);
            setRows((rows) => [...rows, steps.products]);

            tmpArray.push(steps.products);
            checkIfAccordionOpened(steps.products, i);
            if (steps.products.length > 0) {
              setTotalSwitches(steps.products[0].totalNumberOfSwitches);
              setTotalWallPlates(steps.products[0].totalNumberOfPlatePositions);
              setTotalQuickMount(
                steps.products[0].totalNumberQuickMountSwitches
              );
              if (
                response.data.isSpecialNegotiated === false &&
                response.data.defaultControllerPrices !== null
              ) {
                getPriceByRange(
                  steps.products[0].totalNumberOfSwitches === 0
                    ? response.data.defaultControllerQty
                    : steps.products[0].totalNumberOfSwitches,
                  response.data.defaultControllerPrices,
                  response.data.isCompPP
                );
              }
            }
            i++;
          });

          if (fromCreate === true && TwentyAmpsCreated === false) {
            await checkTwentyAmps(tmpArray);
            await setFromCreate(false);
          }
          setPageLoad(false);
        })
        .catch((error) => {
          setPageLoad(false);
          setGetError(error.response.data.error.error);
        });
    } else if (value === 4) {
      setInvoicePageLoad(true);
      invoicesServices
        .getInvoicesLinesByOrderID({
          Keys: { Page: page + 1, PageSize: rowsPerPage },
          OrderID: orderID,
        })
        .then((response) => {
          setInvoiceRows(response.data.invoiceLines);
          setNumberInvoiceLines(response.data.totalNumberOfRecords);
          setSumOfTotalInvoice(response.data.totalPriceSum);
          setSumOfTotalInvoiceAfterTax(response.data.totalPriceIncludingTaxSum);
          setTaxValue(response.data.totalSumsDifference);
          if (orderInTabs)
            setCurrencyInvoice(orderInTabs.purchaser.company.country.cur);
          setInvoicePageLoad(false);
        })
        .catch((error) => {
          setInvoicePageLoad(false);
          setGetError(error.response.data.error.error);
        });
    }
  }, [value, resubmit, page, rowsPerPage]);

  useEffect(() => {
    //function that updates the default unit price for default controller depending on change of the total number of switches
    if (pageLoad === false) {
      if (specialNegotiated === false) {
        getPriceByRange(
          totalSwitches === 0 ? defControllerQty : totalSwitches,
          defaultPriceRanges,
          orderIsCompPP
        );
      }
    }
  }, [totalSwitches]);

  useEffect(() => {
    //function that updates the default unit price for default controller depending on change of default controller price
    if (pageLoad === false) {
      if (specialNegotiated === false && totalSwitches === 0) {
        getPriceByRange(
          totalSwitches === 0 ? defControllerQty : totalSwitches,
          defaultPriceRanges,
          orderIsCompPP
        );
      }
    }
  }, [defControllerQty]);

  const updateUnitPrices = async () => {
    //updates unit prices of all products that have the default unit price for default controller on change of the default unit price for default controller
    var tmpArray = rows;
    var tmp = totalOrderPrice;
    for (var i = 0; i < hasDefaultController.length; i++) {
      var previous = 0;
      if (
        tmpArray[hasDefaultController[i].index1][hasDefaultController[i].index2]
          .quantity !== null
      ) {
        tmpArray[hasDefaultController[i].index1][
          hasDefaultController[i].index2
        ].quantity.unitPrice = defUPC;

        //If price changed we keep value of new price changed else w eupdate it to newest default upc
        tmpArray[hasDefaultController[i].index1][
          hasDefaultController[i].index2
        ].quantity.editedUnitPrice =
          tmpArray[hasDefaultController[i].index1][
            hasDefaultController[i].index2
          ].quantity.priceChanged === false
            ? defUPC
            : tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.editedUnitPrice;

        /*------------*/
        if (
          tmpArray[hasDefaultController[i].index1][
            hasDefaultController[i].index2
          ].product.category.categoryCode === 1
        ) {
          if (
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].product.controller.productID === defaultController.productID ||
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].isDimmer === true
          ) {
            previous =
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total;
            if (
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.priceChanged === true
            ) {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.editedUnitPrice +
                        tmpArray[hasDefaultController[i].index1][
                          hasDefaultController[i].index2
                        ].quantity.switchQuantity *
                          tmpArray[hasDefaultController[i].index1][
                            hasDefaultController[i].index2
                          ].quantity.editedSwitchUnitPrice)
                  ) * 100
                ) / 100;
            } else {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (defUPC +
                        tmpArray[hasDefaultController[i].index1][
                          hasDefaultController[i].index2
                        ].quantity.switchQuantity *
                          tmpArray[hasDefaultController[i].index1][
                            hasDefaultController[i].index2
                          ].quantity.switchUnitPrice)
                  ) * 100
                ) / 100;
            }
          } else {
            previous =
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total;

            if (
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.priceChanged === true
            ) {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.editedUnitPrice +
                        tmpArray[hasDefaultController[i].index1][
                          hasDefaultController[i].index2
                        ].quantity.switchQuantity *
                          tmpArray[hasDefaultController[i].index1][
                            hasDefaultController[i].index2
                          ].quantity.editedSwitchUnitPrice)
                  ) * 100
                ) / 100;
            } else {
              tmpArray[hasDefaultController[i].index1][
                hasDefaultController[i].index2
              ].quantity.total =
                Math.round(
                  parseFloat(
                    parseInt(
                      tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.quantity
                    ) *
                      (tmpArray[hasDefaultController[i].index1][
                        hasDefaultController[i].index2
                      ].quantity.unitPrice +
                        tmpArray[hasDefaultController[i].index1][
                          hasDefaultController[i].index2
                        ].quantity.switchQuantity *
                          tmpArray[hasDefaultController[i].index1][
                            hasDefaultController[i].index2
                          ].quantity.switchUnitPrice)
                  ) * 100
                ) / 100;
            }
          }
        } else if (
          tmpArray[hasDefaultController[i].index1][
            hasDefaultController[i].index2
          ].product.productID === defaultController.productID
        ) {
          previous =
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total;

          if (
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.priceChanged === true
          ) {
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.quantity
                  ) *
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.editedUnitPrice
                ) * 100
              ) / 100;
          } else {
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total =
              Math.round(
                parseFloat(
                  parseInt(
                    tmpArray[hasDefaultController[i].index1][
                      hasDefaultController[i].index2
                    ].quantity.quantity
                  ) * defUPC
                ) * 100
              ) / 100;
          }
        }

        tmp = tmp - parseFloat(previous);
        tmp =
          tmp +
          parseFloat(
            tmpArray[hasDefaultController[i].index1][
              hasDefaultController[i].index2
            ].quantity.total
          );
      }
    }
    setTotalOrderPrice(tmp);
    setRows(tmpArray);
    setPriceResetLoading(false);
  };

  useEffect(() => {
    //function that updates unit price values on change of default unit price
    if (pageLoad === false) {
      if (!specialNegotiated) {
        updateUnitPrices();
      }
    }
  }, [defUPC]);

  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //function to handle page change
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
      setCreateNote(false);
    } else {
      setValue(newValue);
      setCreateNote(false);
    }
  };

  const handleConfirm = () => {
    sessionStorage.removeItem("unsavedChanges");

    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const sendEmail = async () => {
    if (
      orderInTabs !== null &&
      orderInTabs !== undefined &&
      minRequestedDeliveryDate !== null
    ) {
      var newDate = new Date(orderInTabs.requestedDeliveryDate);
      var currentDate = new Date();
      var minimumRequestedDateForDeliveryDate = new Date(
        Date.now() + minRequestedDeliveryDate
      );

      minimumRequestedDateForDeliveryDate.setHours(0, 0, 0, 0);
      newDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      if (
        (newDate >= currentDate &&
          newDate <= minimumRequestedDateForDeliveryDate) ||
        newDate < currentDate
      ) {
        var daysTillrequestedDeliveryDate =
          minRequestedDeliveryDate / (24 * 60 * 60 * 1000);
        setOpenLevvenContactsModal(false);
        setEditOrderError(
          "You cannot edit this " +
            (isRMA === false ? "order" : "RMA") +
            " since there is less than " +
            daysTillrequestedDeliveryDate +
            " days until your requested deivery date"
        );
      } else {
        setEmailButtonLoading(true);
        const body =
          "Customer: " +
          orderInTabs.purchaser.company.name +
          "\n" +
          "Username: " +
          currentUsername +
          "\n" +
          (isRMA === false ? "Customer PO:" : "Service Case/Ticket Number:") +
          orderInTabs.purchaseOrderNbr +
          "\n" +
          "Sales Order No.: " +
          orderInTabs.navNo +
          "\n\n\n" +
          "Change request:" +
          " MY CHANGE REQUEST HERE . . .";

        var subject =
          "Change request to submitted order: " +
          orderInTabs.purchaser.company.name +
          " - " +
          orderInTabs.purchaseOrderNbr;

        const encodedBody = await encodeURI(body);
        const encodedSubject = await encodeURI(subject);

        await systemDataServices
          .getSystemDataVariables()
          .then(async (response) => {
            await window.open(
              "mailto:" +
                response.data.levvenOrderContactEmail +
                "?subject=" +
                encodedSubject +
                "&body=" +
                encodedBody +
                ""
            );
            setEmailButtonLoading(false);

            setOpenLevvenContactsModal(false);
          })
          .catch((error) => {
            setError(error.response.data.error.error);
            setEmailButtonLoading(false);
          });
      }
    } else {
      setEditOrderError("An error occured while editing your order");
    }
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    //function for rerendering so values are changes and updated on the display
    setRows(rows);
  }, [changeMade]);

  const deleteRecords = () => {
    //for deleting records selected
    setLoading(true);
    setDeleteRecord(false);
    orderServices
      .deleteOrders({ Orders: [orderID] })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: isRMA === false ? "/Orders" : "/RMA",
          state: {
            openSnackbar: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  const getProductsToChangeFrom = async (orderID) => {
    setArrayFrom([]);
    setArrayTo([]);
    setArrayFrom2([]);
    setArrayTo2([]);
    setChangeError("");
    setSelectedFrom2(null);
    setSelectedTo2(null);
    setSelectedFrom(null);
    setSelectedTo(null);
    setChangeQuantities(true);
    setChangeLoading(true);
    setAddSwitchRows([]);
    setAddAssemblyRows([]);

    await orderQuantitiesServices
      .getListOfProductsToBeChangedForOrderCreation({
        OrderID: orderID,
      })
      .then(async (response) => {
        await setArrayFrom(response.data.from);
        await setArrayTo2(response.data.toSwitchColorAndStyle);
        await setArrayFrom2(response.data.from);
        await setArrayTo(response.data.to);
        await setMaxQuantity(response.data.maxQuantity);
        await setAgreementDefaultSwitch({
          style: response.data.defaultSwitchStyle,
          color: response.data.defaultSwitchColor,
        });

        await orderQuantitiesServices
          .getListOfProductsToAddForOrderQuantitiesCreation({
            OrderID: orderID,
          })
          .then(async (response) => {
            await setAddSwitchRows(response.data.switches);
            await setAddAssemblyRows(response.data.assemblies);
            await setChangeLoading(false);
          })
          .catch((error) => {
            setChangeError(error.response.data.error.error);
            setChangeLoading(false);
          });
      })
      .catch((error) => {
        setChangeError(error.response.data.error.error);
        setChangeLoading(false);
      });
  };

  const saveQuantities = async (beforeSubmit = false) => {
    //function to save order quantities
    if (
      totalQuickMount === totalWallPlates ||
      isRMA === true ||
      companyTypeForWarningCheck === "R"
    ) {
      setPristine();
      setButtonLoading(true);
      await orderQuantitiesServices
        .UpdateOrderQuantity({
          OrderID: orderID,
          ProductQuantities: rows,
          SwitchesNum: totalSwitches,
          SwitchPlatePositionsNum: totalWallPlates,
          QuickMountSwitches: totalQuickMount,
          OrderTotal: Math.floor(parseFloat(totalOrderPrice) * 100) / 100,
          RecalculatePrices: false,
        })
        .then(async () => {
          if (orderStatusInTabs === "D") {
            await orderServices
              .submitOrder({
                OrderID: orderID,
                Status: "N",
                UserID: currentUserID,
              })
              .then(() => {
                setOrderStatusInTabs("N");
              })
              .catch((error) => {
                setError(error.response.data.error.error);
                if (beforeSubmit) {
                  setSubmissionWarningOpen(false);
                }
              });
          }
          await checkTwentyAmps(rows);
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setSuccess(true);
          setResubmit(!resubmit);
          setButtonLoading(false);
          setMessage("Saved Successfully");
          setShowWarning(false);
          setShowWarning2(false);
          setShowWarning3(false);
          setCanSubmitAlert(false);
          setCanSubmitModal(false);
          if (
            beforeSubmit &&
            purchaseOrderNumberInTabs !== "" &&
            requestedDateInTabs !== "" &&
            requestedDateInTabs !== "Invalid Date Format"
          ) {
            submitOrderPermission ? submitOrder() : finalizeOrder();
          } else if (beforeSubmit) {
            if (requestedDateInTabs === "" || requestedDateInTabs === null) {
              setHighlightRequestedDate(true);
            }
            if (
              purchaseOrderNumberInTabs === "" ||
              purchaseOrderNumberInTabs === null
            ) {
              setHighlightPONumber(true);
            }
            setValue(0);

            setSubmissionWarningOpen(false);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          setError(error.response.data.error.error);
          setSubmitLoading(false);
          setShowWarning(false);
          setShowWarning2(false);
          setShowWarning3(false);
          setCanSubmitAlert(false);
          setCanSubmitModal(false);
          if (beforeSubmit) {
            setSubmissionWarningOpen(false);
          }
        });
    } else {
      if (orderType === "P") {
        if (beforeSubmit) {
          setShowWarning2(true);
          setSubmissionWarningOpen(false);
        } else {
          setShowWarning(true);
        }
      } else {
        if (beforeSubmit) {
          setSubmissionWarningOpen(false);
          saveQuantitiesFinal(true);
        } else {
          saveQuantitiesFinal(false);
        }
      }
      setCanSubmitAlert(false);
      setCanSubmitModal(false);
      setSubmitLoading(false);
    }
  };

  const saveQuantitiesFinal = (beforeSubmit = false) => {
    //function to save quantities in database
    setPristine();
    setButtonLoading(true);
    setShowWarning(false);
    orderQuantitiesServices
      .UpdateOrderQuantity({
        OrderID: orderID,
        ProductQuantities: rows,
        SwitchesNum: totalSwitches,
        SwitchPlatePositionsNum: totalWallPlates,
        QuickMountSwitches: totalQuickMount,
        OrderTotal: Math.floor(parseFloat(totalOrderPrice) * 100) / 100,
        RecalculatePrices: false,
      })
      .then(async () => {
        if (orderStatusInTabs === "D") {
          await orderServices
            .submitOrder({
              OrderID: orderID,
              Status: "N",
              UserID: currentUserID,
            })
            .then(() => {
              setOrderStatusInTabs("N");
            })
            .catch((error) => {
              setError(error.response.data.error.error);
            });
        }
        await checkTwentyAmps(rows);
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        if (!beforeSubmit) {
          setSuccess(true);
        }
        setResubmit(!resubmit);
        setButtonLoading(false);
        setMessage("Saved Successfully");
        setShowWarning(false);
        setShowWarning2(false);
        setShowWarning3(false);
        setCanSubmitAlert(false);
        setCanSubmitModal(false);
        if (
          beforeSubmit &&
          purchaseOrderNumberInTabs !== "" &&
          requestedDateInTabs !== "" &&
          requestedDateInTabs !== "Invalid Date Format"
        ) {
          submitOrderPermission ? submitOrder() : finalizeOrder();
        } else if (beforeSubmit) {
          if (requestedDateInTabs === "" || requestedDateInTabs === null) {
            setHighlightRequestedDate(true);
          }
          if (
            purchaseOrderNumberInTabs === "" ||
            purchaseOrderNumberInTabs === null
          ) {
            setHighlightPONumber(true);
          }
          setValue(0);
          setSubmissionWarningOpen(false);
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        setSubmitLoading(false);
        setShowWarning(false);
        setShowWarning2(false);
        setShowWarning3(false);
        setCanSubmitAlert(false);
        setCanSubmitModal(false);
        if (beforeSubmit) {
          setSubmissionWarningOpen(false);
        }
        setError(error.response.data.error.error);
      });
  };

  /*Submit Order*/
  const submitOrder = async () => {
    setCanSubmitAlert(false);
    setDisplayOrderReview(true);
  };

  const submitOrderAfterReview = async () => {
    setSubmitLoading(true);
    await orderServices
      .submitOrder({
        OrderID: orderID,
        Status: "T",
        UserID: currentUserID,
      })
      .then(() => {
        setDisplayAddressVerificationWarning(false);
        setOrderStatusInTabs("T");
        setSubmitLoading(false);
        setCanSubmitAlert(false);
        setSubmitSnackBarOpen(true);
        setDisplayOrderReview(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setSubmitLoading(false);
        setCanSubmitAlert(false);
      });
  };

  const finalizeOrder = async () => {
    //function for order finalization
    setSubmitLoading(true);
    await orderServices
      .submitOrder({
        OrderID: orderID,
        Status: "F",
        UserID: currentUserID,
      })
      .then(() => {
        setOrderStatusInTabs("F");
        orderServices
          .sendApprovalEmail({
            Receiver: chosenCanSubmitUser.userName,
            Sender: currentUsername,
            OrderID: orderID,
          })
          .then(() => {
            setSubmitLoading(false);
            setCanSubmitModal(false);
            setSubmitSnackBarOpen(true);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
            setSubmitLoading(false);
            setCanSubmitModal(false);
          });
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setSubmitLoading(false);
        setCanSubmitModal(false);
      });
  };

  const reopenOrder = () => {
    setReopenDialog(false);
    setReopenLoading(true);
    //function to reopen order
    orderServices
      .submitOrder({
        OrderID: orderID,
        Status: "N",
        UserID: currentUserID,
      })
      .then(() => {
        setOrderStatusInTabs("N");
        setReopenLoading(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setReopenLoading(false);
      });
  };

  const sendOrderNotification = async (e) => {
    //function to send order notification
    if (e) e.preventDefault();
    if (formChanged) {
      if (value === 0) await orderRef.current.save(null, true);
      else await saveQuantitiesFinal(true);
    } else {
      finalizeOrder();
    }
  };

  const fetchCanSubmitUsers = () => {
    //function to fetch users that can subimt order
    accountServices
      .getAllUsersHavingSubmitOrderTrueByCompanyID(companyIDInTabs)
      .then((response) => {
        setCanSubmitUsers(response.data);
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };

  useEffect(() => {
    if (canSubmitModal) fetchCanSubmitUsers();
  }, [canSubmitModal]);

  const getOrderModificationLevvenContactText = () => {
    setLevvenContactLoading(true);
    systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        setLevvenContactText(
          response.data.orderModificationLevvenContacts.split("\n")
        );
        setLevvenContactLoading(false);
        setLevvenContactError(false);
      })
      .catch((error) => {
        setLevvenContactError(true);
        setLevvenContactLoading(false);
        setLevvenContactErrorText(error.response.data.error.error);
      });
  };

  const createTemporaryArrayFromArrayFrom = async () => {
    var tmp = [];

    for (var i = 0; i < arrayFrom.length; i++) {
      tmp.push({
        isDimmer: arrayFrom[i].isDimmer,
        quantity: arrayFrom[i].quantity,
        unmodifiedQuantity: arrayFrom[i].quantity,
        switchAssemblyTypeCode:
          arrayFrom[i].product.switchAssemblyType.switchAssemblyTypeCode,
        controllerTypeCode:
          arrayFrom[i].product.controller.controllerType.controllerTypeCode,
        switchStyle: arrayFrom[i].product.switch.switchStyle.description,
        switchColor: arrayFrom[i].product.switch.switchColor.description,
      });
    }

    return tmp;
  };

  const getSwitchAssemblyType = (code) => {
    if (code === 1) {
      return "Single-pole";
    } else if (code === 2) {
      return "3-way";
    } else if (code === 3) {
      return "4-way";
    } else {
      return "";
    }
  };

  const getControllerType = (code) => {
    if (code === 1) {
      return "On/off";
    } else if (code === 2) {
      return "Dimmer";
    } else if (code === 3) {
      return "Convertible";
    } else {
      return "";
    }
  };

  useEffect(() => {}, [selectedFrom, selectedTo, valueToAdd]);

  const addValuesToRows = () => {
    setSaveQuantitiesLoading(true);
    if (
      selectedFrom !== null ||
      selectedTo !== null ||
      selectedFrom2 !== null ||
      selectedTo2 !== null ||
      (valueToAdd !== null && valueToAdd !== "")
    ) {
      rowsChange.push({
        From: selectedFrom,
        To: selectedTo,
        quantity: valueToAdd,
        FromSwitchStyleColor: selectedFrom2,
        ToSwitchStyleColor: selectedTo2,
      });
      setSelectedTo2(null);
      setSelectedFrom2(null);
      setSelectedFrom(null);
      setSelectedTo(null);
      setValueToAdd("");
    }
  };

  const checkForQuantitiesError = async () => {
    var array = await createTemporaryArrayFromArrayFrom();

    var error = [];
    var errorFound = false;

    for (var i = 0; i < rowsChange.length; i++) {
      if (
        rowsChange[i].From === null ||
        rowsChange[i].FromSwitchStyleColor === null
      ) {
        errorFound = true;
        //var textAftNumber1 = await textAfterNumber(i + 1);
        var stringBuilder1 = "-The Change From field cannot be empty.";
        error.push(stringBuilder1);
      }

      if (
        rowsChange[i].To === null ||
        rowsChange[i].ToSwitchStyleColor === null
      ) {
        errorFound = true;
        //var textAftNumber2 = await textAfterNumber(i + 1);
        var stringBuilder2 = "-The Upgrade To field cannot be empty.";
        error.push(stringBuilder2);
      }

      if (rowsChange[i].quantity === null || rowsChange[i].quantity === "") {
        errorFound = true;
        //var textAftNumber3 = await textAfterNumber(i + 1);
        var stringBuilder3 = "-The quantity to allocate cannot be empty.";
        error.push(stringBuilder3);
      }
    }

    for (i = 0; i < rowsChange.length; i++) {
      for (var j = 0; j < array.length; j++) {
        if (rowsChange[i].From === null) {
          break;
        }

        if (rowsChange[i].To === null) {
          break;
        }
        if (rowsChange[i].FromSwitchStyleColor === null) {
          break;
        }

        if (rowsChange[i].ToSwitchStyleColor === null) {
          break;
        }
        if (rowsChange[i].quantity === null) {
          break;
        }

        if (
          rowsChange[i].From.product.switchAssemblyType
            .switchAssemblyTypeCode === array[j].switchAssemblyTypeCode &&
          rowsChange[i].From.product.controller.controllerType
            .controllerTypeCode === array[j].controllerTypeCode &&
          rowsChange[i].From.isDimmer === array[j].isDimmer
        ) {
          array[j].quantity -= rowsChange[i].quantity;

          break;
        }
      }
    }

    for (var k = 0; k < array.length; k++) {
      if (array[k].quantity < 0) {
        var quantityExceeded = -array[k].quantity;
        var stringBuilder =
          "-You have exceeded the maximum allowed quantity of ";

        stringBuilder += array[k].switchStyle + " " + array[k].switchColor;
        stringBuilder +=
          " " + getSwitchAssemblyType(array[k].switchAssemblyTypeCode);
        stringBuilder += " " + getControllerType(array[k].controllerTypeCode);
        stringBuilder += array[k].isDimmer ? " (at the price of On/off)" : "";
        stringBuilder +=
          " (Quantity Allowed:" +
          array[k].unmodifiedQuantity +
          ", Quantity Entered:" +
          (quantityExceeded + array[k].unmodifiedQuantity) +
          ")";
        error.push(stringBuilder);
        errorFound = true;
      }
    }

    return { errorFound: errorFound, errorArray: error };
  };

  const submitChangeValues = async () => {
    //handles the save of changes in product quantities
    setSaveQuantitiesLoading(true);
    setErrorArray([]);

    var checkError = await checkForQuantitiesError();

    if (checkError.errorFound === true) {
      var tmpErrorArray = checkError.errorArray;
      tmpErrorArray.unshift(
        checkError.errorArray.length === 1
          ? "We encountered an error in the Change A Switch table:"
          : "We encountered several errors in the Change A Switch table:"
      );
      await setErrorArray(tmpErrorArray);
      setErrorOnSave(true);
      setSaveQuantitiesLoading(false);
    } else {
      var success = 0;
      const data = {
        ListOfChanges: rowsChange,
        OrderID: orderID,
        ApplySwitchstyleColorChange: true,
      };

      //to change product quantities
      await orderQuantitiesServices
        .changeProductsOfOrderQuantitiesOfCreatedOrder(data)
        .then(async (response) => {
          await success++;
        })
        .catch((error) => {
          setSaveQuantitiesLoading(false);
          setError(error.response.data.error.error);
        });

      //add Switches
      await orderQuantitiesServices
        .addProductsToOrderQuantities({
          OrderID: orderID,
          Products: addSwitchRows,
        })
        .then(async (response) => {
          await success++;
        })
        .catch((error) => {
          setSaveQuantitiesLoading(false);
          setError(error.response.data.error.error);
        });

      //add Assemblies
      await orderQuantitiesServices
        .addProductsToOrderQuantities({
          OrderID: orderID,
          Products: addAssemblyRows,
        })
        .then(async (response) => {
          await success++;
        })
        .catch((error) => {
          setSaveQuantitiesLoading(false);
          setError(error.response.data.error.error);
        });

      if (success === 3) {
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        setPristine();
        setSuccess(true);
        setMessage("Saved Successfully");
        setSaveQuantitiesLoading(false);

        setChangeQuantities(false);
        setResubmit(!resubmit);
      }
    }
  };

  const saveChangesInQuantities = async () => {
    setSaveQuantitiesLoading(true);
    submitChangeValues();
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveError("");
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  const duplicateOrder = async (id) => {
    setDuplicateLoading(true);

    const data = { OrderID: id, IsRMA: isRMA, CreatedByID: userID };

    await orderServices
      .duplicateOrder(data)
      .then((response) => {
        setDuplicateLoading(false);
        history.push({
          pathname: isRMA === false ? "/order-information" : "/rma-information",
          state: {
            orderID: response.data.orderID,
            orderName: response.data.purchaser.company.name,
            orderStatus: response.data.status,
            purchaserCompanyID: response.data.purchaser.companyID,
            jobName: response.data.jobName,
            jobNum: response.data.jobNum,
            purchaseOrderNbr: response.data.purchaseOrderNbr,
            requestedDate: response.data.requestedDeliveryDate
              ? response.data.requestedDeliveryDate
              : "",
          },
        });

        window.location.reload();
      })
      .catch((error) => {
        setDuplicateLoading(false);
        setError(error.response.data.error.error);
      });
  };

  /*Handle closing snackBar*/
  const handleDeliveryDateSnaclbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDeliveryDateSnackbar(false);
  };

  const updatedRequestedDate = async () => {
    setDateChangeLoading(true);
    if (changedDate === null || changedDate === "") {
      setDateChangeLoading(false);
      setChangedDateError(true);
    } else {
      const requested = new Date(changedDate);
      const minmumRequestedDeliveryDate = new Date(
        Date.now() + minRequestedDeliveryDate
      );
      requested.setHours(0, 0, 0, 0);
      minmumRequestedDeliveryDate.setHours(0, 0, 0, 0);

      if (requested < minmumRequestedDeliveryDate) {
        setDateChangeLoading(false);
        setChangedDateError(true);
      } else {
        await orderServices
          .updateOrder({
            OrderID: orderID,
            RequestedDeliveryDate:
              changedDate !== null &&
              changedDate !== "" &&
              changedDate !== "Invalid Date Format"
                ? moment(new Date(changedDate)).format("MM/DD/YYYY")
                : null,
          })
          .then((response) => {
            setOpenChangeDateSubmit(false);
            setChangedDateError(false);
            setChangedDate(null);
            setOpenChangeDateSubmit(false);
            setDateChangeLoading(false);
            setOpenDeliveryDateSnackbar(true);
            setRefetchOrderData((orderData) => !orderData);
          })
          .catch((error) => {
            setDateChangeLoading(false);
            setError(error.response.data.error.error);
          });
      }
    }
  };

  function sendDraftOrderNotification(
    sendNow = false,
    sendin24 = false,
    receiver = null
  ) {
    if (sendNow) {
      setLoadingSendNow(true);
    } else if (sendin24) {
      setLoadingSend24(true);
    }

    orderServices
      .draftOrderEmaiSent({
        OrderID: orderInTabs?.orderID,

        NotificationType: "SavedOrderDraftReadyToOrder",

        SendNow: sendNow,

        SendIn24h: sendin24,

        ReceiverID: receiver?.userID,
      })
      .then((response) => {
        setOrderInTabs(response.data);
        console.log("order from response data", receiver);
        if (sendNow) {
          setLoadingSendNow(false);
        } else if (sendin24) {
          setLoadingSend24(false);
        }
        setDisplayOrderSaveDraftNotif(false);
        // setReceiver(receiver)
        if (sendNow || sendin24) {
          setAlertNotification(true);
        }
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        if (sendNow) {
          setLoadingSendNow(false);
        } else if (sendin24) {
          setLoadingSend24(false);
        }
      });
  }
  console.log(receiver);
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {/* {Prompt} */}
      {saveError !== "" ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
      {alertNotification !== false ? (
        <AlertDialogSlide
          title={"Send Notification"}
          textContent={`A notification for draft Order ID ${orderID} has been queued for ${receiver?.firstName} ${receiver?.lastName} <${receiver?.userName}>.`}
          saveButtonLoading={submitLoading}
          disableUnsave={submitLoading}
          save={() => setAlertNotification(false)}
          saveText="Ok"
        />
      ) : null}
      {displayAddressVerificationWarning !== false ? (
        <AlertDialogSlide
          title={"Address Not Verified"}
          textContent={
            "The address entered is not verified. Are you sure you wish to continue?"
          }
          saveButtonLoading={submitLoading}
          disableUnsave={submitLoading}
          save={() => submitOrderAfterReview()}
          saveText="Ok"
          unsaveText="Cancel"
          unsave={() => setDisplayAddressVerificationWarning(false)}
        />
      ) : null}
      {fillQtyBeforeSubmit !== false ? (
        <AlertDialogSlide
          title={isRMA === false ? "Bulk Inventory Order" : "RMA"}
          textContent={`Select the products you need and enter a quantity. Provide a ${
            isRMA === false
              ? "PO number and request date"
              : "Service Case/Ticket Number and Delivery Date"
          } before submitting your order to Levven.`}
          save={() => setFillQtyBeforeSubmit(false)}
          saveText="Ok"
        />
      ) : null}
      {openLevvenContactsModal ? (
        <>
          <CustomModal openModal={openLevvenContactsModal} width="700px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "80vh",
                padding: "20px",
                justifyContent: "center",
              }}
            >
              {levvenContactLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      textAlign: "center",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                </Grid>
              ) : (
                <>
                  {levvenContactError ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "red",
                          marginTop: "50px",
                          marginBottom: "30px",
                        }}
                      >
                        <CustomAlert>{levvenContactErrorText}</CustomAlert>
                      </div>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body2" component="span">
                          {levvenContactText.map((paragraph) => {
                            return (
                              <div>
                                <p>{paragraph}</p>
                                <div style={{ height: "10px" }} />
                              </div>
                            );
                          })}
                        </Typography>
                      </Grid>

                      {orderInTabs &&
                      orderInTabs !== null &&
                      openEmailSection ? (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Paper
                            elevation={0}
                            style={{
                              border: "1px solid #c4c4c4",
                              padding: "20px",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  variant="body2"
                                  component="span"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  <strong>Subject:</strong> Change request to
                                  submitted{" "}
                                  {isRMA === false
                                    ? "order"
                                    : "Service Case/Ticket Number"}
                                  :{" "}
                                  {orderInTabs.purchaser.company.name +
                                    " - " +
                                    orderInTabs.purchaseOrderNbr}{" "}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  variant="body2"
                                  component="span"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  <strong>Customer:</strong>{" "}
                                  {orderInTabs.purchaser.company.name} {"\n"}
                                  <strong>Username:</strong> {currentUsername}{" "}
                                  {"\n"}
                                  <strong>
                                    {isRMA === false
                                      ? "Custom PO:"
                                      : "Service Case/Ticket Number:"}
                                  </strong>{" "}
                                  {orderInTabs.purchaseOrderNbr} {"\n"}
                                  <strong>Sales Order No.:</strong>{" "}
                                  {orderInTabs.navNo} {"\n"}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  label="Change Request"
                                  required
                                  fullWidth
                                  multiline
                                  value={emailBody}
                                  error={emailBodyError}
                                  helperText={
                                    emailBodyError
                                      ? "Email Body field is empty"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setEmailBody(e.target.value);
                                    if (emailBodyError)
                                      setEmailBodyError(false);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography
                                  variant="body2"
                                  component="span"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  Thank you, {"\n"}
                                  {currentUserFirstname +
                                    " " +
                                    currentUserLastname}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ paddingTop: "25px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <CustomButton
                            toggle={sendEmail}
                            loading={emailButtonLoading}
                          >
                            Send Change Request
                          </CustomButton>

                          <div style={{ width: "10px" }} />
                          <CustomButton
                            toggle={() => {
                              setOpenLevvenContactsModal(false);
                              setLevvenContactError(false);
                              setLevvenContactText("");
                              setOpenEmailSection(false);
                            }}
                          >
                            Cancel
                          </CustomButton>
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}

      {errorOnSave ? (
        <>
          <CustomModal openModal={errorOnSave} width="600px">
            <Typography variant="h6" component="span" align="left">
              {" "}
              {errorArray[0]}
            </Typography>
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                paddingTop: "20px",

                // justifyContent: "center",
                textAlign: "left",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <p>
                  {errorArray.map((paragraph, index) => {
                    return index !== 0 ? (
                      <div>
                        <p>{paragraph}</p>
                        <div style={{ height: "10px" }} />
                      </div>
                    ) : null;
                  })}
                </p>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "25px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setErrorArray([]);
                      setErrorOnSave(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}

      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />
      <SnackBar
        open={openDeliveryDateSnackbar}
        message={
          isRMA === false
            ? "Requested Delivery Date Updated Successfully"
            : "Requested Date Updated Successfully"
        }
        severity="success"
        handleClose={handleDeliveryDateSnaclbarClose}
      />
      <SnackBar
        open={deletedSuccessfully}
        message={"Note Deleted Successfully"}
        severity="success"
        handleClose={() => {
          setDeletedSuccessfully(false);
        }}
      />
      <SnackBar
        open={submitSnackBarOpen}
        message={
          isRMA === false
            ? "Order Submitted Successfully"
            : "RMA Submitted Successfully"
        }
        severity="success"
        handleClose={() => {
          setSubmitSnackBarOpen(false);
        }}
      />

      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message={message !== "" ? message : "Saved Successfully"}
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}

      {reopenDialog ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            isRMA === false
              ? "Opening the order might cause the prices to change. Are you sure you wish to proceed?"
              : "Opening the RMA might cause the prices to change. Are you sure you wish to proceed?"
          }
          save={reopenOrder}
          saveText="Yes"
          unsave={() => setReopenDialog(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteRecords}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}

      {editOrderError ? (
        <AlertDialogSlide
          title="Error"
          textContent={editOrderError}
          save={() => {
            setEditOrderError("");
          }}
          saveText="Close"
        />
      ) : (
        ""
      )}

      {showWarning ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "The number of Quick Mount switches you entered is not equal to the number of plate positions. Do you wish to continue?"
          }
          save={() => saveQuantitiesFinal(false)}
          saveText="Yes"
          unsave={() => setShowWarning(false)}
          unsaveText="No"
          saveButtonLoading={buttonLoading}
        />
      ) : null}
      {showWarning2 ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "The number of Quick Mount switches you entered is not equal to the number of plate positions. Do you wish to continue?"
          }
          save={() => saveQuantitiesFinal(true)}
          saveText="Yes"
          unsave={() => setShowWarning2(false)}
          unsaveText="No"
          saveButtonLoading={buttonLoading}
        />
      ) : null}
      {showWarning3 ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "The number of Quick Mount switches you entered is not equal to the number of plate positions. Do you wish to continue?"
          }
          save={() => {
            setShowWarning3(false);
            submitOrderPermission
              ? setCanSubmitAlert(true)
              : setCanSubmitModal(true);
          }}
          saveText="Yes"
          unsave={() => setShowWarning3(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {displayOrderSaveDraftNotif && (
        <NotificationOrderAlert
          open={displayOrderSaveDraftNotif}
          button1Function={() => {
            sendDraftOrderNotification(true, false, receiver);
          }}
          button2Function={() => {
            sendDraftOrderNotification(false, true, receiver);
          }}
          button3Function={() => {
            setDisplayOrderSaveDraftNotif(false);
            setReceiver(null);
          }}
          allowButton3Enable
          button1Loading={loadingSendNow}
          button1Disable={loadingSend24}
          button2Loading={loadingSend24}
          button2Disable={loadingSendNow}
          button3Disable={loadingSendNow || loadingSend24}
          setError={setSaveError}
          user={receiver}
          setUser={setReceiver}
          companyID={orderInTabs?.purchaser?.companyID}
        />
      )}
      <Paper>
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{ marginRight: "20px" }}
            >
              {fromDashboard ? (
                <Link
                  underline="always"
                  key="0"
                  color="inherit"
                  onClick={() =>
                    history.push({
                      pathname: "/Dashboard",
                    })
                  }
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Dashboard
                </Link>
              ) : null}
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() =>
                  history.push({
                    pathname: isRMA === false ? "/Orders" : "/RMA",
                  })
                }
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                {isRMA === false ? "Orders" : "RMAs"}
              </Link>

              {value !== 0 ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    setValue(0);
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {jobNumInTabs}
                </Link>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {jobNumInTabs}
                </Typography>
              )}
              {value === 1 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {isRMA === false ? "Order Quantities" : "RMA Quantities"}
                </Typography>
              ) : (
                ""
              )}
              {value === 2 && createNote === false ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Notes
                </Typography>
              ) : value === 2 && createNote === true ? (
                <Link
                  underline="always"
                  key="3"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();

                      setFormChanged(false);
                      setCreateNote(false);
                    } else {
                      setCreateNote(false);
                    }
                  }}
                  sx={{
                    fontSize: "24px",
                    color: "#0077c6",
                    cursor: "pointer",
                  }}
                >
                  Notes
                </Link>
              ) : (
                ""
              )}
              {value === 2 && createNote === true ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  New Note
                </Typography>
              ) : null}
              {value === 3 ? (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Status
                </Typography>
              ) : null}
              {value === 4 ? (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Invoice
                </Typography>
              ) : null}
            </Breadcrumbs>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {rolePermission === "M" &&
              (value === 0 || value === 2 || value === 3) ? (
                <>
                  {orderStatusInTabs === "F" && submitOrderPermission ? (
                    <>
                      <CustomButton
                        startIcon={<BookOpen />}
                        loading={reopenLoading}
                        toggle={() => setReopenDialog(true)}
                      >
                        Reopen
                      </CustomButton>
                      <div style={{ width: "10px" }} />
                    </>
                  ) : null}

                  {orderStatusInTabs === "N" ||
                  orderStatusInTabs === "D" ||
                  orderStatusInTabs === "F" ? (
                    <>
                      <>
                        <CustomButton
                          startIcon={<Send />}
                          loading={submitLoading}
                          toggle={() => {
                            if (value === 0) {
                              orderRef.current.checkBeforeFinalAndSubmit(
                                submitOrderPermission ? true : false
                              );
                            }
                            if (value === 2 || value === 3) {
                              if (
                                purchaseOrderNumberInTabs === "" ||
                                requestedDateInTabs === "" ||
                                requestedDateInTabs === "Invalid Date Format" ||
                                requestedDateInTabs === null
                              ) {
                                setSubmissionWarningOpen(true);
                                return;
                              }
                              submitOrderPermission
                                ? setCanSubmitAlert(true)
                                : setCanSubmitModal(true);
                            }
                          }}
                        >
                          {submitOrderPermission
                            ? isRMA === false
                              ? "Place Order"
                              : "Place RMA"
                            : "Send to Purchasing for Approval"}
                        </CustomButton>
                        {orderStatusInTabs !== "F" && value === 0 ? (
                          <>
                            <div style={{ width: "10px" }} />
                            <CustomButton
                              startIcon={<Save />}
                              loading={tabButtonLoading}
                              toggle={() => {
                                orderRef.current.checkRequestedDate();
                              }}
                            >
                              Save
                            </CustomButton>
                          </>
                        ) : null}
                      </>
                    </>
                  ) : null}
                </>
              ) : null}

              {getError === "" &&
              rolePermission === "M" &&
              (orderStatusInTabs === "T" || orderStatusInTabs === "I") ? (
                <>
                  <CustomButton
                    startIcon={<Phone />}
                    toggle={() => {
                      setOpenLevvenContactsModal(true);
                      getOrderModificationLevvenContactText();
                    }}
                  >
                    {isRMA === false ? "Edit Order" : "Edit RMA"}
                  </CustomButton>
                </>
              ) : null}

              {getError === "" &&
              rolePermission === "M" &&
              orderStatusInTabs === "I" ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Edit />}
                    toggle={() => {
                      const tmpDate = new Date(
                        Date.now() + minRequestedDeliveryDate
                      );
                      setChangeRequestedDateMinDate(tmpDate);
                      setChangedDate(requestedDateInTabs);
                      setOpenChangeDateSubmit(true);
                    }}
                  >
                    {isRMA === false
                      ? "Change Requested Delivery Date"
                      : "Change Delivery Date"}
                  </CustomButton>
                </>
              ) : null}
              {value === 1 &&
              rolePermission === "M" &&
              !getError &&
              buttonDisplayArray.indexOf(orderStatusInTabs) !== -1 ? (
                <>
                  {" "}
                  <CustomButton
                    startIcon={<Send />}
                    loading={submitLoading}
                    toggle={async () => {
                      if (
                        purchaseOrderNumberInTabs === "" ||
                        requestedDateInTabs === "" ||
                        requestedDateInTabs === "Invalid Date Format" ||
                        requestedDateInTabs === null
                      ) {
                        setSubmissionWarningOpen(true);
                        return;
                      } else {
                        if (
                          totalQuickMount !== totalWallPlates &&
                          isRMA === false &&
                          companyTypeForWarningCheck !== "R" &&
                          orderType === "P"
                        ) {
                          setShowWarning3(true);
                          return;
                        }
                        submitOrderPermission
                          ? setCanSubmitAlert(true)
                          : setCanSubmitModal(true);
                      }
                    }}
                  >
                    {submitOrderPermission
                      ? isRMA === false
                        ? "Place Order"
                        : "Place RMA"
                      : "Send to Purchasing for Approval"}
                  </CustomButton>
                  {orderStatusInTabs !== "F" ? (
                    <>
                      {" "}
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Save />}
                        loading={buttonLoading}
                        toggle={() => {
                          saveQuantities(false);
                        }}
                      >
                        Save
                      </CustomButton>
                    </>
                  ) : null}
                </>
              ) : null}

              {rolePermission === "M" &&
                orderInTabs &&
                orderInTabs.notifEmailSent === false &&
                (orderStatusInTabs === "N" || orderStatusInTabs === "D") && (
                  <>
                    <div style={{ width: "10px" }} />
                    <CustomButton
                      startIcon={<ForwardToInbox />}
                      toggle={() => setDisplayOrderSaveDraftNotif(true)}
                    >
                      Send Notification
                    </CustomButton>
                  </>
                )}
              {rolePermission === "M" ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Copy />}
                    loading={duplicateLoading}
                    toggle={() => duplicateOrder(orderID)}
                  >
                    Duplicate
                  </CustomButton>
                </>
              ) : (
                ""
              )}
              {(orderStatusInTabs === "N" || orderStatusInTabs === "D") &&
              rolePermission === "M" ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Trash2 />}
                    loading={loading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                </>
              ) : null}
              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  await setFormChanged(false);
                  await sessionStorage.removeItem("unsavedChanges");

                  await setPristine();
                  await history.push(isRMA === false ? "/orders" : "/RMA");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Setup" {...a11yProps(0)} style={{ margin: "50" }} />
              <Tab
                label={isRMA === false ? "Order Quantities" : "RMA Quantities"}
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />
              <Tab label="Notes" {...a11yProps(2)} style={{ margin: "50" }} />
              <Tab label="Status" {...a11yProps(3)} style={{ margin: "50" }} />
              {orderStatusInTabs === "V" && canViewInv ? (
                <Tab
                  label="Invoice"
                  {...a11yProps(4)}
                  style={{ margin: "50" }}
                />
              ) : null}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <OrderForm
              isRMA={isRMA}
              receivedOrderID={orderID}
              setCompanyTypeForWarningCheck={setCompanyTypeForWarningCheck}
              adminManage={
                rolePermission === "M"
                  ? orderStatusInTabs !== "N" && orderStatusInTabs !== "D"
                    ? false
                    : true
                  : false
              }
              refetchOrderData={refetchOrderData}
              highlightPO={highlightPONumber}
              setHighlightPO={setHighlightPONumber}
              highlightDate={highlightRequestedDate}
              setHighlightDate={setHighlightRequestedDate}
              orderFulfillmentThreshold={orderFulfillmentThreshold}
              setOpenSnackBar={setOpenSnackBar}
              ref={orderRef}
              openDrawerMenu={openDrawerMenu}
              setFormChanged={setFormChanged}
              totalOrderPrice={totalOrderPrice}
              setOrderStatusInTabs={setOrderStatusInTabs}
              orderStatusInTabs={orderStatusInTabs}
              setTabButtonLoading={setTabButtonLoading}
              windowWidth={windowWidth}
              addressVerificationLevel={addressVerificationLevel}
              setAddressVerificationLevel={setAddressVerificationLevel}
              setCanSubmitAlert={setCanSubmitAlert}
              setCanSubmitModal={setCanSubmitModal}
              setJobNumInTabs={setJobNumInTabs}
              setOrderInTabs={setOrderInTabs}
              orderType={orderType}
              setOrderType={setOrderType}
              setRequestedDateInTabs={setRequestedDateInTabs}
              setDateAlertDialogOpen={setDateAlertDialogOpen}
              setPurchaseOrderNumberInTabs={setPurchaseOrderNumberInTabs}
              submitOrder={submitOrder}
              loading={loading}
              minRequestedDeliveryDate={minRequestedDeliveryDate}
              setDeleteRecord={setDeleteRecord}
              finalizeOrder={finalizeOrder}
              submitOrderPermission={submitOrderPermission}
              rolePermission={rolePermission}
              reopenLoading={reopenLoading}
              setReopenDialog={setReopenDialog}
              submitLoading={submitLoading}
              getError={getError}
              setOpenLevvenContactsModal={setOpenLevvenContactsModal}
              getOrderModificationLevvenContactText={
                getOrderModificationLevvenContactText
              }
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {pageLoad === false && !getError ? (
              <div className={classes.buttonStyle3}>
                {(orderStatusInTabs === "N" || orderStatusInTabs === "D") &&
                rolePermission === "M" &&
                orderType === "P" &&
                isRMA === false ? (
                  <>
                    <CustomButton
                      startIcon={<Edit />}
                      toggle={async () => {
                        await getProductsToChangeFrom(orderID);
                      }}
                    >
                      Changes/Additions
                    </CustomButton>
                    <div className={classes.divStyle3} />
                  </>
                ) : null}

                <CustomButton
                  startIcon={<ChevronsDown />}
                  toggle={() => {
                    var tmp = [...opened];
                    setOpenedAccordion(tmp);
                  }}
                >
                  View Quantities
                </CustomButton>
                <div className={classes.divStyle3} />
                <CustomButton
                  startIcon={<ChevronUp />}
                  toggle={() => setOpenedAccordion([])}
                >
                  Collapse All
                </CustomButton>
              </div>
            ) : null}
            {!getError && pageLoad === false ? (
              <Grid container spacing={2} style={{ marginBottom: "30px" }}>
                <Grid item sm={12} md={6} lg={4}>
                  <Typography>
                    Total Number of Switches: {totalSwitches}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <Typography>
                    Total Number of Switch Plate Positions: {totalWallPlates}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <Typography>
                    Total Number of Quick Mount Switches: {totalQuickMount}
                  </Typography>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                  <Typography>
                    Total Price of {isRMA === false ? "Order" : "RMA"}:{" "}
                    {currency === "CAD" ? "C$" : "$"}{" "}
                    {parseFloat(
                      Math.round(totalOrderPrice * 100) / 100
                    ).toFixed(2)}{" "}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}

            <ProductQuantitiesAccordion
              isRMA={isRMA}
              defControllerQty={defControllerQty}
              setDefControllerQty={setDefControllerQty}
              priceResetLoading={priceResetLoading}
              setPriceResetLoading={setPriceResetLoading}
              isCompPP={orderIsCompPP}
              isReseller={companyTypeForWarningCheck === "R"}
              isLevvenAdmin={userRole === "Levven Admin"}
              steps={steps}
              rows={rows}
              setRows={setRows}
              pageLoad={pageLoad}
              changeMade={changeMade}
              setChangeMade={setChangeMade}
              rolePermission={rolePermission}
              setPageLoad={setPageLoad}
              error={getError}
              totalOrderPrice={totalOrderPrice}
              orderStatusInTabs={orderStatusInTabs}
              setTotalOrderPrice={setTotalOrderPrice}
              defaultController={defaultController}
              windowWidth={windowWidth}
              openDrawerMenu={openDrawerMenu}
              setGetError={setGetError}
              specialNegotiated={specialNegotiated}
              defaultPriceRanges={defaultPriceRanges}
              defUPC={defUPC}
              getPriceByRange={getDefaultUPCByRange}
              setDirty={setDirty}
              currency={currency}
              saveQuantities={saveQuantities}
              orderType={orderType}
              totalSwitches={totalSwitches}
              setTotalSwitches={setTotalSwitches}
              totalWallPlates={totalWallPlates}
              setPristine={setPristine}
              buttonLoading={buttonLoading}
              setTotalWallPlates={setTotalWallPlates}
              totalQuickMount={totalQuickMount}
              opened={opened}
              setOpened={setOpened}
              openedAccordion={openedAccordion}
              setOpenedAccordion={setOpenedAccordion}
              setTotalQuickMount={setTotalQuickMount}
              setFormChanged={setFormChanged}
              orderID={orderID}
              maxDimmer={maxDimmer}
              orderVersion={orderVersion}
              setDeleteRecord={setDeleteRecord}
              getError={getError}
              submitLoading={submitLoading}
              purchaseOrderNumberInTabs={purchaseOrderNumberInTabs}
              requestedDateInTabs={requestedDateInTabs}
              setSubmissionWarningOpen={setSubmissionWarningOpen}
              setShowWarning3={setShowWarning3}
              submitOrderPermission={submitOrderPermission}
              setCanSubmitAlert={setCanSubmitAlert}
              setCanSubmitModal={setCanSubmitModal}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            {!createNote ? (
              <>
                <OrderNotesTableComponent
                  isRMA={isRMA}
                  setCreateNote={setCreateNote}
                  orderID={orderID}
                  orderName={orderName}
                  value={value}
                  orderStatus={orderStatusInTabs}
                />
              </>
            ) : (
              <OrderNotesForm
                isRMA={isRMA}
                create={true}
                setFormChanged={setFormChanged}
                orderID={orderID}
                setSaved={setSaved}
                setCreateOrderNote={setCreateNote}
                setOpenSnackBar={setOpenSnackBar}
                setCreatedNote={setCreateNote}
                saveOrderNote={saveOrderNote}
                setSaveOrderNote={setSaveOrderNote}
                setValue={setValue}
                setWarning={setSubmissionWarningOpen}
                setHighlightDate={setHighlightRequestedDate}
                setHighlightPO={setHighlightPONumber}
                dateInTabs={requestedDateInTabs}
                poInTabs={purchaseOrderNumberInTabs}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <OrderStatus isRMA={isRMA} receivedOrderID={orderID} />
          </TabPanel>

          {orderStatusInTabs === "V" && canViewInv ? (
            <TabPanel value={value} index={4}>
              <>
                {invoicePageLoad ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "30px" }}
                    >
                      <Grid item sm={12} md={6} lg={6}>
                        <Typography>
                          Invoice Number: {orderInTabs?.invoiceNo}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={0}
                        md={3}
                        lg={3}
                        sx={{
                          display: "flex",
                          justifyContent: {
                            md: "space-between",
                            lg: "space-between",
                            sm: "inherit",
                          },
                        }}
                      >
                        <Typography>Invoice Total: </Typography>
                        <Typography>
                          {" "}
                          &nbsp;{parseFloat(sumOfTotalInvoice).toFixed(2)}
                          {currencyInvoice === "CAD" ? "C$" : "$"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        lg={3}
                        sx={{
                          display: { xs: "none", md: "inherit", lg: "inherit" },
                        }}
                      />
                      <Grid item sm={12} md={6} lg={6}>
                        <Typography>
                          Invoice Date:{" "}
                          {orderInTabs
                            ? convertUTC(orderInTabs.invoiceDate)
                            : null}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        md={3}
                        lg={3}
                        sx={{
                          display: "flex",
                          justifyContent: {
                            md: "space-between",
                            lg: "space-between",
                            sm: "inherit",
                          },
                        }}
                      >
                        <Typography>Total Tax:</Typography>
                        <Typography>
                          {" "}
                          &nbsp;{parseFloat(taxValue).toFixed(2)}{" "}
                          {currencyInvoice === "CAD" ? "C$" : "$"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        lg={3}
                        sx={{
                          display: { xs: "none", md: "inherit", lg: "inherit" },
                        }}
                      />
                      <Grid
                        item
                        md={6}
                        lg={6}
                        sx={{
                          display: { sm: "none", md: "inherit", lg: "inherit" },
                        }}
                      />
                      <Grid
                        item
                        sm={12}
                        md={3}
                        lg={3}
                        sx={{
                          display: "flex",
                          justifyContent: {
                            md: "space-between",
                            lg: "space-between",
                            sm: "inherit",
                          },
                          whiteSpace: "noWrap",
                        }}
                      >
                        <Typography>Total Including Tax: </Typography>{" "}
                        <Typography>
                          {" "}
                          &nbsp;
                          {parseFloat(sumOfTotalInvoiceAfterTax).toFixed(
                            2
                          )}{" "}
                          {currencyInvoice === "CAD" ? "C$" : "$"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        lg={3}
                        sx={{
                          display: { xs: "none", md: "inherit", lg: "inherit" },
                        }}
                      />
                    </Grid>
                    <InvoiceLinesTable
                      isRMA={isRMA}
                      rows={invoiceRows}
                      numberRecords={numberInvoiceLines}
                      page={page}
                      currency={currencyInvoice === "CAD" ? "C$" : "$"}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </>
                )}
              </>
            </TabPanel>
          ) : null}
        </Box>
      </Paper>
      {canSubmitModal && !submitOrderPermission ? (
        <CustomModal openModal={canSubmitModal && !submitOrderPermission}>
          <form onSubmit={sendOrderNotification}>
            <Typography variant="h6" component="span" align="left">
              Send to Purchasing for Approval
            </Typography>
            <br />
            <br />
            <Autocomplete
              id="combo-box-demo"
              componentName="Field1"
              options={canSubmitUsers}
              value={chosenCanSubmitUser ? chosenCanSubmitUser : ""}
              getOptionLabel={(option) =>
                option.userName ? option.userName : ""
              }
              onChange={(event, value) => {
                setChosenCanSubmitUser(value);
                if (chosenCanSubmitUserError)
                  setChosenCanSubmitUserError(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Field1"
                  label="Send Email to"
                  fullWidth
                  size="small"
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  error={chosenCanSubmitUserError}
                  helperText={
                    chosenCanSubmitUserError
                      ? "Send Email to field is empty"
                      : ""
                  }
                />
              )}
            />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                toggle={() => {
                  setCanSubmitModal(false);
                }}
              >
                Cancel
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton
                type="submit"
                loading={buttonLoading || submitLoading}
              >
                {formChanged ? "Save & Send" : "Send"}
              </CustomButton>
            </div>
          </form>
        </CustomModal>
      ) : null}
      {canSubmitAlert && submitOrderPermission ? (
        <AlertDialogSlide
          title="Warning"
          textContent={`Submitted ${
            isRMA === false ? "orders" : "RMAs"
          } can't be modified. Are you sure you want to proceed?`}
          save={async () => {
            if (formChanged) {
              if (value === 0) await orderRef.current.save(null, true);
              else await saveQuantitiesFinal(true);
            } else {
              submitOrder();
            }
          }}
          saveText={formChanged ? "Save & Submit" : "Submit"}
          unsave={() => setCanSubmitAlert(false)}
          unsaveText="Cancel"
          saveButtonLoading={tabButtonLoading || submitLoading}
        />
      ) : null}
      {submissionWarningOpen ? (
        <AlertDialogSlide
          title="Warning"
          textContent={`Please make sure that the ${
            isRMA === false
              ? "purchase order number and the requested date"
              : "Service Case/Ticket Number and the delivery date"
          } are filled in the setup tab.`}
          save={async () => {
            if (formChanged && value === 1) {
              await saveQuantities(true);
            } else if (formChanged === true && value === 2) {
              setSaveOrderNote(true);
            } else {
              if (requestedDateInTabs === "" || requestedDateInTabs === null) {
                setHighlightRequestedDate(true);
              }
              if (
                purchaseOrderNumberInTabs === "" ||
                purchaseOrderNumberInTabs === null
              ) {
                setHighlightPONumber(true);
              }
              setSubmissionWarningOpen(false);
              setValue(0);
            }
          }}
          saveText={formChanged ? "Save & Go to Setup" : "Go to Setup"}
          unsave={() => setSubmissionWarningOpen(false)}
          unsaveText="Cancel"
          saveButtonLoading={buttonLoading}
        />
      ) : null}
      {dateAlertDialogOpen ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            <>
              <Typography
                variant="body1"
                component="span"
                style={{ whiteSpace: "pre-line" }}
              >
                The delivery date you are requesting is less than{" "}
                {orderFulfillmentThreshold === 1
                  ? orderFulfillmentThreshold + "day"
                  : orderFulfillmentThreshold + "days"}{" "}
                from today.{"\n"}Do you wish to continue?
              </Typography>
            </>
          }
          save={() => {
            setDateAlertDialogOpen(false);
          }}
          saveText="Edit Date"
          // unsave={() => {
          //   setDateAlertDialogOpen(false);
          //   submitOrderPermission
          //     ? setCanSubmitAlert(true)
          //     : setCanSubmitModal(true);
          // }}
          // unsaveText="Continue"
          // unsaveLoading={loading}
        />
      ) : null}

      {changeQuantities ? (
        <>
          <CustomModal
            openModal={changeQuantities}
            width="75vw"
            overflow="hidden"
          >
            <Grid
              container
              spacing={3}
              style={{
                // overflowX: "auto",
                // maxHeight: "80vh",
                padding: "20px",
                justifyContent: "center",
              }}
            >
              {changeLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      textAlign: "center",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                </Grid>
              ) : (
                <>
                  {changeError ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          {" "}
                          <div
                            style={{
                              textAlign: "center",
                              color: "red",
                              marginTop: "50px",
                              marginBottom: "30px",
                            }}
                          >
                            <CustomAlert>{changeError}</CustomAlert>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <CustomButton
                            toggle={() => setChangeQuantities(false)}
                          >
                            Close
                          </CustomButton>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{
                          overflow: "auto",
                          maxHeight: "600px",
                          paddingRight: "30px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Accordion
                              key={"Make Changes"}
                              elevation={0}
                              defaultExpanded
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"Make Changes-content"}
                                id={"Make Changes-id"}
                                style={{ flexDirection: "row-reverse" }}
                              >
                                <Typography
                                  variant="h6"
                                  component="div"
                                  style={{ paddingLeft: "40px" }}
                                >
                                  Change or upgrade switches from what was
                                  specified in the base plan:
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  marginTop: "-10px",
                                  marginBottom: "-10px",
                                  marginLeft: "-16px",
                                  marginRight: "-16px",
                                }}
                              >
                                <ChangeProductsTable
                                  arrayFrom={arrayFrom}
                                  arrayTo={arrayTo}
                                  selectedFrom={selectedFrom}
                                  agreementDefaultSwitch={
                                    agreementDefaultSwitch
                                  }
                                  setDirty={setDirty}
                                  selectedTo={selectedTo}
                                  setSelectedFrom={setSelectedFrom}
                                  setSelectedTo={setSelectedTo}
                                  valueToAdd={valueToAdd}
                                  setValueToAdd={setValueToAdd}
                                  maxQuantity={maxQuantity}
                                  rows={rowsChange}
                                  setRows={setRowsChange}
                                  fromTabs={true}
                                  setFormChanged={setFormChanged}
                                  selectedFrom2={selectedFrom2}
                                  selectedTo2={selectedTo2}
                                  setSelectedFrom2={setSelectedFrom2}
                                  setSelectedTo2={setSelectedTo2}
                                  defaultSwitch={
                                    arrayFrom2.length > 0 ? arrayFrom2[0] : null
                                  }
                                  arrayFrom2={arrayFrom2}
                                  arrayTo2={arrayTo2}
                                />
                              </AccordionDetails>
                            </Accordion>
                            <Accordion key={"Add Switches"} elevation={0}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"Add Switches-content"}
                                id={"Add Switches-id"}
                                style={{ flexDirection: "row-reverse" }}
                              >
                                <Typography
                                  variant="h6"
                                  component="div"
                                  style={{ paddingLeft: "40px" }}
                                >
                                  Add extra switch kits not included in the base
                                  plan:
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  marginTop: "-10px",
                                  marginBottom: "-10px",
                                  marginLeft: "-16px",
                                  marginRight: "-16px",
                                }}
                              >
                                <AddProductsTable
                                  rows={addAssemblyRows}
                                  setAddRows={setAddAssemblyRows}
                                  setDirty={setDirty}
                                  changed={changed}
                                  setFormChanged={setFormChanged}
                                  setChanged={setChanged}
                                />
                              </AccordionDetails>
                            </Accordion>
                            <Accordion key={"Add Assemblies"} elevation={0}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"Add Assemblies-content"}
                                id={"Add Assemblies-id"}
                                style={{ flexDirection: "row-reverse" }}
                              >
                                <Typography
                                  variant="h6"
                                  component="div"
                                  style={{ paddingLeft: "40px" }}
                                >
                                  Add extra switches not included in the base
                                  plan:
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  marginTop: "-10px",
                                  marginBottom: "-10px",
                                  marginLeft: "-16px",
                                  marginRight: "-16px",
                                }}
                              >
                                <AddProductsTable
                                  rows={addSwitchRows}
                                  setAddRows={setAddSwitchRows}
                                  setDirty={setDirty}
                                  changed={changed}
                                  setFormChanged={setFormChanged}
                                  setChanged={setChanged}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <CustomButton
                            disable={saveQuantitiesLoading}
                            toggle={() => {
                              if (formChanged) {
                                handleConfirm();
                                setChangeQuantities(false);
                                setArrayFrom([]);
                                setArrayTo([]);
                                setSelectedFrom(null);
                                setSelectedFrom2([]);
                                setSelectedTo2([]);
                                setArrayFrom2([]);
                                setArrayTo2([]);
                                setSelectedTo(null);
                                setValueToAdd(null);
                                setMaxQuantity(0);
                                setRowsChange([]);
                                setFormChanged(false);
                              } else {
                                setChangeQuantities(false);
                                setArrayFrom([]);
                                setArrayTo([]);
                                setSelectedFrom2([]);
                                setSelectedTo2([]);
                                setArrayFrom2([]);
                                setArrayTo2([]);
                                setSelectedFrom(null);
                                setSelectedTo(null);
                                setValueToAdd(null);
                                setMaxQuantity(0);
                                setRowsChange([]);
                              }
                            }}
                          >
                            Cancel
                          </CustomButton>
                          <div style={{ width: "20px" }} />
                          <CustomButton
                            loading={saveQuantitiesLoading}
                            startIcon={<Save />}
                            toggle={async () => {
                              // setPageLoad(true);
                              setSaveQuantitiesLoading(true);
                              await addValuesToRows();
                              await saveChangesInQuantities();
                            }}
                          >
                            Save
                          </CustomButton>
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}

      {openChangeDateSubmit ? (
        <>
          <CustomModal openModal={openChangeDateSubmit} width="50vw">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "20px",
                justifyContent: "center",
                // textAlign: "justify",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h4" component="span">
                  {isRMA === false
                    ? "Change Requested Delivery Date"
                    : "Change Delivery Date"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomDatePickerComponent
                  label={
                    isRMA === false
                      ? "Requested Delivery Date"
                      : "Delivery Date"
                  }
                  helperText={
                    "The earliest allowed " +
                    (isRMA === false ? "Request" : "Delivery") +
                    " Date is " +
                    orderFulfillmentThreshold +
                    " day" +
                    (orderFulfillmentThreshold === 1 ? "" : "s") +
                    " from today."
                  }
                  value={changedDate}
                  isRMA={isRMA}
                  setValue={setChangedDate}
                  setDateInTabs={setRequestedDateInTabs}
                  defaultCalendarMonth={changeRequestedDateMinDate}
                  minDate={changeRequestedDateMinDate}
                  setDirty={setDirty}
                  inOrderTabs={true}
                  setFormChanged={setFormChanged}
                  error={changedDateError}
                  setError={setChangedDateError}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "50px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    disable={dateChangedLoading}
                    toggle={() => {
                      setChangedDateError(false);
                      setChangedDate(null);
                      setOpenChangeDateSubmit(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: "20px" }} />
                  <CustomButton
                    loading={dateChangedLoading}
                    startIcon={<Save />}
                    toggle={async () => {
                      await updatedRequestedDate();
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}

      {displayOrderReview ? (
        <>
          <CustomModal openModal={displayOrderReview} width="75vw">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                padding: "20px",
                justifyContent: "center",
                // textAlign: "justify",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h4" component="span">
                  {isRMA === false ? "Review Your Order" : "Review Your RMA"}
                </Typography>
                <br />
                <Typography variant="h5" component="span">
                  Confirm all quantities and prices.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <OrderReviewPage
                  fromTabs={true}
                  isRMA={isRMA}
                  currency={currency}
                  orderID={orderID}
                  orderType={orderType}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "50px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    disable={submitLoading}
                    toggle={() => {
                      setDisplayOrderReview(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: "20px" }} />
                  <CustomButton
                    loading={submitLoading}
                    startIcon={<Save />}
                    toggle={async () => {
                      if (
                        (orderType !== "P" &&
                          orderType !== "Project Pack for Home") ||
                        [
                          "AV11",
                          "AV12",
                          "AV13",
                          "AV14",
                          "AV21",
                          "AV22",
                          "AV23",
                          "AV24",
                          "AV25",
                        ].includes(addressVerificationLevel)
                      ) {
                        submitOrderAfterReview();
                      } else {
                        setDisplayAddressVerificationWarning(true);
                      }
                    }}
                  >
                    Save
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}
