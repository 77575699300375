import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import { useHistory } from "react-router";
import logo from "../../assets/Levven_logo.png";
import { accountServices } from "../../services/account.services";
import { Alert } from "@mui/material";
import { CircularProgress } from "@mui/material";
import CustomAlert from "../../components/CustomAlert/CustomAlert";

const VerificationPage = () => {
  /*Local variables*/
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const params = new URLSearchParams(window.location.search);
  const receivedToken = params.get("token");
  const receivedEmail = params.get("email");
  const history = useHistory();

  /*Redirection condition if the user tries to access the reset password page without a token*/
  if (receivedToken === null) {
    history.push("/login");
  }

  //to change title of page
  useEffect(() => {
    document.title =
      "Levven Electronics - Customer Portal - Email Verification";
  }, []);

  /*Functions*/
  /*Check the inputs and reset the password*/
  const verifyUser = () => {
    accountServices
      .verifyProfile({
        Token: receivedToken,
        Email: receivedEmail,
      })
      .then(() => {
        setError("");
        setLoading(false);
        history.push("/login");
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  useEffect(() => {
    verifyUser();
  }, []);

  /*Render function*/
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="100vh"
    >
      <CustomPaper>
        <img
          src={logo}
          alt="Levven Logo"
          width="141px"
          style={{ marginBottom: "4rem", objectFit: "contain" }}
        />
        <Grid container alignSelf="center" spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography align="center" variant="h6" color="#303030">
              Email Verification
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography align="center" variant="subtitle1" color="#303030">
              Waiting until your email is verified...
            </Typography>
          </Grid>
          {loading ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CircularProgress sx={{ color: "#0077c6" }} />
            </Grid>
          ) : null}
          <Grid
            item
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            {error ? <CustomAlert>{error}</CustomAlert> : null}
          </Grid>
        </Grid>
      </CustomPaper>
    </Grid>
  );
};

export default VerificationPage;
