import userReducer from "./user.reducer";
import { combineReducers } from "redux";
import orderReducer from "./order.reducer";
import agreementReducer from "./agreement.reducer";

/*This is the root reducer where all reducers are combined*/

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  agreement: agreementReducer,
});

export default rootReducer;
