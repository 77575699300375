import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { store } from "../redux/store";
import { userTypes } from "../redux/types/userTypes";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/users";

/*Timeout refresh token*/
let refreshTokenTimeout;

const login = (data) => {
  return fetchWrapper.post(baseUrl + "/login", data);
};

const logout = () => {
  return fetchWrapper
    .post(baseUrl + "/deleteCookies")
    .then(() => stopRefreshTokenTimer());
};

const registerNewDevice = (data) => {
  return fetchWrapper.post(baseUrl + "/SaveUserDevice", data);
};

const requestOtpCode = (data) => {
  return fetchWrapper.post(baseUrl + "/RequestOTPCode", data);
};

const sendOtpCode = (data) => {
  return fetchWrapper.post(baseUrl + "/VerifyOTPCode", data);
};

const forgotPassword = (data) => {
  return fetchWrapper.post(baseUrl + "/forgot-password", data);
};

const resetPassword = (data) => {
  return fetchWrapper.post(baseUrl + "/reset-password", data);
};

const getEndUserAgreement = () => {
  return fetchWrapper.get(baseUrl + "/GetEndUserAgreement");
};

const acceptEndUserAgreement = (data) => {
  return fetchWrapper.post(baseUrl + "/AcceptEndUserAgreement", data);
};

const getUsers = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllUsersList", data);
};

const getMyUsers = (data) => {
  return fetchWrapper.post(baseUrl + "/GetCompanyUsersList", data);
};

const getUserDataByID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetUserByID/" + data);
};

const updateUserData = (userID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateUser/" + userID, data);
};

const verifyProfile = (data) => {
  return fetchWrapper.post(baseUrl + "/VerifyProfile", data);
};

const getUserDevices = (data) => {
  return fetchWrapper.get(baseUrl + "/GetAllUserDevicesAsync/" + data);
};

const deleteUserDevices = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteUserDevices", data);
};

const getLevvenSales = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllLevvenSalesUsers", data);
};

const changePassword = (data) => {
  return fetchWrapper.post(baseUrl + "/change-password", data);
};

const getUsersByRoleID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetUsersByRoleID/" + data);
};

const checkIfUserActive = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckActiveUser", data);
};

const GetUsersByCompanyIDManageOrder = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetUsersByCompanyIDManageOrder/" + companyID
  );
};

const getUsersForNotesByOrder = (orderID) => {
  return fetchWrapper.get(baseUrl + "/GetUsersForNotesByOrder/" + orderID);
};

const checkUserNotifInvoices = (companyID) => {
  return fetchWrapper.get(baseUrl + "/CheckUserNotifInvoices/" + companyID);
};

const CheckUsernameExists = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckUsernameExists", data);
};

const getAllUsersByRelatedCompanies = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetAllUsersByRelatedCompanies/" + companyID
  );
};

const getAllUsersHavingSubmitOrderTrueByCompanyID = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetAllUsersHavingSubmitOrderTrueByCompanyID/" + companyID
  );
};

const getAllUsersHavingNotifyWhenSavedDraftReadyToOrderTrueByCompanyID = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetAllUsersHavingNotifyWhenSavedDraftReadyToOrderTrueByCompanyID/" + companyID
  );
};

const getUsersForOrdersFiltersCreatedBy = (data) => {
  return fetchWrapper.get(baseUrl + "/GetUsersForOrderCreatedByFilter/" + data);
};

const checkTokenActive = (data) => {
  return fetchWrapper.post(baseUrl + "/IsTokenActive", data);
};

const getTableColumnsBasedOnUserPreferences = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetTableColumnsBasedOnUserPreferences",
    data
  );
};

const updateUserTablePreferences = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateUserTablePreferences", data);
};

const updateUserCookieChoices = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateUserCookieChoices ", data);
};

const getUserContactsToBeLinked = (data) => {
  return fetchWrapper.post(baseUrl + "/GetUserContactsToBeLinked", data);
};

const getUsersForCreatedByRMA = () => {
  return fetchWrapper.get(baseUrl + "/GetUsersForCreatedByRMA");
};

const deleteUsers = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteUser", data);
};

const getDeletedUserForRecreation = (data) => {
  return fetchWrapper.post(baseUrl + "/GetDeletedUserForRecreation", data);
};

const recreateUser = (data) => {
  return fetchWrapper.post(baseUrl + "/RecreateUser", data);
};

/*Refresh Token*/

const refreshTokenAsync = async (dispatch) => {
  /*Global state*/
  const state = store.getState();
  await fetchWrapper
    .post(baseUrl + "/refresh-token", { token: state.user.user.refreshToken })
    .then((response) => {
      // publish user to subscribers and start timer to refresh token
      dispatch({
        type: userTypes.SET_LOGGED_IN,
        payload: true,
      });
      return dispatch({
        type: userTypes.GET_USER_DATA,
        payload: response.data,
      });
    })
    .then(() => startRefreshTokenTimer(dispatch))
    .catch((error) => {
      return dispatch({
        type: userTypes.SET_LOGGED_IN,
        payload: false,
      });
    });
};

const startRefreshTokenTimer = (dispatch) => {
  /*Global state*/
  const state = store.getState();
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(state.user.user.jwtToken.split(".")[1]));
  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = 28800000; //expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(() => refreshTokenAsync(dispatch), timeout);
};

const stopRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
};

export const accountServices = {
  login,
  registerNewDevice,
  requestOtpCode,
  sendOtpCode,
  forgotPassword,
  resetPassword,
  deleteUsers,
  getEndUserAgreement,
  acceptEndUserAgreement,
  logout,
  getUsers,
  getMyUsers,
  getUserDataByID,
  updateUserData,
  checkTokenActive,
  verifyProfile,
  getUserDevices,
  deleteUserDevices,
  getLevvenSales,
  changePassword,
  getUsersByRoleID,
  updateUserCookieChoices,
  checkIfUserActive,
  GetUsersByCompanyIDManageOrder,
  getUsersForNotesByOrder,
  checkUserNotifInvoices,
  startRefreshTokenTimer,
  stopRefreshTokenTimer,
  refreshTokenAsync,
  CheckUsernameExists,
  getAllUsersByRelatedCompanies,
  getAllUsersHavingSubmitOrderTrueByCompanyID,
  getAllUsersHavingNotifyWhenSavedDraftReadyToOrderTrueByCompanyID,
  getUsersForOrdersFiltersCreatedBy,
  getTableColumnsBasedOnUserPreferences,
  updateUserTablePreferences,
  getUsersForCreatedByRMA,
  getUserContactsToBeLinked,
  getDeletedUserForRecreation,
  recreateUser,
};
