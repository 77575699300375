import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { Copy, Plus, Save, Trash2, X, Check } from "react-feather";
import PricesTableComponent from "../PricesTableComponent/PricesTableComponent";
import ProductForm from "../ProductForm/ProductForm";
import CustomerGroupTable from "../CustomerGroupPrices/CustomerGroup";
import { productServices } from "../../services/product.service";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";
import CustomModal from "../CustomModal/CustomModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductsTabs({ setPageID, setPageLink }) {
  //used to send data to next page
  const history = useHistory();

  //used to retrieve data from previous page
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //permission and redux
  const prodCat = useSelector((state) =>
    state.user.user ? state.user.user.user.role.prodCat : ""
  );

  // Redirection
  if (prodCat === "N") history.push("/dashboard");

  //get id of product from previous page
  const id = fromURL
    ? decryptedObject
      ? decryptedObject.id
        ? decryptedObject.id
        : 0
      : 0
    : location.state
    ? location.state.id
      ? location.state.id
      : 0
    : 0;

  if (id === 0) {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  //holds which tab we are in
  const [value, setValue] = useState(0);

  //permissions
  const [adminManage, setAdminManage] = useState(false);
  //product status
  const [isValid, setIsValid] = useState(null);
  const [openOrderList, setOpenOrderList] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  //to make delete button load when clicked
  const [loading, setLoading] = useState(false);

  //holds error message when deleting if any
  const [deleteError, setDeleteError] = useState("");

  //holds error message when deleting if any
  const [activeError, setActiveError] = useState("");
  //displays the alert dialog before delete
  const [deleteRecord, setDeleteRecord] = useState(false);

  //makes save button in tabs load until save is over
  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  //makes save button in tabs load until updateStatus is over
  const [tabActivateButtonLoading, setTabActivateButtonLoading] =
    useState(false);
  //if any change
  const [formChanged, setFormChanged] = useState(false);

  //holds data received from prevous page

  const [productPartNumber, setProductPartNumber] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.partNumber
          ? decryptedObject.partNumber
          : ""
        : ""
      : location.state
      ? location.state.partNumber
        ? location.state.partNumber
        : ""
      : ""
  );
  const source = fromURL
    ? decryptedObject
      ? decryptedObject.source
        ? decryptedObject.source
        : ""
      : ""
    : location.state
    ? location.state.source
      ? location.state.source
      : ""
    : "";

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );

  /*User Form Ref*/
  const productRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (prodCat === "M") setAdminManage(true);
  }, []);

  useEffect(() => {
    //for help button
    setPageLink("Products");

    //for drawer menu
    setPageID(9);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleRouteClick = (route) => {
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };

  const handleConfirm = (route) => {
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    if (tempVal === "/create-product") {
      history.push("/create-product");
    } else if (route !== null) history.push(route);
    sessionStorage.removeItem("unsavedChanges");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const deleteRecords = () => {
    //function to delete records from table
    setLoading(true);
    setDeleteRecord(false);
    productServices
      .deleteProducts({ Products: [id] })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/Products",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  const updateProductStatusApi = () => {
    //function to delete records from table
    setTabActivateButtonLoading(true);
    productServices
      .updateProductStatus({ productID: id, activate: !isValid })
      .then((response) => {
        if (
          response.data.orderIDs == null &&
          response.data.statusUpdated == true
        ) {
          setIsValid(!isValid);
          setTabActivateButtonLoading(false);

          setOpenSnackBar(true);
        } else {
          // open popup with list of orders and proceed button
          setOrderList(response.data.orderIDs);
          setOpenOrderList(true);
          //on click on proceed button send same api with ForceDeactivate=true
          setTabActivateButtonLoading(false);
        }
      })
      .catch((error) => {
        setTabActivateButtonLoading(false);
        setActiveError(error.response.data.error.error);
      });
  };

  const forceUpdateProductStatusApi = () => {
    //function to delete records from table
    setTabActivateButtonLoading(true);
    productServices
      .updateProductStatus({
        productID: id,
        activate: !isValid,
        ForceDeactivate: true,
      })
      .then((response) => {
        if (response.data.statusUpdated == true) {
          setIsValid(!isValid);
          setTabActivateButtonLoading(false);
          setButtonLoading(false);
          setOpenOrderList(false);
          setOpenSnackBar(true);
        }

        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        setActiveError(error.response.data.error.error);
      });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };
  useEffect(() => {
    console.log(isValid);
  }, [isValid]);

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      <Paper>
        {openOrderList ? (
          <CustomModal openModal={orderList.length !== 0}>
            <Typography variant="h6" component="span" align="left">
              {" "}
              Warning
            </Typography>
            <br />
            <br />
            <Typography variant="body1" component="span" align="left">
              This product is used in one or more orders.
            </Typography>
            <br />
            <br />
            {orderList.length !== 0 ? (
              <Typography variant="body1" component="span" align="left">
                Orders:
              </Typography>
            ) : null}
            <br />
            {orderList &&
              orderList.map((order) => (
                <>
                  <Typography variant="body1" component="span" align="left">
                    - {order}
                  </Typography>
                  <br />
                </>
              ))}
            <br />

            <br />
            <Typography variant="body1" component="span" align="left">
              Are you sure you want to proceed?
            </Typography>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                toggle={() => {
                  setOrderList([]);
                  setOpenOrderList(false);
                }}
              >
                Cancel
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton
                toggle={forceUpdateProductStatusApi}
                loading={buttonLoading}
              >
                Proceed
              </CustomButton>
            </div>
          </CustomModal>
        ) : null}
        {deleteRecord ? (
          <AlertDialogSlide
            title="Warning"
            textContent={"Are you sure you want to delete this record?"}
            save={deleteRecords}
            deleteDialog={true}
            saveText="Yes"
            unsave={() => setDeleteRecord(false)}
            unsaveText="No"
          />
        ) : (
          ""
        )}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() => {
                  if (formChanged) {
                    handleConfirm("/Products");
                    setValue(0);
                    setFormChanged(false);
                  } else {
                    handleConfirm("/Products");
                    setValue(0);
                  }
                }}
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                {source}
              </Link>

              {value === 1 ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();

                      setFormChanged(false);
                    } else {
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {productPartNumber}
                </Link>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {productPartNumber}
                </Typography>
              )}
              {value === 1 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Prices
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>

            {value === 0 ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {prodCat === "M" ? (
                  <>
                    <Link
                      to="/create-product"
                      style={{ textDecoration: "none" }}
                    >
                      <CustomButton
                        startIcon={<Plus />}
                        toggle={() => handleRouteClick("/create-product")}
                      >
                        New Product
                      </CustomButton>
                    </Link>
                    <div style={{ width: "10px" }} />
                    <CustomButton
                      startIcon={<Copy />}
                      toggle={() =>
                        history.push({
                          pathname: "/create-product",
                          state: {
                            id: id,
                            duplicate: true,
                          },
                        })
                      }
                    >
                      Duplicate
                    </CustomButton>
                    <div style={{ width: "10px" }} />

                    {isValid != null && (
                      <>
                        <CustomButton
                          startIcon={isValid == true ? <X /> : <Check />}
                          toggle={updateProductStatusApi}
                          // toggle={() => productRef.current.updateStatus()}
                          loading={tabActivateButtonLoading}
                        >
                          {/* {isValid} */}
                          {isValid == true ? "Deactivate" : "Activate"}
                        </CustomButton>
                        <div style={{ width: "10px" }} />
                      </>
                    )}

                    <CustomButton
                      startIcon={<Save />}
                      toggle={() => productRef.current.save()}
                      loading={tabButtonLoading}
                    >
                      Save
                    </CustomButton>
                    <div style={{ width: "10px" }} />
                    <CustomButton
                      startIcon={<Trash2 />}
                      toggle={() => setDeleteRecord(true)}
                      loading={loading}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : null}

                <div style={{ width: "10px" }} />
                <CustomButton
                  startIcon={<X />}
                  toggle={async () => {
                    await productRef.current.setPristine();
                    history.push("/products");
                  }}
                >
                  Cancel
                </CustomButton>
              </div>
            ) : (
              ""
            )}
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Product" {...a11yProps(0)} style={{ margin: "50" }} />
              <Tab
                label="Item Price"
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />
              <Tab
                label="Customer/Group Price"
                {...a11yProps(2)}
                style={{ margin: "50" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ProductForm
              receivedProductID={id}
              adminManage={adminManage}
              setOpenSnackBar={setOpenSnackBar}
              setTabButtonLoading={setTabButtonLoading}
              setProductName={setProductPartNumber}
              ref={productRef}
              setFormChanged={setFormChanged}
              setIsValid={setIsValid}
              isValid={isValid}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PricesTableComponent
              titleText={"Prices for Product Number " + productPartNumber}
              productID={id}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CustomerGroupTable
              titleText={
                "Prices for Product Number " +
                productPartNumber +
                " " +
                " by Customer/Group"
              }
              productID={id}
            />
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
