//This file contains a key and a description of the errors that results from calling the melissa API

const melissaLogs = new Map();

//Address Verification Logs
melissaLogs.set("AV11", "The address has been partially verified to the State Level, which is NOT the highest level possible with the reference data.");
melissaLogs.set("AV12", "The address has been partially verified to the City Level, which is NOT the highest level possible with the reference data.")
melissaLogs.set("AV13", "The address has been partially verified to the Street Level, which is NOT the highest level possible with the reference data.")
melissaLogs.set("AV14", "The address has been partially verified to the House/Building Level, which is NOT the highest level possible with the reference data.")
melissaLogs.set("AV21", "The address has been verified to the State Level, which is the highest level possible with the reference data.")
melissaLogs.set("AV22", "The address has been verified to the City Level, which is the highest level possible with the reference data.")
melissaLogs.set("AV23", "The address has been verified to the Street Level, which is the highest level possible with the reference data.")
melissaLogs.set("AV24", "The address has been verified to the House/Building Level, which is the highest level possible with the reference data.")
melissaLogs.set("AV25", "The address has been verified to the SubPremise (Suite) or PO Box Level, which is the highest level possible with the reference data.")

//Address Error Logs
melissaLogs.set("AE01", "The address could not be verified at least up to the postal code level.")
melissaLogs.set("AE02", "Could not match the input street to a unique street name. Either no matches or too many matches found.")
melissaLogs.set("AE03", "The combination of directionals (N, E, SW, etc) and the suffix (AVE, ST, BLVD) is not correct and produced multiple possible matches.");
melissaLogs.set("AE05","The address was matched to multiple records. There is not enough information available in the address to break the tie between multiple records.");
melissaLogs.set("AE08","An address element after the house number, in most cases the sub-premise, was not valid.");
melissaLogs.set("AE09","An address element after the house number, in most cases the sub-premise, was missing.");
melissaLogs.set("AE10","The premise (house or building) number for the address is not valid.");
melissaLogs.set("AE11","The premise (house or building) number for the address is missing.");
melissaLogs.set("AE12","The PO (Post Office Box), RR (Rural Route), or HC (Highway Contract) Box number is invalid.");
melissaLogs.set("AE13","The PO (Post Office Box), RR (Rural Route), or HC (Highway Contract) Box number is missing.");
melissaLogs.set("AE14","US Only. The address is a Commercial Mail Receiving Agency (CMRA) and the Private Mail Box (PMB or #) number is missing.");
melissaLogs.set("AE17","A sub premise (suite) number was entered but the address does not have secondaries.");

//Address Change Logs
melissaLogs.set("AC01","The postal code was changed or added.");
melissaLogs.set("AC02","The administrative area (state, province) was added or changed.");
melissaLogs.set("AC03","The locality (city, municipality) name was added or changed.");
melissaLogs.set("AC09","The dependent locality (urbanization) was changed.");
melissaLogs.set("AC10","The thoroughfare (street) name was added or changed due to a spelling correction.");
melissaLogs.set("AC11","The thoroughfare (street) leading or trailing type was added or changed, such as from 'St' to 'Rd.'");
melissaLogs.set("AC12","The thoroughfare (street) pre-directional or post-directional was added or changed, such as from 'N' to 'NW.'");
melissaLogs.set("AC13","The sub premise (suite) type was added or changed, such as from 'STE' to 'APT.'");
melissaLogs.set("AC14","The sub premise (suite) unit number was added or changed.");
melissaLogs.set("AC15","The double dependent locality was added or changed.");
melissaLogs.set("AC16","The subadministrative area was added or changed.");
melissaLogs.set("AC17","The subnational area was added or changed.");
melissaLogs.set("AC18","The PO Box was added or changed.");
melissaLogs.set("AC19","The premise type was added or changed.");
melissaLogs.set("AC20","The house number was changed.");
melissaLogs.set("AC22","The organization was added or changed.");

//General Transmission Error
melissaLogs.set("GE05","The required license string is missing or invalid. Use the Credits License on the My Account page or call Tech Support 1-800-Melissa.");


export function getMelissaLog(code) {
    var result = ""
    if(melissaLogs.has(code)){
        result = melissaLogs.get(code);
    }
    return result;
  }