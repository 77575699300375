import React, { useEffect, useState } from "react";
import UsersTableComponent from "../../../components/UsersTableComponent/UsersTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderTable from "../../../components/OrderTable/OrderTable";
import OrderTableTemp from "../../../components/OrderTable/OrderTableTemp";

const OrdersTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  useEffect(() => {
    //for help button
    setPageLink("Orders");

    //for drawer menu
    setPageID(5);
  }, []);

  return (
    <div>
      <OrderTable />
      {/* <OrderTableTemp /> */}
    </div>
  );
};

export default OrdersTable;
