import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import { Trash2 } from "react-feather";
import { orderNotesServices } from "../../services/orderNotes.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import OrderNotesForm from "../OrderNotesForm/OrderNotesForm";
import { X } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//function to change tabs values
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OrderNoteTabs({ setPageID, setPageLink }) {
  //for holding the tab we are in
  const [value, setValue] = useState(0);

  //used to send data to next page
  const history = useHistory();

  //used to retrieve data sent from previous page
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //for loading of delete button
  const [loading, setLoading] = useState(false);

  //for holding errors while deleting record
  const [deleteError, setDeleteError] = useState("");

  //for displaying dialog before deletion
  const [deleteRecord, setDeleteRecord] = useState(false);

  //we fetch data from previous page
  const noteID = fromURL
    ? decryptedObject
      ? decryptedObject.noteID
        ? decryptedObject.noteID
        : 0
      : 0
    : location.state
    ? location.state.noteID
      ? location.state.noteID
      : 0
    : 0;
  const isRMA = fromURL
    ? decryptedObject
      ? decryptedObject.isRMA
        ? decryptedObject.isRMA
        : false
      : false
    : location.state
    ? location.state.isRMA
      ? location.state.isRMA
      : false
    : false;
  const orderID = fromURL
    ? decryptedObject
      ? decryptedObject.orderID
        ? decryptedObject.orderID
        : 0
      : 0
    : location.state
    ? location.state.orderID
      ? location.state.orderID
      : 0
    : 0;
  const orderName = fromURL
    ? decryptedObject
      ? decryptedObject.orderName
        ? decryptedObject.orderName
        : ""
      : ""
    : location.state
    ? location.state.orderName
      ? location.state.orderName
      : ""
    : "";
  const username = fromURL
    ? decryptedObject
      ? decryptedObject.userName
        ? decryptedObject.userName
        : ""
      : ""
    : location.state
    ? location.state.userName
      ? location.state.userName
      : ""
    : "";
  const date = fromURL
    ? decryptedObject
      ? decryptedObject.creationdate
        ? decryptedObject.creationdate
        : ""
      : ""
    : location.state
    ? location.state.creationdate
      ? location.state.creationdate
      : ""
    : "";

  if (noteID === 0) {
    history.goBack();
  }

  const handleChange = (event, newValue) => {
    //function to handle tab change
    setValue(newValue);
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      return (
        d.substring(8, 10) + "/" + d.substring(4, 7) + "/" + d.substring(13, 15)
      );
    }
  };

  useEffect(() => {
    //for help button

    if (isRMA === false) {
      //for help button
      setPageLink("Orders");
      //for darwer menu select
      setPageID(5);
    } else {
      setPageLink("RMA");
      setPageID(11);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   if (rolePermission === "N" || noteID === "") {
  //     history.push("/Dashboard");
  //   }

  const deleteOrderNote = () => {
    //delete selected Order Note by providing an array of their ID's
    setLoading(true);
    setDeleteRecord(false);
    const data = { OrderNotes: [noteID] };

    orderNotesServices
      .deleteOrderNote(data)
      .then((response) => {
        setLoading(false);
        history.push({
          pathname: isRMA === false ? "/order-information" : "/rma-information",
          state: {
            orderID: orderID,
            noteID: noteID,
            deletedSuccessfully: true,
            value: 2,
            orderName: orderName,
          },
        });
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };

  return (
    <Paper>
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteOrderNote}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      <Box
        sx={{
          width: "100%",
          paddingTop: "30px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflow: "auto",
          }}
        >
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="always"
              key="1"
              color="inherit"
              onClick={() => history.push(isRMA === false ? "/orders" : "/RMA")}
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              {isRMA === false ? "Orders" : "Notes"}
            </Link>
            <Link
              underline="always"
              key="2"
              color="inherit"
              onClick={() =>
                history.push({
                  pathname:
                    isRMA === false ? "/order-information" : "/rma-information",
                  state: {
                    orderID: orderID,
                    noteID: noteID,
                    orderName: orderName,
                  },
                })
              }
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              {orderName}
            </Link>
            
            <Link
              underline="always"
              key="3"
              color="inherit"
              onClick={() =>
                history.push({
                  pathname:
                    isRMA === false ? "/order-information" : "/rma-information",
                  state: {
                    orderID: orderID,
                    noteID: noteID,
                    orderName: orderName,
                    value: 2,
                  },
                })
              }
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              Notes
            </Link>
            <Typography key="4" color="text.primary" sx={{ fontSize: "24px" }}>
              {username} {convertUTC(date)}
            </Typography>
          </Breadcrumbs>
          {value === 0 ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CustomButton
                startIcon={<Trash2 />}
                size="medium"
                loading={loading}
                toggle={() => setDeleteRecord(true)}
              >
                Delete
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton
                size="medium"
                startIcon={<X />}
                toggle={() => history.goBack()}
              >
                Cancel
              </CustomButton>
            </div>
          ) : (
            ""
          )}
        </div>

        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs
            variant={"scrollable"}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Information"
              {...a11yProps(0)}
              style={{ margin: "50" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OrderNotesForm orderID={orderID} create={false} noteID={noteID} />
        </TabPanel>
      </Box>
    </Paper>
  );
}
