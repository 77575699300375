import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/orderquantity";

const GetProductListByTakeoffWizardStepForOrder = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetProductListByTakeoffWizardStepForOrder",
    data
  );
};

const UpdateOrderQuantity = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateOrderQuantityList", data);
};

const getListOfProductsToAddForOrderQuantitiesCreation = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetListOfProductsToAddForOrderQuantitiesCreation",
    data
  );
};

const addProductsToOrderQuantities = (data) => {
  return fetchWrapper.post(baseUrl + "/AddProductsToOrderQuantities", data);
};

const getListOfProductsToBeChangedForOrderCreation = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetListOfProductsToBeChangedForOrderCreation",
    data
  );
};

const changeProductsOfOrderQuantitiesOfCreatedOrder = (data) => {
  return fetchWrapper.post(
    baseUrl + "/ChangeProductsOfOrderQuantitiesOfCreatedOrder",
    data
  );
};

const getOrderQuantitiesForOrderReview = (data) => {
  return fetchWrapper.get(baseUrl + "/GetOrderQuantitiesForReview/" + data);
};

export const orderQuantitiesServices = {
  GetProductListByTakeoffWizardStepForOrder,
  UpdateOrderQuantity,
  getListOfProductsToAddForOrderQuantitiesCreation,
  addProductsToOrderQuantities,
  getListOfProductsToBeChangedForOrderCreation,
  changeProductsOfOrderQuantitiesOfCreatedOrder,
  getOrderQuantitiesForOrderReview,
};
