import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Tooltip,
  IconButton,
  Icon,
} from "@mui/material";
import Underline from "../Underline/Underline";
import validator from "validator";
import { accountServices } from "../../services/account.services";
import { countryServices } from "../../services/country.services";
import { rolesServices } from "../../services/roles.services";
import { adminServices } from "../../services/admin.services";
import { companyServices } from "../../services/company.services";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Clipboard, Compass, Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import CustomModal from "../CustomModal/CustomModal";
import { getMelissaLog } from "../../helpers/melissa-api-logs";
import { melissaServices } from "../../services/melissa.services";
import { systemDataServices } from "../../services/systemData.services";
import { userTypes } from "../../redux/types/userTypes";
import { customerServices } from "../../services/customer.services";
import LinkUserToContactTable from "../LinkUserToContactTable/LinkUserToContactTable";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useLogs } from "../../hooks/useLogs";
import InfoSnackbar from "../InfoSnackbar/InfoSnackbar";

/*Global Variables*/
const arrayOfJobRoles = [
  "Administration",
  "Design",
  "Estimating",
  "Purchasing",
  "Construction",
  "Installation",
  "Sales",
  "Marketing",
  "Warranty",
];

/*for checking for phone number*/
const phoneChecking = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-"];

const UserForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      sameUser = false,
      receivedUserID,
      receivedCompanyID = 0,
      companyAdmin = false,
      userStatus = "",
      setUserStatus,
      receivedRoleID = 0,
      setOpenSnackBar,
      createUser = false,
      setCreateChangeUser,
      setTabButtonLoading,
      setUserFullname,
      setReceivedUserID,
      setUsernameForTabs,
      isALevvenAdmin = false,
      setMessage,
      setFormChanged,
    },
    ref
  ) => {
    /*User Object*/
    const [user, setUser] = useState();
    const [previousNotified, setPreviousNotified] = useState();
    /*Company Object*/
    const [company, setCompany] = useState();
    const [companyError, setCompanyError] = useState();
    /*Selectors data*/
    const [countries, setCountries] = useState([]);
    const [stateProvince, setStateProvince] = useState([]);
    const [roles, setRoles] = useState([]);
    const [companies, setCompanies] = useState([]);
    /*User Status*/
    const [status, setStatus] = useState(userStatus);
    /*Username*/
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [notValidUsername, setNotValidUsername] = useState(false);
    /*First name*/
    const [firstname, setFirstname] = useState("");
    const [firstnameError, setFirstnameError] = useState(false);
    /*Last name*/
    const [lastname, setLastname] = useState("");
    const [lastnameError, setLastnameError] = useState(false);
    /*Job title*/
    const [jobTitle, setJobTitle] = useState("");
    const [jobTitleError, setJobTitleError] = useState(false);
    /*Phone number*/
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [validPhoneError, setValidPhoneError] = useState(false);
    /*Employee Job Roles*/
    const [employeeJobRoles, setEmployeeJobRoles] = useState([]);
    /*Certified*/
    const [certified, setCertified] = useState(false);
    /*Country*/
    const [country, setCountry] = useState();
    const [countryError, setCountryError] = useState(false);
    /*Office State/Province*/
    const [officeStateProvince, setOfficeStateProvince] = useState();
    const [officeStateProvinceError, setOfficeStateProvinceError] =
      useState(false);
    /*Address*/
    const [street, setStreet] = useState("");
    const [streetError, setStreetError] = useState(false);
    /*Address 2*/
    const [street2, setStreet2] = useState("");
    /*City*/
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(false);
    /*Office State/Province*/
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState(false);
    /*Role*/
    const [role, setRole] = useState();
    const [roleError, setRoleError] = useState(false);
    const [rolePermissionsDescription, setRolePermissionsDescription] =
      useState([]);
    /*Notifications*/
    const [invoices, setInvoices] = useState(false);
    const [shipment, setShipment] = useState(false);
    const [newOrder, setNewOrder] = useState(false);
    const [savedDraftOrder, setSavedDraftOrder] = useState(false);
    const [newRMA, setNewRMA] = useState(false);

    const [reCreateUser, setReCreateUser] = useState(false);

    const [takeUserToForm, setTakeUserToForm] = useState(false);

    //prompts user for compying company address
    const [promptCompanyAddressCopy, setPromptCompanyAddressCopy] =
      useState(false);

    //flag to disable user contact link to nav
    const [disableLinkUser, setDisableLinkUser] = useState(false);

    //hook to call log function
    const [
      logLoading,
      logError,
      handleLogCloseError,
      logUnsaveLoading,
      addLogAndLogDetail,
    ] = useLogs();

    /**Holds address to log */
    const [AddressToLog, setAddressToLog] = useState("");

    /*Loading*/
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [activateLoading, setActivateLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [createdDialog, setCreatedDialog] = useState(false);
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    const [userIDTmp, setUserIDTmp] = useState(0);

    //checks if an address has changed
    const [addressChanged, setAddressChanged] = useState(false);

    //Melissa Creadits Error Dialog
    //to display the dialog in case no credits or melissa side error, we give the User to option to continue
    const [melissaCreditsDialog, setMelissaCreaditsDialog] = useState(false);

    //holds if we should check is the address is valid
    const [verifyAddress, setVerifyAddress] = useState(false);

    //holds fprmatted address after Melissa
    const [formattedAddress, setFormattedAddress] = useState("");

    //holds the flag if User deleted from previous company
    const [deletedNewUser, setDeletedNewUser] = useState(false);

    //holds Melissa Result
    const [melissaResults, setMelissaResults] = useState([]);

    //holds Address Verification Level
    const [addressVerificationLevel, setAddressVerificationLevel] =
      useState(null);

    //to call the save after all new values have been set
    const [melissaSave, setMelissaSave] = useState(false);

    //holds address verification level when we fetch data from backend
    const [addressLevel, setAddressLevel] = useState(null);

    /**For Contacts in nav */
    const [contactsInNav, setContactsInNav] = useState([]);
    const [contactInNav, setContactInNav] = useState(null);

    /**to display popup for linking user to contact in nav */
    const [openContactInNavModal, setOpenContactInNavModal] = useState(false);
    const [selectedContactLoading, setSelectContactLoading] = useState(false);

    const [addressError, setAddressError] = useState(false);
    const [melissaInfoSnackbar, setMelissaInfoSnackbar] = useState(false);
    const [melissaMessageSnackbar, setMelissaMessageSnackbar] = useState(false);
    const [melissaSeveritySnackbar, setMelissaSeveritySnackbar] =
      useState(false);

    //holds addresses returned for autocomplete of addresses
    const [suggestions, setSuggestions] = useState([]);

    //holds autocomplete address

    const [addressValue, setAddressValue] = useState("");
    const [addressPicked, setAddressPicked] = useState(null);
    const [addressVerified, setAddressVerified] = useState(false);

    const history = useHistory();

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );

    const roleName = useSelector((state) =>
      state.user.user ? state.user.user.user.role.description : ""
    );

    const companyType = useSelector((state) =>
      state.user.user ? state.user.user.user.company.type : ""
    );

    const isLevvenAdmin = roleName === "Levven Admin" && companyType === "L";

    const userName = useSelector((state) =>
      state.user.user ? state.user.user.user.userName : ""
    );
    /*Get current company ID from Redux*/
    const currentCompanyID = useSelector((state) =>
      state.user.user ? state.user.user.user.company.companyID : ""
    );

    /*Declare dispatch to dispatch redux actions*/
    const dispatch = useDispatch();

    /*Functions*/

    /*Fetch user data by ID*/
    const fetchUserDataByID = async (
      saved = false,
      newUserID = 0,
      afterReCreate = false
    ) => {
      await setEmployeeJobRoles([]);
      accountServices
        .getUserDataByID(
          sameUser ? currentUserID : receivedUserID

          // newUserID !== 0 ? newUserID : receivedUserID
        )
        .then(async (response) => {
          const userData = response.data;
          if (currentUserID === receivedUserID && saved) {
            dispatch({
              type: userTypes.UPDATE_USER_INFO,
              payload: userData.user,
            });
          }

          setUser(userData);
          setContactInNav(userData.user.linkedNavContact);

          if (
            userData.user.linkedNavContact !== null &&
            userData.user.linkedNavContact !== undefined
          ) {
            setDisableLinkUser(true);
          }
          setStatus(userData.user.status);
          setAddressValue(
            `${userData.user.streetAddress}, ${userData.user.city}, ${userData.user.stateProvince?.stateProvinceCode} ${userData.user.zipPostCode}`
          );

          setAddressPicked({
            address: `${userData.user.streetAddress}, ${userData.user.city}, ${userData.user.stateProvince?.stateProvinceCode} ${userData.user.zipPostCode}`,
            address1: userData.user.streetAddress,
            address2: userData.user.secondAddress,
            city: userData.user.city,
            zipPostCode: userData.user.zipPostCode,
            country: userData.user.country,
            stateProvince: userData.user.stateProvince,
          });
          if (
            setUserStatus !== undefined &&
            typeof setUserStatus === "function"
          )
            setUserStatus(userData.user.status);
          setUsername(userData.user.userName);
          if (
            setUsernameForTabs !== undefined &&
            typeof setUsernameForTabs === "function"
          )
            setUsernameForTabs(userData.user.userName);
          setFirstname(userData.user.firstName);
          setAddressLevel(userData.user.addressVerificationLevel);
          setAddressVerificationLevel(userData.user.addressVerificationLevel);
          setLastname(userData.user.lastName);
          setJobTitle(userData.user.jobTitle);
          setPhoneNumber(userData.user.phoneNumber);
          setCertified(userData.user.isCertified);
          setCountry(userData.user.country);
          // setOfficeStateProvince(userData.user.stateProvince);
          // setCity(userData.user.city);
          // setStreet(userData.user.streetAddress);
          // setStreet2(userData.user.streetAddressTwo);
          // setZipCode(userData.user.zipPostCode);
          setRole(userData.user.role);
          setRolePermissionsDescription(userData.rolePermissionsDescription);
          setInvoices(userData.user.notifInv);
          setShipment(userData.user.notifShip);
          setSavedDraftOrder(userData.user.notifSavedDraftReadyToOrder);
          setNewOrder(userData.user.notifOrder);
          setNewRMA(userData.user.notifRMA);

          setCompany(userData.user.company);
          /*Job Roles*/
          await setEmployeeJobRoles([]);
          if (userData.user.administrationRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Administration",
            ]);
          if (userData.user.designRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Design",
            ]);
          if (userData.user.estimatingRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Estimating",
            ]);
          if (userData.user.purchasingRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Purchasing",
            ]);
          if (userData.user.constructionRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Construction",
            ]);
          if (userData.user.installationRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Installation",
            ]);
          if (userData.user.salesRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Sales",
            ]);
          if (userData.user.marketingRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Marketing",
            ]);
          if (userData.user.warrantyRole)
            setEmployeeJobRoles((employeeJobRoles) => [
              ...employeeJobRoles,
              "Warranty",
            ]);

          if (afterReCreate === true) {
            setCreatedDialog(true);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          if (afterReCreate === true) {
            setButtonLoading(false);
          }
          setSaveError(error.response.data.error.error);
        })
        .finally(() => {
          if (user) setLoading(false);
        });
    };

    const fetchAddresses = (address) => {
      if (verifyAddress === true && country !== null) {
        melissaServices
          .getAddressLookup({
            Address: address,
            Country: country.countryCode,
          })
          .then((response) => {
            setSuggestions(response.data.addresses);
          })
          .catch((error) => {
            const logDetail = {
              Status: "Melissa Error",
              Description: `Address Autocomplete fail, address sent:{Country:${country.description}, Address:${address}}`,
              AdditionalInfo: error.response.data.error.error,
              LogDetailTimeStamp: new Date().toISOString(),
            };

            addLogAndLogDetail(
              "Melissa",
              "Melissa Address Autocomplete",
              new Date().toISOString(),
              new Date().toISOString(),
              [logDetail],
              "onError"
            );
          });
      }
    };

    const getDeletedUserForRecreation = (data) => {
      //function to check if user has been deleted for old company
      if (
        company !== null &&
        company !== undefined &&
        username !== "" &&
        username !== null
      ) {
        accountServices
          .getDeletedUserForRecreation({
            Email: data,
            CompanyID: company.companyID,
          })
          .then(async (response) => {
            if (response.data.user !== null) {
              const userData = response.data;

              setUser(userData);

              setUsername(userData.user.userName);

              setAddressLevel(userData.user.addressVerificationLevel);
              setAddressVerificationLevel(
                userData.user.addressVerificationLevel
              );

              setPhoneNumber(userData.user.phoneNumber);

              setCountry(userData.user.country);
              setOfficeStateProvince(userData.user.stateProvince);
              setCity(userData.user.city);
              setStreet(userData.user.streetAddress);
              setStreet2(userData.user.streetAddressTwo);
              setZipCode(userData.user.zipPostCode);

              setDeletedNewUser(true);
              setReCreateUser(true);
            }
          })
          .catch((error) => {
            const logDetail = {
              Status: "Error on Function call.",
              Description:
                "Function to check if User with same username has been deleted before.",
              AdditionalInfo: error.response.data.error.error,
              LogDetailTimeStamp: new Date().toISOString(),
            };

            addLogAndLogDetail(
              "Function Call",
              "Check User Deleted Failed",
              new Date().toISOString(),
              new Date().toISOString(),
              [logDetail],
              "onError"
            );
          });
      }
    };

    /*Fetch All companies when there is no company ID received*/
    const fetchAllCompanies = () => {
      companyServices
        .getAllCompanies()
        .then((response) => {
          setCompanies(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch company data by ID*/
    const fetchuserDataByID = () => {
      companyServices
        .getCompanyByID(receivedCompanyID)
        .then((response) => {
          setCompany(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Fetch available countries*/
    const fetchCountries = () => {
      countryServices
        .getCountries()
        .then((response) => {
          setCountries(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch State/Province depending on the chosen country*/
    const fetchStateProvince = (countryID) => {
      countryServices
        .getStateProvince(countryID)
        .then((response) => {
          setStateProvince(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch company roles depending on the received companyID*/
    const fetchCompanyRoles = (data) => {
      rolesServices
        .getCompanyRoles(data)
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Save Changes*/
    const save = async (
      event,
      notifyInvoices = "",
      saveAfterContactLinked = false,
      createContact = false
    ) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      setSelectContactLoading(true);
      /*Conditions to check if there are any errors*/
      if (!receivedCompanyID && (!company || company === null))
        setCompanyError(true);
      if (username === "") setUsernameError(true);
      else if (!validator.isEmail(username)) setNotValidUsername(true);
      if (firstname === "") setFirstnameError(true);
      if (lastname === "") setLastnameError(true);
      if (jobTitle === "") setJobTitleError(true);
      if (phoneNumber === "") setPhoneNumberError(true);
      if (phoneNumber.length !== 12) setValidPhoneError(true);
      if (!country || country === null) setCountryError(true);
      if (addressValue === null || addressValue === "") setAddressError(true);

      if (!role) setRoleError(true);

      if (country.description === "" || country.description === null) {
        setCountryError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      }

      var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\-\. ]+\,[a-zA-Z0-9\- ]+$/g;

      if (regex.test(addressValue) === false) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Address Format not Valid");
        return;
      }

      var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

      if (editAddress_1.length !== 3) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Please Check if All the Fields Are Valid");

        return;
      }

      var streetAddressToBeSent_1 = editAddress_1[0].trim();
      var cityToBeSent_1 = editAddress_1[1].trim();

      var editAddress_StateProvince_Zip = editAddress_1[2].trim().split(" ");

      if (editAddress_StateProvince_Zip.length < 2) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      }

      var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

      var StateProvinceToBeSentCodeIndex = stateProvince.findIndex(
        (x) => x.stateProvinceCode === StateProvinceToBeSent_1
      );

      if (StateProvinceToBeSentCodeIndex === -1) {
        setAddressError(true);
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Invalid State Province");
        return;
      }

      var ZipPostCodeToBeSent_1 = "";

      editAddress_StateProvince_Zip.map((value, index) => {
        if (index !== 0 && value !== null && value !== undefined) {
          ZipPostCodeToBeSent_1 += " " + value;
          return "";
        } else {
          return "";
        }
      });

      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        (!receivedCompanyID && (!company || company === null)) ||
        username === "" ||
        !validator.isEmail(username) ||
        firstname === "" ||
        lastname === "" ||
        jobTitle === "" ||
        phoneNumber === "" ||
        phoneNumber.length !== 12 ||
        !country ||
        country === null ||
        addressValue === null ||
        addressValue === "" ||
        !role
      ) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSelectContactLoading(false);
        setPreviousNotified(null);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        const administartion = employeeJobRoles.includes("Administration");
        const design = employeeJobRoles.includes("Design");
        const estimating = employeeJobRoles.includes("Estimating");
        const purchasing = employeeJobRoles.includes("Purchasing");
        const construction = employeeJobRoles.includes("Construction");
        const installation = employeeJobRoles.includes("Installation");
        const sales = employeeJobRoles.includes("Sales");
        const marketing = employeeJobRoles.includes("Marketing");
        const warranty = employeeJobRoles.includes("Warranty");
        if (!create) {
          /*If the page is in edit mode*/

          accountServices
            .updateUserData(user.user.userID, {
              CompanyID: company.companyID,
              ModifiedByID: currentUserID,
              FirstName: firstname,
              LastName: lastname,
              JobTitle: jobTitle,
              PhoneNumber: phoneNumber,
              CountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : country && country !== null
                  ? country.countryID
                  : null,
              StateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : officeStateProvince && officeStateProvince !== null
                  ? officeStateProvince.stateProvinceID
                  : stateProvince[StateProvinceToBeSentCodeIndex]
                      .stateProvinceID,
              ZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : zipCode !== "" && zipCode !== null
                  ? zipCode
                  : ZipPostCodeToBeSent_1,
              StreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : street !== "" && street !== null
                  ? street
                  : streetAddressToBeSent_1,
              StreetAddressTwo:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : street2 !== "" && street2 !== null
                  ? street2
                  : editAddress_1[1] + ", " + editAddress_1[2],
              City:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : city !== "" && city !== null
                  ? city
                  : cityToBeSent_1,
              RoleID: role.roleID,
              NotifInv: notifyInvoices !== "" ? false : invoices,
              NotifShip: shipment,
              NotifOrder: newOrder,
              NotifSavedDraftReadyToOrder: savedDraftOrder,
              NotifRMA: newRMA,
              IsCertified: certified,
              AdministrationRole: administartion,
              DesignRole: design,
              EstimatingRole: estimating,
              PurchasingRole: purchasing,
              ConstructionRole: construction,
              InstallationRole: installation,
              SalesRole: sales,
              MarketingRole: marketing,
              WarrantyRole: warranty,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              CreateNavContact: contactInNav
                ? contactInNav.createContact
                  ? contactInNav.createContact
                  : false
                : false,
              NavContactID: contactInNav ? contactInNav.contactID : null,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setMessage("Saved Successfully");
              setOpenSnackBar(true);
              setAddressChanged(false);
              setAddressVerificationLevel("");
              setButtonLoading(false);
              setTabButtonLoading(false);
              setSelectContactLoading(false);
              setSaveError("");
              fetchUserDataByID(response.data);
              setUserFullname(firstname + " " + lastname);
              setPreviousNotified(null);
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setSelectContactLoading(false);
              setPreviousNotified(null);
            });
        } else {
          /*If the page is in create mode*/

          /*We check if the User's company is linked to a customer or a contact
          we get all contacts according to the company's customer or contact
          that are similar to the current user so we link the user
          to one of them. If user's company is not linked to a customer or contact then
           we directly create the user.*/

          /*We use the same function to create the User passing in a flag in the function's
           props to save after contact has been linked (or create contact in nav is chosen) */

          if (reCreateUser) {
            if (
              company !== null &&
              company !== undefined &&
              ((company.navContact !== null &&
                company.navContact !== undefined) ||
                (company.navCustomer !== null &&
                  company.navCustomer !== undefined)) &&
              saveAfterContactLinked === false
            ) {
              await accountServices
                .getUserContactsToBeLinked({
                  CustomerID:
                    company.navCustomer !== null &&
                    company.navCustomer !== undefined
                      ? company.navCustomer.customerID
                      : null,
                  ContactNo:
                    company.navContact !== null &&
                    company.navContact !== undefined
                      ? company.navContact.no
                      : null,
                  Email: username,
                  FirstName: firstname,
                  LastName: lastname,
                  UserToBeLinkedID: create
                    ? null
                    : user
                    ? user.user.userID
                    : null,
                })
                .then((response) => {
                  if (response.data !== null && response.data.length > 0) {
                    setContactsInNav(response.data);
                    setOpenContactInNavModal(true);
                    setButtonLoading(false);
                    setTabButtonLoading(false);
                    setSelectContactLoading(false);
                  } else {
                    save(event, "", true, true);
                  }
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setSelectContactLoading(false);
                  setPreviousNotified(null);
                });
            } else {
              await accountServices
                .recreateUser({
                  UserID: user.user.userID,
                  RecreationModel: {
                    CompanyID: company.companyID,
                    ModifiedByID: currentUserID,
                    FirstName: firstname,
                    LastName: lastname,
                    JobTitle: jobTitle,
                    PhoneNumber: phoneNumber,
                    CountryID:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.country.countryID
                        : country && country !== null
                        ? country.countryID
                        : null,
                    StateProvinceID:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.stateProvince.stateProvinceID
                        : officeStateProvince && officeStateProvince !== null
                        ? officeStateProvince.stateProvinceID
                        : stateProvince[StateProvinceToBeSentCodeIndex]
                            .stateProvinceID,
                    ZipPostCode:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.zipPostCode
                        : zipCode !== "" && zipCode !== null
                        ? zipCode
                        : ZipPostCodeToBeSent_1,
                    StreetAddress:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.address1
                        : street !== "" && street !== null
                        ? street
                        : streetAddressToBeSent_1,
                    StreetAddressTwo:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.address2
                        : street2 !== "" && street2 !== null
                        ? street2
                        : editAddress_1[1] + ", " + editAddress_1[2],
                    City:
                      addressPicked !== null && addressPicked !== undefined
                        ? addressPicked.city
                        : city !== "" && city !== null
                        ? city
                        : cityToBeSent_1,
                    RoleID: role.roleID,
                    NotifInv: notifyInvoices !== "" ? false : invoices,
                    NotifShip: shipment,
                    NotifOrder: newOrder,
                    NotifSavedDraftReadyToOrder: savedDraftOrder,
                    NotifRMA: newRMA,
                    IsCertified: certified,
                    AdministrationRole: administartion,
                    DesignRole: design,
                    EstimatingRole: estimating,
                    PurchasingRole: purchasing,
                    ConstructionRole: construction,
                    InstallationRole: installation,
                    SalesRole: sales,
                    MarketingRole: marketing,
                    WarrantyRole: warranty,
                    AddressVerificationLevel:
                      addressVerified === true
                        ? "AV25"
                        : addressVerificationLevel,
                    CreateNavContact: contactInNav
                      ? contactInNav.createContact
                        ? contactInNav.createContact
                        : false
                      : false,
                    NavContactID: contactInNav ? contactInNav.contactID : null,
                  },
                })
                .then((response) => {
                  setPristine();
                  setUserIDTmp(response.data);
                  fetchUserDataByID(false, response.data, true);
                  setFormChanged(false);
                  sessionStorage.removeItem("unsavedChanges");

                  setOpenContactInNavModal(false);

                  setAddressChanged(false);
                  setAddressVerificationLevel("");
                  setTabButtonLoading(false);
                  setSaveError("");
                  setPreviousNotified(null);

                  setSelectContactLoading(false);
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);

                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setSelectContactLoading(false);
                  setPreviousNotified(null);
                });
            }
          } else {
            if (
              company !== null &&
              company !== undefined &&
              ((company.navContact !== null &&
                company.navContact !== undefined) ||
                (company.navCustomer !== null &&
                  company.navCustomer !== undefined)) &&
              saveAfterContactLinked === false
            ) {
              await accountServices
                .getUserContactsToBeLinked({
                  CustomerID:
                    company.navCustomer !== null &&
                    company.navCustomer !== undefined
                      ? company.navCustomer.customerID
                      : null,
                  ContactNo:
                    company.navContact !== null &&
                    company.navContact !== undefined
                      ? company.navContact.no
                      : null,
                  Email: username,
                  FirstName: firstname,
                  LastName: lastname,
                  UserToBeLinkedID: create
                    ? null
                    : user
                    ? user.user.userID
                    : null,
                })
                .then((response) => {
                  if (response.data !== null && response.data.length > 0) {
                    setContactsInNav(response.data);
                    setOpenContactInNavModal(true);
                    setButtonLoading(false);
                    setTabButtonLoading(false);
                    setSelectContactLoading(false);
                  } else {
                    save(event, "", true, true);
                  }
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setSelectContactLoading(false);
                  setPreviousNotified(null);
                });
            } else {
              adminServices
                .createUser({
                  CreatorID: currentUserID,
                  UserName: username,
                  CompanyID:
                    receivedCompanyID === 0
                      ? company.companyID
                      : receivedCompanyID,
                  FirstName: firstname,
                  LastName: lastname,
                  JobTitle: jobTitle,
                  PhoneNumber: phoneNumber,
                  CountryID:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.country.countryID
                      : country && country !== null
                      ? country.countryID
                      : null,
                  StateProvinceID:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.stateProvince.stateProvinceID
                      : officeStateProvince && officeStateProvince !== null
                      ? officeStateProvince.stateProvinceID
                      : stateProvince[StateProvinceToBeSentCodeIndex]
                          .stateProvinceID,
                  ZipPostCode:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.zipPostCode
                      : zipCode !== "" && zipCode !== null
                      ? zipCode
                      : ZipPostCodeToBeSent_1,
                  StreetAddress:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.address1
                      : street !== "" && street !== null
                      ? street
                      : streetAddressToBeSent_1,
                  StreetAddressTwo:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.address2
                      : street2 !== "" && street2 !== null
                      ? street2
                      : editAddress_1[1] + ", " + editAddress_1[2],
                  City:
                    addressPicked !== null && addressPicked !== undefined
                      ? addressPicked.city
                      : city !== "" && city !== null
                      ? city
                      : cityToBeSent_1,
                  RoleID: role.roleID,
                  NotifInv: notifyInvoices !== "" ? false : invoices,
                  NotifShip: shipment,
                  NotifOrder: newOrder,
                  NotifRMA: newRMA,
                  NotifSavedDraftReadyToOrder: savedDraftOrder,
                  IsCertified: certified,
                  AdministrationRole: administartion,
                  DesignRole: design,
                  EstimatingRole: estimating,
                  PurchasingRole: purchasing,
                  ConstructionRole: construction,
                  InstallationRole: installation,
                  SalesRole: sales,
                  MarketingRole: marketing,
                  WarrantyRole: warranty,
                  AddressVerificationLevel:
                    addressVerified === true
                      ? "AV25"
                      : addressVerificationLevel,
                  CreateNavContact:
                    create === false
                      ? contactInNav
                        ? contactInNav.createContact
                          ? contactInNav.createContact
                          : false
                        : false
                      : createContact,
                  NavContactID: contactInNav ? contactInNav.contactID : null,
                })
                .then((response) => {
                  setPristine();
                  setFormChanged(false);
                  sessionStorage.removeItem("unsavedChanges");

                  setButtonLoading(false);
                  setAddressChanged(false);
                  setAddressVerificationLevel("");
                  setOpenContactInNavModal(false);
                  setTabButtonLoading(false);
                  setSaveError("");
                  setPreviousNotified(null);
                  setUser(response.data);
                  setSelectContactLoading(false);
                  setCreatedDialog(true);
                })
                .catch((error) => {
                  setSaveError(error.response.data.message);
                  setButtonLoading(false);
                  setTabButtonLoading(false);
                  setSelectContactLoading(false);
                  setPreviousNotified(null);
                });
            }
          }
        }
      }
    };

    const fetchVerifyAddress = async () => {
      /**Fetch if we should verify for the address */
      await systemDataServices
        .getSystemDataVariables()
        .then((response) => {
          setVerifyAddress(response.data.applyAddressValidation);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    useEffect(() => {
      /*once we get the User's company data we check if the company is linked
      to a customer or contact and we get the contacts that are similar to the current user
      according to the customer or contact that the User's company islinked to*/
      if (
        company !== null &&
        company !== undefined &&
        ((company.navCustomer !== null && company.navCustomer !== undefined) ||
          (company.navContact !== null && company.navContact !== undefined))
      ) {
        accountServices
          .getUserContactsToBeLinked({
            CustomerID:
              company.navCustomer !== null && company.navCustomer !== undefined
                ? company.navCustomer.customerID
                : null,
            ContactNo:
              company.navContact !== null && company.navContact !== undefined
                ? company.navContact.no
                : null,
            Email: username,
            FirstName: firstname,
            LastName: lastname,
            UserToBeLinkedID: create ? null : user ? user.user.userID : null,
          })
          .then((response) => {
            setContactsInNav(response.data);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          });
      }
    }, [company]);

    useEffect(() => {}, []);

    useEffect(() => {
      /**Used to save, for rendering purposes */
      if (melissaSave === true) {
        save("", "");
        setMelissaSave(false);
      }
    }, [melissaSave]);

    /*Check if another user having Notified of Invoices true in the same company*/
    const checkUserNotifInvoices = async (e) => {
      if (e) e.preventDefault();
      if (invoices) {
        await accountServices
          .CheckUsernameExists({ Email: username })
          .then((response) => {
            if (response.data && create) {
              setSaveError("User Already Exists");
              return;
            } else {
              setSaveError("");
              accountServices
                .checkUserNotifInvoices(
                  receivedCompanyID !== 0
                    ? receivedCompanyID
                    : company?.companyID
                )
                .then(async (response) => {
                  if (response.data && response.data !== null) {
                    if (user && user !== null) {
                      if (response.data.userID !== user.user.userID) {
                        setPreviousNotified(response.data);
                      } else if (addressChanged && verifyAddress) {
                        setButtonLoading(true);
                        setTabButtonLoading(true);
                        setMelissaMessageSnackbar("Checking This Address...");
                        setMelissaSeveritySnackbar("Loading_Icon");
                        setMelissaInfoSnackbar(true);

                        if (
                          country.description === "" ||
                          country.description === null
                        ) {
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          setCountryError(true);
                          setMelissaMessageSnackbar(
                            "Address format not OK, please re-enter."
                          );
                          setMelissaSeveritySnackbar("Error_Icon");
                          setMelissaInfoSnackbar(true);
                          return;
                        }

                        var regex =
                          /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

                        if (regex.test(addressValue) === false) {
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          setAddressError(true);
                          setMelissaMessageSnackbar(
                            "Address format not OK, please re-enter."
                          );
                          setMelissaSeveritySnackbar("Error_Icon");
                          setMelissaInfoSnackbar(true);
                          return;
                        }

                        var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

                        if (editAddress_1.length !== 3) {
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          setAddressError(true);
                          setMelissaMessageSnackbar(
                            "Address format not OK, please re-enter."
                          );
                          setMelissaSeveritySnackbar("Error_Icon");
                          setMelissaInfoSnackbar(true);
                          return;
                        }

                        var streetAddressToBeSent_1 = editAddress_1[0].trim();
                        var cityToBeSent_1 = editAddress_1[1].trim();

                        var editAddress_StateProvince_Zip = editAddress_1[2]
                          .trim()
                          .split(" ");

                        if (editAddress_StateProvince_Zip.length < 2) {
                          setButtonLoading(false);
                          setAddressError(true);
                          setTabButtonLoading(false);
                          setMelissaMessageSnackbar(
                            "Address format not OK, please re-enter."
                          );
                          setMelissaSeveritySnackbar("Error_Icon");
                          setMelissaInfoSnackbar(true);
                          return;
                        }

                        var StateProvinceToBeSent_1 =
                          editAddress_StateProvince_Zip[0].trim();

                        var ZipPostCodeToBeSent_1 = "";

                        editAddress_StateProvince_Zip.map((value, index) => {
                          if (
                            index !== 0 &&
                            value !== null &&
                            value !== undefined
                          ) {
                            ZipPostCodeToBeSent_1 += " " + value;
                            return "";
                          } else {
                            return "";
                          }
                        });

                        const data = {
                          Street1: streetAddressToBeSent_1,
                          Street2: "", //instStreet2,
                          City: cityToBeSent_1,
                          StateProvince: StateProvinceToBeSent_1,
                          Country: country.description,
                          ZipCode: ZipPostCodeToBeSent_1,
                        };

                        await melissaServices
                          .validateAddress(data)
                          .then(async (response) => {
                            setMelissaInfoSnackbar(false);
                            var descriptions = [];
                            var addresslvl = "";
                            setFormattedAddress(
                              response.data.result.records[0].formattedAddress
                            );
                            setCity(response.data.result.records[0].locality);
                            setCountry(response.data.country);
                            setOfficeStateProvince(response.data.stateProvince);
                            setStreet(
                              response.data.result.records[0].addressLine1
                            );

                            var zip = await response.data.result.records[0]
                              .postalCode;
                            if (zip.indexOf("-") === -1) {
                              setZipCode(
                                response.data.result.records[0].postalCode
                              );
                              setAddressValue(
                                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                              );
                            } else {
                              if (zipCode.indexOf("-") === -1) {
                                var part1 = zip.split("-");
                                setZipCode(part1[0]);
                                setAddressValue(
                                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                                );
                              } else {
                                setZipCode(
                                  response.data.result.records[0].postalCode
                                );
                                setAddressValue(
                                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                                );
                              }
                            }

                            await response.data.result.records[0].results
                              .split(",")
                              .forEach((code) => {
                                if (getMelissaLog(code) !== "") {
                                  descriptions.push(getMelissaLog(code));
                                }
                                if (
                                  code === "AV11" ||
                                  code === "AV12" ||
                                  code === "AV13" ||
                                  code === "AV14" ||
                                  code === "AV21" ||
                                  code === "AV22" ||
                                  code === "AV23" ||
                                  code === "AV24" ||
                                  code === "AV25"
                                ) {
                                  setAddressVerificationLevel(code);
                                  addresslvl = code;
                                }
                              });

                            if (
                              addresslvl === "AV11" ||
                              addresslvl === "AV12" ||
                              addresslvl === "AV13" ||
                              addresslvl === "AV14" ||
                              addresslvl === "AV21" ||
                              addresslvl === "AV22" ||
                              addresslvl === "AV23" ||
                              addresslvl === "AV24" ||
                              addresslvl === "AV25"
                            ) {
                              setMelissaMessageSnackbar(
                                "Address adjusted/verified, thank you."
                              );
                              setMelissaSeveritySnackbar("Success_Icon");
                              setMelissaInfoSnackbar(true);
                              setMelissaSave(true);
                              setAddressChanged(false);
                            } else {
                              setMelissaMessageSnackbar(
                                "The address could not be verified at least up to the zip or post code level."
                              );
                              setMelissaSeveritySnackbar("Warning_Icon");
                              setMelissaInfoSnackbar(true);
                              setMelissaResults(descriptions);
                              setButtonLoading(false);
                              setTabButtonLoading(false);
                              setAddressChanged(false);
                            }
                          })
                          .catch((error) => {
                            setButtonLoading(false);
                            setTabButtonLoading(false);
                            if (
                              error.response.data.error.errorCode === "err369"
                            ) {
                              setAddressToLog(`{
                                Address: ${streetAddressToBeSent_1},
                                City: ${cityToBeSent_1},
                                Country: ${country.description},
                                State Province: ${StateProvinceToBeSent_1},
                                ZipCode: ${ZipPostCodeToBeSent_1},
                              }`);
                              setMelissaCreaditsDialog(true);
                              setMelissaMessageSnackbar(
                                "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                              );
                              setMelissaSeveritySnackbar("Warning_Icon");
                              setMelissaInfoSnackbar(true);
                            } else {
                              setMelissaInfoSnackbar(false);
                              setSaveError(error.response.data.error.error);
                            }
                          });
                      } else {
                        save(e, "");
                      }
                    } else if (addressChanged && verifyAddress) {
                      setButtonLoading(true);
                      setTabButtonLoading(true);
                      setMelissaMessageSnackbar("Checking This Address...");
                      setMelissaSeveritySnackbar("Loading_Icon");
                      setMelissaInfoSnackbar(true);

                      if (
                        country.description === "" ||
                        country.description === null
                      ) {
                        setButtonLoading(false);
                        setTabButtonLoading(false);
                        setCountryError(true);
                        setMelissaMessageSnackbar(
                          "Address format not OK, please re-enter."
                        );
                        setMelissaSeveritySnackbar("Error_Icon");
                        setMelissaInfoSnackbar(true);
                        return;
                      }

                      var regex =
                        /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

                      if (regex.test(addressValue) === false) {
                        setButtonLoading(false);
                        setTabButtonLoading(false);
                        setAddressError(true);
                        setMelissaMessageSnackbar(
                          "Address format not OK, please re-enter."
                        );
                        setMelissaSeveritySnackbar("Error_Icon");
                        setMelissaInfoSnackbar(true);
                        return;
                      }

                      var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

                      if (editAddress_1.length !== 3) {
                        setButtonLoading(false);
                        setTabButtonLoading(false);
                        setAddressError(true);
                        setMelissaMessageSnackbar(
                          "Address format not OK, please re-enter."
                        );
                        setMelissaSeveritySnackbar("Error_Icon");
                        setMelissaInfoSnackbar(true);
                        return;
                      }

                      var streetAddressToBeSent_1 = editAddress_1[0].trim();
                      var cityToBeSent_1 = editAddress_1[1].trim();

                      var editAddress_StateProvince_Zip = editAddress_1[2]
                        .trim()
                        .split(" ");

                      if (editAddress_StateProvince_Zip.length < 2) {
                        setButtonLoading(false);
                        setAddressError(true);
                        setTabButtonLoading(false);
                        setMelissaMessageSnackbar(
                          "Address format not OK, please re-enter."
                        );
                        setMelissaSeveritySnackbar("Error_Icon");
                        setMelissaInfoSnackbar(true);
                        return;
                      }

                      var StateProvinceToBeSent_1 =
                        editAddress_StateProvince_Zip[0].trim();

                      var ZipPostCodeToBeSent_1 = "";

                      editAddress_StateProvince_Zip.map((value, index) => {
                        if (
                          index !== 0 &&
                          value !== null &&
                          value !== undefined
                        ) {
                          ZipPostCodeToBeSent_1 += " " + value;
                          return "";
                        } else {
                          return "";
                        }
                      });

                      const data = {
                        Street1: streetAddressToBeSent_1,
                        Street2: "", //instStreet2,
                        City: cityToBeSent_1,
                        StateProvince: StateProvinceToBeSent_1,
                        Country: country.description,
                        ZipCode: ZipPostCodeToBeSent_1,
                      };

                      await melissaServices
                        .validateAddress(data)
                        .then(async (response) => {
                          setMelissaInfoSnackbar(false);
                          var descriptions = [];
                          var addresslvl = "";
                          setFormattedAddress(
                            response.data.result.records[0].formattedAddress
                          );
                          setCity(response.data.result.records[0].locality);
                          setCountry(response.data.country);
                          setOfficeStateProvince(response.data.stateProvince);
                          setStreet(
                            response.data.result.records[0].addressLine1
                          );

                          var zip = await response.data.result.records[0]
                            .postalCode;
                          if (zip.indexOf("-") === -1) {
                            setZipCode(
                              response.data.result.records[0].postalCode
                            );
                            setAddressValue(
                              `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                            );
                          } else {
                            if (zipCode.indexOf("-") === -1) {
                              var part1 = zip.split("-");
                              setZipCode(part1[0]);
                              setAddressValue(
                                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                              );
                            } else {
                              setZipCode(
                                response.data.result.records[0].postalCode
                              );
                              setAddressValue(
                                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                              );
                            }
                          }

                          await response.data.result.records[0].results
                            .split(",")
                            .forEach((code) => {
                              if (getMelissaLog(code) !== "") {
                                descriptions.push(getMelissaLog(code));
                              }
                              if (
                                code === "AV11" ||
                                code === "AV12" ||
                                code === "AV13" ||
                                code === "AV14" ||
                                code === "AV21" ||
                                code === "AV22" ||
                                code === "AV23" ||
                                code === "AV24" ||
                                code === "AV25"
                              ) {
                                setAddressVerificationLevel(code);
                                addresslvl = code;
                              }
                            });

                          if (
                            addresslvl === "AV11" ||
                            addresslvl === "AV12" ||
                            addresslvl === "AV13" ||
                            addresslvl === "AV14" ||
                            addresslvl === "AV21" ||
                            addresslvl === "AV22" ||
                            addresslvl === "AV23" ||
                            addresslvl === "AV24" ||
                            addresslvl === "AV25"
                          ) {
                            setMelissaMessageSnackbar(
                              "Address adjusted/verified, thank you."
                            );
                            setMelissaSeveritySnackbar("Success_Icon");
                            setMelissaInfoSnackbar(true);
                            setMelissaSave(true);
                            setAddressChanged(false);
                          } else {
                            setMelissaMessageSnackbar(
                              "The address could not be verified at least up to the zip or post code level."
                            );
                            setMelissaSeveritySnackbar("Warning_Icon");
                            setMelissaInfoSnackbar(true);
                            setMelissaResults(descriptions);
                            setButtonLoading(false);
                            setTabButtonLoading(false);
                            setAddressChanged(false);
                          }
                        })
                        .catch((error) => {
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          if (
                            error.response.data.error.errorCode === "err369"
                          ) {
                            setAddressToLog(`{
                              Address: ${streetAddressToBeSent_1},
                              City: ${cityToBeSent_1},
                              Country: ${country.description},
                              State Province: ${StateProvinceToBeSent_1},
                              ZipCode: ${ZipPostCodeToBeSent_1},
                            }`);
                            setMelissaCreaditsDialog(true);
                            setMelissaMessageSnackbar(
                              "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                            );
                            setMelissaSeveritySnackbar("Warning_Icon");
                            setMelissaInfoSnackbar(true);
                          } else {
                            setMelissaInfoSnackbar(false);
                            setSaveError(error.response.data.error.error);
                          }
                        });
                    } else {
                      setPreviousNotified(response.data);
                    }
                  } else {
                    save(e, "");
                  }
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                });
            }
          })
          .catch((error) => setSaveError(error.response.data.error.error));
      } else if (addressChanged && verifyAddress) {
        setButtonLoading(true);
        setTabButtonLoading(true);

        setMelissaMessageSnackbar("Checking This Address...");
        setMelissaSeveritySnackbar("Loading_Icon");
        setMelissaInfoSnackbar(true);

        if (country.description === "" || country.description === null) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setCountryError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

        if (regex.test(addressValue) === false) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

        if (editAddress_1.length !== 3) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var streetAddressToBeSent_1 = editAddress_1[0].trim();
        var cityToBeSent_1 = editAddress_1[1].trim();

        var editAddress_StateProvince_Zip = editAddress_1[2].trim().split(" ");

        if (editAddress_StateProvince_Zip.length < 2) {
          setButtonLoading(false);
          setAddressError(true);
          setTabButtonLoading(false);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

        var ZipPostCodeToBeSent_1 = "";

        editAddress_StateProvince_Zip.map((value, index) => {
          if (index !== 0 && value !== null && value !== undefined) {
            ZipPostCodeToBeSent_1 += " " + value;
            return "";
          } else {
            return "";
          }
        });

        const data = {
          Street1: streetAddressToBeSent_1,
          Street2: "", //instStreet2,
          City: cityToBeSent_1,
          StateProvince: StateProvinceToBeSent_1,
          Country: country.description,
          ZipCode: ZipPostCodeToBeSent_1,
        };
        await melissaServices
          .validateAddress(data)
          .then(async (response) => {
            setMelissaInfoSnackbar(false);
            var descriptions = [];
            var addresslvl = "";
            setFormattedAddress(
              response.data.result.records[0].formattedAddress
            );
            setCity(response.data.result.records[0].locality);
            setCountry(response.data.country);
            setOfficeStateProvince(response.data.stateProvince);
            setStreet(response.data.result.records[0].addressLine1);

            var zip = await response.data.result.records[0].postalCode;

            if (zip.indexOf("-") === -1) {
              setZipCode(response.data.result.records[0].postalCode);
              setAddressValue(
                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
              );
            } else {
              if (zipCode.indexOf("-") === -1) {
                var part1 = zip.split("-");
                setZipCode(part1[0]);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                );
              } else {
                setZipCode(response.data.result.records[0].postalCode);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                );
              }
            }

            await response.data.result.records[0].results
              .split(",")
              .forEach((code) => {
                if (getMelissaLog(code) !== "") {
                  descriptions.push(getMelissaLog(code));
                }
                if (
                  code === "AV11" ||
                  code === "AV12" ||
                  code === "AV13" ||
                  code === "AV14" ||
                  code === "AV21" ||
                  code === "AV22" ||
                  code === "AV23" ||
                  code === "AV24" ||
                  code === "AV25"
                ) {
                  setAddressVerificationLevel(code);
                  addresslvl = code;
                }
              });

            if (
              addresslvl === "AV11" ||
              addresslvl === "AV12" ||
              addresslvl === "AV13" ||
              addresslvl === "AV14" ||
              addresslvl === "AV21" ||
              addresslvl === "AV22" ||
              addresslvl === "AV23" ||
              addresslvl === "AV24" ||
              addresslvl === "AV25"
            ) {
              setMelissaMessageSnackbar(
                "Address adjusted/verified, thank you."
              );
              setMelissaSeveritySnackbar("Success_Icon");
              setMelissaInfoSnackbar(true);
              setMelissaSave(true);
              setAddressChanged(false);
            } else {
              setMelissaMessageSnackbar(
                "The address could not be verified at least up to the zip or post code level."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
              setMelissaResults(descriptions);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setAddressChanged(false);
            }
          })
          .catch((error) => {
            setButtonLoading(false);
            setTabButtonLoading(false);
            if (error.response.data.error.errorCode === "err369") {
              setAddressToLog(`{
                Address: ${streetAddressToBeSent_1},
                City: ${cityToBeSent_1},
                Country: ${country.description},
                State Province: ${StateProvinceToBeSent_1},
                ZipCode: ${ZipPostCodeToBeSent_1},
              }`);
              setMelissaCreaditsDialog(true);
              setMelissaMessageSnackbar(
                "Address format OK but not verifiable at this time. You can proceed with this unverified address."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
            } else {
              setMelissaInfoSnackbar(false);
              setSaveError(error.response.data.error.error);
            }
          });
      } else {
        save(e, "");
      }
    };

    /*Navigate to the user information tab*/
    const goToUserInformation = () => {
      if (createUser) {
        setTakeUserToForm(false);
        setMessage("Saved Successfully");
        setOpenSnackBar(true);
        setUserFullname(firstname + " " + lastname);
        setUsernameForTabs(username);

        setCreateChangeUser(false);
        setReceivedUserID(user.user.userID);
        setUserStatus("N");
      } else {
        console.log("go to user info from, user form");
        history.push({
          pathname: "/user-information",
          state: {
            path: "user-information",
            userID: user.user.userID,
            microUserID: user.user.microUserID,
            fname: user.user.firstName,
            lname: user.user.lastName,
            companyID:
              create === false
                ? user.user.company.companyID
                : company.companyID,
            systemGenerated: user.user.systemGenerated,
            source:
              (create === false &&
                user.user.company.companyID === currentCompanyID) ||
              (create === true && company.companyID === currentCompanyID)
                ? "My Users"
                : "Users",
            linkSource:
              (create === false &&
                user.user.company.companyID === currentCompanyID) ||
              (create === true && company.companyID === currentCompanyID)
                ? "/my-users"
                : "/users",
            userStatus: user.user.status,
            username: user.user.userName,
            self: user.user.userID === currentUserID ? true : false,
            openSnackBar: true,
          },
        });
      }
    };

    /*Activate user function*/
    const activateUser = async () => {
      setActivateLoading(true);
      //if user inactive, we are reactivating him, else we are activating him
      const data = {
        AdminID: currentUserID,
        Users: [{ Email: username, CompanyID: company.companyID }],
        ReactivateUser: false,
        ResendActivationEmail: false,
      };

      await adminServices
        .activateUsers(data)
        .then(async () => {
          //we get updated values for users from database, and reset them so no record is selected
          setActivateLoading(false);
          setCreatedDialog(false);
          goToUserInformation("P");
        })
        .catch((error) => {
          setActivateLoading(false);
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch a role by it's ID sent from the role tabs to get the role automatically*/
    const fetchRoleByID = () => {
      rolesServices
        .getRoleByID(receivedRoleID)
        .then((response) => {
          setRole(response.data.role);
          setRolePermissionsDescription(
            response.data.rolePermissionsDescription
          );
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Check Status*/
    const userStatusCheck = (status) => {
      if (status === "P") {
        return "Pending";
      } else if (status === "A") {
        return "Active";
      } else if (status === "I") {
        return "Inactive";
      } else if (status === "N") {
        return "New";
      }
    };

    /*Check Company Type*/
    const companyTypeCheck = (status) => {
      if (status === "L") {
        return "Levven";
      } else if (status === "B") {
        return "Builder";
      } else if (status === "I") {
        return "Installer";
      } else if (status === "R") {
        return "Reseller";
      }
    };

    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        return (
          d.substring(8, 10) +
          "/" +
          d.substring(4, 7) +
          "/" +
          d.substring(13, 15) +
          " at " +
          d.substring(17, 21)
        );
      }
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    /*Initial useEffect*/
    useEffect(() => {
      fetchVerifyAddress();
      if (!create) {
        setEmployeeJobRoles([]);
        if (receivedUserID !== "") {
          fetchUserDataByID();
        }
      } else {
        if (receivedCompanyID === 0) fetchAllCompanies();
        else {
          fetchuserDataByID();
        }
      }
      if (receivedRoleID !== 0) fetchRoleByID();
      fetchCountries();
      if (receivedCompanyID !== 0) fetchCompanyRoles(receivedCompanyID);
    }, [userStatus, receivedUserID, sameUser]);

    /*Format the phone number correctly*/
    const editPhoneFormat = (onlyNums) => {
      var string1, string2;
      string1 = onlyNums.replace(/[^0-9]/g, "");
      string2 = string1.replace(/[^0-9]/g, "");

      switch (string2.length) {
        case 1:
          const number1 = string2.replace(/(\d{1})/, "$1");
          setPhoneNumber(number1);
          break;
        case 2:
          const number2 = string2.replace(/(\d{2})/, "$1");
          setPhoneNumber(number2);
          break;
        case 3:
          const number3 = string2.replace(/(\d{3})/, "$1");
          setPhoneNumber(number3);
          break;
        case 4:
          const number4 = string2.replace(/(\d{3})(\d{1})/, "$1-$2");
          setPhoneNumber(number4);
          break;
        case 5:
          const number5 = string2.replace(/(\d{3})(\d{2})/, "$1-$2");
          setPhoneNumber(number5);
          break;
        case 6:
          const number6 = string2.replace(/(\d{3})(\d{3})/, "$1-$2");
          setPhoneNumber(number6);
          break;
        case 7:
          const number7 = string2.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
          setPhoneNumber(number7);
          break;
        case 8:
          const number8 = string2.replace(/(\d{3})(\d{3})(\d{2})/, "$1-$2-$3");
          setPhoneNumber(number8);
          break;
        case 9:
          const number9 = string2.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
          setPhoneNumber(number9);
          break;
        case 10:
          const number10 = string2.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          setPhoneNumber(number10);
          break;
        default:
          const number0 = "";
          setPhoneNumber(number0);
          break;
      }
    };

    /*Fetch states/provinces everytime the country changes*/
    useEffect(() => {
      if (country) fetchStateProvince(country.countryID);
    }, [country]);

    useEffect(() => {
      if (
        company &&
        company !== null &&
        (country === null || country === undefined)
      )
        setCountry(company.country);
    }, [company]);

    /**For passing values to parent */
    useImperativeHandle(ref, () => ({
      setPristine,
      checkUserNotifInvoices,
      userNotNotifInvoices,
      invoices,
    }));

    const compNameRef = useRef(null);

    useEffect(() => {
      if (takeUserToForm === true) goToUserInformation();
    }, [takeUserToForm]);

    /**In case user not notified of invoices */
    const userNotNotifInvoices = async (e) => {
      if (e) e.preventDefault();
      if (addressChanged && verifyAddress) {
        setButtonLoading(true);
        setTabButtonLoading(true);

        setMelissaMessageSnackbar("Checking This Address...");
        setMelissaSeveritySnackbar("Loading_Icon");
        setMelissaInfoSnackbar(true);

        if (country.description === "" || country.description === null) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setCountryError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

        if (regex.test(addressValue) === false) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

        if (editAddress_1.length !== 3) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setAddressError(true);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var streetAddressToBeSent_1 = editAddress_1[0].trim();
        var cityToBeSent_1 = editAddress_1[1].trim();

        var editAddress_StateProvince_Zip = editAddress_1[2].trim().split(" ");

        if (editAddress_StateProvince_Zip.length < 2) {
          setButtonLoading(false);
          setAddressError(true);
          setTabButtonLoading(false);
          setMelissaMessageSnackbar("Address format not OK, please re-enter.");
          setMelissaSeveritySnackbar("Error_Icon");
          setMelissaInfoSnackbar(true);
          return;
        }

        var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

        var ZipPostCodeToBeSent_1 = "";

        editAddress_StateProvince_Zip.map((value, index) => {
          if (index !== 0 && value !== null && value !== undefined) {
            ZipPostCodeToBeSent_1 += " " + value;
            return "";
          } else {
            return "";
          }
        });

        const data = {
          Street1: streetAddressToBeSent_1,
          Street2: "", //instStreet2,
          City: cityToBeSent_1,
          StateProvince: StateProvinceToBeSent_1,
          Country: country.description,
          ZipCode: ZipPostCodeToBeSent_1,
        };

        await melissaServices
          .validateAddress(data)
          .then(async (response) => {
            setMelissaInfoSnackbar(false);
            var descriptions = [];
            var addresslvl = "";
            setFormattedAddress(
              response.data.result.records[0].formattedAddress
            );
            setCity(response.data.result.records[0].locality);
            setCountry(response.data.country);
            setOfficeStateProvince(response.data.stateProvince);
            setStreet(response.data.result.records[0].addressLine1);

            var zip = await response.data.result.records[0].postalCode;
            if (zip.indexOf("-") === -1) {
              setZipCode(response.data.result.records[0].postalCode);
              setAddressValue(
                `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
              );
            } else {
              if (zipCode.indexOf("-") === -1) {
                var part1 = zip.split("-");
                setZipCode(part1[0]);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                );
              } else {
                setZipCode(response.data.result.records[0].postalCode);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                );
              }
            }

            await response.data.result.records[0].results
              .split(",")
              .forEach((code) => {
                if (getMelissaLog(code) !== "") {
                  descriptions.push(getMelissaLog(code));
                }
                if (
                  code === "AV11" ||
                  code === "AV12" ||
                  code === "AV13" ||
                  code === "AV14" ||
                  code === "AV21" ||
                  code === "AV22" ||
                  code === "AV23" ||
                  code === "AV24" ||
                  code === "AV25"
                ) {
                  setAddressVerificationLevel(code);
                  addresslvl = code;
                }
              });

            if (
              addresslvl === "AV11" ||
              addresslvl === "AV12" ||
              addresslvl === "AV13" ||
              addresslvl === "AV14" ||
              addresslvl === "AV21" ||
              addresslvl === "AV22" ||
              addresslvl === "AV23" ||
              addresslvl === "AV24" ||
              addresslvl === "AV25"
            ) {
              setMelissaMessageSnackbar(
                "Address adjusted/verified, thank you."
              );
              setMelissaSeveritySnackbar("Success_Icon");
              setMelissaInfoSnackbar(true);
              setMelissaSave(true);
              setAddressChanged(false);
            } else {
              setMelissaMessageSnackbar(
                "The address could not be verified at least up to the zip or post code level."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
              setMelissaResults(descriptions);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setAddressChanged(false);
            }
          })
          .catch((error) => {
            setButtonLoading(false);
            setTabButtonLoading(false);
            if (error.response.data.error.errorCode === "err369") {
              setAddressToLog(`{
                Address: ${streetAddressToBeSent_1},
                City: ${cityToBeSent_1},
                Country: ${country.description},
                State Province: ${StateProvinceToBeSent_1},
                ZipCode: ${ZipPostCodeToBeSent_1},
              }`);
              setMelissaCreaditsDialog(true);
              setMelissaMessageSnackbar(
                "Address format OK but not verifiable at this time. You can proceed with this unverified address."
              );
              setMelissaSeveritySnackbar("Warning_Icon");
              setMelissaInfoSnackbar(true);
            } else {
              setMelissaInfoSnackbar(false);
              setSaveError(error.response.data.error.error);
            }
          });
      } else {
        save(e, "");
      }
    };

    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setMelissaInfoSnackbar(false);
    };

    return (
      <>
        {melissaCreditsDialog === true ? (
          <>
            <AlertDialogSlide
              title="Warning"
              textContent={"Address check failed, cancel or continue anyway?"}
              save={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: `{On User ${
                    create === true ? "creation" : "edit"
                  }, ${
                    create === false ? "UserID:" + user?.userID + "," : ""
                  },user Full Name:  ${
                    firstname + " " + lastname
                  }, User Email: ${userName}, Company Name:${company?.name}}`,
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onSave"
                ).then(() => {
                  setMelissaResults([]);
                  save("", "");
                  setMelissaCreaditsDialog(false);
                  setMelissaCreaditsDialog(false);
                });
              }}
              saveText="Continue"
              disableUnsave={logLoading === true || buttonLoading === true}
              unsaveLoading={logUnsaveLoading}
              disableSave={logUnsaveLoading === true}
              unsave={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: "",
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onUnsave"
                ).then(() => {
                  setMelissaCreaditsDialog(false);
                });
              }}
              unsaveText="Cancel"
              saveButtonLoading={logLoading || buttonLoading}
            />
            <InfoSnackbar
              open={melissaInfoSnackbar}
              message={melissaMessageSnackbar}
              severity={melissaSeveritySnackbar}
              handleClose={handleSnackBarClose}
            />
          </>
        ) : null}
        {deletedNewUser === true ? (
          <AlertDialogSlide
            title="Warning"
            textContent={
              "This user existed in Avenue for " +
              user.user.company.name +
              " and was deleted. Their old information will be retrieved, and you can change it before saving this user. You will need to reactivate this user by sending him the activation email."
            }
            save={() => {
              setDeletedNewUser(false);
            }}
            saveText="Ok"
          />
        ) : null}
        {logError !== "" ? (
          <AlertSnackBar
            message={logError}
            handleClose={handleLogCloseError}
            open={logError !== ""}
          />
        ) : null}
        {promptCompanyAddressCopy ? (
          <AlertDialogSlide
            title="Warning"
            textContent={
              "Are you sure you want to copy the user's company address?"
            }
            save={() => {
              if (company !== null && company !== undefined) {
                var fieldConcat = "";

                if (company.country) setCountry(company.country);

                if (company.streetAddress) {
                  fieldConcat += company.streetAddress;
                  setStreet(company.streetAddress);
                }
                if (company.secondAddress) {
                  setStreet2(company.secondAddress);
                }
                if (company.city) {
                  fieldConcat += ", " + company.city;
                  setCity(company.city);
                }

                if (company.stateProvince) {
                  fieldConcat += ", " + company.stateProvince.stateProvinceCode;
                  setOfficeStateProvince(company.stateProvince);
                }
                if (company.zipPostCode) {
                  fieldConcat += " " + company.zipPostCode;
                  setZipCode(company.zipPostCode);
                }
                setAddressChanged(false);
                setAddressPicked({
                  address: fieldConcat,
                  address1: company.streetAddress,
                  address2: company.secondAddress,
                  city: company.city,
                  zipPostCode: company.zipPostCode,
                  country: company.country,
                  stateProvince: company.stateProvince,
                });
                setAddressVerified(true);
                setAddressValue(fieldConcat);
                setAddressVerificationLevel(company.addressVerificationLevel);
                setDirty();
                setFormChanged(true);
                sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                if (streetError) setStreetError(false);
                if (cityError) setCityError(false);
                if (countryError) setCountryError(false);
                if (zipCodeError) setZipCodeError(false);
                if (officeStateProvinceError)
                  setOfficeStateProvinceError(false);
              }
              setPromptCompanyAddressCopy(false);
            }}
            saveText="Yes"
            unsave={() => setPromptCompanyAddressCopy(false)}
            unsaveText="No"
          />
        ) : null}
        {createdDialog ? (
          <CustomModal openModal={createdDialog}>
            <Typography variant="h6" component="span" align="left">
              {firstname + " " + lastname} is created successfully.
            </Typography>
            <br />
            <br />
            <Typography variant="body1" component="span" align="left">
              Would you like to activate {firstname + " " + lastname}? An email
              will be sent to this user for activating their account. Only
              Active users will be able to access the system. You can choose to
              save the user and send them an activation email later.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <CustomButton
                toggle={() => {
                  setCreatedDialog(false);
                  goToUserInformation("N");
                }}
              >
                Save For Later
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton toggle={activateUser} loading={activateLoading}>
                Send Activation Email
              </CustomButton>
            </div>
          </CustomModal>
        ) : null}
        {previousNotified && previousNotified !== null ? (
          <CustomModal openModal={previousNotified !== null}>
            <Typography variant="h6" component="span" align="left">
              {" "}
              Warning
            </Typography>
            <br />
            <br />
            <Typography variant="body1" component="span" align="left">
              {previousNotified.firstName + " " + previousNotified.lastName} is
              currently selected to receive invoice notifications. Do you want
              to change this to {firstname + " " + lastname}?
            </Typography>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                toggle={async (e) => {
                  setInvoices(false);
                  setPreviousNotified(null);
                  if (addressChanged && verifyAddress) {
                    setButtonLoading(true);
                    setTabButtonLoading(true);
                    setMelissaMessageSnackbar("Checking This Address...");
                    setMelissaSeveritySnackbar("Loading_Icon");
                    setMelissaInfoSnackbar(true);

                    if (
                      country.description === "" ||
                      country.description === null
                    ) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setCountryError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var regex =
                      /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

                    if (regex.test(addressValue) === false) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setAddressError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

                    if (editAddress_1.length !== 3) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setAddressError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var streetAddressToBeSent_1 = editAddress_1[0].trim();
                    var cityToBeSent_1 = editAddress_1[1].trim();

                    var editAddress_StateProvince_Zip = editAddress_1[2]
                      .trim()
                      .split(" ");

                    if (editAddress_StateProvince_Zip.length < 2) {
                      setButtonLoading(false);
                      setAddressError(true);
                      setTabButtonLoading(false);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var StateProvinceToBeSent_1 =
                      editAddress_StateProvince_Zip[0].trim();

                    var ZipPostCodeToBeSent_1 = "";

                    editAddress_StateProvince_Zip.map((value, index) => {
                      if (
                        index !== 0 &&
                        value !== null &&
                        value !== undefined
                      ) {
                        ZipPostCodeToBeSent_1 += " " + value;
                        return "";
                      } else {
                        return "";
                      }
                    });

                    const data = {
                      Street1: streetAddressToBeSent_1,
                      Street2: "", //instStreet2,
                      City: cityToBeSent_1,
                      StateProvince: StateProvinceToBeSent_1,
                      Country: country.description,
                      ZipCode: ZipPostCodeToBeSent_1,
                    };

                    await melissaServices
                      .validateAddress(data)
                      .then(async (response) => {
                        setMelissaInfoSnackbar(false);
                        var descriptions = [];
                        var addresslvl = "";
                        setFormattedAddress(
                          response.data.result.records[0].formattedAddress
                        );
                        setCity(response.data.result.records[0].locality);
                        setCountry(response.data.country);
                        setOfficeStateProvince(response.data.stateProvince);
                        setStreet(response.data.result.records[0].addressLine1);
                        // if(street2) setStreet2(
                        //   response.data.result.records[0].addressLine2
                        // );
                        var zip = await response.data.result.records[0]
                          .postalCode;
                        if (zip.indexOf("-") === -1) {
                          setZipCode(
                            response.data.result.records[0].postalCode
                          );
                          setAddressValue(
                            `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                          );
                        } else {
                          if (zipCode.indexOf("-") === -1) {
                            var part1 = zip.split("-");
                            setZipCode(part1[0]);
                            setAddressValue(
                              `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                            );
                          } else {
                            setZipCode(
                              response.data.result.records[0].postalCode
                            );
                            setAddressValue(
                              `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                            );
                          }
                        }
                        await response.data.result.records[0].results
                          .split(",")
                          .forEach((code) => {
                            if (getMelissaLog(code) !== "") {
                              descriptions.push(getMelissaLog(code));
                            }
                            if (
                              code === "AV11" ||
                              code === "AV12" ||
                              code === "AV13" ||
                              code === "AV14" ||
                              code === "AV21" ||
                              code === "AV22" ||
                              code === "AV23" ||
                              code === "AV24" ||
                              code === "AV25"
                            ) {
                              setAddressVerificationLevel(code);
                              addresslvl = code;
                            }
                          });

                        if (
                          addresslvl === "AV11" ||
                          addresslvl === "AV12" ||
                          addresslvl === "AV13" ||
                          addresslvl === "AV14" ||
                          addresslvl === "AV21" ||
                          addresslvl === "AV22" ||
                          addresslvl === "AV23" ||
                          addresslvl === "AV24" ||
                          addresslvl === "AV25"
                        ) {
                          setMelissaMessageSnackbar(
                            "Address adjusted/verified, thank you."
                          );
                          setMelissaSeveritySnackbar("Success_Icon");
                          setMelissaInfoSnackbar(true);
                          setMelissaSave(true);
                          setAddressChanged(false);
                        } else {
                          setMelissaMessageSnackbar(
                            "The address could not be verified at least up to the zip or post code level."
                          );
                          setMelissaSeveritySnackbar("Warning_Icon");
                          setMelissaInfoSnackbar(true);
                          setMelissaResults(descriptions);
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          setAddressChanged(false);
                        }
                      })
                      .catch((error) => {
                        setButtonLoading(false);
                        setTabButtonLoading(false);
                        if (error.response.data.error.errorCode === "err369") {
                          setAddressToLog(`{
                            Address: ${streetAddressToBeSent_1},
                            City: ${cityToBeSent_1},
                            Country: ${country.description},
                            State Province: ${StateProvinceToBeSent_1},
                            ZipCode: ${ZipPostCodeToBeSent_1},
                          }`);
                          setMelissaCreaditsDialog(true);
                          setMelissaMessageSnackbar(
                            "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                          );
                          setMelissaSeveritySnackbar("Warning_Icon");
                          setMelissaInfoSnackbar(true);
                        } else {
                          setMelissaInfoSnackbar(false);
                          setSaveError(error.response.data.error.error);
                        }
                      });
                  } else {
                    save(e, "false");
                  }
                }}
              >
                No
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton
                toggle={async (e) => {
                  setInvoices(true);
                  setPreviousNotified(null);
                  if (addressChanged && verifyAddress) {
                    setButtonLoading(true);
                    setTabButtonLoading(true);

                    setMelissaMessageSnackbar("Checking This Address...");
                    setMelissaSeveritySnackbar("Loading_Icon");
                    setMelissaInfoSnackbar(true);

                    if (
                      country.description === "" ||
                      country.description === null
                    ) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setCountryError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var regex =
                      /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

                    if (regex.test(addressValue) === false) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setAddressError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

                    if (editAddress_1.length !== 3) {
                      setButtonLoading(false);
                      setTabButtonLoading(false);
                      setAddressError(true);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var streetAddressToBeSent_1 = editAddress_1[0].trim();
                    var cityToBeSent_1 = editAddress_1[1].trim();

                    var editAddress_StateProvince_Zip = editAddress_1[2]
                      .trim()
                      .split(" ");

                    if (editAddress_StateProvince_Zip.length < 2) {
                      setButtonLoading(false);
                      setAddressError(true);
                      setTabButtonLoading(false);
                      setMelissaMessageSnackbar(
                        "Address format not OK, please re-enter."
                      );
                      setMelissaSeveritySnackbar("Error_Icon");
                      setMelissaInfoSnackbar(true);
                      return;
                    }

                    var StateProvinceToBeSent_1 =
                      editAddress_StateProvince_Zip[0].trim();

                    var ZipPostCodeToBeSent_1 = "";

                    editAddress_StateProvince_Zip.map((value, index) => {
                      if (
                        index !== 0 &&
                        value !== null &&
                        value !== undefined
                      ) {
                        ZipPostCodeToBeSent_1 += " " + value;
                        return "";
                      } else {
                        return "";
                      }
                    });

                    const data = {
                      Street1: streetAddressToBeSent_1,
                      Street2: "", //instStreet2,
                      City: cityToBeSent_1,
                      StateProvince: StateProvinceToBeSent_1,
                      Country: country.description,
                      ZipCode: ZipPostCodeToBeSent_1,
                    };
                    await melissaServices
                      .validateAddress(data)
                      .then(async (response) => {
                        setMelissaInfoSnackbar(false);
                        var descriptions = [];
                        var addresslvl = "";
                        setFormattedAddress(
                          response.data.result.records[0].formattedAddress
                        );
                        setCity(response.data.result.records[0].locality);
                        setCountry(response.data.country);
                        setOfficeStateProvince(response.data.stateProvince);
                        setStreet(response.data.result.records[0].addressLine1);
                        // if(street2) setStreet2(
                        //   response.data.result.records[0].addressLine2
                        // );
                        var zip = await response.data.result.records[0]
                          .postalCode;

                        if (zip.indexOf("-") === -1) {
                          setZipCode(
                            response.data.result.records[0].postalCode
                          );
                          setAddressValue(
                            `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                          );
                        } else {
                          if (zipCode.indexOf("-") === -1) {
                            var part1 = zip.split("-");
                            setZipCode(part1[0]);
                            setAddressValue(
                              `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                            );
                          } else {
                            setZipCode(
                              response.data.result.records[0].postalCode
                            );
                            setAddressValue(
                              `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                            );
                          }
                        }
                        await response.data.result.records[0].results
                          .split(",")
                          .forEach((code) => {
                            if (getMelissaLog(code) !== "") {
                              descriptions.push(getMelissaLog(code));
                            }
                            if (
                              code === "AV11" ||
                              code === "AV12" ||
                              code === "AV13" ||
                              code === "AV14" ||
                              code === "AV21" ||
                              code === "AV22" ||
                              code === "AV23" ||
                              code === "AV24" ||
                              code === "AV25"
                            ) {
                              setAddressVerificationLevel(code);
                              addresslvl = code;
                            }
                          });

                        if (
                          addresslvl === "AV11" ||
                          addresslvl === "AV12" ||
                          addresslvl === "AV13" ||
                          addresslvl === "AV14" ||
                          addresslvl === "AV21" ||
                          addresslvl === "AV22" ||
                          addresslvl === "AV23" ||
                          addresslvl === "AV24" ||
                          addresslvl === "AV25"
                        ) {
                          setMelissaMessageSnackbar(
                            "Address adjusted/verified, thank you."
                          );
                          setMelissaSeveritySnackbar("Success_Icon");
                          setMelissaInfoSnackbar(true);
                          setMelissaSave(true);
                          setAddressChanged(false);
                        } else {
                          setMelissaMessageSnackbar(
                            "The address could not be verified at least up to the zip or post code level."
                          );
                          setMelissaSeveritySnackbar("Warning_Icon");
                          setMelissaInfoSnackbar(true);
                          setMelissaResults(descriptions);
                          setButtonLoading(false);
                          setTabButtonLoading(false);
                          setAddressChanged(false);
                        }
                      })
                      .catch((error) => {
                        setButtonLoading(false);
                        setTabButtonLoading(false);
                        if (error.response.data.error.errorCode === "err369") {
                          setAddressToLog(`{
                            Address: ${streetAddressToBeSent_1},
                            City: ${cityToBeSent_1},
                            Country: ${country.description},
                            State Province: ${StateProvinceToBeSent_1},
                            ZipCode: ${ZipPostCodeToBeSent_1},
                          }`);
                          setMelissaCreaditsDialog(true);
                          setMelissaMessageSnackbar(
                            "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                          );
                          setMelissaSeveritySnackbar("Warning_Icon");
                          setMelissaInfoSnackbar(true);
                        } else {
                          setMelissaInfoSnackbar(false);
                          setSaveError(error.response.data.error.error);
                        }
                      });
                  } else {
                    save(e, "");
                  }
                }}
                loading={buttonLoading}
              >
                Yes
              </CustomButton>
            </div>
          </CustomModal>
        ) : null}
        <Paper elevation={0}>
          {/*Main Grid*/}
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="80vh"
            >
              <CircularProgress sx={{ color: "#0077c6" }} />
            </Grid>
          ) : (
            <>
              {/* {Prompt} */}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={9}
                  xl={9}
                  sx={{
                    borderRight: "2px solid #c4c4c4",
                    paddingRight: "20px",
                  }}
                >
                  {/*Form Grid*/}
                  <form
                    onSubmit={
                      create || adminManage || companyAdmin || sameUser
                        ? invoices
                          ? checkUserNotifInvoices
                          : userNotNotifInvoices
                        : ""
                    }
                  >
                    <Grid container spacing={6}>
                      {create && receivedCompanyID === 0 ? (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant="h6" component="span">
                              Company
                            </Typography>
                            <Underline />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                          ></Grid>
                          {/*Company*/}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                              id="combo-box-demo"
                              componentName="Field5"
                              options={companies}
                              value={company ? company : ""}
                              getOptionLabel={(option) =>
                                option.name ? option.name : ""
                              }
                              onChange={(event, value) => {
                                if (create) {
                                  setCompany(value);
                                  if (value && value !== null) {
                                    fetchCompanyRoles(value.companyID);
                                  } else {
                                    setRoles([]);
                                  }
                                  if (companyError) setCompanyError(false);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              onBlur={() => {
                                if (create) {
                                  if (username !== "" || username !== null)
                                    getDeletedUserForRecreation(username);
                                }
                              }}
                              disabled={create ? false : true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Field5"
                                  label="Company Name"
                                  fullWidth
                                  size="small"
                                  required
                                  inputRef={compNameRef}
                                  onInvalid={(e) => {
                                    window.scrollTo(0, e.target.scrollHeight);
                                  }}
                                  type="text"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                  error={companyError}
                                  helperText={
                                    companyError ? "Company field is empty" : ""
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                          ></Grid>
                        </>
                      ) : null}
                      {create === false &&
                      isLevvenAdmin &&
                      company !== null &&
                      company !== undefined &&
                      company.type !== "L" ? (
                        <>
                          {" "}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant="h6" component="span">
                              Connection to NAV
                            </Typography>
                            <Underline />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "right",
                            }}
                          >
                            {create ? null : (
                              <span
                                style={{
                                  padding: "5px 10px",
                                  width: "100px",
                                  borderRadius: "25px",
                                  textAlign: "center",
                                  backgroundColor:
                                    status === "P"
                                      ? "rgba(100, 76, 159, 0.2)"
                                      : status === "N"
                                      ? "rgba(255, 193, 7, 0.2)"
                                      : status === "A"
                                      ? "rgba(29, 148, 75, 0.2)"
                                      : "rgba(150, 150, 150, 0.3)",
                                  color:
                                    status === "P"
                                      ? "rgba(100, 76, 159, 1)"
                                      : status === "N"
                                      ? "rgba(255, 193, 7, 1)"
                                      : status === "A"
                                      ? "rgba(29, 148, 75, 1)"
                                      : "rgba(150, 150, 150, 1)",
                                }}
                              >
                                {userStatusCheck(status)}
                              </span>
                            )}
                          </Grid>
                          {/*Contact In Nav*/}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Autocomplete
                              id="combo-box-demo"
                              componentName="Field7"
                              options={[
                                ...contactsInNav,
                                {
                                  no: "",
                                  name: "Create Contact In Nav",
                                  sourceCountry: "",
                                  levvenClassification: "",
                                  createContact: true,
                                },
                              ]}
                              value={contactInNav ? contactInNav : ""}
                              getOptionLabel={(option) =>
                                option
                                  ? option.no +
                                    (option.no ? ", " : "") +
                                    option.name +
                                    (option.sourceCountry
                                      ? " (" + option.sourceCountry + ")"
                                      : "") +
                                    (option.levvenClassification &&
                                    option.levvenClassification === "B"
                                      ? "*"
                                      : "")
                                  : ""
                              }
                              onChange={(event, value) => {
                                if (create || adminManage) {
                                  setContactInNav(value);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                disableLinkUser
                                  ? true
                                  : create || adminManage
                                  ? false
                                  : true
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="Field7"
                                  label="Contact In Nav"
                                  fullWidth
                                  size="small"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "right",
                            }}
                          ></Grid>
                        </>
                      ) : null}

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Contact
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        {create === true ||
                        (create === false &&
                          isLevvenAdmin === true &&
                          company !== null &&
                          company !== undefined &&
                          company.type !== "L") ? null : (
                          <span
                            style={{
                              padding: "5px 10px",
                              width: "100px",
                              borderRadius: "25px",
                              textAlign: "center",
                              backgroundColor:
                                status === "P"
                                  ? "rgba(100, 76, 159, 0.2)"
                                  : status === "N"
                                  ? "rgba(255, 193, 7, 0.2)"
                                  : status === "A"
                                  ? "rgba(29, 148, 75, 0.2)"
                                  : "rgba(150, 150, 150, 0.3)",
                              color:
                                status === "P"
                                  ? "rgba(100, 76, 159, 1)"
                                  : status === "N"
                                  ? "rgba(255, 193, 7, 1)"
                                  : status === "A"
                                  ? "rgba(29, 148, 75, 1)"
                                  : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {userStatusCheck(status)}
                          </span>
                        )}
                      </Grid>
                      {/*First name input*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="First Name"
                          required
                          fullWidth
                          value={firstname}
                          error={firstnameError}
                          helperText={
                            firstnameError ? "First Name field is empty" : ""
                          }
                          onChange={(e) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              setFirstname(e.target.value);
                              if (firstnameError) setFirstnameError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                          inputProps={{
                            maxLength: 100,
                            autoComplete: "new-password",
                          }}
                        />
                      </Grid>
                      {/*Last name input*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Last Name"
                          required
                          fullWidth
                          value={lastname}
                          error={lastnameError}
                          helperText={
                            lastnameError ? "Last Name field is empty" : ""
                          }
                          onChange={(e) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              setLastname(e.target.value);
                              if (lastnameError) setLastnameError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                          inputProps={{
                            maxLength: 100,
                            autoComplete: "new-password",
                          }}
                        />
                      </Grid>
                      {/*Username input*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Email Address"
                          required
                          fullWidth
                          value={username}
                          error={usernameError || notValidUsername}
                          disabled={create ? false : true}
                          helperText={
                            usernameError ? "Username field is empty" : ""
                          }
                          onChange={(e) => {
                            if (create) {
                              setReCreateUser(false);
                              setUserIDTmp(0);
                              setUsername(e.target.value);
                              if (usernameError) setUsernameError(false);
                              if (validator.isEmail(username))
                                setNotValidUsername(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          onBlur={() => {
                            if (create && reCreateUser === false)
                              getDeletedUserForRecreation(username);
                          }}
                          inputProps={{
                            maxLength: 100,
                            autoComplete: "new-password",
                          }}
                        />
                      </Grid>
                      {/*Job title*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Job Title"
                          fullWidth
                          required
                          value={jobTitle}
                          error={jobTitleError}
                          helperText={
                            jobTitleError ? "Job Title field is empty" : ""
                          }
                          onChange={(e) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              setJobTitle(e.target.value);
                              if (jobTitleError) setJobTitleError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                          inputProps={{
                            maxLength: 100,
                            autoComplete: "new-password",
                          }}
                        />
                      </Grid>
                      {/*Phone number*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Phone Number"
                          fullWidth
                          required
                          type="text"
                          value={phoneNumber}
                          error={phoneNumberError || validPhoneError}
                          placeholder="999-999-9999"
                          helperText={
                            phoneNumberError
                              ? "Phone Number field is empty"
                              : validPhoneError
                              ? "Invalid Phone Number"
                              : ""
                          }
                          onKeyPress={(event) => {
                            if (phoneChecking.indexOf(event.key) === -1) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              if (e.target.value.length < 13) {
                                if (e.target.value < 0) {
                                  var tmp = e.target.value * -1;
                                  editPhoneFormat(tmp);
                                } else {
                                  editPhoneFormat(e.target.value);
                                }
                              }
                              if (phoneNumberError) setPhoneNumberError(false);
                              if (validPhoneError) setValidPhoneError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          inputProps={{
                            maxLength: 100,
                            autoComplete: "new-password",
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      {/*Employee job roles*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          multiple
                          id="size-small-outlined-multi"
                          size="small"
                          options={arrayOfJobRoles}
                          fullWidth
                          value={
                            employeeJobRoles !== (employeeJobRoles.length == 0)
                              ? employeeJobRoles
                              : ""
                          }
                          sx={{ maxWidth: "500px" }}
                          onChange={(event, value) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              setEmployeeJobRoles(value);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Employee Job Roles"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {/*Certified checkbox*/}
                      {company && company !== null && company?.type === "I" ? (
                        <>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={certified}
                                  onClick={() => {
                                    if (create || adminManage) {
                                      if (company?.type === "I") {
                                        setCertified(!certified);
                                        setDirty();
                                        setFormChanged(true);
                                        sessionStorage.setItem(
                                          "unsavedChanges",
                                          JSON.stringify(true)
                                        );
                                      }
                                    }
                                  }}
                                  disabled={
                                    (create || adminManage) &&
                                    company?.type === "I"
                                      ? false
                                      : true
                                  }
                                />
                              }
                              label="Completed Levven Certification Course?"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                          ></Grid>
                        </>
                      ) : null}

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" component="span">
                            Address
                          </Typography>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Tooltip arrow title="Copy From Company Address">
                              <IconButton
                                size="small"
                                disabled={
                                  company === null || company === undefined
                                }
                              >
                                {/**Copy Company Address */}
                                <Clipboard
                                  onClick={() => {
                                    setPromptCompanyAddressCopy(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                addressLevel === "AV11" ||
                                addressLevel === "AV12" ||
                                addressLevel === "AV13" ||
                                addressLevel === "AV14"
                                  ? "Address Partially Verified"
                                  : addressLevel === "AV21" ||
                                    addressLevel === "AV22" ||
                                    addressLevel === "AV23" ||
                                    addressLevel === "AV24" ||
                                    addressLevel === "AV25"
                                  ? "Address Fully Verified"
                                  : "Address Not Verified"
                              }
                            >
                              <span>
                                <IconButton disabled>
                                  <Compass
                                    color={
                                      addressLevel === "AV11" ||
                                      addressLevel === "AV12" ||
                                      addressLevel === "AV13" ||
                                      addressLevel === "AV14"
                                        ? "#FEBF11"
                                        : addressLevel === "AV21" ||
                                          addressLevel === "AV22" ||
                                          addressLevel === "AV23" ||
                                          addressLevel === "AV24" ||
                                          addressLevel === "AV25"
                                        ? "#98C848"
                                        : "#E63846"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>

                      {/*Country selector*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          componentName="Field1"
                          options={countries}
                          value={country ? country : {}}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          onChange={(event, value) => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              sameUser
                            ) {
                              setAddressChanged(true);
                              if (country?.description !== value?.description) {
                                setOfficeStateProvince();
                              }
                              setCountry(value);
                              if (value === null) setStateProvince([]);
                              if (countryError) setCountryError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin || sameUser
                              ? false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Field1"
                              label="Country"
                              fullWidth
                              size="small"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              error={countryError}
                              helperText={
                                countryError ? "Country field is empty" : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          autoSelect
                          options={suggestions.map(
                            (options) =>
                              options.address1 +
                              ", " +
                              options.city +
                              ", " +
                              options.stateProvince.stateProvinceCode +
                              " " +
                              options.zipPostCode
                          )}
                          value={addressValue}
                          disabled={
                            country === null ||
                            country === undefined ||
                            !(create || adminManage || companyAdmin || sameUser)
                          }
                          onChange={(event, newValue) => {
                            if (verifyAddress === true)
                              fetchAddresses(newValue);
                            if (addressError) setAddressError(false);
                            var index = suggestions.findIndex(
                              (x) =>
                                x.address1 +
                                  ", " +
                                  x.city +
                                  ", " +
                                  x.stateProvince.stateProvinceCode +
                                  " " +
                                  x.zipPostCode ===
                                newValue
                            );

                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (index !== -1) {
                              setAddressChanged(false);
                              setAddressPicked(suggestions[index]);

                              setAddressValue(
                                suggestions[index].address1 +
                                  ", " +
                                  suggestions[index].city +
                                  ", " +
                                  suggestions[index].stateProvince
                                    .stateProvinceCode +
                                  " " +
                                  suggestions[index].zipPostCode
                              );
                              setAddressVerified(true);
                            } else {
                              setAddressVerified(false);
                              setAddressPicked(null);
                              setAddressChanged(true);
                              setAddressValue(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address"
                              size="small"
                              required
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              error={addressError}
                              onChange={(newValue) => {
                                if (verifyAddress === true)
                                  fetchAddresses(newValue.target.value);
                              }}
                              helperText={
                                addressError
                                  ? "Address field is empty. Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                                  : "Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                              }
                              disabled={
                                country === null ||
                                country === undefined ||
                                !(
                                  create ||
                                  adminManage ||
                                  companyAdmin ||
                                  sameUser
                                )
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Permissions
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/*Role*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          componentName="Field5"
                          options={roles}
                          value={role ? role : ""}
                          noOptionsText={
                            !company ? "No Company Selected" : "No Options"
                          }
                          getOptionLabel={(option) =>
                            option.role
                              ? option.role.description
                              : option.description
                              ? option.description
                              : ""
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || companyAdmin) {
                              if (value) {
                                setRole(value.role);
                                setRolePermissionsDescription(
                                  value.rolePermissionsDescription
                                );
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                              if (roleError) setRoleError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin ? false : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Field5"
                              label="Role"
                              fullWidth
                              size="small"
                              required
                              error={roleError}
                              helperText={
                                roleError ? "Role field is empty" : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Notifications
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/*Notified on invoices*/}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" component="span">
                          Notified on invoices
                        </Typography>
                        <Switch
                          checked={invoices}
                          onChange={() => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              (sameUser && isALevvenAdmin)
                            ) {
                              setInvoices(!invoices);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create ||
                            adminManage ||
                            companyAdmin ||
                            (sameUser && isALevvenAdmin)
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      {/*Notified of shipments*/}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" component="span">
                          Notified of shipments
                        </Typography>
                        <Switch
                          checked={shipment}
                          onChange={() => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              (sameUser && isALevvenAdmin)
                            ) {
                              setShipment(!shipment);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create ||
                            adminManage ||
                            companyAdmin ||
                            (sameUser && isALevvenAdmin)
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      {/*Notified when a saved draft order is ready to be ordered*/}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" component="span">
                          Notify User When Saved Draft Ready to Order
                        </Typography>
                        <Switch
                          checked={savedDraftOrder}
                          onChange={() => {
                            if (
                              create ||
                              adminManage ||
                              companyAdmin ||
                              (sameUser && isALevvenAdmin)
                            ) {
                              setSavedDraftOrder((val) => !val);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={
                            create ||
                            adminManage ||
                            companyAdmin ||
                            (sameUser && isALevvenAdmin)
                              ? false
                              : true
                          }
                        />
                      </Grid>
                      {/*Notified when a new order is submited*/}
                      {company && company !== null && company?.type === "L" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body1" component="span">
                              Notified when a new order is submited
                            </Typography>
                            <Switch
                              checked={newOrder}
                              onChange={() => {
                                if (
                                  create ||
                                  adminManage ||
                                  companyAdmin ||
                                  (sameUser && isALevvenAdmin)
                                ) {
                                  setNewOrder(!newOrder);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create ||
                                adminManage ||
                                companyAdmin ||
                                (sameUser && isALevvenAdmin)
                                  ? false
                                  : true
                              }
                            />
                          </Grid>
                          {/*Notified when a new RMA is submitted*/}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body1" component="span">
                              Notified when a new RMA is submitted
                            </Typography>
                            <Switch
                              checked={newRMA}
                              onChange={() => {
                                if (
                                  create ||
                                  adminManage ||
                                  companyAdmin ||
                                  (sameUser && isALevvenAdmin)
                                ) {
                                  setNewRMA(!newRMA);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create ||
                                adminManage ||
                                companyAdmin ||
                                (sameUser && isALevvenAdmin)
                                  ? false
                                  : true
                              }
                            />
                          </Grid>
                        </>
                      ) : null}

                      {/*Save button*/}
                      {create || adminManage || sameUser || companyAdmin ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <CustomButton
                            type="submit"
                            loading={buttonLoading}
                            startIcon={<Save />}
                          >
                            Save
                          </CustomButton>
                        </Grid>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  sx={{
                    paddingLeft: "20px",
                    alignSelf: "flex-start",
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      {create && receivedCompanyID !== 0 ? null : (
                        <>
                          {" "}
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              component="span"
                            >
                              Company:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="caption" component="span">
                              {create
                                ? company
                                  ? company.name
                                  : ""
                                : user
                                ? user.user.company.name
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              component="span"
                            >
                              Company Type:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="caption" component="span">
                              {create
                                ? company
                                  ? companyTypeCheck(company.type)
                                  : ""
                                : user
                                ? companyTypeCheck(user.user.company.type)
                                : ""}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Creation Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {user ? convertUTC(user.user.creationDate) : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Created by:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {user
                            ? user.user.createdBy
                              ? user.user.createdBy.firstName +
                                " " +
                                user.user.createdBy.lastName
                              : ""
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modification Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {user ? convertUTC(user.user.modificationDate) : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modified By:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {user
                            ? user.user.modifiedBy
                              ? user.user.modifiedBy.firstName +
                                " " +
                                user.user.modifiedBy.lastName
                              : ""
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="subtitle2" component="span">
                          Permissions
                        </Typography>
                      </Grid>
                      {rolePermissionsDescription.map((string) => (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="caption" component="span">
                              {string}
                            </Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}
          {saveError !== "" ? (
            <AlertSnackBar
              message={saveError}
              handleClose={handleCloseAlertSnackbar}
              open={saveError !== ""}
            />
          ) : null}
        </Paper>

        {melissaResults.length !== 0 ? (
          <CustomModal openModal={melissaResults.length !== 0} width="60vw">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span">
                  Address Verification
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {melissaResults.map((description) => (
                  <>
                    <Typography variant="body1" component="span">
                      - {description}
                    </Typography>
                    <br />
                  </>
                ))}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body1" component="span">
                  <b>Address Found</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Address: </strong> {street}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Address 2: </strong> {street2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>City: </strong> {city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>State/Province: </strong>{" "}
                      {officeStateProvince?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Country: </strong> {country?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Zip/Post Code: </strong> {zipCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    toggle={() => {
                      setMelissaResults([]);
                      setAddressChanged(true);
                      setAddressVerificationLevel("");
                    }}
                  >
                    Edit Address
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    toggle={async (e) => {
                      setMelissaResults([]);
                      setAddressChanged(false);
                      // setAddressVerificationLevel("");
                      save(e, "");
                    }}
                    loading={buttonLoading}
                  >
                    Proceed
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        ) : null}

        {/**Popup for linking User to contact in nav */}
        {openContactInNavModal ? (
          <CustomModal openModal={openContactInNavModal} width="70vw">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span">
                  New User Matches an Existing Contact
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body1" component="span">
                  {username} may be a duplicate of an existing contact. To avoid
                  creating a duplicate, select their matching record from the
                  list below.
                  <br />
                  <br /> If no match is listed, click Finish to create your new
                  user.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <LinkUserToContactTable
                  rows={contactsInNav}
                  selected={contactInNav}
                  setSelected={setContactInNav}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    toggle={async (e) => {
                      setContactsInNav([]);
                      setContactInNav(null);
                      setOpenContactInNavModal(false);
                    }}
                    disable={selectedContactLoading}
                  >
                    Cancel
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    toggle={async (e) => {
                      if (contactInNav !== null && contactInNav !== undefined)
                        save(e, "", true, false);
                      else save(e, "", true, true);
                    }}
                    loading={selectedContactLoading}
                  >
                    {contactInNav !== null && contactInNav !== undefined
                      ? "Finish / Link to Selected Contact"
                      : "Finish"}
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        ) : null}
        <InfoSnackbar
          open={melissaInfoSnackbar && melissaCreditsDialog === false}
          message={melissaMessageSnackbar}
          severity={melissaSeveritySnackbar}
          handleClose={handleSnackBarClose}
        />
      </>
    );
  }
);

export default UserForm;
