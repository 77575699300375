import { useEffect, useState, Fragment, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  Grid,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import update from "immutability-helper";
import ProductTakeoff from "../ProductTakeoff/ProductTakeoff";
import { useLocation, useHistory } from "react-router-dom";
import { takeoffServices } from "../../services/takeoff.services";
import { productServices } from "../../services/product.service";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import ChangeProductsTable from "./ChangeProductsTable";
import OrderTypeStep from "./OrderTypeStep";
import CompanyStep from "./CompanyStep";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import ProjectDetailStep from "./ProjectDetailsStep";
import PurchasingDetailStep from "./PurchasingDetailsStep";
import OrderProductsModification from "./OrderProductsModification";
import { orderQuantitiesServices } from "../../services/orderQuantities.service";
import { accountServices } from "../../services/account.services";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import { companyServices } from "../../services/company.services";
import TakeoffStep from "./TakeoffStep";
import ModificationQuestion from "./ModificationQuestion";
import OverrideSwitchColorStep from "./OverrideSwitchColorStep";
import { orderServices } from "../../services/orders.service";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import CustomModal from "../CustomModal/CustomModal";
import ShipWithOtherOrdersStep from "./ShipWithOtherOrdersStep";
import { melissaServices } from "../../services/melissa.services";
import { getMelissaLog } from "../../helpers/melissa-api-logs";
import moment from "moment";
import { systemDataServices } from "../../services/systemData.services";
import OrderReviewPage from "./OrderReviewPage";
import EditUnitPricesStep from "./EditUnitPricesStep/EditUnitPricesStep";
import SnackBar from "../SnackBar/SnackBar";
import { useLogs } from "../../hooks/useLogs";
import InfoSnackbar from "../InfoSnackbar/InfoSnackbar";
import { countryServices } from "../../services/country.services";
import NotificationOrderAlert from "../NotificationOrderAlert";

/*Levven-Project Pack- Step #*/
const lPpStep1 = [{ title: "Company" }];
const lPpStep12 = [{ title: "Company" }, { title: "OrderType" }];
const lPpStep123 = [
  { title: "Company" },
  { title: "Order Type" },
  { title: "Takeoff" },
];
const lPpStep1234 = [
  { title: "Company" },
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },
];
const lPpStep12345 = [
  { title: "Company" },
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },
  // { title: "Purchasing Details" },
  { title: "Modification Question" },
];
const lPpStep12345678 = [
  { title: "Company" },
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },
  { title: "Modification Question" },
  { title: "Products Page" },
  { title: "Purchasing Details" },
  { title: "Order Review" },
];

/*Not Levven-Project Pack-Step #*/
const nlPpStep1 = [{ title: "Order Type" }];
const nlPpStep12 = [{ title: "Order Type" }, { title: "Takeoff" }];
const nlPpStep123 = [
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },
];
const nlPpStep1234 = [
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },
  { title: "Modification Question" },
];
const nlPpStep1234567 = [
  { title: "Order Type" },
  { title: "Takeoff" },
  { title: "Project Details" },

  { title: "Modification Question" },
  { title: "Products Page" },
  { title: "Purchasing Details" },
  { title: "Order Review" },
];

/*Levven-Bulk Inventory-Step#*/
const lSiStep1 = [{ title: "Company" }];
const lSiStep12 = [{ title: "Company" }, { title: "Order Type" }];
const lSiStep123 = [
  { title: "Company" },
  { title: "Order Type" },
  { title: "Purchasing Details" },
  { title: "Order Products" },
  { title: "Order Review" },
];

/*Builder Installer-Bulk Inventory-Step#*/
const biSiStep1 = [{ title: "Order Type" }];
const biSiStep12 = [
  { title: "Order Type" },
  { title: "Purchasing Details" },
  { title: "Order Products" },
  { title: "Order Review" },
];

/*Reseller-Bulk Inventory-Step#*/
const rSiStep1 = [
  { title: "Purchasing Details" },
  { title: "Order Products" },
  { title: "Order Review" },
];

export default function OrderCreationStepper({
  takeoffObj = null,
  builderObj = null,
  fromTakeoff = false,
  windowWidth = 0,
  openDrawerMenu,
  isRMA = false,
}) {
  //   const location = useLocation();
  const history = useHistory();

  const [purchasers, setPurchasers] = useState([]);
  const [takeoffs, setTakeoffs] = useState([]);
  const [installerContacts, setInstallerContacts] = useState([]);
  const [unshippedOrders, setUnshippedOrders] = useState([]);
  const [billingCompanies, setBillingCompanies] = useState([]);
  const [companiesUsers, setCompaniesUsers] = useState([]);
  // send notification alert
  const [alertNotification, setAlertNotification] = useState(false);
  /*Order ID in Stepper*/
  const [orderID, setOrderID] = useState(0);
  const [order, setOrder] = useState();

  /*Company Step*/
  const [company, setCompany] = useState();
  const [companyError, setCompanyError] = useState(false);

  /*Purchasing Email*/
  const [purchaserEmail, setPurchaserEmail] = useState("");
  const [purchaserEmailError, setPurchaserEmailError] = useState(false);

  /*Order Type*/
  const [orderType, setOrderType] = useState("");
  const [orderTypeError, setOrderTypeError] = useState(false);

  /*Takeoff*/
  const [takeoff, setTakeoff] = useState();
  const [takeoffError, setTakeoffError] = useState(false);

  /*Agreement*/
  const [agreement, setAgreement] = useState();

  /*InstallerContact*/
  const [installerContact, setInstallerContact] = useState();

  //used for snackbar display
  const [success, setSuccess] = useState(false);

  //used for snackbar message
  const [message, setMessage] = useState("");

  const [maxQuantity, setMaxQuantity] = useState(0);

  /*Special Negotiated Price*/
  const [specialNegotiated, setSpecialNegotiated] = useState(false);

  /*Purchase Order Number*/
  const [purchaseOrderNb, setPurchaseOrderNb] = useState("");
  const [purchaseOrderNbError, setPurchaseOrderNbError] = useState(false);

  /*Requested Delivery Date*/
  const [requestedDate, setRequestedDate] = useState("");
  const [requestedDateError, setRequestedDateError] = useState(false);

  /*Requested Delivery Date*/
  const [forModelHome, setForModelHome] = useState(false);

  /*Override Default Switch Color?*/
  const [overDefSwitchColor, setOverDefSwitchColor] = useState();
  const [defSwitch, setDefSwitch] = useState();

  /**get complementary project pack permission */
  const canCreateCompPP = useSelector((state) =>
    state.user.user ? state.user.user.user.role.canCreateCompPP : false
  );

  /**Holds user that can submit order for notification */
  const [receiver, setReceiver] = useState(null);

  /*Ship to Address*/
  const [shipToAddress, setShipToAddress] = useState(null);
  const [shipToAddressError, setShipToAddressError] = useState(false);

  /*State to save the ID of last added shipping address*/
  const [toUpdateShipAddID, setToUpdateShipAddID] = useState(0);

  // Billing Address
  const [billingAddress, setBillingAddress] = useState();
  const [billingAddressError, setBillingAddressError] = useState(false);

  // Billing Contact
  const [billingContact, setBillingContact] = useState([]);

  //for loading when fetching order quantities
  const [orderQuantitiesLoading, setOrderQuantitiesLoading] = useState(false);

  /*Shipping method*/
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingMethodError, setShippingMethodError] = useState(false);

  const [valueToAdd, setValueToAdd] = useState(null);

  /**State used to handle the display of the dialog prompting the user if he wishes to send email on order Save Draft */
  const [displayNotifAlert, setDisplayNotifAlert] = useState(false);

  /*Ship with other orders*/
  const [otherOrder, setOtherOrder] = useState([]);
  const [otherOrderError, setOtherOrderError] = useState(false);

  /*Lot Number*/
  const [lotNbr, setLotNbr] = useState("");
  const [lotNbrError, setLotNbrError] = useState(false);

  /*Lot Number*/
  const [jobName, setJobName] = useState("");
  const [jobNameError, setJobNameError] = useState(false);

  /*Lot Number*/
  const [jobNbr, setJobNbr] = useState("");
  const [jobNbrError, setJobNbrError] = useState(false);

  /*Default Controller Price*/
  const [fillQtyBeforeSubmit, setFillQtyBeforeSubmit] = useState(false);
  const [
    displayedFillQtyBeforeSubmitWarning,
    setDisplayedFillQtyBeforeSubmitWarning,
  ] = useState(false);

  /**holds order fulfillment threshold */
  const [orderFulfillmentThreshold, setOrderFulfillmentThreshold] = useState(0);

  //holds currency of country of purchaser's company
  const [currency2, setCurrency2] = useState("");

  /*Installation Country*/
  const [instCountry, setInstCountry] = useState();
  const [instCountryError, setInstCountryError] = useState(false);

  /*Installation State/Province*/
  const [instStateProvince, setInstStateProvince] = useState();
  const [instStateProvinceError, setInstStateProvinceError] = useState(false);

  /*Installation City*/
  const [instCity, setInstCity] = useState("");
  const [instCityError, setInstCityError] = useState(false);

  //holds addresses returned for autocomplete of addresses
  const [suggestions, setSuggestions] = useState([]);

  const [addressChange, setAddressChange] = useState("");

  //holds autocomplete address

  const [addressValue, setAddressValue] = useState("");
  const [addressPicked, setAddressPicked] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);

  const [melissaInfoSnackbar, setMelissaInfoSnackbar] = useState(false);
  const [melissaMessageSnackbar, setMelissaMessageSnackbar] = useState(false);
  const [melissaSeveritySnackbar, setMelissaSeveritySnackbar] = useState(false);

  const [addressError, setAddressError] = useState(false);

  //hook to call log function
  const [
    logLoading,
    logError,
    handleLogCloseError,
    logUnsaveLoading,
    addLogAndLogDetail,
  ] = useLogs();

  /**For loading of buttons in modification page */
  const [changeSwitchLoading, setChangeSwitchLoading] = useState(false);
  const [addSwitchLoading, setAddSwitchLoading] = useState(false);
  const [changeSwitchColorLoading, setChangeSwitchColorLoading] =
    useState(false);

  /**Holds address to log */
  const [AddressToLog, setAddressToLog] = useState("");

  /*Installation Zip Code*/
  const [instZipCode, setInstZipCode] = useState("");
  const [instZipCodeError, setInstZipCodeError] = useState(false);

  /*Installation Street*/
  const [instStreet, setInstStreet] = useState("");
  const [instStreetError, setInstStreetError] = useState(false);

  /*Installation Street2*/
  const [instStreet2, setInstStreet2] = useState("");

  /*Ship With Other Goods*/
  const [shipWithOtherGoods, setShipWithOtherGoods] = useState(false);

  /**is complementary project pack */
  const [isCompPP, setIsCompPP] = useState(false);

  /*Bulk Inventory Warning*/
  const [shelfInventoryWarning, setShelfInventoryWarning] = useState(false);

  //to call the save after all new values have been set
  const [melissaSave, setMelissaSave] = useState(false);

  /*Error Handling*/
  const [saveError, setSaveError] = useState("");

  /**Holds if we are placing order from Use Base Plan Quantities or Make Changes/Additions */
  const [submitSource, setSubmitSource] = useState("");

  /*Modal to submit order*/
  const [canSubmitModal, setCanSubmitModal] = useState(false);
  const [canSubmitUsers, setCanSubmitUsers] = useState([]);
  const [chosenCanSubmitUser, setChosenCanSubmitUser] = useState();
  const [chosenCanSubmitUserError, setChosenCanSubmitUserError] =
    useState(false);

  /*Address Checking section*/
  const [addressChanged, setAddressChanged] = useState(false);
  const [melissaResults, setMelissaResults] = useState([]);
  const [formattedAddress, setFormattedAddress] = useState("");

  /*Address Verification Code*/
  const [addressVerificationLevel, setAddressVerificationLevel] = useState("");

  /*Open and Close the alert dialog when submitting an order*/
  const [canSubmitAlert, setCanSubmitAlert] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  /*Flag to open and close the Shipping Address modal*/
  const [shipModalOpen, setShipModalOpen] = useState(false);
  const [shipModalUpdatelOpen, setShipModalUpdateOpen] = useState(false);

  /*Modal to warn the user if the requested date is less than 2 weeks in the future*/
  const [dateAlertDialogOpen, setDateAlertDialogOpen] = useState(false);

  /*Modal to ask the user if he wants to submit the order directly*/
  const [placeOrderModalOpen, setPlaceOrderModalOpen] = useState(false);

  //holds selected rows in unshipped orders table
  const [selected, setSelected] = useState([]);

  //for total price of orders
  const [orderTotal, setOrderTotal] = useState(0);

  /*value for max value of all orders possible*/
  const [maxOrderTotal, setMaxOrderTotal] = useState(0);

  /**If values have been added to the change a switch but not added with the plus, we add them */
  /**then change this state so we can call function that uses the updated version of the rows array */
  const [addedValues, setAddedValues] = useState(false);

  /*so useEffect doesnt trigger on first render*/
  const [firstRendered, setFirstRendered] = useState(false);

  //to check if any change has been done to the Edit Unit Price
  const [formChanged, setFormChanged] = useState(false);

  const [errorOnFinish, setErrorOnFinish] = useState(false);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const [displayEditUnitprice, setDisplayEditUnitPrice] = useState(false);

  const [displayFinalPlaceOrderModal, setDisplayFinalPLaceOrderModal] =
    useState(false);

  /*------------------Holds Values for Order Quantities-----------------*/
  const [totalSwitches, setTotalSwitches] = useState(0);
  const [totalWallPlates, setTotalWallPlates] = useState(0);
  const [totalQuickMount, setTotalQuickMount] = useState(0);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  var [rows2, setRows2] = useState([]);
  const [steps2, setSteps2] = useState([]);

  const [showWarning, setShowWarning] = useState(false);
  //for save button lgetLevvenQPositionoading
  const [buttonLoading, setButtonLoading] = useState(false);
  const [opened, setOpened] = useState([]);
  const [defUPC, setDefUPC] = useState(0);
  const [openedAccordion, setOpenedAccordion] = useState([]);
  const [specialNegotiated2, setSpecialNegotiated2] = useState(false);
  const [defaultPriceRanges, setDefaultPriceRanges] = useState([]);
  const [defaultController, setDefaultController] = useState({});
  const [maxDimmer, setMaxDimmer] = useState(0);
  const [currency, setCurrency] = useState("");
  const [orderIsCompPP, setOrderIsCompPP] = useState(false);
  const [orderType2, setOrderType2] = useState("");
  const [hasDefaultController, setHasDefaultController] = useState([]);
  const [levvenQ, setLevvenQ] = useState({});
  const [continueToPlaceOrder, setContinueToPlaceOrder] = useState(false);

  //Melissa Creadits Error Dialog
  //to display the dialog in case no credits or melissa side error, we give the User to option to continue
  const [melissaCreditsDialog, setMelissaCreaditsDialog] = useState(false);

  /**To display change and add Switches & Assemblies and change switch color*/
  const [displayChangeSwitches, setDisplayChangeSwitches] = useState(false);
  const [displayAddSwitches, setDisplayAddSwitches] = useState(false);
  const [displayChangeSwitchColor, setDisplayChangeSwitchColor] =
    useState(false);
  const [oldSwitchColor, setOldSwitchColor] = useState(null);
  const [switchColors, setSwitchColors] = useState([]);
  const [submitChangeProducts, setSubmitChangeProducts] = useState(false);

  const [agreementDefaultSwitch, setAgreementDefaultSwitch] = useState(null);

  const [shippingAddressIDs, setShippingAddressIDs] = useState([]);

  const [
    displayAddressVerificationWarning,
    setDisplayAddressVerificationWarning,
  ] = useState(false);

  /*Get agreement permission from redux*/
  const currentCompanyID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );

  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  /*Get current company type from redux*/
  const currentCompanyIsParent = useSelector((state) =>
    state.user.user ? state.user.user.user.company.isParent : false
  );
  const notifSavedDraftReadyToOrder = useSelector((state) =>
    currentCompanyType !== "L"
      ? state.user.user
        ? state.user.user.user.notifSavedDraftReadyToOrder
        : false
      : purchaserEmail && purchaserEmail !== null
      ? purchaserEmail.notifSavedDraftReadyToOrder
      : false
  );

  /**Get User Role */
  const userRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  /*Get current user from Redux*/
  const currentUser = useSelector((state) =>
    state.user.user ? state.user.user.user : {}
  );

  /*Get current user ID from Redux*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : 0
  );

  const currentUsername = useSelector((state) =>
    state.user.user ? state.user.user.user.userName : ""
  );

  const submitOrderPermission = useSelector((state) =>
    state.user.user
      ? isRMA === false
        ? state.user.user.user.role.submitOrder
        : state.user.user.user.role.submitRMA
      : false
  );

  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);

  /*Fetch available countries*/
  const fetchCountries = () => {
    countryServices
      .getCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.message);
      });
  };

  /*Fetch State/Province depending on the chosen country*/
  const fetchStateProvince = (countryID) => {
    countryServices
      .getStateProvince(countryID)
      .then((response) => {
        setStateProvinces(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.message);
      });
  };

  /*Initial useEffect*/
  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
    fetchCountries();
  }, []);

  /*Fetch states/provinces everytime the country changes*/
  useEffect(() => {
    if (instCountry && instCountry !== null)
      fetchStateProvince(instCountry.countryID);
  }, [instCountry]);

  useEffect(() => {
    if (submitChangeProducts === true) {
      submitChangeAndAddValues(true, false);
    }
  }, [submitChangeProducts]);

  const [minimumRequestedDeliveryDate, setMinimumRequestedDeliveryDate] =
    useState(null);

  /**We are fetching the minimum requested delivery date day possible */
  const fetchMinimumRequestedDeliveryDate = async () => {
    await systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        var minDaysToMilliseconds =
          parseInt(response.data.orderFulfillmentThreshold) *
          24 *
          60 *
          60 *
          1000;
        setOrderFulfillmentThreshold(response.data.orderFulfillmentThreshold);
        setMinimumRequestedDeliveryDate(minDaysToMilliseconds);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
      });
  };

  useEffect(() => {
    fetchMinimumRequestedDeliveryDate();
  }, []);

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMelissaInfoSnackbar(false);
  };

  const setValues = () => {
    setCompany(builderObj);
    setTakeoff(takeoffObj);
  };

  useEffect(() => {
    if (submitSource === "Make Changes/Additions") {
      save("", false, false, true);
    }
  }, [submitSource]);

  useEffect(() => {
    if (melissaSave === true) {
      validateUserAddressDuplicate();
    }
  }, [melissaSave]);

  useEffect(() => {
    /**Call function that checks for errors after adding value from last row in change a switch table */
    /**But dont enter useEffect on first render */
    if (
      ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
        orderType === "Project Pack for Home" &&
        activeStep === 6) ||
      (currentCompanyType !== "L" &&
        currentCompanyIsParent !== true &&
        orderType === "Project Pack for Home" &&
        activeStep === 5)
    ) {
      checkForErrorFirst();
    }
  }, [addedValues]);

  useEffect(() => {
    if (builderObj !== null && takeoffObj !== null) {
      setValues();
    }
  });

  const fetchAddresses = (address) => {
    if (verifyAddress === true && instCountry !== null) {
      melissaServices
        .getAddressLookup({
          Address: address,
          Country: instCountry.countryCode,
        })
        .then((response) => {
          setSuggestions(response.data.addresses);
        })
        .catch((error) => {
          const logDetail = {
            Status: "Melissa Error",
            Description: `Address Autocomplete fail, address sent:{Country:${instCountry?.description}, Address:${address}}`,
            AdditionalInfo: error?.response?.data?.error?.error,
            LogDetailTimeStamp: new Date().toISOString(),
          };

          addLogAndLogDetail(
            "Melissa",
            "Melissa Address Autocomplete",
            new Date().toISOString(),
            new Date().toISOString(),
            [logDetail],
            "onError"
          );
        });
    }
  };

  /*Fetch Switch Colors*/
  const fetchSwitchColors = () => {
    setSwitchColors([]);
    setChangeSwitchColorLoading(true);
    systemDataServices
      .getAllSwitchColor()
      .then((response) => {
        setSwitchColors(response.data);
        setChangeSwitchColorLoading(false);
        setOldSwitchColor(overDefSwitchColor);
        setDisplayAddSwitches(false);
        setDisplayChangeSwitches(false);
        setDisplayChangeSwitchColor(true);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setChangeSwitchColorLoading(false);
      });
  };

  //holds the step we are on
  const [activeStep, setActiveStep] = useState(0);

  //makes page load while data is being fetched
  const [pageLoad, setPageLoad] = useState(false);

  //holds the error if any while fetching data
  const [error, setError] = useState("");

  //makes finish button load while data is being sent
  const [loading, setLoading] = useState(false);

  //hold the data to be displayed in the page
  const [steps, setSteps] = useState([
    { title: "Company" },
    { title: "Order Type" },
    { title: "Takeoff" },
    { title: "Any Changes" },
  ]);
  var [rows, setRows] = useState([]);

  const [arrayFrom, setArrayFrom] = useState([]);

  const [arrayTo, setArrayTo] = useState([]);

  const [arrayFrom2, setArrayFrom2] = useState([]);

  const [arrayTo2, setArrayTo2] = useState([]);

  const [selectedFrom, setSelectedFrom] = useState(null);
  const [selectedTo, setSelectedTo] = useState(null);
  const [selectedFrom2, setSelectedFrom2] = useState(null);
  const [selectedTo2, setSelectedTo2] = useState(null);

  //holds the data for the Add switches and Assembly table
  const [rowsSwitchAdd, setRowsSwitchAdd] = useState([]);
  const [rowsAssemblyAdd, setRowsAssemblyAdd] = useState([]);

  /*To Check if we must make an address verification*/
  const [verifyAddress, setVerifyAddress] = useState(false);

  /*Initial useEffect*/
  useEffect(() => {
    if (currentCompanyType !== "L" && currentCompanyIsParent !== true)
      fetchCompanyData();
  }, []);

  useEffect(() => {
    fetchVerifyAddress();
  }, []);

  useEffect(() => {
    if (
      (activeStep === 4 &&
        (currentCompanyType === "L" || currentCompanyIsParent === true) &&
        orderType === "Project Pack for Home") ||
      (activeStep === 3 &&
        currentCompanyIsParent != true &&
        (currentCompanyType === "B" || currentCompanyType === "I") &&
        orderType === "Project Pack for Home")
    ) {
      setSubmitSource("");
    }
  }, [activeStep]);

  useEffect(() => {
    if (canSubmitModal) fetchCanSubmitUsers();
  }, [canSubmitModal]);

  useEffect(() => {
    console.log("order type", orderID, orderType);
    if (
      (orderID === 0 &&
        activeStep === 1 &&
        (currentCompanyType === "L" || currentCompanyIsParent === true)) ||
      (orderID === 0 &&
        activeStep === 0 &&
        currentCompanyIsParent !== true &&
        (currentCompanyType === "B" || currentCompanyType === "I"))
    ) {
      if (orderType !== "") {
        handleNext();
      }
    }
  }, [orderType]);

  useEffect(() => {
    console.log("changeStepsArrayDynamically");
    changeStepsArrayDynamically();
  }, [
    company,
    orderType,
    lotNbr,
    jobNbr,
    jobName,
    instStreet,
    instCity,
    instCountry,
    instStateProvince,
    instZipCode,
    addressValue,
    addressChange,
    // shipToAddress,
    shippingMethod,
    takeoff,
    overDefSwitchColor,
    purchaserEmail,
  ]);
  console.log(steps);
  useEffect(() => {
    /* If we are coming from the Takeoff, it means that we want an order related to a takeoff so we want the order type to be project pack for home */
    if (fromTakeoff) {
      setOrderType("Project Pack for Home");
    } else if (isRMA) {
      setOrderType("Bulk Inventory");
    }
  }, []);

  /*Everytime the company changes, values and dropdowns options change*/
  useEffect(() => {
    if (company && company !== null) {
      fetchCompanyUsers(company.companyID);
      //fetchUnshippedOrder();
      fetchCompanybillingCompanies();
      fetchRelatedUserToCompany();
      setShippingMethod(shipMethodCheckReversed(company.shippingMethod));
      if (company.type !== "R") fetchTakeoffs();
      if (company?.type === "R") setOrderType("Bulk Inventory");
      setInstCountry(company.country);
    } else {
      setShippingMethod("Prepaid and charge");
    }
  }, [company]);

  /*Everytime the agreement changes, values and dropdowns options changes*/
  useEffect(() => {
    if (agreement && agreement !== null) {
      if (company?.type === "B") fetchInstallerContacts();
      fetchDefSwitch();
      setOverDefSwitchColor(agreement.defaultSwitchColor);
    }
  }, [agreement, takeoff]);

  /*Everytime the override default switch color changes, the default switch changes*/
  useEffect(() => {
    if (
      (overDefSwitchColor && overDefSwitchColor !== null) ||
      currentCompanyType !== "L"
    ) {
      fetchDefSwitch();
    }
  }, [overDefSwitchColor]);

  const fetchVerifyAddress = async () => {
    setPageLoad(true);
    await systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        setVerifyAddress(response.data.applyAddressValidation);
        setPageLoad(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  const getProductsToChangeFrom = async (orderID) => {
    setArrayFrom([]);
    setArrayTo([]);
    setArrayFrom2([]);
    setArrayTo2([]);
    setValueToAdd(null);
    setRows([]);
    setSelectedFrom(null);
    setSelectedTo(null);
    setSelectedFrom2(null);
    setSelectedTo2(null);
    setChangeSwitchLoading(true);
    await orderQuantitiesServices
      .getListOfProductsToBeChangedForOrderCreation({
        OrderID: orderID,
      })
      .then((response) => {
        setArrayFrom(response.data.from);
        setArrayFrom2(response.data.from);
        setArrayTo(response.data.to);
        setArrayTo2(response.data.toSwitchColorAndStyle);
        setMaxQuantity(response.data.maxQuantity);
        setAgreementDefaultSwitch({
          style: response.data.defaultSwitchStyle,
          color: response.data.defaultSwitchColor,
        });
        setDisplayAddSwitches(false);
        setOldSwitchColor(overDefSwitchColor);
        setDisplayChangeSwitchColor(false);
        setDisplayChangeSwitches(true);
        setChangeSwitchLoading(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setChangeSwitchLoading(false);
      });
  };

  const [errorArray, setErrorArray] = useState([]);

  //holds error if any while saving data
  const [createError, setCreateError] = useState("");

  const fetchCanSubmitUsers = () => {
    //function to fetch users that can subimt order
    accountServices
      .getAllUsersHavingSubmitOrderTrueByCompanyID(company?.companyID)
      .then((response) => {
        setCanSubmitUsers(response.data);
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };

  /*Save Changes*/
  const save = (
    event,
    submit = false,
    continueToPlaceOrder = false,
    goNext = false,
    sendNotif = false,
    sendNotif24h = false
  ) => {
    if (event) event.preventDefault();
    if (
      continueToPlaceOrder === false &&
      submit === false &&
      goNext === false &&
      notifSavedDraftReadyToOrder === false &&
      displayNotifAlert === false
    ) {
      setDisplayNotifAlert(true);
      return;
    }

    if (displayNotifAlert === true) {
      setDisplayNotifAlert(false);
    }

    if (submit) setSubmitLoading(true);
    else setLoading(true);

    if (submit) {
      if (
        requestedDate === "" ||
        requestedDate === null ||
        requestedDate === "Invalid Date"
      ) {
        setSaveError(
          `Invalid ${
            isRMA === false ? "Requested Delivery Date" : "Delivery Date"
          }`
        );
        setSubmitLoading(false);
        return;
      } else if (
        (shipToAddress === "" ||
          shipToAddress === null ||
          shipToAddress === undefined) &&
        shippingMethod !== "Local pickup"
      ) {
        setSaveError("Invalid Shipping Address");
        setSubmitLoading(false);
        return;
      } else if (purchaseOrderNb === "" || purchaseOrderNb === null) {
        setSaveError(
          `Invalid ${
            isRMA === false
              ? "Purchase Order Number"
              : "Service Case/Ticket Number"
          }`
        );
        setSubmitLoading(false);
        return;
      }
    }
    /*Conditions to check if there are any errors*/
    if (currentCompanyType === "L" && (!company || company === null))
      setCompanyError(true);
    if (orderType === "") setOrderTypeError(true);
    if (orderType === "Project Pack for Home" && (!takeoff || takeoff === null))
      setTakeoffError(true);
    if (!purchaserEmail || purchaserEmail === null)
      setPurchaserEmailError(true);
    if (orderType === "Project Pack for Home" && jobNbr === "")
      setJobNbrError(true);
    if (orderType === "Project Pack for Home" && jobName === "")
      setJobNameError(true);
    if (orderType === "Project Pack for Home" && lotNbr === "")
      setLotNbrError(true);
    if (
      orderType === "Project Pack for Home" &&
      (!instCountry || instCountry === null)
    )
      setInstCountryError(true);

    if (
      orderType === "Project Pack for Home" &&
      (!addressValue || addressValue === null || addressValue === "")
    )
      setInstCountryError(true);

    // if (!shipToAddress || shipToAddress === null) setShipToAddressError(true);
    if (shippingMethod === "" || shippingMethod === null)
      setShippingMethodError(true);
    if (!billingAddress || billingAddress === null)
      setBillingAddressError(true);

    const regex = new RegExp("^[0-9]{2}/[0-9]{2}/[0-9]{4}$");

    //function to check if the date format is correct
    if (requestedDate !== null && requestedDate !== "") {
      if (regex.test(requestedDate) === false) {
        setRequestedDateError("Invalid Date Format");
      } else {
        const searchDate = moment(requestedDate).isValid()
          ? moment(requestedDate).format("MM/DD/YYYY")
          : "Invalid Date Format";

        if (searchDate === "Invalid Date Format") {
          setRequestedDateError("Invalid Date Format");
        }
      }
    }

    if (orderType === "Project Pack for Home") {
      if (instCountry.description === "" || instCountry.description === null) {
        if (submit) setSubmitLoading(false);
        else setLoading(false);
        setDateAlertDialogOpen(false);
        setCanSubmitAlert(false);
        setCanSubmitModal(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      }

      if (
        (requestedDate !== null &&
          requestedDate !== "" &&
          (regex.test(requestedDate) === false ||
            moment(requestedDate).isValid() === false)) ||
        (currentCompanyType === "L" && (!company || company === null)) ||
        orderType === "" ||
        (orderType === "Project Pack for Home" &&
          (!takeoff || takeoff === null)) ||
        !purchaserEmail ||
        purchaserEmail === null ||
        (orderType === "Project Pack for Home" && jobNbr === "") ||
        (orderType === "Project Pack for Home" && jobName === "") ||
        (orderType === "Project Pack for Home" && lotNbr === "") ||
        (orderType === "Project Pack for Home" &&
          (!instCountry || instCountry === null)) ||
        (orderType === "Project Pack for Home" &&
          (!addressValue || addressValue === null || addressValue === "")) ||
        // !shipToAddress ||
        // shipToAddress === null ||
        shippingMethod === "" ||
        shippingMethod === null ||
        !billingAddress ||
        billingAddress === null
      ) {
        /*If there is any error the function returns and highlights the field containing the error*/
        if (submit) setSubmitLoading(false);
        else setLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        setDateAlertDialogOpen(false);
        return;
      } else {
        /*If there is no errors*/
        /*If the page is in create mode*/

        let street1 = "";
        let street2 = "";
        let city = "";
        let state = "";
        let zip = "";

        if (addressPicked) {
          //address from autocomplete
          street1 = addressPicked.address1;
          street2 = addressPicked.address2;
          city = addressPicked.city;
          state =
            addressPicked.stateProvince &&
            addressPicked.stateProvince.stateProvinceCode
              ? addressPicked.stateProvince.stateProvinceCode
              : "N/A";
          zip = addressPicked.zipPostCode;
        } else if (verifyAddress) {
          //address not from autocomplete but verification on
          street1 =
            instStreet !== "" && instStreet !== null ? instStreet : "N/A";
          street2 =
            instStreet2 !== "" && instStreet2 !== null ? instStreet2 : "N/A";
          city = instCity !== "" && instCity !== null ? instCity : " N/A";

          state =
            instStateProvince && instStateProvince !== null
              ? instStateProvince.stateProvinceCode
              : "N/A";

          zip =
            instZipCode !== "" && instZipCode !== null ? instZipCode : "N/A";
        } else {
          //address not from autocomplete & verification off
          street1 = addressValue;
        }

        if (orderID === 0) {
          orderServices
            .createOrder({
              PurchaserID:
                currentCompanyType !== "L" && currentCompanyIsParent !== true
                  ? currentUserID
                  : purchaserEmail && purchaserEmail !== null
                  ? purchaserEmail.userID
                  : "",
              Type:
                orderType !== ""
                  ? orderType.charAt(0) === "B"
                    ? "S"
                    : orderType.charAt(0)
                  : "",
              TakeoffID: takeoff && takeoff !== null ? takeoff.takeoffID : "",
              InstallerContactID:
                installerContact && installerContact !== null
                  ? installerContact.userID
                  : "",
              PurchaseOrderNbr: purchaseOrderNb,
              RequestedDeliveryDate:
                requestedDate !== null &&
                requestedDate !== "" &&
                requestedDate !== "Invalid Date Format"
                  ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                  : null,
              ModelHome: forModelHome,
              OverrideDefSwitchColorCode:
                overDefSwitchColor && overDefSwitchColor !== null
                  ? overDefSwitchColor?.switchColorCode
                  : agreement && agreement !== null
                  ? agreement.defaultSwitchColor &&
                    agreement.defaultSwitchColor !== null
                    ? agreement?.defaultSwitchColor.switchColorCode
                    : agreement?.defaultSwitch &&
                      agreement?.defaultSwitch !== null
                    ? agreement?.defaultSwitch?.switchColorCode
                    : ""
                  : "",
              ShipToAddressID:
                shipToAddress && shipToAddress !== null
                  ? shipToAddress.shippingID
                  : null,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              BillingCompanyID:
                billingAddress && billingAddress !== null
                  ? billingAddress.companyID
                  : "",
              BillingContactID:
                billingContact && billingContact !== null
                  ? billingContact.userID
                  : null,
              LotNum: lotNbr,
              JobName: jobName,
              JobNum: orderType === "Bulk Inventory" ? null : jobNbr,
              CreatedByID: currentUserID,
              InstallationCity:
                orderType === "Project Pack for Home"
                  ? city
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : instCity,
              InstallationCountryID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.country.countryID
                    : instCountry && instCountry !== null
                    ? instCountry.countryID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : instCountry && instCountry !== null
                  ? instCountry.countryID
                  : null,
              InstallationStateProvinceID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.stateProvince.stateProvinceID
                    : instStateProvince && instStateProvince !== null
                    ? instStateProvince.stateProvinceID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : instStateProvince && instStateProvince !== null
                  ? instStateProvince.stateProvinceID
                  : null,
              InstallationZipPostCode:
                orderType === "Project Pack for Home"
                  ? zip
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : instZipCode,
              InstallationStreetAddress:
                orderType === "Project Pack for Home"
                  ? street1
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : instStreet,
              InstallationSecondStreetAddress:
                orderType === "Project Pack for Home"
                  ? street2
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : instStreet2,
              Status: currentCompanyType === "L" ? "D" : "N",
              SpecialNegotiatedProjectPrice: specialNegotiated,
              ShipWithOtherGoods: shipWithOtherGoods,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              IsComplementaryPP: isCompPP,
              IsRMA: isRMA,
              CompanyShippingAddressesToBeUpdated: shippingAddressIDs,
              NotifEmailSendNow: sendNotif,
              NotifEmailSend24h: sendNotif24h,
              ReceiverID: receiver?.userID,
              // NotifEmailSent: sendNotif || sendNotif24h ? true : false,
            })
            .then(async (response) => {
              await setPristine();
              setSaveError("");
              setAddressChanged("");
              setAddressVerificationLevel("");
              setDateAlertDialogOpen(false);
              setDisplayFinalPLaceOrderModal(false);
              if (
                (activeStep === 3 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 4 &&
                  (currentCompanyIsParent === true ||
                    currentCompanyType === "L") &&
                  orderType === "Project Pack for Home") ||
                goNext === true
              ) {
                setOrderID(response.data.orderID);
                setOrder(response.data);
                setCurrency2(response.data.purchaser.company.country.cur);

                if (submit) setSubmitLoading(false);
                else setLoading(false);
                if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === false
                ) {
                  console.log(sendNotif || sendNotif24h);
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push(isRMA === false ? "/Orders" : "/rma");
                  }
                } else if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === true
                ) {
                  setActiveStep(currentCompanyType === "L" ? 6 : 5);
                } else handleNext();
              } else {
                if (submit) {
                  submitOrderPermission
                    ? await submitOrder(response.data)
                    : await finalizeOrder(response.data);
                } else if (continueToPlaceOrder === false) {
                  console.log(sendNotif || sendNotif24h);

                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push({
                      pathname: isRMA === false ? "/Orders" : "/RMA",
                      state: {
                        openOrderSuccess: true,
                      },
                    });
                  }
                } else {
                  setOrderID(response.data.orderID);
                  setOrder(response.data);
                  setCurrency2(response.data.purchaser.company.country.cur);
                  if (
                    (activeStep === 1 &&
                      currentCompanyIsParent !== true &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type !== "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 0 &&
                      currentCompanyType === "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 1 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R" &&
                      orderType === "Bulk Inventory")
                  ) {
                    fetchOrderQuantitiesForEdit(true, response.data.orderID);
                  } else {
                    setActiveStep(
                      orderType === "Project Pack for Home"
                        ? currentCompanyType === "L" ||
                          currentCompanyIsParent === true
                          ? 7
                          : 6
                        : orderType === "Bulk Inventory"
                        ? (currentCompanyType === "L" ||
                            currentCompanyIsParent === true) &&
                          company?.type === "R"
                          ? 2
                          : (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            company?.type !== "R"
                          ? 3
                          : currentCompanyType === "R"
                          ? 1
                          : currentCompanyType !== "R"
                          ? 2
                          : activeStep
                        : activeStep
                    );
                    setLoading(false);
                  }
                }
              }
              if (sendNotif || sendNotif24h) {
                setAlertNotification(true);
              }
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
              if (submit) setSubmitLoading(false);
              else setLoading(false);
              setDateAlertDialogOpen(false);
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
            });
        } else {
          orderServices
            .updateOrder({
              FromCreate: true,
              NotifEmailSendNow: sendNotif,
              NotifEmailSend24h: sendNotif24h,
              ReceiverID: receiver?.userID,
              //NotificationEmailSent: sendNotif || sendNotif24h,
              OrderID: orderID,
              PurchaserID:
                purchaserEmail && purchaserEmail !== null
                  ? purchaserEmail.userID
                  : "",
              Type:
                orderType !== ""
                  ? orderType.charAt(0) === "B"
                    ? "S"
                    : orderType.charAt(0)
                  : "",
              TakeoffID: takeoff && takeoff !== null ? takeoff.takeoffID : "",
              InstallerContactID:
                installerContact && installerContact !== null
                  ? installerContact.userID
                  : "",
              PurchaseOrderNbr: purchaseOrderNb,
              RequestedDeliveryDate:
                requestedDate !== null &&
                requestedDate !== "" &&
                requestedDate !== "Invalid Date Format"
                  ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                  : null,
              ModelHome: forModelHome,
              OverrideDefSwitchColorCode:
                overDefSwitchColor && overDefSwitchColor !== null
                  ? overDefSwitchColor?.switchColorCode
                  : agreement && agreement !== null
                  ? agreement?.defaultSwitchColor &&
                    agreement?.defaultSwitchColor !== null
                    ? agreement?.defaultSwitchColor.switchColorCode
                    : agreement?.defaultSwitch &&
                      agreement?.defaultSwitch !== null
                    ? agreement?.defaultSwitch?.switchColorCode
                    : ""
                  : "",
              ShipToAddressID:
                shipToAddress && shipToAddress !== null
                  ? shipToAddress.shippingID
                  : null,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              BillingCompanyID:
                billingAddress && billingAddress !== null
                  ? billingAddress.companyID
                  : "",
              BillingContactID:
                billingContact && billingContact !== null
                  ? billingContact.userID
                  : null,
              LotNum: lotNbr,
              JobName: jobName,
              JobNum: orderType === "Bulk Inventory" ? null : jobNbr,
              ModifiedByID: currentUserID,
              InstallationCity:
                orderType === "Project Pack for Home"
                  ? city
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : instCity,
              InstallationCountryID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.country.countryID
                    : instCountry && instCountry !== null
                    ? instCountry.countryID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : instCountry && instCountry !== null
                  ? instCountry.countryID
                  : null,
              InstallationStateProvinceID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.stateProvince.stateProvinceID
                    : instStateProvince && instStateProvince !== null
                    ? instStateProvince.stateProvinceID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : instStateProvince && instStateProvince !== null
                  ? instStateProvince.stateProvinceID
                  : null,
              InstallationZipPostCode:
                orderType === "Project Pack for Home"
                  ? zip
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : instZipCode,
              InstallationStreetAddress:
                orderType === "Project Pack for Home"
                  ? street1
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : instStreet,
              InstallationSecondStreetAddress:
                orderType === "Project Pack for Home"
                  ? street2
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : instStreet2,
              Status: currentCompanyType === "L" ? "D" : "N",
              SpecialNegotiatedProjectPrice: specialNegotiated,
              OrdersToBeShippedWith: selected,
              ShipWithOtherGoods: shipWithOtherGoods,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              IsComplementaryPP: isCompPP,
              IsRMA: isRMA,
            })
            .then(async (response) => {
              await setPristine();
              setCanSubmitAlert(false);

              setSaveError("");
              if (
                (activeStep === 3 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                goNext === true
              ) {
                setOrder(response.data);

                if (submit) setSubmitLoading(false);
                else setLoading(false);
                if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === false
                ) {
                  console.log(sendNotif || sendNotif24h);

                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push(isRMA === false ? "/Orders" : "/rma");
                  }
                } else if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === true
                ) {
                  setActiveStep(currentCompanyType === "L" ? 6 : 5);
                } else handleNext();
              } else {
                if (submit) {
                  submitOrderPermission
                    ? submitOrder(response.data)
                    : finalizeOrder(response.data);
                } else if (continueToPlaceOrder === false) {
                  console.log(sendNotif || sendNotif24h);
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push({
                      pathname: isRMA === false ? "/Orders" : "/RMA",
                      state: {
                        openOrderSuccess: true,
                      },
                    });
                  }
                } else {
                  setOrderID(response.data.orderID);
                  setOrder(response.data);
                  if (
                    (activeStep === 1 &&
                      currentCompanyIsParent !== true &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type !== "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 0 &&
                      currentCompanyType === "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 1 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R" &&
                      orderType === "Bulk Inventory")
                  ) {
                    fetchOrderQuantitiesForEdit(true, response.data.orderID);
                  } else {
                    setActiveStep(
                      orderType === "Project Pack for Home"
                        ? currentCompanyType === "L" ||
                          currentCompanyIsParent === true
                          ? 7
                          : 6
                        : orderType === "Bulk Inventory"
                        ? (currentCompanyType === "L" ||
                            currentCompanyIsParent === true) &&
                          company?.type === "R"
                          ? 2
                          : (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            company?.type !== "R"
                          ? 3
                          : currentCompanyType === "R"
                          ? 1
                          : currentCompanyType !== "R"
                          ? 2
                          : activeStep
                        : activeStep
                    );
                    setLoading(false);
                  }
                }
              }
              if (sendNotif || sendNotif24h) {
                setAlertNotification(true);
              }
            })
            .catch((error) => {
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
              setSaveError(error.response.data.error.error);
              if (submit) setSubmitLoading(false);
              else setLoading(false);
            });
        }
      }
    } else {
      if (
        (requestedDate !== null &&
          requestedDate !== "" &&
          (regex.test(requestedDate) === false ||
            moment(requestedDate).isValid() === false)) ||
        ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
          (!company || company === null)) ||
        orderType === "" ||
        (orderType === "Project Pack for Home" &&
          (!takeoff || takeoff === null)) ||
        !purchaserEmail ||
        purchaserEmail === null ||
        (orderType === "Project Pack for Home" && jobNbr === "") ||
        (orderType === "Project Pack for Home" && jobName === "") ||
        (orderType === "Project Pack for Home" && lotNbr === "") ||
        // !shipToAddress ||
        // shipToAddress === null ||
        shippingMethod === "" ||
        shippingMethod === null ||
        !billingAddress ||
        billingAddress === null
      ) {
        /*If there is any error the function returns and highlights the field containing the error*/
        if (submit) setSubmitLoading(false);
        else setLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        setDateAlertDialogOpen(false);
        return;
      } else {
        /*If there is no errors*/
        /*If the page is in create mode*/

        let street1 = "";
        let street2 = "";
        let city = "";
        let state = "";
        let zip = "";

        if (addressPicked) {
          //address from autocomplete
          street1 = addressPicked.address1;
          street2 = addressPicked.address2;
          city = addressPicked.city;
          state =
            addressPicked.stateProvince &&
            addressPicked.stateProvince.stateProvinceCode
              ? addressPicked.stateProvince.stateProvinceCode
              : "N/A";
          zip = addressPicked.zipPostCode;
        } else if (verifyAddress) {
          //address not from autocomplete but verification on
          street1 =
            instStreet !== "" && instStreet !== null ? instStreet : "N/A";
          street2 =
            instStreet2 !== "" && instStreet2 !== null ? instStreet2 : "N/A";
          city = instCity !== "" && instCity !== null ? instCity : " N/A";

          state =
            instStateProvince && instStateProvince !== null
              ? instStateProvince.stateProvinceCode
              : "N/A";

          zip =
            instZipCode !== "" && instZipCode !== null ? instZipCode : "N/A";
        } else {
          //address not from autocomplete & verification off
          street1 = addressValue;
        }
        if (orderID === 0) {
          orderServices
            .createOrder({
              PurchaserID:
                currentCompanyType !== "L" && currentCompanyIsParent !== true
                  ? currentUserID
                  : purchaserEmail && purchaserEmail !== null
                  ? purchaserEmail.userID
                  : "",
              Type:
                orderType !== ""
                  ? orderType.charAt(0) === "B"
                    ? "S"
                    : orderType.charAt(0)
                  : "",
              TakeoffID: takeoff && takeoff !== null ? takeoff.takeoffID : "",
              InstallerContactID:
                installerContact && installerContact !== null
                  ? installerContact.userID
                  : "",
              PurchaseOrderNbr: purchaseOrderNb,
              RequestedDeliveryDate:
                requestedDate !== null &&
                requestedDate !== "" &&
                requestedDate !== "Invalid Date Format"
                  ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                  : null,
              ModelHome: forModelHome,
              OverrideDefSwitchColorCode:
                overDefSwitchColor && overDefSwitchColor !== null
                  ? overDefSwitchColor?.switchColorCode
                  : agreement && agreement !== null
                  ? agreement.defaultSwitchColor &&
                    agreement.defaultSwitchColor !== null
                    ? agreement?.defaultSwitchColor.switchColorCode
                    : agreement?.defaultSwitch &&
                      agreement?.defaultSwitch !== null
                    ? agreement?.defaultSwitch?.switchColorCode
                    : ""
                  : "",
              ShipToAddressID:
                shipToAddress && shipToAddress !== null
                  ? shipToAddress.shippingID
                  : null,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              BillingCompanyID:
                billingAddress && billingAddress !== null
                  ? billingAddress.companyID
                  : "",
              BillingContactID:
                billingContact && billingContact !== null
                  ? billingContact.userID
                  : null,
              LotNum: lotNbr,
              JobName: jobName,
              JobNum: orderType === "Bulk Inventory" ? null : jobNbr,
              CreatedByID: currentUserID,
              InstallationCity:
                orderType === "Project Pack for Home"
                  ? city
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : instCity,
              InstallationCountryID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.country.countryID
                    : instCountry && instCountry !== null
                    ? instCountry.countryID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : instCountry && instCountry !== null
                  ? instCountry.countryID
                  : null,
              InstallationStateProvinceID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.stateProvince.stateProvinceID
                    : instStateProvince && instStateProvince !== null
                    ? instStateProvince.stateProvinceID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : instStateProvince && instStateProvince !== null
                  ? instStateProvince.stateProvinceID
                  : null,
              InstallationZipPostCode:
                orderType === "Project Pack for Home"
                  ? zip
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : instZipCode,
              InstallationStreetAddress:
                orderType === "Project Pack for Home"
                  ? street1
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : instStreet,
              InstallationSecondStreetAddress:
                orderType === "Project Pack for Home"
                  ? street2
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : instStreet2,
              Status: currentCompanyType === "L" ? "D" : "N",
              SpecialNegotiatedProjectPrice: specialNegotiated,
              ShipWithOtherGoods: shipWithOtherGoods,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              IsComplementaryPP: isCompPP,
              IsRMA: isRMA,
              CompanyShippingAddressesToBeUpdated: shippingAddressIDs,
              NotifEmailSendNow: sendNotif,
              NotifEmailSend24h: sendNotif24h,
              ReceiverID: receiver?.userID,
              // NotifEmailSent: sendNotif || sendNotif24h ? true : false,
            })
            .then(async (response) => {
              await setPristine();
              setSaveError("");
              setAddressChanged("");
              setAddressVerificationLevel("");
              setDateAlertDialogOpen(false);
              setDisplayFinalPLaceOrderModal(false);
              if (
                (activeStep === 3 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                goNext === true
              ) {
                setOrderID(response.data.orderID);
                setOrder(response.data);
                setCurrency2(response.data.purchaser.company.country.cur);

                if (submit) setSubmitLoading(false);
                else setLoading(false);
                if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === false
                ) {
                  console.log(sendNotif || sendNotif24h);
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push(isRMA === false ? "/Orders" : "/rma");
                  }
                } else if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === true
                ) {
                  setActiveStep(
                    currentCompanyType === "L" ||
                      currentCompanyIsParent === true
                      ? 6
                      : 5
                  );
                } else handleNext();
              } else {
                if (submit) {
                  submitOrderPermission
                    ? await submitOrder(response.data)
                    : await finalizeOrder(response.data);
                } else if (continueToPlaceOrder === false) {
                  console.log(sendNotif || sendNotif24h);
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push({
                      pathname: isRMA === false ? "/Orders" : "/RMA",
                      state: {
                        openOrderSuccess: true,
                      },
                    });
                  }
                } else {
                  setOrderID(response.data.orderID);
                  setOrder(response.data);
                  setCurrency2(response.data.purchaser.company.country.cur);
                  if (
                    (activeStep === 1 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type !== "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 0 &&
                      currentCompanyType === "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 1 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R" &&
                      orderType === "Bulk Inventory")
                  ) {
                    fetchOrderQuantitiesForEdit(true, response.data.orderID);
                  } else {
                    setActiveStep(
                      orderType === "Project Pack for Home"
                        ? currentCompanyType === "L" ||
                          currentCompanyIsParent === true
                          ? 7
                          : 6
                        : orderType === "Bulk Inventory"
                        ? (currentCompanyType === "L" ||
                            currentCompanyIsParent === true) &&
                          company?.type === "R"
                          ? 2
                          : (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            company?.type !== "R"
                          ? 3
                          : currentCompanyType === "R"
                          ? 1
                          : currentCompanyType !== "R"
                          ? 2
                          : activeStep
                        : activeStep
                    );
                    setLoading(false);
                  }
                }
              }
              // if (sendNotif || sendNotif24h) {
              //   setAlertNotification(true);
              // }
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
              if (submit) setSubmitLoading(false);
              else setLoading(false);
              setDateAlertDialogOpen(false);
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
            });
        } else {
          orderServices
            .updateOrder({
              FromCreate: true,
              NotifEmailSendNow: sendNotif,
              NotifEmailSend24h: sendNotif24h,
              ReceiverID: receiver?.userID,
              //NotificationEmailSent: sendNotif || sendNotif24h,
              OrderID: orderID,
              PurchaserID:
                purchaserEmail && purchaserEmail !== null
                  ? purchaserEmail.userID
                  : "",
              Type:
                orderType !== ""
                  ? orderType.charAt(0) === "B"
                    ? "S"
                    : orderType.charAt(0)
                  : "",
              TakeoffID: takeoff && takeoff !== null ? takeoff.takeoffID : "",
              InstallerContactID:
                installerContact && installerContact !== null
                  ? installerContact.userID
                  : "",
              PurchaseOrderNbr: purchaseOrderNb,
              RequestedDeliveryDate:
                requestedDate !== null &&
                requestedDate !== "" &&
                requestedDate !== "Invalid Date Format"
                  ? moment(new Date(requestedDate)).format("MM/DD/YYYY")
                  : null,
              ModelHome: forModelHome,
              OverrideDefSwitchColorCode:
                overDefSwitchColor && overDefSwitchColor !== null
                  ? overDefSwitchColor?.switchColorCode
                  : agreement && agreement !== null
                  ? agreement?.defaultSwitchColor &&
                    agreement?.defaultSwitchColor !== null
                    ? agreement?.defaultSwitchColor.switchColorCode
                    : agreement?.defaultSwitch &&
                      agreement?.defaultSwitch !== null
                    ? agreement?.defaultSwitch?.switchColorCode
                    : ""
                  : "",
              ShipToAddressID:
                shipToAddress && shipToAddress !== null
                  ? shipToAddress.shippingID
                  : null,
              ShippingMethod:
                shippingMethod !== "" ? shipMethodCheck(shippingMethod) : "",
              BillingCompanyID:
                billingAddress && billingAddress !== null
                  ? billingAddress.companyID
                  : "",
              BillingContactID:
                billingContact && billingContact !== null
                  ? billingContact.userID
                  : null,
              LotNum: lotNbr,
              JobName: jobName,
              JobNum: orderType === "Bulk Inventory" ? null : jobNbr,
              ModifiedByID: currentUserID,
              InstallationCity:
                orderType === "Project Pack for Home"
                  ? city
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : instCity,
              InstallationCountryID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.country.countryID
                    : instCountry && instCountry !== null
                    ? instCountry.countryID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : instCountry && instCountry !== null
                  ? instCountry.countryID
                  : null,
              InstallationStateProvinceID:
                orderType === "Project Pack for Home"
                  ? addressPicked !== null && addressPicked !== undefined
                    ? addressPicked.stateProvince.stateProvinceID
                    : instStateProvince && instStateProvince !== null
                    ? instStateProvince.stateProvinceID
                    : null
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : instStateProvince && instStateProvince !== null
                  ? instStateProvince.stateProvinceID
                  : null,
              InstallationZipPostCode:
                orderType === "Project Pack for Home"
                  ? zip
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : instZipCode,
              InstallationStreetAddress:
                orderType === "Project Pack for Home"
                  ? street1
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : instStreet,
              InstallationSecondStreetAddress:
                orderType === "Project Pack for Home"
                  ? street2
                  : addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : instStreet2,
              Status: currentCompanyType === "L" ? "D" : "N",
              SpecialNegotiatedProjectPrice: specialNegotiated,
              OrdersToBeShippedWith: selected,
              ShipWithOtherGoods: shipWithOtherGoods,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              IsComplementaryPP: isCompPP,
              IsRMA: isRMA,
            })
            .then(async (response) => {
              console.log(sendNotif || sendNotif24h);
              if (sendNotif || sendNotif24h) {
                setAlertNotification(true);
              }
              await setPristine();
              setCanSubmitAlert(false);

              setSaveError("");
              if (
                (activeStep === 3 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                goNext === true
              ) {
                setOrder(response.data);

                if (submit) setSubmitLoading(false);
                else setLoading(false);
                if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === false
                ) {
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push(isRMA === false ? "/Orders" : "/rma");
                  }
                } else if (
                  submitSource === "Use Base Plan Quantities" &&
                  continueToPlaceOrder === true
                ) {
                  setActiveStep(
                    currentCompanyType === "L" ||
                      currentCompanyIsParent === true
                      ? 6
                      : 5
                  );
                } else handleNext();
              } else {
                if (submit) {
                  submitOrderPermission
                    ? submitOrder(response.data)
                    : finalizeOrder(response.data);
                } else if (continueToPlaceOrder === false) {
                  if (sendNotif || sendNotif24h) {
                    setAlertNotification(true);
                  } else {
                    history.push({
                      pathname: isRMA === false ? "/Orders" : "/RMA",
                      state: {
                        openOrderSuccess: true,
                      },
                    });
                  }
                } else {
                  setOrderID(response.data.orderID);
                  setOrder(response.data);
                  if (
                    (activeStep === 1 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type !== "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 0 &&
                      currentCompanyType === "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 1 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R" &&
                      orderType === "Bulk Inventory")
                  ) {
                    fetchOrderQuantitiesForEdit(true, response.data.orderID);
                  } else {
                    setActiveStep(
                      orderType === "Project Pack for Home"
                        ? currentCompanyType === "L" ||
                          currentCompanyIsParent === true
                          ? 7
                          : 6
                        : orderType === "Bulk Inventory"
                        ? (currentCompanyType === "L" ||
                            currentCompanyIsParent === true) &&
                          company?.type === "R"
                          ? 2
                          : (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            company?.type !== "R"
                          ? 3
                          : currentCompanyType === "R"
                          ? 1
                          : currentCompanyType !== "R"
                          ? 2
                          : activeStep
                        : activeStep
                    );
                    setLoading(false);
                  }
                }
              }
            })
            .catch((error) => {
              setCanSubmitAlert(false);
              setCanSubmitModal(false);
              setSaveError(error.response.data.error.error);
              if (submit) setSubmitLoading(false);
              else setLoading(false);
            });
        }
      }
    }
  };

  /*Submit Order*/
  const submitOrder = async (orderParam) => {
    setSubmitLoading(true);
    await orderServices
      .submitOrder({
        OrderID: orderParam.orderID,
        Status: "T",
        UserID: currentUserID,
      })
      .then(() => {
        setSubmitLoading(false);
        setCanSubmitAlert(false);
        setDisplayAddressVerificationWarning(false);
        navigateToOrderPage(orderParam, "T");
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setSubmitLoading(false);
        setCanSubmitAlert(false);
      });
  };

  const finalizeOrder = async (orderParam) => {
    //function for order finalization
    setSubmitLoading(true);
    await orderServices
      .submitOrder({
        OrderID: orderParam.orderID,
        Status: "F",
        UserID: currentUserID,
      })
      .then(() => {
        orderServices
          .sendApprovalEmail({
            Receiver: chosenCanSubmitUser.userName,
            Sender: currentUsername,
            OrderID: orderParam.orderID,
          })
          .then(() => {
            setSubmitLoading(false);
            setCanSubmitModal(false);
            navigateToOrderPage(orderParam, "F");
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
            setSubmitLoading(false);
            setCanSubmitModal(false);
          });
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setSubmitLoading(false);
        setCanSubmitModal(false);
      });
  };

  const handleNext = () => {
    console.log(
      "handle next from",
      activeStep,
      activeStep !== steps.length - 1
    );
    //function responsible for going next in steps
    if (activeStep !== steps.length - 1) {
      if (
        (fromTakeoff &&
          (currentCompanyType === "L" || currentCompanyIsParent === true)) ||
        (isRMA &&
          (currentCompanyType === "L" || currentCompanyIsParent === true))
      ) {
        if (company && company !== null && activeStep === 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (currentCompanyIsParent === true && orderID == 0) {
      console.log("handle next parent company ");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    console.log("handle next to", activeStep);
  };

  const [defaultArray, setDefaultArray] = useState(
    currentCompanyType === "L" && company && company !== null
      ? [{ title: "Company" }, { title: "Order Type" }]
      : currentCompanyType === "L"
      ? [{ title: "Company" }]
      : [{ title: "Order Type" }]
  );

  const handleBack = () => {
    if (formChanged) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        )
      ) {
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        if (displayEditUnitprice) {
          setDisplayEditUnitPrice(false);
          return;
        }
        if (displayAddSwitches) {
          setRowsSwitchAdd([]);
          setRowsAssemblyAdd([]);
          setDisplayAddSwitches(false);
          return;
        }

        if (displayChangeSwitchColor) {
          setOverDefSwitchColor(
            oldSwitchColor !== null ? oldSwitchColor : null
          );
          setSwitchColors([]);
          setDisplayChangeSwitchColor(false);
          return;
        }

        if (displayChangeSwitches) {
          setArrayFrom([]);
          setArrayTo([]);
          setArrayFrom2([]);
          setArrayTo2([]);
          setValueToAdd(null);
          setRows([]);
          setSelectedFrom(null);
          setSelectedTo(null);
          setSelectedFrom2(null);
          setSelectedTo2(null);
          setDisplayChangeSwitches(false);
          return;
        }

        if (!fromTakeoff && !isRMA) {
          if (
            (orderID === 0 &&
              activeStep === 1 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I")) ||
            (orderID === 0 &&
              activeStep === 2 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type !== "R")
          ) {
            setOrderType("");
          }
        }

        if (
          (fromTakeoff && currentCompanyType === "L" && activeStep === 2) ||
          (isRMA && currentCompanyType === "L" && activeStep === 2)
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
          if (
            ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
              activeStep === 6) ||
            (currentCompanyType !== "L" && activeStep === 5)
          ) {
            if (submitSource === "Use Base Plan Quantities") {
              setActiveStep((prevActiveStep) => prevActiveStep - 2);
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
          }
        }
      } else {
        return;
      }
    } else {
      if (displayEditUnitprice) {
        setDisplayEditUnitPrice(false);
        return;
      }

      if (displayEditUnitprice) {
        setDisplayEditUnitPrice(false);
        return;
      }
      if (displayAddSwitches) {
        setDisplayAddSwitches(false);
        return;
      }

      if (displayChangeSwitchColor) {
        setDisplayChangeSwitchColor(false);
        return;
      }

      if (displayChangeSwitches) {
        setDisplayChangeSwitches(false);
        return;
      }

      if (!fromTakeoff && !isRMA) {
        if (
          (orderID === 0 &&
            activeStep === 1 &&
            currentCompanyIsParent !== true &&
            (currentCompanyType === "B" || currentCompanyType === "I")) ||
          (orderID === 0 &&
            activeStep === 2 &&
            (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company?.type !== "R")
        ) {
          setOrderType("");
        }
      }

      if (
        (fromTakeoff && currentCompanyType === "L" && activeStep === 2) ||
        (isRMA && currentCompanyType === "L" && activeStep === 2)
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      } else {
        if (
          ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
            activeStep === 6) ||
          (currentCompanyType !== "L" && activeStep === 5)
        ) {
          if (submitSource === "Use Base Plan Quantities") {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
          }
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      }
    }
  };

  const createTemporaryArrayFromArrayFrom = async () => {
    var tmp = [];

    for (var i = 0; i < arrayFrom.length; i++) {
      tmp.push({
        isDimmer: arrayFrom[i].isDimmer,
        quantity: arrayFrom[i].quantity,
        unmodifiedQuantity: arrayFrom[i].quantity,
        switchAssemblyTypeCode:
          arrayFrom[i].product.switchAssemblyType.switchAssemblyTypeCode,
        controllerTypeCode:
          arrayFrom[i].product.controller.controllerType.controllerTypeCode,
        switchStyle: arrayFrom[i].product.switch.switchStyle.description,
        switchColor: arrayFrom[i].product.switch.switchColor.description,
      });
    }

    return tmp;
  };

  const getSwitchAssemblyType = (code) => {
    if (code === 1) {
      return "Single-pole";
    } else if (code === 2) {
      return "3-way";
    } else if (code === 3) {
      return "4-way";
    } else {
      return "";
    }
  };

  const getControllerType = (code) => {
    if (code === 1) {
      return "On/off";
    } else if (code === 2) {
      return "Dimmer";
    } else if (code === 3) {
      return "Convertible";
    } else {
      return "";
    }
  };

  const navigateToOrderPage = (order, orderStatusParam) => {
    if (order && order !== null) {
      history.push({
        pathname: isRMA === false ? "/order-information" : "/rma-information",
        state: {
          orderID: order.orderID,
          orderName: order.purchaser.company.name,
          openSnackBar: true,
          value: 1,
          orderStatus: orderStatusParam,
          purchaserCompanyID: order.purchaser.companyID,
          jobName: jobName,
          fromCreate: true,
          jobNum: order.jobNum,
          purchaseOrderNbr: purchaseOrderNb,
          requestedDate: requestedDate,
          orderFromRedirect: order,
        },
      });
    }
  };

  const checkForErrorFirst = async () => {
    setLoading(true);
    setErrorArray([]);

    var checkError = await checkForQuantitiesError();

    if (checkError.errorFound === true) {
      var tmpErrorArray = checkError.errorArray;
      tmpErrorArray.unshift(
        checkError.errorArray.length === 1
          ? "We encountered an error in the Change A Switch table:"
          : "We encountered several errors in the Change A Switch table:"
      );
      await setErrorArray(tmpErrorArray);
      setErrorOnFinish(true);
      setLoading(false);
    } else {
      setLoading(false);
      if (orderType === "Bulk Inventory") {
        save("", false);
      } else {
        if (
          purchaseOrderNb === "" ||
          requestedDate === "" ||
          requestedDate === "Invalid Date Format"
        ) {
          setPlaceOrderModalOpen(true);
        } else if (submitOrderPermission) {
          if (orderType === "Bulk Inventory") {
            save("", false);
          } else setCanSubmitAlert(true);
        } else if (!submitOrderPermission) {
          setCanSubmitModal(true);
        }
      }
    }
  };

  const checkWhichModalToOpen = async (
    fromQuantities = false,
    submit = false
  ) => {
    /**Check which Modal to Open */
    if (fromQuantities) {
      if (
        selectedFrom !== null ||
        selectedTo !== null ||
        selectedFrom2 !== null ||
        selectedTo2 !== null ||
        (valueToAdd !== null && valueToAdd !== "")
      ) {
        setLoading(true);
        await setRows(
          update(rows, {
            $push: [
              {
                From: selectedFrom,
                To: selectedTo,
                quantity: valueToAdd,
                FromSwitchStyleColor: selectedFrom2,
                ToSwitchStyleColor: selectedTo2,
              },
            ],
          })
        );
        setSelectedFrom(null);
        setSelectedTo(null);
        setSelectedFrom2(null);
        setSelectedTo2(null);
        setValueToAdd("");
        setAddedValues((prevValue) => !prevValue);
      } else {
        checkForErrorFirst();
      }
    } else {
      if (orderType === "Bulk Inventory") {
        if (submit) setDisplayFinalPLaceOrderModal(true);
        else save("", false, true);
      } else {
        if (
          purchaseOrderNb === "" ||
          requestedDate === "" ||
          requestedDate === "Invalid Date Format" ||
          shipToAddress === "" ||
          shipToAddress === null ||
          purchaseOrderNb === null
        ) {
          if (
            (activeStep === 4 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ||
            (activeStep === 3 &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home")
          )
            setPlaceOrderModalOpen(true);
          else setDisplayFinalPLaceOrderModal(true);
          // setPlaceOrderModalOpen(true);
        } else if (submitOrderPermission) {
          if (orderType === "Bulk Inventory") {
            save("", false);
          } else setCanSubmitAlert(true);
        } else if (!submitOrderPermission) {
          setCanSubmitModal(true);
        }
      }
    }
  };

  const checkForQuantitiesError = async () => {
    var array = await createTemporaryArrayFromArrayFrom();

    var error = [];
    var errorFound = false;

    for (var i = 0; i < rows.length; i++) {
      if (rows[i].From === null || rows[i].FromSwitchStyleColor === null) {
        errorFound = true;
        //var textAftNumber1 = await textAfterNumber(i + 1);
        var stringBuilder1 = "-The Change From field cannot be empty.";
        error.push(stringBuilder1);
      }

      if (rows[i].To === null || rows[i].ToSwitchStyleColor === null) {
        errorFound = true;
        //var textAftNumber2 = await textAfterNumber(i + 1);
        var stringBuilder2 = "-The Upgrade To field cannot be empty.";
        error.push(stringBuilder2);
      }

      if (rows[i].quantity === null || rows[i].quantity === "") {
        errorFound = true;
        //var textAftNumber3 = await textAfterNumber(i + 1);
        var stringBuilder3 = "-The quantity to allocate cannot be empty.";
        error.push(stringBuilder3);
      }
    }

    for (i = 0; i < rows.length; i++) {
      for (var j = 0; j < array.length; j++) {
        if (rows[i].From === null) {
          break;
        }

        if (rows[i].To === null) {
          break;
        }
        if (rows[i].FromSwitchStyleColor === null) {
          break;
        }

        if (rows[i].ToSwitchStyleColor === null) {
          break;
        }
        if (rows[i].quantity === null) {
          break;
        }

        if (
          rows[i].From.product.switchAssemblyType.switchAssemblyTypeCode ===
            array[j].switchAssemblyTypeCode &&
          rows[i].From.product.controller.controllerType.controllerTypeCode ===
            array[j].controllerTypeCode &&
          rows[i].From.isDimmer === array[j].isDimmer
        ) {
          array[j].quantity -= rows[i].quantity;

          break;
        }
      }
    }

    for (var k = 0; k < array.length; k++) {
      if (array[k].quantity < 0) {
        var quantityExceeded = -array[k].quantity;
        var stringBuilder =
          "-You have exceeded the maximum allowed quantity of ";
        stringBuilder += array[k].switchStyle + " " + array[k].switchColor;
        stringBuilder +=
          " " + getSwitchAssemblyType(array[k].switchAssemblyTypeCode);
        stringBuilder += " " + getControllerType(array[k].controllerTypeCode);
        stringBuilder += array[k].isDimmer ? " (at the price of On/off)" : "";
        stringBuilder +=
          " (Quantity Allowed:" +
          array[k].unmodifiedQuantity +
          ", Quantity Entered:" +
          (quantityExceeded + array[k].unmodifiedQuantity) +
          ")";
        error.push(stringBuilder);
        errorFound = true;
      }
    }

    return { errorFound: errorFound, errorArray: error };
  };

  const addValuesToRows = async () => {
    if (
      selectedFrom !== null ||
      selectedTo !== null ||
      selectedFrom2 !== null ||
      selectedTo2 !== null ||
      (valueToAdd !== null && valueToAdd !== "")
    ) {
      await rows.push({
        From: selectedFrom,
        To: selectedTo,
        FromSwitchStyleColor: selectedFrom2,
        ToSwitchStyleColor: selectedTo2,
        quantity: valueToAdd,
      });
      setSelectedFrom(null);
      setSelectedTo(null);
      setSelectedFrom2(null);
      setSelectedTo2(null);
      setValueToAdd("");
      setLoading(false);
      setSubmitChangeProducts(true);
    }
  };

  const submitChangeAndAddValues = async (
    changes = false,
    addition = false
  ) => {
    //handles the save of the switches & assemblies after adding switch quantities and assembly quantities

    //in case user wants to save changes to product quantities
    if (changes === true) {
      setLoading(true);
      if (
        selectedFrom !== null ||
        selectedTo !== null ||
        selectedFrom2 !== null ||
        selectedTo2 !== null ||
        (valueToAdd !== null && valueToAdd !== "")
      ) {
        addValuesToRows();
      } else {
        setErrorArray([]);

        var checkError = await checkForQuantitiesError();

        if (checkError.errorFound === true) {
          var tmpErrorArray = checkError.errorArray;
          tmpErrorArray.unshift(
            checkError.errorArray.length === 1
              ? "We encountered an error in the Change A Switch table:"
              : "We encountered several errors in the Change A Switch table:"
          );
          await setErrorArray(tmpErrorArray);
          setErrorOnFinish(true);
          setLoading(false);
          return;
        }
        //in case of save changes
        const data = {
          ListOfChanges: rows,
          OrderID: orderID,
          ApplySwitchstyleColorChange: true,
        };

        //to change product quantities
        await orderQuantitiesServices
          .changeProductsOfOrderQuantitiesOfCreatedOrder(data)
          .then(async (response) => {
            setPristine();
            setFormChanged(false);
            sessionStorage.removeItem("unsavedChanges");

            setLoading(false);
            setDisplayChangeSwitches(false);
            setSuccess(true);
            setMessage("Saved Successfully");
            setSubmitChangeProducts(false);
          })
          .catch((error) => {
            setLoading(false);
            setSaveError(error.response.data.error.error);
          });
      }
    } else if (addition === true) {
      setLoading(true);
      const arrayToBeSent = [...rowsSwitchAdd, ...rowsAssemblyAdd];

      await orderQuantitiesServices
        .addProductsToOrderQuantities({
          OrderID: orderID,
          Products: arrayToBeSent,
        })
        .then(async (response) => {
          setPristine();
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setLoading(false);
          setDisplayAddSwitches(false);
          setSuccess(true);
          setMessage("Saved Successfully");
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setLoading(false);
        });
    }
  };

  const getProductsForAdd = async (orderID) => {
    setRowsSwitchAdd([]);
    setRowsAssemblyAdd([]);
    setAddSwitchLoading(true);
    const data = { OrderID: orderID };
    orderQuantitiesServices
      .getListOfProductsToAddForOrderQuantitiesCreation(data)
      .then((response) => {
        setRowsSwitchAdd(response.data.switches);

        setRowsAssemblyAdd(response.data.assemblies);
        setDisplayAddSwitches(true);
        setDisplayChangeSwitches(false);
        setDisplayChangeSwitchColor(false);
        setAddSwitchLoading(false);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setAddSwitchLoading(false);
      });
  };

  /*Fetch takeoffs when specifying an agreement*/
  const fetchTakeoffs = () => {
    takeoffServices
      .GetTakeoffByAgreementPurchasing({
        CompanyID: company.companyID,
        CompanyType: company.type,
      })
      .then((response) => {
        setTakeoffs(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
      });
  };

  /*Fetch users when there is no company ID received*/
  const fetchCompanyUsers = () => {
    accountServices
      .GetUsersByCompanyIDManageOrder(
        currentCompanyType !== "L" && currentCompanyIsParent !== true
          ? currentCompanyID
          : company.companyID
      )
      .then((response) => {
        setPurchasers(response.data);
        if (currentCompanyType !== "L" && currentCompanyIsParent !== true)
          setPurchaserEmail(currentUser);
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };

  /*Fetch related users to the specified */
  const fetchRelatedUserToCompany = () => {
    accountServices
      .getAllUsersByRelatedCompanies(company?.companyID)
      .then((response) => {
        setCompaniesUsers(response.data);
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
      });
  };

  /*Fetch Company's billing Addresses*/
  const fetchCompanybillingCompanies = () => {
    companyServices
      .getAllRelatedCompaniesByCompanyID(company?.companyID)
      .then(async (response) => {
        await setBillingCompanies(response.data);
        response.data.forEach((address) => {
          if (address?.companyID === company?.companyID)
            setBillingAddress(address);
        });
        /*Fetch the notified of invoices user of a company*/
        await accountServices
          .checkUserNotifInvoices(company?.companyID)
          .then((response) => {
            setBillingContact(response.data);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          });
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };

  /*Fetch Installer's users*/
  const fetchInstallerContacts = () => {
    if (agreement.installer && agreement.installer !== null) {
      accountServices
        .GetUsersByCompanyIDManageOrder(agreement.installer.companyID)
        .then((response) => {
          setInstallerContacts(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    }
  };

  /*Fetch Def Switch*/
  const fetchDefSwitch = () => {
    if (agreement?.defaultSwitch && agreement?.defaultSwitch !== null) {
      productServices
        .getSwitchesByColorAndStyle({
          Style: agreement.defaultSwitch.switchStyleCode,
          Color:
            overDefSwitchColor && overDefSwitchColor !== null
              ? overDefSwitchColor?.switchColorCode
              : agreement && agreement !== null
              ? agreement.defaultSwitchColor &&
                agreement.defaultSwitchColor !== null
                ? agreement.defaultSwitchColor.switchColorCode
                : agreement.defaultSwitch && agreement.defaultSwitch !== null
                ? agreement.defaultSwitch.switchColorCode
                : ""
              : "",
          isCoated:
            agreement && agreement !== null ? agreement.coatedSwitch : "",
        })
        .then((response) => {
          setDefSwitch(response.data[0]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    }
  };

  /*Check shipping address*/
  const shipMethodCheck = (ship) => {
    if (ship === "Prepaid and charge") {
      return "C";
    } else if (ship === "Prepaid") {
      return "P";
    } else if (ship === "Local pickup") {
      return "L";
    } else if (ship === "Ship with other goods") {
      return "S";
    } else return "Prepaid and charge";
  };

  /*Check Shipping Method Reversed */
  const shipMethodCheckReversed = (ship) => {
    if (ship === "C") {
      return "Prepaid and charge";
    } else if (ship === "P") {
      return "Prepaid";
    } else if (ship === "L") {
      return "Local pickup";
    } else if (ship === "S") {
      return "Ship with other goods";
    } else return "Prepaid and charge";
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  // /*Reset All fields on Company change*/
  const resetFields = () => {
    setPurchaserEmail();
    if (!fromTakeoff && !isRMA) setOrderType("");
    setTakeoff();
    setAgreement();
    setInstallerContact();
    setOverDefSwitchColor();
    setShipToAddress();
    setOtherOrder([]);
    setBillingAddress();
    setBillingContact();
  };

  const checkRequestedDate = () => {
    console.log("check request date");
    if (requestedDate !== "" && requestedDate !== null) {
      const requested = new Date(requestedDate);
      const minRequestedDeliveryDate = new Date(
        Date.now() + minimumRequestedDeliveryDate
      );
      requested.setHours(0, 0, 0, 0);
      minRequestedDeliveryDate.setHours(0, 0, 0, 0);

      if (requested < minRequestedDeliveryDate) {
        setSaveError("");
        setDateAlertDialogOpen(true);
        setLoading(false);
      } else if (requestedDate === "Invalid Date Format") {
        setSaveError(
          `The ${
            isRMA === false ? "Requested Delivery Date" : "Delivery Date"
          } you entered is invalid`
        );
      } else {
        setSaveError("");

        setDisplayFinalPLaceOrderModal(true);
      }
    } else {
      setSaveError("");
      if (
        (orderType === "Bulk Inventory" &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company?.type === "R" &&
          activeStep === 1) ||
        (orderType === "Bulk Inventory" &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company?.type !== "R" &&
          activeStep === 2) ||
        (orderType === "Bulk Inventory" &&
          (currentCompanyType === "B" || currentCompanyType === "I") &&
          activeStep === 1) ||
        (orderType === "Bulk Inventory" &&
          currentCompanyType === "R" &&
          activeStep === 0) ||
        (activeStep === 6 &&
          (currentCompanyType === "B" || currentCompanyType === "I") &&
          orderType === "Project Pack for Home") ||
        (activeStep === 7 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          orderType === "Project Pack for Home") ||
        (activeStep === 3 &&
          (currentCompanyType === "B" || currentCompanyType === "I") &&
          orderType === "Bulk Inventory") ||
        (activeStep === 4 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          orderType === "Bulk Inventory") ||
        (activeStep === 2 && currentCompanyType === "R") ||
        (activeStep === 3 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company?.type === "R")
      )
        checkWhichModalToOpen(false, true);
      else handleNext();
    }
  };

  const changeStepsArrayDynamically = () => {
    if (orderType === "Project Pack for Home") {
      setSteps(
        (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company &&
          company !== null &&
          purchaserEmail &&
          purchaserEmail !== null &&
          orderType !== "" &&
          takeoff &&
          takeoff !== null &&
          lotNbr !== "" &&
          jobNbr !== "" &&
          jobName !== "" &&
          addressChange !== "" &&
          addressChange !== null &&
          instCountry &&
          instCountry !== null &&
          shippingMethod !== ""
          ? //&&
            // shipToAddress &&
            // shipToAddress !== null &&

            lPpStep12345678
          : (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== "" &&
            takeoff &&
            takeoff !== null &&
            lotNbr !== "" &&
            jobNbr !== "" &&
            jobName !== "" &&
            instCountry &&
            instCountry !== null &&
            addressChange !== "" &&
            addressChange !== null
          ? lPpStep12345
          : (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== "" &&
            takeoff &&
            takeoff !== null
          ? lPpStep1234
          : (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== ""
          ? lPpStep123
          : (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null
          ? lPpStep12
          : currentCompanyType === "L" || currentCompanyIsParent === true
          ? lPpStep1
          : currentCompanyType !== "L" &&
            currentCompanyIsParent !== true &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== "" &&
            takeoff &&
            takeoff !== null &&
            lotNbr !== "" &&
            jobNbr !== "" &&
            jobName !== "" &&
            addressChange !== "" &&
            addressChange !== null &&
            instCountry &&
            instCountry !== null &&
            shippingMethod !== ""
          ? //&&
            // shipToAddress &&
            // shipToAddress !== null &&

            nlPpStep1234567
          : currentCompanyType !== "L" &&
            currentCompanyIsParent !== true &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== "" &&
            takeoff &&
            takeoff !== null &&
            lotNbr !== "" &&
            jobNbr !== "" &&
            jobName !== "" &&
            instCountry &&
            instCountry !== null &&
            addressChange !== "" &&
            addressChange !== null
          ? nlPpStep1234
          : currentCompanyType !== "L" &&
            currentCompanyIsParent !== true &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== "" &&
            takeoff &&
            takeoff !== null
          ? nlPpStep123
          : currentCompanyType !== "L" &&
            currentCompanyIsParent !== true &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== ""
          ? nlPpStep12
          : currentCompanyType !== "L" &&
            currentCompanyIsParent !== true &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null
          ? nlPpStep1
          : lPpStep1
      );
    } else if (orderType === "Bulk Inventory") {
      setSteps(
        (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company?.type !== "R" &&
          purchaserEmail &&
          purchaserEmail !== null &&
          orderType !== ""
          ? lSiStep123
          : (currentCompanyType === "L" || currentCompanyIsParent === true) &&
            company?.type === "R" &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== ""
          ? lSiStep12
          : currentCompanyType === "L" || currentCompanyIsParent === true
          ? lSiStep1
          : (currentCompanyType === "B" || currentCompanyType === "I") &&
            currentCompanyIsParent !== true &&
            company?.type !== "R" &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== ""
          ? biSiStep12
          : (currentCompanyType === "B" || currentCompanyType === "I") &&
            currentCompanyIsParent !== true &&
            company?.type === "R" &&
            purchaserEmail &&
            purchaserEmail !== null &&
            orderType !== ""
          ? biSiStep1
          : currentCompanyType === "R"
          ? rSiStep1
          : lPpStep1
      );
    } else {
      setSteps(
        (currentCompanyType === "L" || currentCompanyIsParent !== true) &&
          company &&
          company !== null &&
          purchaserEmail &&
          purchaserEmail !== null
          ? lPpStep123
          : currentCompanyType === "L"
          ? lPpStep1
          : (company == null || purchaserEmail == null) &&
            currentCompanyIsParent == true
          ? nlPpStep1
          : currentCompanyType !== "R" &&
            company &&
            company !== null &&
            purchaserEmail &&
            purchaserEmail !== null
          ? nlPpStep12
          : []
      );
    }
  };

  async function validateUserAddressDuplicate() {
    if (instCountry.description === "" || instCountry.description === null) {
      setLoading(false);
      setInstCountryError(true);
      setMelissaMessageSnackbar("Address format not OK, please re-enter.");
      setMelissaSeveritySnackbar("Error_Icon");
      setMelissaInfoSnackbar(true);
      return;
    }

    let street1 = "";
    let street2 = "";
    let city = "";
    let state = "";
    let zip = "";

    if (addressPicked) {
      //address from autocomplete
      street1 = addressPicked.address1;
      street2 = addressPicked.address2;
      city = addressPicked.city;
      state =
        addressPicked.stateProvince &&
        addressPicked.stateProvince.stateProvinceCode
          ? addressPicked.stateProvince.stateProvinceCode
          : "N/A";
      zip = addressPicked.zipPostCode;
    } else if (verifyAddress) {
      //address not from autocomplete but verification on
      street1 = instStreet !== "" && instStreet !== null ? instStreet : "N/A";
      street2 =
        instStreet2 !== "" && instStreet2 !== null ? instStreet2 : "N/A";
      city = instCity !== "" && instCity !== null ? instCity : " N/A";

      state =
        instStateProvince && instStateProvince !== null
          ? instStateProvince.stateProvinceCode
          : "N/A";

      zip = instZipCode !== "" && instZipCode !== null ? instZipCode : "N/A";
    } else {
      //address not from autocomplete & verification off
      street1 = addressValue;
    }

    const data = {
      Street1: street1,
      Street2: street2,
      City: city,
      StateProvince:
        addressPicked !== null && addressPicked !== undefined
          ? addressPicked.stateProvince.stateProvinceCode
          : instStateProvince && instStateProvince !== null
          ? instStateProvince.stateProvinceCode
          : null,
      Country: instCountry.countryCode,
      ZipCode: zip,
    };

    await melissaServices
      .checkDuplicateAddress(data)
      .then((response) => {
        setLoading(false);
        setSaveError("");
        handleNext();
        setMelissaSave(false);
      })
      .catch((error) => {
        setMelissaSave(false);
        setSaveError(error.response.data.error.error);
        setLoading(false);
      });
  }

  const checkIfJobNumberExists = async () => {
    if (orderID === 0) {
      setLoading(true);
      await orderServices
        .checkIfJobNumberExists({ jobNum: jobNbr })
        .then(async (response) => {
          if (response.data) {
            setLoading(false);
            setSaveError("Job Number already exists.");
          } else {
            if (addressChanged && verifyAddress === true) {
              setLoading(true);
              setMelissaMessageSnackbar("Checking This Address...");
              setMelissaSeveritySnackbar("Loading_Icon");
              setMelissaInfoSnackbar(true);

              if (
                instCountry.description === "" ||
                instCountry.description === null
              ) {
                setInstCountryError(true);
                setLoading(false);
                setMelissaMessageSnackbar(
                  "Address format not OK, please re-enter."
                );
                setMelissaSeveritySnackbar("Error_Icon");
                setMelissaInfoSnackbar(true);
                return;
              }

              const data = {
                Street1: addressValue,
                Street2: "", //instStreet2,
                City: "",
                StateProvince: "",
                Country: instCountry.description,
                ZipCode: "",
              };
              await melissaServices
                .validateAddress(data)
                .then(async (response) => {
                  setMelissaInfoSnackbar(false);
                  var descriptions = [];
                  var addresslvl = "";
                  setFormattedAddress(
                    response.data.result.records[0].formattedAddress || ""
                  );
                  setInstCity(response.data.result.records[0].locality || "");
                  setInstCountry(response.data.country || null);
                  setInstStateProvince(response.data.stateProvince || null);
                  setInstStreet(
                    response.data.result.records[0].addressLine1 || ""
                  );
                  // if (instStreet2)
                  //   setInstStreet2(
                  //     response.data.result.records[0].addressLine2
                  //   );

                  var zip = response.data.result.records[0].postalCode || "";
                  if (zip.indexOf("-") === -1) {
                    setInstZipCode(
                      response.data.result.records[0].postalCode || ""
                    );
                    setAddressValue(
                      `${response.data.result.records[0].addressLine1 || ""}, ${
                        response.data.result.records[0].locality || ""
                      }, ${
                        response.data.stateProvince
                          ? response.data.stateProvince.stateProvinceCode
                          : ""
                      } ${response.data.result.records[0].postalCode || ""}`
                    );
                  } else {
                    if (instZipCode.indexOf("-") === -1) {
                      var part1 = zip.split("-");
                      setInstZipCode(part1[0] || "");
                      setAddressValue(
                        `${
                          response.data.result.records[0].addressLine1 || ""
                        }, ${response.data.result.records[0].locality || ""}, ${
                          response.data.stateProvince
                            ? response.data.stateProvince.stateProvinceCode
                            : ""
                        } ${part1[0] || ""}`
                      );
                    } else {
                      setInstZipCode(
                        response.data.result.records[0].postalCode || ""
                      );
                      setAddressValue(
                        `${
                          response.data.result.records[0].addressLine1 || ""
                        }, ${response.data.result.records[0].locality || ""}, ${
                          response.data.stateProvince
                            ? response.data.stateProvince.stateProvinceCode
                            : ""
                        } ${response.data.result.records[0].postalCode || ""}`
                      );
                    }
                  }

                  await response.data.result.records[0].results
                    .split(",")
                    .forEach((code) => {
                      if (getMelissaLog(code) !== "") {
                        descriptions.push(getMelissaLog(code));
                      }
                      if (
                        code === "AV11" ||
                        code === "AV12" ||
                        code === "AV13" ||
                        code === "AV14" ||
                        code === "AV21" ||
                        code === "AV22" ||
                        code === "AV23" ||
                        code === "AV24" ||
                        code === "AV25"
                      ) {
                        addresslvl = code;
                      }
                    });

                  setAddressVerificationLevel(addresslvl);
                  if (
                    addresslvl === "AV11" ||
                    addresslvl === "AV12" ||
                    addresslvl === "AV13" ||
                    addresslvl === "AV14" ||
                    addresslvl === "AV21" ||
                    addresslvl === "AV22" ||
                    addresslvl === "AV23" ||
                    addresslvl === "AV24" ||
                    addresslvl === "AV25"
                  ) {
                    setMelissaMessageSnackbar(
                      "Address adjusted/verified, thank you."
                    );
                    setMelissaSeveritySnackbar("Success_Icon");
                    setMelissaInfoSnackbar(true);
                    setMelissaSave(true);
                    setAddressChanged(false);
                  } else {
                    setMelissaResults(descriptions);
                    setMelissaMessageSnackbar(
                      "The address could not be verified at least up to the zip or post code level."
                    );
                    setMelissaSeveritySnackbar("Warning_Icon");
                    setMelissaInfoSnackbar(true);
                    setLoading(false);
                  }
                  setCanSubmitAlert(false);
                  setCanSubmitModal(false);
                })
                .catch((error) => {
                  setLoading(false);
                  if (error.response.data.error.errorCode === "err369") {
                    setAddressToLog(`{
                      Address: ${addressValue},
                      Country: ${instCountry.description},
                    }`);
                    setMelissaCreaditsDialog(true);
                    setMelissaMessageSnackbar(
                      "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                    );
                    setMelissaSeveritySnackbar("Warning_Icon");
                    setMelissaInfoSnackbar(true);
                  } else {
                    setMelissaInfoSnackbar(false);
                    setSaveError(error.response.data.error.error);
                  }
                });
            } else {
              validateUserAddressDuplicate();
            }
          }
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setLoading(false);
        });
    } else {
      handleNext();
    }
  };

  const fetchCompanyData = () => {
    setPageLoad(true);
    companyServices
      .getCompanyByID(currentCompanyID)
      .then((response) => {
        setCompany(response.data);
      })
      .catch((error) => setSaveError(error.response.data.message))
      .finally(() => {
        setPageLoad(false);
      });
  };

  /*------------------Start of Function for Order Quantities----------------------*/

  const saveQuantities = async (continueToPlaceOrderI = false) => {
    //function to save order quantities
    if (
      totalQuickMount === totalWallPlates ||
      orderType === "Bulk Inventory" ||
      isRMA === true ||
      (company !== null && company !== undefined && company.type === "R")
    ) {
      setButtonLoading(true);
      await orderQuantitiesServices
        .UpdateOrderQuantity({
          OrderID: orderID,
          ProductQuantities: rows2,
          SwitchesNum: totalSwitches,
          SwitchPlatePositionsNum: totalWallPlates,
          QuickMountSwitches: totalQuickMount,
          OrderTotal: Math.floor(parseFloat(totalOrderPrice) * 100) / 100,
          RecalculatePrices: false,
        })
        .then(async () => {
          setDisplayEditUnitPrice(false);
          setContinueToPlaceOrder(false);
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");

          setButtonLoading(false);
          if (orderType === "Project Pack for Home") {
            setSuccess(true);
            setMessage("Saved Successfully");
          } else if (continueToPlaceOrderI === true) {
            handleNext();
          } else {
            if (notifSavedDraftReadyToOrder === false) {
              setDisplayNotifAlert(true);
            } else {
              history.push({
                pathname: isRMA === false ? "/Orders" : "/RMA",
                state: {
                  openOrderSuccess: true,
                },
              });
            }
          }
          setShowWarning(false);
        })
        .catch((error) => {
          setButtonLoading(false);
          setContinueToPlaceOrder(false);
          setSaveError(error.response.data.error.error);
          setShowWarning(false);
        });
    } else {
      setContinueToPlaceOrder(continueToPlaceOrderI);
      //saveQuantitiesFinal();
      if (orderType === "Project Pack for Home") setShowWarning(true);
    }
  };

  const saveQuantitiesFinal = () => {
    //function to save quantities in database

    setButtonLoading(true);

    orderQuantitiesServices
      .UpdateOrderQuantity({
        OrderID: orderID,
        ProductQuantities: rows2,
        SwitchesNum: totalSwitches,
        SwitchPlatePositionsNum: totalWallPlates,
        QuickMountSwitches: totalQuickMount,
        OrderTotal: Math.floor(parseFloat(totalOrderPrice) * 100) / 100,
        RecalculatePrices: false,
      })
      .then(async () => {
        setDisplayEditUnitPrice(false);
        setShowWarning(false);
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        setButtonLoading(false);
        if (orderType === "Project Pack for Home") {
          setSuccess(true);
          setMessage("Saved Successfully");
        } else if (continueToPlaceOrder === true) {
          handleNext();
          setContinueToPlaceOrder(false);
        } else {
          history.push({
            pathname: isRMA === false ? "/Orders" : "/RMA",
            state: {
              openOrderSuccess: true,
            },
          });
        }
      })
      .catch((error) => {
        setContinueToPlaceOrder(false);
        setButtonLoading(false);
        setSaveError(error.response.data.error.error);
      });
  };

  const filterDefaultControllerProducts = (ar, defaultController) => {
    //function that saves products that will be affected and affect the default unit price for default controller
    setHasDefaultController([]);
    var i = 0;
    var j = 0;
    ar.forEach((a) => {
      j = 0;
      a.products.forEach((ap) => {
        if (
          (ap.product.category.categoryCode === 1 &&
            ap.product.controller.productID === defaultController.productID) ||
          (ap.product.category.categoryCode === 4 &&
            ap.product.productID === defaultController.productID) ||
          ap.isDimmer === true
        ) {
          setHasDefaultController((hasDefaultController) => [
            ...hasDefaultController,
            { index1: i, index2: j },
          ]);
        }
        j++;
      });
      i++;
    });
  };

  const getLevvenQPosition = (array, type) => {
    //holds position for Levven Q product so we can access it directly later on
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].products.length; j++) {
        if (
          type === "P" &&
          array[i].products[j].product.category.categoryCode === 6
        ) {
          setLevvenQ({ x: i, y: j });
          return;
        }
      }
    }
  };

  const checkIfAccordionOpened = (array, val) => {
    //function to check which accordions to open (which accordions have products that contain quantities)
    for (var i = 0; i < array.length; i++) {
      if (
        array[i].quantity !== null &&
        array[i].quantity.quantity !== null &&
        array[i].quantity.quantity !== 0
      ) {
        setOpened((prevOpened) => [...prevOpened, val]);
        setOpenedAccordion((prevOpened) => [...prevOpened, val]);
        break;
      }
    }
  };

  const getPriceByRange = async (qty, priceRanges, isCompPP = false) => {
    if (isCompPP === false) {
      if (qty !== 0) {
        if (priceRanges.length === 0) {
          setDefUPC(0);
        }
        for (var i = 0; i < priceRanges.length; i++) {
          if (priceRanges[i].to === null) {
            setDefUPC(priceRanges[i].price);
            break;
          } else if (qty >= priceRanges[i].from && qty <= priceRanges[i].to) {
            setDefUPC(priceRanges[i].price);
            break;
          }
        }
      } else {
        setDefUPC(0);
      }
    } else {
      setDefUPC(0);
    }
  };

  function fetchOrderQuantitiesForEdit(
    afterSave = false,
    orderIdAfterSave,
    fromEdit = false
  ) {
    console.log("fetchOrderQuantitiesForEdit");
    setTotalSwitches(0);
    setTotalWallPlates(0);
    setTotalQuickMount(0);
    setTotalOrderPrice(0);
    //fetch data from database only if we are on the order quantities tab
    //getting the values from the database
    setOrderQuantitiesLoading(true);
    setRows2([]);
    setSteps2([]);
    setDefUPC(0);
    setOpened([]);
    setOpenedAccordion([]);
    setSpecialNegotiated2(false);

    const data = { OrderID: afterSave === true ? orderIdAfterSave : orderID };

    orderQuantitiesServices
      .GetProductListByTakeoffWizardStepForOrder(data)
      .then(async (response) => {
        if (
          response.data.defaultControllerPrices !== null &&
          response.data.defaultControllerPrices.length > 0
        )
          setDefaultPriceRanges(response.data.defaultControllerPrices);
        if (response.data.defaultController)
          setDefaultController(response.data.defaultController);
        else setDefaultController({});
        setTotalOrderPrice(response.data.order.orderTotal);
        if (response.data.defaultController)
          filterDefaultControllerProducts(
            response.data.quantities,
            response.data.defaultController
          );
        if (
          response.data.isSpecialNegotiated === true &&
          response.data.isCompPP === false
        ) {
          setSpecialNegotiated2(true);
          setDefUPC(response.data.specialNegotiatedPrice);
        }

        if (response.data.order.takeoff !== null) {
          if (
            response.data.order.takeoff.agreement.dimmerAtControllerPrice ===
            true
          ) {
            setMaxDimmer(
              response.data.order.takeoff.agreement.dimmerUpgradeQuantity
            );
          }
        }

        setCurrency(response.data.order.purchaser.country.cur);
        setOrderIsCompPP(response.data.isCompPP);

        setOrderType2(response.data.order.type);
        await getLevvenQPosition(
          response.data.quantities,
          response.data.order.type
        );

        var tmpArray = [];
        var i = 0;
        await response.data.quantities.forEach(async (steps) => {
          setSteps2((step) => [...step, steps.step.title]);
          setRows2((rows) => [...rows, steps.products]);

          tmpArray.push(steps.products);
          checkIfAccordionOpened(steps.products, i);
          if (steps.products.length > 0) {
            setTotalSwitches(steps.products[0].totalNumberOfSwitches);
            setTotalWallPlates(steps.products[0].totalNumberOfPlatePositions);
            setTotalQuickMount(steps.products[0].totalNumberQuickMountSwitches);
            if (
              response.data.isSpecialNegotiated === false &&
              response.data.defaultControllerPrices !== null
            ) {
              getPriceByRange(
                steps.products[0].totalNumberOfSwitches,
                response.data.defaultControllerPrices,
                response.data.isCompPP
              );
            }
          }
          i++;
        });

        if (
          displayedFillQtyBeforeSubmitWarning === false &&
          ((activeStep === 1 &&
            (currentCompanyType === "B" || currentCompanyType === "I") &&
            orderType === "Bulk Inventory") ||
            (activeStep === 2 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type !== "R" &&
              orderType === "Bulk Inventory") ||
            (activeStep === 0 &&
              currentCompanyType === "R" &&
              orderType === "Bulk Inventory") ||
            (activeStep === 1 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type === "R" &&
              orderType === "Bulk Inventory"))
        ) {
          setFillQtyBeforeSubmit(true);
          setDisplayedFillQtyBeforeSubmitWarning(true);
        }

        setOrderQuantitiesLoading(false);
        if (afterSave === true) {
          setActiveStep(
            orderType === "Project Pack for Home"
              ? currentCompanyType === "L" || currentCompanyIsParent === true
                ? 7
                : 6
              : orderType === "Bulk Inventory"
              ? (currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                company?.type === "R"
                ? 2
                : (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  company?.type !== "R"
                ? 3
                : currentCompanyType === "R"
                ? 1
                : currentCompanyType !== "R"
                ? 2
                : activeStep
              : activeStep
          );
          setLoading(false);
        }

        if (orderType === "Project Pack for Home")
          setDisplayEditUnitPrice(true);

        if (orderType === "Bulk Inventory" && fromEdit)
          setActiveStep((val) => val - 1);
      })
      .catch((error) => {
        setOrderQuantitiesLoading(false);
        if (afterSave === true) setLoading(false);
        setSaveError(error.response.data.error.error);
      });
  }
  /*------------------End of Function for Order Quantities----------------------*/

  //CSS applied to the Box that holds the buttons in the Order Creation Stepper
  const boxButtonStyle = {
    // display: "flex",
    flexDirection: "row",
    mt: 3,
    textAlign: "left",
    overflowX: "auto",
    display:
      !openDrawerMenu &&
      windowWidth <= 100 &&
      ((activeStep === 4 &&
        currentCompanyIsParent !== true &&
        (currentCompanyType === "B" || currentCompanyType === "I") &&
        orderType === "Project Pack for Home") ||
        (activeStep === 5 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          orderType === "Project Pack for Home") ||
        (activeStep === 0 &&
          currentCompanyIsParent !== true &&
          (currentCompanyType === "B" || currentCompanyType === "I")) ||
        (activeStep === 1 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          company?.type !== "R") ||
        (activeStep === 7 &&
          currentCompanyIsParent !== true &&
          (currentCompanyType === "B" || currentCompanyType === "I") &&
          orderType === "Project Pack for Home") ||
        (activeStep === 8 &&
          (currentCompanyType === "L" || currentCompanyIsParent === true) &&
          orderType === "Project Pack for Home"))
        ? "block"
        : "flex",
    marginLeft: "-10px",

    width: "100%",

    paddingRight:
      ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
        orderType === "Project Pack for Home" &&
        activeStep === 3) ||
      (currentCompanyIsParent !== true &&
        (currentCompanyType === "B" || currentCompanyType === "I") &&
        orderType === "Project Pack for Home" &&
        activeStep === 2) ||
      ((currentCompanyType === "L" || currentCompanyIsParent === true) &&
        orderType === "Project Pack for Home" &&
        activeStep === 7) ||
      (currentCompanyIsParent !== true &&
        (currentCompanyType === "B" || currentCompanyType === "I") &&
        orderType === "Project Pack for Home" &&
        activeStep === 6)
        ? "0"
        : "15px",
  };

  //style for modification page
  const boxButtonStyle2 = {
    // display: "flex",
    flexDirection: "row",
    mt: 3,
    textAlign: "left",
    overflowX: "auto",
    display: { xs: "block", sm: "block", md: "block", lg: "flex", xl: "flex" },
    marginLeft: "-10px",

    width: "100%",

    paddingRight: "15px",
  };

  const updateSwitchColor = async () => {
    setLoading(true);
    if (overDefSwitchColor === null || overDefSwitchColor === undefined) {
      setSaveError("Default Switch Color cannot be empty");
      setLoading(false);
      return;
    }
    await orderServices
      .updateOrder({
        OrderID: orderID,
        OverrideDefSwitchColorCode:
          overDefSwitchColor && overDefSwitchColor !== null
            ? overDefSwitchColor?.switchColorCode
            : agreement && agreement !== null
            ? agreement?.defaultSwitchColor &&
              agreement?.defaultSwitchColor !== null
              ? agreement?.defaultSwitchColor.switchColorCode
              : agreement?.defaultSwitch && agreement?.defaultSwitch !== null
              ? agreement?.defaultSwitch?.switchColorCode
              : ""
            : "",
      })
      .then(async (response) => {
        await setPristine();
        setFormChanged(false);
        sessionStorage.removeItem("unsavedChanges");

        setLoading(false);
        setOverDefSwitchColor(
          overDefSwitchColor &&
            overDefSwitchColor !== null &&
            overDefSwitchColor !== undefined
            ? overDefSwitchColor
            : agreement && agreement !== null && agreement !== undefined
            ? agreement.defaultSwitchColor &&
              agreement.defaultSwitchColor !== null
              ? agreement.defaultSwitchColor
              : agreement.defaultSwitch && agreement.defaultSwitch !== null
              ? agreement.defaultSwitch
              : null
            : null
        );
        setOldSwitchColor(
          overDefSwitchColor &&
            overDefSwitchColor !== null &&
            overDefSwitchColor !== undefined
            ? overDefSwitchColor
            : agreement && agreement !== null && agreement !== undefined
            ? agreement.defaultSwitchColor &&
              agreement.defaultSwitchColor !== null
              ? agreement.defaultSwitchColor
              : agreement.defaultSwitch && agreement.defaultSwitch !== null
              ? agreement.defaultSwitch
              : null
            : null
        );
        setDisplayChangeSwitchColor(false);
        setSuccess(true);
        setMessage("Saved Successfully");
      })
      .catch((error) => {
        setSaveError(error.response.data.error.error);
        setLoading(false);
      });
  };

  const handleMakeChangesPage = async () => {
    console.log("handleMakeChangesPage");
    if (
      displayAddSwitches === false &&
      displayChangeSwitchColor === false &&
      displayChangeSwitches === false
    ) {
      checkWhichModalToOpen(true);
    } else if (displayAddSwitches === true) {
      await submitChangeAndAddValues(false, true);
    } else if (displayChangeSwitchColor === true) {
      await updateSwitchColor();
    } else if (displayChangeSwitches === true) {
      await submitChangeAndAddValues(true, false);
    }
  };
  console.log("company", company);

  console.log("activeStep", activeStep);
  console.log("currentCompanyIsParent", currentCompanyIsParent);
  return (
    <Paper
      elevation={0}
      sx={{
        paddingBottom: "30px",
        minHeight: displayAddSwitches ? "70vh" : "",
      }}
    >
      {displayNotifAlert && (
        <NotificationOrderAlert
          open={displayNotifAlert}
          button1Function={() => {
            save(null, false, false, false, true, false);
          }}
          button2Function={() => {
            save(null, false, false, false, false, true);
          }}
          button3Function={() => {
            save(null, false, false, false, false, false);
          }}
          setError={setSaveError}
          user={receiver}
          setUser={setReceiver}
          companyID={company?.companyID}
        />
      )}
      {displayAddressVerificationWarning !== false ? (
        <AlertDialogSlide
          title={"Address Not Verified"}
          textContent={
            "The address entered is not verified. Are you sure you wish to continue?"
          }
          saveButtonLoading={submitLoading}
          disableUnsave={submitLoading}
          save={() => save("", true)}
          saveText="Ok"
          unsaveText="Cancel"
          unsave={() => setDisplayAddressVerificationWarning(false)}
        />
      ) : null}
      {/* {Prompt} */}
      {createError ? (
        <AlertDialogSlide
          title="Error"
          textContent={createError}
          save={() => setCreateError("")}
          saveText="Ok"
        />
      ) : null}

      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />
      {alertNotification !== false ? (
        <AlertDialogSlide
          title={"Send Notification"}
          textContent={`A notification for draft Order ID ${orderID} has been queued for ${receiver?.firstName} ${receiver?.lastName} <${receiver?.userName}>.`}
          saveButtonLoading={submitLoading}
          disableUnsave={submitLoading}
          save={() => {
            // history.push(isRMA === false ? "/Orders" : "/rma");
            history.push({
              pathname: isRMA === false ? "/Orders" : "/RMA",
              state: {
                openOrderSuccess: true,
              },
            });
            setAlertNotification(false);
          }}
          saveText="Ok"
        />
      ) : null}
      {showWarning ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "The number of Quick Mount switches you entered is not equal to the number of plate positions. Do you wish to continue?"
          }
          save={() => saveQuantitiesFinal()}
          saveText="Yes"
          disableUnsave={buttonLoading === true}
          unsave={() => setShowWarning(false)}
          unsaveText="No"
          saveButtonLoading={buttonLoading}
        />
      ) : null}

      {melissaCreditsDialog === true ? (
        <>
          <AlertDialogSlide
            title="Warning"
            textContent={"Address check failed, cancel or continue anyway?"}
            save={() => {
              const logDetail = {
                Status:
                  "Melissa Address Check Failed. Melissa tokens may be finished.",
                Description: `{On Order creation, Job Number: ${jobNbr}, Company Name: ${company?.name}, Purchaser: ${purchaserEmail.userName}}`,
                AdditionalInfo: AddressToLog,
                LogDetailTimeStamp: new Date().toISOString(),
              };
              addLogAndLogDetail(
                "Melissa",
                "Melissa Address Check Failed",
                new Date().toISOString(),
                new Date().toISOString(),
                [logDetail],
                "onSave"
              ).then(() => {
                setMelissaResults([]);
                setFormattedAddress("");
                setAddressChanged(false);
                setMelissaCreaditsDialog(false);
                validateUserAddressDuplicate();
              });
            }}
            saveText="Continue"
            disableUnsave={logLoading === true || loading === true}
            unsaveLoading={logUnsaveLoading}
            disableSave={logUnsaveLoading === true}
            unsave={() => {
              const logDetail = {
                Status:
                  "Melissa Address Check Failed. Melissa tokens may be finished.",
                Description: "",
                AdditionalInfo: AddressToLog,
                LogDetailTimeStamp: new Date().toISOString(),
              };
              addLogAndLogDetail(
                "Melissa",
                "Melissa Address Check Failed",
                new Date().toISOString(),
                new Date().toISOString(),
                [logDetail],
                "onUnsave"
              ).then(() => {
                setMelissaResults([]);
                setMelissaCreaditsDialog(false);
              });
            }}
            unsaveText="Cancel"
            saveButtonLoading={logLoading || loading}
          />
          <InfoSnackbar
            open={melissaInfoSnackbar}
            message={melissaMessageSnackbar}
            severity={melissaSeveritySnackbar}
            handleClose={handleSnackBarClose}
          />
        </>
      ) : null}

      {logError !== "" ? (
        <AlertSnackBar
          message={logError}
          handleClose={handleLogCloseError}
          open={logError !== ""}
        />
      ) : null}
      {fillQtyBeforeSubmit !== false ? (
        <AlertDialogSlide
          title={isRMA === false ? "Bulk Inventory Order" : "RMA"}
          textContent={`Select the products you need and enter a quantity. Provide a ${
            isRMA === false
              ? "PO number and request date"
              : "Service Case/Ticket Number and Delivery Date"
          } before submitting your order to Levven.`}
          save={() => setFillQtyBeforeSubmit(false)}
          saveText="Ok"
        />
      ) : null}

      {errorOnFinish ? (
        <>
          <CustomModal openModal={errorOnFinish} width="600px">
            <Typography variant="h6" component="span" align="left">
              {" "}
              {errorArray[0]}
            </Typography>
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                maxHeight: "50vh",
                paddingTop: "20px",
                // justifyContent: "center",
                textAlign: "left",
                // textJustify: "inter - word",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <p>
                  {errorArray.map((paragraph, index) => {
                    return index !== 0 ? (
                      <div>
                        <p>{paragraph}</p>
                        <div style={{ height: "10px" }} />
                      </div>
                    ) : null;
                  })}
                </p>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "25px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setErrorArray([]);
                      setErrorOnFinish(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        </>
      ) : (
        ""
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}></div>
      <Box
        sx={{
          minHeight: displayAddSwitches ? "70vh" : "",
          display: displayAddSwitches ? "flex" : "",
          flexDirection: displayAddSwitches ? "column" : "",
          justifyContent: displayAddSwitches ? "space-between" : "",
        }}
      >
        <>
          <div>
            {/* Step 1 for Levven */}
            {activeStep === 0 &&
            (currentCompanyType === "L" || currentCompanyIsParent === true) ? (
              //&& (currentCompanyType === "L" || hasParentCompany)
              <CompanyStep
                company={company}
                setCompany={setCompany}
                companyError={companyError}
                setCompanyError={setCompanyError}
                setSaveError={setSaveError}
                setDirty={setDirty}
                setTakeoffs={setTakeoffs}
                resetFields={resetFields}
                purchaserEmail={purchaserEmail}
                setPurchaserEmail={setPurchaserEmail}
                purchaserEmailError={purchaserEmailError}
                setPurchaserEmailError={setPurchaserEmailError}
                purchasers={purchasers}
                //purchasers={companiesUsers}
                orderID={orderID}
                companyID={currentCompanyID}
              />
            ) : null}

            {/* Step 2 form Levven - Step 1 for Builder and Installer */}
            {(activeStep === 0 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I")) ||
            (activeStep === 1 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type !== "R") ? (
              <OrderTypeStep
                orderType={orderType}
                setOrderType={setOrderType}
                orderTypeError={orderTypeError}
                setOrderTypeError={setOrderTypeError}
                setDirty={setDirty}
                setTakeoff={setTakeoff}
                setAgreement={setAgreement}
                setInstallerContact={setInstallerContact}
                setOverDefSwitchColor={setOverDefSwitchColor}
                setForModelHome={setForModelHome}
                setSpecialNegotiated={setSpecialNegotiated}
                setShippingMethod={setShippingMethod}
                company={company}
                setShelfInventoryWarning={setShelfInventoryWarning}
                orderID={orderID}
              />
            ) : null}

            {/* Step 3 for Levven - Step 2 for Builder and Installer */}
            {(activeStep === 1 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 2 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ? (
              <TakeoffStep
                setDirty={setDirty}
                setSaveError={setSaveError}
                windowWidth={windowWidth}
                takeoffs={takeoffs}
                isCompPP={isCompPP}
                canCreateCompPP={canCreateCompPP}
                setIsCompPP={setIsCompPP}
                takeoff={takeoff}
                openDrawerMenu={openDrawerMenu}
                setTakeoff={setTakeoff}
                takeoffError={takeoffError}
                setTakeoffError={setTakeoffError}
                setShippingMethod={setShippingMethod}
                setAgreement={setAgreement}
                setForModelHome={setForModelHome}
                forModelHome={forModelHome}
                setSpecialNegotiated={setSpecialNegotiated}
                specialNegotiated={specialNegotiated}
                orderID={orderID}
              />
            ) : null}

            {/* Step 4 for Levven - Step 3 for Builder and Installer */}
            {(activeStep === 2 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 3 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ? (
              <ProjectDetailStep
                setAddressChange={setAddressChange}
                setDirty={setDirty}
                countries={countries}
                setCountries={setCountries}
                stateProvinces={stateProvinces}
                setStateProvinces={setStateProvinces}
                fetchCountries={fetchCountries}
                fetchStateProvince={fetchStateProvince}
                jobName={jobName}
                setJobName={setJobName}
                jobNameError={jobNameError}
                setJobNameError={setJobNameError}
                jobNbr={jobNbr}
                setJobNbr={setJobNbr}
                jobNbrError={jobNbrError}
                setJobNbrError={setJobNbrError}
                lotNbr={lotNbr}
                setLotNbr={setLotNbr}
                lotNbrError={lotNbrError}
                setLotNbrError={setLotNbrError}
                instStreet={instStreet}
                setInstStreet={setInstStreet}
                instStreetError={instStreetError}
                setInstStreetError={setInstStreetError}
                addressVerificationLevel={addressVerificationLevel}
                instStreet2={instStreet2}
                setInstStreet2={setInstStreet2}
                instCity={instCity}
                setInstCity={setInstCity}
                instCityError={instCityError}
                setInstCityError={setInstCityError}
                instCountry={instCountry}
                setInstCountry={setInstCountry}
                instCountryError={instCountryError}
                setInstCountryError={setInstCountryError}
                instStateProvince={instStateProvince}
                setInstStateProvince={setInstStateProvince}
                instStateProvinceError={instStateProvinceError}
                setInstStateProvinceError={setInstStateProvinceError}
                instZipCode={instZipCode}
                setInstZipCode={setInstZipCode}
                instZipCodeError={instZipCodeError}
                setInstZipCodeError={setInstZipCodeError}
                setOrderType={setOrderType}
                orderTypeError={orderTypeError}
                setOrderTypeError={setOrderTypeError}
                setSaveError={setSaveError}
                orderID={orderID}
                setAddressChanged={setAddressChanged}
                suggestions={suggestions}
                addressValue={addressValue}
                verifyAddress={verifyAddress}
                fetchAddresses={fetchAddresses}
                addressError={addressError}
                setAddressError={setAddressError}
                setAddressPicked={setAddressPicked}
                setAddressValue={setAddressValue}
                setAddressVerified={setAddressVerified}
              />
            ) : null}

            {/* Step 5 for Levven - Step 4 for Builder and Installer in Project Pack / 
            Step 3 for Builder and Installer in Bulk Inventory - Step 1 for Reseller */}
            {(activeStep === 0 && currentCompanyType === "R") ||
            (activeStep === 1 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type === "R") ||
            (activeStep === 1 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Bulk Inventory") ||
            (activeStep === 2 &&
              company?.type !== "R" &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Bulk Inventory") ||
            (activeStep === 5 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 6 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ? (
              <PurchasingDetailStep
                setShippingAddressIDs={setShippingAddressIDs}
                shippingAddressIDs={shippingAddressIDs}
                setForModelHome={setForModelHome}
                isRMA={isRMA}
                orderFulfillmentThreshold={orderFulfillmentThreshold}
                forModelHome={forModelHome}
                setSpecialNegotiated={setSpecialNegotiated}
                specialNegotiated={specialNegotiated}
                windowWidth={windowWidth}
                isCompPP={isCompPP}
                openDrawerMenu={openDrawerMenu}
                canCreateCompPP={canCreateCompPP}
                setIsCompPP={setIsCompPP}
                setDirty={setDirty}
                orderType={orderType}
                minimumRequestedDeliveryDate={minimumRequestedDeliveryDate}
                setSaveError={setSaveError}
                purchaseOrderNb={purchaseOrderNb}
                setPurchaseOrderNb={setPurchaseOrderNb}
                purchaseOrderNbError={purchaseOrderNbError}
                setPurchaseOrderNbError={setPurchaseOrderNbError}
                requestedDate={requestedDate}
                setRequestedDate={setRequestedDate}
                requestedDateError={requestedDateError}
                setRequestedDateError={setRequestedDateError}
                shipToAddress={shipToAddress}
                setShipToAddress={setShipToAddress}
                shipToAddressError={shipToAddressError}
                setShipToAddressError={setShipToAddressError}
                shippingMethod={shippingMethod}
                setShippingMethod={setShippingMethod}
                shippingMethodError={shippingMethodError}
                setShippingMethodError={setShippingMethodError}
                shipWithOtherGoods={shipWithOtherGoods}
                setShipWithOtherGoods={setShipWithOtherGoods}
                company={company}
                toUpdateShipAddID={toUpdateShipAddID}
                setShipModalUpdateOpen={setShipModalUpdateOpen}
                setShipModalOpen={setShipModalOpen}
                setToUpdateShipAddID={setToUpdateShipAddID}
                shipModalOpen={shipModalOpen}
                shipModalUpdatelOpen={shipModalUpdatelOpen}
                orderID={orderID}
              />
            ) : null}

            {/* Step 6 for Levven - Step 5 for Builder and Installer */}
            {(activeStep === 3 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 4 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ? (
              <ModificationQuestion
                setDirty={setDirty}
                setSaveError={setSaveError}
              />
            ) : null}

            {/* Step 7 for Levven - Step 6 for Builder and Installer */}
            {/* {(activeStep === 4 &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 5 &&
              currentCompanyType === "L" &&
              orderType === "Project Pack for Home") ? (
              <OverrideSwitchColorStep
                setDirty={setDirty}
                setSaveError={setSaveError}
                overDefSwitchColor={overDefSwitchColor}
                setOverDefSwitchColor={setOverDefSwitchColor}
                agreement={agreement}
                orderID={orderID}
              />
            ) : null} */}

            {/* Step 8 for Levven - Step 7 for Builder and Installer */}
            {(activeStep === 4 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home") ||
            (activeStep === 5 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home") ? (
              <>
                {orderID === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "60vh",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{ color: "#0077c6", margin: "auto 0" }}
                    />
                  </div>
                ) : (
                  <OrderProductsModification
                    switchColors={switchColors}
                    setFormChanged={setFormChanged}
                    title="Assembly"
                    setSaveError={setSaveError}
                    displayAddSwitches={displayAddSwitches}
                    displayChangeSwitchColor={displayChangeSwitchColor}
                    displayChangeSwitches={displayChangeSwitches}
                    arrayFrom={arrayFrom}
                    agreementDefaultSwitch={agreementDefaultSwitch}
                    overDefSwitchColor={overDefSwitchColor}
                    setOverDefSwitchColor={setOverDefSwitchColor}
                    agreement={agreement}
                    orderID={orderID}
                    arrayFrom2={arrayFrom2}
                    arrayTo2={arrayTo2}
                    pageLoad={pageLoad}
                    arrayTo={arrayTo}
                    defaultSwitch={arrayFrom2.length > 0 ? arrayFrom2[0] : null}
                    setDirty={setDirty}
                    maxQuantity={maxQuantity}
                    selectedFrom={selectedFrom}
                    selectedTo={selectedTo}
                    setSelectedFrom={setSelectedFrom}
                    setSelectedTo={setSelectedTo}
                    selectedFrom2={selectedFrom2}
                    selectedTo2={selectedTo2}
                    setSelectedFrom2={setSelectedFrom2}
                    setSelectedTo2={setSelectedTo2}
                    valueToAdd={valueToAdd}
                    setValueToAdd={setValueToAdd}
                    rows={rows}
                    setRows={setRows}
                    addSwitchRows={rowsSwitchAdd}
                    setAddSwitchRows={setRowsSwitchAdd}
                    addAssemblyRows={rowsAssemblyAdd}
                    setAddAssemblyRows={setRowsAssemblyAdd}
                  />
                )}
              </>
            ) : null}

            {(activeStep === 6 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Project Pack for Home" &&
              displayEditUnitprice === false) ||
            (activeStep === 7 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Project Pack for Home" &&
              displayEditUnitprice === false) ||
            (activeStep === 3 &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Bulk Inventory") ||
            (activeStep === 4 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Bulk Inventory") ||
            (activeStep === 2 && currentCompanyType === "R") ||
            (activeStep === 3 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type === "R") ? (
              <OrderReviewPage
                isRMA={isRMA}
                currency={currency2}
                orderID={orderID}
                orderType={orderType}
              />
            ) : null}

            {displayEditUnitprice ||
            (activeStep === 1 && currentCompanyType === "R") ||
            (activeStep === 2 &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              company?.type === "R") ||
            (activeStep === 2 &&
              currentCompanyIsParent !== true &&
              (currentCompanyType === "B" || currentCompanyType === "I") &&
              orderType === "Bulk Inventory") ||
            (activeStep === 3 &&
              company?.type !== "R" &&
              (currentCompanyType === "L" || currentCompanyIsParent === true) &&
              orderType === "Bulk Inventory") ? (
              <EditUnitPricesStep
                orderID={orderID}
                isReseller={
                  company !== null &&
                  company !== undefined &&
                  company.type === "R"
                }
                windowWidth={windowWidth}
                setPageLoad={setOrderQuantitiesLoading}
                pageLoad={orderQuantitiesLoading}
                openDrawerMenu={openDrawerMenu}
                fetchOrderQuantitiesForEdit={fetchOrderQuantitiesForEdit}
                filterDefaultControllerProducts={
                  filterDefaultControllerProducts
                }
                isRMA={isRMA}
                setFormChanged={setFormChanged}
                getLevvenQPosition={getLevvenQPosition}
                checkIfAccordionOpened={checkIfAccordionOpened}
                getPriceByRange={getPriceByRange}
                totalSwitches={totalSwitches}
                setTotalSwitches={setTotalSwitches}
                totalWallPlates={totalWallPlates}
                setTotalWallPlates={setTotalWallPlates}
                totalQuickMount={totalQuickMount}
                setTotalQuickMount={setTotalQuickMount}
                totalOrderPrice={totalOrderPrice}
                setTotalOrderPrice={setTotalOrderPrice}
                rows={rows2}
                setRows={setRows2}
                steps={steps2}
                setSteps={setSteps2}
                getError={saveError}
                setGetError={setSaveError}
                opened={opened}
                setOpened={setOpened}
                defUPC={defUPC}
                setDefUPC={setDefUPC}
                openedAccordion={openedAccordion}
                setOpenedAccordion={setOpenedAccordion}
                specialNegotiated={specialNegotiated2}
                setSpecialNegotiated={setSpecialNegotiated2}
                defaultPriceRanges={defaultPriceRanges}
                setDefaultPriceRanges={setDefaultPriceRanges}
                defaultController={defaultController}
                setDefaultController={setDefaultController}
                maxDimmer={maxDimmer}
                setMaxDimmer={setMaxDimmer}
                currency={currency}
                setCurrency={setCurrency}
                orderIsCompPP={orderIsCompPP}
                setOrderIsCompPP={setOrderIsCompPP}
                orderType={orderType2}
                setOrderType={setOrderType2}
                hasDefaultController={hasDefaultController}
                setHasDefaultController={setHasDefaultController}
                levvenQ={levvenQ}
                setLevvenQ={setLevvenQ}
              />
            ) : null}

            {/* {activeStep === 7 ? (
              <ShipWithOtherOrdersStep
                order={order}
                unshippedOrders={unshippedOrders}
                orderTotal={orderTotal}
                setOrderTotal={setOrderTotal}
                maxOrderTotal={maxOrderTotal}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null} */}
          </div>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 3) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 2) ||
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 7) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 6) ||
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 6) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 5) ||
                (activeStep === 7 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 8 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 1 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 2 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 2 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 3 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 3 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 0 && currentCompanyType === "R") ||
                (activeStep === 1 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  company?.type === "R") ||
                (activeStep === 1 && currentCompanyType === "R") ||
                (activeStep === 2 && currentCompanyType === "R") ||
                displayChangeSwitches === true ||
                displayAddSwitches
                  ? 12
                  : ((currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                      currentCompanyIsParent !== true &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 4) ||
                    ((currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 5)
                  ? 10
                  : 6
              }
              xl={
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 3) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  currentCompanyIsParent !== true &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 2) ||
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 7) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  currentCompanyIsParent !== true &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 6) ||
                ((currentCompanyType === "L" ||
                  currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 6) ||
                ((currentCompanyType === "B" || currentCompanyType === "I") &&
                  currentCompanyIsParent !== true &&
                  orderType === "Project Pack for Home" &&
                  activeStep === 5) ||
                (activeStep === 7 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  currentCompanyIsParent !== true &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 8 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 1 &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  currentCompanyIsParent !== true &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 2 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 2 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 3 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 3 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Bulk Inventory") ||
                (activeStep === 0 && currentCompanyType === "R") ||
                (activeStep === 1 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  company?.type === "R") ||
                (activeStep === 1 && currentCompanyType === "R") ||
                (activeStep === 2 && currentCompanyType === "R") ||
                displayChangeSwitches === true ||
                displayAddSwitches
                  ? 12
                  : 6
              }
            >
              <Box
                sx={
                  (activeStep === 4 &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                    currentCompanyIsParent !== true &&
                    orderType === "Project Pack for Home") ||
                  (activeStep === 5 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    orderType === "Project Pack for Home")
                    ? boxButtonStyle2
                    : boxButtonStyle
                }
              >
                <CustomButton
                  disable={
                    activeStep === 0 ||
                    addSwitchLoading === true ||
                    changeSwitchLoading === true ||
                    changeSwitchColorLoading === true ||
                    loading === true ||
                    submitLoading === true ||
                    (fromTakeoff &&
                      currentCompanyType !== "L" &&
                      activeStep === 1) ||
                    (isRMA && currentCompanyType !== "L" && activeStep === 1) ||
                    orderQuantitiesLoading === true ||
                    buttonLoading
                  }
                  toggle={handleBack}
                  stepper={true}
                >
                  {displayChangeSwitchColor === false &&
                  displayAddSwitches === false &&
                  displayChangeSwitches === false
                    ? "Back"
                    : "Cancel"}
                </CustomButton>

                {windowWidth < 700 &&
                ((activeStep === 7 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ||
                  (activeStep === 8 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    orderType === "Project Pack for Home")) ? (
                  <div style={{ height: "10px" }} />
                ) : null}

                {((orderType === "Project Pack for Home" &&
                  activeStep === 7 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true)) ||
                  (orderType === "Project Pack for Home" &&
                    activeStep === 6 &&
                    currentCompanyIsParent !== true &&
                    currentCompanyType !== "L") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 4 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type !== "R") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 3 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type === "R") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 3 &&
                    currentCompanyIsParent !== true &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I")) ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 2 &&
                    currentCompanyType === "R")) &&
                displayEditUnitprice === false ? null : (
                  <Box sx={{ flex: "1 1 auto" }} />
                )}

                {((orderType === "Project Pack for Home" &&
                  activeStep === 7 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true)) ||
                  (orderType === "Project Pack for Home" &&
                    activeStep === 6 &&
                    currentCompanyType !== "L") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 4 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type !== "R") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 3 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type === "R") ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 3 &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I")) ||
                  (orderType === "Bulk Inventory" &&
                    activeStep === 2 &&
                    currentCompanyType === "R")) &&
                displayEditUnitprice === false ? (
                  <>
                    <CustomButton
                      disable={
                        activeStep === 0 ||
                        loading === true ||
                        submitLoading === true
                      }
                      loading={orderQuantitiesLoading}
                      toggle={() =>
                        fetchOrderQuantitiesForEdit(false, "", true)
                      }
                      stepper={true}
                    >
                      {userRole === "Levven Admin" && currentCompanyType === "L"
                        ? "Edit Prices/Quantities"
                        : "Edit Quantities"}
                    </CustomButton>
                    <div style={{ width: "10px" }} />
                    {windowWidth < 700 &&
                    ((activeStep === 6 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Project Pack for Home") ||
                      (activeStep === 7 &&
                        (currentCompanyType === "L" ||
                          currentCompanyIsParent === true) &&
                        orderType === "Project Pack for Home")) ? (
                      <div style={{ height: "10px" }} />
                    ) : null}
                    <Box sx={{ flex: "1 1 auto" }} />
                  </>
                ) : null}

                {(activeStep === 4 &&
                  (currentCompanyType === "L" ||
                    currentCompanyIsParent === true) &&
                  orderType === "Project Pack for Home") ||
                (activeStep === 3 &&
                  currentCompanyIsParent !== true &&
                  (currentCompanyType === "B" || currentCompanyType === "I") &&
                  orderType === "Project Pack for Home") ? (
                  <>
                    {windowWidth < 700 &&
                    ((activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Project Pack for Home") ||
                      (activeStep === 4 &&
                        (currentCompanyType === "L" ||
                          currentCompanyIsParent === true) &&
                        orderType === "Project Pack for Home")) ? (
                      <div style={{ height: "10px" }} />
                    ) : null}
                    <CustomButton
                      toggle={() => {
                        setSubmitSource("Use Base Plan Quantities");
                        checkWhichModalToOpen();
                      }}
                      loading={loading}
                      extraMarginLeft={"10px"}
                      disable={error || loading === true}
                    >
                      Use Base Plan Quantities
                    </CustomButton>
                    {windowWidth < 700 &&
                    ((activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Project Pack for Home") ||
                      (activeStep === 4 &&
                        (currentCompanyType === "L" ||
                          currentCompanyIsParent === true) &&
                        orderType === "Project Pack for Home")) ? (
                      <div style={{ height: "10px" }} />
                    ) : null}
                    <CustomButton
                      toggle={() => {
                        setSubmitSource("Make Changes/Additions");
                      }}
                      loading={loading}
                      stepper={true}
                    >
                      Make Changes/Additions
                    </CustomButton>
                  </>
                ) : (orderID === 0 &&
                    activeStep === 1 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type !== "R") ||
                  (orderID === 0 &&
                    activeStep === 0 &&
                    currentCompanyIsParent !== true &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I")) ? (
                  <>
                    {windowWidth < 700 &&
                    ((activeStep === 0 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      company) ||
                      (activeStep === 1 &&
                        (currentCompanyType === "L" ||
                          currentCompanyIsParent === true) &&
                        company)) ? (
                      <div style={{ height: "10px" }} />
                    ) : null}

                    <CustomButton
                      extraMarginLeft={"10px"}
                      toggle={async () => {
                        // setShelfInventoryWarning(true);
                        await setOrderType("Bulk Inventory");
                        // setPlaceOrderModalOpen(true);
                      }}
                      disable={error || loading === true}
                    >
                      Bulk Inventory
                    </CustomButton>
                    {windowWidth < 700 &&
                    ((activeStep === 0 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      company) ||
                      (activeStep === 1 &&
                        (currentCompanyType === "L" ||
                          currentCompanyIsParent === true) &&
                        company)) ? (
                      <div style={{ height: "10px" }} />
                    ) : null}
                    <CustomButton
                      toggle={async () => {
                        await setOrderType("Project Pack for Home");
                      }}
                      stepper={true}
                    >
                      Quick Kit for Home
                    </CustomButton>
                  </>
                ) : (
                  <>
                    {(activeStep === 1 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type !== "R" &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 0 && currentCompanyType === "R") ||
                    (activeStep === 1 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company &&
                      company.type === "R") ||
                    (activeStep === 6 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 7 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 4 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 && currentCompanyType === "R") ||
                    ((currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                      currentCompanyIsParent !== true &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 4) ||
                    ((currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 5) ||
                    (activeStep === 3 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R") ? null : (
                      /// TODO: checl all the condition on the next button
                      <CustomButton
                        toggle={
                          (activeStep === 2 &&
                            currentCompanyIsParent !== true &&
                            (currentCompanyType === "B" ||
                              currentCompanyType === "I") &&
                            orderType === "Bulk Inventory") ||
                          (activeStep === 3 &&
                            (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            orderType === "Bulk Inventory") ||
                          (activeStep === 1 && currentCompanyType === "R") ||
                          (activeStep === 2 &&
                            (currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                            company?.type === "R")
                            ? () => setPlaceOrderModalOpen(true)
                            : ((currentCompanyType === "L" ||
                                currentCompanyIsParent === true) &&
                                orderType === "Project Pack for Home" &&
                                activeStep === 4) ||
                              (currentCompanyIsParent !== true &&
                                (currentCompanyType === "B" ||
                                  currentCompanyType === "I") &&
                                orderType === "Project Pack for Home" &&
                                activeStep === 3)
                            ? () => save("", false)
                            : (activeStep === 6 &&
                                currentCompanyIsParent !== true &&
                                (currentCompanyType === "B" ||
                                  currentCompanyType === "I") &&
                                orderType === "Project Pack for Home") ||
                              (activeStep === 7 &&
                                (currentCompanyType === "L" ||
                                  currentCompanyIsParent === true) &&
                                orderType === "Project Pack for Home")
                            ? () => save("", false, false, true)
                            : (orderType === "Project Pack for Home" &&
                                currentCompanyType !== "L" &&
                                activeStep === 3) ||
                              (orderType === "Bulk Inventory" &&
                                (currentCompanyType === "L" ||
                                  currentCompanyIsParent === true) &&
                                company?.type === "R" &&
                                activeStep === 1) ||
                              (orderType === "Bulk Inventory" &&
                                (currentCompanyType === "L" ||
                                  currentCompanyIsParent === true) &&
                                company?.type !== "R" &&
                                activeStep === 2) ||
                              (orderType === "Bulk Inventory" &&
                                currentCompanyIsParent !== true &&
                                (currentCompanyType === "B" ||
                                  currentCompanyType === "I") &&
                                activeStep === 1) ||
                              (orderType === "Bulk Inventory" &&
                                currentCompanyType === "R" &&
                                activeStep === 0)
                            ? checkRequestedDate
                            : (orderType === "Project Pack for Home" &&
                                (currentCompanyType === "L" ||
                                  currentCompanyIsParent === true) &&
                                activeStep === 3) ||
                              (orderType === "Project Pack for Home" &&
                                currentCompanyIsParent !== true &&
                                currentCompanyType !== "L" &&
                                activeStep === 2)
                            ? checkIfJobNumberExists
                            : handleNext
                        }
                        disable={activeStep === steps.length - 1}
                        loading={
                          (loading && !(activeStep === steps.length - 1)) ||
                          buttonLoading === true
                        }
                      >
                        Next
                      </CustomButton>
                    )}

                    {((currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                      currentCompanyIsParent !== true &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 4) ||
                    ((currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                      orderType === "Project Pack for Home" &&
                      activeStep === 5) ? (
                      <>
                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <div style={{ height: "10px" }} />
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <CustomButton
                            extraMarginLeft={"10px"}
                            stepper={true}
                            loading={changeSwitchLoading}
                            disable={
                              addSwitchLoading ||
                              changeSwitchColorLoading ||
                              loading
                            }
                            toggle={async () => {
                              await getProductsToChangeFrom(orderID);
                            }}
                          >
                            Change Switches
                          </CustomButton>
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <div style={{ height: "10px" }} />
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <CustomButton
                            stepper={true}
                            loading={addSwitchLoading}
                            disable={
                              changeSwitchColorLoading ||
                              changeSwitchLoading ||
                              loading
                            }
                            toggle={async () => {
                              await getProductsForAdd(orderID);
                            }}
                          >
                            Add Switches
                          </CustomButton>
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <div style={{ height: "10px" }} />
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <CustomButton
                            loading={changeSwitchColorLoading}
                            disable={
                              addSwitchLoading || changeSwitchLoading || loading
                            }
                            stepper={true}
                            toggle={async () => {
                              await fetchSwitchColors();
                            }}
                          >
                            Change Switch Color
                          </CustomButton>
                        ) : null}

                        {displayAddSwitches === false &&
                        displayChangeSwitchColor === false &&
                        displayChangeSwitches === false ? (
                          <div style={{ height: "10px" }} />
                        ) : null}

                        <CustomButton
                          toggle={handleMakeChangesPage}
                          loading={loading || buttonLoading}
                          disable={
                            error ||
                            loading === true ||
                            orderQuantitiesLoading === true ||
                            addSwitchLoading === true ||
                            changeSwitchLoading === true ||
                            changeSwitchColorLoading === true
                          }
                          stepper={true}
                        >
                          Next
                        </CustomButton>
                      </>
                    ) : null}

                    {((currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory" &&
                      company?.type !== "R" &&
                      activeStep === 2) ||
                    ((currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory" &&
                      company?.type === "R" &&
                      activeStep === 1) ||
                    ((currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                      currentCompanyIsParent !== true &&
                      orderType === "Bulk Inventory" &&
                      activeStep === 1) ||
                    (currentCompanyType === "R" &&
                      orderType === "Bulk Inventory" &&
                      activeStep === 0) ||
                    displayEditUnitprice === true ? (
                      <CustomButton
                        toggle={
                          displayEditUnitprice === true
                            ? () => saveQuantities()
                            : ((currentCompanyType === "L" ||
                                currentCompanyIsParent === true) &&
                                orderType === "Bulk Inventory" &&
                                company?.type !== "R" &&
                                activeStep === 2) ||
                              ((currentCompanyType === "L" ||
                                currentCompanyIsParent === true) &&
                                orderType === "Bulk Inventory" &&
                                company?.type === "R" &&
                                activeStep === 1) ||
                              ((currentCompanyType === "B" ||
                                currentCompanyType === "I") &&
                                orderType === "Bulk Inventory" &&
                                activeStep === 1) ||
                              (currentCompanyType === "R" &&
                                orderType === "Bulk Inventory" &&
                                activeStep === 0)
                            ? () => save("", false, true)
                            : ((currentCompanyType === "B" ||
                                currentCompanyType === "I") &&
                                orderType === "Project Pack for Home" &&
                                activeStep === 4) ||
                              ((currentCompanyType === "L" ||
                                currentCompanyIsParent === true) &&
                                orderType === "Project Pack for Home" &&
                                activeStep === 5)
                            ? () => {
                                checkWhichModalToOpen(true);
                              }
                            : () => {} /*console.log("More Options")*/
                        }
                        loading={loading || buttonLoading}
                        disable={
                          error ||
                          loading === true ||
                          orderQuantitiesLoading === true
                        }
                        stepper={true}
                      >
                        {displayEditUnitprice === true
                          ? "Save"
                          : ((currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                              orderType === "Bulk Inventory" &&
                              company?.type !== "R" &&
                              activeStep === 2) ||
                            ((currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                              orderType === "Bulk Inventory" &&
                              company?.type === "R" &&
                              activeStep === 1) ||
                            ((currentCompanyType === "B" ||
                              currentCompanyType === "I") &&
                              orderType === "Bulk Inventory" &&
                              activeStep === 1) ||
                            (currentCompanyType === "R" &&
                              orderType === "Bulk Inventory" &&
                              activeStep === 0) ||
                            ((currentCompanyType === "B" ||
                              currentCompanyType === "I") &&
                              orderType === "Project Pack for Home" &&
                              activeStep === 4) ||
                            ((currentCompanyType === "L" ||
                              currentCompanyIsParent === true) &&
                              orderType === "Project Pack for Home" &&
                              activeStep === 5)
                          ? "Next"
                          : "Finish"}
                      </CustomButton>
                    ) : null}
                    {(activeStep === 6 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      displayEditUnitprice === false &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 7 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      displayEditUnitprice === false &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 4 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 && currentCompanyType === "R") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R") ? (
                      <CustomButton
                        toggle={() => save("", false)}
                        loading={loading}
                        disable={
                          error ||
                          submitLoading === true ||
                          orderQuantitiesLoading === true
                        }
                        stepper={true}
                      >
                        Save Draft
                      </CustomButton>
                    ) : null}

                    {(windowWidth < 700 &&
                      ((activeStep === 6 &&
                        (currentCompanyType === "B" ||
                          currentCompanyType === "I") &&
                        displayEditUnitprice === false &&
                        orderType === "Project Pack for Home") ||
                        (activeStep === 7 &&
                          (currentCompanyType === "L" ||
                            currentCompanyIsParent === true) &&
                          displayEditUnitprice === false &&
                          orderType === "Project Pack for Home"))) ||
                    (activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 4 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 && currentCompanyType === "R") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R") ? (
                      <div style={{ height: "10px" }} />
                    ) : null}

                    {(activeStep === 6 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      displayEditUnitprice === false &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 7 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      displayEditUnitprice === false &&
                      orderType === "Project Pack for Home") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "B" ||
                        currentCompanyType === "I") &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 4 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      orderType === "Bulk Inventory") ||
                    (activeStep === 2 && currentCompanyType === "R") ||
                    (activeStep === 3 &&
                      (currentCompanyType === "L" ||
                        currentCompanyIsParent === true) &&
                      company?.type === "R") ? (
                      <CustomButton
                        toggle={() => checkRequestedDate()}
                        loading={submitLoading}
                        disable={
                          error ||
                          loading === true ||
                          orderQuantitiesLoading === true
                        }
                        stepper={true}
                      >
                        Place Order
                      </CustomButton>
                    ) : null}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      </Box>
      {shelfInventoryWarning ? (
        <AlertDialogSlide
          title="Reminder"
          textContent=" Bulk Inventory Orders use list pricing for Levven power controllers. Controllers in this order may have a higher price than Project Pack Orders."
          save={async () => {
            setOrderType("Bulk Inventory");
            setTakeoff();
            setAgreement();
            setInstallerContact();
            setOverDefSwitchColor();
            setForModelHome(false);
            setSpecialNegotiated(false);
            setShelfInventoryWarning(false);
            handleNext();
          }}
          saveText="Ok"
          unsave={() => setShelfInventoryWarning(false)}
          unsaveText="Change My Choice"
        />
      ) : null}
      {placeOrderModalOpen ? (
        <CustomModal openModal={placeOrderModalOpen}>
          <Typography variant="h6" component="span">
            Save Order
          </Typography>
          <br />
          <br />
          <Typography
            variant="body1"
            component="span"
            style={{ whiteSpace: "pre-line" }}
          >
            Save this as a 'draft' and edit it later, or continue and place the{" "}
            {isRMA === false ? "order" : "RMA"} with Levven.
          </Typography>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <CustomButton
              editTableColumnsButton={true}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => {
                setPlaceOrderModalOpen(false);
              }}
              disable={buttonLoading}
            >
              Cancel
            </CustomButton>
            <div style={{ width: "10px" }} />
            <CustomButton
              loading={loading || buttonLoading}
              editTableColumnsButton={true}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => {
                if (
                  (activeStep === 2 &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                    currentCompanyIsParent !== true &&
                    orderType === "Bulk Inventory") ||
                  (activeStep === 3 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    orderType === "Bulk Inventory") ||
                  (activeStep === 1 && currentCompanyType === "R") ||
                  (activeStep === 2 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type === "R")
                ) {
                  saveQuantities(false);
                } else save("", false);
              }}
            >
              Save Draft
            </CustomButton>
            <div style={{ width: "10px" }} />
            <CustomButton
              editTableColumnsButton={true}
              loading={buttonLoading}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => {
                if (
                  (activeStep === 4 &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                    currentCompanyIsParent !== true &&
                    orderType === "Project Pack for Home") ||
                  (activeStep === 5 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    orderType === "Project Pack for Home")
                ) {
                  setActiveStep(
                    currentCompanyType === "L" ||
                      currentCompanyIsParent === true
                      ? 6
                      : 5
                  );
                } else if (
                  (activeStep === 2 &&
                    (currentCompanyType === "B" ||
                      currentCompanyType === "I") &&
                    currentCompanyIsParent !== true &&
                    orderType === "Bulk Inventory") ||
                  (activeStep === 3 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    orderType === "Bulk Inventory") ||
                  (activeStep === 1 && currentCompanyType === "R") ||
                  (activeStep === 2 &&
                    (currentCompanyType === "L" ||
                      currentCompanyIsParent === true) &&
                    company?.type === "R")
                ) {
                  saveQuantities(true);
                } else save("", false, true);

                setPlaceOrderModalOpen(false);
              }}
            >
              Continue to Place {isRMA === false ? "Order" : "RMA"}
            </CustomButton>
          </div>
        </CustomModal>
      ) : null}
      {canSubmitModal && !submitOrderPermission ? (
        <CustomModal openModal={canSubmitModal && !submitOrderPermission}>
          <form onSubmit={(e) => save(e, true)}>
            <Typography variant="h6" component="span" align="left">
              You can send the {isRMA === false ? "order" : "RMA"} to Purchasing
              for Approval. Otherwise continue to the{" "}
              {isRMA === false ? "order" : "RMA"} page.
            </Typography>
            <br />
            <br />
            <Autocomplete
              id="combo-box-demo"
              componentName="Field1"
              options={canSubmitUsers}
              value={chosenCanSubmitUser ? chosenCanSubmitUser : ""}
              getOptionLabel={(option) =>
                option.userName ? option.userName : ""
              }
              onChange={(event, value) => {
                setChosenCanSubmitUser(value);
                if (chosenCanSubmitUserError)
                  setChosenCanSubmitUserError(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Field1"
                  label="Send Email to"
                  fullWidth
                  size="small"
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  error={chosenCanSubmitUserError}
                  helperText={
                    chosenCanSubmitUserError
                      ? "Send Email to field is empty"
                      : ""
                  }
                />
              )}
            />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton toggle={() => setCanSubmitModal(false)}>
                Cancel
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton
                loading={loading}
                toggle={() => {
                  save("", false);
                }}
              >
                Save and Place {isRMA === false ? "Order" : "RMA"} Later
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton type="submit" loading={submitLoading}>
                Save and Send
              </CustomButton>
            </div>
          </form>
        </CustomModal>
      ) : null}
      {displayFinalPlaceOrderModal === true ? (
        <CustomModal openModal={displayFinalPlaceOrderModal}>
          <Typography variant="h6" component="span">
            Save {isRMA === false ? "Order" : "RMA"}
          </Typography>
          <br />
          <br />
          <Typography
            variant="body1"
            component="span"
            style={{ whiteSpace: "pre-line" }}
          >
            If you place this {isRMA === false ? "order" : "RMA"} now you will
            not be able to edit it later. You may contact Levven if you wish to
            make any change to your {isRMA === false ? "order" : "RMA"}. Do you
            prefer to save this {isRMA === false ? "order" : "RMA"} as a draft
            instead?
          </Typography>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <CustomButton
              editTableColumnsButton={true}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => {
                setDisplayFinalPLaceOrderModal(false);
              }}
              disable={loading || submitLoading}
            >
              Cancel
            </CustomButton>
            <div style={{ width: "10px" }} />
            <CustomButton
              loading={loading}
              disable={submitLoading}
              editTableColumnsButton={true}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => save("", false)}
            >
              Save Draft
            </CustomButton>
            <div style={{ width: "10px" }} />
            <CustomButton
              editTableColumnsButton={true}
              sx={{ whiteSpace: "normal", height: "fit" }}
              toggle={() => {
                if (
                  orderType !== "Project Pack for Home" ||
                  (orderType === "Project Pack for Home" &&
                    [
                      "AV11",
                      "AV12",
                      "AV13",
                      "AV14",
                      "AV21",
                      "AV22",
                      "AV23",
                      "AV24",
                      "AV25",
                    ].includes(addressVerificationLevel))
                ) {
                  save("", true);
                } else {
                  setDisplayAddressVerificationWarning(true);
                }
              }}
              loading={submitLoading}
              disable={loading}
            >
              Place {isRMA === false ? "Order" : "RMA"} with Levven
            </CustomButton>
          </div>
        </CustomModal>
      ) : null}
      {canSubmitAlert && submitOrderPermission ? (
        <CustomModal openModal={canSubmitAlert && submitOrderPermission}>
          <Typography variant="h6" component="span" align="left">
            Save {isRMA === false ? "Order" : "RMA"}
          </Typography>
          <br />
          <br />
          {orderType !== "Bulk Inventory" ? (
            <Typography variant="body1" component="span" align="left">
              You can save this {isRMA === false ? "order" : "RMA"} as a draft
              and edit it later, or finish your{" "}
              {isRMA === false ? "order" : "RMA"} now.
            </Typography>
          ) : null}

          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton toggle={() => setCanSubmitAlert(false)}>
              Cancel
            </CustomButton>
            <div style={{ width: "10px" }} />
            <CustomButton toggle={() => save("", false)} loading={loading}>
              Save Draft
            </CustomButton>
            {orderType !== "Bulk Inventory" ? (
              <>
                <div style={{ width: "10px" }} />
                <CustomButton
                  loading={submitLoading}
                  toggle={() => {
                    if (
                      orderType !== "Project Pack for Home" ||
                      (orderType === "Project Pack for Home" &&
                        [
                          "AV11",
                          "AV12",
                          "AV13",
                          "AV14",
                          "AV21",
                          "AV22",
                          "AV23",
                          "AV24",
                          "AV25",
                        ].includes(addressVerificationLevel))
                    ) {
                      save("", true);
                    } else {
                      setDisplayAddressVerificationWarning(true);
                    }
                  }}
                >
                  Save and Place {isRMA === false ? "Order" : "RMA"} With Levven
                </CustomButton>
              </>
            ) : null}
          </div>
        </CustomModal>
      ) : null}
      {saveError !== "" ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
      {dateAlertDialogOpen ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            <>
              <Typography
                variant="body1"
                component="span"
                style={{ whiteSpace: "pre-line" }}
              >
                The{" "}
                {isRMA === false ? "Requested Delivery Date" : "Delivery Date"}{" "}
                should be adjusted. It should be starting{" "}
                {orderFulfillmentThreshold +
                  "day" +
                  (orderFulfillmentThreshold === 1 ? "" : "s")}{" "}
                from today.
              </Typography>
            </>
          }
          save={() => {
            setDateAlertDialogOpen(false);
          }}
          saveText="Close"
        />
      ) : null}
      {melissaResults.length !== 0 ? (
        <CustomModal openModal={melissaResults.length !== 0} width="60vw">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" component="span">
                Address Verification
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {melissaResults.map((description) => (
                <>
                  <Typography variant="body1" component="span">
                    - {description}
                  </Typography>
                  <br />
                </>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1" component="span">
                <b>Address Found</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>Address: </strong> {instStreet}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>Address 2: </strong> {instStreet2}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>City: </strong> {instCity}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>State/Province: </strong>{" "}
                    {instStateProvince?.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>Country: </strong> {instCountry?.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1" component="span">
                    <strong>Zip/Post Code: </strong> {instZipCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <CustomButton
                  sx={{
                    whiteSpace: windowWidth < 700 ? "normal" : "",
                    height: windowWidth < 700 ? "80px" : "",
                  }}
                  toggle={() => {
                    setMelissaResults([]);
                    setAddressChanged(true);
                    setAddressVerificationLevel("");
                  }}
                >
                  Edit Installation Address
                </CustomButton>

                <div style={{ width: "10px" }} />
                <CustomButton
                  toggle={async () => {
                    setMelissaResults([]);
                    setFormattedAddress("");
                    setAddressChanged(false);
                    validateUserAddressDuplicate();
                    // setLoading(false);
                    // handleNext();
                  }}
                  loading={loading}
                >
                  Proceed
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </CustomModal>
      ) : null}

      <InfoSnackbar
        open={melissaInfoSnackbar && melissaCreditsDialog === false}
        message={melissaMessageSnackbar}
        severity={melissaSeveritySnackbar}
        handleClose={handleSnackBarClose}
      />
    </Paper>
  );
}
