import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { accountServices } from "../../services/account.services";
import { userTypes } from "../../redux/types/userTypes";
import { useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { useState } from "react";
import { useEffect } from "react";

export default function AccountMenu({ setPageID }) {
  //Component to display when we click the user profile on the top right

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /* Get values from redux store*/
  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const jobTitle = useSelector((state) =>
    state.user.user ? state.user.user.user.jobTitle : ""
  );

  const loggedIn = useSelector((state) =>
    state.user ? (state.user.loggedIn ? state.user.loggedIn : false) : false
  );

  const roleName = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const microUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.microUserID : ""
  );

  const ownCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownCompany : ""
  );

  const firstName = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const lastName = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const companyName = useSelector((state) =>
    state.user.user ? state.user.user.user.company.name : ""
  );

  const currentUserFirstName = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const currentUserLastName = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  const userSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.systemGenerated : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const logout = () => {
    //function that clears redux values and logs out
    return (dispatch) => {
      accountServices.logout().then(() => {
        dispatch({
          type: userTypes.LOGOUT_USER,
        });
      });
    };
  };

  const handleClick = (event) => {
    //opens account menu
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    //closes account menu
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = (route) => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      setOpenDialog(true);

      setRouteToNavigate(route);
    } else {
      // history.push(route);
      console.log("no vhanges");
      setRouteToNavigate(route);
      handleAgree(route);
    }
  };

  const handleAgree = (route) => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    console.log(route);

    if (route === "/user-information") {
      console.log("go to user info pagewith user id", userID);
      history.push({
        pathname: "/user-information",
        state: {
          userID: userID,
          microUserID: microUserID,
          companyID: companyID,
          fname: currentUserFirstName,
          lname: currentUserLastName,
          roleDescription: roleName,
          self: true,
          fromCompany: false,
          fromRoles: false,
        },
      });
    } else if (route === "/company-information") {
      console.log("go to company poage");
      history.push({
        pathname: "/company-information",
        state: {
          source: currentUserFirstName + " " + currentUserLastName,
          companyID: companyID,
          companyName: companyName,
          myCompany: true,
        },
      });
    } else if (route === "/system-preferences") {
      console.log("go to system page");
      history.push({
        pathname: "/system-preferences",
        state: {
          systemGenerated: userSystemGenerated,
          isLevven: companyType === "L" ? true : false,
        },
      });
    }
  };
  // useEffect(() => {
  //   if (JSON.parse(sessionStorage.getItem("unsavedChanges")) !== true)
  //     handleAgree();
  // }, [routeToNavigate]);
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Account settings">
          <Avatar
            sx={{ backgroundColor: "#0077c6" }}
            onClick={handleClick}
            size="small"
          />
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography
          style={{
            fontWeight: "700",
            margin: "5px 20px",
          }}
        >
          {firstName} {lastName}
        </Typography>
        <MenuItem
          onClick={() => {
            setPageID(-1);
            console.log(userID);
            handleItemClick("/user-information");
          }}
          sx={{
            "&:hover": {
              bgcolor: "#cde2f5",
            },
          }}
        >
          <Avatar /> Profile
        </MenuItem>
        {ownCompany !== "N" ? (
          <MenuItem
            onClick={() => {
              setPageID(-1);
              handleItemClick("/company-information");
            }}
            sx={{
              "&:hover": {
                bgcolor: "#cde2f5",
              },
            }}
          >
            <Icon
              icon="cil:building"
              height={26}
              color="#565759"
              style={{ marginRight: "12px" }}
            />{" "}
            My Company
          </MenuItem>
        ) : (
          ""
        )}

        <Divider />

        {roleName === "Levven Admin" ? (
          <MenuItem
            onClick={() => {
              setPageID(-1);
              handleItemClick("/system-preferences");
            }}
            sx={{
              "&:hover": {
                bgcolor: "#cde2f5",
              },
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            System Preferences
          </MenuItem>
        ) : (
          ""
        )}

        <MenuItem
          disabled={!loggedIn}
          onClick={() => dispatch(logout())}
          sx={{
            "&:hover": {
              bgcolor: "#cde2f5",
            },
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
