import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Trash2,
  RotateCw,
} from "react-feather";
import CustomButton from "../CustomButton/CustomButton";

import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { orderServices } from "../../services/orders.service";

import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const headCells = [
  {
    numeric: false,
    disablePadding: false,
    label: "Job Number",
    center: false,
    id: "JobNum",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Builder",
    center: false,
    id: "BuilderName",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Community",
    center: false,
    id: "Community",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Lot Number",
    center: false,
    id: "LotNum",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
    center: false,
    id: "PlanName",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Plan Number",
    center: false,
    id: "PlanNum",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "PO Number",
    center: false,
    id: "PurchaseOrderNbr",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Installer",
    center: false,
    id: "InstallerName",
  },
  // {
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Reseller",
  //   center: false,
  //   id: "ResellerName",
  // },
  {
    numeric: false,
    disablePadding: false,
    label: "Created By",
    center: false,
    id: "UserName",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Order Creation Date",
    center: false,
    id: "CreationDate",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Delivery Request Date",
    center: false,
    id: "RequestedDeliveryDate",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Status",
    center: true,
    id: "Status",
  },
  { id: "OrderID", disablePadding: false, label: "OrderID", center: true },
  {
    id: "NavNo",
    disablePadding: false,
    label: "SO Number",
    center: true,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rowCount > 0 && numSelected !== 0 ? (
            <Checkbox
              checkedIcon={
                <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
            // onClick={createSortHandler(headCell.id)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OrderTableTemp() {
  //used to send data to next page
  const history = useHistory();

  //used to retrieve sent data from previous page
  const location = useLocation();

  const [orderColumn, setOrderColumn] = React.useState("CreationDate");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //for page in table
  const [page, setPage] = React.useState(0);

  //for rows per page in table
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  //make page load until all data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds are data to be displayed in rows
  const [rows, setRows] = React.useState([]);

  //to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //for holding error messages
  const [error, setError] = React.useState("");

  //number of selected rows
  var numSelected = selected.length;

  //total number of records without pagination
  const [numberRecords, setNumberRecords] = React.useState(0);

  //make delete button load while delete in progress
  const [loading, setLoading] = React.useState(false);

  //to refetch data from database
  const [resubmit, setResubmit] = React.useState(false);

  //in case of error while deleting
  const [deleteError, setDeleteError] = React.useState("");

  //to display success snackbar
  const [success, setSuccess] = React.useState(false);

  //to display alert dialog before deleting
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  const [dateError, setDateError] = React.useState("");

  //filters
  const [planName, setPlanName] = React.useState("");
  const [planNumber, setPlanNumber] = React.useState("");
  const [agreementName, setAgreementName] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [builderName, setBuilderName] = React.useState([]);
  const [installerName, setInstallerName] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [requestDateFrom, setRequestDateFrom] = React.useState(null);
  const [requestDateTo, setRequestDateTo] = React.useState(null);
  const [orderIdFilter, setOrderIdFilter] = React.useState(null);
  const [soNumber, setSoNumber] = React.useState("");

  //hold filters selected
  const [selectedBuilderName, setSelectedBuilderName] = React.useState([]);
  const [selectedAgreementName, setSelectedAgreementName] = React.useState([]);
  const [selectedInstallerName, setSelectedInstallerName] = React.useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [jobNum, setJobNum] = React.useState("");
  const [community, setCommunity] = React.useState("");
  const [lotNum, setLotNum] = React.useState("");
  const [poNumber, setPONumber] = React.useState("");

  //To display column
  const [changeColumns, setChangeColumns] = React.useState(false);

  //if order deleted from tabs
  const [openSnackbar, setOpenSnackbar] = React.useState(
    location.state
      ? location.state.openSnackbar
        ? location.state.openSnackbar
        : false
      : false
  );

  const [openOrderSuccess, setOpenOrderSuccess] = React.useState(
    location.state
      ? location.state.openOrderSuccess
        ? location.state.openOrderSuccess
        : false
      : false
  );

  //get values from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  /*Check Status*/
  const orderStatusCheck = (status) => {
    if (status === "N") {
      return "Open";
    } else if (status === "D") {
      return "Draft";
    } else if (status === "F") {
      return "Final";
    } else if (status === "T") {
      return "Submitted";
    } else if (status === "I") {
      return "In Process";
    } else if (status === "S") {
      return "Shipped";
    } else if (status === "V") {
      return "Invoiced";
    } else return "";
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      const m = date.toLocaleString("default", { month: "long" });
      return m + " " + d.substring(8, 10) + ", " + d.substring(11, 15);
    }
  };

  

  const handleDateSubmit = async (date) => {
    const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

    if (!regex.test(date)) {
      return "Invalid Date Format";
    }

    const searchDate = moment(date).isValid()
      ? moment(date).format("MM/DD/YYYY")
      : "Invalid Date Format";

    return searchDate;
  };

  const getValuesFromDatabase = async () => {
    //function to retrieve the row data for table

    //we filter the filter data to send to the backend since the filters are saved as objects
    var agreementArray = [];
    var builderArray = [];
    var installerArray = [];
    var statusArray = [];
    var createdByArray = [];

    selectedAgreementName.forEach((agreement) => {
      agreementArray.push(agreement.agreementID);
    });
    selectedBuilderName.forEach((builder) => {
      builderArray.push(builder.companyID);
    });

    selectedInstallerName.forEach((installer) => {
      installerArray.push(installer.companyID);
    });

    selectedStatus.forEach((status) => {
      statusArray.push(status.id);
    });

    selectedCreatedBy.forEach((status) => {
      createdByArray.push(status.userID);
    });

    var handleDateErrorDateTo = "";
    var handleDateErrorDateFrom = "";
    var handleDateErrorRequestDateFrom = "";
    var handleDateErrorRequestDateTo = "";

    if (dateTo !== null) handleDateErrorDateTo = await handleDateSubmit(dateTo);
    if (dateFrom !== null)
      handleDateErrorDateFrom = await handleDateSubmit(dateFrom);
    if (requestDateFrom !== null)
      handleDateErrorRequestDateFrom = await handleDateSubmit(requestDateFrom);
    if (requestDateFrom !== null)
      handleDateErrorRequestDateTo = await handleDateSubmit(requestDateTo);

    if (
      handleDateErrorDateTo === "Invalid Date Format" ||
      handleDateErrorDateFrom === "Invalid Date Format" ||
      handleDateErrorRequestDateFrom === "Invalid Date Format" ||
      handleDateErrorRequestDateTo === "Invalid Date Format"
    ) {
      if (handleDateErrorDateTo === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorDateFrom === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorRequestDateFrom === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Delivery Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorRequestDateTo === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Delivery Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      await setPageLoad(false);
    } else {
      await setRows([]);
      await setSelected([]);
      var data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: {
          JobNum: jobNum,
          Community: community,
          LotNum: lotNum,
          PONumber: poNumber,
          planName: planName,
          Agreements: agreementArray,
          planNumber: planNumber,
          createdBy: createdByArray,
          Builders: builderArray,
          Installers: installerArray,
          Status: statusArray,
          OrderID: orderIdFilter ? orderIdFilter : 0,
          LevvenSONumber: soNumber,
          creationDateFrom:
            dateFrom === "" || dateFrom === null
              ? null
              : moment(new Date(dateFrom)).format("MM/DD/YYYY"),
          creationDateTo:
            dateTo === "" || dateTo === null
              ? null
              : moment(new Date(dateTo)).format("MM/DD/YYYY"),
          requestedDeliveryDateFrom:
            requestDateFrom === "" || requestDateFrom === null
              ? null
              : moment(new Date(requestDateFrom)).format("MM/DD/YYYY"),
          requestedDeliveryDateTo:
            requestDateTo === "" || requestDateTo === null
              ? null
              : moment(new Date(requestDateTo)).format("MM/DD/YYYY"),
        },
        isLevven: companyType === "L",
        companyID: companyID,
        orderDirection: orderDirection,
        orderColumn: orderColumn,
        Orphaned : true
      };
      await orderServices
        .getOrdersList(data)
        .then((response) => {
          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.order);

          setPageLoad(false);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    setError("");

    window.scrollTo(0, 0); //to scroll to top of page
    setPageLoad(true);

    setRows([]);

    getValuesFromDatabase();

    return () => {
      setRows([]);
    };
  }, [page, rowsPerPage, resubmit]);


  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //for selecting all rows
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  //change page in table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const clearFilterData = () => {
    //function to clear form data
    setOrderIdFilter(null);
    setSoNumber("");
    setJobNum("");
    setCommunity("");
    setLotNum("");
    setPONumber("");
    setPlanName("");
    setPlanNumber("");
    setSelectedCreatedBy([]);
    setDateFrom(null);
    setRequestDateFrom(null);
    setDateTo(null);
    setRequestDateTo(null);
    setSelectedBuilderName([]);
    setSelectedAgreementName([]);
    setSelectedInstallerName([]);
    setSelectedStatus([]);
    setResubmit(!resubmit);
  };

  //change rowsPerPage of table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecords = () => {
    //for deleting records selected
    setLoading(true);
    setDeleteRecord(false);
    orderServices
      .deleteOrders({ Orders: selected, Orphaned : true })
      .then(() => {
        setLoading(false);
        setSelected([]);
        setResubmit(!resubmit);
      })
      .catch((error) => {
        setLoading(false);

        setResubmit(!resubmit);
        setSelected([]);
        setDeleteError(error.response.data.error.error);
      });
  };

  const viewOrderData = (
    id,
    name,
    status,
    compID,
    jobName,
    jobNum,
    purchaseOrderNb,
    requestedDate
  ) => {
    //to view order data and go to order information
    history.push({
      pathname: "/order-information",
      state: {
        orderID: id,
        orderName: name,
        orderStatus: status,
        purchaserCompanyID: compID,
        jobName: jobName,
        jobNum: jobNum,
        purchaseOrderNbr: purchaseOrderNb,
        requestedDate: requestedDate ? requestedDate : "",
      },
    });
  };

  //check if row selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <SnackBar
        open={openSnackbar}
        message="Order Deleted Successfully"
        severity="success"
        handleClose={() => setOpenSnackbar(false)}
      />
      <SnackBar
        open={openOrderSuccess}
        message="Saved Successfully"
        severity="success"
        handleClose={() => setOpenOrderSuccess(false)}
      />
      <SnackBar
        open={success}
        message="Order(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Order Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteRecords}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {dateError ? (
        <AlertSnackBar
          message={dateError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDateError("");
          }}
          open={dateError !== ""}
        />
      ) : (
        ""
      )}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box>
        <Paper
          sx={{
            mb: 2,
          }}
        >
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", marginRight: "10px" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", marginRight: "10px" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Deleted Orders
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                {/* <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton> */}

                {rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecord(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<RotateCw />}
                  size="medium"
                  tableButton={true}
                  toggle={() => clearFilterData()}
                >
                  Refresh
                </CustomButton>

           
              </>
            )}
          </Toolbar>

         

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ width: "100%", whiteSpace: "nowrap" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={numSelected}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="15" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="15"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no orders to display. "}
                    
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.orderID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                        key={row.orderID}
                      >
                        <TableCell padding="checkbox" align="center">
                          
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.orderID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          
                        </TableCell>

                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.jobNum}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.takeoff
                            ? row.takeoff.agreement.builder.name
                            : row.purchaser.company.type === "B"
                            ? row.purchaser.company.name
                            : "N/A"}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.takeoff ? row.takeoff.community : "N/A"}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.lotNum}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.takeoff ? row.takeoff.planName : ""}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.takeoff ? row.takeoff.planNum : ""}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.purchaseOrderNbr}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.takeoff
                            ? row.takeoff.agreement.installer
                              ? row.takeoff.agreement.installer.name
                              : row.purchaser.company.type === "I"
                              ? row.purchaser.company.name
                              : "N/A"
                            : row.purchaser.company.type === "I"
                            ? row.purchaser.company.name
                            : "N/A"}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.createdBy.userName}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {convertUTC(row.creationDate)}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {convertUTC(row.requestedDeliveryDate)}
                        </TableCell>
                        <TableCell
                          align="center"
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          <span
                            style={{
                              padding: "5px 10px",
                              borderRadius: "25px",
                              textAlign: "center",
                              marginLeft: "-10px",
                              backgroundColor:
                                row.status === "D"
                                  ? "rgba(100, 76, 159, 0.2)"
                                  : row.status === "N"
                                  ? "rgba(255, 193, 7, 0.2)"
                                  : row.status === "F"
                                  ? "rgba(179, 27, 27 , 0.4)"
                                  : row.status === "T"
                                  ? "rgba(84, 184, 122, 0.3)"
                                  : row.status === "I"
                                  ? "rgb(64, 184, 194, 0.2)"
                                  : row.status === "S"
                                  ? "rgb(28, 128, 0, 0.1)"
                                  : "rgba(150, 150, 150, 0.3)",
                              color:
                                row.status === "D"
                                  ? "rgba(100, 76, 159, 1)"
                                  : row.status === "N"
                                  ? "rgba(255, 193, 7, 1)"
                                  : row.status === "F"
                                  ? "rgba(179, 27, 27 , 1)"
                                  : row.status === "T"
                                  ? "rgb(84, 184, 122, 1)"
                                  : row.status === "I"
                                  ? "rgb(64, 184, 194, 1)"
                                  : row.status === "S"
                                  ? "rgb(28, 128, 0, 1)"
                                  : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {orderStatusCheck(row.status)}
                          </span>
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.orderID}
                        </TableCell>
                        <TableCell
                        //   onClick={() =>
                        //     viewOrderData(
                        //       row.orderID,
                        //       row.purchaser.company.name,
                        //       row.status,
                        //       row.purchaser.companyID,
                        //       row.jobName,
                        //       row.jobNum,
                        //       row.purchaseOrderNbr,
                        //       row.requestedDeliveryDate
                        //     )
                        //   }
                        >
                          {row.navNo}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
