import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { countryServices } from "../../services/country.services";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { encryptObject } from "../../helpers/link-variables";
import ContextMenu from "../ContextMenu/ContextMenu";
import CustomModal from "../CustomModal/CustomModal";
import ShippingAddressForm from "../ShippingAddressForm/ShippingAddressForm";

const headCells = [
  { id: "Name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "StreetAddress",
    numeric: false,
    disablePadding: true,
    label: "Street Address",
  },
  {
    id: "SecondAddress",
    numeric: false,
    disablePadding: false,
    label: "Street Address 2",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "StateProvince",
    numeric: false,
    disablePadding: false,
    label: "State/Province",
  },
  {
    id: "ZipPostCode",
    numeric: false,
    disablePadding: false,
    label: "ZIP/Post Code",
  },
  {
    id: "Country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolePermission,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ShippingAddressTableComponent({
  companyID,
  setCreateAddress = false,
  companyName,
  dataObject = null,
}) {
  //getting data from redux
  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  //getting user permission from redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? companyID === compID
        ? state.user.user.user.role.ownCompany
        : state.user.user.user.role.clientCompany
      : ""
  );

  const [shippingAddressIDToView, setShippingAddressIDToView] =
    React.useState(0);

  //used to send data to next page
  const history = useHistory();

  //for sorting
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //holds IDs selected
  const [selected, setSelected] = React.useState([]);

  //holds page we are in
  const [page, setPage] = React.useState(0);

  //holds rows per page
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds the rows to be displayed in the table
  const [rows, setRows] = React.useState([]);

  //to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //holds number of rows selected
  var numSelected = selected.length;

  //holds total number of records that fit the filter in the database
  const [numberRecords, setNumberRecords] = React.useState(0);

  //to make delete button loading while waiting for delete to complete
  const [loading, setLoading] = React.useState(false);

  //for filters
  const [countries, setCountries] = React.useState([]);
  const [countryInformation, setCountryInformation] = React.useState([]);
  const [zipPostCodeSearch, setZipPostCodeSearch] = React.useState("");
  const [streetAddressSearch, setStreetAddressSearch] = React.useState("");
  const [streetAddressSearch2, setStreetAddressSearch2] = React.useState("");
  const [citySearch, setCitySearch] = React.useState("");
  const [stateProvinceSearch, setStateProvinceSearch] = React.useState([]);
  const [stateProvince, setStateProvince] = React.useState([]);

  //to resubmit the filter values
  const [resubmit, setResubmit] = React.useState(false);

  //to make page load while waiting for rows data to be fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //in case of any error occured during the delete process
  const [deleteError, setDeleteError] = React.useState("");

  //to display snackbar on delete success
  const [success, setSuccess] = React.useState(false);

  const [successModification, setSuccessModification] = React.useState(false);

  //in case of error while fetching data
  const [error, setError] = React.useState("");

  //to display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  /**Variables to Open Shipping Address Form */
  const [openShipModalEdit, setOpenShipModalEdit] = React.useState(false);
  const [openShipModalCreate, setOpenShipModalCreate] = React.useState(false);

  const getFilterData = () => {
    //we get the data for the filters
    countryServices
      .getCountries()
      .then((response) => {
        setCountryInformation(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
    countryServices
      .getStateProvinceList()
      .then((response) => {
        setStateProvinceSearch(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const fetchDataFromDatabase = () => {
    //fetching the data from the database
    var array = [];
    //we get the indexes of state provinces selected in the filter

    stateProvince.forEach((state) => {
      return array.push(state.stateProvinceID);
    });

    var array2 = [];

    //we get the indexes of countries selected in the filter

    countries.forEach((state) => {
      return array2.push(state.countryID);
    });

    setRows([]);
    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, keyWord: "" },
      Filters: {
        streetAddress: streetAddressSearch,
        streetAddress2: streetAddressSearch2,
        city: citySearch,
        StateProvince: array,
        ZipPostCode: zipPostCodeSearch,
        Countries: array2,
      },
      CompanyID: companyID,
      orderDirection: orderDirection,
      orderColumn: orderColumn,
    };

    shippingaddressServices
      .getShippingAddress(data)
      .then((response) => {
        setPageLoad(false);

        setNumberRecords(response.data.totalNumberOfRecords);

        setRows(response.data.companyShippingAddresses);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    //we get the data from the database
    //we get the countries and the state provinces in order to fill the select ilst in the filter
    //and we get the data as a whole finally

    setPageLoad(true);
    setCountryInformation([]);
    setError("");
    setSelected([]);

    getFilterData();
    fetchDataFromDatabase();
  }, [page, rowsPerPage, resubmit]);

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //to select all values on the page
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.shippingID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const submitFilters = (event) => {
    //we resubmit the filters so we get the filtered data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const deleteShippingAddress = () => {
    //delete selected shipping addresses by providing an array of their ID's
    setLoading(true);
    setDeleteRecord(false);
    const data = { ShippingAddresses: selected };

    shippingaddressServices
      .deleteShippingAddress(data)
      .then((response) => {
        setResubmit(!resubmit);
        setLoading(false);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };

  const clearFilterData = () => {
    //clear filter data
    setStreetAddressSearch("");
    setCitySearch("");
    setZipPostCodeSearch("");
    setStateProvince([]);
    setCountries([]);
    setResubmit(!resubmit);
  };

  const viewAddressData = (id) => {
    //function to go to view all the data concerning the shipping address

    setShippingAddressIDToView(id);
    setOpenShipModalEdit(true);
  };

  const handleChangePage = (event, newPage) => {
    //functino to change page in table
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change number of rows to display in a page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //to check if a row is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      shippingAddressID: contextMenuData.shippingID,
      companyID: companyID,
      self: companyID === compID ? true : false,
      selfLink: companyID === compID ? "/company-information" : "/Companies",
      addressLink: "/company-information",
      companyName: companyName,
      myCompany: companyID === compID ? true : false,
      streetAddress: contextMenuData.streetAddress,
      dataObject: dataObject,
      tab: 1,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/shipping-address-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Shipping Address(es) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      <SnackBar
        open={successModification}
        message={"Company Shipping Address Saved Successfully"}
        severity="success"
        handleClose={(event, reason) => {
          if (reason === "clickaway") return;
          setSuccessModification(false);
        }}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Shipping Address Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteShippingAddress}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Shipping Address
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Trash2 />}
                    size="medium"
                    tableButton={true}
                    loading={loading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    toggle={() => setOpenShipModalCreate(true)}
                  >
                    New Address
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilters}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={streetAddressSearch}
                        onChange={(e) => setStreetAddressSearch(e.target.value)}
                        label="Street Address"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={streetAddressSearch2}
                        onChange={(e) =>
                          setStreetAddressSearch2(e.target.value)
                        }
                        label="Street Address 2"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={citySearch}
                        onChange={(e) => setCitySearch(e.target.value)}
                        label="City"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={stateProvinceSearch}
                        getOptionLabel={(option) =>
                          option.description && option.stateProvinceCode
                            ? option.stateProvinceCode +
                              " - " +
                              option.description
                            : ""
                        }
                        value={stateProvince}
                        onChange={(event, value) => {
                          setStateProvince(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State/Province"
                            fullWidth
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={zipPostCodeSearch}
                        onChange={(e) => setZipPostCodeSearch(e.target.value)}
                        label="Zip/Post Code"
                        fullWidth
                        autoComplete="off"
                        inputProps={{}}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={countryInformation}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={countries}
                        onChange={(event, value) => {
                          setCountries(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                            }}
                            autoComplete="off"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Addresses to display."}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.shippingID);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        tabIndex={-1}
                        key={row.shippingID}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {rolePermission === "M" ? (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.shippingID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.streetAddress}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.secondAddress ? row.secondAddress : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.city}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.stateProvince.description}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.zipPostCode}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewAddressData(
                              row.shippingID,
                              companyID,
                              row.streetAddress
                            )
                          }
                        >
                          {row.country.description}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      {/**Section to open Shipping Address Modal */}

      {/**On Create */}
      {openShipModalCreate ? (
        <CustomModal openModal={openShipModalCreate} width="70vw">
          <ShippingAddressForm
            companyID={companyID}
            fromCompany={true}
            setShipModalOpen={setOpenShipModalCreate}
            fetchShippingAddressesForCompany={fetchDataFromDatabase}
            companyName={companyName}
            setSuccessModification={setSuccessModification}
          />
        </CustomModal>
      ) : null}

      {/**On Edit */}
      {openShipModalEdit ? (
        <CustomModal openModal={openShipModalEdit} width="70vw">
          <ShippingAddressForm
            create={false}
            companyID={companyID}
            fromCompany={true}
            setShipModalOpen={setOpenShipModalEdit}
            fetchShippingAddressesForCompany={fetchDataFromDatabase}
            shippingAddressID={shippingAddressIDToView}
            setSuccessModification={setSuccessModification}
            setShippingAddressID={setShippingAddressIDToView}
            companyName={companyName}
          />
        </CustomModal>
      ) : null}
    </>
  );
}
