import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { Copy, Save, Trash2, X } from "react-feather";
import { takeoffWizardServices } from "../../services/takeoffWizard.service";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import TakeoffWizardForm from "../TakeoffWizardForm/TakeoffWizardForm";
import SnackBar from "../SnackBar/SnackBar";
import { Plus } from "react-feather";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TakeoffWizardTabs({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //for user permission
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoffWiz : ""
  );

  const takeoffID = fromURL
    ? decryptedObject
      ? decryptedObject.takeoffID
        ? decryptedObject.takeoffID
        : 0
      : 0
    : location.state
    ? location.state.takeoffID
      ? location.state.takeoffID
      : 0
    : 0;

  if (takeoffID === 0) {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  //for security
  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  //holds tabs value
  const [value, setValue] = useState(0);

  //for permissions
  const [adminManage, setAdminManage] = useState(false);

  //makes delete button loading while delete in progress
  const [buttonLoading, setButtonLoading] = useState(false);

  //makes save button loading while delete in progress
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  //holds error on delete if any
  const [deleteError, setDeleteError] = useState("");

  //displays the alert dialog before delete
  const [deleteRecord, setDeleteRecord] = useState(false);

  //get values from previous page
  const create = fromURL
    ? decryptedObject
      ? decryptedObject.create
        ? decryptedObject.create
        : false
      : false
    : location.state
    ? location.state.create
      ? location.state.create
      : false
    : false;

  const [takeoffDescription, setTakeoffDescription] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.takeoffDescription
          ? decryptedObject.takeoffDescription
          : ""
        : ""
      : location.state
      ? location.state.takeoffDescription
        ? location.state.takeoffDescription
        : ""
      : ""
  );

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );

  /*User Form Ref*/
  const takeoffWizardRef = useRef(null);

  useEffect(() => {
    //for help button
    setPageLink("TakeoffWizard");

    //for drawer menu
    setPageID(10);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (rolePermission === "M") {
      setAdminManage(true);
    }
  }, []);

  const handleChange = (event, newValue) => {
    //handles tab change
    setValue(newValue);
  };

  const deleteValues = () => {
    //function to delete record
    const data = { TakeoffWizardList: [takeoffID] };
    setButtonLoading(true);
    setDeleteRecord(false);
    takeoffWizardServices
      .deleteTakeoffWizard(data)
      .then(() => {
        setButtonLoading(false);
        history.push({
          pathname: "/takeoff-wizard",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setButtonLoading(false);

        setDeleteError(error.response.data.error.error);
      });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const createTakeoff = () => {
    //function to go to form to create take off wizard
    history.push({
      pathname: "/create-takeoff-wizard",
      state: {
        create: true,
      },
    });
  };
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose= () =>{
    setOpenDialog(false)
  }

  const handleRouteClick = (route) => {
    
    if (sessionStorage.getItem("unsavedChanges") === "true") {
      setOpenDialog(true);
    }
    
  };

  const handleConfirm = () => {
    
    setOpenDialog(false);
    sessionStorage.removeItem("unsavedChanges");
    createTakeoff()
  };

  return (
    <>
    <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteValues}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      <Paper>
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() =>
                  history.push({
                    pathname: "/takeoff-wizard",
                  })
                }
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                Takeoff Wizard Setup
              </Link>

              <Typography
                key="2"
                color="text.primary"
                sx={{ fontSize: "24px" }}
              >
                {takeoffDescription}
              </Typography>
            </Breadcrumbs>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {!create && value === 0 && rolePermission === "M" ? (
                <>
                  <CustomButton startIcon={<Plus />} toggle={handleRouteClick}>
                    New Step
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Copy />}
                    toggle={() =>
                      history.push({
                        pathname: "/create-takeoff-wizard",
                        state: {
                          id: takeoffID,
                          duplicate: true,
                        },
                      })
                    }
                  >
                    Duplicate
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Save />}
                    toggle={() => takeoffWizardRef.current.save()}
                    loading={tabButtonLoading}
                  >
                    Save
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<Trash2 />}
                    loading={buttonLoading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                </>
              ) : null}
              {value === 0 ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<X />}
                    toggle={async () => {
                      await takeoffWizardRef.current.setPristine();
                      history.push("/takeoff-wizard");
                    }}
                  >
                    Cancel
                  </CustomButton>
                </>
              ) : null}
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Information"
                {...a11yProps(0)}
                style={{ margin: "50" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TakeoffWizardForm
              adminManage={adminManage}
              receivedTakeoffID={takeoffID}
              setOpenSnackBar={setOpenSnackBar}
              ref={takeoffWizardRef}
              setTabButtonLoading={setTabButtonLoading}
            />
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
