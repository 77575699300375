import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { Image } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import CustomAlert from "../CustomAlert/CustomAlert";
import Modal from "../CustomModal/CustomModal";
import { productServices } from "../../services/product.service";

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", paddingLeft: "10px" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AddProductsTable({
  title = "",
  rows = [],
  setAddRows,
  setDirty,
  changed,
  setChanged,
  setFormChanged,
}) {
  const [imageSpecification, setImageSpecification] = React.useState("");

  //holds error while fetching image
  const [imageError, setImageError] = React.useState("");

  const [imageLoad, setImageLoad] = React.useState(false);

  const [displayImage, setDisplayImage] = React.useState(false);
  const [image, setImage] = React.useState();
  const [imageDescription, setImageDescription] = React.useState("");

  const headCells = [
    {
      id: "image",
      numeric: false,
      disablePadding: true,
      label: "Image",
      center: false,
    },
    {
      id: "partNbr",
      numeric: false,
      disablePadding: true,
      label: "Part Number",
      center: false,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      center: false,
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
      center: true,
    },
  ];

  /*Fetch Image File*/
  const fetchImageFile = async (id, path, description, specification) => {
    setImageLoad(true);
    setImageError("");
    if (path === null) return;
    setImageDescription(description);
    setImageSpecification(specification);
    setDisplayImage(true);

    if (path !== "") {
      await productServices
        .getImageFile(path, id)
        .then(async (response) => {
          await setImage(await URL.createObjectURL(response.data));
          await setImageLoad(false);
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setImageError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
          setImageLoad(false);
        });
    } else {
      setImageLoad(false);
    }
  };

  return (
    <>
      {displayImage ? (
        <>
          <Modal openModal={displayImage} width="600px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                padding: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="div">
                  {imageDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!imageLoad ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {image ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <img
                              src={image}
                              alt="product"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                margin: "auto",
                                alignSelf: "center",
                                border: "1px solid #c4c4c4",
                                borderRadius: 8,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {imageError ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <CustomAlert>{imageError}</CustomAlert>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Typography variant="h7">
                                    <b>Specifications</b>
                                  </Typography>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      textJustify: "inter-word",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      {imageSpecification}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography variant="h7">
                                  <b>Specifications</b>
                                </Typography>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textJustify: "inter-word",
                                  }}
                                >
                                  <Typography variant="body2" component="span">
                                    {imageSpecification}
                                  </Typography>
                                </div>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setImage(null);
                      setDisplayImage(false);
                      setImageDescription("");
                      setImageSpecification("");
                    }}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {title ? (
            <>
              <Toolbar
                sx={{
                  overflow: "auto",
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {title}
                </Typography>
              </Toolbar>
            </>
          ) : null}

          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead headCells={headCells} />

              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan="12"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no products to display. "}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{ height: "130px" }}
                      >
                        <TableCell>
                          {rows[index].productImage ? (
                            <img
                              onClick={() =>
                                fetchImageFile(
                                  rows[index].product.productID,
                                  rows[index].product.imagePath
                                    ? rows[index].product.imagePath
                                    : "",
                                  rows[index].product.description,
                                  rows[index].product.specifications
                                )
                              }
                              src={rows[index].productImage}
                              alt={rows[index].product.partNbr}
                              style={{
                                height: "100px",
                                objectFit: "cover",
                                alignSelf: "center",
                                borderRadius: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <>
                              <IconButton
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchImageFile(
                                    rows[index].product.productID,
                                    rows[index].product.imagePath
                                      ? rows[index].product.imagePath
                                      : "",
                                    rows[index].product.description,
                                    rows[index].product.specifications
                                  )
                                }
                              >
                                <Image />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell width={"33%"}>
                          {row.product.partNbr}
                        </TableCell>

                        <TableCell width={"33%"}>
                          {row.product.description}
                        </TableCell>

                        <TableCell width={"33%"} align="center">
                          <TextField
                            variant="standard"
                            type="number"
                            inputProps={{
                              style: { textAlign: "right" },
                              min: 0,
                            }}
                            value={
                              rows[index].quantity !== null
                                ? rows[index].quantity
                                : ""
                            }
                            onChange={async (e) => {
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              var tmp = rows;

                              if (e.target.value !== "") {
                                tmp[index].quantity = parseInt(e.target.value);
                              } else {
                                tmp[index].quantity = null;
                              }
                              await setAddRows(tmp);
                              setChanged(!changed);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
