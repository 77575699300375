import { Checkbox, Grid, Typography, Paper } from "@mui/material";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Example from "./example";

const TakeoffWizardStepsTable = ({ steps, setSteps }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#0077c6",
          padding: "16px 0",
        }}
      >
        <Grid container>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            textAlign="center"
            style={{ margin: "auto" }}
          >
            <Typography
              component="span"
              variant="body2"
              align="left"
              color="white"
              style={{ fontWeight: "500" }}
            >
              Step Number
            </Typography>
          </Grid>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            style={{ margin: "auto" }}
          >
            <Typography
              component="span"
              variant="body2"
              align="left"
              color="white"
              style={{ fontWeight: "500" }}
            >
              Title
            </Typography>
          </Grid>
        </Grid>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Example steps={steps} setSteps={setSteps} />
      </DndProvider>
    </>
  );
};

export default TakeoffWizardStepsTable;
