import * as React from "react";
import PropTypes, { resetWarningCache } from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, RotateCw } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { companyServices } from "../../services/company.services";
import { takeoffServices } from "../../services/takeoff.services";
import { agreementServices } from "../../services/agreement.services";
import { orderServices } from "../../services/orders.service";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

//function for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//function to check if we sort in ascending or descending order
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "jobNum",
    numeric: false,
    disablePadding: false,
    label: "Job Number",
    center: false,
  },
  {
    id: "orderTotal",
    numeric: false,
    disablePadding: false,
    label: "Order Total",
    center: true,
  },
];

export default function OtherOrdersTable({
  initialValue = 0,
  totalValue = 0,
  rows,
  numberRecords = 0,
  orderTotal,
  setOrderTotal,
  selected,
  setSelected,
}) {
  //used to send data to next page
  const history = useHistory();

  //state for ordering in table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  //for page in table
  const [page, setPage] = React.useState(0);

  //for rows per page in table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //make page load until all data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //for holding error messages
  const [error, setError] = React.useState("");

  //number of selected rows
  var numSelected = selected.length;

  //make delete button load while delete in progress
  const [loading, setLoading] = React.useState(false);

  //to refetch data from database
  const [resubmit, setResubmit] = React.useState(false);

  //get values from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  useEffect(() => {
    setError("");
    setOrderTotal(orderTotal);
  }, []);

  const handleRequestSort = (event, property) => {
    //for sorting
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    //for selecting all rows
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.orderID);
      setOrderTotal(totalValue);
      setSelected(newSelecteds);
      return;
    }
    setOrderTotal(initialValue);
    setSelected([]);
  };

  const addToOrderTotal = (value) => {
    var tmp = Math.round(parseFloat(orderTotal) * 100) / 100;
    tmp = tmp + Math.round(parseFloat(value) * 100) / 100;
    setOrderTotal(Math.round(tmp * 100) / 100);
  };

  const removeFromOrderTotal = (value) => {
    var tmp = Math.round(parseFloat(orderTotal) * 100) / 100;
    tmp = tmp - Math.round(parseFloat(value) * 100) / 100;
    setOrderTotal(Math.round(tmp * 100) / 100);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      addToOrderTotal(Math.round(row.orderTotal * 100) / 100);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      removeFromOrderTotal(Math.round(row.orderTotal * 100) / 100);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));

      removeFromOrderTotal(Math.round(row.orderTotal * 100) / 100);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );

      removeFromOrderTotal(Math.round(row.orderTotal * 100) / 100);
    }

    setSelected(newSelected);
  };

  //check if row selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
          }}
        >
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="#303030"
              variant="h6"
              component="div"
            >
              Current Order Total: {initialValue}
            </Typography>
          </Toolbar>

          <TableContainer style={{ maxHeight: "300px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              stickyHeader
            >
              {/* <EnhancedTableHead
                numSelected={numSelected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
              /> */}
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    style={{ backgroundColor: "#0077c6" }}
                  >
                    {numberRecords > 0 ? (
                      <Checkbox
                        checkedIcon={
                          <IndeterminateCheckBoxIcon
                            sx={{ color: "#005085" }}
                          />
                        }
                        checked={numberRecords > 0 && numSelected > 0}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={
                        headCell.numeric
                          ? "right"
                          : headCell.center
                          ? "center"
                          : "left"
                      }
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{
                        color: "white",
                      }}
                      style={{ backgroundColor: "#0077c6" }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="3" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="3"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no orders to display. "}
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.orderID);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.orderID}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.orderID, row)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.jobNum}</TableCell>
                          <TableCell align={"center"}>
                            {row.orderTotal}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
