import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { systemDataServices } from "../../services/systemData.services";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
import { takeoffWizardServices } from "../../services/takeoffWizard.service";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const TakeoffWizardForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      duplicate = false,
      receivedTakeoffID = 0,
      setOpenSnackBar,
      setTabButtonLoading,
    },
    ref
  ) => {
    /*Company Object*/
    const [takeoff, setTakeoff] = useState();
    /*Selectors data*/
    const [businessUnits, setBusinessUnits] = useState([]);
    const [categories, setCategories] = useState([]);
    const [switchStyles, setSwitchStyles] = useState([]);
    const [switchColors, setSwitchColors] = useState([]);
    const [controllerTypes, setControllerTypes] = useState([]);
    const [switchAssemblyTypes, setSwitchAssemblyTypes] = useState([]);
    const [packagingTypes, setPackagingTypes] = useState([]);
    const [powerSupplyOptions, setPowerSupplyOptions] = useState([]);
    const [ampsOptions, setAmpsOptions] = useState([]);
    const [voltsOptions, setVoltsOptions] = useState([]);
    const [nbOfGangOptions, setNbOfGangOptions] = useState([]);
    const [gateWayTypes, setGateWayTypes] = useState([]);

    /*Description*/
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    /*Title*/
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    /*Business Unit*/
    const [businessUnit, setBusinessUnit] = useState();
    const [businessUnitDescription, setBusinessUnitDescription] = useState("");
    const [businessUnitError, setBusinessUnitError] = useState(false);
    /*Category*/
    const [category, setCategory] = useState();
    const [categoryDescription, setCategoryDescription] = useState("");
    const [categoryError, setCategoryError] = useState(false);
    /*Switch Style*/
    const [switchStyle, setSwitchStyle] = useState();
    const [switchStyleDescription, setSwitchStyleDescription] = useState("");
    const [switchStyleError, setSwitchStyleError] = useState(false);
    /*Switch Color*/
    const [switchColor, setSwitchColor] = useState();
    const [switchColorDescription, setSwitchColorDescription] = useState("");
    const [switchColorError, setSwitchColorError] = useState(false);
    /*Switch Assembly Type*/
    const [switchAssemblyType, setSwitchAssemblyType] = useState();
    const [switchAssemblyTypeDescription, setSwitchAssemblyTypeDescription] =
      useState("");
    const [switchAssemblyTypeError, setSwitchAssemblyTypeError] =
      useState(false);
    /*Coated Switch*/
    const [coatedSwitch, setCoatedSwitch] = useState(false);
    const [coatedSwitchDescription, setCoatedSwitchDescription] = useState("");
    /*Controller Type*/
    const [controllerType, setControllerType] = useState();
    const [controllerTypeDescription, setControllerTypeDescription] =
      useState("");
    const [controllerTypeError, setControllerTypeError] = useState(false);
    /*Packaging Type*/
    const [packagingType, setPackagingType] = useState();
    const [packagingTypeDescription, setPackagingTypeDescription] =
      useState("");
    const [packagingTypeError, setPackagingTypeError] = useState(false);
    /*Power Supply*/
    const [powerSupply, setPowerSupply] = useState();
    const [powerSupplyDescription, setPowerSupplyDescription] = useState("");
    const [powerSupplyError, setPowerSupplyError] = useState(false);
    /*Power Supply*/
    const [amps, setAmps] = useState();
    const [ampsDescription, setAmpsDescription] = useState("");
    const [ampsError, setAmpsError] = useState(false);
    /*volts*/
    const [volts, setVolts] = useState();
    const [voltsDescription, setVoltsDescription] = useState("");
    const [voltsError, setVoltsError] = useState(false);
    /*Number of gangs*/
    const [nbOfGangs, setNbOfGangs] = useState();
    const [nbOfGangsDescription, setNbOfGangsDescription] = useState("");
    const [nbOfGangsError, setNbOfGangsError] = useState(false);
    /*Gateway Type*/
    const [gatewayType, setGatewayType] = useState();
    const [gatewayTypeDescription, setGatewayTypeDescription] = useState("");
    const [gatewayTypeError, setGatewayTypeError] = useState(false);
    /*Loading*/
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const history = useHistory();
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );
    /*Get prodCat permission from redux*/
    const takeoffWiz = useSelector((state) =>
      state.user.user ? state.user.user.user.role.takeoffWiz : ""
    );
    /*Redirection*/
    if (adminManage && receivedTakeoffID === "") {
      history.push("/dashboard");
    } else if (create && takeoffWiz !== "M") history.push("/dashboard");

    /*Functions*/

    /*Fetch company data by ID*/
    const fetchTakeoffDataById = () => {
      takeoffWizardServices
        .getTakeoffByID(receivedTakeoffID)
        .then((response) => {
          const takeoffData = response.data;
          setTakeoff(takeoffData);
          setTitle(takeoffData.title);
          setDescription(takeoffData.description);
          setBusinessUnit(takeoffData.businessUnit);
          setBusinessUnitDescription(takeoffData.businessUnit.description);
          setCategory(takeoffData.category);
          setCategoryDescription(takeoffData.category.description);
          setSwitchAssemblyType(takeoffData.switchAssemblyType);
          setSwitchAssemblyTypeDescription(
            takeoffData.switchAssemblyType
              ? takeoffData.switchAssemblyType.description
              : ""
          );
          setSwitchStyle(takeoffData.switchStyle);
          setSwitchStyleDescription(
            takeoffData.switchStyle ? takeoffData.switchStyle.description : ""
          );
          setSwitchColor(takeoffData.switchColor);
          setSwitchColorDescription(
            takeoffData.switchColor ? takeoffData.switchColor.description : ""
          );
          setCoatedSwitch(takeoffData.coatedSwitch);
          setCoatedSwitchDescription(
            takeoffData.coatedSwitch ? "Coated Switch" : ""
          );
          setControllerType(takeoffData.controllerType);
          setControllerTypeDescription(
            takeoffData.controllerType
              ? takeoffData.controllerType.description
              : ""
          );
          setPowerSupply(takeoffData.powerSupply);
          setPowerSupplyDescription(
            takeoffData.powerSupply ? takeoffData.powerSupply.description : ""
          );
          setAmps(takeoffData.amps);
          setAmpsDescription(
            takeoffData.amps ? takeoffData.amps.description : ""
          );
          setVolts(takeoffData.volts);
          setVoltsDescription(
            takeoffData.volts ? takeoffData.volts.description : ""
          );
          setPackagingType(takeoffData.packaging);
          setNbOfGangs(takeoffData.gangNbr);
          setGatewayType(takeoffData.gatewayType);
        })
        .catch((error) => setSaveError(error.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Save Changes*/
    const save = (event) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      /*Conditions to check if there are any errors*/
      if (description === "") {
        setDescriptionError(true);
      }
      if (title === "") setTitleError(true);
      if (!businessUnit || businessUnit === null) setBusinessUnitError(true);
      if (!category || category === null) setCategoryError(true);
      if (
        category?.description !== "Assembly" &&
        (!packagingType || packagingType === null)
      )
        setPackagingTypeError(true);
      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        title === "" ||
        description === "" ||
        !businessUnit ||
        businessUnit === null ||
        !category ||
        category === null ||
        (category?.description !== "Assembly" &&
          (!packagingType || packagingType === null))
      ) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        if (!create) {
          /*If the page is in edit mode*/
          takeoffWizardServices
            .updateTakeoffWizardData(takeoff.stepID, {
              Title: title,
              Description: description,
              SwitchStyleCode: switchStyle?.switchStyleCode,
              SwitchColorCode: switchColor?.switchColorCode,
              CoatedSwitch: coatedSwitch,
              BusinessUnitCode: businessUnit?.businessUnitCode,
              ProductCategoryCode: category?.categoryCode,
              AmpsCode: amps?.ampsCode,
              VoltsCode: volts?.voltsCode,
              GangNbrCode: nbOfGangs?.gangNbrCode,
              GatewayTypeCode: gatewayType?.gatewayTypeCode,
              PackagingCode: packagingType ? packagingType.packagingCode : 1,
              PowerSupplyCode: powerSupply?.powerSupplyCode,
              ControllerTypeCode: controllerType?.controllerTypeCode,
              SwitchAssemblyTypeCode:
                switchAssemblyType?.switchAssemblyTypeCode,
            })
            .then((response) => {
              setPristine();
              setButtonLoading(false);
              setTabButtonLoading(false);
              setOpenSnackBar(true);
              setSaveError("");
              sessionStorage.removeItem("unsavedChanges");

            })
            .catch((error) => {
              setSaveError(error.response.data.title);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        } else if (create || duplicate) {
          /*If the page is in create mode*/
          takeoffWizardServices
            .createTakeoffWizard({
              Title: title,
              Description: description,
              SwitchStyleCode: switchStyle?.switchStyleCode,
              SwitchColorCode: switchColor?.switchColorCode,
              CoatedSwitch: coatedSwitch,
              BusinessUnitCode: businessUnit?.businessUnitCode,
              ProductCategoryCode: category?.categoryCode,
              AmpsCode: amps?.ampsCode,
              VoltsCode: volts?.voltsCode,
              GangNbrCode: nbOfGangs?.gangNbrCode,
              GatewayTypeCode: gatewayType?.gatewayTypeCode,
              PackagingCode: packagingType ? packagingType.packagingCode : 1,
              PowerSupplyCode: powerSupply?.powerSupplyCode,
              ControllerTypeCode: controllerType?.controllerTypeCode,
              SwitchAssemblyTypeCode:
                switchAssemblyType?.switchAssemblyTypeCode,
            })
            .then((response) => {
              setPristine();
              setSaveError("");
              setButtonLoading(false);
              setTabButtonLoading(false);
              sessionStorage.removeItem("unsavedChanges");

              if (duplicate) {
                history.push("/takeoff-wizard");
              } else {
                history.push({
                  pathname: "/takeoff-wizard-information",
                  state: {
                    takeoffID: response.data.stepID,
                    takeoffDescription:
                      "Sequence Number " + response.data.stepSequence,
                    create: false,
                    openSnackBar: true,
                  },
                });
              }
            })
            .catch((error) => {
              setSaveError(error.response.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        }
      }
    };

    /*Fetch Business Units*/
    const fetchAllBusinessUnits = () => {
      systemDataServices
        .getAllBusinessUnits()
        .then((response) => {
          setBusinessUnits(response.data);
          setBusinessUnits((businessUnits) => [
            ...businessUnits,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Categories*/
    const fetchCategories = (buUrl) => {
      systemDataServices
        .getProductCategoryByID(buUrl)
        .then((response) => {
          setCategories(response.data);
          setCategories((categories) => [...categories, { description: "" }]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Switch Assembly Type*/
    const fetchSwitchAssemblyTypes = () => {
      systemDataServices
        .getAllSwitchAssemblyType()
        .then((response) => {
          setSwitchAssemblyTypes(response.data);
          setSwitchAssemblyTypes((switchAssemblyTypes) => [
            ...switchAssemblyTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Switch Colors*/
    const fetchSwitchColors = () => {
      systemDataServices
        .getAllSwitchColor()
        .then((response) => {
          setSwitchColors(response.data);
          setSwitchColors((switchColors) => [
            ...switchColors,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Switch Styles*/
    const fetchSwitchStyles = () => {
      systemDataServices
        .getAllSwitchStyle()
        .then((response) => {
          setSwitchStyles(response.data);
          setSwitchStyles((switchStyles) => [
            ...switchStyles,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Switch Styles*/
    const fetchControllerTypes = () => {
      systemDataServices
        .getAllControllerType()
        .then((response) => {
          setControllerTypes(response.data);
          setControllerTypes((controllerTypes) => [
            ...controllerTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Power Supplies*/
    const fetchPowerSupplies = () => {
      systemDataServices
        .getAllPowerSupply()
        .then((response) => {
          setPowerSupplyOptions(response.data);
          setPowerSupplyOptions((powerSupplyOptions) => [
            ...powerSupplyOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Amps*/
    const fetchAmps = () => {
      systemDataServices
        .getAllAmps()
        .then((response) => {
          setAmpsOptions(response.data);
          setAmpsOptions((ampsOptions) => [
            ...ampsOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Volts*/
    const fetchVolts = () => {
      systemDataServices
        .getAllVolts()
        .then((response) => {
          setVoltsOptions(response.data);
          setVoltsOptions((voltsOptions) => [
            ...voltsOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Pachaging Types*/
    const fetchPackagingTypes = () => {
      systemDataServices
        .getAllPackaging()
        .then((response) => {
          setPackagingTypes(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Number of Gang*/
    const fetchNbOFGang = () => {
      systemDataServices
        .getAllGangNumber()
        .then((response) => {
          setNbOfGangOptions(response.data);
          setNbOfGangOptions((nbOfGangOptions) => [
            ...nbOfGangOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Fetch Gateway Types*/
    const fetchGatewayTypes = () => {
      systemDataServices
        .getAllGatewayType()
        .then((response) => {
          setGateWayTypes(response.data);
          setLoading(false);
          setGateWayTypes((gatewayTypes) => [
            ...gatewayTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Set Description*/
    // const renderDescription = () => {
    //   if (
    //     businessUnit ||
    //     category ||
    //     switchAssemblyType ||
    //     switchStyle ||
    //     switchColor ||
    //     controllerType ||
    //     powerSupply ||
    //     amps ||
    //     volts
    //   )
    //     setDescription(
    //       ampsDescription +
    //         (businessUnitDescription ? " " : "") +
    //         businessUnitDescription +
    //         (controllerTypeDescription ? " " : "") +
    //         controllerTypeDescription +
    //         (categoryDescription ? " " : "") +
    //         categoryDescription +
    //         (switchStyleDescription ? " " : "") +
    //         switchStyleDescription +
    //         (switchColorDescription ? " " : "") +
    //         switchColorDescription +
    //         (coatedSwitchDescription ? " " : "") +
    //         coatedSwitchDescription +
    //         (powerSupplyDescription ? " " : "") +
    //         powerSupplyDescription +
    //         (voltsDescription ? " " : "") +
    //         voltsDescription +
    //         (switchAssemblyTypeDescription ? " " : "") +
    //         switchAssemblyTypeDescription
    //     );
    //   else setDescription("");
    // };

    /*Reset All Fields*/
    const resetFields = () => {
      setSwitchAssemblyType({});
      setSwitchStyle({});
      setSwitchColor({});
      setCoatedSwitch(false);
      setControllerType({});
      setPowerSupply({});
      setAmps({});
      setVolts({});
      setNbOfGangs({});
      setGatewayType({});
      setSwitchAssemblyTypeDescription("");
      setSwitchStyleDescription("");
      setSwitchColorDescription("");
      setCoatedSwitchDescription("");
      setControllerTypeDescription("");
      setPowerSupplyDescription("");
      setAmpsDescription("");
      setVoltsDescription("");
      setNbOfGangsDescription("");
      setGatewayTypeDescription("");
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    /*Initial useEffect*/
    useEffect(() => {
      if ((!create || duplicate) && receivedTakeoffID !== 0) {
        fetchTakeoffDataById();
      }
      fetchAllBusinessUnits();
      fetchSwitchAssemblyTypes();
      fetchSwitchColors();
      fetchSwitchStyles();
      fetchControllerTypes();
      fetchPowerSupplies();
      fetchAmps();
      fetchVolts();
      fetchPackagingTypes();
      fetchNbOFGang();
      fetchGatewayTypes();
    }, []);

    /*Fetch categories everytime the business unit is changed*/
    useEffect(() => {
      if (businessUnit) {
        fetchCategories(businessUnit.businessUnitCode);
      }
    }, [businessUnit]);

    /*Reset all fields when category has changed*/
    useEffect(() => {
      resetFields();
    }, [category]);

    /*Concatinate Description*/
    // useEffect(() => {
    //   renderDescription();
    //   if (description !== "") setDescriptionError(false);
    // }, [
    //   businessUnit,
    //   category,
    //   switchAssemblyType,
    //   switchStyle,
    //   switchColor,
    //   coatedSwitch,
    //   controllerType,
    //   powerSupply,
    //   amps,
    //   volts,
    //   description,
    // ]);

    useImperativeHandle(ref, () => ({
      save,
      setPristine,
    }));

    return (
      <Paper elevation={0}>
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{ borderRight: "2px solid #c4c4c4", paddingRight: "20px" }}
              >
                {/*Form Grid*/}
                <form id="form" onSubmit={create || adminManage ? save : ""}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Description
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Title"
                        required
                        fullWidth
                        value={title}
                        error={titleError}
                        helperText={titleError ? "Title field is empty" : ""}
                        onChange={(e) => {
                          if (create || adminManage) {
                            setTitle(e.target.value);
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (titleError) setTitleError(false);
                          }
                        }}
                        disabled={
                          create || adminManage || duplicate ? false : true
                        }
                      />
                    </Grid>
                    {/* Description */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip
                        title={description}
                        arrow
                        hidden={description !== ""}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: "100%",
                            },
                          },
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Step Description"
                          required
                          fullWidth
                          value={description}
                          error={descriptionError}
                          helperText={
                            descriptionError ? "Description field is empty" : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage) {
                              setDescription(e.target.value);
                              setDirty();
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              if (descriptionError) setDescriptionError(false);
                            }
                          }}
                          disabled={
                            create || adminManage || duplicate ? false : true
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        General
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Business Unit*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={businessUnits}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={businessUnit ? businessUnit : {}}
                        onChange={(event, value) => {
                          setCategory({});
                          setCategoryDescription("");
                          setBusinessUnit(value);
                          value && value !== null
                            ? setBusinessUnitDescription(value?.description)
                            : setBusinessUnitDescription("");
                          setDirty();
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          if (businessUnitError) setBusinessUnitError(false);
                        }}
                        disabled={
                          create || adminManage || duplicate ? false : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business Unit"
                            fullWidth
                            size="small"
                            required
                            error={businessUnitError}
                            helperText={
                              businessUnitError
                                ? "Business Unit field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Category*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={categories ? categories : {}}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={category ? category : {}}
                        onChange={(event, value) => {
                          setCategory(value);
                          value && value !== null
                            ? setCategoryDescription(value?.description)
                            : setCategoryDescription("");
                          setDirty();
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          if (categoryError) setCategoryError(false);
                        }}
                        disabled={
                          create || adminManage || duplicate ? false : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            fullWidth
                            size="small"
                            required
                            error={categoryError}
                            helperText={
                              categoryError ? "Category field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {category?.description !== "Assembly" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Switch Assembly Type */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={
                            switchAssemblyTypes ? switchAssemblyTypes : {}
                          }
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={switchAssemblyType ? switchAssemblyType : {}}
                          onChange={(event, value) => {
                            setSwitchAssemblyType(value);
                            value && value !== null
                              ? setSwitchAssemblyTypeDescription(
                                  value?.description
                                )
                              : setSwitchAssemblyTypeDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (switchAssemblyTypeError)
                              setSwitchAssemblyTypeError(false);
                          }}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly"
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Switch Assembly Type"
                              fullWidth
                              size="small"
                              error={switchAssemblyTypeError}
                              helperText={
                                switchAssemblyTypeError
                                  ? "Switch Assembly Type field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Switch" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Switch Style */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={switchStyles}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            (category?.description !== "Switch" &&
                              option.description === "Portable") ||
                            option.description === ""
                          }
                          value={
                            switchStyle ? switchStyle : { description: "" }
                          }
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Switch"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setSwitchStyle(value);
                            value && value !== null
                              ? setSwitchStyleDescription(value?.description)
                              : setSwitchStyleDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (switchStyleError) setSwitchStyleError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Switch Style"
                              fullWidth
                              size="small"
                              error={switchStyleError}
                              helperText={
                                switchStyleError
                                  ? "Switch Style field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Switch" &&
                    category?.description !== "Wall Plate" &&
                    category?.description !== "Harness" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Switch Color */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={switchColors}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={switchColor ? switchColor : {}}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Switch" &&
                                category?.description !== "Wall Plate" &&
                                category?.description !== "Harness"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setSwitchColor(value);
                            value && value !== null
                              ? setSwitchColorDescription(value?.description)
                              : setSwitchColorDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (switchColorError) setSwitchColorError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Switch Color"
                              fullWidth
                              size="small"
                              error={switchColorError}
                              helperText={
                                switchColorError
                                  ? "Switch Color field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Switch" &&
                    category?.description !== "Assembly" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/*Coated Switch*/}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={coatedSwitch}
                              onClick={() => {
                                setCoatedSwitch(!coatedSwitch);
                                coatedSwitch
                                  ? setCoatedSwitchDescription("")
                                  : setCoatedSwitchDescription("Coated Switch");
                              }}
                              disabled={
                                create || adminManage || duplicate
                                  ? category?.description !== "Switch" &&
                                    category?.description !== "Assembly"
                                    ? true
                                    : false
                                  : true
                              }
                            />
                          }
                          label="Coated Switch"
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Controller" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Controller Type */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={controllerTypes}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={controllerType ? controllerType : {}}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Controller"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setControllerType(value);
                            value && value !== null
                              ? setControllerTypeDescription(value?.description)
                              : setControllerTypeDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (controllerTypeError)
                              setControllerTypeError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Controller Type"
                              fullWidth
                              size="small"
                              error={controllerTypeError}
                              helperText={
                                controllerTypeError
                                  ? "Controller Type field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Controller" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Power Supply */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={powerSupplyOptions}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={powerSupply ? powerSupply : ""}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Controller"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setPowerSupply(value);
                            value && value !== null
                              ? setPowerSupplyDescription(value?.description)
                              : setPowerSupplyDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (powerSupplyError) setPowerSupplyError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Power Supply"
                              fullWidth
                              size="small"
                              error={powerSupplyError}
                              helperText={
                                powerSupplyError
                                  ? "Power Supply field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Controller" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Amps */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={ampsOptions}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={amps ? amps : {}}
                          onChange={(event, value) => {
                            setAmps(value);
                            value && value !== null
                              ? setAmpsDescription(value?.description)
                              : setAmpsDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (ampsError) setAmpsError(false);
                          }}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Controller"
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Amps"
                              fullWidth
                              size="small"
                              error={ampsError}
                              helperText={
                                ampsError ? "Amps field is empty" : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Assembly" &&
                    category?.description !== "Controller" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Volts */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={voltsOptions}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.description === ""
                          }
                          value={volts ? volts : {}}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Assembly" &&
                                category?.description !== "Controller"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setVolts(value);
                            value && value !== null
                              ? setVoltsDescription(value?.description)
                              : setVoltsDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (voltsError) setVoltsError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Volts"
                              fullWidth
                              size="small"
                              error={voltsError}
                              helperText={
                                voltsError ? "Volts field is empty" : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {/* {category?.description === "Assembly" ||
                    category?.description !== "Wall Plate" ||
                    category?.description !== "Gateway" ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Advanced
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    ) : null} */}
                    {category?.description === "Assembly" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Packaging Types */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={packagingTypes}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          value={packagingType ? packagingType : null}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description === "Assembly"
                                ? true
                                : false
                              : true
                          }
                          onChange={(event, value) => {
                            setPackagingType(value);
                            value && value !== null
                              ? setPackagingTypeDescription(value?.description)
                              : setPackagingTypeDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (packagingTypeError)
                              setPackagingTypeError(false);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Packaging Type"
                              fullWidth
                              required={category?.description !== "Assembly"}
                              size="small"
                              error={packagingTypeError}
                              helperText={
                                packagingTypeError
                                  ? "Packaging Type field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Wall Plate" ? null : (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {/* Number Of Gang */}
                        <Autocomplete
                          id="combo-box-demo"
                          options={nbOfGangOptions}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          value={nbOfGangs ? nbOfGangs : {}}
                          onChange={(event, value) => {
                            setNbOfGangs(value);
                            value && value !== null
                              ? setNbOfGangsDescription(value?.description)
                              : setNbOfGangsDescription("");
                            setDirty();
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            if (nbOfGangsError) setNbOfGangs(false);
                          }}
                          disabled={
                            create || adminManage || duplicate
                              ? category?.description !== "Wall Plate"
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Number Of Gang"
                              fullWidth
                              size="small"
                              error={nbOfGangsError}
                              helperText={
                                nbOfGangsError
                                  ? "Number Of Gang field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {category?.description !== "Gateway" ? null : (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          {/* Gateway Type */}
                          <Autocomplete
                            id="combo-box-demo"
                            options={gateWayTypes}
                            getOptionLabel={(option) =>
                              option.description ? option.description : ""
                            }
                            value={gatewayType ? gatewayType : {}}
                            onChange={(event, value) => {
                              setGatewayType(value);
                              value && value !== null
                                ? setGatewayTypeDescription(value?.description)
                                : setGatewayTypeDescription("");
                              setDirty();
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                              if (gatewayTypeError) setGatewayTypeError(false);
                            }}
                            disabled={
                              create || adminManage || duplicate
                                ? category?.description !== "Gateway"
                                  ? true
                                  : false
                                : true
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Gateway Type"
                                fullWidth
                                size="small"
                                error={gatewayTypeError}
                                helperText={
                                  gatewayTypeError
                                    ? "Gateway Type field is empty"
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    )}

                    {/*Save button*/}
                    {create || adminManage || duplicate ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <CustomButton
                          type="submit"
                          loading={buttonLoading}
                          startIcon={<Save />}
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              ></Grid>
            </Grid>
          </>
        )}
        {saveError !== "" ? (
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        ) : null}
      </Paper>
    );
  }
);

export default TakeoffWizardForm;
