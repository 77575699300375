import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement specific price*/

const baseUrl = apiConfig + "/AgreementSpecificPrice";

const addAgreementSpecificPrices = (data) => {
  return fetchWrapper.post(baseUrl + "/AddAgreementSpecificPrice", data);
};
const updateAgreementSpecificPrices = (data) => {
  return fetchWrapper.put(baseUrl + "/UpdateAgreementSpecificPrice", data);
};

const getAgreementSpecificPrices = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAgreementSpecificPrices/" , data);
};

const deleteAgreementSpecificPrice = (priceID) => {
  return fetchWrapper.delete(baseUrl + "/DeleteAgreementSpecificPrice/"+ priceID);
};

export const agreementSpecificPricesServices = {
  addAgreementSpecificPrices,
  updateAgreementSpecificPrices,
  getAgreementSpecificPrices,
  deleteAgreementSpecificPrice
};
