import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { accountServices } from "../../services/account.services";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import SnackBar from "../SnackBar/SnackBar";
import { Filter, Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rolesServices } from "../../services/roles.services";
import CustomModal from "../CustomModal/CustomModal";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

/**holds table header values */
const headCells = [
  {
    id: "Description",
    numeric: false,
    disablePadding: true,
    label: "Role Name",
  },
  {
    id: "CompanyName",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    myRoles,
    rolePermission,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow style={{ height: 53 }}>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && numSelected > 0 ? (
            <Checkbox
              checkedIcon={
                <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) =>
          headCell.label === "Company Name" && myRoles === true ? (
            ""
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: "white", backgroundColor: "#0077c6" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function RolesTableComponent({
  myRoles = false,
  companyID = 0,
  setCreateRole,
  companyRole = false,
  compName = "",
  setCreateChangeRole,
  titleText = "Roles",
  dataObject = null,
  locationToBeSent = null,
}) {
  //get user permission from redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? myRoles === true
        ? state.user.user.user.role.ownRoles
        : state.user.user.user.role.clientRoles
      : ""
  );

  //used to get the data sent from previous page
  const location = useLocation();

  //used to send data to next page
  const history = useHistory();

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  //for sorting in table
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //holds IDs or selected rows
  const [selected, setSelected] = React.useState([]);

  //holds page we are in
  const [page, setPage] = React.useState(0);

  //holds rows per page
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //variable to change when we want to resubmit values
  const [resubmit, setResubmit] = React.useState(false);

  //state that holds all rows in table
  const [rows, setRows] = React.useState([]);

  //to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //holds error in case of any
  const [error, setError] = React.useState("");

  //holds number of selected items
  var numSelected = selected.length;

  //holds total number of records in the database that fits the filters we need
  const [numberRecords, setNumberRecords] = React.useState(0);

  //for filters
  const [companySearch, setCompanySearch] = React.useState("");
  const [roleSearch, setRoleSearch] = React.useState("");

  //for delete button loading while waiting for delete process to finish
  const [loading, setLoading] = React.useState(false);

  //for permissions
  const [catchedID, setCatchedID] = React.useState("");

  //to get users linked to role before deletion if any linked to it
  const [roleUsers, setRoleUsers] = React.useState([]);

  //for page load while waiting for data to be fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //for snackbar display on success
  const [success, setSuccess] = React.useState(false);

  //to display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //for snackbar if any delete success from tabs
  const [snackbarSuccess, setSnackbarSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //we get data from redux
  const roleID = useSelector((state) =>
    state.user.user ? state.user.user.user.roleID : ""
  );

  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const getData = () => {
    //function to get the data to display in the table

    //we fetch the data depending on where are we accessing the roles table from
    if (myRoles === false) {
      if (companyRole === false) {
        setRows([]);
        const data = {
          Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
          Filters: { RoleName: roleSearch, CompanyName: companySearch },
          ViewAllRoles: true,
          myCompanyID: companyID,
          orderDirection: orderDirection,
          orderColumn: orderColumn,
        };

        rolesServices
          .getRoles(data)
          .then((response) => {
            setPageLoad(false);
            setNumberRecords(response.data.totalNumberOfRecords);

            setRows(response.data.roles);
          })
          .catch((error) => {
            setError(error.response.data.message);
            setPageLoad(false);
          });
      } else {
        setRows([]);
        const data2 = {
          Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
          Filters: { RoleName: roleSearch, CompanyName: companySearch },
          orderDirection: orderDirection,
          orderColumn: orderColumn,
          CompanyID: companyID,
        };

        rolesServices
          .getMyRoles(data2)
          .then((response) => {
            setPageLoad(false);
            setNumberRecords(response.data.totalNumberOfRecords);
            setRows(response.data.roles);
          })
          .catch((error) => {
            setError(error.response.data.message);
            setPageLoad(false);
          });
      }
    } else {
      setRows([]);
      const data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: { RoleName: roleSearch, CompanyName: companySearch },
        orderDirection: orderDirection,
        orderColumn: orderColumn,
        companyID: companyID,
      };

      rolesServices
        .getMyRoles(data)
        .then((response) => {
          setPageLoad(false);

          setNumberRecords(response.data.totalNumberOfRecords);
          setRows(response.data.roles);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    //we get data from database
    //we check if it is from myRoles or from Roles to get the data depending on the page

    if (!companyRole && success === false) {
      setSuccess(snackbarSuccess);
      setSnackbarSuccess(false);
    }
    window.scrollTo(0, 0);
    setPageLoad(true);
    setError("");
    setSelected([]);
    getData();
  }, [page, rowsPerPage, resubmit]);

  useEffect(() => {
    if (catchedID !== "") {
      accountServices
        .getUsersByRoleID(catchedID)
        .then((response) => {
          setRoleUsers(response.data);
        })
        .catch((error) => setError(error.response.data.error.error));
    }
  }, [catchedID]);

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //function that selects all data in the page
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.roleID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const viewRolesData = (
    rolesID,
    roleName,
    companyName,
    companyID,
    systemGenerated
  ) => {
    //send data to the RolesTabs
    if (companyRole === false) {
      history.push({
        pathname: "/role-information",
        state: {
          roleID: rolesID,
          roleName: roleName,
          source: myRoles ? "My Roles" : "Roles",
          linkSource: myRoles ? "/my-roles" : "/Roles",
          titleText: roleName + " at " + companyName,
          companyID: companyID,
          selfRole: rolesID === roleID ? true : false,
          systemGenerated: systemGenerated,
          fromCompany: false,
          locationToReceive: null,
        },
      });
    } else {
      history.push({
        pathname: "/role-information",
        state: {
          roleID: rolesID,
          roleName: roleName,
          source: "Roles",
          linkSource: "/company-information",
          titleText: roleName + " at " + companyName,
          companyID: companyID,
          selfRole: rolesID === roleID ? true : false,
          systemGenerated: systemGenerated,
          companyName: companyName,
          fromCompany: true,
          roleSource: companyID === compID ? "My Company" : "Companies",
          roleSourceName: compName,
          roleSourceLink:
            companyID === compID ? "/company-information" : "/Companies",
          roleSourceNameLink: "/company-information",
          locationToReceive: locationToBeSent,
        },
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    //for page change
    setPage(newPage);
  };

  const clearFilterData = () => {
    //for clearning filter data
    setRoleSearch("");
    setCompanySearch("");
    setResubmit(!resubmit);
  };

  const deleteRoles = () => {
    //we delete selected roles by providing an array of ID's
    setLoading(true);
    setDeleteRecord(false);
    rolesServices
      .deleteRoles({ Roles: selected })
      .then((response) => {
        setLoading(false);
        setSuccess(true);
        setResubmit(!resubmit);

        setSelected([]);
        setCatchedID("");
        setSelected([]);
      })
      .catch((error) => {
        setCatchedID(error.response.data.error.error.split(":")[1].trim());
        setLoading(false);
      });
  };

  const submitFilters = (event) => {
    //we resubmit filters
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    //to handle change in rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createRole = () => {
    //to go to create role form
    if (companyRole === true) {
      setCreateRole(true);
      setCreateChangeRole(true);
    } else {
      history.push({
        pathname: "/create-role",
        state: {
          companyID: myRoles ? companyID : 0,
          source: myRoles,
        },
      });
    }
  };

  //to check if a row is selected or not
  const isSelected = (name) => selected.indexOf(name) !== -1;

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    //send data to the RolesTabs
    if (companyRole === false) {
      const objectToBeSent = {
        roleID: contextMenuData.role.roleID,
        roleName: contextMenuData.role.description,
        source: myRoles ? "My Roles" : "Roles",
        linkSource: myRoles ? "/my-roles" : "/Roles",
        titleText:
          contextMenuData.role.description +
          " at " +
          contextMenuData.role.company.name,
        companyID: contextMenuData.role.company.companyID,
        selfRole: contextMenuData.role.roleID === roleID ? true : false,
        systemGenerated: contextMenuData.role.systemGenerated,
        fromCompany: false,
        dataObject: dataObject,
        locationToReceive: null,
      };

      const encryptedDate = await encryptObject(objectToBeSent);

      window.open(
        "/role-information?otbd=" + encodeURIComponent(encryptedDate),
        "_blank"
      );

      setContextMenu(null);
    } else {
      const objectToBeSent = {
        roleID: contextMenuData.role.roleID,
        roleName: contextMenuData.role.description,
        source: "Roles",
        linkSource: "/company-information",
        titleText:
          contextMenuData.role.description +
          " at " +
          contextMenuData.role.company.name,
        companyID: contextMenuData.role.company.companyID,
        selfRole: contextMenuData.role.roleID === roleID ? true : false,
        systemGenerated: contextMenuData.role.systemGenerated,
        companyName: contextMenuData.role.company.name,
        fromCompany: true,
        roleSource:
          contextMenuData.role.company.companyID === compID
            ? "My Company"
            : "Companies",
        roleSourceName: compName,
        roleSourceLink:
          contextMenuData.role.company.companyID === compID
            ? "/company-information"
            : "/Companies",
        roleSourceNameLink: "/company-information",
        dataObject: dataObject,
        locationToReceive: locationToBeSent,
      };

      const encryptedDate = await encryptObject(objectToBeSent);

      window.open(
        "/role-information?otbd=" + encodeURIComponent(encryptedDate),
        "_blank"
      );

      setContextMenu(null);
    }
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Role(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Role Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteRoles}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {catchedID !== "" && roleUsers[0] ? (
        <CustomModal openModal={catchedID !== ""}>
          <Typography variant="h6" component="span" align="left">
            {" "}
            Error
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="span" align="left">
            {"The role '" +
              roleUsers[0].user.role.description +
              "' could not be deleted because the following user(s) are assigned to it:"}
          </Typography>
          <br />
          {roleUsers.map((user) => (
            <>
              <Typography variant="body1" component="span" align="left">
                - {user.user.userName}
              </Typography>
              <br />
            </>
          ))}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton toggle={() => setCatchedID("")}>Cancel</CustomButton>
          </div>
        </CustomModal>
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {titleText}
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Trash2 />}
                    size="medium"
                    tableButton={true}
                    loading={loading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Plus />}
                    size="medium"
                    tableButton={true}
                    toggle={() => createRole()}
                  >
                    New Role
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilters}>
                <Grid container spacing={2}>
                  <Grid item xs={8} md={5} lg={2}>
                    <TextField
                      size="small"
                      value={roleSearch}
                      onChange={(e) => setRoleSearch(e.target.value)}
                      label="Role"
                      fullWidth
                      sx={{
                        marginRight: "10px",
                        marginLeft: "10px",
                        marginBottom: "20px",
                      }}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                  {myRoles === false ? (
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        fullWidth
                        value={companySearch}
                        onChange={(e) => setCompanySearch(e.target.value)}
                        label="Company"
                        sx={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          marginBottom: "20px",
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                myRoles={myRoles}
                rolePermission={rolePermission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no Roles to display.{" "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={createRole}
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.role.roleID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        style={{ height: 53 }}
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                        key={row.role.roleID}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {rolePermission === "R" ||
                          (myRoles === true &&
                            row.role.systemGenerated === true) ||
                          roleID === row.role.roleID ? (
                            ""
                          ) : (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.role.roleID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={() =>
                            viewRolesData(
                              row.role.roleID,
                              row.role.description,
                              row.role.company.name,
                              row.role.company.companyID,
                              row.role.systemGenerated
                            )
                          }
                        >
                          {row.role.description}
                        </TableCell>
                        {myRoles === false ? (
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewRolesData(
                                row.role.roleID,
                                row.role.description,
                                row.role.company.name,
                                row.role.company.companyID,
                                row.role.systemGenerated
                              )
                            }
                          >
                            {row.role.company.name}
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
