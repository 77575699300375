import React, { useEffect, useState } from "react";
import UsersTableComponent from "../../../components/UsersTableComponent/UsersTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import OrphanedOrderTable from "../../../components/OrphanedOrders/OrphanedOrdersTable";
import OrderTableTemp from "../../../components/OrderTable/OrderTableTemp";

const OrphanedOrdersTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  useEffect(() => {
    //for help button
    setPageLink("Deleted Orders");

    //for drawer menu
    setPageID(13);
  }, []);

  return (
    <div>
      <OrphanedOrderTable />

    </div>
  );
};

export default OrphanedOrdersTable;
