import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import SnackBar from "../SnackBar/SnackBar";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, Check } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { companyServices } from "../../services/company.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { agreementServices } from "../../services/agreement.services";
import { logsService } from "../../services/logs.services";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

//holds columns for table head
const headCells = [
  {
    id: "ItemDescription",
    numeric: false,
    disablePadding: false,
    center: false,
    label: "Item Description",
  },
  {
    id: "Quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
    center: false,
  },
  {
    id: "UnitOfMeasure",
    numeric: false,
    disablePadding: true,
    center: true,
    label: "Unit Of Measure",
  },
  {
    id: "UnitPrice",
    numeric: true,
    disablePadding: false,
    center: false,
    label: "Unit Price",
  },
  {
    id: "TotalPrice",
    numeric: true,
    disablePadding: false,
    center: false,
    label: "Total Price",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InvoiceLinesTable({
  rows,
  numberRecords,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  currency = "",
}) {
  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Invoice Lines
            </Typography>
          </Toolbar>

          <TableContainer className={"table-container"}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead />
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Lines to display. "}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.itemDescription + " " + row.totalPrice}
                      >
                        <TableCell width={"40%"}>
                          {row.itemDescription}
                        </TableCell>
                        <TableCell width={"15%"} align={"right"}>
                          {row.quantity}
                        </TableCell>
                        <TableCell width={"15%"} align={"center"}>
                          {row.unitOfMeasure}
                        </TableCell>
                        <TableCell width={"15%"} align={"right"}>
                          {parseFloat(row.unitPrice).toFixed(2)} {currency}
                        </TableCell>
                        <TableCell width={"15%"} align={"right"}>
                          {parseFloat(row.totalPrice).toFixed(2)} {currency}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
