import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import {
  Plus,
  RefreshCcw,
  Save,
  Trash2,
  UserCheck,
  UserX,
  X,
} from "react-feather";
import UsersTableComponent from "../UsersTableComponent/UsersTableComponent";
import RoleForm from "../../components/RoleForm/RoleForm";
import { rolesServices } from "../../services/roles.services";
import { accountServices } from "../../services/account.services";
import UserForm from "../UserForm/UserForm";
import SnackBar from "../SnackBar/SnackBar";
import CustomModal from "../CustomModal/CustomModal";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { adminServices } from "../../services/admin.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

//function to change tabs values
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RolesTabs({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //state coming from previous page
  const [locationToReceive, setLocationToReceive] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.locationToReceive
          ? decryptedObject.locationToReceive
          : null
        : null
      : location.state
        ? location.state.locationToReceive
          ? location.state.locationToReceive
          : null
        : null
  );
  const companyID = fromURL
    ? decryptedObject
      ? decryptedObject.companyID
        ? decryptedObject.companyID
        : 0
      : 0
    : location.state
      ? location.state.companyID
        ? location.state.companyID
        : 0
      : 0;

  const roleID = fromURL
    ? decryptedObject
      ? decryptedObject.roleID
        ? decryptedObject.roleID
        : 0
      : 0
    : location.state
      ? location.state.roleID
        ? location.state.roleID
        : 0
      : 0;

  const linkSource = fromURL
    ? decryptedObject
      ? decryptedObject.linkSource
        ? decryptedObject.linkSource
        : ""
      : ""
    : location.state
      ? location.state.linkSource
        ? location.state.linkSource
        : ""
      : "";

  /**fetching user permissions from redux */
  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  if (roleID === 0 || companyID === 0) {
    history.push({
      pathname: "/Dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  /**We are checking if the user has permission to view this page or has picked a role to view */
  if (
    (linkSource === "/my-roles" && ownRoles === "N") ||
    (linkSource === "/roles" && clientRoles === "N")
  ) {
    history.push("/Dashboard");
  }

  //to hold tab value
  const [value, setValue] = useState(0);

  //for permissions
  const [companyAdmin, setCompanyAdmin] = useState(false);
  const [adminManage, setAdminManage] = useState(false);
  const [catchedID, setCatchedID] = React.useState("");
  const [receivedUserID, setReceivedUserID] = React.useState(0);
  const [adminManageForUsers, setAdminManageForUsers] = React.useState(false);
  const [username, setUsername] = React.useState("");

  //for errors
  const [saveError, setSaveError] = useState("");

  //for delete button loading while waiting for delete
  const [buttonLoading, setButtonLoading] = useState(false);

  //to replace user table with create user form to create user
  const [createUserRole, setCreateUserRole] = useState(false);

  //to add New User to the tab on create user
  const [createChangeUser, setCreateChangeUser] = useState(false);

  //for save button loading while saving the data
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  //to display alert dialog before delete is done
  const [deleteRecord, setDeleteRecord] = useState(false);

  //to save users related to role if we want to delete a role that has users linked to it
  const [roleUsers, setRoleUsers] = React.useState([]);

  //to display user name in breadcrumbs
  const [userFullName, setUserFullName] = React.useState("");

  //to display the user status and to update it in case of activation or deactivation
  const [userStatus, setUserStatus] = React.useState("");

  //for snackbar message
  const [message, setMessage] = React.useState("");

  //to get tab we want when coming from a page within the tab
  const [tab, setTab] = useState(location.state ? location.state.tab : 0);

  //to track changes in the tabs
  const [formChanged, setFormChanged] = React.useState(false);

  //we fetch data sent from previous page
  const [roleName, setRoleName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.roleName
          ? decryptedObject.roleName
          : ""
        : ""
      : location.state
        ? location.state.roleName
          ? location.state.roleName
          : ""
        : ""
  );

  const companyName = fromURL
    ? decryptedObject
      ? decryptedObject.companyName
        ? decryptedObject.companyName
        : ""
      : ""
    : location.state
      ? location.state.companyName
        ? location.state.companyName
        : ""
      : "";

  const dataFromUrl = fromURL
    ? decryptedObject
      ? decryptedObject.dataObject
        ? decryptedObject.dataObject
        : null
      : null
    : null;

  const source = fromURL
    ? decryptedObject
      ? decryptedObject.source
        ? decryptedObject.source
        : ""
      : ""
    : location.state
      ? location.state.source
        ? location.state.source
        : ""
      : "";

  const titleText = fromURL
    ? decryptedObject
      ? decryptedObject.titleText
        ? decryptedObject.titleText
        : ""
      : ""
    : location.state
      ? location.state.titleText
        ? location.state.titleText
        : ""
      : "";

  const selfRole = fromURL
    ? decryptedObject
      ? decryptedObject.selfRole
        ? decryptedObject.selfRole
        : false
      : false
    : location.state
      ? location.state.selfRole
        ? location.state.selfRole
        : false
      : false;

  const systemGenerated = fromURL
    ? decryptedObject
      ? decryptedObject.systemGenerated
        ? decryptedObject.systemGenerated
        : false
      : false
    : location.state
      ? location.state.systemGenerated
        ? location.state.systemGenerated
        : false
      : false;

  const fromCompany = fromURL
    ? decryptedObject
      ? decryptedObject.fromCompany
        ? decryptedObject.fromCompany
        : false
      : false
    : location.state
      ? location.state.fromCompany
        ? location.state.fromCompany
        : false
      : false;

  const roleSource = fromURL
    ? decryptedObject
      ? decryptedObject.roleSource
        ? decryptedObject.roleSource
        : ""
      : ""
    : location.state
      ? location.state.roleSource
        ? location.state.roleSource
        : ""
      : "";

  const roleSourceName = fromURL
    ? decryptedObject
      ? decryptedObject.roleSourceName
        ? decryptedObject.roleSourceName
        : ""
      : ""
    : location.state
      ? location.state.roleSourceName
        ? location.state.roleSourceName
        : ""
      : "";

  const roleSourceLink = fromURL
    ? decryptedObject
      ? decryptedObject.roleSourceLink
        ? decryptedObject.roleSourceLink
        : ""
      : ""
    : location.state
      ? location.state.roleSourceLink
        ? location.state.roleSourceLink
        : ""
      : "";

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location
      ? location.state
        ? location.state.openSnackBar
          ? true
          : false
        : false
      : false
  );

  const [previousPageSnackbar, setPreviousPageSnackbar] = useState(
    location.state
      ? location.state.previousPageSnackbar
        ? location.state.previousPageSnackbar
        : ""
      : ""
  );
  /*Get data regarding current logged in user from redux*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const currentUserFirstName = useSelector((state) =>
    state.user.user ? state.user.user.user.firstName : ""
  );

  const currentUserLastName = useSelector((state) =>
    state.user.user ? state.user.user.user.lastName : ""
  );

  const managing = source === "Roles" ? clientRoles : ownRoles;

  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const roleUsersPermission = useSelector((state) =>
    state.user.user
      ? (source === "My Roles" && !fromCompany) ||
        (fromCompany && companyID === compID)
        ? state.user.user.user.role.ownUsers
        : state.user.user.user.role.clientUsers
      : "N"
  );

  /*Role Form Ref*/
  const roleRef = useRef(null);

  /*User Form Ref*/
  const userRef = useRef(null);

  useEffect(() => {
    //for help button
    setPageLink("Roles");

    //for drawer menu
    if (fromCompany) {
      if (companyID === compID) {
        setPageID(-1);
      } else {
        setPageID(6);
      }
    } else {
      if (companyID === compID) {
        setPageID(2);
      } else {
        setPageID(8);
      }
    }
  }, []);

  const handleSnackBarClosePreviousPage = (event, reason) => {
    setPreviousPageSnackbar("");
  };

  const activateUser = () => {
    //function to activate user(s)
    setButtonLoading(true);
    //if user inactive, we are reactivating him, else we are activating him
    const data = {
      AdminID: currentUserID,
      Users: [
        { Email: username, CompanyID: companyID === 0 ? compID : companyID },
      ],
      ReactivateUser: userStatus === "I" ? true : false,
      ResendActivationEmail: userStatus === "P" ? true : false,
    };
    adminServices
      .activateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUserStatus(userStatus === "I" ? "A" : "P");
        setMessage(
          userStatus === "P"
            ? "Activation Email Sent Successfully"
            : "User Activated Successfully"
        );
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };
  const deactivateUser = () => {
    setButtonLoading(true);
    //function to deactivate user(s)
    const data = {
      AdminID: currentUserID,

      Users: [
        { Email: username, CompanyID: companyID === 0 ? compID : companyID },
      ],
    };
    adminServices
      .deactivateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setButtonLoading(false);
        setUserStatus("I");
        setMessage("User Deactivated Successfully");
        setOpenSnackBar(true);
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    //for setting the tab on first load
    if (!tab) {
      setValue(0);
    } else {
      setValue(tab);
    }
  }, []);

  useEffect(() => {
    //for permissions in form
    if (clientRoles === "M") {
      setAdminManage(true);
    }
    if (ownRoles === "M") {
      setCompanyAdmin(true);
    }
    if (clientUsers === "M" || ownUsers === "M") {
      setAdminManageForUsers(true);
    }
  }, [source, value]);

  useEffect(() => {
    if (catchedID !== "") {
      accountServices
        .getUsersByRoleID(catchedID)
        .then((response) => {
          setRoleUsers(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    }
  }, [catchedID]);

  const deleteRole = () => {
    //we delete selected role by providing it's ID's
    setButtonLoading(true);
    setDeleteRecord(false);
    rolesServices
      .deleteRoles({ Roles: [roleID] })
      .then((response) => {
        setButtonLoading(false);
        history.push({
          pathname: linkSource,
          state: {
            companyID: companyID,
            companyName: companyName,
            myCompany: companyID === compID,
            source: "Companies",
            tab: 3,
            previousPageSnackbar: "Role Deleted successfully",
          },
        });
      })
      .catch((error) => {
        setCatchedID(error.response.data.error.error.split(":")[1].trim());
        setButtonLoading(false);
      });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //to handle change page
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
      setCreateUserRole(false);


    } else {
      setValue(newValue);
      setCreateUserRole(false);
    }
  };

  const handleConfirm = () => {
    sessionStorage.removeItem("unsavedChanges");

    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
  };

  const handleClose = () => {

    setOpenDialog(false);
  };

  const backToMain = () => {
    //return to first tab
    if (formChanged) {
      handleConfirm()
        setValue(0);

        setFormChanged(false);
      
    } else {
      setValue(0);
    }
  };

  const toCompanies = () => {
    //link to go to company page relative to which company page we came from
    if (roleSourceLink === "/Companies") {
      history.push({
        pathname: roleSourceLink,
      });
    } else {
      history.push({
        pathname: roleSourceLink,
        state: {
          source: currentUserFirstName + " " + currentUserLastName,
          companyID: companyID,
          companyName: roleSourceName,
          myCompany: true,
        },
      });
    }
  };

  const toCompanyInformation = () => {
    //go to company information page we came from
    history.push({
      pathname: "/company-information",
      state: {
        path: "company-information",
        companyID: companyID,
        companyName: roleSourceName,
        source: roleSource,
      },
    });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  

  /*Add a new role*/
  const createRole = (companyID, myRoles) => {
 
    history.push({
      pathname: "/create-role",
      state: {
        companyID: companyID,
        source: myRoles,
      },
    });
  };

  useEffect(() => {
    if (createChangeUser) {
      setUserFullName("");
      setUsername("");
    }
  }, [createChangeUser]);

  useEffect(() => {
    setUserFullName("");
    setUsername("");
  }, [value]);

  const handleCancel = async () => {
    //to handle cancel button click
    if (value === 0) {
      await roleRef.current.setPristine();
      if (fromCompany === true) {
        if (dataFromUrl !== null && dataFromUrl !== undefined)
          history.push({
            pathname: "/company-information",
            state: {
              ...dataFromUrl,
              previousPageSnackbar: "",
              tab: fromCompany ? 3 : 0,
            },
          });
        else if (locationToReceive !== null) {
          history.push({
            pathname: "/company-information",
            state: {
              ...locationToReceive,
              previousPageSnackbar: "",
              tab: fromCompany ? 3 : 0,
            },
          });
        } else {
          history.goBack();
        }
      } else if (source === "My Roles") history.push("/my-roles");
      else history.push("/roles");
    } else if (value === 1) {
      await userRef.current.setPristine();
      setCreateUserRole(false);
      setFormChanged(false);
      sessionStorage.removeItem("unsavedChanges");
      setUserFullName("");
    }
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveError("");
  };

  
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={ handleConfirm}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {saveError !== "" ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
      {previousPageSnackbar ? (
        <SnackBar
          open={previousPageSnackbar !== ""}
          message={previousPageSnackbar}
          severity="success"
          handleClose={handleSnackBarClosePreviousPage}
        />
      ) : null}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message={message !== "" ? message : "Saved Successfully"}
          severity="success"
          handleClose={handleSnackBarClose}
          setOpenSnackBar={setOpenSnackBar}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteRole}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {catchedID !== "" && roleUsers[0] ? (
        <CustomModal openModal={catchedID !== ""}>
          <Typography variant="h6" component="span" align="left">
            {" "}
            Error
          </Typography>
          <br />
          <br />
          <Typography variant="body1" component="span" align="left">
            {"The role '" +
              roleUsers[0].user.role.description +
              "' could not be deleted because the following user(s) are assigned to it:"}
          </Typography>
          <br />
          {roleUsers.map((user) => (
            <>
              <Typography variant="body1" component="span" align="left">
                - {user.user.userName}
              </Typography>
              <br />
            </>
          ))}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton startIcon={<X />} toggle={() => setCatchedID("")}>
              Cancel
            </CustomButton>
          </div>
        </CustomModal>
      ) : null}
      <Paper>
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {fromCompany ? (
                <Link
                  underline="always"
                  key="1"
                  color="inherit"
                  onClick={toCompanies}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {roleSource}
                </Link>
              ) : (
                ""
              )}
              {fromCompany ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={toCompanyInformation}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {roleSourceName}
                </Link>
              ) : (
                ""
              )}
              <Link
                underline="always"
                key="3"
                color="inherit"
                onClick={() =>
                  history.push({
                    pathname: linkSource,
                    state: {
                      path: "company-information",
                      companyID: companyID,
                      companyName: roleSourceName,
                      source: roleSource,
                      self: companyID === compID ? true : false,
                      myCompany: companyID === compID ? true : false,
                      tab: 3,
                    },
                  })
                }
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                {source}
              </Link>
              ,
              {value === 1 ? (
                <Link
                  underline="always"
                  key="4"
                  color="inherit"
                  onClick={backToMain}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {roleName}
                </Link>
              ) : (
                <Typography
                  key="4"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {roleName}
                </Typography>
              )}
              {value === 1 && createUserRole === false ? (
                <Typography
                  key="5"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Users
                </Typography>
              ) : value === 1 && createUserRole === true ? (
                <Link
                  underline="always"
                  key="4"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();
                      setCreateUserRole(false);
                      setUsername("");
                      setUserFullName("");
                      setFormChanged(false);

                    } else {
                      setValue(0)
                      setCreateUserRole(false);
                      setUserFullName("");
                      setUsername("");
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Users
                </Link>
              ) : (
                ""
              )}
              {createUserRole === true && value === 1 ? (
                <Typography
                  key="6"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {!createChangeUser ? userFullName : "New User"}
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {managing === "M" &&
                selfRole === false &&
                (systemGenerated === false ||
                  (systemGenerated === true && compID !== companyID)) ? (
                <>
                  {value === 0 ? (
                    <>
                      <CustomButton
                        startIcon={<Plus />}
                        toggle={() =>
                          
                          createRole(
                            source === "Roles" ? 0 : companyID,
                            source === "Roles" ? false : true
                          )
                        }
                      >
                        New Role
                      </CustomButton>
                      <div style={{ width: "10px" }} />
                    </>
                  ) : null}
                  {value === 0 || (value === 1 && createUserRole) ? (
                    <CustomButton
                      startIcon={<Save />}
                      toggle={() =>
                        value === 1
                          ? userRef.current.checkUserNotifInvoices()
                          : roleRef.current.save()
                      }
                      loading={tabButtonLoading}
                    >
                      Save
                    </CustomButton>
                  ) : null}

                  {value === 0 ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Trash2 />}
                        toggle={() => setDeleteRecord(true)}
                        loading={buttonLoading}
                      >
                        Delete
                      </CustomButton>
                    </>
                  ) : null}

                  {value === 1 && adminManage && username !== "" ? (
                    <>
                      <div style={{ width: "10px" }} />
                      {userStatus === "A" ? (
                        <div>
                          <CustomButton
                            startIcon={<UserX />}
                            toggle={deactivateUser}
                            loading={buttonLoading}
                          >
                            Deactivate
                          </CustomButton>
                        </div>
                      ) : userStatus === "I" ||
                        userStatus === "N" ||
                        userStatus === "P" ? (
                        <div>
                          <CustomButton
                            startIcon={
                              userStatus === "P" ? (
                                <RefreshCcw />
                              ) : (
                                <UserCheck />
                              )
                            }
                            toggle={activateUser}
                            loading={buttonLoading}
                          >
                            {userStatus === "P"
                              ? "Resend Activation Email"
                              : "Activate"}
                          </CustomButton>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : (
                ""
              )}
              {value === 0 || (value === 1 && createUserRole) ? (
                <>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<X />}
                    toggle={async () => {
                      handleCancel();
                    }}
                  >
                    Cancel
                  </CustomButton>
                </>
              ) : null}
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Role" {...a11yProps(0)} style={{ margin: "50" }} />
              {roleUsersPermission ? (
                <Tab label="Users" {...a11yProps(1)} style={{ margin: "50" }} />
              ) : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <RoleForm
              receivedRoleID={roleID}
              adminManage={adminManage}
              companyAdmin={companyAdmin}
              receivedCompanyID={companyID}
              systemGenerated={systemGenerated}
              setOpenSnackBar={setOpenSnackBar}
              setTabButtonLoading={setTabButtonLoading}
              setRoleName={setRoleName}
              setMessage={setMessage}
              ref={roleRef}
              setFormChanged={setFormChanged}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!createUserRole ? (
              <UsersTableComponent
                companyID={companyID}
                rolesTable={true}
                titleText={titleText}
                roleID={roleID}
                fromCompanyRole={fromCompany}
                setCreateUserRole={setCreateUserRole}
                roleName={roleName}
                userRoleID={roleID}
                userRoleName={roleName}
                userCompanyName={companyName}
                locationToBeSent={fromURL ? decryptedObject : location?.state}
                userSystemGenerated={systemGenerated}
                setCreateChangeRole={setCreateChangeUser}
                myCompany={companyID === compID}
                dataObject={dataObject ? dataObject : location.state}
              />
            ) : (
              <>
                <UserForm
                  create={createChangeUser}
                  setCreateChangeUser={setCreateChangeUser}
                  createUser={createUserRole}
                  receivedCompanyID={companyID}
                  receivedRoleID={roleID}
                  receivedUserID={receivedUserID}
                  setReceivedUserID={setReceivedUserID}
                  setOpenSnackBar={setOpenSnackBar}
                  setTabButtonLoading={setButtonLoading}
                  setUserFullname={setUserFullName}
                  setUsernameForTabs={setUsername}
                  adminManage={adminManageForUsers}
                  setMessage={setMessage}
                  userStatus={userStatus.charAt(0)}
                  setUserStatus={setUserStatus}
                  ref={userRef}
                  setFormChanged={setFormChanged}
                />
              </>
            )}
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
