import { agreementTypes } from "../types/agreementTypes";

const initialState = {};

/*This is the user reducer where all user states are modified */

const agreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case agreementTypes.REDIRECT_TO_AGREEMENT_URL:
      return {
        ...state,
        ToAgreementUrlPage: action.payload.ToAgreementUrlPage,
        AgreementUrlPageID: action.payload.AgreementUrlPageID,
      };
    case agreementTypes.RESET_TO_AGREEMENT_URL:
      return {
        ...state,
        ToAgreementUrlPage: action.payload.ToAgreementUrlPage,
        AgreementUrlPageID: action.payload.AgreementUrlPageID,
      };
    default:
      return { ...state };
  }
};

export default agreementReducer;
