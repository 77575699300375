import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Move } from "react-feather";
import { ItemTypes } from "./ItemTypes";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import SixDotsIcon from "../../assets/drag-handle-dots-2.svg";

const style = {
  display: "flex",
  borderBottom: "1px solid #e0e0e0",
  padding: "10px 0",
  backgroundColor: "white",
  cursor: "move",
};

const CustomDropDown = ({ value, setValue, disabled = false }) => {
  return (
    <FormControl size="small">
      <InputLabel id="sort-direction-select-label">Direction</InputLabel>
      <Select
        labelId="sort-direction-select-label"
        id="sort-direction-select"
        value={value}
        disabled={disabled}
        sx={{ display: disabled ? "none" : "" }}
        label="Direction"
        onChange={(e) => setValue(e.target.value)}
      >
        <MenuItem value={"asc"}>Ascending</MenuItem>
        <MenuItem value={"desc"}>Descending</MenuItem>
        <MenuItem value={""} sx={{ display: "none" }}></MenuItem>
      </Select>
    </FormControl>
  );
};

export const Card = ({
  id,
  description,
  index,
  moveCard,
  card,
  direction,
  setDirection,
  listRef,
  sorting,
  setSorting,
}) => {
  const [changed, setChanged] = React.useState(false);

  const ref = useRef(null);
  var scrollTop = 0;

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ ...style, opacity, width: "100%", height: "60px" }}
      data-handler-id={handlerId}
    >
      <Grid container>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "15px",
          }}
        >
          <img src={SixDotsIcon} alt="sort-icon" style={{ width: "30px" }} />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography component="span" variant="body">
            {description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            onClick={() => {
              card.visibility = !card.visibility;
              setChanged((changed) => !changed);
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            {card.visibility === false ? (
              <CheckBoxOutlineBlankIcon sx={{ color: "#565759" }} />
            ) : (
              <CheckBoxIcon sx={{ color: "#0077c6" }} />
            )}
          </span>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            onClick={() => {
              setSorting(id);
            }}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            {sorting !== id ? (
              <CheckBoxOutlineBlankIcon sx={{ color: "#565759" }} />
            ) : (
              <CheckBoxIcon sx={{ color: "#0077c6" }} />
            )}
          </span>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomDropDown
            value={sorting === id ? direction : ""}
            setValue={setDirection}
            disabled={sorting !== id}
          />
        </Grid>
      </Grid>
    </div>
  );
};
