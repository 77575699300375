import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, Grid } from "@mui/material";
import CustomAlert from "../../CustomAlert/CustomAlert";
import OrderProducts from "../../OrderProducts/OrderProducts";
import CustomButton from "../../CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import { Save, Send, Trash2, X } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divStyle2: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
  buttonStyle2: {
    display: "flex",
    textAlign: "right",
    flexDirection: "row",
    justifyContent: "flex-end",
    overflow: "auto",

    "@media(max-width: 1000px)": {
      display: "block",
    },
  },
}));

export default function ProductQuantitiesAccordion({
  rows,
  setRows,
  isCompPP,
  steps,
  pageLoad = true,
  setPristine,
  error = "",
  totalSwitches,
  defaultPriceRanges,
  rolePermission,
  orderStatusInTabs,
  totalWallPlates,
  priceResetLoading,
  setPriceResetLoading,
  defUPC,
  setGetError,
  totalOrderPrice,
  openedAccordion,
  setOpenedAccordion,
  setTotalOrderPrice,
  setTotalSwitches,
  orderType,
  maxDimmer = 0,
  defaultController,
  getPriceByRange,
  setChangeMade,
  specialNegotiated,
  setTotalWallPlates,
  changeMade,
  totalQuickMount,
  setTotalQuickMount,
  isLevvenAdmin,
  currency,
  setFormChanged,
  opened = [],
  setOpened,
  setDirty,
  orderID,
  getError,
  orderVersion,
}) {
  const classes = useStyles();

  const [changesMade, setChangesMade] = React.useState(false);

  const history = useHistory();

  var refInput = React.useRef(null);
  var refTextField = React.useRef(null);

  React.useEffect(() => {
    setOpenedAccordion(openedAccordion);
  }, [changesMade]);

  const handleAccordionOpen = (index) => {
    if (openedAccordion.includes(index)) {
      var id = openedAccordion.indexOf(index);
      openedAccordion.splice(id, 1);
    } else {
      setOpenedAccordion((prevOpenedAccordion) => [
        ...prevOpenedAccordion,
        index,
      ]);
    }
  };

  React.useEffect(() => {
    if (
      refInput.current !== undefined &&
      refInput.current !== null &&
      refTextField.current !== null &&
      refTextField.current !== undefined
    ) {
      refTextField.current.setSelectionRange(
        refInput.current,
        refInput.current
      );
    }
  }, [rows]);

  return pageLoad === true ? (
    <div style={{ textAlign: "center" }}>
      <CircularProgress sx={{ color: "#0077c6" }} />
    </div>
  ) : error ? (
    <div
      style={{
        textAlign: "center",
        color: "red",
        marginTop: "50px",
        marginBottom: "30px",
      }}
    ></div>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={11} md={12} lg={12}>
          <div>
            {steps.map((step, index) => {
              return (
                <Accordion
                  key={step + "-" + index}
                  elevation={0}
                  style={
                    {
                      // borderTopLeftRadius: index === 0 ? "8px" : 0,
                      // borderTopRightRadius: index === 0 ? "8px" : 0,
                      // borderBottomLeftRadius:
                      //   index === rows.length - 1 ? "8px" : 0,
                      // borderBottomRightRadius:
                      //   index === rows.length - 1 ? "8px" : 0,
                      // borderTop: index % 2 === 0 ? "1px solid #c4c4c4" : "",
                      // borderRight: "1px solid #c4c4c4",
                      // borderLeft: "1px solid #c4c4c4",
                      // borderBottom:
                      //   index === rows.length - 1 || index % 2 === 0
                      //     ? "1px solid #c4c4c4"
                      //     : "",
                      //backgroundColor: index % 2 !== 0 ? "#cde2f5" : "#fff",
                    }
                  }
                  expanded={openedAccordion.includes(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={step + "-content"}
                    id={step + "-id"}
                    onClick={async () => {
                      await handleAccordionOpen(index);
                      setChangesMade(!changesMade);
                    }}
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <Typography style={{ paddingLeft: "40px" }}>
                      {step}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      marginTop: "-10px",
                      marginBottom: "-10px",
                      marginLeft: "-16px",
                      marginRight: "-16px",
                    }}
                  >
                    <OrderProducts
                      isCompPP={isCompPP}
                      isLevvenAdmin={isLevvenAdmin}
                      rows={rows[index]}
                      allRows={rows}
                      activeStep={index}
                      titleText={step}
                      orders={true}
                      defaultController={defaultController}
                      getPriceByRange={getPriceByRange}
                      totalSwitches={totalSwitches}
                      defUPC={defUPC}
                      isAssembly={
                        rows[index][0]
                          ? rows[index][0].product.category.categoryCode === 1
                            ? true
                            : false
                          : false
                      }
                      isSwitch={
                        rows[index][0]
                          ? rows[index][0].product.category.categoryCode === 3
                            ? true
                            : false
                          : false
                      }
                      setTotalSwitchesOrder={setTotalSwitches}
                      defaultPriceRanges={defaultPriceRanges}
                      currency={currency}
                      refInput={refInput}
                      setChangeMade={setChangeMade}
                      totalOrderPrice={totalOrderPrice}
                      setTotalOrderPrice={setTotalOrderPrice}
                      setGetError={setGetError}
                      specialNegotiated={specialNegotiated}
                      setDirty={setDirty}
                      orderStatusInTabs={orderStatusInTabs}
                      changeMade={changeMade}
                      totalWallPlates={totalWallPlates}
                      setTotalWallPlatesOrder={setTotalWallPlates}
                      orderType={orderType}
                      totalQuickMount={totalQuickMount}
                      refTextField={refTextField}
                      rolePermission={rolePermission}
                      setTotalQuickMount={setTotalQuickMount}
                      setFormChanged={setFormChanged}
                      maxDimmer={maxDimmer}
                      setOpenedAccordion={setOpenedAccordion}
                      priceResetLoading={priceResetLoading}
                      setPriceResetLoading={setPriceResetLoading}
                      openedAccordion={openedAccordion}
                      orderID={orderID}
                      opened={opened}
                      setOpened={setOpened}
                      setRows={setRows}
                      // orderVersion={orderVrsion}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
