import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { visuallyHidden } from "@mui/utils";
import { Save, Layers, Copy, X } from "react-feather";
import { Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { CircularProgress, TableSortLabel } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { takeoffWizardServices } from "../../services/takeoffWizard.service";
import SnackBar from "../SnackBar/SnackBar";
import TakeoffWizardStepsTable from "../TakeoffWizardStepsTable/TakeoffWizardStepsTable";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

const headCells = [
  {
    id: "StepSequence",
    numeric: true,
    disablePadding: false,
    label: "Step Number",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    rolePermission,
    changeSort,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && rowCount > 0 && changeSort === false ? (
            <Checkbox
              style={{ marginRight: "70px" }}
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : null}
        </TableCell>
        {headCells.map((headCell) =>
          !changeSort && headCell.id !== "title" ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: "white",
                whiteSpace: "nowrap",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: "white",
                whiteSpace: "nowrap",
              }}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function TakeoffWizardTable({ setPageLink, setPageID }) {
  //get data from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoffWiz : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  //used to send data to next page
  const history = useHistory();

  //used ot receive data sent from previous page
  const location = useLocation();

  //redirection
  if (!(rolePermission !== "N" && companyType === "L")) {
    history.push("/Dashboard");
  }

  //for sorting
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("");

  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //holds the page in the table
  const [page, setPage] = React.useState(0);

  //holds the number of rows per page to display
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds the rows in the table
  var [rows, setRows] = React.useState([]);

  //holds the error if any while fetching the data from the database
  const [error, setError] = React.useState("");

  //holds the number of selected values in the table
  var numSelected = selected.length;

  //holds the total number of records that match the filter in the database
  const [numberRecords, setNumberRecords] = React.useState(0);

  //makes delete button loading while delete is still in progress
  const [loading, setLoading] = React.useState(false);

  //refetches data from the database
  const [resubmit, setResubmit] = React.useState(false);

  //makes page load while fetching data from the database
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds the error if any while deleting the records
  const [deleteError, setDeleteError] = React.useState("");

  //displays the alert dialog before deleting
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //used for moved rows while editing them
  const [moved, setMoved] = React.useState([]);

  //makes the table row sequence editable
  const [changeSort, setChangeSort] = React.useState(false);

  //displays snackbar
  const [success, setSuccess] = React.useState(false);

  //holds the snackbar message
  const [message, setMessage] = React.useState("");

  const [allRows, setAllRows] = React.useState([]);

  const [takeoffwizardSuccess, setTakeoffwizardSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuDataIndex, setContextMenuDataIndex] = React.useState(null);

  useEffect(() => {
    //for help button

    setPageLink("TakeoffWizard");

    //for drawer menu
    setPageID(10);
  }, []);

  useEffect(() => {
    //we get the data from the database

    setSelected([]);

    if (takeoffwizardSuccess === true) {
      setSuccess(true);
      setMessage("Record(s) Deleted Successfully");
      setTakeoffwizardSuccess(false);
    }
    setPageLoad(true);
    window.scrollTo(0, 0);
    setRows([]);
    setAllRows([]);
    setError("");
    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
      orderDirection: orderDirection,
      orderColumn: orderColumn,
    };

    takeoffWizardServices
      .getTakeoffWizardList(data)
      .then((response) => {
        setNumberRecords(response.data.totalNumberOfRecords);

        setPageLoad(false);

        setAllRows(response.data.allTakeoffList);

        setRows(response.data.takeoffWizardList);
      })
      .catch((error) => {
        setPageLoad(false);
        setError(error.response.data.error.error);
      });
  }, [page, rowsPerPage, resubmit]);

  const handleRequestSort = (property) => {
    //for sorting
    setPageLoad(true);
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //function for select all
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.stepID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, stepID) => {
    const selectedIndex = selected.indexOf(stepID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, stepID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //check if row is selected
  const isSelected = (stepID) => selected.indexOf(stepID) !== -1;

  const cancelEdit = () => {
    //function to clear sorting changes
    setMoved([]);
    setChangeSort(false);
  };

  const deleteValues = () => {
    //function to delete record
    const data = { TakeoffWizardList: selected };
    setDeleteRecord(false);
    setLoading(true);
    takeoffWizardServices
      .deleteTakeoffWizard(data)
      .then(() => {
        setLoading(false);
        setResubmit(!resubmit);
        setSuccess(true);
        setSelected([]);
        setMessage("Record(s) Deleted Successfully");
      })
      .catch((error) => {
        setLoading(false);
        setSelected([]);
        setDeleteError(error.response.data.error.error);
      });
  };

  const changeOrderSequence = () => {
    //function to enable change sort
    if (changeSort === true) {
      //setRows(moved);
      setPageLoad(true);
      setRows([]);

      takeoffWizardServices
        .UpdateTakeoffWizardStepSequence(moved)
        .then(() => {
          setResubmit(!resubmit);
          setSuccess(true);
          setMessage("Saved Successfully");
        })
        .catch((error) => {
          setDeleteError(error.response.data.error.error);
        });
      setChangeSort(false);
    } else {
      setMoved(allRows);
      setChangeSort(true);
    }
  };

  const createTakeoff = () => {
    //function to go to form to create take off wizard
    history.push({
      pathname: "/create-takeoff-wizard",
      state: {
        create: true,
      },
    });
  };

  const viewTakeoffData = (stepID, name) => {
    //function to go view information about takeoff wizard step
    history.push({
      pathname: "/takeoff-wizard-information",
      state: {
        takeoffID: stepID,
        takeoffDescription: "Step " + name,
        create: false,
      },
    });
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      takeoffID: contextMenuData.stepID,
      takeoffDescription:
        "Step " +
        (orderDirection === "desc"
          ? allRows.length - (rowsPerPage * page + contextMenuDataIndex + 1)
          : rowsPerPage * page + contextMenuDataIndex + 1),
      create: false,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/takeoff-wizard-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />

      {deleteRecord ? (
        <AlertDialogSlide
          title="Takeoff Wizard Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteValues}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}

      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Takeoff Setup
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                {numSelected === 1 ? (
                  <CustomButton
                    startIcon={<Copy />}
                    size="medium"
                    tableButton={true}
                    toggle={() =>
                      history.push({
                        pathname: "/create-takeoff-wizard",
                        state: {
                          id: selected[0],
                          duplicate: true,
                        },
                      })
                    }
                  >
                    Duplicate
                  </CustomButton>
                ) : null}
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Trash2 />}
                    size="medium"
                    tableButton={true}
                    loading={loading}
                    toggle={() => setDeleteRecord(true)}
                  >
                    Delete
                  </CustomButton>
                ) : null}
              </>
            ) : (
              <>
                {rolePermission === "M" ? (
                  <CustomButton
                    size="medium"
                    tableButton={true}
                    toggle={changeOrderSequence}
                    extraMarginTop={"10px"}
                    extraMarginBottom={"10px"}
                    sx={{
                      whiteSpace: "normal",
                      height: "70px",
                    }}
                    startIcon={changeSort ? <Save /> : <Layers />}
                  >
                    {changeSort ? "Save Sequence Order" : "Edit Sequence Order"}
                  </CustomButton>
                ) : null}

                {changeSort ? (
                  <CustomButton
                    startIcon={<X />}
                    size="medium"
                    tableButton={true}
                    toggle={cancelEdit}
                  >
                    Cancel
                  </CustomButton>
                ) : null}
                {changeSort ? null : rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                      toggle={createTakeoff}
                    >
                      New Step
                    </CustomButton>
                  </>
                ) : null}
              </>
            )}
          </Toolbar>

          {changeSort ? (
            <TakeoffWizardStepsTable steps={moved} setSteps={setMoved} />
          ) : (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={orderDirection}
                    orderBy={orderColumn}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={numberRecords}
                    rolePermission={rolePermission}
                  />
                  <TableBody
                    onContextMenu={
                      pageLoad === false && numberRecords > 0
                        ? handleContextMenu
                        : () => {
                            return null;
                          }
                    }
                  >
                    {pageLoad === true ? (
                      <TableRow>
                        <TableCell colSpan="7" style={{ textAlign: "center" }}>
                          <CircularProgress sx={{ color: "#0077c6" }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {numberRecords === 0 && pageLoad === false ? (
                      <TableRow>
                        <TableCell
                          colSpan="4"
                          style={{ color: "grey", textAlign: "center" }}
                        >
                          It seems there are is data to display.
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows.map((row, index) => {
                        const isItemSelected = isSelected(row.stepID);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.stepID}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              padding="checkbox"
                              onContextMenu={() => {
                                setContextMenuData(row);
                                setContextMenuDataIndex(index);
                              }}
                            >
                              {rolePermission === "M" &&
                              changeSort === false ? (
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, row.stepID)
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon sx={{ color: "#badaf1" }} />
                                  }
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              onContextMenu={() => {
                                setContextMenuData(row);
                                setContextMenuDataIndex(index);
                              }}
                              align="center"
                              onClick={() =>
                                viewTakeoffData(
                                  row.stepID,
                                  orderDirection === "desc"
                                    ? allRows.length -
                                        (rowsPerPage * page + index + 1)
                                    : rowsPerPage * page + index + 1
                                )
                              }
                            >
                              {orderDirection === "desc"
                                ? allRows.length -
                                  (rowsPerPage * page + index + 1)
                                : rowsPerPage * page + index + 1}
                            </TableCell>
                            <TableCell
                              onContextMenu={() => {
                                setContextMenuData(row);
                                setContextMenuDataIndex(index);
                              }}
                              size="small"
                              onClick={() =>
                                viewTakeoffData(
                                  row.stepID,
                                  orderDirection === "desc"
                                    ? allRows.length -
                                        (rowsPerPage * page + index + 1)
                                    : rowsPerPage * page + index + 1
                                )
                              }
                            >
                              {row.title}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                    <>
                      <ContextMenu
                        contextMenu={contextMenu}
                        handleClose={handleCloseContextMenu}
                        openInNewTab={openInNewTab}
                      />
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 200]}
                component="div"
                count={numberRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>
    </>
  );
}
