import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/country";

const getCountries = () => {
  return fetchWrapper.get(baseUrl + "/GetAllCountries");
};

const deleteCountries = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteCountries", data);
};

const getStateProvince = (data) => {
  return fetchWrapper.get(baseUrl + "/GetCountryStatesAndProvinces/" + data);
};

const getStateProvinceList = () => {
  return fetchWrapper.get(baseUrl + "/GetAllStatesAndProvinces");
};

const getStateProvinceFilteredList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetStateProvinceFilters", data);
};

const createStateProvince = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateStateProvince", data);
};
const createCountry = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateCountry", data);
};

const deleteStateProvince = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteStateProvince", data);
};

const getCountryByID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetCountryById/" + data);
};

const updateCountryByID = (id, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateCountry/" + id, data);
};

export const countryServices = {
  getCountries,
  deleteCountries,
  getStateProvince,
  getStateProvinceFilteredList,
  getStateProvinceList,
  createStateProvince,
  deleteStateProvince,
  getCountryByID,
  updateCountryByID,
  createCountry,
};
