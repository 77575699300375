import React, { useState, useEffect } from "react";
import { Alert, Grid, TextField, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import logo from "../../assets/Levven_logo.png";
import { accountServices } from "../../services/account.services";
import validator from "validator";
import { Link } from "react-router-dom";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import CustomAlertSuccess from "../../components/CustomAlertSuccess/CustomAlertSuccess";
import { useReCaptcha } from "../../hooks/useReCaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import ReCaptchaText from "../../reCaptcha";

const ForgotPasswordPage = () => {
  /*Local variables*/
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [sentEmailError, setSentEmailError] = useState("");
  const [notValidEmail, setNotValidEmail] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  var _reCaptchaRef = React.createRef();

  //For ReCaptcha
  const [
    siteKey,
    v2SiteKey,
    v2Token,
    reCaptchaError,
    setReCaptchaError,
    checkReCaptchaV2,
    setCheckRecaptchaV2,
    checkReCaptchaV3Token,
    handleReCaptchaV2Change,
    checkReCaptchaV2Token,
  ] = useReCaptcha();

  /*Functions*/
  /*Send the reset link*/
  const getResetLink = async (event) => {
    event.preventDefault();
    if (email === "") setEmailError(true);
    else if (!validator.isEmail(email)) setNotValidEmail(true);
    else if (checkReCaptchaV2 === false) {
      setLoading(true);

      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(siteKey, { action: "login" })
          .then(async function (token) {
            setLoading(true);

            var result = await checkReCaptchaV3Token(token);

            if (result === true) {
              accountServices
                .forgotPassword({ email: email })
                .then(() => {
                  setSentEmailError("");
                  setSuccessMessage(true);
                  setLoading(false);
                })
                .catch((error) => {
                  setSentEmailError(error.response.data.message);
                  setSuccessMessage(false);
                  setLoading(false);
                });
            } else {
              setLoading(false);
              setCheckRecaptchaV2(true);
            }
          });
      });
    } else if (checkReCaptchaV2 === true && v2Token !== "") {
      setLoading(true);
      var result = await checkReCaptchaV2Token();

      if (result === true) {
        accountServices
          .forgotPassword({ email: email })
          .then(() => {
            setSentEmailError("");
            setSuccessMessage(true);
            setLoading(false);
          })
          .catch((error) => {
            setSentEmailError(error.response.data.message);
            setSuccessMessage(false);
            setLoading(false);
          });
      } else {
        setLoading(false);
        window.grecaptcha.reset();
      }
    }
  };

  //to change title of page
  useEffect(() => {
    document.title = "Levven Electronics - Customer Portal - Forgot Password";
  }, []);

  /*Render function*/
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="100vh"
    >
      <CustomPaper>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: "20px" }}
        >
          <img
            src={logo}
            alt="Levven Logo"
            style={{
              objectFit: "contain",
              width: "50%",
            }}
          />
        </Grid>
        <form onSubmit={getResetLink}>
          <Grid container alignSelf="center" spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography align="left" variant="h6" color="#303030">
                Recover Password
              </Typography>
              <Typography align="left" mt={1} variant="body2" color="#303030">
                An email will be sent with a link to reset your password.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                variant="outlined"
                label="Email Address"
                fullWidth
                size="medium"
                error={emailError || notValidEmail}
                helperText={
                  emailError
                    ? "Please enter an email"
                    : notValidEmail
                    ? "Email format not valid"
                    : null
                }
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (emailError) setEmailError(false);
                  if (validator.isEmail(email)) setNotValidEmail(false);
                }}
                required
              />{" "}
              <ReCaptchaText />
            </Grid>
            {checkReCaptchaV2 === true ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReCAPTCHA
                  type="image"
                  theme="light"
                  ref={_reCaptchaRef}
                  sitekey={v2SiteKey}
                  onChange={handleReCaptchaV2Change}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomButton fullWidth type="submit" loading={loading}>
                Get Reset Link
              </CustomButton>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Typography
                  variant="subtitle2"
                  mt={3}
                  align="center"
                  color={loading ? "rgba(0, 0, 0, 0.12)" : "#0077c6"}
                  disabled={loading ? true : false}
                >
                  Login
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {reCaptchaError ? (
                <CustomAlert>{reCaptchaError}</CustomAlert>
              ) : sentEmailError !== "" ? (
                <CustomAlert>{sentEmailError}</CustomAlert>
              ) : successMessage ? (
                <CustomAlertSuccess>
                  We will attempt to send a reset link. Check your email.
                </CustomAlertSuccess>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </CustomPaper>
    </Grid>
  );
};

export default ForgotPasswordPage;
