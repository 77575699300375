import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { productpricesServices } from "../../services/productprice.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

//function that sorts (compares) the data
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

//function that handles if we sort in ascending or descending order
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
    center: false,
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    center: false,
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
    center: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, rolesTable } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        {headCells.map((headCell) =>
          rolesTable &&
          (headCell.label === "Company" || headCell.label === "Role") ? (
            ""
          ) : (
            <TableCell
              key={headCell.id}
              align={
                headCell.numeric ? "right" : headCell.center ? "center" : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: "white", backgroundColor: "#0077c6" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function PricesTableComponent({
  productID,
  titleText = "Prices",
}) {
  //for sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //holds page in table
  const [page, setPage] = React.useState(0);

  //holds rows per page of table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //makes page load until we receive all data from database
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds all data to be displayed in rows
  const [rows, setRows] = React.useState([]);

  //holds the number of selected values
  var numSelected = selected.length;

  //holds the total number of records (without pagination)
  const [numberRecords, setNumberRecords] = React.useState(0);

  //holds error message if any when fetching data from database
  const [error, setError] = React.useState("");

  const getDataFromDatabase = () => {
    //function to get data from the database
    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
      productID: productID,
    };

    productpricesServices
      .getProductPrices(data)
      .then((response) => {
        setNumberRecords(response.data?.totalNumberOfRecords);

        response?.data?.productPrice.forEach((price) => {
          return setRows((rows) => [
            ...rows,
            {
              id: price.priceID,
              country: price.country.description,
              price: price.unitPrice,
              currency: price.country.cur,
            },
          ]);
        });
        setPageLoad(false);
      })
      .catch((error) => {
        setError(error.response?.data?.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    setRows([]);
    window.scrollTo(0, 0);
    setPageLoad(true);
    setError("");

    getDataFromDatabase();
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    //for sorting the data in table
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    //select all data in the table page
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    //for changing the page in table pagination
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //for changing rows per page in table
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //check if row is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page >= 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  return (
    <>
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {titleText}
              </Typography>
            )}
          </Toolbar>

          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolesTable={false}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="12"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no Prices to display.
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell>{row.country}</TableCell>

                          <TableCell>
                            {parseFloat(row.price).toFixed(2)}
                          </TableCell>

                          <TableCell>{row.currency}</TableCell>
                        </TableRow>
                      );
                    }
                  )
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
