import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar({ open, message, severity, handleClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      onClick={handleClose}
      sx={{ height: "100%", width: "50%" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{
          width: "100%",
          height: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        style={{ backgroundColor: "#1D944B", color: "#fff" }}
      >
        <p style={{ fontSize: "120%" }}>{message}</p>
      </Alert>
    </Snackbar>
  );
}
