import React from "react";

const Underline = ({ color = "#c4c4c4" }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "1px",
        backgroundColor: color,
      }}
    />
  );
};

export default Underline;
