import { Grid, Typography } from "@mui/material";
import React from "react";

const UndefinedPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography>404 Page not found</Typography>
      </Grid>
    </Grid>
  );
};

export default UndefinedPage;
