import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement */

const baseUrl = apiConfig + "/agreement";

const getAgreements = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllAgreementsList", data);
};

const getCompanyAgreements = (data) => {
  return fetchWrapper.post(baseUrl + "/GetCompanyAgreementsList", data);
};

const createAgreement = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateAgreement", data);
};

const deleteAgreement = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteAgreements", data);
};

const getAgreementByID = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAgreementByID", data);
};

const updateAgreementData = (agrID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateAgreement/" + agrID, data);
};

const getAllAgreements = (companyID) => {
  return fetchWrapper.get(baseUrl + "/GetAllAgreements/" + companyID);
};

const checkIfAgreementIsLinkedToTakeoff = (agreementID) => {
  return fetchWrapper.get(
    baseUrl + "/CheckIfAgreementIsLinkedToTakeoff/" + agreementID
  );
};

const GetAgreementsPurchasingCompany = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetAgreementsPurchasingCompany/" + companyID
  );
};
const getAgreementByName = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAgreementByName", data);
};

export const agreementServices = {
  getAgreements,
  createAgreement,
  getCompanyAgreements,
  deleteAgreement,
  getAgreementByID,
  updateAgreementData,
  getAllAgreements,
  checkIfAgreementIsLinkedToTakeoff,
  GetAgreementsPurchasingCompany,
  getAgreementByName,
};
