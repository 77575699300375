import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomModal from "../CustomModal/CustomModal";
import { CircularProgress, IconButton, Paper } from "@mui/material";
import { AlertCircle, CheckCircle, Info, X } from "react-feather";

const Icon_Array = {
  Loading_Icon: <CircularProgress />,
  Warning_Icon: <Info color="#F18721" />,
  Error_Icon: <AlertCircle color="#E63846" />,
  Success_Icon: <CheckCircle color="#1D944B" />,
};

export default function InfoSnackbar({ open, message, severity, handleClose }) {
  return (
    <CustomModal openModal={open}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            gap: 10,
            alignItems: "center",
          }}
        >
          {Icon_Array[severity] ? Icon_Array[severity] : null}
          <p>{message}</p>
        </div>
        <IconButton onClick={handleClose}>
          <X />
        </IconButton>
      </div>
    </CustomModal>
  );
}
