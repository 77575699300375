import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { Plus, Save, Trash2, X } from "react-feather";
import DefaultControllerPriceTable from "../DefaultControllerPriceTable/DefaultControllerPriceTable";
import AddItemAgreementTable from "../AddItemAgreementTable/AddItemAgreementTable";
import AgreementForm from "../AgreementForm/AgreementForm";
import { agreementServices } from "../../services/agreement.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  //component to display the tab values
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AgreementTabs({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();
  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  //for permission handling
  const agreementPrices = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  const takeoffRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const advAgreement = useSelector((state) =>
    state.user.user ? state.user.user.user.role.advAgreement : ""
  );

  //We get data sent from previous page
  var agreementID = fromURL
    ? decryptedObject
      ? decryptedObject.agreementID
        ? decryptedObject.agreementID
        : 0
      : 0
    : location.state
    ? location.state.agreementID
      ? location.state.agreementID
      : 0
    : 0;

  const [agreementName, setAgreementName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.agreementName
          ? decryptedObject.agreementName
          : 0
        : 0
      : location.state
      ? location.state.agreementName
        ? location.state.agreementName
        : ""
      : ""
  );

  /*Check if Create mode*/
  const create = fromURL
    ? decryptedObject
      ? decryptedObject.create
        ? decryptedObject.create
        : 0
      : 0
    : location.state
    ? location.state.create
      ? location.state.create
      : false
    : false;

  // Redirection
  if (agreementPrices === "N" || agreementID === 0 || agreementName === "")
    history.push({
      pathname: "/dashboard",
      state: { redirectionError: FrontEndErrors["frontend_error1"] },
    });

  useEffect(() => {
    //for help button
    setPageLink("Agreements");

    //for side menu select
    setPageID(3);
  }, []);

  //state holding which tab we are in at the moment
  const [value, setValue] = useState(0);

  //permissions for the form
  const [adminManage, setAdminManage] = useState(false);
  const [salesManage, setSalesManage] = useState(false);
  const [createdAgreementID, setCreatedAgreementID] = useState(0);

  //make the save button in the tabs load on submit
  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  //make delete button in the tabs load on delete
  const [loading, setLoading] = useState(false);

  //holds the error if encountered while deleting record
  const [deleteError, setDeleteError] = useState("");

  //used to display the alert dialog before deleting
  const [deleteRecord, setDeleteRecord] = useState(false);

  //in case any changed happened
  const [formChanged, setFormChanged] = useState(false);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  /*Agreement Form Ref*/
  const agreementRef = useRef(null);

  //getting values from redux and values received from previous pages

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar
      ? location.state.openSnackBar
        ? true
        : false
      : false
  );

  const [snackBarMessage, setSnackBarMessage] = useState(
    location.state?.snackBarMessage !== ""
      ? location.state?.snackBarMessage
      : "Saved Successfully"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    //for help button

    if (advAgreement === "M") setAdminManage(true);
    if (agreementPrices === "M") setSalesManage(true);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {
    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleRouteClick = (route) => {
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };

  const handleConfirm = () => {
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    if (tempVal === "/Agreements") {
      history.push("/Agreements");
    } else if (tempVal === "/create-takeoff") {
      history.push({
        pathname: "/create-takeoff",
        state: {
          agreement: agreementID,
        },
      });
    }
    sessionStorage.removeItem("unsavedChanges");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  if (createdAgreementID !== 0) {
    agreementID = createdAgreementID;
  }

  const deleteAgreement = () => {
    //function to delete agreement

    setLoading(true);
    setDeleteRecord(false);
    const data = { Agreements: [agreementID] };
    agreementServices
      .deleteAgreement(data)
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/Agreements",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const viewAgreementData = (id, name) => {
    //we are taken to a page to create a new agreement
    if (id === 0) {
      history.push({
        pathname: "/create-agreement",
        state: {
          agreementID: id,
          agreementName: "New Agreement",
          create: true,
        },
      });
    } else {
      history.push({
        pathname: "/agreement-information",
        state: {
          agreementID: id,
          agreementName: name,
          create: false,
        },
      });
    }
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {/* <ConfirmationPopup
        openDialog={openItemDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        title="Leave Page?"
        message="You have items changes."
        confirmLabel="Leave"
        cancelLabel="Cancel"
      /> */}
      {/* {Prompt} */}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message={snackBarMessage}
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      <Paper>
        {deleteRecord ? (
          <AlertDialogSlide
            title="Warning"
            textContent={"Are you sure you want to delete this record?"}
            save={deleteAgreement}
            unsaveText="No"
            deleteDialog={true}
            unsave={() => setDeleteRecord(false)}
            saveText="Yes"
          />
        ) : null}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() => handleRouteClick("/Agreements")}
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                Agreements
              </Link>

              {(value === 1 || value === 2) && agreementName !== "" ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleRouteClick("/Agreement-information");
                    } else {
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {agreementName}
                </Link>
              ) : value === 0 && agreementName !== "" ? (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {agreementName}
                </Typography>
              ) : (
                ""
              )}
              {value === 1 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Default Controller Price
                </Typography>
              ) : (
                ""
              )}
              {value === 2 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Quick Kit Item Price
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>

            {!create && value === 0 ? (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {value === 0 && takeoffRole === "M" ? (
                    <CustomButton
                      startIcon={<Plus />}
                      extraMarginLeft={"10px"}
                      toggle={() => handleRouteClick("/create-takeoff")}
                    >
                      New Takeoff
                    </CustomButton>
                  ) : null}
                  {agreementPrices === "M" ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Plus />}
                        toggle={() => viewAgreementData(0)}
                      >
                        New Agreement
                      </CustomButton>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Save />}
                        toggle={() =>
                          agreementRef.current.checkIfAgreementIsLinkedToTakeoff()
                        }
                        loading={tabButtonLoading}
                      >
                        Save
                      </CustomButton>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Trash2 />}
                        toggle={() => setDeleteRecord(true)}
                        loading={loading}
                      >
                        Delete
                      </CustomButton>
                    </>
                  ) : null}

                  <div style={{ width: "10px" }} />
                  <CustomButton
                    startIcon={<X />}
                    toggle={async () => {
                      await agreementRef.current.setPristine();
                      history.push("/Agreements");
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="General" {...a11yProps(0)} style={{ margin: "50" }} />
              {/* {agreementID !== 0 && agreementPrices !== "N" ? (
                <Tab
                  label="Default Controller Price"
                  {...a11yProps(1)}
                  style={{ margin: "50" }}
                />
              ) : (
                ""
              )} */}
              {agreementID !== 0 && agreementPrices !== "N" ? (
                <Tab
                  label="Quick Kit Item Price"
                  {...a11yProps(2)}
                  style={{ margin: "50" }}
                />
              ) : (
                ""
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AgreementForm
              receivedAgreementID={agreementID}
              adminManage={adminManage}
              salesManage={salesManage}
              setOpenSnackBar={setOpenSnackBar}
              setSnackBarMessage={setSnackBarMessage}
              setTabButtonLoading={setTabButtonLoading}
              ref={agreementRef}
              setAgreementNameInTabs={setAgreementName}
              setFormChanged={setFormChanged}
            />
          </TabPanel>
          {/* {agreementPrices !== "N" ? (
            <TabPanel value={value} index={1}>
              <DefaultControllerPriceTable
                agreementID={agreementID}
                setFormChanged={setFormChanged}
                setDirty={setDirty}
                setPristine={setPristine}
              />
            </TabPanel>
          ) : (
            ""
          )} */}
          {agreementPrices !== "N" ? (
            <TabPanel value={value} index={1}>
              <AddItemAgreementTable
                agreementID={agreementID}
                setDirty={setDirty}
                setPristine={setPristine}
              />
            </TabPanel>
          ) : (
            ""
          )}
        </Box>
      </Paper>
    </>
  );
}
