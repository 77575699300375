import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { accountServices } from "../../services/account.services";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Trash2 } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const headCells = [
  {
    id: "deviceName",
    numeric: false,
    disablePadding: true,
    label: "Device Name",
  },
  {
    id: "creationDate",
    numeric: false,
    disablePadding: false,
    label: "Creation Date",
  },
  {
    id: "lastAccessed",
    numeric: false,
    disablePadding: false,
    label: "Last Accessed",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    rolePermission,
    user,
    userID,
  } = props;

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rowCount > 0 && (rolePermission === "M" || user === userID) ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UserDevices({ userID }) {
  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //holds page and rowsPerPage for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds table rows
  const [rows, setRows] = React.useState([]);

  //holds errors
  const [error, setError] = React.useState("");

  //holds number of selected rows
  var numSelected = selected.length;

  //holds total rows in database that match filters
  const [numberRecords, setNumberRecords] = React.useState(0);

  //for loading of button
  const [loading, setLoading] = React.useState(false);

  //to reload page
  const [reload, setReload] = React.useState(false);

  //make page load until data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds error on delete
  const [deleteError, setDeleteError] = React.useState("");

  //displays dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //fetch data from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  const user = useSelector((state) =>
    state.user.user ? state.user.user.user.microUserID : ""
  );

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      return (
        d.substring(8, 10) +
        "/" +
        d.substring(4, 7) +
        "/" +
        d.substring(13, 15) +
        " at " +
        d.substring(16, 21)
      );
    }
  };

  useEffect(() => {
    //we get list of devices for current user
    setRows([]);
    setPageLoad(true);
    setSelected([]);

    accountServices
      .getUserDevices(userID)
      .then((response) => {
        setNumberRecords(response.data?.length);

        setRows(response.data);

        setPageLoad(false);
      })
      .catch((error) => {
        setError(error.response.data?.message);
        setPageLoad(false);
      });
  }, [page, rowsPerPage, reload]);

  //function to select all fields
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.device.deviceID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  //function to change page in table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //function to change rowsPerPage in table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUserDevices = () => {
    //function to delete user devices
    setLoading(true);
    setDeleteRecord(false);
    const data = { Devices: selected };
    accountServices
      .deleteUserDevices(data)
      .then((response) => {
        setReload(!reload);
        setLoading(false);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };

  //checks if row selecetd
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete this record?"}
          save={deleteUserDevices}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              ""
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Trash2 />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setDeleteRecord(true)}
                  loading={loading}
                >
                  Delete
                </CustomButton>
              </>
            ) : (
              ""
            )}
          </Toolbar>

          <TableContainer className={"table-container"}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={numberRecords}
                rolePermission={rolePermission}
                user={user}
                userID={userID}
              />
              <TableBody>
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems the User has no saved devices.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.device.deviceID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.device.deviceID}
                      >
                        <TableCell padding="checkbox">
                          {rolePermission === "M" || user === userID ? (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.device.deviceID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.deviceName}
                        </TableCell>
                        <TableCell>
                          {convertUTC(row.device.creationDateTime)}
                        </TableCell>
                        <TableCell>
                          {convertUTC(row.device.lastAccessDateTime)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
