import React, { useState, useEffect } from "react";
import { logsService } from "../services/logs.services";

const LogTypes = {
  Melissa: "Melissa",
  API_CALL: "Function Call",
  Middleware: "Middleware",
};

const FunctionCall = {
  onSave: "onSave",
  onUnsave: "onUnsave",
};

export function useLogs() {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonLoading2, setButtonLoading2] = useState(false);
  const [error, setError] = useState("");

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  const addLogAndLogDetail = async (
    Type,
    Status,
    LogDate,
    LogEndDate,
    LogDetailList,
    placeCalled = ""
  ) => {
    if (LogTypes[Type] === null && LogTypes[Type] === undefined) return;

    const LogObject = {
      Type: LogTypes[Type],
      Status: Status,
      LogDate: LogDate,
      LogEndDate: LogEndDate,
    };

    if (FunctionCall[placeCalled] === "onUnsave") setButtonLoading2(true);
    else if (FunctionCall[placeCalled] === "onSave ") setButtonLoading(true);
    await logsService
      .createLogForAddress({
        Log: LogObject,
        LogDetails: LogDetailList,
      })
      .then((response) => {
        if (FunctionCall[placeCalled] === "onUnsave") setButtonLoading2(false);
        else if (FunctionCall[placeCalled] === "onSave ")
          setButtonLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        if (FunctionCall[placeCalled] === "onUnsave") setButtonLoading2(false);
        else if (FunctionCall[placeCalled] === "onSave ")
          setButtonLoading(false);
      });
  };

  return [
    buttonLoading,
    error,
    handleCloseError,
    buttonLoading2,
    addLogAndLogDetail,
  ];
}
