import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { store } from "../redux/store";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/admin";

const createUser = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateUser", data);
};

const activateUsers = (data) => {
  return fetchWrapper.post(baseUrl + "/ActivateUser", data);
};

const deactivateUsers = (data) => {
  return fetchWrapper.post(baseUrl + "/DeactivateUser", data);
};

const createCompany = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateCompany", data);
};

const createRole = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateRole", data);
};

const changeUserPassword = (data) => {
  return fetchWrapper.post(baseUrl + "/ResetAUserPassword", data);
};

const uploadVideo = (data) => {
  const state = store.getState();
  return fetchWrapper.postFormData(baseUrl + "/UploadVideo", data, {
    headers: {
      // 'Authorization': "Bearer " + state.user.token,
      "content-type": "multipart/form-data",
      Authorization: state.user.user
        ? "Bearer " + state.user.user.jwtToken
        : "",
    },
  });
};

const getVideos = () => {
  return fetchWrapper.get(baseUrl + "/GetVideosList");
};

const deleteVideo = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteVideo", data);
};
export const adminServices = {
  activateUsers,
  deactivateUsers,
  createUser,
  createCompany,
  createRole,
  changeUserPassword,
  uploadVideo,
  getVideos,
  deleteVideo,
};
