import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Tooltip,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { countryServices } from "../../services/country.services";
import SnackBar from "../SnackBar/SnackBar";
import { useSelector } from "react-redux";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Compass, Save, X } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { getMelissaLog } from "../../helpers/melissa-api-logs";
import { systemDataServices } from "../../services/systemData.services";
import CustomModal from "../CustomModal/CustomModal";
import { melissaServices } from "../../services/melissa.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { useLogs } from "../../hooks/useLogs";
import InfoSnackbar from "../InfoSnackbar/InfoSnackbar";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

const ShippingAddressForm = forwardRef(
  (
    {
      create = true,
      companyID,
      shippingAddressID = 0,
      setSaved,
      countryIdentification = 0,
      setFormChanged,
      permission = "M",
      fromOrder = false,
      setShipModalOpen,
      setShipModalUpdateOpen,
      fetchShippingAddressesForOrder,
      fetchShippingAddressesForCompany,
      fromCompany = false,
      orderID = null,
      setShippingAddressIDs,
      setShipToAddress,
      setToUpdateShipAddID,
      companyName = "",
      setShippingAddressID,
      setSuccessModification,
      fromCreateOrder = false,
    },
    ref
  ) => {
    /*Selectors data*/
    const [stateProvince, setStateProvince] = useState([]);

    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    /*SnackBar open handling*/

    //to hold field values in form
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState();
    const [stateProvincesValue, setStateProvincesValue] = useState();
    const [city, setCity] = useState("");
    const [zipPostCode, setZipPostCode] = useState("");
    const [name, setName] = useState("");
    const [changedHappened, setChangedHappened] = useState(false);

    //in case of error in a field
    const [countryError, setCountryError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [streetError, setStreetError] = useState(false);
    const [street2Error, setStreet2Error] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [nameError, setNameError] = useState(false);

    var [addressChanged, setAddressChanged] = useState(false);
    const [verifyAddress, setVerifyAddress] = useState(false);
    const [dateAlertDialogOpen2, setDateAlertDialog2] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState("");
    const [melissaResults, setMelissaResults] = useState([]);
    const [addressVerificationLevel, setAddressVerificationLevel] =
      useState(null);
    const [addressLevel, setAddressLevel] = useState(null);

    //hook to call log function
    const [
      logLoading,
      logError,
      handleLogCloseError,
      logUnsaveLoading,
      addLogAndLogDetail,
    ] = useLogs();

    /**Holds address to log */
    const [AddressToLog, setAddressToLog] = useState("");

    //Melissa Creadits Error Dialog
    //to display the dialog in case no credits or melissa side error, we give the User to option to continue
    const [melissaCreditsDialog, setMelissaCreaditsDialog] = useState(false);

    const [addressError, setAddressError] = useState(false);
    const [melissaInfoSnackbar, setMelissaInfoSnackbar] = useState(false);
    const [melissaMessageSnackbar, setMelissaMessageSnackbar] = useState(false);
    const [melissaSeveritySnackbar, setMelissaSeveritySnackbar] =
      useState(false);

    //holds addresses returned for autocomplete of addresses
    const [suggestions, setSuggestions] = useState([]);

    //holds autocomplete address

    const [addressValue, setAddressValue] = useState("");
    const [addressPicked, setAddressPicked] = useState(null);
    const [addressVerified, setAddressVerified] = useState(false);

    //holds value for AlternateShippingAddress
    const [alternateShippingAddress, setAlternateShippingAddress] =
      useState(true);

    //to call the save after all new values have been set
    const [melissaSave, setMelissaSave] = useState(false);

    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    //getting data from redux
    const compID = useSelector((state) =>
      state.user.user ? state.user.user.user.companyID : ""
    );

    const rolePermission = useSelector((state) =>
      state.user.user
        ? companyID === compID
          ? state.user.user.user.role.ownCompany
          : state.user.user.user.role.clientCompany
        : ""
    );

    const getStateProvinces = (value) => {
      //get state provinces according to a specific company
      if (value !== null) {
        countryServices
          .getStateProvince(value.countryID)
          .then((response) => {
            setStateProvince(response.data);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          });
      }
    };

    const fetchAddresses = (address) => {
      if (verifyAddress === true && country !== null) {
        melissaServices
          .getAddressLookup({
            Address: address,
            Country: country.countryCode,
          })
          .then((response) => {
            setSuggestions(response.data.addresses);
          })
          .catch((error) => {
            const logDetail = {
              Status: "Melissa Error",
              Description: `Address Autocomplete fail, address sent:{Country:${country.description}, Address:${address}}`,
              AdditionalInfo: error.response.data.error.error,
              LogDetailTimeStamp: new Date().toISOString(),
            };

            addLogAndLogDetail(
              "Melissa",
              "Melissa Address Autocomplete",
              new Date().toISOString(),
              new Date().toISOString(),
              [logDetail],
              "onError"
            );
          });
      }
    };

    const fetchVerifyAddress = async () => {
      systemDataServices
        .getSystemDataVariables()
        .then((response) => {
          setVerifyAddress(response.data.applyAddressValidation);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    useEffect(() => {
      if (melissaSave === true) {
        createShippingAddress("");
        setMelissaSave(false);
      }
    }, [melissaSave]);

    const getData = () => {
      //if edit mode, get data to display
      if (!create && shippingAddressID !== 0) {
        setLoading(true);
        shippingaddressServices
          .getShippingAddressByID(shippingAddressID)
          .then((response) => {
            setName(response.data.name);
            setCountry(response.data.country);
            // setStateProvincesValue(response.data.stateProvince);
            // setStreetAddress(response.data.streetAddress);

            setAddressValue(
              `${response.data.streetAddress}, ${response.data.city}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.zipPostCode}`
            );
            setAddressPicked({
              address: `${response.data.streetAddress}, ${response.data.city}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.zipPostCode}`,
              address1: response.data.streetAddress,
              address2: response.data.secondAddress,
              city: response.data.city,
              zipPostCode: response.data.zipPostCode,
              country: response.data.country,
              stateProvince: response.data.stateProvince,
            });
            // setStreetAddress2(response.data.secondAddress);
            setAddressLevel(response.data.addressVerificationLevel);
            setAddressVerificationLevel(response.data.addressVerificationLevel);
            setAlternateShippingAddress(response.data.alternateShippingAddress);
            // setCity(response.data.city);
            // setZipPostCode(response.data.zipPostCode);
            setSaveError("");
            getStateProvinces(response.data.country);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setSaveError(error.response.data.error.error);
          });
      }
    };

    useEffect(() => {
      //get Country List
      countryServices
        .getCountries()
        .then((response) => {
          setCountries(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error.to);
        });

      fetchVerifyAddress();

      //we fill country according to company country office country
      if (create && countryIdentification !== 0) {
        countryServices
          .getCountryByID(countryIdentification)
          .then((response) => {
            setCountry(response.data);
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
          });
      }

      if (shippingAddressID !== 0) getData();
    }, []);

    const errorHandling = () => {
      //check for errors before submitting form
      var error = false;

      if (name === "" || name === null) {
        setNameError(true);
        error = true;
      }

      if (country === null) {
        setCountryError(true);
        error = true;
      }

      if (addressValue === null || addressValue === "") {
        setAddressError(true);
        error = true;
      }

      if (shippingAddressID === 0 && create === false) {
        error = true;
      }

      return error;
    };

    const createShippingAddress = async (e) => {
      //create shipping address
      if (e) e.preventDefault();
      var error = errorHandling();
      if (error === false) {
        setButtonLoading(true);

        if (addressChanged && verifyAddress) {
          setMelissaMessageSnackbar("Checking This Address...");
          setMelissaSeveritySnackbar("Loading_Icon");
          setMelissaInfoSnackbar(true);

          if (country.description === "" || country.description === null) {
            setButtonLoading(false);
            setCountryError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\- ]+\,[a-zA-Z0-9\- ]+$/g;

          if (regex.test(addressValue) === false) {
            setButtonLoading(false);
            setAddressError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

          if (editAddress_1.length !== 3) {
            setButtonLoading(false);
            setAddressError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          var streetAddressToBeSent_1 = editAddress_1[0].trim();
          var cityToBeSent_1 = editAddress_1[1].trim();

          var editAddress_StateProvince_Zip = editAddress_1[2]
            .trim()
            .split(" ");

          if (editAddress_StateProvince_Zip.length < 2) {
            setButtonLoading(false);
            setAddressError(true);
            setMelissaMessageSnackbar(
              "Address format not OK, please re-enter."
            );
            setMelissaSeveritySnackbar("Error_Icon");
            setMelissaInfoSnackbar(true);
            return;
          }

          var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

          var ZipPostCodeToBeSent_1 = "";

          editAddress_StateProvince_Zip.map((value, index) => {
            if (index !== 0 && value !== null && value !== undefined) {
              ZipPostCodeToBeSent_1 += " " + value;
              return "";
            } else {
              return "";
            }
          });

          const data = {
            Street1: streetAddressToBeSent_1,
            Street2: "", //instStreet2,
            City: cityToBeSent_1,
            StateProvince: StateProvinceToBeSent_1,
            Country: country.description,
            ZipCode: ZipPostCodeToBeSent_1,
          };

          await melissaServices
            .validateAddress(data)
            .then(async (response) => {
              setMelissaInfoSnackbar(false);
              var descriptions = [];
              var addresslvl = "";
              setFormattedAddress(
                response.data.result.records[0].formattedAddress
              );
              setCity(response.data.result.records[0].locality);
              setCountry(response.data.country);
              setStateProvincesValue(response.data.stateProvince);
              setStreetAddress(response.data.result.records[0].addressLine1);

              var zip = await response.data.result.records[0].postalCode;
              if (zip.indexOf("-") === -1) {
                setZipPostCode(response.data.result.records[0].postalCode);
                setAddressValue(
                  `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                );
              } else {
                if (zipPostCode.indexOf("-") === -1) {
                  var part1 = zip.split("-");
                  setZipPostCode(part1[0]);
                  setAddressValue(
                    `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${part1[0]}`
                  );
                } else {
                  setZipPostCode(response.data.result.records[0].postalCode);
                  setAddressValue(
                    `${response.data.result.records[0].addressLine1}, ${response.data.result.records[0].locality}, ${response.data.stateProvince?.stateProvinceCode} ${response.data.result.records[0].postalCode}`
                  );
                }
              }
              await response.data.result.records[0].results
                .split(",")
                .forEach((code) => {
                  if (getMelissaLog(code) !== "") {
                    descriptions.push(getMelissaLog(code));
                  }
                  if (
                    code === "AV11" ||
                    code === "AV12" ||
                    code === "AV13" ||
                    code === "AV14" ||
                    code === "AV21" ||
                    code === "AV22" ||
                    code === "AV23" ||
                    code === "AV24" ||
                    code === "AV25"
                  ) {
                    setAddressVerificationLevel(code);
                    addresslvl = code;
                  }
                });

              if (
                addresslvl === "AV11" ||
                addresslvl === "AV12" ||
                addresslvl === "AV13" ||
                addresslvl === "AV14" ||
                addresslvl === "AV21" ||
                addresslvl === "AV22" ||
                addresslvl === "AV23" ||
                addresslvl === "AV24" ||
                addresslvl === "AV25"
              ) {
                setMelissaMessageSnackbar(
                  "Address adjusted/verified, thank you."
                );
                setMelissaSeveritySnackbar("Success_Icon");
                setMelissaInfoSnackbar(true);
                setAddressChanged(false);
                setMelissaSave(true);
              } else {
                setMelissaResults(descriptions);
                setMelissaMessageSnackbar(
                  "The address could not be verified at least up to the zip or post code level."
                );
                setMelissaSeveritySnackbar("Warning_Icon");
                setMelissaInfoSnackbar(true);

                setButtonLoading(false);
              }
            })
            .catch((error) => {
              setButtonLoading(false);
              if (error.response.data.error.errorCode === "err369") {
                setAddressToLog(`{
                  Address: ${streetAddressToBeSent_1},
                  City: ${cityToBeSent_1},
                  Country: ${country.description},
                  State Province: ${StateProvinceToBeSent_1},
                  ZipCode: ${ZipPostCodeToBeSent_1},
                }`);
                setMelissaCreaditsDialog(true);
                setMelissaMessageSnackbar(
                  "Address format OK but not verifiable at this time. You can proceed with this unverified address."
                );
                setMelissaSeveritySnackbar("Warning_Icon");
                setMelissaInfoSnackbar(true);
              } else {
                setMelissaInfoSnackbar(false);
                setSaveError(error.response.data.error.error);
              }
            });
        } else {
          if (country.description === "" || country.description === null) {
            setCountryError(true);
            setButtonLoading(false);

            setSaveError("Please Check if All the Fields Are Valid");
            return;
          }

          var regex = /^[0-9a-zA-Z\- ]+\,[a-zA-Z0-9\-\. ]+\,[a-zA-Z0-9\- ]+$/g;

          if (regex.test(addressValue) === false) {
            setAddressError(true);
            setButtonLoading(false);

            setSaveError("Address Format not Valid");
            return;
          }

          var editAddress_1 = addressValue.trim().split(","); //get address seperated by commas

          if (editAddress_1.length !== 3) {
            setAddressError(true);
            setButtonLoading(false);

            setSaveError("Please Check if All the Fields Are Valid");

            return;
          }

          var streetAddressToBeSent_1 = editAddress_1[0].trim();
          var cityToBeSent_1 = editAddress_1[1].trim();

          var editAddress_StateProvince_Zip = editAddress_1[2]
            .trim()
            .split(" ");

          if (editAddress_StateProvince_Zip.length < 2) {
            setAddressError(true);
            setButtonLoading(false);

            setSaveError("Please Check if All the Fields Are Valid");
            return;
          }

          var StateProvinceToBeSent_1 = editAddress_StateProvince_Zip[0].trim();

          var StateProvinceToBeSentCodeIndex = stateProvince.findIndex(
            (x) => x.stateProvinceCode === StateProvinceToBeSent_1
          );

          if (StateProvinceToBeSentCodeIndex === -1) {
            setAddressError(true);
            setButtonLoading(false);

            setSaveError("Invalid State Province");
            return;
          }

          var ZipPostCodeToBeSent_1 = "";

          editAddress_StateProvince_Zip.map((value, index) => {
            if (index !== 0 && value !== null && value !== undefined) {
              ZipPostCodeToBeSent_1 += " " + value;
              return "";
            } else {
              return "";
            }
          });

          if (fromOrder || fromCompany) {
            setButtonLoading(true);

            const data = {
              CompanyID: companyID,
              StreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : streetAddress !== "" && streetAddress !== null
                    ? streetAddress
                    : streetAddressToBeSent_1,
              SecondAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : streetAddress2 !== "" && streetAddress2 !== null
                    ? streetAddress2
                    : editAddress_1[1] + ", " + editAddress_1[2],
              CountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : country && country !== null
                    ? country.countryID
                    : null,
              StateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : stateProvincesValue && stateProvincesValue !== null
                    ? stateProvincesValue.stateProvinceID
                    : stateProvince[StateProvinceToBeSentCodeIndex]
                      .stateProvinceID,
              City:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : city !== "" && city !== null
                    ? city
                    : cityToBeSent_1,
              ZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : zipPostCode !== "" && zipPostCode !== null
                    ? zipPostCode
                    : ZipPostCodeToBeSent_1,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              AlternateShippingAddress: alternateShippingAddress,
              Name: name,
              OrderID: fromOrder ? (orderID ? orderID : null) : null,
            };

            if (create) {
              //create shipping address depending if in create mode
              shippingaddressServices
                .createShippingAddress(data)
                .then(async (response) => {
                  setButtonLoading(false);
                  setShipModalOpen(false);
                  setSaveError("");
                  if (fromOrder && fromCreateOrder === true)
                    setShippingAddressIDs((oldAddr) => [
                      ...oldAddr,
                      response.data.shippingID,
                    ]);
                  if (fromOrder) await fetchShippingAddressesForOrder();
                  else if (fromCompany)
                    await await fetchShippingAddressesForCompany();
                  if (fromOrder) setToUpdateShipAddID(response.data.shippingID);
                  setAddressChanged("");
                  setAddressVerificationLevel("");
                  if (fromOrder) setShipToAddress(response.data);

                  if (fromCompany) setSuccessModification(true);
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                  setButtonLoading(false);
                });
            } else {
              shippingaddressServices
                .updateShippingAddress(shippingAddressID, data)
                .then(async (response) => {
                  setButtonLoading(false);
                  if (!fromOrder) setShipModalOpen(false);
                  if (fromOrder) setShipModalUpdateOpen(false);
                  setSaveError("");
                  setAddressChanged("");
                  setAddressVerificationLevel("");
                  if (fromOrder) await fetchShippingAddressesForOrder();
                  else if (fromCompany)
                    await await fetchShippingAddressesForCompany();
                  if (fromOrder) setShipToAddress(response.data);
                  if (fromCompany) setSuccessModification(true);
                })
                .catch((error) => {
                  setButtonLoading(false);
                  setSaveError(error.response.data.error.error);
                });
            }
          } else {
            setButtonLoading(true);
            const data = {
              CompanyID: companyID,
              StreetAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address1
                  : streetAddress !== "" && streetAddress !== null
                    ? streetAddress
                    : streetAddressToBeSent_1,
              SecondAddress:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.address2
                  : streetAddress2 !== "" && streetAddress2 !== null
                    ? streetAddress2
                    : editAddress_1[1] + ", " + editAddress_1[2],
              CountryID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.country.countryID
                  : country && country !== null
                    ? country.countryID
                    : null,
              StateProvinceID:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.stateProvince.stateProvinceID
                  : stateProvincesValue && stateProvincesValue !== null
                    ? stateProvincesValue.stateProvinceID
                    : stateProvince[StateProvinceToBeSentCodeIndex]
                      .stateProvinceID,
              City:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.city
                  : city !== "" && city !== null
                    ? city
                    : cityToBeSent_1,
              ZipPostCode:
                addressPicked !== null && addressPicked !== undefined
                  ? addressPicked.zipPostCode
                  : zipPostCode !== "" && zipPostCode !== null
                    ? zipPostCode
                    : ZipPostCodeToBeSent_1,
              AddressVerificationLevel:
                addressVerified === true ? "AV25" : addressVerificationLevel,
              AlternateShippingAddress: alternateShippingAddress,
              Name: name,
            };

            //create or update shipping address depending if in create or edit mode
            if (create) {
              shippingaddressServices
                .createShippingAddress(data)
                .then((response) => {
                  if (fromCompany === true) setChangedHappened(false);
                  if (fromCompany === false) {
                    setFormChanged(false)
                    sessionStorage.removeItem("unsavedChanges");

                  };
                  setPristine();
                  setButtonLoading(false);
                  setShipModalOpen(false);
                  setSaved(true);
                  if (fromCompany) setSuccessModification(true);
                  setSaveError("");
                  getData();
                })
                .catch((error) => {
                  setSaveError(error.response.data.error.error);
                  setButtonLoading(false);
                });
            } else {
              shippingaddressServices
                .updateShippingAddress(shippingAddressID, data)
                .then((response) => {
                  if (fromCompany === true) setChangedHappened(false);
                  if (fromCompany === false) {
                    setFormChanged(false)
                    sessionStorage.removeItem("unsavedChanges");

                  };
                  setPristine();
                  setAddressChanged("");
                  setAddressVerificationLevel("");
                  setShipModalOpen(false);
                  setButtonLoading(false);
                  setOpenSnackBar(true);
                  if (fromCompany) setSuccessModification(true);
                  setSaveError("");
                  getData();
                })
                .catch((error) => {
                  setButtonLoading(false);
                  setSaveError(error.response.data.error.error);
                });
            }
          }
        }
      }
    };
    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setMelissaInfoSnackbar(false);
    };

    /*Fetch states/provinces everytime the company is changed*/
    useEffect(() => {
      //get state provinces according to country
      if (country) getStateProvinces(country);
    }, [country]);

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    useImperativeHandle(ref, () => ({
      setPristine,
      createShippingAddress,
    }));

    function closeForm() {
      if (changedHappened === true) {
       setOpenDialog(true)
          setShipModalOpen(false);
          if (fromCompany && create === false) setShippingAddressID(0);
        
      } else {
        setShipModalOpen(false);
        if (fromCompany && create === false) setShippingAddressID(0);
      }
    }
    const [openDialog, setOpenDialog] = useState(false);
  
    
  
    const handleConfirm = () => {
      sessionStorage.removeItem("unsavedChanges");
  
      setChangedHappened(false);
      setOpenDialog(false);
    };
  
    const handleClose = () => {
  
      setOpenDialog(false);
    };

    return (
      <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
        {melissaCreditsDialog === true ? (
          <>

            <AlertDialogSlide
              title="Warning"
              textContent={"Address check failed, cancel or continue anyway?"}
              save={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: `{On Shipping Address ${create === true ? "creation" : "edit"
                    }, CompanyID: ${companyID}, Company Name: ${companyName}, Alternate Shipping Address Flag: ${alternateShippingAddress}}`,
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onSave"
                ).then(() => {
                  addressChanged = false;
                  setMelissaResults([]);
                  createShippingAddress("");
                  setMelissaCreaditsDialog(false);
                });
              }}
              saveText="Continue"
              disableUnsave={logLoading === true || buttonLoading === true}
              unsaveLoading={logUnsaveLoading}
              disableSave={logUnsaveLoading === true}
              unsave={() => {
                const logDetail = {
                  Status:
                    "Melissa Address Check Failed. Melissa tokens may be finished.",
                  Description: "",
                  AdditionalInfo: AddressToLog,
                  LogDetailTimeStamp: new Date().toISOString(),
                };
                addLogAndLogDetail(
                  "Melissa",
                  "Melissa Address Check Failed",
                  new Date().toISOString(),
                  new Date().toISOString(),
                  [logDetail],
                  "onUnsave"
                ).then(() => {
                  setMelissaResults([]);
                  setAddressChanged(true);

                  setAddressVerificationLevel("");
                  setMelissaCreaditsDialog(false);
                });
              }}
              unsaveText="Cancel"
              saveButtonLoading={logLoading || buttonLoading}
            />
            <InfoSnackbar
              open={melissaInfoSnackbar}
              message={melissaMessageSnackbar}
              severity={melissaSeveritySnackbar}
              handleClose={handleSnackBarClose}
            />
          </>
        ) : null}
        {logError !== "" ? (
          <AlertSnackBar
            message={logError}
            handleClose={handleLogCloseError}
            open={logError !== ""}
          />
        ) : null}
        {openSnackBar ? (
          <SnackBar
            open={openSnackBar}
            message="Saved Successfully"
            severity="success"
            handleClose={() => setOpenSnackBar(false)}
          />
        ) : null}
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{ paddingRight: "20px" }}
              >
                {/*Form Grid*/}
                <form onSubmit={createShippingAddress} autoComplete="off">
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6" component="span">
                          Shipping Address
                        </Typography>
                        <Tooltip
                          title={
                            addressLevel === "AV11" ||
                              addressLevel === "AV12" ||
                              addressLevel === "AV13" ||
                              addressLevel === "AV14"
                              ? "Address Partially Verified"
                              : addressLevel === "AV21" ||
                                addressLevel === "AV22" ||
                                addressLevel === "AV23" ||
                                addressLevel === "AV24" ||
                                addressLevel === "AV25"
                                ? "Address Fully Verified"
                                : "Address Not Verified"
                          }
                        >
                          <span>
                            <IconButton disabled>
                              <Compass
                                color={
                                  addressLevel === "AV11" ||
                                    addressLevel === "AV12" ||
                                    addressLevel === "AV13" ||
                                    addressLevel === "AV14"
                                    ? "#FEBF11"
                                    : addressLevel === "AV21" ||
                                      addressLevel === "AV22" ||
                                      addressLevel === "AV23" ||
                                      addressLevel === "AV24" ||
                                      addressLevel === "AV25"
                                      ? "#98C848"
                                      : "#E63846"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                      <Underline />
                    </Grid>
                    {fromOrder === true ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                          disabled={rolePermission !== "M" ? true : false}
                          control={
                            <Checkbox
                              checked={alternateShippingAddress}
                              onClick={() => {
                                setAlternateShippingAddress(
                                  !alternateShippingAddress
                                );
                                setDirty();
                                if (fromCompany === true)
                                  setChangedHappened(true);
                                
                                if (fromCompany === false) {
                                  setFormChanged(true);
                                  sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                                }
                              }}
                            />
                          }
                          label="Save this as an alternate shipping address"
                        />
                      </Grid>
                    ) : null}

                    {/*Name */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Location Name"
                        required
                        fullWidth
                        value={name}
                        inputProps={{
                          autoComplete: "new-password",
                          maxLength: 50,
                        }}
                        error={nameError}
                        helperText={nameError ? "Name field is empty" : ""}
                        onChange={(e) => {
                          setName(e.target.value);
                          setDirty();
                          if (fromCompany === true) setChangedHappened(true);
                          if (fromCompany === false) {
                            setFormChanged(true);
                            sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                          }
                          if (nameError) setNameError(false);
                        }}
                        disabled={rolePermission !== "M" ? true : false}
                      />
                    </Grid>

                    {/*Country input*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        componentName="Field1"
                        options={countries}
                        value={country ? country : {}}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        onChange={(event, value) => {
                          if (country?.description !== value?.description) {
                            setStateProvincesValue();
                          }
                          setCountry(value);
                          setAddressChanged(true);
                          setAddressVerificationLevel(null);
                          getStateProvinces(value);
                          setDirty();
                          if (fromCompany === true) setChangedHappened(true);
                          if (fromCompany === false) {
                            setFormChanged(true);
                            sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                          }

                          if (countryError) setCountryError(false);
                        }}
                        disabled={rolePermission !== "M" ? true : false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="Field1"
                            label="Country"
                            fullWidth
                            size="small"
                            required
                            type="text"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            error={countryError}
                            helperText={
                              countryError ? "Country field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        componentName="aFieldq23"
                        options={suggestions.map(
                          (options) =>
                            options.address1 +
                            ", " +
                            options.city +
                            ", " +
                            options.stateProvince.stateProvinceCode +
                            " " +
                            options.zipPostCode
                        )}
                        value={addressValue}
                        disabled={
                          country === null ||
                          country === undefined ||
                        
                          rolePermission !== "M"
                        }
                        onChange={(event, newValue) => {
                          if (verifyAddress === true) fetchAddresses(newValue);
                          if (addressError) setAddressError(false);
                          var index = suggestions.findIndex(
                            (x) =>
                              x.address1 +
                              ", " +
                              x.city +
                              ", " +
                              x.stateProvince.stateProvinceCode +
                              " " +
                              x.zipPostCode ===
                              newValue
                          );
                          if (fromCompany === true) setChangedHappened(true);
                          if (fromCompany === false) {
                            setFormChanged(true);
                            sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                          }
                          setDirty();
                          if (index !== -1) {
                            setAddressChanged(false);
                            setAddressPicked(suggestions[index]);

                            setAddressValue(
                              suggestions[index].address1 +
                              ", " +
                              suggestions[index].city +
                              ", " +
                              suggestions[index].stateProvince
                                .stateProvinceCode +
                              " " +
                              suggestions[index].zipPostCode
                            );
                            setAddressVerified(true);
                          } else {
                            setAddressVerified(false);
                            setAddressPicked(null);
                            setAddressChanged(true);
                            setAddressValue(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Address"
                            size="small"
                            componentName="asdField879"
                            required
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            error={addressError}
                            onChange={(newValue) => {
                              if (verifyAddress === true)
                                fetchAddresses(newValue.target.value);
                            }}
                            helperText={
                              addressError
                                ? "Address field is empty. Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                                : "Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                            }
                            disabled={
                              country === null ||
                              country === undefined ||
                              rolePermission !== "M"
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/*Save button*/}
                    {permission === "M" ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {fromOrder || fromCompany ? (
                            <>
                              <CustomButton
                                startIcon={<X />}
                                disabled={buttonLoading === true}
                                toggle={() =>
                                  fromOrder
                                    ? create
                                      ? setShipModalOpen(false)
                                      : setShipModalUpdateOpen(false)
                                    : closeForm()
                                }
                              >
                                Cancel
                              </CustomButton>
                              <div style={{ width: "10px" }} />
                            </>
                          ) : null}

                          <CustomButton
                            type={"submit"}
                            loading={buttonLoading}
                            startIcon={<Save />}
                          >
                            Save
                          </CustomButton>
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </>
        )}

        {melissaResults.length !== 0 ? (
          <CustomModal openModal={melissaResults.length !== 0} width="60vw">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="span">
                  Address Verification
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {melissaResults.map((description) => (
                  <>
                    <Typography variant="body1" component="span">
                      - {description}
                    </Typography>
                    <br />
                  </>
                ))}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body1" component="span">
                  <b>Address Found</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Street 1: </strong> {streetAddress}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Street 2: </strong> {streetAddress2}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>City: </strong> {city}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>State/Province: </strong>{" "}
                      {stateProvincesValue?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Country: </strong> {country?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span">
                      <strong>Zip/Post Code: </strong> {zipPostCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <CustomButton
                    toggle={() => {
                      setMelissaResults([]);
                      setAddressChanged(true);
                      setAddressVerificationLevel("");
                    }}
                  >
                    Edit Address
                  </CustomButton>
                  <div style={{ width: "10px" }} />
                  <CustomButton
                    toggle={async () => {
                      addressChanged = false;
                      setMelissaResults([]);
                      createShippingAddress("");
                    }}
                    loading={buttonLoading}
                  >
                    Proceed
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </CustomModal>
        ) : null}

        {saveError !== "" ? (
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        ) : null}

        <InfoSnackbar
          open={melissaInfoSnackbar && melissaCreditsDialog === false}
          message={melissaMessageSnackbar}
          severity={melissaSeveritySnackbar}
          handleClose={handleSnackBarClose}
        />
      </>
    );
  }
);

export default ShippingAddressForm;
