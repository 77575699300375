import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/customer";

const getListOfCustomers = (data) => {
  return fetchWrapper.post(baseUrl + "/GetListOfCustomers", data);
};

const getListOfContactsForCustomer = (data) => {
  return fetchWrapper.post(baseUrl + "/GetListOfContactsForCustomer", data);
};

const getAllCustomerContacts = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllCustomerContacts", data);
};

export const customerServices = {
  getListOfCustomers,
  getListOfContactsForCustomer,
  getAllCustomerContacts,
};
