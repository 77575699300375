import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SnackBar from "../SnackBar/SnackBar";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { UserPlus, Filter, Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { countryServices } from "../../services/country.services";
import { companyServices } from "../../services/company.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { accountServices } from "../../services/account.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

//holds the columns of the table header

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "Type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "officeCountry",
    numeric: false,
    disablePadding: false,
    label: "Office Country",
  },
  {
    id: "levvenSales",
    numeric: false,
    disablePadding: false,
    label: "Levven Sales",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolePermission,
  } = props;
  const createSortHandler = (property) => {
    //calls the sorting function from the companies table component
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === "M" && rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CompaniesTableComponent() {
  //used for permissions
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientCompany : ""
  );

  const history = useHistory();
  const location = useLocation();

  /*Redirection if no permission*/
  if (rolePermission === "N") {
    history.push("/dashboard");
  }
  //state for ordering
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //state holding selected values
  const [selected, setSelected] = React.useState([]);

  //state responsible for the pages in the table pagination
  const [page, setPage] = React.useState(0);

  //state responsible for the number of rows per page to display
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //state holding all records to be displayed in the databasse
  const [rows, setRows] = React.useState([]);

  //state to display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //state holding errors while fetching data
  const [error, setError] = React.useState("");

  //saving number of records are curently selected
  var numSelected = selected.length;

  //state holding all records that can be displayed in the table (without pagination)
  const [numberRecords, setNumberRecords] = React.useState(0);

  //state for delete button loading on delete
  const [loading, setLoading] = React.useState(false);

  //states for filters
  const [types, setTypes] = React.useState([
    { name: "Builder", id: "B" },
    { name: "Installer", id: "I" },
    { name: "Reseller", id: "R" },
    { name: "Levven", id: "L" },
  ]);
  const [countryInformation, setCountryInformation] = React.useState([]);
  const [levvenSales, setLevvenSales] = React.useState([]);

  //state to refetch data from the database
  const [resubmit, setResubmit] = React.useState(false);

  //state to hold data for filters submission
  const [saveCountries, setSaveCountries] = React.useState([]);
  const [saveTypes, setSaveTypes] = React.useState([]);
  const [nameSearch, setNameSearch] = React.useState("");
  const [saveStatus, setSaveStatus] = React.useState([]);
  const [saveLevvenSales, setSaveLevvenSales] = React.useState([]);

  //state for page laod before data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //state to hold message in case of error in delete operation
  const [deleteError, setDeleteError] = React.useState("");

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //state to display snackbar success
  const [success, setSuccess] = React.useState(false);

  //state to display alert dialog prompting the user if they really want to delete the selected records
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  //fetching data sent from previous page
  const [companySuccess, setCompanySuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  //fetching data from redux
  const companyID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  const typeChange = (type) => {
    // to change the value returned from the database to displayed value for the company type
    if (type === "L") {
      return "Levven";
    } else if (type === "B") {
      return "Builder";
    } else if (type === "I") {
      return "Installer";
    } else if (type === "R") {
      return "Reseller";
    }
  };

  const fetchFilterData = () => {
    //get the values to display in filters
    countryServices
      .getCountries()
      .then((response) => {
        setCountryInformation(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    accountServices
      .getLevvenSales({ GetAssignedLevenUsers: true })
      .then((response) => {
        setLevvenSales(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const getDataFromDatabase = () => {
    //submit and get the data to be displayed according to filters from the database

    //we are filling the filter data we need since the filter data is saved as objects
    var array = [];

    saveCountries.forEach((country) => {
      return array.push(country.countryID);
    });

    var array2 = [];

    saveTypes.forEach((type) => {
      return array2.push(type.id);
    });

    var array3 = [];

    saveLevvenSales.forEach((levvenSale) => {
      return array3.push(levvenSale.user.userID);
    });

    var array4 = [];

    saveStatus.forEach((status) => {
      var value = "";
      if (status === "Active") {
        value = "A";
      } else {
        value = "H";
      }
      return array4.push(value);
    });

    //fetching the data
    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage },
      Filters: {
        Name: nameSearch,
        Types: array2,
        Countries: array,
        LevvenSales: array3,
        Status: array4,
      },
      ExcludedCompanyID: companyID,
      orderDirection: orderDirection,
      orderColumn: orderColumn,
    };

    companyServices
      .getCompanies(data)
      .then((response) => {
        setPageLoad(false);

        setNumberRecords(response.data.totalNumberOfRecords);

        setRows(response.data.companies);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    //we get the data for the rows as well as the filters

    if (companySuccess === true) {
      //to display success snackbar if record deleted from tabs
      setSuccess(true);
      setCompanySuccess(false);
    }
    setRows([]);
    window.scrollTo(0, 0);
    setError("");
    setSelected([]);
    setCountryInformation([]);
    setPageLoad(true);

    fetchFilterData();
    getDataFromDatabase();
  }, [page, rowsPerPage, resubmit]);

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //function to select all records in table
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.companyID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const submitFilters = (event) => {
    //we submit filters
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
    setSelected([]);
  };

  const deleteCompanies = () => {
    //delete companies based on array of their ID's

    const data = { Companies: selected };
    setDeleteRecord(false);
    companyServices
      .deleteCompanies(data)
      .then((response) => {
        setLoading(false);
        setResubmit(!resubmit);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
      });
  };

  const viewCompanyData = (companyID, companyName, countryID, countryCode) => {
    //we are taken to a page to view company data
    history.push({
      pathname: "/company-information",
      state: {
        path: "company-information",
        companyID: companyID,
        companyName: companyName,
        source: "Companies",
        countryID: countryID,
        countryCode: countryCode,
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    //function to handle page change in table
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //change rows per page in table
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearFilterData = () => {
    //function to clear filter data
    setNameSearch("");
    setSaveTypes([]);
    setSaveCountries([]);
    setSaveLevvenSales([]);
    setSaveStatus([]);
    setResubmit(!resubmit);
  };

  const addUser = () => {
    //we are redirected to user creation page
    history.push({
      pathname: "/create-user",
      state: {
        companyID: selected[0],
      },
    });
  };

  //check if row is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      path: "company-information",
      companyID: contextMenuData.companyID,
      companyName: contextMenuData.name,
      source: "Companies",
      countryID: contextMenuData.country.countryID,
      countryCode: contextMenuData.country.countryCode,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/company-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Companie(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Company Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteCompanies}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Companies
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                {rolePermission === "M" ? (
                  <>
                    <CustomButton
                      startIcon={<Filter />}
                      size="medium"
                      tableButton={true}
                      toggle={() => setApplyFilters(!applyFilters)}
                    >
                      {applyFilters ? "Hide Filters" : "Show Filters"}
                    </CustomButton>
                    {selected.length === 1 ? (
                      <CustomButton
                        startIcon={<UserPlus />}
                        size="medium"
                        tableButton={true}
                        loading={loading}
                        toggle={addUser}
                      >
                        New User
                      </CustomButton>
                    ) : (
                      ""
                    )}

                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      toggle={() => setDeleteRecord(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <>
                    <Link
                      to="/create-company"
                      style={{ textDecoration: "none" }}
                    >
                      <CustomButton
                        startIcon={<Plus />}
                        size="medium"
                        tableButton={true}
                      >
                        New Company
                      </CustomButton>
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div
              style={{
                backgroundColor: "#EFEFF0",
                paddingTop: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilters}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        value={nameSearch}
                        onChange={(e) => setNameSearch(e.target.value)}
                        size="small"
                        label="Company Name"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                          autoComplete: "new-password",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={types}
                        fullWidth
                        value={saveTypes}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          setSaveTypes(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={countryInformation}
                        fullWidth
                        value={saveCountries}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, value) => {
                          setSaveCountries(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Office Country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={levvenSales}
                        fullWidth
                        value={saveLevvenSales}
                        getOptionLabel={(option) =>
                          option.user.firstName + " " + option.user.lastName
                        }
                        onChange={(event, value) => {
                          setSaveLevvenSales(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Levven Sales"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={["Active", "On Hold"]}
                        fullWidth
                        value={saveStatus}
                        onChange={(event, value) => {
                          setSaveStatus(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no Companies to display.{" "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push({ pathname: "/create-company" })
                          }
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.companyID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        tabIndex={-1}
                        key={row.companyID}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {rolePermission === "M" ? (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.companyID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={() =>
                            viewCompanyData(
                              row.companyID,
                              row.name,
                              row.country.countryID,
                              row.country.countryCode
                            )
                          }
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCompanyData(
                              row.companyID,
                              row.name,
                              row.country.countryID,
                              row.country.countryCode
                            )
                          }
                        >
                          {typeChange(row.type)}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCompanyData(
                              row.companyID,
                              row.name,
                              row.country.countryID,
                              row.country.countryCode
                            )
                          }
                        >
                          {row.country.description}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCompanyData(
                              row.companyID,
                              row.name,
                              row.country.countryID,
                              row.country.countryCode
                            )
                          }
                        >
                          {row.levvenSales
                            ? row.levvenSales.firstName +
                              " " +
                              row.levvenSales.lastName
                            : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          align="center"
                          onClick={() =>
                            viewCompanyData(
                              row.companyID,
                              row.name,
                              row.country.countryID,
                              row.country.countryCode
                            )
                          }
                        >
                          <span
                            style={{
                              marginLeft: "-20px",
                              padding: "5px 10px",
                              height: "30px",
                              width: "100px",
                              borderRadius: "25px",
                              backgroundColor:
                                row.status === "A"
                                  ? "rgba(29, 148, 75, 0.2)"
                                  : "rgba(150, 150, 150, 0.3)",
                              color:
                                row.status === "A"
                                  ? "rgba(29, 148, 75, 1)"
                                  : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {row.status === "A" ? "Active" : "On Hold"}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
