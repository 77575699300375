import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Compass } from "react-feather";
import { countryServices } from "../../services/country.services";
import Underline from "../Underline/Underline";

const ProjectDetailStep = ({
  setDirty,
  jobName,
  setJobName,
  jobNameError,
  setJobNameError,
  jobNbr,
  setJobNbr,
  jobNbrError,
  setJobNbrError,
  lotNbr,
  setLotNbr,
  lotNbrError,
  setLotNbrError,
  instStreet,
  setInstStreet,
  instStreetError,
  setInstStreetError,
  instStreet2,
  setInstStreet2,
  instCity,
  setInstCity,
  instCityError,
  setInstCityError,
  instCountry,
  setInstCountry,
  instCountryError,
  setInstCountryError,
  instStateProvince,
  setInstStateProvince,
  instStateProvinceError,
  setInstStateProvinceError,
  instZipCode,
  setInstZipCode,
  instZipCodeError,
  setInstZipCodeError,
  setOrderType,
  orderTypeError,
  setOrderTypeError,
  suggestions,
  addressValue,
  verifyAddress,
  fetchAddresses,
  addressError,
  setAddressError,
  setAddressPicked,
  setAddressValue,
  setAddressVerified,
  setAddressChange,
  setSaveError,
  orderID,
  addressVerificationLevel = null,
  setAddressChanged,
  countries,
  setCountries,
  stateProvinces,
  setStateProvinces,
  fetchCountries,
  fetchStateProvince,
}) => {
  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Project Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h6" component="span">
            Job Details
          </Typography>
          <Underline />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
        {/*Job Name */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Job Name"
            fullWidth
            required
            value={jobName}
            inputProps={{
              autoComplete: "new-password",
            }}
            error={jobNameError}
            helperText={jobNameError ? "Job Name field is empty" : ""}
            onChange={(e) => {
              setJobName(e.target.value);
              if (jobNameError) setJobNameError(false);
              setDirty();
            }}
            disabled={orderID !== 0}
          />
        </Grid>
        {/*Job Number */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Job Number"
            fullWidth
            required
            value={jobNbr}
            inputProps={{
              maxLength: 30,

              autoComplete: "new-password",
            }}
            error={jobNbrError}
            helperText={jobNbrError ? "Job Name field is empty" : ""}
            onChange={(e) => {
              setJobNbr(e.target.value);
              if (jobNbrError) setJobNbrError(false);
              setDirty();
            }}
            disabled={orderID !== 0}
          />
        </Grid>
        {/*Lot Number */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Lot Number"
            fullWidth
            required
            value={lotNbr}
            inputProps={{
              autoComplete: "new-password",
            }}
            error={lotNbrError}
            helperText={lotNbrError ? "Lot Number field is empty" : ""}
            onChange={(e) => {
              setLotNbr(e.target.value);
              if (lotNbrError) setLotNbrError(false);
              setDirty();
            }}
            disabled={orderID !== 0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" component="span">
              Installation Address
            </Typography>
            <Tooltip
              title={
                addressVerificationLevel === "AV11" ||
                addressVerificationLevel === "AV12" ||
                addressVerificationLevel === "AV13" ||
                addressVerificationLevel === "AV14"
                  ? "Address Partially Verified"
                  : addressVerificationLevel === "AV21" ||
                    addressVerificationLevel === "AV22" ||
                    addressVerificationLevel === "AV23" ||
                    addressVerificationLevel === "AV24" ||
                    addressVerificationLevel === "AV25"
                  ? "Address Fully Verified"
                  : "Address Not Verified"
              }
            >
              <span>
                <IconButton disabled>
                  <Compass
                    color={
                      addressVerificationLevel === "AV11" ||
                      addressVerificationLevel === "AV12" ||
                      addressVerificationLevel === "AV13" ||
                      addressVerificationLevel === "AV14"
                        ? "#FEBF11"
                        : addressVerificationLevel === "AV21" ||
                          addressVerificationLevel === "AV22" ||
                          addressVerificationLevel === "AV23" ||
                          addressVerificationLevel === "AV24" ||
                          addressVerificationLevel === "AV25"
                        ? "#98C848"
                        : "#E63846"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <Underline />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={6}>
            {/*Installation Country*/}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                componentName="Field1"
                id="combo-box-demo"
                options={countries}
                value={instCountry ? instCountry : ""}
                getOptionLabel={(option) => (option ? option.description : "")}
                onChange={(e, value) => {
                  if (instCountry?.description !== value?.description) {
                    setInstStateProvince();
                  }

                  setAddressChange("");
                  setAddressPicked(null);
                  setAddressValue("");
                  setInstCountry(value);
                  setAddressChanged(true);
                  if (instCountryError) setInstCountryError(false);
                  setDirty();
                }}
                disabled={orderID !== 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="Field1"
                    label="Country"
                    required
                    fullWidth
                    size="small"
                    error={instCountryError}
                    helperText={
                      instCountryError
                        ? "Installation Country field is empty"
                        : ""
                    }
                    type="text"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Autocomplete
                freeSolo
                componentName="Field22"
                autoSelect
                options={suggestions.map(
                  (options) =>
                    options?.address1 +
                    ", " +
                    options?.city +
                    ", " +
                    options?.stateProvince?.stateProvinceCode +
                    " " +
                    options?.zipPostCode
                )}
                value={addressValue}
                disabled={
                  instCountry === null ||
                  instCountry === undefined ||
                  instCountry === {} ||
                  orderID !== 0
                }
                onChange={(event, newValue) => {
                  if (verifyAddress === true) fetchAddresses(newValue);
                  if (addressError) setAddressError(false);

                  var index = suggestions.findIndex(
                    (x) =>
                      x?.address1 +
                        ", " +
                        x?.city +
                        ", " +
                        x?.stateProvince?.stateProvinceCode +
                        " " +
                        x?.zipPostCode ===
                      newValue
                  );

                  setDirty();
                  if (index !== -1) {
                    setAddressChanged(false);
                    setAddressPicked(suggestions[index]);

                    setAddressValue(
                      suggestions[index].address1 +
                        ", " +
                        suggestions[index].city +
                        ", " +
                        suggestions[index].stateProvince.stateProvinceCode +
                        " " +
                        suggestions[index].zipPostCode
                    );
                    setAddressVerified(true);
                  } else {
                    setAddressVerified(false);
                    setAddressPicked(null);
                    setAddressChanged(true);
                    setAddressValue(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Address"
                    size="small"
                    componentName="Field222"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    error={addressError}
                    onChange={(newValue) => {
                      setAddressChange(newValue.target.value);
                      if (verifyAddress === true)
                        fetchAddresses(newValue.target.value);
                    }}
                    helperText={
                      addressError
                        ? "Address field is empty. Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                        : "Address Format: [Address1], [City], [State Province] [Zip Post Code]"
                    }
                    disabled={
                      instCountry === null ||
                      instCountry === undefined ||
                      instCountry === {} ||
                      orderID !== 0
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectDetailStep;
