import React, { useEffect } from "react";
import CompaniesTableComponent from "../../../components/CompaniesTableComponent/CompaniesTableComponent";

const CompaniesTable = ({ setPageLink, setPageID }) => {
  useEffect(() => {
    setPageLink("Companies");

    setPageID(6);
  }, []);

  return (
    <div>
      <CompaniesTableComponent />
    </div>
  );
};

export default CompaniesTable;
