import * as React from "react";
import Popover from "@mui/material/Popover";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconButton, Paper, TextField } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { makeStyles } from "@mui/styles";
import moment from "moment";

export const CustomDatePickerComponent = ({
  label = "",
  value = null,
  setValue,
  inOrder = false,
  setDateInTabs,
  defaultCalendarMonth = null,
  minDate = null,
  setDirty,
  setFormChanged,
  disabled = false,
  inOrderTabs = false,
  error = "",
  setError,
  helperText = "",
  inCreateOrder = false,
  error2 = false,
  setError2,
  isRMA = false,
}) => {
  const buttonRef1 = React.useRef(null);

  const [open1, setOpen1] = React.useState(false);

  const handleClick1 = (event) => {
    setOpen1((prev) => !prev);
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
          variant="outlined"
          size="small"
          label={label}
          fullWidth
          disabled={disabled}
          ref={buttonRef1}
          error={error || error2}
          helperText={
            helperText === ""
              ? error2 === true
                ? isRMA === false
                  ? "Requested Delivery Date field is empty"
                  : "Delivery Date field is empty"
                : error
              : helperText
          }
          onClick={handleClick1}
          value={value !== null ? value : ""}
          onKeyPress={(event) => {
            const okArray = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "0",
              "/",
            ];
            if (okArray.includes(event.key) === false) {
              event.preventDefault();
            }
          }}
          onChange={(date) => {
            if (date.target.value === "") setValue(null);
            else setValue(date.target.value);

            if (inOrderTabs === true) {
              if (error) {
                setError(false);
              }
            }
            if (inOrder === true) {
              if (inCreateOrder === false) {
                setFormChanged(true);
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                if (date.target.value === "") setDateInTabs(null);
                else setDateInTabs(date.target.value);
              }
              setDirty();

              if (error) {
                setError(inCreateOrder === false ? "" : false);
              }

              if (inCreateOrder === false && error2 === true) {
                setError2(false);
              }
            }
          }}
          inputProps={{
            autoComplete: "new-password",
          }}
          InputProps={{
            endAdornment: (
              <IconButton sx={{ marginRight: "-14px" }} onClick={() => 0}>
                <EventIcon />
              </IconButton>
            ),
          }}
        />

        <Popover
          open={open1}
          anchorEl={buttonRef1.current}
          onClose={() => setOpen1(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Paper>
            <StaticDatePicker
              autoFocus
              minDate={minDate}
              defaultCalendarMonth={defaultCalendarMonth}
              displayStaticWrapperAs="desktop"
              value={value}
              onChange={(newValue) => {
                const formattedDate = moment(newValue).isValid()
                  ? moment(newValue).format("MM/DD/YYYY")
                  : "Invalid Date Format";

                setValue(formattedDate);

                setOpen1(false);

                if (inOrderTabs === true) {
                  if (error) {
                    setError(false);
                  }
                }

                if (inOrder === true) {
                  if (inCreateOrder === false) {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDateInTabs(formattedDate);
                  }
                  setDirty();
                  if (error) {
                    setError(inCreateOrder === false ? "" : false);
                  }

                  if (inCreateOrder === false && error2 === true) {
                    setError2(false);
                  }
                }
              }}
              renderInput={() => null}
            />
          </Paper>
        </Popover>
      </LocalizationProvider>
    </div>
  );
};
