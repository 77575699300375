import { Paper, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import UserForm from "../../../components/UserForm/UserForm";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Save, X } from "react-feather";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateUserPage = ({ setPageID, setPageLink }) => {
  const history = useHistory();
  const location = useLocation();

  //getting values from redux
  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  const companyID = location.state
    ? location.state.companyID
      ? location.state.companyID
      : 0
    : 0;

  /*Redirection*/
  if (
    (companyID === 0 && clientUsers !== "M") ||
    (companyID !== 0 && ownUsers !== "M")
  ) {
    history.push("/dashboard");
  }

  const [companyAdmin, setCompanyAdmin] = useState(false);
  const [adminManage, setAdminManage] = useState(false);
  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate(companyID !== 0 && companyName === "" ? "/my-users" : "users");
  
    } else {
  
      history.push(companyID !== 0 && companyName === "" ? "/my-users" : "users");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  /*User Form Ref*/
  const userRef = useRef(null);

  useEffect(() => {
    //for help button
    setPageLink("Users");

    //for drawer menu
    if (companyID === compID) {
      setPageID(1);
    } else {
      setPageID(7);
    }
  }, []);

  /*Get Client Roles permission from Redux*/
  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  /*Get Own Roles permission from Redux*/
  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.role.companyID : ""
  );

  const companyName = location.state
    ? location.state.companyName
      ? location.state.companyName
      : ""
    : "";

  useEffect(() => {
    if (clientRoles === "M") {
      setAdminManage(true);
    }
    if (ownRoles === "M") {
      setCompanyAdmin(true);
    }
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">

        {companyID !== 0 && companyName === "" ? (
            <Link
              underline="always"
              key="1"
              color="inherit"
              onClick={() => handleItemClick()}
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              My Users
            </Link>
          ) : (
            <Link
              underline="always"
              key="1"
              color="inherit"
              onClick={() => handleItemClick()}
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              Users
            </Link>
          )}
          
          <Typography key="5" color="text.primary" sx={{ fontSize: "24px" }}>
            New User
          </Typography>
        </Breadcrumbs>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CustomButton
            startIcon={<Save />}
            toggle={() => userRef.current.checkUserNotifInvoices()}
            loading={tabButtonLoading}
          >
            Save
          </CustomButton>
          <div style={{ width: "10px" }} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await userRef.current.setPristine();
              history.push(companyID === 0 ? "/Users" : "/my-users");
            }}
          >
            Cancel
          </CustomButton>
        </div>
      </div>
      <div style={{ height: "40px" }} />
      <UserForm
        create={true}
        adminManage={adminManage}
        receivedCompanyID={companyID}
        companyAdmin={companyAdmin}
        ref={userRef}
        setTabButtonLoading={setTabButtonLoading}
        setFormChanged={setFormChanged}
      />
    </Paper></>
  );
};

export default CreateUserPage;
