import React, { useState, useEffect } from "react";
import { systemDataServices } from "../services/systemData.services";
import { melissaServices } from "../services/melissa.services";

const reCaptchaLink = "https://www.google.com/recaptcha/api.js?render=";

export function useReCaptcha() {
  const [siteKey, setSiteKey] = useState("");
  const [v2SiteKey, setV2SiteKey] = useState("");
  const [checkRecaptchaV2, setCheckRecaptchaV2] = useState(false);
  const [error, setError] = useState("");
  const [v2Token, setV2Token] = useState("");

  const getRecaptchaKey = () => {
    setError("");
    systemDataServices
      .getRecaptchaKey()
      .then((response) => {
        // Add reCaptcha
        const script = document.createElement("script");
        script.src = reCaptchaLink + response.data.recaptchaKey;
        document.body.appendChild(script);

        setSiteKey(response.data.recaptchaKey);
        setV2SiteKey(response.data.recaptchaV2Key);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const checkReCaptchaV3Token = async (newToken) => {
    setError("");
    var valueToBeReturned = false;
    await melissaServices
      .checkRecaptchaV3({ Token: newToken })
      .then((response) => {
        valueToBeReturned = response.data;
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        valueToBeReturned = false;
      });
    return valueToBeReturned;
  };

  const checkReCaptchaV2Token = async () => {
    setError("");
    var valueToBeReturned = false;
    await melissaServices
      .checkRecaptchaV2({ Token: v2Token })
      .then((response) => {
        valueToBeReturned = response.data;
        if (response.data === false) {
          setV2Token("");
        }
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setV2Token("");
        valueToBeReturned = false;
      });
    return valueToBeReturned;
  };

  const handleReCaptchaV2Change = async (newToken) => {
    setV2Token(newToken);
  };

  useEffect(() => {
    getRecaptchaKey();
  }, []);

  return [
    siteKey,
    v2SiteKey,
    v2Token,
    error,
    setError,
    checkRecaptchaV2,
    setCheckRecaptchaV2,
    checkReCaptchaV3Token,
    handleReCaptchaV2Change,
    checkReCaptchaV2Token,
  ];
}
