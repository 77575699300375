import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import { useHistory } from "react-router";
import logo from "../../assets/Levven_logo.png";
import { accountServices } from "../../services/account.services";
import { Alert } from "@mui/material";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import CustomAlertSuccess from "../../components/CustomAlertSuccess/CustomAlertSuccess";
import { Link } from "react-router-dom";
import { Info } from "react-feather";
import { useDispatch } from "react-redux";
import { userTypes } from "../../redux/types/userTypes";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { systemDataServices } from "../../services/systemData.services";
import { useReCaptcha } from "../../hooks/useReCaptcha";
import ReCaptchaText from "../../reCaptcha";
const ResetPasswordPage = ({ source }) => {
  /*Local variables*/
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userActivated, setUserActivated] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [errorOnStart, setErrorOnStart] = useState("");
  const [
    siteKey,
    v2SiteKey,
    v2Token,
    reCaptchaError,
    setReCaptchaError,
    checkReCaptchaV2,
    setCheckRecaptchaV2,
    checkReCaptchaV3Token,
    handleReCaptchaV2Change,
    checkReCaptchaV2Token,
  ] = useReCaptcha();
  var _reCaptchaRef = React.createRef();
  const params = new URLSearchParams(window.location.search);
  const receivedToken = params.get("token");
  const receivedEmail = params.get("email");
  const history = useHistory();
  /*Declare dispatch to dispatch redux actions*/
  const dispatch = useDispatch();
  /*Redirection condition if the user tries to access the reset password page without a token*/
  if (receivedToken === null) {
    history.push("/login");
  }
  //to change title of page
  useEffect(() => {
    document.title = "Levven Electronics - Customer Portal - Reset Password";
  }, []);
  /*Get user data from api and save them in redux store*/
  const getUserData = (data) => {
    return (dispatch) => {
      accountServices
        .login(data)
        .then((response) => {
          if (response) {
            setError("");
            dispatch({
              type: userTypes.GET_USER_DATA,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setLoading(false);
          return Promise.reject(error);
        });
    };
  };
  /*search cookie based on a string*/
  const searchCookie = async (name) => {
    let str = document.cookie;
    str = str.split("; ");
    const result = {};
    for (let i in str) {
      const cur = str[i].split("=");
      result[cur[0]] = cur[1];
    }
    return result[name];
  };
  /*Check inputs and sign in*/
  const signIn = async () => {
    if (receivedEmail === "" || !validator.isEmail(receivedEmail))
      setError(
        "Email not received well. Please ask your company admin to reactivate your account"
      );
    if (password === "") setPasswordError(true);
    if (confirmPassword === "") setConfirmPasswordError(true);
    if (
      receivedEmail !== "" &&
      password !== "" &&
      validator.isEmail(receivedEmail) &&
      confirmPassword !== ""
    ) {
      setLoading(true);
      const deviceTokenCookie = await searchCookie(
        "Levven-Device-Token-" + receivedEmail
      );
      dispatch(
        getUserData({
          email: receivedEmail,
          password,
          deviceToken: deviceTokenCookie ? deviceTokenCookie : "",
        })
      );
    }
  };
  /*Check the inputs and reset the password*/
  const resetPassword = async (event) => {
    event.preventDefault();
    if (confirmPassword === "" || password === "") {
      if (confirmPassword === "") setConfirmPasswordError(true);
      if (password === "") setPasswordError(true);
    } else if (password !== confirmPassword) {
      setError("The passwords you entered do not match");
    } else if (checkReCaptchaV2 === false) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(siteKey, { action: "login" })
          .then(async function (token) {
            setLoading(true);
            var result = await checkReCaptchaV3Token(token);
            if (result === true) {
              accountServices
                .resetPassword({
                  Token: receivedToken,
                  Password: password,
                  ConfirmPassword: confirmPassword,
                })
                .then(async (response) => {
                  setError("");
                  setSuccessMessage(true);
                  setLoading(false);
                  await signIn();
                  // history.push({
                  //   pathname: "/login",
                  //   state: {
                  //     email: receivedEmail,
                  //   },
                  //});
                })
                .catch((error) => {
                  if (checkReCaptchaV2 === true) window.grecaptcha.reset();
                  setError(error.response.data.error.error);
                  setSuccessMessage(false);
                  setLoading(false);
                });
            } else {
              setLoading(false);
              setCheckRecaptchaV2(true);
            }
          });
      });
    } else if (checkReCaptchaV2 === true && v2Token !== "") {
      setLoading(true);
      var result = await checkReCaptchaV2Token();
      if (result === true) {
        accountServices
          .resetPassword({
            Token: receivedToken,
            Password: password,
            ConfirmPassword: confirmPassword,
          })
          .then(async (response) => {
            setError("");
            setSuccessMessage(true);
            setLoading(false);
            await signIn();
            // history.push({
            //   pathname: "/login",
            //   state: {
            //     email: receivedEmail,
            //   },
            //});
          })
          .catch((error) => {
            setError(error.response.data.error.error);
            setSuccessMessage(false);
            setLoading(false);
          });
      } else {
        setLoading(false);
        window.grecaptcha.reset();
      }
    }
  };
  const checkValidToken = async () => {
    setPageLoading(true);
    await accountServices
      .checkTokenActive({ Token: receivedToken })
      .then((response) => {
        setPageLoading(false);
      })
      .catch((error) => {
        setErrorOnStart(error.response.data.error.error);
        setPageLoading(false);
      });
  };
  const checkActivatedUserAndValidToken = async () => {
    //check if user active
    setPageLoading(true);
    await accountServices
      .checkIfUserActive({ Email: receivedEmail })
      .then(async (response) => {
        setUserActivated(response.data);
        //check if token is active
        await accountServices
          .checkTokenActive({ Token: receivedToken })
          .then((response) => {
            setPageLoading(false);
          })
          .catch((error) => {
            setErrorOnStart(error.response.data.error.error);
            setPageLoading(false);
          });
      })
      .catch((error) => {
        setErrorOnStart(error.response.data.message);
        setPageLoading(false);
      });
  };
  useEffect(() => {
    if (source === "activation") {
      checkActivatedUserAndValidToken();
    } else {
      checkValidToken();
    }
  }, []);
  /*Render function*/
  return pageLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <CircularProgress sx={{ color: "#0077c6" }} />
    </Grid>
  ) : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="100vh"
    >
      <CustomPaper>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: "20px" }}
        >
          <img
            src={logo}
            alt="Levven Logo"
            style={{
              objectFit: "contain",
              width: "50%",
            }}
          />
        </Grid>
        <form onSubmit={resetPassword}>
          <Grid container alignSelf="center" spacing={4}>
            {userActivated === "A" ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomAlertSuccess>
                    User Already Activated
                  </CustomAlertSuccess>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color={loading ? "rgba(0, 0, 0, 0.12)" : "#0077c6"}
                      disabled={loading ? true : false}
                    >
                      Login
                    </Typography>
                  </Link>
                </Grid>
              </>
            ) : userActivated === "I" ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomAlert>
                    This user has been deactivated! Contact your system
                    administrator to reactivate it.
                  </CustomAlert>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color={loading ? "rgba(0, 0, 0, 0.12)" : "#0077c6"}
                      disabled={loading ? true : false}
                    >
                      Login
                    </Typography>
                  </Link>
                </Grid>
              </>
            ) : (
              <>
                {errorOnStart ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        align="left"
                        variant="h6"
                        color="#303030"
                        style={{ margin: "auto 0" }}
                      >
                        {source === "resetPassword"
                          ? "Reset Your Password"
                          : "Create Your Password"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <CustomAlert>{errorOnStart}</CustomAlert>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ height: "6rem" }}
                    >
                      <TextField
                        variant="outlined"
                        label="Password"
                        fullWidth
                        size="medium"
                        error={passwordError}
                        value={password}
                        helperText={
                          passwordError ? "Please enter a new password" : null
                        }
                        disabled={successMessage || loading ? true : false}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (passwordError) {
                            setPasswordError(false);
                          }
                        }}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        label="Confirm Password"
                        fullWidth
                        error={confirmPasswordError}
                        value={confirmPassword}
                        helperText={
                          confirmPasswordError
                            ? "Please confirm your password"
                            : null
                        }
                        disabled={successMessage || loading ? true : false}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (confirmPasswordError)
                            setConfirmPasswordError(false);
                        }}
                        type="password"
                      />{" "}
                      <ReCaptchaText />
                    </Grid>
                    {checkReCaptchaV2 === true ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ReCAPTCHA
                          type="image"
                          theme="light"
                          ref={_reCaptchaRef}
                          sitekey={v2SiteKey}
                          onChange={handleReCaptchaV2Change}
                        />
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ height: "4rem" }}
                    >
                      {successMessage ? null : (
                        <CustomButton
                          disable={siteKey === ""}
                          fullWidth
                          loading={loading}
                          type="submit"
                        >
                          {source === "resetPassword" ? "Reset" : "Create"}
                        </CustomButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      textAlign="left"
                    >
                      <Typography variant="caption" component="span">
                        <span style={{ whiteSpace: "pre-line" }}>
                          <strong> Password requirements:</strong> {"\n"} -
                          Minimum 6 characters
                          {"\n"} - At least 1 uppercase and 1 lowercase letter{" "}
                          {"\n"} - At least 1 number and 1 symbol ($&?)
                          character
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      {reCaptchaError ? (
                        <CustomAlert>{reCaptchaError}</CustomAlert>
                      ) : error ? (
                        <CustomAlert>{error}</CustomAlert>
                      ) : successMessage ? (
                        <CustomAlertSuccess>
                          {source === "resetPassword"
                            ? "Password updated successfully"
                            : "Password created successfully"}
                        </CustomAlertSuccess>
                      ) : null}
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </form>
      </CustomPaper>
    </Grid>
  );
};
export default ResetPasswordPage;
