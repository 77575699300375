import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { agreementServices } from "../../services/agreement.services";
import { useHistory } from "react-router";
import SnackBar from "../SnackBar/SnackBar";
import { useSelector } from "react-redux";
import { takeoffServices } from "../../services/takeoff.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";

const TakeoffForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      receivedTakeoffID,
      setTakeoffNameForTabs,
      setFormChanged,
      duplicate = false,
      agreementID = 0,
    },
    ref
  ) => {
    /*Takeoff Object*/
    const [takeoff, setTakeoff] = useState();

    /*Takeoff Name*/
    const [takeoffName, setTakeoffName] = useState("");
    const [takeoffNameError, setTakeoffNameError] = useState(false);

    /*Agreement*/
    const [agreements, setAgreements] = useState([]);
    const [agreement, setAgreement] = useState("");
    const [agreementError, setAgreementError] = useState(false);
    const [builder, setBuilder] = useState("");
    const [installer, setInstaller] = useState("");
    const [switchColor, setSwitchColor] = useState("");
    const [switchStyle, setSwitchStyle] = useState("");
    const [coated, setCoated] = useState("");

    /*Community*/
    const [community, setCommunity] = useState("");
    const [communityError, setCommunityError] = useState(false);

    /*Plan Number*/
    const [planNbr, setPlanNbr] = useState("");
    const [planNbrError, setPlanNbrError] = useState(false);

    /*Plan Name*/
    const [planName, setPlanName] = useState(null);
    const [planNameError, setPlanNameError] = useState(false);

    /*Area*/
    const [area, setArea] = useState("");
    const [areaError, setAreaError] = useState(false);

    /*Area Unit Of Measure*/
    const [areaUnits, setAreaUnits] = useState(["Square Feet", "Square Meter"]);
    const [areaUnit, setAreaUnit] = useState("");
    const [areaUnitError, setAreaUnitError] = useState(false);

    /*Total Number of Swicthes*/
    const [nbOfSwitches, setNbOfSwitches] = useState("");
    const [nbOfSwitchesError, setNbOfSwitchesError] = useState(false);

    /*Total Number of Swicth Plates*/
    const [nbOfBathrooms, setNbOfBathrooms] = useState("");
    const [nbOfBathroomsError, setNbOfBathroomsError] = useState(false);

    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    /**for IsALowVoltageCabinetStandardInThisPlan */
    const [
      isALowVoltageCabinetStandardInThisPlan,
      setIsALowVoltageCabinetStandardInThisPlan,
    ] = useState(false);

    /*SnackBar open handling*/
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const history = useHistory();

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );

    /*Get agreement permission from redux*/
    const takeoffPermission = useSelector((state) =>
      state.user.user ? state.user.user.user.role.takeoff : ""
    );

    /*Get company ID from redux*/
    const currentCompanyID = useSelector((state) =>
      state.user.user ? state.user.user.user.company.companyID : ""
    );

    /*Get company type from redux*/
    const currentCompanyType = useSelector((state) =>
      state.user.user ? state.user.user.user.company.type : ""
    );

    /*Redirection if no permission is accessed*/
    if (
      (adminManage && receivedTakeoffID === "") ||
      takeoffPermission === "N"
    ) {
      history.push("/dashboard");
    }

    /*Functions*/

    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnackBar(false);
    };

    /*Fetch Agreement data by ID*/
    const fetchTakeoffDataById = () => {
      setLoading(true);
      takeoffServices
        .getTakeoffByID(receivedTakeoffID)
        .then((response) => {
          const takeoffData = response.data;
          setTakeoff(response.data);
          setAgreement(takeoffData.agreement);
          setBuilder(takeoffData.agreement.builder?.name);
          setInstaller(takeoffData.agreement.installer?.name);
          setSwitchColor(takeoffData.agreement.defaultSwitchColor?.description);
          setSwitchStyle(takeoffData.agreement.defaultSwitchStyle?.description);
          setCoated(takeoffData.agreement.coatedSwitch + "");
          setCommunity(takeoffData.community);
          setPlanNbr(takeoffData.planNum);
          setPlanName(takeoffData.planName);
          setArea(takeoffData.area);
          setIsALowVoltageCabinetStandardInThisPlan(
            takeoffData.isALowVoltageCabinetStandardInThisPlan
          );
          setAreaUnit(
            takeoffData.areaUOM === "ft2" ? "Square Feet" : "Square meter"
          );
          setNbOfSwitches(takeoffData.bathroomSwitchesNum);
          setNbOfBathrooms(takeoffData.bathroomNum);
          setLoading(false);
        })
        .catch((error) => setSaveError(error.response.data.error.error))
        .finally(() => {
          setButtonLoading(false);
        });
    };

    /*Save Changes*/
    const save = (event) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      /*Conditions to check if there are any errors*/
      if (takeoffName === "") setTakeoffNameError(true);
      if (!agreement || agreement === null) setAgreementError(true);
      if (planNbr === "") setPlanNbrError(true);
      if (area === "") setAreaError(true);
      if (areaUnit === "") setAreaUnitError(true);
      if (nbOfSwitches === "") setNbOfSwitchesError(true);
      if (nbOfBathrooms === "") setNbOfBathroomsError(true);
      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        takeoffName === "" ||
        !agreement ||
        agreement === null ||
        planNbr === "" ||
        area === "" ||
        areaUnit === "" ||
        nbOfSwitches === "" ||
        nbOfBathrooms === ""
      ) {
        setButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        if (!create) {
          /*If the page is in edit mode*/
          takeoffServices
            .updateTakeoff(takeoff.takeoffID, {
              Name: takeoffName,
              AgreementID: agreement?.agreementID,
              Community: community === "" ? "N/A" : community,
              PlanNum: planNbr,
              PlanName: planName,
              Area: area,
              AreaUOM: areaUnit === "Square Feet" ? "ft2" : "m2",
              BathroomSwitchesNum: nbOfSwitches,
              BathroomNum: nbOfBathrooms,
              ModifiedByID: currentUserID,
              IsALowVoltageCabinetStandardInThisPlan:
                isALowVoltageCabinetStandardInThisPlan,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setButtonLoading(false);
              setOpenSnackBar(true);
              setSaveError("");
              fetchTakeoffDataById(response.data);
              setTakeoffNameForTabs(takeoffName);
            })
            .catch((error) => {
              setSaveError(error.response.data.message);
              setButtonLoading(false);
            });
        } else if (create || duplicate) {
          /*If the page is in create mode*/
          takeoffServices
            .createTakeoff({
              Name: takeoffName,
              AgreementID: agreement?.agreementID,
              Community: community === "" ? "N/A" : community,
              PlanNum: planNbr,
              PlanName: planName,
              Area: area,
              AreaUOM: areaUnit === "Square Feet" ? "ft2" : "m2",
              BathroomSwitchesNum: nbOfSwitches,
              BathroomNum: nbOfBathrooms,
              CreatedByID: currentUserID,
              IsALowVoltageCabinetStandardInThisPlan:
                isALowVoltageCabinetStandardInThisPlan,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setButtonLoading(false);
              setOpenSnackBar(true);
              setSaveError("");
              const result = response.data;
              if (duplicate) {
                takeoffQuantityServices
                  .duplicateTakeoffQuantities({
                    OldTakeoffID: receivedTakeoffID,
                    NewTakeoffID: response.data.takeoffID,
                  })
                  .then(() => {
                    // history.push({
                    //   pathname: "/takeoff-information",
                    //   state: {
                    //     id: result.takeoffID,
                    //     name: result.name,
                    //     doesTakeoff:
                    //       result.whoDoesTakeoff === currentCompanyType
                    //         ? true
                    //         : currentCompanyType === "L"
                    //         ? true
                    //         : false,
                    //     agreementID: result.agreementID,
                    //   },
                    // });
                    history.push({
                      pathname: "/takeoff-product-quantities",
                      state: {
                        create: true,
                        agreementID: result.agreementID,
                        takeoffID: result.takeoffID,
                        takeoffName: result.name,
                      },
                    });
                  })
                  .catch((error) =>
                    setSaveError(error.response.data.error.error)
                  );
              } else {
                history.push({
                  pathname: "/takeoff-product-quantities",
                  state: {
                    create: true,
                    agreementID: response.data.agreementID,
                    takeoffID: response.data.takeoffID,
                    takeoffName: response.data.name,
                  },
                });
              }
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
            });
        }
      }
    };

    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        return (
          d.substring(8, 10) +
          "/" +
          d.substring(4, 7) +
          "/" +
          d.substring(13, 15) +
          " at " +
          d.substring(17, 21)
        );
      }
    };

    /*Get Agreements*/
    const getAllAgreements = () => {
      agreementServices
        .getAllAgreements(currentCompanyID)
        .then((response) => {
          setAgreements(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    const getAgreementByID = () => {
      agreementServices
        .getAgreementByID({ AgreementID: agreementID, IsEncrypted: false })
        .then((response) => {
          setAgreement(response.data.agreement);
        })
        .catch((error) => {
          setSaveError(error.response.data.erro.error);
        });
    };

    /*Initial useEffect*/
    useEffect(() => {
      if (!create || duplicate) {
        fetchTakeoffDataById();
      }

      if (agreementID !== 0) {
        getAgreementByID();
      }
      getAllAgreements();
    }, []);

    /*Everytime the agreement changes, values and dropdowns options change*/
    useEffect(() => {
      if (agreement !== null && agreement) {
        setBuilder(agreement.builder?.name);
        setInstaller(agreement.installer?.name);
        setSwitchColor(agreement.defaultSwitchColor?.description);
        setSwitchStyle(agreement.defaultSwitchStyle?.description);
        setCoated(agreement.coatedSwitch + "");
      }
    }, [agreement]);

    /*Concatinate the takeoff name*/
    useEffect(() => {
      setTakeoffName(
        (builder !== "" ? builder : "") +
          (community !== "" && community !== "N/A" ? " " + community : "") +
          (planName !== "" && planName !== null ? " " + planName : "") +
          (planNbr !== "" ? " " + planNbr : "")
      );
    }, [builder, community, planName, planNbr]);

    useImperativeHandle(ref, () => ({
      setPristine,
      save,
    }));

    return (
      <Paper elevation={0}>
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{
                  borderRight: "2px solid #c4c4c4",
                  paddingRight: "20px",

                  alignSelf: "flex-start",
                }}
              >
                {/*Form Grid*/}
                <form
                  autoComplete="off"
                  onSubmit={create || adminManage ? save : ""}
                >
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h6" component="span">
                        Takeoff Details
                      </Typography>
                      <Underline />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid> */}
                    {/*Agreements*/}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        id="combo-box-demo1"
                        options={agreements}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        value={agreement ? agreement : {}}
                        onChange={(event, value) => {
                          if (create) {
                            setAgreement(value);
                            if (agreementError) setAgreementError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create && !duplicate ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agreement"
                            fullWidth
                            size="small"
                            required
                            error={agreementError}
                            helperText={
                              agreementError ? "Agreement field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Community */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Community"
                        fullWidth
                        placeholder="N/A"
                        helperText={
                          "Name of development or subdivision, e.g. 'Camelot' or 'Arcadia'"
                        }
                        InputLabelProps={{ shrink: true }}
                        value={community}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={(e) => {
                          if (create || adminManage) {
                            setCommunity(e.target.value);
                            if (communityError) setCommunityError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/* Plan Name */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Model/Plan Name"
                        fullWidth
                        helperText={
                          "Name of floor plan from builder catalog e.g. 'Palisade' or 'Chateau'"
                        }
                        value={planName}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={(e) => {
                          if (create || adminManage) {
                            setPlanName(e.target.value);
                            if (planNameError) setPlanNameError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/* Plan Number */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Plan Number"
                        fullWidth
                        required
                        value={planNbr}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={(e) => {
                          if (create || adminManage) {
                            setPlanNbr(e.target.value);
                            if (planNbrError) setPlanNbrError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/* Area */}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Total Floor Area"
                        helperText={
                          "Square footage or square meters e.g. '1100' or '2500'"
                        }
                        type="number"
                        fullWidth
                        required
                        value={parseFloat(area)}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 10,
                          min: 0,
                          display: "decimal",
                          step: 0.01,
                        }}
                        onChange={(e) => {
                          if (
                            create ||
                            (adminManage && e.target.value.length < 10)
                          ) {
                            if (e.target.value < 0) {
                              setArea(
                                parseFloat(e.target.value * -1).toFixed(1)
                              );
                            } else {
                              setArea(parseFloat(e.target.value).toFixed(1));
                            }
                            if (areaError) setAreaError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/*Area Unit*/}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={areaUnits}
                        value={areaUnit ? areaUnit : ""}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            setAreaUnit(value);
                            if (areaUnitError) setAreaUnitError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Area Unit of Measure"
                            fullWidth
                            size="small"
                            required
                            error={areaUnitError}
                            helperText={
                              areaUnitError
                                ? "Area Unit of Measure field is empty"
                                : ""
                            }
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                    {/* Number of Bathrooms */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Number of Bathrooms"
                        helperText={
                          "Total count of full- and half-baths in the home"
                        }
                        fullWidth
                        required
                        type="number"
                        value={nbOfBathrooms}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 100,
                          min: 0,
                        }}
                        onChange={(e) => {
                          if (
                            create ||
                            (adminManage && e.target.value.length < 10)
                          ) {
                            if (e.target.value < 0) {
                              setNbOfBathrooms(e.target.value * -1);
                            } else {
                              setNbOfBathrooms(e.target.value);
                            }
                            if (nbOfBathroomsError)
                              setNbOfBathroomsError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/* Total Number of Switches in Bathrooms */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Total Number of Switches in Bathrooms"
                        fullWidth
                        required
                        type="number"
                        value={nbOfSwitches}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 10,
                          min: 0,
                        }}
                        onChange={(e) => {
                          if (
                            create ||
                            (adminManage && e.target.value.length < 10)
                          ) {
                            if (e.target.value < 0) {
                              setNbOfSwitches(e.target.value * -1);
                            } else {
                              setNbOfSwitches(e.target.value);
                            }
                            if (nbOfSwitchesError) setNbOfSwitchesError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        error={nbOfSwitchesError}
                        helperText={
                          nbOfSwitchesError
                            ? "Total Number of Switches in Bathrooms field is empty"
                            : "Sum of all wall switches in bathrooms"
                        }
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isALowVoltageCabinetStandardInThisPlan}
                            onClick={() => {
                              if (create || adminManage) {
                                setIsALowVoltageCabinetStandardInThisPlan(
                                  !isALowVoltageCabinetStandardInThisPlan
                                );
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }}
                            disabled={create || adminManage ? false : true}
                          />
                        }
                        label="Is a low-voltage cabinet standard in this plan?"
                      />
                    </Grid>
                    {/*Save button*/}
                    {create || adminManage ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <CustomButton
                          type="submit"
                          loading={buttonLoading}
                          startIcon={<Save />}
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Creation Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && takeoff
                          ? takeoff.creationDate !== null
                            ? convertUTC(takeoff.creationDate)
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Created By:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && takeoff
                          ? takeoff.createdBy !== null
                            ? takeoff.createdBy.firstName +
                              " " +
                              takeoff.createdBy?.lastName
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modification Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && takeoff
                          ? takeoff.modificationDate !== null
                            ? convertUTC(takeoff.modificationDate)
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modified By:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && takeoff
                          ? takeoff.modifiedBy !== null
                            ? takeoff.modifiedBy.firstName +
                              " " +
                              takeoff.modifiedBy?.lastName
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Total Number of Switches:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {takeoff?.switchesNum}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Total Number of Switch Plate Positions:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {takeoff?.switchPlatePositionsNum}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={7} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Builder:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={5} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {builder}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Installer:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {installer}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Default Switch Style:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {switchStyle}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Default Switch Color:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {switchColor}
                      </Typography>
                    </Grid>
                    {agreement &&
                    agreement !== null &&
                    agreement.coatedSwitch ? (
                      <>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="caption"
                            fontWeight={500}
                            component="span"
                          >
                            Coated:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography variant="caption" component="span">
                            Available
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {openSnackBar ? (
              <SnackBar
                open={openSnackBar}
                message="Saved Successfully"
                severity="success"
                handleClose={handleSnackBarClose}
              />
            ) : null}
            {saveError !== "" ? (
              <AlertSnackBar
                message={saveError}
                handleClose={handleCloseAlertSnackbar}
                open={saveError !== ""}
              />
            ) : null}
          </>
        )}
      </Paper>
    );
  }
);

export default TakeoffForm;
