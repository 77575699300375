import { useEffect, useState } from "react";
import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useHistory, useLocation } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  ListItemButton,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getHelpIdentifier } from "../../helpers/help-identifier";
import {
  Users,
  User,
  Key,
  Menu,
  Layout,
  Package,
  FileText,
  Tool,
  Truck,
  Home,
  HelpCircle,
  Database,
  Inbox,
  FileMinus,
} from "react-feather";
import { Icon } from "@iconify/react";
import logo from "../../assets/Levven_logo.png";
import logosmaller from "../../assets/Levven-smaller.png";
import { useSelector } from "react-redux";
import AccountMenu from "../../components/AccountMenu/AccountMenu";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import { CustomBanner } from "../CustomBanner/CustomBanner";
import { systemDataServices } from "../../services/systemData.services";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawerMenuBoxStyle: {
    maxWidth: "95%",
    minWidth: "50%",
    backgroundColor: "#f5f5f5",

    "@media(max-width: 700px)": {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
}));

/*functions specific for the drawer menu*/
const drawerWidth = 240;

//for drawer menu styles and functions
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerMenu = (props) => {
  const classes = useStyles();

  //selected value in drawer menu
  const [menuItemId, setMenuItemId] = useState(-1);

  //holds banner text
  const [bannerText, setBannerText] = useState("");

  //In case of errors
  const [error, setError] = useState("");

  //to display help dialog
  const [openHelp, setOpenHelp] = useState(false);

  //in case of error while fetching help text
  const [helpError, setHelpError] = useState(false);

  //returned title and message for help
  const [help, setHelp] = useState("");

  //to display loading before data is fetched
  const [helpLoading, setHelpLoading] = useState(false);

  //for color of help icon
  const [helpColor, setHelpColor] = useState("#000");

  //for help button to know which help text to display
  const [pageLink, setPageLink] = useState("");

  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = (event, route) => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      setOpenDialog(true);

      setRouteToNavigate(route);
    } else {
      history.push(route);
    }
  };

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);

    history.push(routeToNavigate);
  };

  const getBannerText = () => {
    //function to get the banner text to be displayed in case a company is on Hold
    systemDataServices
      .getOnHoldBanner()
      .then((response) => {
        setBannerText(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  const getHelpData = async () => {
    //function to get the data to display the help text

    var helpTextID = await getHelpIdentifier(props.pageLink);

    window.open("/help?source=" + helpTextID, "_blank");
  };

  useEffect(() => {
    getBannerText();
  }, []);

  //getting values from redux
  const ownUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownUsers : ""
  );

  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientUsers : ""
  );

  const takeoffWiz = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoffWiz : ""
  );

  const clientCompany = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientCompany : ""
  );

  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  const takeoff = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const orders = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const agreements = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  const companyStatus = useSelector((state) =>
    state.user.user ? state.user.user.user.company.status : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const roleType = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const rma = useSelector((state) =>
    state.user.user ? state.user.user.user.role.rma : ""
  );

  const roleSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.role.systemGenerated : ""
  );

  //holds values to be displayed in drawer menu
  const Admin = [
    {
      id: 0,
      description: "Dashboard",
      link: "/Dashboard",
      icon: <Layout color="#565759" />,
      iconSelected: <Layout color="#0077c6" />,
      display: true,
      divider: 1,
    },

    {
      id: 1,
      description: "My Users",
      link: "/my-users",
      icon: <User color="#565759" />,
      iconSelected: <User color="#0077c6" />,
      display: ownUsers !== "N" ? true : false,
      divider: 1,
    },

    {
      id: 2,
      description: "My Roles",
      link: "/my-roles",
      icon: <Key color="#565759" />,
      iconSelected: <Key color="#0077c6" />,
      display: ownRoles !== "N" ? true : false,
      divider: 1,
    },
    {
      id: 3,
      description: "Agreements",
      link: "/Agreements",
      icon: <FileText color="#565759" />,
      iconSelected: (
        <FileText
          icon="fluent:handshake-16-regular"
          height={24}
          color="#0077c6"
        />
      ),
      display: agreements !== "N" ? true : false,
      divider: 1,
    },
    {
      id: 4,
      description: "Takeoffs",
      link: "/Takeoff",
      icon: <Home color="#565759" />,
      iconSelected: <Home color="#0077c6" />,
      display: takeoff !== "N" ? true : false,
      divider: 1,
    },
    {
      id: 5,
      description: "Orders",
      link: "/Orders",
      icon: <Truck color="#565759" />,
      iconSelected: <Truck color="#0077c6" />,
      display: orders !== "N" ? true : false,
      divider: 1,
    },

    {
      id: 6,
      description: "Companies",
      link: "/Companies",
      icon: <Icon icon="clarity:building-line" height={24} color="#565759" />,
      iconSelected: (
        <Icon icon="clarity:building-line" height={24} color="#0077c6" />
      ),
      display: clientCompany !== "N" && companyType === "L" ? true : false,
      divider: 2,
    },
    {
      id: 7,
      description: "Users",
      link: "/Users",
      icon: <Users color="#565759" />,
      iconSelected: <Users color="#0077c6" />,
      display: clientUsers !== "N" && companyType === "L" ? true : false,
      divider: 2,
    },
    {
      id: 8,
      description: "Roles",
      link: "/Roles",
      icon: <Key color="#565759" />,
      iconSelected: <Key color="#0077c6" />,
      display: clientRoles !== "N" && companyType === "L" ? true : false,
      divider: 2,
    },
    {
      id: 9,
      description: "Products",
      link: "/Products",
      icon: <Package color="#565759" />,
      iconSelected: <Package color="#0077c6" />,
      display: takeoffWiz !== "N" ? true : false,
      divider: 2,
    },

    {
      id: 10,
      description: "Takeoff Setup",
      link: "/takeoff-wizard",
      icon: <Tool color="#565759" />,
      iconSelected: <Tool color="#0077c6" />,
      display: takeoffWiz !== "N" && companyType === "L" ? true : false,
      divider: 2,
    },
    {
      id: 11,
      description: "RMA",
      link: "/RMA",
      icon: <Inbox color="#565759" />,
      iconSelected: <Inbox color="#0077c6" />,
      display: companyType === "L" && (rma === "M" || rma === "R"),
      divider: 2,
    },
    {
      id: 12,
      description: "Logs",
      link: "/Logs",
      icon: <Database color="#565759" />,
      iconSelected: <Database color="#0077c6" />,
      display:
        companyType === "L" &&
        roleType === "Levven Admin" &&
        roleSystemGenerated === true,
      divider: 2,
    },
    {
      id: 13,
      description: "Deleted Orders",
      link: "/Deleted-Orders",
      icon: <FileMinus color="#565759" />,
      iconSelected: <FileMinus color="#0077c6" />,
      display:
        companyType === "L" &&
        roleType === "Levven Admin" &&
        roleSystemGenerated === true,
      divider: 2,
    },
  ];

  useEffect(() => {
    setMenuItemId(props.pageId);
    setPageLink(props.pageLink);
  }, [props.pageId, props.pageLinks]);

  const selectMenuItem = (index) => {
    //handling which page we are going to in drawer menu
    setMenuItemId(index);
  };

  const handleDrawerOpen = () => {
    //for drawer menu open
    props.setOpen(true);
  };

  const handleDrawerClose = () => {
    //for drawer menu close
    props.setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <AppBar
        position="fixed"
        open={props.open}
        color="inherit"
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <Toolbar style={{ marginLeft: !props.open ? "60px" : "-15px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              marginRight: "36px",
            }}
          >
            <Menu />
          </IconButton>
          <span style={{ position: "fixed", right: "20px" }}>
            {props.pageId !== -2 ? (
              <IconButton
                color="inherit"
                aria-label="help-button"
                onMouseOver={() => setHelpColor("#fff")}
                onMouseLeave={() => setHelpColor("#000")}
                onClick={getHelpData}
                sx={{
                  padding: "3px",
                  "&:hover": { backgroundColor: "rgba(0,144,212,0.2)" },
                  marginRight: "5px",
                }}
              >
                <HelpCircle color={helpColor} />
              </IconButton>
            ) : null}

            <IconButton
              color="inherit"
              aria-label="user-profile"
              sx={{ marginRight: "20px" }}
            >
              <AccountMenu setPageID={props.setPageID} />
            </IconButton>
          </span>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={props.open}>
        <DrawerHeader
          sx={{
            alignText: "left",
            justifyContent: "left",
            alignContent: "left",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        >
          <div onClick={(event) => handleItemClick(event, "/")}>
            {/* <Link to="/"> */}
            <img
              src={logosmaller}
              alt="levven logo"
              style={{
                marginTop: "10px",
                marginLeft: "2px",
                display: props.open ? "none" : "",
              }}
            />
            <img
              src={logo}
              alt="levven logo"
              width="141px"
              height="30px"
              style={{ marginLeft: "30px", display: props.open ? "" : "none" }}
            />
            {/* </Link> */}
          </div>
        </DrawerHeader>
        <List>
          {Admin.map((text, index) => (
            <>
              {text?.display === true ? (
                <div onClick={(event) => handleItemClick(event, text?.link)}>
                  {/* <Link
                  // to={text.link}

                  style={{ color: "#0077c6", textDecoration: "none" }}
                > */}
                  <ListItemButton
                    key={text.description}
                    sx={{
                      "&:hover": {
                        bgcolor: "#cde2f5",
                      },
                      "&:active": {
                        bgcolor: "#fafafa",
                      },
                      marginTop: "2px",
                      bgcolor: menuItemId === index ? "#cde2f5" : "#fff",
                      borderRight:
                        menuItemId === index ? "3px solid #0077c6" : "none",
                    }}
                  >
                    <ListItemIcon>
                      {menuItemId === index ? text.iconSelected : text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.description}
                      disableTypography
                      sx={{
                        color: menuItemId === index ? "#0077c6" : "#565759",
                        fontWeight: menuItemId === index ? 550 : "normal",
                      }}
                    />
                  </ListItemButton>
                  {/* </Link> */}
                </div>
              ) : (
                ""
              )}
              {index !== Admin.length - 1 &&
              Admin[index].divider !== Admin[index + 1].divider ? (
                <Divider sx={{ margin: "20px 0px" }} />
              ) : (
                ""
              )}
            </>
          ))}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          display: "block !important",
          flexGrow: 1,
          p: 3,
          justifyContent: "center",
          minHeight: "100vh",
        }}
        className={classes.drawerMenuBoxStyle}
      >
        <DrawerHeader />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ backgroundColor: "#f5f5f5" }}
          >
            {companyStatus === "A" ? null : (
              <CustomBanner bannerText={bannerText} />
            )}
            {props.children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DrawerMenu;
