import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, X } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { countryServices } from "../../services/country.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";

const headCells = [
  {
    id: "stateProvinceCode",
    numeric: false,
    disablePadding: false,
    label: "State/Province Code",
  },
  {
    id: "stateProvince",
    numeric: false,
    disablePadding: false,
    label: "State/Province",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function StateProvinceTableComponent({ countryID = 0 }) {
  //holds IDs of selected rows
  const [selected, setSelected] = React.useState([]);

  //holds page we are in in the table
  const [page, setPage] = React.useState(0);

  //holds how many rows per page to display
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds the data to display in the rows
  const [rows, setRows] = React.useState([]);

  //display filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //holds the total number of selected values
  var numSelected = selected.length;

  //holds the total number of records in the database that match the filters
  const [numberRecords, setNumberRecords] = React.useState(0);

  //for delete button loading while delete is underway
  const [loading, setLoading] = React.useState(false);

  //to resubmit data and fetch new data
  const [resubmit, setResubmit] = React.useState(false);

  //holds state province value to be added
  const [stateProvinceAdd, setStateProvinceAdd] = React.useState("");
  const [stateProvinceCodeAdd, setStateProvinceCodeAdd] = React.useState("");

  //holds error in case of any during the fetching process
  const [error, setError] = React.useState("");

  //displays the textfield to add the state province
  const [addSP, setAddSP] = React.useState(false);

  //holds search data of state provinces
  const [stateProvince, setStateProvince] = React.useState("");
  const [stateProvinceCode, setStateProvinceCode] = React.useState("");

  //makes page laod while data is being fetched from database
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds error if any during the deletion process
  const [deleteError, setDeleteError] = React.useState("");

  //displays snackbar
  const [success, setSuccess] = React.useState(false);

  //displays alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  const [addLoading, setAddLoading] = React.useState(false);
  // const rolePermission = useSelector((state) =>
  //   state.user.user ? state.user.user.user.role.clientCompany : ""
  // );

  const fetchDataFromDatabase = () => {
    //function to fetch data from the database
    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
      Filters: {
        StateProvince: stateProvince,
        StateProvinceCode: stateProvinceCode,
      },
      CountryID: countryID,
    };
    countryServices
      .getStateProvinceFilteredList(data)
      .then((response) => {
        setNumberRecords(response.data.totalNumberOfRecords);

        setRows(response.data.stateProvinces);

        setPageLoad(false);
      })
      .catch((error) => {
        setPageLoad(false);
        setError(error.response.data.error.error);
      });
  };

  useEffect(() => {
    //we get the data from the database

    setPageLoad(true);
    setRows([]);
    setSelected([]);
    if (countryID !== 0) {
      fetchDataFromDatabase();
    }
  }, [page, rowsPerPage, resubmit]);

  const handleSelectAllClick = (event) => {
    //function to select all records in the page
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const submitFilters = (event) => {
    //we resubmit the filters so we get the filtered data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page in table
    setPage(newPage);
  };

  const displaySateProvinceAdd = () => {
    //function to display and hide state province add in table
    setAddSP(!addSP);
    setStateProvince("");
  };

  const addStateProvince = (event) => {
    //function to add state province in table
    event.preventDefault();
    const data = {
      CountryID: countryID,
      Description: stateProvinceAdd,
      StateProvinceCode: stateProvinceCodeAdd,
    };

    setAddLoading(true);
    countryServices
      .createStateProvince(data)
      .then(() => {
        setStateProvinceAdd("");
        setAddLoading(false);
        setStateProvinceCodeAdd("");
        setResubmit(!resubmit);
        setAddSP(false);
      })
      .catch((error) => {
        setAddLoading(false);
        setStateProvinceAdd("");
        setAddSP(false);
      });
  };

  const deleteStateProvince = () => {
    //function to delete selected rows from table
    setLoading(true);
    setDeleteRecord(false);
    countryServices
      .deleteStateProvince({ StateProvince: selected })
      .then(() => {
        setLoading(false);
        setResubmit(!resubmit);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setResubmit(!resubmit);
        setSelected([]);
      });
  };

  const clearFilterData = () => {
    //function to clear filter data
    setStateProvince("");
    setStateProvinceCode("");
    setResubmit(!resubmit);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page in table
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //function to check if a row is selected in the table
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <SnackBar
        open={success}
        message="State/Province(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {deleteRecord ? (
        <AlertDialogSlide
          title="State/Province Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteStateProvince}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}
      {addSP === true ? (
        <form onSubmit={addStateProvince}>
          <Paper elevation={0} sx={{ marginBottom: "20px" }}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ justifyContent: "space-between" }}
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="State/Province Code"
                  value={stateProvinceCodeAdd}
                  onChange={(e) => {
                    if (e.target.value.length <= 2) {
                      setStateProvinceCodeAdd(e.target.value);
                    }
                  }}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <X
                        color="#969696"
                        onClick={() => setStateProvinceCodeAdd("")}
                        style={{ cursor: "pointer" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="State/Province"
                  value={stateProvinceAdd}
                  onChange={(e) => setStateProvinceAdd(e.target.value)}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <X
                        color="#969696"
                        onClick={() => setStateProvinceAdd("")}
                        style={{ cursor: "pointer" }}
                      />
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <CustomButton type="submit" loading={addLoading}>
                  Add State/Province
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </form>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                State/Province
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                <CustomButton
                  startIcon={<Trash2 />}
                  size="medium"
                  tableButton={true}
                  loading={loading}
                  toggle={() => setDeleteRecord(true)}
                >
                  Delete
                </CustomButton>
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>

                <CustomButton
                  startIcon={addSP ? <X /> : <Plus />}
                  size="medium"
                  tableButton={true}
                  toggle={displaySateProvinceAdd}
                >
                  {addSP ? "Cancel" : "Add"}
                </CustomButton>
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilters}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={stateProvinceCode}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setStateProvinceCode(e.target.value);
                          }
                        }}
                        label="State/Province Code"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <TextField
                        size="small"
                        value={stateProvince}
                        onChange={(e) => setStateProvince(e.target.value)}
                        label="State/Province"
                        fullWidth
                        inputProps={{}}
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={numberRecords}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no state/provinces to display.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.descriptionID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.descriptionID}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) =>
                              handleClick(event, row.descriptionID)
                            }
                            checkedIcon={
                              <CheckBoxIcon sx={{ color: "#badaf1" }} />
                            }
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.descriptionCode}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
