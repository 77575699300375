import { Alert } from "@mui/material";
import React from "react";

const CustomAlertSuccess = ({ children }) => {
  return (
    <Alert
      sx={{ justifyContent: "center" }}
      severity="success"
      style={{
        backgroundColor: "#a2d6a4",
        color: "#517156",
        borderRadius: "3px",
      }}
    >
      {children}
    </Alert>
  );
};

export default CustomAlertSuccess;
