import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const ContextMenu = ({
  contextMenu,
  handleClose,
  openInNewTab,
  logsTable = false,
  hasDetails = false,
}) => {
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {logsTable === false || (logsTable === true && hasDetails === true) ? (
        <MenuItem
          sx={{ paddingLeft: "50px", paddingRight: "50px" }}
          onClick={openInNewTab}
        >
          Open in new tab
        </MenuItem>
      ) : (
        <MenuItem
          sx={{ paddingLeft: "50px", paddingRight: "50px" }}
          disabled={true}
        >
          No options
        </MenuItem>
      )}
    </Menu>
  );
};

export default ContextMenu;
