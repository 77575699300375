import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the company */

const baseUrl = apiConfig + "/company";

const getCompanyByID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetCompanyByID/" + data);
};

const getCompanies = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllCompaniesList", data);
};

const getParentCompanies = (data) => {
  return fetchWrapper.post(baseUrl + "/GetParentCompanies", data);
};

const updateCompanyData = (companyID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateCompany/" + companyID, data);
};
const deleteCompanies = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteCompanies", data);
};

const getAllCompanies = () => {
  return fetchWrapper.get(baseUrl + "/GetAllCompanies");
};

const getCompaniesByType = (data) => {
  return fetchWrapper.post(baseUrl + "/GetChildCompaniesByType", data);
};

const getBuilders = () => {
  return fetchWrapper.post(baseUrl + "/GetChildCompaniesByType", {
    CompanyType: "B",
  });
};

const getChildCompaniesForAgreementByType = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetChildCompaniesForAgreementByType",
    data
  );
};

const GetChildCompaniesForTakeoffByType = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetChildCompaniesForTakeoffByType",
    data
  );
};

const getInstallers = () => {
  return fetchWrapper.post(baseUrl + "/GetChildCompaniesByType", {
    CompanyType: "I",
  });
};

const getAllRelatedCompaniesByCompanyID = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetAllRelatedCompaniesByCompanyID/" + companyID
  );
};

const getParentChildCompaniesByCompanyID = (companyID) => {
  return fetchWrapper.get(
    baseUrl + "/GetParentChildCompanies/" + companyID
  );
};

const getResellers = () => {
  return fetchWrapper.post(baseUrl + "/GetChildCompaniesByType", {
    CompanyType: "R",
  });
};

const getAllRMACompanies = () => {
  return fetchWrapper.get(baseUrl + "/GetAllRMACompanies");
};

export const companyServices = {
  getCompanies,
  getCompanyByID,
  getParentCompanies,
  updateCompanyData,
  deleteCompanies,
  getAllCompanies,
  getCompaniesByType,
  GetChildCompaniesForTakeoffByType,
  getBuilders,
  getInstallers,
  getChildCompaniesForAgreementByType,
  getAllRelatedCompaniesByCompanyID,
  getResellers,
  getAllRMACompanies,
  getParentChildCompaniesByCompanyID
};
