import { Breadcrumbs, Link, Paper, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderCreationStepper from "../../../components/OrderCreationStepper/OrderCreationStepper";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { X, Save } from "react-feather";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateOrderPage = ({
  setPageID,
  setPageLink,
  windowWidth,
  openDrawerMenu,
  isRMA = false,
}) => {
  /*Get Takeoff permission from Redux*/
  const order = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const rma = useSelector((state) =>
    state.user.user ? state.user.user.user.role.rma : ""
  );

  const history = useHistory();
  const location = useLocation();

  if (
    (order !== "M" && isRMA === false) ||
    (isRMA === true && (rma !== "M" || currentCompanyType !== "L"))
  ) {
    history.push("/dashboard");
  }

  const takeoff = location.state
    ? location.state.takeoff
      ? location.state.takeoff
      : null
    : null;

  const fromTakeoff = location.state
    ? location.state.fromTakeoff
      ? location.state.fromTakeoff
      : false
    : false;

  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [adminManage, setAdminManage] = useState(false);
  const orderRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate(isRMA === false ? "/orders" : "RMA");
  
    } else {
  
      history.push(isRMA === false ? "/orders" : "RMA")
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  useEffect(() => {
    if (isRMA === false) {
      //for drawer menu
      setPageID(5);

      //for help button
      setPageLink("Orders");
    } else {
      //for drawer menu
      setPageID(11);

      //for help button
      setPageLink("RMA");
    }
  }, []);

  useEffect(() => {
    if (
      (order === "M" && isRMA === false) ||
      (isRMA === true && rma === "M" && currentCompanyType === "L")
    )
      setAdminManage(true);
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper
      sx={{
        padding: "20px",
        minHeight: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            onClick={() => handleItemClick()}
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            {isRMA === false ? "Orders" : "RMA"}
          </Link>
          <Typography key="2" color="text.primary" sx={{ fontSize: "24px" }}>
            {isRMA === false ? "New Order" : "New RMA"}
          </Typography>
        </Breadcrumbs>
      
      </div>
      <div style={{ height: "20px" }} />
  
      <OrderCreationStepper
        builderObj={takeoff !== null ? takeoff.agreement.builder : null}
        takeoffObj={takeoff !== null ? takeoff : null}
        fromTakeoff={fromTakeoff}
        windowWidth={windowWidth}
        openDrawerMenu={openDrawerMenu}
        isRMA={isRMA}
      />
    </Paper></>
  );
};

export default CreateOrderPage;
