import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useLocation } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { accountServices } from "../../services/account.services";
import { adminServices } from "../../services/admin.services";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  UserCheck,
  UserPlus,
  UserX,
  Filter,
  RefreshCcw,
  Trash2,
} from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Autocomplete, CircularProgress } from "@mui/material";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolesTable,
    headCells,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {numSelected > 0 ? (
            <Checkbox
              checkedIcon={
                <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) =>
          rolesTable &&
          (headCell.label === "Company" ||
            headCell.label === "Role" ||
            headCell.display === false) ? null : ( // No display for company and role columns if the Users table is accessed from the role information page
            <TableCell
              key={headCell.id}
              align={
                headCell.numeric ? "right" : headCell.center ? "center" : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: "white", backgroundColor: "#0077c6" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function UsersTableComponent({
  companyID = 0,
  rolesTable = false,
  roleID = 0,
  titleText = "Users",
  companyTable = false,
  fromCompanyRole = false,
  locationToBeSent = null,
  setCreateUser,
  setCreateUserRole,
  roleName = "",
  userRoleID,
  userRoleName,
  userCompanyName,
  userSystemGenerated = false,
  setCreateChangeUser,
  setCreateChangeRole,
  myCompany = false,
  dataObject = null,
}) {
  const location = useLocation();

  //used for permissions
  const rolePermission = useSelector((state) =>
    !companyTable && !fromCompanyRole && !rolesTable
      ? state.user.user
        ? companyID === 0
          ? state.user.user.user.role.clientUsers
          : state.user.user.user.role.ownUsers
        : "N"
      : state.user.user
      ? !myCompany
        ? state.user.user.user.role.clientUsers
        : state.user.user.user.role.ownUsers
      : "N"
  );

  const history = useHistory();

  //for security
  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  /**For sorting */
  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //holds selected rows in table
  const [selected, setSelected] = React.useState([]);

  //holds page in table
  const [page, setPage] = React.useState(0);

  //holds rows per page in table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds rows in table
  const [rows, setRows] = React.useState([]);

  //displays filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //holds the error while fetching data if any
  const [error, setError] = React.useState("");

  //holds the number of selected rows in the table
  var numSelected = selected.length;

  //holds currentState selected
  const [currentState, setCurrentState] = React.useState("");

  //holds number of records in database that match filters
  const [numberRecords, setNumberRecords] = React.useState(0);

  //checks if a user has their status changed
  const [userStatusChange, setUserStatusChange] = React.useState(false);

  //for filters
  const [usernameSearch, setUsernameSearch] = React.useState("");
  const [companySearch, setCompanySearch] = React.useState("");
  const [fnameSearch, setFnameSearch] = React.useState("");
  const [lnameSearch, setLnameSearch] = React.useState("");
  const [roleSearch, setRoleSearch] = React.useState("");
  const [selectedState, setSelectedState] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [statusList, setStatusList] = React.useState([
    { name: "New", id: "N" },
    { name: "Pending", id: "P" },
    { name: "Active", id: "A" },
    { name: "Inactive", id: "I" },
  ]);

  //to refetch data
  const [resubmit, setResubmit] = React.useState(false);

  //holds errors during deactivation and activation of user
  const [deActError, setDeActError] = React.useState("");

  //for page loading
  const [pageLoad, setPageLoad] = React.useState(false);

  //for delete button loading
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  //displays dialog before deactivation
  const [deactivateRecord, setDeactivateRecord] = React.useState(false);

  //displays snackbar
  const [success, setSuccess] = React.useState(false);

  //holds message for snackbar
  const [message, setMessage] = React.useState("");

  //fetch data from redux
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  const [previousPageSnackbar, setPreviousPageSnackbar] = React.useState(
    location.state
      ? location.state.previousPageSnackbar
        ? location.state.previousPageSnackbar
        : ""
      : ""
  );

  const headCells = [
    {
      id: "UserName",
      numeric: false,
      disablePadding: true,
      label: "Username",
      center: false,
      display: true,
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Company",
      center: false,
      display: true,
    },
    {
      id: "FirstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      center: false,
      display: true,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      center: false,
      display: true,
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: "Role",
      center: false,
      display: true,
    },
    {
      id: "Status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      center: true,
      display: true,
    },
  ];

  //get user ID of user logged in
  const userID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  //get company ID of user logged in
  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.companyID : ""
  );

  useEffect(() => {
    // Get data from the database after first render, or rows per page change, or page change, or activate/deactivate/filter of users
    var array = [];

    window.scrollTo(0, 0);

    setPageLoad(true);
    setError("");
    setSelected([]);

    if (rolesTable === false) {
      //if not the users table that is accessible from the Roles information page
      if (companyID === 0) {
        //companyID === 0 means we are in the Users page and not the My Users page

        setRows([]);

        selectedState.map((state) => {
          return array.push(state.id);
        });

        const data = {
          Keys: { Page: page + 1, PageSize: rowsPerPage },
          Filters: {
            username: usernameSearch,
            firstname: fnameSearch,
            lastname: lnameSearch,
            company: companySearch,
            role: roleSearch,
            status: array,
          },
          ViewAllUsers: true,
          ExcludedCompanyID: compID,

          orderDirection: orderDirection,
          orderColumn: orderColumn,
        };

        accountServices
          .getUsers(data)
          .then((response) => {
            setPageLoad(false);

            setNumberRecords(response.data.totalNumberOfRecords);

            setRows(response.data.users);
          })
          .catch((error) => {
            setError(error.response.data.error.error);

            setPageLoad(false);
          });
      } else {
        setRows([]);
        selectedState.forEach((state) => {
          return array.push(state.id);
        });

        const data = {
          Keys: { Page: page + 1, PageSize: rowsPerPage },
          Filters: {
            username: usernameSearch,
            firstname: fnameSearch,
            lastname: lnameSearch,
            company: companySearch,
            role: roleSearch,
            status: array,
          },
          ViewAllUsers: true,
          CompanyID: companyID,
          ExcludedUserID: userID,

          orderDirection: orderDirection,
          orderColumn: orderColumn,
        };

        accountServices
          .getMyUsers(data)
          .then((response) => {
            setNumberRecords(response.data?.totalNumberOfRecords);

            setPageLoad(false);
            setRows(response.data?.users);
          })
          .catch((error) => {
            setError(error.response.data?.error.error);

            setPageLoad(false);
          });
      }
    } else {
      setRows([]);

      selectedState.map((state) => {
        return array.push(state.id);
      });

      const data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage },
        Filters: {
          username: usernameSearch,
          firstname: fnameSearch,
          lastname: lnameSearch,
          company: companySearch,
          role: roleSearch,
          status: array,
          CompanyID: companyID,
          RoleID: roleID,
        },
        ViewAllUsers: true,

        orderDirection: orderDirection,
        orderColumn: orderColumn,
      };

      accountServices
        .getUsers(data)
        .then((response) => {
          setPageLoad(false);

          setNumberRecords(response.data.totalNumberOfRecords);

          setRows(response.data.users);
        })
        .catch((error) => {
          setError(error.response.data.error.error);

          setPageLoad(false);
        });
    }
  }, [page, rowsPerPage, userStatusChange, resubmit]);

  const submitFilterData = (event) => {
    //function to submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  const handleSnackBarClosePreviousPage = (event, reason) => {
    setPreviousPageSnackbar("");
  };

  const viewUserData = (
    usersID,
    firstName,
    lastName,
    microUserID,
    systemGenerated,
    status,
    username,
    companyName,
    companyIDGiven,
    roleName
  ) => {
    //send the data to company information page on record click depending on where we are
    //accessing the user table

    if (companyTable === false) {
      if (rolesTable === false) {
        if (companyID === 0) {
          history.push({
            pathname: "/user-information",
            state: {
              path: "user-information",
              userID: usersID,
              microUserID: microUserID,
              fname: firstName,
              lname: lastName,
              companyID: companyIDGiven,
              companyName: companyName,
              roleDescription: roleName,
              systemGenerated: systemGenerated,
              source: "Users",
              linkSource: "/Users",
              userStatus: status,
              username: username,
              self: usersID === userID ? true : false,
              fromCompany: false,
              fromRoles: false,
              locationToReceive: null,
            },
          });
        } else {
          history.push({
            pathname: "/user-information",
            state: {
              path: "user-information",
              userID: usersID,
              microUserID: microUserID,
              fname: firstName,
              lname: lastName,
              companyID: companyIDGiven,
              companyName: companyName,
              roleDescription: roleName,
              systemGenerated: systemGenerated,
              source: "My Users",
              linkSource: "/my-users",
              userStatus: status,
              username: username,
              self: usersID === userID ? true : false,
              fromCompany: false,
              fromRoles: false,
              locationToReceive: null,
            },
          });
        }
      } else {
        history.push({
          pathname: "/user-information",
          state: {
            path: "user-information",
            userID: usersID,
            microUserID: microUserID,
            roleID: userRoleID,
            roleName: userRoleName,
            companyName: userCompanyName,
            userSystemGenerated: userSystemGenerated,
            fname: firstName,
            lname: lastName,
            roleDescription: roleName,
            companyID: companyIDGiven,
            systemGenerated: systemGenerated,
            source: "Users",
            linkSource: companyIDGiven === compID ? "/my-users" : "/Users",
            userStatus: status,
            username: username,
            self: usersID === userID ? true : false,
            fromCompany: false,
            fromCompanyRole: fromCompanyRole,
            fromRoles: true,
            sourceCompany: "Roles",
            sourceCompanyLink:
              companyIDGiven === compID ? "/my-roles" : "/Roles",
            sourceCompanyName: roleName,
            sourceCompanyNameLink: "/role-information",
            locationToReceive: locationToBeSent,
          },
        });
      }
    } else {
      history.push({
        pathname: "/user-information",
        state: {
          path: "user-information",
          userID: usersID,
          microUserID: microUserID,
          fname: firstName,
          lname: lastName,
          companyID: companyIDGiven,
          companyName: companyName,
          systemGenerated: systemGenerated,
          source: companyIDGiven === compID ? "My Users" : "Users",
          linkSource: companyIDGiven === compID ? "/my-users" : "/Users",
          userStatus: status,
          username: username,
          self: companyIDGiven === compID ? true : false,
          fromCompany: true,
          sourceCompany: companyIDGiven === compID ? "My Company" : "Companies",
          sourceCompanyLink:
            companyIDGiven === compID ? "/company-information" : "/Companies",
          sourceCompanyName: companyName,
          sourceCompanyNameLink: "/company-information",
          locationToReceive: locationToBeSent,
        },
      });
    }
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    if (companyTable === false) {
      if (rolesTable === false) {
        if (companyID === 0) {
          const objectToBeSent = {
            path: "user-information",
            userID: contextMenuData.user.userID,
            microUserID: contextMenuData.user.microUserID,
            fname: contextMenuData.user.firstName,
            lname: contextMenuData.user.lastName,
            companyID: contextMenuData.user.company.companyID,
            companyName: contextMenuData.user.company.name,
            roleDescription: contextMenuData.user.role.description,
            systemGenerated: contextMenuData.user.systemGenerated,
            source: "Users",
            linkSource: "/Users",
            userStatus: contextMenuData.user.status,
            username: contextMenuData.user.userName,
            self: contextMenuData.user.userID === userID ? true : false,
            fromCompany: false,
            fromRoles: false,
            dataObject: dataObject,
            locationToReceive: null,
          };

          const encryptedDate = await encryptObject(objectToBeSent);

          window.open(
            "/user-information?otbd=" + encodeURIComponent(encryptedDate),
            "_blank"
          );

          setContextMenu(null);
        } else {
          const objectToBeSent = {
            path: "user-information",
            userID: contextMenuData.user.userID,
            microUserID: contextMenuData.user.microUserID,
            fname: contextMenuData.user.firstName,
            lname: contextMenuData.user.lastName,
            companyID: contextMenuData.user.company.companyID,
            companyName: contextMenuData.user.company.name,
            roleDescription: contextMenuData.user.role.description,
            systemGenerated: contextMenuData.user.systemGenerated,
            source: "My Users",
            linkSource: "/my-users",
            userStatus: contextMenuData.user.status,
            username: contextMenuData.user.userName,
            self: contextMenuData.user.userID === userID ? true : false,
            fromCompany: false,
            fromRoles: false,
            dataObject: dataObject,
            locationToReceive: null,
          };

          const encryptedDate = await encryptObject(objectToBeSent);

          window.open(
            "/user-information?otbd=" + encodeURIComponent(encryptedDate),
            "_blank"
          );

          setContextMenu(null);
        }
      } else {
        const objectToBeSent = {
          path: "user-information",
          userID: contextMenuData.user.userID,
          microUserID: contextMenuData.user.microUserID,
          roleID: userRoleID,
          roleName: userRoleName,
          companyName: userCompanyName,
          userSystemGenerated: userSystemGenerated,
          fname: contextMenuData.user.firstName,
          lname: contextMenuData.user.lastName,
          roleDescription: contextMenuData.user.role.description,
          companyID: contextMenuData.user.company.companyID,
          systemGenerated: contextMenuData.user.systemGenerated,
          source: "Users",
          linkSource:
            contextMenuData.user.company.companyID === compID
              ? "/my-users"
              : "/Users",
          userStatus: contextMenuData.user.status,
          username: contextMenuData.user.userName,
          self: contextMenuData.user.userID === userID ? true : false,
          fromCompany: false,
          fromCompanyRole: fromCompanyRole,
          fromRoles: true,
          sourceCompany: "Roles",
          sourceCompanyLink:
            contextMenuData.user.company.companyID === compID
              ? "/my-roles"
              : "/Roles",
          sourceCompanyName: contextMenuData.user.role.description,
          sourceCompanyNameLink: "/role-information",
          dataObject: dataObject,
          locationToReceive: locationToBeSent,
        };

        const encryptedDate = await encryptObject(objectToBeSent);

        window.open(
          "/user-information?otbd=" + encodeURIComponent(encryptedDate),
          "_blank"
        );

        setContextMenu(null);
      }
    } else {
      const objectToBeSent = {
        path: "user-information",
        userID: contextMenuData.user.userID,
        microUserID: contextMenuData.user.microUserID,
        fname: contextMenuData.user.firstName,
        lname: contextMenuData.user.lastName,
        companyID: contextMenuData.user.company.companyID,
        companyName: contextMenuData.user.company.name,
        systemGenerated: contextMenuData.user.systemGenerated,
        source:
          contextMenuData.user.company.companyID === compID
            ? "My Users"
            : "Users",
        linkSource:
          contextMenuData.user.company.companyID === compID
            ? "/my-users"
            : "/Users",
        userStatus: contextMenuData.user.status,
        username: contextMenuData.user.userName,
        self: contextMenuData.user.company.companyID === compID ? true : false,
        fromCompany: true,
        sourceCompany:
          contextMenuData.user.company.companyID === compID
            ? "My Company"
            : "Companies",
        sourceCompanyLink:
          contextMenuData.user.company.companyID === compID
            ? "/company-information"
            : "/Companies",
        sourceCompanyName: contextMenuData.user.company.name,
        sourceCompanyNameLink: "/company-information",
        dataObject: dataObject,
        locationToReceive: locationToBeSent,
      };

      const encryptedDate = await encryptObject(objectToBeSent);

      window.open(
        "/user-information?otbd=" + encodeURIComponent(encryptedDate),
        "_blank"
      );

      setContextMenu(null);
    }
  };

  const userStatusCheck = (status) => {
    //we transform the status returned from the database( since we only receive a P, A, I or N ) to transform it to its string equivalent
    if (status === "P") {
      return "Pending";
    } else if (status === "A") {
      return "Active";
    } else if (status === "I") {
      return "Inactive";
    } else if (status === "N") {
      return "New";
    }
  };

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => {
        return { Email: n.user.userName, CompanyID: n.user.companyID };
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //we get the status of the currently selected user so only the users with
  //the same status can be selected
  function getStatus(name) {
    rows.forEach((row) => {
      if (row.user.userName === name) {
        setCurrentState(row.user.status);
      }
    });
  }

  //Function to select row on click of anywhere on the row
  const handleClick = (event, name, company) => {
    const selectedIndex = selected.findIndex(
      (x) => x.Email === name && x.CompanyID === company
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        Email: name,
        CompanyID: company,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (selected.length === 1) {
      getStatus(selected[0].Email);
    } else if (selected.length === 0) {
      setCurrentState("");
    }
  }, [selected]);

  const activateUsers = () => {
    //function to activate user(s)
    setLoading(true);

    const data = {
      AdminID: userID,
      Users: selected,
      ReactivateUser: currentState === "I" ? true : false,
      ResendActivationEmail: currentState === "P" ? true : false,
    };

    adminServices
      .activateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
        setLoading(false);
        setSuccess(true);
        setMessage(
          currentState === "P"
            ? "Activation Email Sent Successfully"
            : "User(s) Activated Successfully"
        );
      })
      .catch((error) => {
        setDeActError(error.response.data.error.error);
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
        setLoading(false);
      });
  };

  const deactivateUsers = () => {
    //function to deactivate user(s)

    const data = {
      AdminID: userID,
      Users: selected,
    };

    setDeactivateRecord(false);
    setLoading(true);

    adminServices
      .deactivateUsers(data)
      .then(() => {
        //we get updated values for users from database, and reset them so no record is selected
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
        setLoading(false);
        setSuccess(true);
        setMessage("User(s) Deactivated Successfully");
      })
      .catch((error) => {
        setDeActError(error.response.data.error.error);
        setLoading(false);
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
      });
  };

  //function ot change page in table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const clearFilterData = () => {
    //function to clear filter data
    setUsernameSearch("");
    setFnameSearch("");
    setLnameSearch("");
    setCompanySearch("");
    setRoleSearch("");
    setSelectedState([]);
    setResubmit(!resubmit);
  };

  //function to change rowsPerPage
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addUser = () => {
    //function to add user
    if (companyTable === false) {
      if (rolesTable === false) {
        history.push({
          pathname: "/create-user",
          state: {
            companyID: companyID,
          },
        });
      } else {
        setCreateUserRole(true);
        setCreateChangeRole(true);
      }
    } else {
      setCreateUser(true);
      setCreateChangeUser(true);
    }
  };

  //function to check if a row is selected
  const isSelected = (name, company) => {
    return (
      selected.findIndex((x) => x.Email === name && x.CompanyID === company) !==
      -1
    );
  };

  const deleteUsers = async () => {
    setDeleteLoading(true);

    await accountServices
      .deleteUsers({ Users: selected })
      .then((response) => {
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
        setDeleteLoading(false);
        setSuccess(true);
        setResubmit(!resubmit);
        setMessage("User(s) Deleted Successfully");
      })
      .catch((error) => {
        setDeActError(error.response.data.error.error);
        setUserStatusChange(!userStatusChange);
        setSelected([]);
        setCurrentState("");
        setResubmit(!resubmit);
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <SnackBar
        open={success}
        message={message}
        severity="success"
        handleClose={() => {
          setSuccess(false);
          setMessage("");
        }}
      />
      {previousPageSnackbar ? (
        <SnackBar
          open={previousPageSnackbar !== ""}
          message={previousPageSnackbar}
          severity="success"
          handleClose={handleSnackBarClosePreviousPage}
        />
      ) : null}
      {deactivateRecord ? (
        <AlertDialogSlide
          title="User Deactivation"
          textContent={"Are you sure you want to deactivate this record?"}
          save={deactivateUsers}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeactivateRecord(false)}
          unsaveText="No"
        />
      ) : null}

      {deActError ? (
        <AlertSnackBar
          message={deActError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeActError("");
          }}
          open={deActError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {titleText}
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<Trash2 />}
                    size="medium"
                    tableButton={true}
                    toggle={deleteUsers}
                    loading={deleteLoading}
                  >
                    Delete
                  </CustomButton>
                ) : null}
                {rolePermission === "M" &&
                (currentState === "N" || currentState === "I") ? (
                  <CustomButton
                    startIcon={<UserCheck />}
                    size="medium"
                    tableButton={true}
                    toggle={activateUsers}
                    loading={loading}
                  >
                    Activate
                  </CustomButton>
                ) : rolePermission === "M" && currentState === "A" ? (
                  <CustomButton
                    startIcon={<UserX />}
                    size="medium"
                    tableButton={true}
                    toggle={() => setDeactivateRecord(true)}
                    loading={loading}
                  >
                    Deactivate
                  </CustomButton>
                ) : rolePermission === "M" && currentState === "P" ? (
                  <CustomButton
                    startIcon={<RefreshCcw />}
                    size="medium"
                    resendButton={true}
                    toggle={activateUsers}
                    loading={loading}
                  >
                    Resend Activation Email
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <CustomButton
                    startIcon={<UserPlus />}
                    size="medium"
                    tableButton={true}
                    toggle={addUser}
                  >
                    New User
                  </CustomButton>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilterData}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        value={usernameSearch}
                        onChange={(e) => setUsernameSearch(e.target.value)}
                        label="Username"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    {companyType === "L" && companyID === 0 ? (
                      <Grid item xs={8} md={5} lg={2}>
                        <TextField
                          size="small"
                          value={companySearch}
                          onChange={(e) => setCompanySearch(e.target.value)}
                          label="Company"
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                          }}
                          autoComplete="off"
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        value={fnameSearch}
                        onChange={(e) => setFnameSearch(e.target.value)}
                        label="First Name"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        value={lnameSearch}
                        onChange={(e) => setLnameSearch(e.target.value)}
                        label="Last Name"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        value={roleSearch}
                        onChange={(e) => setRoleSearch(e.target.value)}
                        label="Role"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        multiple
                        id="size-small-outlined-multi"
                        size="small"
                        options={statusList}
                        fullWidth
                        value={selectedState}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          setSelectedState(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>

                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolesTable={rolesTable}
                headCells={headCells}
              />

              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="10" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="10"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Users to display. "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={addUser}
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.user.userName,
                      row.user.companyID
                    );

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.user.userID}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {rolePermission === "R" ||
                          ((companyID !== 0 || rolesTable === true) &&
                            row.user.systemGenerated === true) ||
                          row.user.userID === userID ? (
                            ""
                          ) : (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(
                                  event,
                                  row.user.userName,
                                  row.user.companyID
                                )
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              disabled={
                                currentState === row.user.status ||
                                currentState === ""
                                  ? false
                                  : true
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewUserData(
                              row.user.userID,
                              row.user.firstName,
                              row.user.lastName,
                              row.user.microUserID,
                              row.user.systemGenerated,
                              row.user.status,
                              row.user.userName,
                              row.user.company.name,
                              row.user.company.companyID,
                              row.user.role.description
                            )
                          }
                        >
                          {row.user.userName}
                        </TableCell>
                        {!rolesTable ? (
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewUserData(
                                row.user.userID,
                                row.user.firstName,
                                row.user.lastName,
                                row.user.microUserID,
                                row.user.systemGenerated,
                                row.user.status,
                                row.user.userName,
                                row.user.company.name,
                                row.user.company.companyID,
                                row.user.role.description
                              )
                            }
                          >
                            {row.user.company.name}
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewUserData(
                              row.user.userID,
                              row.user.firstName,
                              row.user.lastName,
                              row.user.microUserID,
                              row.user.systemGenerated,
                              row.user.status,
                              row.user.userName,
                              row.user.company.name,
                              row.user.company.companyID,
                              row.user.role.description
                            )
                          }
                        >
                          {row.user.firstName}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewUserData(
                              row.user.userID,
                              row.user.firstName,
                              row.user.lastName,
                              row.user.microUserID,
                              row.user.systemGenerated,
                              row.user.status,
                              row.user.userName,
                              row.user.company.name,
                              row.user.company.companyID,
                              row.user.role.description
                            )
                          }
                        >
                          {row.user.lastName}
                        </TableCell>
                        {!rolesTable ? (
                          <TableCell
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={() =>
                              viewUserData(
                                row.user.userID,
                                row.user.firstName,
                                row.user.lastName,
                                row.user.microUserID,
                                row.user.systemGenerated,
                                row.user.status,
                                row.user.userName,
                                row.user.company.name,
                                row.user.company.companyID,
                                row.user.role.description
                              )
                            }
                          >
                            {row.user.role.description}
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          align="center"
                          onClick={() =>
                            viewUserData(
                              row.user.userID,
                              row.user.firstName,
                              row.user.lastName,
                              row.user.microUserID,
                              row.user.systemGenerated,
                              row.user.status,
                              row.user.userName,
                              row.user.company.name,
                              row.user.company.companyID,
                              row.user.role.description
                            )
                          }
                        >
                          <span
                            style={{
                              marginLeft: "-15px",
                              padding: "5px 10px",
                              height: "30px",
                              width: "100px",
                              borderRadius: "25px",
                              backgroundColor:
                                row.user.status === "P"
                                  ? "rgba(100, 76, 159, 0.2)"
                                  : row.user.status === "N"
                                  ? "rgba(255, 193, 7, 0.2)"
                                  : row.user.status === "A"
                                  ? "rgba(29, 148, 75, 0.2)"
                                  : "rgba(150, 150, 150, 0.3)",
                              color:
                                row.user.status === "P"
                                  ? "rgba(100, 76, 159, 1)"
                                  : row.user.status === "N"
                                  ? "rgba(255, 193, 7, 1)"
                                  : row.user.status === "A"
                                  ? "rgba(29, 148, 75, 1)"
                                  : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {userStatusCheck(row.user.status)}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={10} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
