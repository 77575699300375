import React, { useState, useEffect, useMemo } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import DrawerMenu from "../../components/DrawerMenu/DrawerMenu";
import UsersTable from "../DrawerRoutes/UsersTable/UsersTable";
import MyUsersTable from "../DrawerRoutes/MyUsersTable/MyUsersTable";
import CreateUserPage from "../../pages/DrawerRoutes/CreateUserPage/CreateUserPage";
import UndefinedPage from "../UndefinedPage/UndefinedPage";
import UserTabs from "../../components/UserTabs/UserTabs";
import CompaniesTable from "../DrawerRoutes/CompaniesTable/CompaniesTable";
import RolesTable from "../DrawerRoutes/RolesTable/RolesTable";
import CompaniesTabs from "../../components/CompaniesTabs/CompaniesTabs";
import RolesTabs from "../../components/RolesTabs/RolesTabs";
import CreateCompanyPage from "../DrawerRoutes/CreateCompanyPage/CreateCompanyPage";
import MyRolesTable from "../DrawerRoutes/MyRolesTable/MyRolesTable";
import CreateRolePage from "../DrawerRoutes/CreateRolePage/CreateRolePage";
import DashBoardPage from "../DrawerRoutes/DashBoardPage/DashBoardPage";
import SystemPreferences from "../../components/SystemPreferences/SystemPreferences";
import ProductsTable from "../../components/ProductsTable/ProductsTable";
//import { useHistory, useLocation } from "react-router-dom";
import ProductsTabs from "../../components/ProductsTabs/ProductsTabs";
import Cards from "../../components/CountryForm/Cards";
import CreateProductPage from "../DrawerRoutes/CreateProductPage/CreateProductPage";
import AgreementTable from "../../components/AgreementTable/AgreementTable";
import AgreementTabs from "../../components/AgreementTabs/AgreementTabs";
import CreateAgreementPage from "../DrawerRoutes/CreateAgreementPage/CreateAgreementPage";
import TakeoffWizardTable from "../../components/TakeoffWizardTable/TakeoffWizardTable";
import CreateTakeoffWizardPage from "../DrawerRoutes/CreateTakeoffWizardPage/CreateTakeoffWizardPage";
import ShippingAddressTabs from "../../components/ShippingAddressTabs/ShippingAddressTabs";
import OrderNoteTabs from "../../components/OrderNoteTabs/OrderNoteTabs";
import TakeoffWizardTabs from "../../components/TakeoffWizardTabs/TakeoffWizardTabs";
import TakeoffTable from "../DrawerRoutes/TakeoffTable/TakeoffTable";
import TakeoffStepper from "../../components/TakeoffStepper/TakeoffStepper";
import CreateTakeoffPage from "../DrawerRoutes/CreateTakeoffPage/CreateTakeoffPage";
import TakeoffTabs from "../../components/TakeoffTabs/TakeoffTabs";
import OrderTable from "../../components/OrderTable/OrderTable";
import OrderTabs from "../../components/OrderTabs/OrderTabs";
import CreateOrderPage from "../DrawerRoutes/CreateOrderPage/CreateOrderPage";
import CreateCountryPage from "../DrawerRoutes/CreateCountryPage/CreateCountryPage";
import { accountServices } from "../../services/account.services";
import { useSelector, useDispatch } from "react-redux";
import HelpPage from "../../components/HelpPage/HelpPage";
import LogsTable from "../../components/LogsTable/LogsTable";
import LogDetailsTab from "../../components/LogDetailsTab/LogDetailsTab";
import OrdersTable from "../DrawerRoutes/OrderTable/OrdersTable";
import OrphanedOrders from "../DrawerRoutes/OrphanedOrdersTable/OrphanedOrdersTable"
import TakeoffsTable from "../DrawerRoutes/TakeoffTable/TakeoffTable";
import RMATable from "../DrawerRoutes/RMATable/RMATable";

const MainPage = () => {
  const [pageID, setPageID] = useState(-1);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   if (window.innerWidth < 700 && windowWidth !== 600) {
  //     setWindowWidth(600);
  //   } else if (window.innerWidth > 700 && windowWidth !== 1000) {
  //     setWindowWidth(1000);
  //   }
  // });

  window.addEventListener(
    "resize",
    function (e) {
      // setWindowWidth(window.innerWidth);
      if (window.innerWidth < 700 && windowWidth !== 600) {
        setWindowWidth(600);
      } else if (window.innerWidth > 700 && windowWidth !== 1000) {
        setWindowWidth(900);
      } else {
        setWindowWidth(1000);
      }
    },
    true
  );

  useEffect(() => {
    return () => {
      setWindowWidth(0); // This worked for me
    };
  }, []);

  const changePageTitle = (id) => {
    var string = "Levven Electronics – Customer Portal";

    switch (id) {
      case 0:
        string += " - Dashboard";
        break;

      case 1:
        string += " - My Users";
        break;

      case 2:
        string += " - My Roles";
        break;

      case 3:
        string += " - Agreements";
        break;

      case 4:
        string += " - Takeoffs";
        break;

      case 5:
        string += " - Orders";
        break;
      case 6:
        string += " - Companies";
        break;
      case 7:
        string += " - Users";
        break;

      case 8:
        string += " - Roles";
        break;
      case 9:
        string += " - Products";
        break;
    
      case 10:
        string += " - Takeoff Setup";
        break;
      case 11:
        string += " - Logs";
        break;
        case 12:
          string += " - Deleted Orders";
          break;

      case -5:
        string += " - System Preferences";
        break;
      case -6:
        string += " - My Company";
        break;
      case -7:
        string += " - Profile";
        break;
      case -2:
        string += " - Help";
        break;

      default:
        break;
    }

    document.title = string;
  };

  useEffect(() => {
    changePageTitle(pageID);
  }, [pageID]);

  const [pageLink, setPageLink] = useState("");

  const [open, setOpen] = useState(true);



  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          height: "100%",
        }}
      >
        <DrawerMenu
          pageId={pageID}
          pageLink={pageLink}
          setPageID={setPageID}
          open={open}
          setOpen={setOpen}
          windowWidth={windowWidth}
        >
          <Switch>
            <Redirect exact from="/" to="/Dashboard" />
            <Route
              exact
              path="/dashboard"
              render={(props) => (
                <DashBoardPage
                  {...props}
                  openDrawerMenu={open}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Logs"
              render={(props) => (
                <LogsTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/log-details"
              render={(props) => (
                <LogDetailsTab
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            
            <Route
              path="/help"
              render={(props) => <HelpPage {...props} setPageID={setPageID} />}
            />

            <Route
              exact
              path="/user-information"
              render={(props) => (
                <UserTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Companies"
              render={(props) => (
                <CompaniesTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Roles"
              render={(props) => (
                <RolesTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/company-information"
              render={(props) => (
                <CompaniesTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Products"
              render={(props) => (
                <ProductsTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/product-information"
              render={(props) => (
                <ProductsTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/create-product"
              render={(props) => (
                <CreateProductPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Deleted-Orders"
              render={(props) => (
                <OrphanedOrders
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/role-information"
              render={(props) => (
                <RolesTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/my-roles"
              render={(props) => (
                <MyRolesTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/country-information"
              render={(props) => (
                <Cards
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Orders"
              render={(props) => (
                <OrdersTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/create-role"
              render={(props) => (
                <CreateRolePage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/Agreements"
              render={(props) => (
                <AgreementTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/order-information"
              render={(props) => (
                <OrderTabs
                  {...props}
                  openDrawerMenu={open}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/create-order"
              render={(props) => (
                <CreateOrderPage
                  {...props}
                  openDrawerMenu={open}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/order-note-information"
              render={(props) => (
                <OrderNoteTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/shipping-address-information"
              render={(props) => (
                <ShippingAddressTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/agreement-information"
              render={(props) => (
                <AgreementTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/create-agreement"
              render={(props) => (
                <CreateAgreementPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/system-preferences"
              render={(props) => (
                <SystemPreferences
                  {...props}
                  setPageLink={setPageLink}
                  windowWidth={windowWidth}
                />
              )}
            />
            <Route
              path="/users"
              render={(props) => (
                <UsersTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              path="/my-users"
              render={(props) => (
                <MyUsersTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              path="/create-user"
              render={(props) => (
                <CreateUserPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              path="/create-company"
              render={(props) => (
                <CreateCompanyPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              path="/takeoff-wizard"
              render={(props) => (
                <TakeoffWizardTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              path="/takeoff-wizard-information"
              exact
              render={(props) => (
                <TakeoffWizardTabs
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              path="/TakeOff"
              exact
              render={(props) => (
                <TakeoffsTable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              path="/create-takeoff"
              exact
              render={(props) => (
                <CreateTakeoffPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              path="/takeoff-information"
              exact
              render={(props) => (
                <TakeoffTabs
                  {...props}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              path="/takeoff-product-quantities"
              exact
              render={(props) => (
                <TakeoffStepper
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              path="/create-takeoff-wizard"
              render={(props) => (
                <CreateTakeoffWizardPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />

            <Route
              path="/create-country"
              render={(props) => (
                <CreateCountryPage
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
              exact
            />
            <Route
              exact
              path="/RMA"
              render={(props) => (
                <RMATable
                  {...props}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/create-rma"
              render={(props) => (
                <CreateOrderPage
                  {...props}
                  isRMA={true}
                  openDrawerMenu={open}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route
              exact
              path="/rma-information"
              render={(props) => (
                <OrderTabs
                  {...props}
                  isRMA={true}
                  openDrawerMenu={open}
                  windowWidth={windowWidth}
                  setPageID={setPageID}
                  setPageLink={setPageLink}
                />
              )}
            />
            <Route component={UndefinedPage} />
          </Switch>
        </DrawerMenu>
      </div>
    </>
  );
};

export default MainPage;
