import React, { useState, useEffect } from "react";

import CustomModal from "../CustomModal/CustomModal";
import CustomButton from "../CustomButton/CustomButton";

import { Typography, TextField, Autocomplete } from "@mui/material";

import { accountServices } from "../../services/account.services";

const NotificationOrderAlert = ({
  open = false,
  companyID = "",
  setError = () => {},
  button1Disable = false,
  button1Function = () => {},
  button1Loading = false,
  button2Disable = false,
  button2Function = () => {},
  button2Loading = false,
  button3Disable = false,
  button3Function = () => {},
  button3Loading = false,
  user = null,
  setUser = () => {},
  allowButton3Enable = false,
}) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  function getAllSubmitOrderUsers() {
    setLoading(true);
    accountServices
      .getAllUsersHavingNotifyWhenSavedDraftReadyToOrderTrueByCompanyID(
        companyID
      )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getAllSubmitOrderUsers();
  }, []);

  return (
    <CustomModal openModal={open}>
      <Typography variant="h6" component="span">
        Notify Purchaser
      </Typography>
      <br />
      <br />
      <Typography
        variant="body1"
        component="span"
        style={{ whiteSpace: "pre-line" }}
      >
        Your account is set to notify a user when draft orders are saved.
      </Typography>
      <br />
      <br />
      <Autocomplete
        options={data}
        loading={loading}
        disableClearable
        value={user}
        getOptionLabel={(option) => (option ? option.userName : "")}
        onChange={(event, value) => {
          setUser(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User"
            name="Receiver User"
            fullWidth
            size="small"
            required
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
        }}
      >
        <CustomButton
          editTableColumnsButton={true}
          sx={{ whiteSpace: "normal", height: "fit" }}
          toggle={button1Function}
          loading={button1Loading}
          disable={button1Disable || loading || !user}
        >
          Send Notification Now
        </CustomButton>
        <div style={{ width: "10px" }} />
        <CustomButton
          toggle={button2Function}
          loading={button2Loading}
          disable={button2Disable || loading || !user}
          editTableColumnsButton={true}
          sx={{ whiteSpace: "normal", height: "fit" }}
        >
          Send Notification in 24 Hours
        </CustomButton>
        <div style={{ width: "10px" }} />
        <CustomButton
          editTableColumnsButton={true}
          sx={{ whiteSpace: "normal", height: "fit" }}
          toggle={button3Function}
          loading={button3Loading}
          disable={false}
        >
          {/* Do Not Send Notification */}
          Save Only, No Notification
        </CustomButton>
      </div>
    </CustomModal>
  );
};

export default NotificationOrderAlert;
