import React, { useState, useEffect } from "react";
import { Alert, Grid, TextField, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import { Link } from "react-router-dom";
import logo from "../../assets/Levven_logo.png";
import { useSelector, useDispatch } from "react-redux";
import { userTypes } from "../../redux/types/userTypes";
import { accountServices } from "../../services/account.services";
import { useLocation } from "react-router-dom";
import validator from "validator";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import orderReducer from "../../redux/reducers/order.reducer";
import { orderTypes } from "../../redux/types/orderTypes";
import { useCookies } from "react-cookie";
import { setAgreementURL } from "../../redux/actions/agreementActions";
import { agreementTypes } from "../../redux/types/agreementTypes";
import ReCAPTCHA from "react-google-recaptcha";
import { systemDataServices } from "../../services/systemData.services";
import { useReCaptcha } from "../../hooks/useReCaptcha";
import ReCaptchaText from "../../reCaptcha";
const LoginPage = () => {
  /*Local Variables*/
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [notValidEmail, setNotValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [
    siteKey,
    v2SiteKey,
    v2Token,
    reCaptchaError,
    setReCaptchaError,
    checkReCaptchaV2,
    setCheckRecaptchaV2,
    checkReCaptchaV3Token,
    handleReCaptchaV2Change,
    checkReCaptchaV2Token,
  ] = useReCaptcha();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  var _reCaptchaRef = React.createRef();
  //To Check if Cookie Exists
  const [cookies, setCookie, removeCookie] = useCookies();
  //checks if session expired
  const sessionExpired = useSelector((state) =>
    state.user
      ? state.user.sessionExpired
        ? state.user.sessionExpired
        : false
      : false
  );
  const receivedEmail = params.get("email")
    ? params.get("email")
    : location.state
    ? location.state.email
    : "";
  const toOrderID = location.state
    ? location.state.toOrderID
      ? location.state.toOrderID
      : 0
    : 0;
  const toAgreementUrlPage = location.state
    ? location.state.ToAgreementUrlPage
      ? location.state.ToAgreementUrlPage
      : false
    : false;
  const agreementUrlPageID = location.state
    ? location.state.AgreementUrlPageID
      ? location.state.AgreementUrlPageID
      : false
    : false;
  const toOrderTab = location.state
    ? location.state.toOrderTab
      ? location.state.toOrderTab
      : -1
    : -1;
  const displaySessionExpired = async () => {
    await setLoginError("Your session has expired, please Log In again.");
    await dispatch({
      type: userTypes.LOGOUT_USER,
    });
  };
  useEffect(() => {
    if (sessionExpired === true) {
      displaySessionExpired();
    }
  }, []);
  //to change title of page
  useEffect(() => {
    document.title = "Levven Electronics - Customer Portal - LogIn";
  }, []);
  /*Declare dispatch to dispatch redux actions*/
  const dispatch = useDispatch();
  /*Functions*/
  /*Get user data from api and save them in redux store*/
  const getUserData = (data) => {
    return (dispatch) => {
      accountServices
        .login(data)
        .then((response) => {
          if (response) {
            setLoginError("");
            setReCaptchaError("");
            dispatch({
              type: userTypes.GET_USER_DATA,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          setLoginError(error.response.data.error.error);
          setIsLoading(false);
          if (checkReCaptchaV2 === true) window.grecaptcha.reset();
          return Promise.reject(error);
        });
    };
  };

  const searchCookie = async (name) => {
    let str = document.cookie;
    str = str.split("; ");
    const result = {};
    for (let i in str) {
      const cur = str[i].split("=");
      result[cur[0]] = cur[1];
    }
    return result[name];
  };

  /*Check inputs and sign in*/
  const signIn = async (event) => {
    event.preventDefault();
    if (email === "") {
      setEmailError(true);
    } else setEmailError(false);
    if (!validator.isEmail(email)) {
      setNotValidEmail(true);
    } else setNotValidEmail(false);
    if (password === "") {
      setPasswordError(true);
    } else setPasswordError(false);
    if (
      email !== "" &&
      password !== "" &&
      validator.isEmail(email) &&
      checkReCaptchaV2 === false
    ) {
      setIsLoading(true);
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(siteKey, { action: "login" })
          .then(async function (token) {
            setIsLoading(true);
            var result = await checkReCaptchaV3Token(token);
            if (result === true) {
              const deviceTokenCookie = await searchCookie(
                "Levven-Device-Token-" + email
              );
              dispatch(
                getUserData({
                  email,
                  password,
                  deviceToken: deviceTokenCookie ? deviceTokenCookie : "",
                })
              );
            } else {
              setIsLoading(false);
              setCheckRecaptchaV2(true);
            }
          });
      });
    } else if (
      email !== "" &&
      password !== "" &&
      validator.isEmail(email) &&
      checkReCaptchaV2 === true &&
      v2Token !== ""
    ) {
      setIsLoading(true);
      var result = await checkReCaptchaV2Token();
      if (result === true) {
        const deviceTokenCookie = await searchCookie(
          "Levven-Device-Token-" + email
        );
        dispatch(
          getUserData({
            email,
            password,
            deviceToken: deviceTokenCookie ? deviceTokenCookie : "",
          })
        );
      } else {
        setIsLoading(false);
        window.grecaptcha.reset();
      }
    }
  };
  useEffect(() => {
    receivedEmail !== "" ? setEmail(receivedEmail) : setEmail("");
    if (toOrderID !== 0) {
      dispatch({
        type: orderTypes.REDIRECT_TO_ORDER,
        payload: {
          toOrderID: encodeURIComponent(toOrderID),
          toOrderTab: toOrderTab,
        },
      });
    } else if (toAgreementUrlPage === true) {
      dispatch(setAgreementURL(agreementUrlPageID));
    }
  }, []);
  useEffect(() => {}, [toOrderID]);
  useEffect(() => {}, [toAgreementUrlPage]);
  /*Render function*/
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
      height="100vh"
    >
      <CustomPaper>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: "20px" }}
        >
          <img
            src={logo}
            alt="Levven Logo"
            style={{
              objectFit: "contain",
              width: "50%",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: "20px" }}
        >
          <Typography
            variant="h4"
            component="span"
            color="#666666"
            style={{ fontWeight: 100 }}
          >
            Avenue
          </Typography>
          <br />
          <Typography
            variant="h6"
            component="span"
            color="#666666"
            style={{ fontWeight: 100 }}
          >
            Takeoff and Purchasing Tool
          </Typography>
        </Grid>
        <form onSubmit={signIn}>
          <Grid container alignSelf="center" spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ height: "6rem" }}
            >
              <TextField
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (emailError) setEmailError(false);
                  if (validator.isEmail(e.target.value))
                    setNotValidEmail(false);
                }}
                error={emailError || notValidEmail}
                helperText={
                  emailError
                    ? "Email field is empty"
                    : notValidEmail
                    ? "Email format not valid"
                    : ""
                }
                variant="outlined"
                size="medium"
                label="Email"
                fullWidth
                disabled={isLoading ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                value={password}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (passwordError) setPasswordError(false);
                }}
                variant="outlined"
                error={passwordError}
                helperText={passwordError ? "Password field is empty" : "    "}
                size="medium"
                label="Password"
                fullWidth
                disabled={isLoading ? true : false}
                required
              />{" "}
              <ReCaptchaText />
            </Grid>

            {checkReCaptchaV2 === true ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReCAPTCHA
                  type="image"
                  theme="light"
                  ref={_reCaptchaRef}
                  sitekey={v2SiteKey}
                  onChange={handleReCaptchaV2Change}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomButton
                type="submit"
                disable={
                  (siteKey === "" && checkReCaptchaV2 === false) ||
                  (checkReCaptchaV2 === true && v2SiteKey === "")
                }
                fullWidth
                loading={isLoading}
              >
                Sign In
              </CustomButton>
              <Link
                to={isLoading ? "#" : "/forgot-password"}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="subtitle2"
                  mt={3}
                  align="center"
                  color={isLoading ? "rgba(0, 0, 0, 0.12)" : "#0077c6"}
                  disabled={isLoading ? true : false}
                >
                  Forgot your password?
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {reCaptchaError ? (
                <CustomAlert>{reCaptchaError}</CustomAlert>
              ) : loginError ? (
                <CustomAlert>{loginError}</CustomAlert>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </CustomPaper>
    </Grid>
  );
};
export default LoginPage;
