import { agreementTypes } from "../types/agreementTypes";

// Dispatched actions
export const setAgreementURL = (name) => {
  return {
    type: agreementTypes.REDIRECT_TO_AGREEMENT_URL,
    payload: { ToAgreementUrlPage: true, AgreementUrlPageID: name },
  };
};

export const resetAgreementURL = () => {
  return {
    type: agreementTypes.RESET_TO_AGREEMENT_URL,
    payload: { ToAgreementUrlPage: false, AgreementUrlPageID: "" },
  };
};
