import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { companyServices } from "../../services/company.services";
import { systemDataServices } from "../../services/systemData.services";
import { agreementServices } from "../../services/agreement.services";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { productServices } from "../../services/product.service";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Copy, Link, Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import CustomModal from "../CustomModal/CustomModal";
import SnackBar from "../SnackBar/SnackBar";
import { orderServices } from "../../services/orders.service";

const AgreementForm = forwardRef(
  (
    {
      adminManage = false,
      salesManage = false,
      create = false,
      receivedAgreementID = 0,
      setOpenSnackBar,
      setTabButtonLoading,
      setSnackBarMessage,
      setAgreementNameInTabs,
      setFormChanged,
    },
    ref
  ) => {
    /*Company Object*/
    const [agreement, setAgreement] = useState();

    /*Switches*/
    const [switch1, setSwitch1] = useState();
    const [switch2, setSwitch2] = useState();

    /*Controller*/
    const [controller, setController] = useState();

    /*Selectors data*/
    const [builders, setBuilders] = useState([]);
    const [installers, setInstallers] = useState([]);
    const [companyTypes, setCompanyTypes] = useState(["Builder", "Installer"]);
    const [shippingMethods, setShippingMethods] = useState([
      "Prepaid and charge",
      "Prepaid",
      "Local pickup",
    ]);
    const [realEstateTypes, setRealEstateTypes] = useState([
      "Residential",
      "Commercial",
    ]);
    const [switchStyles, setSwitchStyles] = useState([]);
    const [switchColors, setSwitchColors] = useState([]);
    const [ampsOptions, setAmpsOptions] = useState([]);
    const [paymentTerms, setpaymentTerms] = useState([
      "Standard Terms",
      "Management Special",
    ]);
    const [takeoffs, setTakeoffs] = useState([]);
    const [relatedOrders, setRelatedOrders] = useState([]);

    /*Agreement Name*/
    const [agreementName, setAgreementName] = useState("");
    const [agreementNameError, setAgreementNameError] = useState(false);

    /*Agreement URL*/
    const [agreementUrl, setAgreementUrl] = useState("");
    const [agreementUrlError, setAgreementUrlError] = useState(false);

    const [copied, setCopied] = useState(false);

    /*Builder*/
    const [builder, setBuilder] = useState();
    const [builderError, setBuilderError] = useState(false);

    /*Installer*/
    const [installer, setInstaller] = useState();
    const [installerError, setInstallerError] = useState(false);

    /*Who does purchasing*/
    const [whoDoesPurchasing, setWhoDoesPurchasing] = useState("");
    const [whoDoesPurchasingError, setWhoDoesPurchasingError] = useState(false);

    /*Who does takeoff*/
    const [whoDoesTakeoff, setWhoDoesTakeoff] = useState("");
    const [whoDoesTakeoffError, setWhoDoesTakeoffError] = useState(false);

    /*Audio Agreement*/
    const [audioAgreement, setAudioAgreement] = useState(false);

    /*Electrical Trade Name Controllers*/
    const [electricalTradeNameControllers, setElectricalTradeNameControllers] =
      useState(false);

    /*Default shipping method*/
    const [shipMethod, setShipMethod] = useState("");
    const [shipMethodError, setShipMethodError] = useState(false);

    /*Real Estate Type*/
    const [realEstateType, setRealEstateType] = useState("");
    const [realEstateTypeError, setRealEstateTypeError] = useState(false);

    /*Switch style*/
    const [switchStyle, setSwitchStyle] = useState();
    const [switchStyleError, setSwitchStyleError] = useState(false);

    /*Switch Color*/
    const [switchColor, setSwitchColor] = useState();
    const [switchColorError, setSwitchColorError] = useState(false);

    /*Controller Amps*/
    const [amps, setAmps] = useState();
    const [ampsError, setAmpsError] = useState(false);

    /*Audio Agreement*/
    const [coatedSwitch, setCoatedSwitch] = useState(false);

    /*Dimmer upgrade quantity*/
    const [dimmerUpgrade, setDimmerUpgrade] = useState("");
    const [dimmerUpgradeError, setDimmerUpgradeError] = useState(false);

    /*Dimmer at the price of on/off controllers*/
    const [dimmerAtPrice, setDimmerAtPrice] = useState(false);

    /*Builder Price For Levven Q*/
    const [builderPriceLQ, setBuilderPriceLQ] = useState("0.00");
    const [builderPriceLQError, setBuilderPriceLQError] = useState(false);

    /*Payment Terms*/
    const [paymentTerm, setPaymentTerm] = useState("");
    const [paymentTermError, setPaymentTermError] = useState(false);

    /*Warranty period*/
    const [warrantyPeriod, setWarrantyPeriod] = useState(1);
    const [warrantyPeriodError, setWarrantyPeriodError] = useState(false);

    // discount multiplier
    const [discountMultiplier, setDiscountMultiplier] = useState(0);
    const [discountMultiplierError, setDiscountMultiplierError] =
      useState(false);

    /*Special Negotiated Project Price*/
    //const [projectPrice, setProjectPrice] = useState(false);

    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

    const [hashedID, setHashedID] = useState("");

    /**for isTheBuilderDeliveringQToTheHomeOwner */
    const [
      isTheBuilderDeliveringQToTheHomeOwner,
      setIsTheBuilderDeliveringQToTheHomeOwner,
    ] = useState(false);

    const history = useHistory();

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );

    /*Functions*/

    /*Initial useEffect*/
    useEffect(() => {
      if (!create && receivedAgreementID !== 0) {
        fetchAgreementByID();
      }
      getInstallerCompanies();
      getBuilderCompanies();
      fetchSwitchColors();
      fetchSwitchStyles();
      fetchControllerAmps();
    }, []);

    /*Fetch Switches everytime the color, style, or coated are changed*/
    useEffect(() => {
      if (
        switchStyle &&
        switchStyle !== null &&
        switchColor &&
        switchColor !== null
      ) {
        if (coatedSwitch) fetchSwitchesByColorAndStyle(true);
        else setSwitch2();
        fetchSwitchesByColorAndStyle(false);
      } else {
        setSwitch1();
        setSwitch2();
      }
    }, [switchColor, switchStyle, coatedSwitch]);

    /*Disable and resets the dimmer upgrade everytime the dimmerAtPrice is false*/
    useEffect(() => {
      if (!dimmerAtPrice) {
        setDimmerUpgrade(0);
      }
    }, [dimmerAtPrice]);

    useEffect(() => {
      console.log(
        "items changed and it's not saved yet",
        switch1,
        controller,
        agreement
      );

      if (openAlertDialog && agreement && controller && switch1) {
        console.log(agreement);
        console.log("items changed and it's not saved yet", agreement);
        checkIfAgreementIsLinkedToTakeoff();
        // save();
      }
    }, [controller]);

    /*Get default controller everytime the amps changes*/
    useEffect(() => {
      if (amps && amps !== null) fetchDefaultController();
      else setController();
    }, [amps]);

    /*Fetch Agreement data by ID*/
    const fetchAgreementByID = () => {
      setLoading(true);
      agreementServices
        .getAgreementByID({
          AgreementID: receivedAgreementID,
          IsEncrypted: false,
        })
        .then((response) => {
          const agreementData = response.data.agreement;
          setHashedID(response.data.encryptedAgreementID);
          setAgreement(response.data.agreement);
          setAgreementName(agreementData.name);
          setAgreementUrl(agreementData.url);
          setBuilder(agreementData.builder);
          setInstaller(agreementData.installer);
          setWhoDoesPurchasing(companyTypeCheck(agreementData.purchasing));
          setWhoDoesTakeoff(companyTypeCheck(agreementData.takeoff));
          setShipMethod("");
          setRealEstateType(realEstateCheck(agreementData.realEstateType));
          setAudioAgreement(agreementData.builderAudioAgreement);
          setSwitchStyle(agreementData.defaultSwitchStyle);
          setSwitchColor(agreementData.defaultSwitchColor);
          setCoatedSwitch(agreementData.coatedSwitch);
          setAmps(agreementData.defaultAmps);
          setController(agreementData.defaultController);
          setDimmerAtPrice(agreementData.dimmerAtControllerPrice);
          setDimmerUpgrade(agreementData.dimmerUpgradeQuantity);
          setBuilderPriceLQ(
            parseFloat(agreementData.builderLevvenQPrice).toFixed(2)
          );
          setPaymentTerm(paymentTermsCheck(agreementData.paymentTerms));
          setWarrantyPeriod(agreementData.warrantyPeriod);
          setDiscountMultiplier(agreementData.discountMultiplier);
          //setProjectPrice(agreementData.specialNegotiatedProjectPrice);
          setElectricalTradeNameControllers(
            agreementData.electricalTradeNameControllers
          );
          setIsTheBuilderDeliveringQToTheHomeOwner(
            agreementData.isTheBuilderDeliveringQToTheHomeOwner
          );
        })
        .catch((error) => setSaveError(error.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    };

    const checkErrors = () => {
      /*Conditions to check if there are any errors*/
      if (agreementName === "") setAgreementNameError(true);
      // if (agreementUrl === "") setAgreementUrlError(true);
      if (!builder || builder === null) setBuilderError(true);
      if (installer && installer !== null && whoDoesPurchasing === "")
        setWhoDoesPurchasingError(true);
      if (installer && installer !== null && whoDoesTakeoff === "")
        setWhoDoesTakeoffError(true);
      // if (!shipMethod || shipMethod === {} || shipMethod === null)
      //   setShipMethodError(true);
      if (!realEstateType || realEstateType === null)
        setRealEstateTypeError(true);
      if (!switchStyle || switchStyle === null) setSwitchStyleError(true);
      if (!switchColor || switchColor === null) setSwitchColorError(true);
      if (!amps || amps === null) {
        setAmpsError(true);
      }
      if (dimmerAtPrice && (!dimmerUpgrade || dimmerUpgrade === null))
        setDimmerUpgradeError(true);
      if (adminManage && builderPriceLQ === "") setBuilderPriceLQError(true);
      if (adminManage && (!paymentTerm || paymentTerm === null))
        setPaymentTermError(true);
      if (adminManage && warrantyPeriod === "") setWarrantyPeriod(true);
      // if (adminManage && discountMultiplier === "") setDiscountMultiplierError(true);
      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        agreementName === "" ||
        agreementUrl === "" ||
        !builder ||
        builder === null ||
        (installer && installer !== null && whoDoesPurchasing === "") ||
        (installer && installer !== null && whoDoesTakeoff === "") ||
        !realEstateType ||
        realEstateType === null ||
        !switchStyle ||
        switchStyle === null ||
        !switchColor ||
        switchColor === null ||
        !amps ||
        amps === null ||
        (dimmerAtPrice && (!dimmerUpgrade || dimmerUpgrade === null)) ||
        (adminManage && builderPriceLQ === "") ||
        (adminManage && (!paymentTerm || paymentTerm === null)) ||
        (adminManage && warrantyPeriod === "")
        // ||
        // (adminManage && discountMultiplier === "")
      )
        return true;
      else return false;
    };

    /*Save Changes*/
    const save = async (e) => {
      if (e) e.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      const errors = await checkErrors();
      if (errors) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/

        if (!create) {
          /*If the page is in edit mode*/
          await agreementServices
            .updateAgreementData(agreement.agreementID, {
              Name: agreementName,
              URL: encodeURIComponent(agreementName),
              BuilderID: builder.companyID,
              InstallerID:
                installer && installer !== null ? installer.companyID : null,
              BuilderAudioAgreement: audioAgreement,
              DefaultSwitchStyleCode: switchStyle.switchStyleCode,
              DefaultSwitchColorCode: switchColor.switchColorCode,
              DefaultSwitchID: switch1 ? switch1.productID : "",
              Purchasing:
                installer && installer !== null
                  ? whoDoesPurchasing.charAt(0)
                  : "B",
              Takeoff:
                installer && installer !== null
                  ? whoDoesTakeoff.charAt(0)
                  : "B",
              BuilderLevvenQPrice:
                builderPriceLQ !== "" &&
                builderPriceLQ !== null &&
                !isNaN(builderPriceLQ)
                  ? parseFloat(builderPriceLQ)
                  : 0,
              SpecialNegotiatedProjectPrice: false, //projectPrice,
              CoatedSwitch: coatedSwitch,
              DefaultCoatedSwitchID: switch2 ? switch2.productID : "",
              DefaultAmpsCode: amps.ampsCode,
              DefaultControllerID: controller ? controller.productID : "",
              DimmerAtControllerPrice: dimmerAtPrice,
              DimmerUpgradeQuantity: dimmerAtPrice ? dimmerUpgrade : 0,
              RealEstateType: realEstateType.charAt(0),
              DefaultShippingMethodCode: "",
              PaymentTerms: paymentTerm.charAt(0),
              WarrantyPeriod: warrantyPeriod,
              DiscountMultiplier: discountMultiplier,
              ModifiedByID: currentUserID,
              ElectricalTradeNameControllers: electricalTradeNameControllers,
              IsTheBuilderDeliveringQToTheHomeOwner:
                isTheBuilderDeliveringQToTheHomeOwner,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setOpenAlertDialog(false);
              setButtonLoading(false);
              setTabButtonLoading(false);
              setOpenSnackBar(true);
              setAgreementNameInTabs(agreementName);
              setSnackBarMessage(
                (!switch1 || switch1 === null) && (!switch2 || switch2 === null)
                  ? "Saved Successfully, but Default Coated Switch, and Default Controller are not found"
                  : !switch1 || switch1 === null
                  ? "Saved Successfully, but Default Switch is not found."
                  : !switch2 || switch2 === null
                  ? "Saved Successfully, but Default Coated Switch is not found."
                  : "Saved Successfully"
              );
              setSaveError("");
              fetchAgreementByID(response.data);
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        } else {
          /*If the page is in create mode*/
          await agreementServices
            .createAgreement({
              Name: agreementName,
              URL: encodeURIComponent(agreementName),
              BuilderID: builder.companyID,
              InstallerID:
                installer && installer !== null ? installer.companyID : null,
              BuilderAudioAgreement: audioAgreement,
              DefaultSwitchStyleCode: switchStyle.switchStyleCode,
              DefaultSwitchColorCode: switchColor.switchColorCode,
              DefaultSwitchID: switch1 ? switch1.productID : "",
              Purchasing:
                installer && installer !== null
                  ? whoDoesPurchasing.charAt(0)
                  : "B",
              Takeoff:
                installer && installer !== null
                  ? whoDoesTakeoff.charAt(0)
                  : "B",
              BuilderLevvenQPrice:
                builderPriceLQ !== "" &&
                builderPriceLQ !== null &&
                !isNaN(builderPriceLQ)
                  ? parseFloat(builderPriceLQ)
                  : 0,
              SpecialNegotiatedProjectPrice: false, //projectPrice,
              CoatedSwitch: coatedSwitch,
              DefaultCoatedSwitchID: switch2 ? switch2.productID : "",
              DefaultAmpsCode: amps.ampsCode,
              DefaultControllerID: controller ? controller.productID : "",
              DimmerAtControllerPrice: dimmerAtPrice,
              DimmerUpgradeQuantity: dimmerAtPrice ? dimmerUpgrade : 0,
              RealEstateType: realEstateType.charAt(0),
              DefaultShippingMethodCode: "",
              PaymentTerms: paymentTerm.charAt(0),
              WarrantyPeriod: warrantyPeriod,
              DiscountMultiplier: discountMultiplier,
              CreatedByID: currentUserID,
              ElectricalTradeNameControllers: electricalTradeNameControllers,
              IsTheBuilderDeliveringQToTheHomeOwner:
                isTheBuilderDeliveringQToTheHomeOwner,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setSaveError("");
              setButtonLoading(false);
              setTabButtonLoading(false);
              history.push({
                pathname: "/agreement-information",
                state: {
                  path: "agreement-information",
                  agreementID: response.data.agreementID,
                  agreementName: response.data.name,
                  create: false,
                  openSnackBar: true,
                  snackBarMessage:
                    (!switch1 || switch1 === null) &&
                    (!switch2 || switch2 === null)
                      ? "Saved Successfully, but Default Coated Switch, and Default Controller are not found"
                      : !switch1 || switch1 === null
                      ? "Saved Successfully, but Default Switch is not found."
                      : !switch2 || switch2 === null
                      ? "Saved Successfully, but Default Coated Switch is not found."
                      : "Saved Successfully",
                },
              });
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        }
      }
    };

    /*In edit mode, check if the agreement is linked to takeoffs*/
    const checkIfAgreementIsLinkedToTakeoff = async (e) => {
      if (e) e.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      const errors = await checkErrors();
      if (errors) {
        setSaveError("Please Check if All the Fields Are Valid");
        setButtonLoading(false);
        setTabButtonLoading(false);
        return;
      } else {
        /*Check if the chosen controller is valid*/
        if (!controller || controller === null) {
          setSaveError("Please Choose a Valid Default Controller");
          setButtonLoading(false);
          setTabButtonLoading(false);
          return;
        }
        /*Check if the chosen switch is valid*/

        if (!switch1 || switch1 === null) {
          setSaveError("Please Choose a Valid Default Switch");
          setButtonLoading(false);
          setTabButtonLoading(false);
          return;
        }
        setButtonLoading(true);
        setTabButtonLoading(true);
        /*Check if builder and installer belong to the same country, if installer exists*/
        if (
          installer &&
          installer !== null &&
          builder?.countryID !== installer?.countryID
        ) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setSaveError("Builder and Installer belong to different countries");
          return;
        }
        /*Check if the chosen controller is valid*/
        if (!controller || controller === null) {
          setButtonLoading(false);
          setTabButtonLoading(false);
          setSaveError(
            "The selected Amps does not have any related controller in the products list, please choose another one"
          );
          return;
        }
        if (!create) {
          /*If takeoffs and orders are related to the modified order, a warning appears*/
          orderServices
            .getOrdersAndTakeoffsByAgreementID(agreement.agreementID)
            .then((response) => {
              if (
                response.data.orders.length !== 0 ||
                response.data.takeoffs.length !== 0
              ) {
                setButtonLoading(false);
                setTabButtonLoading(false);
                setTakeoffs(response.data.takeoffs);
                setRelatedOrders(response.data.orders);
                setOpenAlertDialog(true);
                setSaveError("");
              } else {
                save();
              }
            })
            .catch((error) => setSaveError(error.response.data.message));
        } else {
          save();
        }
      }
    };

    /*Fetch Builder Companies*/
    const getBuilderCompanies = () => {
      companyServices
        .getCompaniesByType({ CompanyType: "B" })
        .then((response) => {
          setBuilders(response.data);
        })
        .catch((error) => setSaveError(error.response.data.message));
    };

    /*Fetch Installer Companies*/
    const getInstallerCompanies = () => {
      companyServices
        .getCompaniesByType({ CompanyType: "I" })
        .then((response) => {
          setInstallers(response.data);
        })
        .catch((error) => setSaveError(error.response.data.message));
    };

    /*Fetch Switch By Style And Color */
    const fetchSwitchesByColorAndStyle = (isCoated) => {
      productServices
        .getSwitchesByColorAndStyle({
          Style: switchStyle.switchStyleCode,
          Color: switchColor.switchColorCode,
          isCoated: isCoated,
        })
        .then((response) => {
          if (isCoated) setSwitch2(response.data[0]);
          else setSwitch1(response.data[0]);
        })
        .catch((error) => setSaveError(error.response.data.message));
    };

    /*Fetch Switch Styles*/
    const fetchSwitchStyles = () => {
      systemDataServices
        .getAllSwitchStyle()
        .then((response) => {
          setSwitchStyles(response.data);
        })
        .catch((error) => setSaveError(error.response.data.message));
    };

    /*Fetch Switch Colors*/
    const fetchSwitchColors = () => {
      systemDataServices
        .getAllSwitchColor()
        .then((response) => {
          setSwitchColors(response.data);
        })
        .catch((error) => setSaveError(error.response.data.message));
    };

    /*fetch controller amps*/
    const fetchControllerAmps = () => {
      systemDataServices
        .getAllAmps()
        .then((response) => {
          setAmpsOptions(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*fetch default controller*/
    const fetchDefaultController = () => {
      productServices
        .getControllerByAmpsTypeOnOffAsync(amps?.ampsCode)
        .then((response) => {
          setController(response.data);
        })
        .catch((error) => setSaveError(error.response.data.error.error));
    };

    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        return (
          d.substring(8, 10) +
          "/" +
          d.substring(4, 7) +
          "/" +
          d.substring(13, 15) +
          " at " +
          d.substring(16, 21)
        );
      }
    };

    /*Check Type*/
    const companyTypeCheck = (type) => {
      if (type === "B") {
        return "Builder";
      } else if (type === "I") {
        return "Installer";
      }
    };

    /*Check shipping address*/
    const shipMethodCheck = (ship) => {
      if (ship === "Prepaid and charge") {
        return "C";
      } else if (ship === "Prepaid") {
        return "P";
      } else if (ship === "Local pickup") {
        return "L";
      } else if (ship === "Ship with other goods") {
        return "S";
      }
    };

    /*Open a new tab with the current agreement URL*/
    const linkToAgreementPaper = () => {
      window.open(
        "/agreement-paper?id=" + encodeURIComponent(hashedID),
        "_blank"
      );
    };

    /*Copy the current agreement URL*/
    const copy = () => {
      const el = document.createElement("input");
      el.value =
        window.location.hostname +
        ":" +
        window.location.port +
        "/agreement-paper?id=" +
        encodeURIComponent(hashedID);
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setCopied(true);
    };

    /*Check Shipping Method Reversed */
    const shipMethodCheckReversed = (ship) => {
      if (ship === "C") {
        return "Prepaid and charge";
      } else if (ship === "P") {
        return "Prepaid";
      } else if (ship === "L") {
        return "Local pickup";
      } else if (ship === "S") {
        return "Ship with other goods";
      }
    };

    /*Check real Estate*/
    const realEstateCheck = (estate) => {
      if (estate === "R") {
        return "Residential";
      } else if (estate === "C") {
        return "Commercial";
      }
    };

    /*Check payment terms*/
    const paymentTermsCheck = (payment) => {
      if (payment === "S") {
        return "Standard Terms";
      } else if (payment === "M") {
        return "Management Special";
      }
    };

    /*Handle closing snackBar*/
    const handleSnackBarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setCopied(false);
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    useImperativeHandle(ref, () => ({
      setPristine,
      checkIfAgreementIsLinkedToTakeoff,
      save,
    }));
    return (
      <>
        {openAlertDialog ? (
          <CustomModal
            openModal={takeoffs.length !== 0 || relatedOrders.length !== 0}
          >
            <Typography variant="h6" component="span" align="left">
              {" "}
              Warning
            </Typography>
            <br />
            <br />
            <Typography variant="body1" component="span" align="left">
              {takeoffs.length !== 0 && relatedOrders.length !== 0
                ? "This Agreement is linked to one or more takeoffs and/or draft orders. Revising the Agreement will update these records."
                : takeoffs.length !== 0
                ? " This Agreement is linked to one or more takeoffs and/or draft orders. Revising the Agreement will update these records."
                : relatedOrders.length !== 0
                ? " This Agreement is linked to one or more orders. Revising the Agreement will update these records."
                : ""}
            </Typography>
            <br />
            <br />
            {takeoffs.length !== 0 ? (
              <Typography variant="body1" component="span" align="left">
                Takeoffs:
              </Typography>
            ) : null}
            <br />
            {takeoffs &&
              takeoffs.map((takeoff) => (
                <>
                  <Typography variant="body1" component="span" align="left">
                    - {takeoff.name}
                  </Typography>
                  <br />
                </>
              ))}
            <br />
            {relatedOrders.length !== 0 ? (
              <Typography variant="body1" component="span" align="left">
                Draft Orders:
              </Typography>
            ) : null}
            <br />
            {relatedOrders &&
              relatedOrders.map((order) => (
                <>
                  <Typography variant="body1" component="span" align="left">
                    -{" "}
                    {order.jobName !== ""
                      ? order.orderID + " - " + order.jobName
                      : order.orderID}
                  </Typography>
                  <br />
                </>
              ))}
            <br />
            <Typography variant="body1" component="span" align="left">
              Are you sure you want to proceed?
            </Typography>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                toggle={() => {
                  setTakeoffs([]);
                  setOpenAlertDialog(false);
                }}
              >
                Cancel
              </CustomButton>
              <div style={{ width: "10px" }} />
              <CustomButton toggle={save} loading={buttonLoading}>
                Proceed
              </CustomButton>
            </div>
          </CustomModal>
        ) : null}
        {copied ? (
          <SnackBar
            open={copied}
            message="URL Copied to Clipboard"
            severity="success"
            handleClose={handleSnackBarClose}
          />
        ) : null}
        <Paper elevation={0}>
          {/*Main Grid*/}
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="80vh"
            >
              <CircularProgress sx={{ color: "#0077c6" }} />
            </Grid>
          ) : (
            <>
              {/* {Prompt} */}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={9}
                  xl={9}
                  sx={{
                    borderRight: "2px solid #c4c4c4",
                    paddingRight: "20px",
                  }}
                >
                  {/*Form Grid*/}
                  <form
                    autoComplete="off"
                    onSubmit={checkIfAgreementIsLinkedToTakeoff}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Overview
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/* Agreement Name */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Agreement Name"
                          required
                          fullWidth
                          value={agreementName}
                          error={agreementNameError}
                          helperText={
                            agreementNameError
                              ? "Agreement Name field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage || salesManage) {
                              const newValue = e.target.value;
                              if (agreementName !== newValue) {
                                // Only update if the value has changed
                                setAgreementName(newValue);
                                setAgreementUrl(
                                  encodeURIComponent(newValue.toLowerCase())
                                );
                                if (agreementNameError)
                                  setAgreementNameError(false);
                                setDirty(); // Set the form as dirty if the value changed
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                        />
                      </Grid>
                      {/* Agreement URL */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Agreement URL"
                          fullWidth
                          value={agreementUrl}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <>
                                {!create ? (
                                  <InputAdornment position="end">
                                    <Tooltip arrow title="Copy URL">
                                      <IconButton>
                                        <Copy
                                          onClick={copy}
                                          color="#303030"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow title="Open URL">
                                      <IconButton>
                                        <Link
                                          onClick={linkToAgreementPaper}
                                          color="#303030"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ) : null}
                              </>
                            ),
                          }}
                        />
                      </Grid>
                      {/* Builder */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={builders}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          getOptionDisabled={(option) => option.name === ""}
                          value={builder ? builder : {}}
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setBuilder(value);
                              if (builderError) setBuilderError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Builder"
                              name="Builder"
                              fullWidth
                              size="small"
                              required
                              error={builderError}
                              helperText={
                                builderError ? "Builder field is empty" : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Installer */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={installers}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          getOptionDisabled={(option) => option.name === ""}
                          value={installer ? installer : {}}
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setInstaller(value);
                              if (!value || value === null) {
                                setWhoDoesTakeoff("Builder");
                                setWhoDoesPurchasing("Builder");
                              }
                              if (installerError) setInstallerError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Installer"
                              name="Installer"
                              fullWidth
                              size="small"
                              error={installerError}
                              helperText={
                                installerError ? "Installer field is empty" : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Who does takeoffs */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={companyTypes}
                          value={whoDoesTakeoff}
                          disabled={
                            create || adminManage || salesManage
                              ? installer && installer !== null
                                ? false
                                : true
                              : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setWhoDoesTakeoff(value);
                              if (whoDoesTakeoffError)
                                setWhoDoesTakeoffError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Who Does Takeoffs"
                              name="Who Does Takeoffs"
                              fullWidth
                              size="small"
                              required={installer && installer !== null}
                              error={whoDoesTakeoffError}
                              helperText={
                                whoDoesTakeoffError
                                  ? "Who Does Takeoffs field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Who does purchasing */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={companyTypes}
                          value={whoDoesPurchasing}
                          disabled={
                            create || adminManage || salesManage
                              ? installer && installer !== null
                                ? false
                                : true
                              : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setWhoDoesPurchasing(value);
                              if (whoDoesPurchasingError)
                                setWhoDoesPurchasingError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Who Does Purchasing"
                              name="Who Does Purchasing"
                              fullWidth
                              size="small"
                              required={installer && installer !== null}
                              error={whoDoesPurchasingError}
                              helperText={
                                whoDoesPurchasingError
                                  ? "Who Does Purchasing field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>

                      {/* Real Estate Type */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={realEstateTypes}
                          value={realEstateType}
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setRealEstateType(value);
                              if (realEstateTypeError)
                                setRealEstateTypeError(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Building Type"
                              name="Real Estate Type"
                              fullWidth
                              size="small"
                              required
                              error={realEstateTypeError}
                              helperText={
                                realEstateTypeError
                                  ? "Real Estate Type field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isTheBuilderDeliveringQToTheHomeOwner}
                              onClick={() => {
                                if (create || adminManage || salesManage) {
                                  setIsTheBuilderDeliveringQToTheHomeOwner(
                                    !isTheBuilderDeliveringQToTheHomeOwner
                                  );
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create || adminManage || salesManage
                                  ? false
                                  : true
                              }
                            />
                          }
                          label="Is the builder delivering Q to the home owner?"
                        />
                      </Grid>
                      {/*Audio agreement*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={audioAgreement}
                              onClick={() => {
                                if (create || adminManage || salesManage) {
                                  setAudioAgreement(!audioAgreement);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create || adminManage || salesManage
                                  ? false
                                  : true
                              }
                            />
                          }
                          label="Audio Agreement"
                        />
                      </Grid>
                      {/*Electrical Trade Name Controllers*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={electricalTradeNameControllers}
                              onClick={() => {
                                if (create || adminManage || salesManage) {
                                  setElectricalTradeNameControllers(
                                    !electricalTradeNameControllers
                                  );
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create || adminManage || salesManage
                                  ? false
                                  : true
                              }
                            />
                          }
                          label="Electrical Trade Name Controllers"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Switches
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/* Default switch style */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={switchStyles}
                          value={switchStyle ? switchStyle : {}}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          getOptionDisabled={(option) =>
                            option.switchStyleCode === 3
                          }
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setSwitchStyle(value);
                              if (switchStyleError) setSwitchStyleError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Default Switch Style"
                              name="Default Switch Style"
                              fullWidth
                              size="small"
                              required
                              error={switchStyleError}
                              helperText={
                                switchStyleError
                                  ? "Default Switch Style field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Default switch color */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={switchColors}
                          value={switchColor ? switchColor : {}}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setSwitchColor(value);
                              if (switchColorError) setSwitchColorError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Default Switch Color"
                              name="Default Switch Color"
                              fullWidth
                              size="small"
                              required
                              error={switchColorError}
                              helperText={
                                switchColorError
                                  ? "Default Switch Color field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/*Coated Switch*/}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={coatedSwitch}
                              onClick={() => {
                                if (create || adminManage || salesManage) {
                                  setCoatedSwitch(!coatedSwitch);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                create || adminManage || salesManage
                                  ? false
                                  : true
                              }
                            />
                          }
                          label="This builder requires the option of conformally coated switches for high-humidity areas."
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Controllers
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/* Default Controller Amps */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={ampsOptions}
                          value={amps ? amps : {}}
                          getOptionLabel={(option) =>
                            option.description ? option.description : ""
                          }
                          disabled={
                            create || adminManage || salesManage ? false : true
                          }
                          onChange={(event, value) => {
                            if (create || adminManage || salesManage) {
                              setAmps(value);
                              if (ampsError) setAmpsError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Default Controller Amps"
                              name="Default Controller Amps"
                              fullWidth
                              size="small"
                              required
                              error={ampsError}
                              helperText={
                                ampsError
                                  ? "Default Controller Amps field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Dimmers
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/*Dimmer At Price*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={dimmerAtPrice}
                              onClick={() => {
                                if (create || adminManage || salesManage) {
                                  setDimmerAtPrice(!dimmerAtPrice);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }}
                              disabled={
                                true
                                // create || adminManage || salesManage
                                //   ? false
                                //   : true
                              }
                            />
                          }
                          label="Offer dimmers at same price as on/off controllers?"
                        />
                      </Grid>
                      {/* Dimmer upgrade quantity */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Quantity of Dimmers at Price of On/Off"
                          required={dimmerAtPrice}
                          fullWidth
                          type="number"
                          value={parseInt(dimmerUpgrade)}
                          error={dimmerUpgradeError}
                          helperText={
                            dimmerUpgradeError
                              ? "Quantity of Dimmers at Price of On/Off field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage || salesManage) {
                              if (dimmerAtPrice) {
                                if (e.target.value < 0) {
                                  setDimmerUpgrade(e.target.value * -1);
                                } else {
                                  setDimmerUpgrade(e.target.value);
                                }
                                if (dimmerUpgradeError)
                                  setDimmerUpgradeError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            true
                            // create || adminManage || salesManage
                            //   ? dimmerAtPrice
                            //     ? false
                            //     : true
                            //   : true
                          }
                          inputProps={{ min: 1, maxLength: 10 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="h6" component="span">
                          Additional Pricing and Terms
                        </Typography>
                        <Underline />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      {/* Builder price for Levven Q */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          type="number"
                          label="Builder Price for Levven Q"
                          required={adminManage}
                          fullWidth
                          value={builderPriceLQ}
                          error={builderPriceLQError}
                          helperText={
                            builderPriceLQError
                              ? "Builder Price for Levven Q field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (adminManage) {
                              if (parseFloat(e.target.value) < 0) {
                                setBuilderPriceLQ(
                                  parseFloat(e.target.value * -1)
                                );
                              } else {
                                setBuilderPriceLQ(parseFloat(e.target.value));
                              }
                              if (builderPriceLQError)
                                setBuilderPriceLQError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={adminManage ? false : true}
                          inputProps={{ maxLength: 15, display: "decimal" }}
                          InputProps={{
                            inputProps: {
                              min: 0,
                              display: "decimal",
                              step: 0.01,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <p style={{ marginTop: "2px" }}>
                                  {builder && builder !== null
                                    ? builder.country.cur === "USD"
                                      ? "$"
                                      : "C$"
                                    : installer && installer !== null
                                    ? installer.country.cur === "USD"
                                      ? "$"
                                      : "C$"
                                    : ""}
                                </p>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {/* Payment Terms */}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={paymentTerms}
                          value={paymentTerm ? paymentTerm : ""}
                          disabled={adminManage ? false : true}
                          onChange={(event, value) => {
                            if (adminManage) {
                              setPaymentTerm(value);
                              if (paymentTermError) setPaymentTermError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Payment Terms"
                              name="Payment Terms"
                              fullWidth
                              size="small"
                              required={adminManage}
                              error={paymentTermError}
                              helperText={
                                paymentTermError
                                  ? "Payment Terms field is empty"
                                  : ""
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "off",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {/* Warranty Period*/}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Warranty Period (Years)"
                          required={adminManage}
                          fullWidth
                          type="number"
                          value={warrantyPeriod}
                          error={warrantyPeriodError}
                          helperText={
                            warrantyPeriodError
                              ? "Warranty Period field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (adminManage) {
                              if (e.target.value < 0) {
                                setWarrantyPeriod(e.target.value * -1);
                              } else {
                                setWarrantyPeriod(e.target.value);
                              }
                              if (warrantyPeriodError)
                                setWarrantyPeriodError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={adminManage ? false : true}
                          inputProps={{ min: 0, maxLength: 10 }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Discount Multiplier"
                          // required={adminManage}
                          fullWidth
                          type="number"
                          value={
                            discountMultiplier > 0 ? discountMultiplier : null
                          }
                          error={discountMultiplierError}
                          helperText={
                            discountMultiplierError
                              ? "Discount Multiplier field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (adminManage) {
                              const inputValue = e.target.value;
                              let value = parseFloat(inputValue);
                              console.log(value);
                              // Set to 0 if the input is cleared
                              if (inputValue == 0) {
                                // value = 0;
                                setDiscountMultiplier(0);
                              } else {
                                if (e.target.value < 0) {
                                  setDiscountMultiplier(
                                    parseFloat(e.target.value * -1)
                                  );
                                } else {
                                  setDiscountMultiplier(
                                    parseFloat(e.target.value)
                                  );
                                }
                              }

                              if (discountMultiplierError)
                                setDiscountMultiplierError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={adminManage ? false : true}
                          inputProps={{
                            min: 0.0,
                            display: "decimal",
                            step: "0.000000000001",
                          }}
                        />
                      </Grid>
                      {/*Save button*/}
                      {create || adminManage ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          <CustomButton
                            type="submit"
                            loading={buttonLoading}
                            startIcon={<Save />}
                          >
                            Save
                          </CustomButton>
                        </Grid>
                      ) : null}
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  xl={3}
                  sx={{
                    paddingLeft: "20px",
                    alignSelf: "flex-start",
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Creation Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {agreement ? convertUTC(agreement.creationDate) : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modification Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {agreement
                            ? convertUTC(agreement.modificationDate)
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Modified By:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="caption" component="span">
                          {agreement
                            ? agreement.modifiedBy
                              ? agreement.modifiedBy.firstName +
                                " " +
                                agreement.modifiedBy.lastName
                              : ""
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      border: "1px solid #c4c4c4",
                      padding: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="subtitle2" component="span">
                          Default Part Numbers
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Switch:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                        <Typography variant="caption" component="span">
                          {switch1 ? switch1.partNbr : "Not Found"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Coated Switch:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                        <Typography variant="caption" component="span">
                          {switch2 ? switch2.partNbr : "Not Found"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          component="span"
                        >
                          Controller:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                        <Typography variant="caption" component="span">
                          {controller ? controller.partNbr : "Not Found"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}
          {saveError !== "" ? (
            <AlertSnackBar
              message={saveError}
              handleClose={handleCloseAlertSnackbar}
              open={saveError !== ""}
            />
          ) : null}
        </Paper>
      </>
    );
  }
);

export default AgreementForm;
