import { Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TakeoffWizardForm from "../../../components/TakeoffWizardForm/TakeoffWizardForm";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Save, X } from "react-feather";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateTakeoffWizardPage = ({ setPageID, setPageLink }) => {
  const history = useHistory();
  const location = useLocation();

  const takeoffWiz = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoffWiz : ""
  );

  /*redirection*/
  if (takeoffWiz !== "M") {
    history.push("/dashboard");
  }

  const [tabButtonLoading, setTabButtonLoading] = useState(false);

  const duplicate = location.state
    ? location.state.duplicate
      ? location.state.duplicate
      : false
    : false;

  const takeoffWizardID = location.state
    ? location.state.id
      ? location.state.id
      : 0
    : 0;

  /*Takeoff Wizard Form Ref*/
  const takeoffWizardRef = useRef(null);

  useEffect(() => {
    //for drawer menu select
    setPageID(10);

    //for help button
    setPageLink("TakeoffWizard");
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate("/takeoff-wizard");
  
    } else {
  
      history.push("/takeoff-wizard");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            onClick={() => handleItemClick()}
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            Takeoff Setup
          </Link>
          <Typography key="2" color="text.primary" sx={{ fontSize: "24px" }}>
            New Step
          </Typography>
        </Breadcrumbs>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CustomButton
            startIcon={<Save />}
            toggle={() => takeoffWizardRef.current.save()}
            loading={tabButtonLoading}
          >
            Save
          </CustomButton>
          <div style={{ width: "10px" }} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await takeoffWizardRef.current.setPristine();
              history.push("/takeoff-wizard");
            }}
          >
            Cancel
          </CustomButton>
        </div>
      </div>
      <div style={{ height: "40px" }} />
      <TakeoffWizardForm
        create={true}
        duplicate={duplicate}
        ref={takeoffWizardRef}
        setTabButtonLoading={setTabButtonLoading}
        receivedTakeoffID={takeoffWizardID}
      />
    </Paper></>
  );
};

export default CreateTakeoffWizardPage;
