import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

export default function CustomModal({
  children,
  openModal,
  width = "",
  overflow = "",
  zIndex = 0,
}) {
  const [open, setOpen] = useState(openModal);

  const [windowWidth, setWindowWidth] = React.useState(0);

  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  window.addEventListener(
    "resize",
    function (event) {
      setWindowWidth(window.innerWidth);
    },
    true
  );

  useEffect(() => {
    return () => {
      setWindowWidth(0); // This worked for me
    };
  }, []);

  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: windowWidth > 700 ? (width !== "" ? width : "35vw") : "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "8px",
            overflow: overflow !== "" ? overflow : null,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
}
