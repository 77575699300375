import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement */

const baseUrl = apiConfig + "/helpText";

const getHelpTextByID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetHelpTextByHelpTextCode/" + data);
};

const getListOfHelpTexts = () => {
  return fetchWrapper.get(baseUrl + "/GetListOfHelpTexts");
};

const updateHelpText = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateHelpTextByCode", data);
};

export const helpTextServices = {
  getHelpTextByID,
  getListOfHelpTexts,
  updateHelpText,
};
