import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/roles";

const getCompanyRoles = (data) => {
  return fetchWrapper.get(baseUrl + "/GetCompanyRoles/" + data);
};

const getRoles = (data) => {
  return fetchWrapper.post(baseUrl + "/GetAllRolesList", data);
};

const getMyRoles = (data) => {
  return fetchWrapper.post(baseUrl + "/GetCompanyRolesList", data);
};

const deleteRoles = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteRoles", data);
};

const updateRoleData = (roleID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateRole/" + roleID, data);
};

const getRoleByID = (roleID) => {
  return fetchWrapper.get(baseUrl + "/GetRoleByID/" + roleID);
};

export const rolesServices = {
  getCompanyRoles,
  getRoles,
  getMyRoles,
  deleteRoles,
  updateRoleData,
  getRoleByID
};
