import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { Copy, Edit, Plus, Save, Trash2, X } from "react-feather";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { takeoffServices } from "../../services/takeoff.services";
import TakeoffForm from "../TakeoffForm/TakeoffForm";
import TakeoffQuantitiesTable from "../TakeoffQuantitiesTable/TakeoffQuantitiesTable";
import { Grid } from "@mui/material";
import { takeoffQuantityServices } from "../../services/takeoffquantity.service";
import { makeStyles } from "@material-ui/core/styles";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationTabs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  divStyle: {
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end",

    "@media (max-width: 1000px)": {
      display: "block",
    },
  },

  divStyle4: {
    width: "10px",
    height: "",
    "@media(max-width: 1000px)": {
      width: "",
      height: "10px",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TakeoffTabs({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  const classes = useStyles();

  /**Fetching data needed from Redux */
  const takeoffRole = useSelector((state) =>
    state.user.user ? state.user.user.user.role.takeoff : ""
  );

  const orderPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  /**Getting data sent from previous state */
  const takeoffID = fromURL
    ? decryptedObject
      ? decryptedObject.id
        ? decryptedObject.id
        : 0
      : 0
    : location.state
    ? location.state.id
      ? location.state.id
      : 0
    : 0;

  const [takeoffName, setTakeoffName] = useState(
    fromURL
      ? decryptedObject
        ? decryptedObject.name
          ? decryptedObject.name
          : ""
        : ""
      : location.state
      ? location.state.name
        ? location.state.name
        : ""
      : ""
  );

  if (takeoffID === 0 && takeoffName === "") {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  //redirection
  if (takeoffRole === "N") {
    history.push("/Dashboard");
  }

  //change tab we are in
  const [value, setValue] = useState(0);

  //for permissions
  const [adminManage, setAdminManage] = useState(false);

  //make delete button load until deletion process is complete
  const [loading, setLoading] = useState(false);

  //holds error encountered during the deletion process
  const [deleteError, setDeleteError] = useState("");

  //displays the alert dialog before deletion
  const [deleteRecord, setDeleteRecord] = useState(false);

  //holds if any change happened
  const [formChanged, setFormChanged] = useState(false);

  /**Holds Number of switches */
  const [switchesNum, setSwitchesNum] = React.useState(0);

  /**In case of error */
  const [takeoffError, setTakeoffError] = React.useState("");

  /**Holds number of plate positions (wall plates) */
  const [platePositionNum, setPlatePositionNum] = React.useState(0);

  /**true if there is quick mount */
  const [isQuickMount, setIsQuickMount] = React.useState(false);

  /**Holds rows to display */
  const [rows, setRows] = React.useState([]);

  /**For loading of button */
  const [buttonLoading, setButtonLoading] = React.useState(false);

  /**displays error if quantities are not equal */
  const [notEqualQuantities, setNotEqualQuantities] = React.useState("");

  /**Loading */
  const [newOrderLoading, setNewOrderLoading] = React.useState(false);

  /**In case of unsaved changes */
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  /**Getting data from previous page */
  const fromDashboard = fromURL
    ? decryptedObject
      ? decryptedObject.fromDashboard
        ? decryptedObject.fromDashboard
        : false
      : false
    : location.state
    ? location.state.fromDashboard
      ? location.state.fromDashboard
      : false
    : false;

  //we get state from previous page

  const doesTakeoff = fromURL
    ? decryptedObject
      ? decryptedObject.doesTakeoff
        ? decryptedObject.doesTakeoff
        : false
      : false
    : location.state
    ? location.state.doesTakeoff
      ? location.state.doesTakeoff
      : false
    : false;

  //to redirect to a certain tab when coming from previous page
  const tab = location.state
    ? location.state.tab
      ? location.state.tab
      : null
    : null;

  useEffect(() => {
    if (tab !== null) setValue(tab);
  }, []);

  const takeoffRef = useRef(null);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(
    location.state?.openSnackBar ? true : false
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [tempVal, setTempVal] = useState(0);

  const handleChange = (event, newValue) => {

    //change tab we are in
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(newValue)
    } else {
      setValue(newValue);
    }
  };

  const handleRouteClick = (route) => {
    
    if (formChanged) {
      setOpenDialog(true);
      setTempVal(route);
    } else {
      history.push(route);
    }
  };
  
  const handleConfirm = () => {
    setValue(tempVal);
    setFormChanged(false);
    setOpenDialog(false);
    if(tempVal === "/create-order"){goToOrder() }else history.push(tempVal)
  
      sessionStorage.removeItem("unsavedChanges");

  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const saveQuantities = () => {
    /**Function to save Quantities */
    setButtonLoading(true);

    if (isQuickMount === true && switchesNum !== platePositionNum) {
      setNotEqualQuantities(
        "The number of Switches (" +
          switchesNum +
          ") is not equal to the Switch Plate Positions (" +
          platePositionNum +
          ")"
      );
      setButtonLoading(false);
    } else {
      takeoffQuantityServices
        .updateExistingTakeoffQuantities({
          TakeoffID: takeoffID,
          ProductQuantities: rows,
          SwitchesNum: switchesNum,
          SwitchPlatePositionsNum: platePositionNum,
        })
        .then(() => {
          setOpenSnackBar(true);
          setPristine();
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");
        })
        .catch((error) => {
          setNotEqualQuantities(error.response.data.error.error);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  useEffect(() => {
    //for help button
    setPageLink("Takeoffs");

    //for drawer menu select
    if (!fromDashboard) setPageID(4);
    else setPageID(0);
  }, []);

  const deleteTakeoffs = () => {
    //function to delete Takeoff Value
    setLoading(true);

    setDeleteRecord(false);
    takeoffServices
      .deleteTakeoff({ TakeoffList: [takeoffID] })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/Takeoff",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  /*Launch Wizard*/
  const toWizard = () => {
    history.push({
      pathname: "/takeoff-product-quantities",
      state: {
        create: false,
        agreementID: location.state.agreementID,
        takeoffID: takeoffID,
        takeoffName: takeoffName,
      },
    });
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (takeoffRole === "M" && doesTakeoff === true) {
      setAdminManage(true);
    }
  }, []);

  const goToOrder = async () => {
    /**Function to go to Order */
    setNewOrderLoading(true);

    await takeoffServices
      .getTakeoffByID(takeoffID)
      .then((response) => {
        setNewOrderLoading(false);
        history.push({
          pathname: "/create-order",
          state: {
            takeoff: response.data,
            fromTakeoff: true,
          },
        });
      })
      .catch((error) => {
        setTakeoffError(error.response.data.error.error);
        setNewOrderLoading(false);
      });
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setTakeoffError("");
  };

  return (
    <>
    <ConfirmationPopup
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(value)}
        title="Leave Page?"
        message="You have unsaved changes. Are you sure you want to leave?"
        confirmLabel="Leave"
        cancelLabel="Cancel"
      />
      {/* {Prompt} */}
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      <Paper>
        {deleteRecord ? (
          <AlertDialogSlide
            title="Takeoff Deletion Error"
            textContent={"Are you sure you want delete this record?"}
            save={deleteTakeoffs}
            saveText="Yes"
            deleteDialog={true}
            unsave={() => setDeleteRecord(false)}
            unsaveText="No"
          />
        ) : (
          ""
        )}
        {takeoffError !== "" ? (
          <AlertSnackBar
            message={takeoffError}
            handleClose={handleCloseAlertSnackbar}
            open={takeoffError !== ""}
          />
        ) : null}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}

        {notEqualQuantities ? (
          <AlertSnackBar
            message={notEqualQuantities}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setNotEqualQuantities("");
            }}
            open={notEqualQuantities !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {fromDashboard ? (
                <Link
                  underline="always"
                  key="0"
                  color="inherit"
                  onClick={() =>
                    history.push({
                      pathname: "/Dashboard",
                    })
                  }
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  Dashboard
                </Link>
              ) : null}
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() => history.push("/Takeoff")}
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                Takeoffs
              </Link>

              {value === 1 ? (
                <Link
                  underline="always"
                  key="2"
                  color="inherit"
                  onClick={() => {
                    if (formChanged) {
                      handleConfirm();
                    } else {
                      setValue(0);
                    }
                  }}
                  sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
                >
                  {takeoffName}
                </Link>
              ) : (
                <Typography
                  key="2"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  {takeoffName}
                </Typography>
              )}
              {value === 1 ? (
                <Typography
                  key="3"
                  color="text.primary"
                  sx={{ fontSize: "24px" }}
                >
                  Products/Quantities
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {orderPermission === "M" && value === 0 ? (
                <CustomButton
                  extraMarginLeft={"10px"}
                  startIcon={<Plus />}
                  toggle={() => handleRouteClick("/create-order")}
                >
                  New Order
                </CustomButton>
              ) : null}
              {takeoffRole === "M" ? (
                <>
                  {value === 0 ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Copy />}
                        toggle={() =>
                          history.push({
                            pathname: "/create-takeoff",
                            state: {
                              duplicate: true,
                              takeoffID: takeoffID,
                            },
                          })
                        }
                      >
                        Duplicate
                      </CustomButton>
                    </>
                  ) : null}

                  {value === 1 ? (
                    <>
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Save />}
                        loading={buttonLoading}
                        toggle={saveQuantities}
                      >
                        Save
                      </CustomButton>
                    </>
                  ) : null}

                  <div style={{ width: "10px" }} />
                  <CustomButton startIcon={<Edit />} toggle={toWizard}>
                    Edit Takeoff
                  </CustomButton>
                  {value === 0 ? (
                    <>
                      {" "}
                      <div style={{ width: "10px" }} />
                      <CustomButton
                        startIcon={<Trash2 />}
                        loading={loading}
                        toggle={() => setDeleteRecord(true)}
                      >
                        Delete
                      </CustomButton>
                    </>
                  ) : null}
                </>
              ) : null}

              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  if (value === 0) await takeoffRef.current.setPristine();
                  else await setPristine();

                  await history.push("/takeoff");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginTop: "40px",
            }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Information"
                {...a11yProps(0)}
                style={{ margin: "50" }}
              />

              <Tab
                label="Products/Quantities"
                {...a11yProps(1)}
                style={{ margin: "50" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TakeoffForm
              adminManage={adminManage}
              receivedTakeoffID={takeoffID}
              setTakeoffNameForTabs={setTakeoffName}
              ref={takeoffRef}
              setFormChanged={setFormChanged}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2} style={{ marginBottom: "30px" }}>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>Total Number of Switches: {switchesNum}</Typography>
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                <Typography>
                  Total Number of Switch Plate Positions: {platePositionNum}
                </Typography>
              </Grid>
            </Grid>

            <TakeoffQuantitiesTable
              takeoffID={takeoffID}
              setFormChanged={setFormChanged}
              switchesNum={switchesNum}
              setDirty={setDirty}
              setPristine={setPristine}
              setSwitchesNum={setSwitchesNum}
              platePositionNum={platePositionNum}
              setPlatePositionNum={setPlatePositionNum}
              setIsQuickMount={setIsQuickMount}
              rows={rows}
              setRows={setRows}
            />
            <div style={{ height: "20px" }} />
            <div className={classes.divStyle}>
              {takeoffRole === "M" ? (
                <CustomButton
                  startIcon={<Save />}
                  loading={buttonLoading}
                  toggle={saveQuantities}
                >
                  Save
                </CustomButton>
              ) : null}
              <div className={classes.divStyle4} />
              <CustomButton
                startIcon={<X />}
                toggle={async () => {
                  if (value === 0) await takeoffRef.current.setPristine();
                  else await setPristine();

                  await history.push("/takeoff");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
