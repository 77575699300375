import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { orderServices } from "../../services/orders.service";
import Underline from "../Underline/Underline";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { useSelector } from "react-redux";

const OrderStatus = ({ receivedOrderID, isRMA = false }) => {
  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const [order, setOrder] = useState();
  const [currency, setCurrency] = useState("");

  const [error, setError] = useState("");

  /*Loading*/
  const [loading, setLoading] = useState(false);

  /*Functions*/
  /*Fetch Agreement data by ID*/
  const fetchOrderDataByID = () => {
    setLoading(true);
    orderServices
      .getOrderByID({ OrderID: receivedOrderID })
      .then((response) => {
        const orderData = response.data.order;
        setOrder(orderData);
        setCurrency(orderData.purchaser.company.country.cur);
        setLoading(false);
      })
      .catch((error) => setError(error.response.data.error.error))
      .finally(() => {
        setLoading(false);
      });
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      return (
        d.substring(8, 10) +
        "/" +
        d.substring(4, 7) +
        "/" +
        d.substring(13, 15) +
        " at " +
        d.substring(17, 21)
      );
    }
  };

  useEffect(() => {
    fetchOrderDataByID();
  }, []);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  return (
    <>
      {error !== "" ? (
        <AlertSnackBar
          message={error}
          handleClose={handleCloseAlertSnackbar}
          open={error !== ""}
        />
      ) : null}
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="80vh"
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Paper
            elevation={0}
            sx={{
              border: "1px solid #c4c4c4",
              padding: "20px",
            }}
          >
            <Grid container spacing={1}>
              {/* Levven Q  */}
              {/* <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ marginBottom: "10px" }}
            >
              <Typography variant="h6" fontWeight={500} component="span">
                Levven Q
              </Typography>
              <Underline />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Typography variant="body2" fontWeight={500} component="span">
                Levven Q MAC Address:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Typography variant="body2" component="span"></Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Typography variant="body2" fontWeight={500} component="span">
                Levven Q Registration Date:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Typography variant="body2" component="span"></Typography>
            </Grid> */}

              {/* Billing Contact */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ marginBottom: "10px" }}
              >
                <Typography variant="h6" fontWeight={500} component="span">
                  Billing Contact
                </Typography>
                <Underline />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Billing Contact Email:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={9} lg={9} xl={9}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? order.billingContact && order.billingContact !== null
                      ? order.billingContact.userName
                      : ""
                    : ""}
                </Typography>
              </Grid>

              {/* Shipping Details */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ margin: "20px 0 10px 0" }}
              >
                <Typography variant="h6" fontWeight={500} component="span">
                  Shipment Details
                </Typography>
                <Underline />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>

              {currentCompanyType === "L" ? (
                <>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      component="span"
                    >
                      Levven Sales Order Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Typography variant="body2" component="span">
                      {order && order !== null ? order.navNo : ""}
                    </Typography>
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Shipment Tracking Number:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? order.shipmentTrackingNbr : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  URL to Track Shipment:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? order.trackShipmentURL : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Shipment NAV Number:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? order.shipmentNo : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Shipped date:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? convertUTC(order.shippedDate) : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Shippment Fees:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null && order.shipmentFees !== null
                    ? `${currency === "CAD" ? "C$" : "$"} ${order.shipmentFees}`
                    : ""}
                </Typography>
              </Grid>
              {/* Order Details */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ margin: "20px 0 10px 0" }}
              >
                <Typography variant="h6" fontWeight={500} component="span">
                  Order Details
                </Typography>
                <Underline />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  {isRMA === false ? "Order ID" : "RMA ID"}:{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? receivedOrderID : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Creation date:{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? convertUTC(order.creationDate)
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Created by:{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? order.createdBy && order.createdBy !== null
                      ? order.createdBy.firstName +
                        " " +
                        order.createdBy.lastName
                      : ""
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Modification date:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? convertUTC(order.modificationDate)
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Modified by:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? order.modifiedBy && order.modifiedBy !== null
                      ? order.modifiedBy.firstName +
                        " " +
                        order.modifiedBy.lastName
                      : ""
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Submitted date:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? convertUTC(order.submittedDate)
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Submitted by:{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? order.submittedBy && order.submittedBy !== null
                      ? order.submittedBy?.firstName +
                        " " +
                        order.submittedBy?.lastName
                      : ""
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  In Process date:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null
                    ? convertUTC(order.inProcessDate)
                    : ""}
                </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" fontWeight={500} component="span">
                  Invoice Date:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="body2" component="span">
                  {order && order !== null ? convertUTC(order.invoiceDate) : ""}
                </Typography>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default OrderStatus;
