import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

const OverrideSwitchColorStep = ({
  setDirty,
  setSaveError,
  setFormChanged,
  setOverDefSwitchColor,
  overDefSwitchColor,
  agreement,
  orderID,
  switchColors = [],
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
  }, []);

  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Change Default Switch Color
          </Typography>
          <br />
          <Typography variant="h5" component="span">
            Select a different color, or leave as default.
          </Typography>
        </Grid>
        {/* Override Default Switch Color? */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Autocomplete
            id="combo-box-demo"
            componentName="Field1"
            options={switchColors}
            getOptionLabel={(option) =>
              option
                ? option.switchColorCode === agreement?.defaultSwitchColorCode
                  ? option.description + " (default)"
                  : option.description
                : ""
            }
            value={overDefSwitchColor ? overDefSwitchColor : ""}
            onChange={(event, value) => {
              setOverDefSwitchColor(value);
              setDirty();
              setFormChanged(true);
              sessionStorage.setItem(
                "unsavedChanges",
                JSON.stringify(true)
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Field1"
                label="Override Builder’s Default Switch Color?"
                fullWidth
                size="small"
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
      </Grid>
    </div>
  );
};

export default OverrideSwitchColorStep;
