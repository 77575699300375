import * as React from "react";
import { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { Plus, Save, XCircle, Edit } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { productServices } from "../../services/product.service";
import { agreementSpecificPricesServices } from "../../services/agreementSpecificPrices.service";
import SnackBar from "../SnackBar/SnackBar";
import CustomModal from "../CustomModal/CustomModal";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Description } from "@material-ui/icons";
import { agreementServices } from "../../services/agreement.services";
import TablePagination from "@mui/material/TablePagination";

//holds cloumns for table head
const headCells = [
  {
    id: "item",
    numeric: false,
    disablePadding: false,
    label: "Item",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default function AddItemAgreementTable({
  agreementID,
  setDirty,
  setPristine,
}) {
  //state holding the values item be displayed in the table
  var [rows, setRows] = React.useState([]);
  const [forEdit, setForEdit] = React.useState(false);

  //state for getting the error message if any
  const [error, setError] = React.useState("");

  //state item get the total number of records without pagination
  const [numberRecords, setNumberRecords] = React.useState(0);

  //used item refetch data from the database
  const [resubmit, setResubmit] = React.useState(false);

  //make page load until all the data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);
  //holds the page we are in in the table
  const [page, setPage] = React.useState(0);

  //holds the rows per page in the table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //values item be added item the table
  const [itemsList, setItemsList] = React.useState([]);
  const [item, setItem] = React.useState("");
  const [price, setPrice] = React.useState("");

  //starting ID in case we are adding item the table
  const [id, setID] = React.useState(1);

  //state for errors while saving values
  const [submitError, setSubmitError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  //if values saved successfully
  const [success, setSuccess] = React.useState(false);

  //item reference quantity item()
  const qtyToRef = useRef();
  const toRef = useRef();
  /*Builder*/
  const [builder, setBuilder] = React.useState();
  const [builderError, setBuilderError] = React.useState(false);

  /*Installer*/
  const [installer, setInstaller] = React.useState();
  const [installerError, setInstallerError] = React.useState(false);
  //permissions for page
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );
  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead style={{ backgroundColor: "#0077c6" }}>
        <TableRow>
          {headCells.map((headCell) =>
            headCell.id === "action" && props.rolePermission === "R" ? null : (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "center" : "left"}
                // padding={headCell.disablePadding ? "none" : "normal"}
                padding="normal"
                sx={{
                  color: "white",
                  backgroundColor: "#0077c6",
                }}
              >
                {headCell.id === "price"
                  ? installer && installer !== null
                    ? installer.country.cur === "USD"
                      ? "Price ($)"
                      : "Price (C$)"
                    : builder && builder !== null
                    ? builder.country.cur === "USD"
                      ? "Price ($)"
                      : "Price (C$)"
                    : "Price"
                  : headCell.label}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
  const getBulkProductFromDatabase = () => {
    //function to get data from the database
    setItemsList([]);
    productServices
      .getBulkProducts()
      .then((response) => {
        console.log(response);
        setPageLoad(false);

        response.data.forEach((product) => {
          return setItemsList((rows) => [
            ...rows,
            {
              itemID: product.productID,
              name: product.partNbr,
              description: product.description,
            },
          ]);
        });

        if (rolePermission === "M" && response.data.length > 0) {
          //we set focus on field
          // qtyToRef.current.focus();
        }
      })
      .catch((error) => {
        console.log(error);
        // setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  const getDataFromDatabase = () => {
    //function to get data from the database
    const data = {
      agreementID: agreementID,
      // keys: {
      //   page: 1,
      //   pageSize: 10,
      // },
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
    };
    agreementSpecificPricesServices
      .getAgreementSpecificPrices(data)
      .then((response) => {
        console.log(response);
        setPageLoad(false);

        setNumberRecords(response.data.totalNumberOfRecords);
        response.data.agreementSpecificPrices.forEach((product) => {
          return setRows((rows) => [
            ...rows,
            {
              itemPriceID: product.agreementSpecificPriceID,
              agreementID: product.agreementID,

              item: {
                itemID: product.product.productID,
                name: product.product.partNbr,
                description: product.product.description,
              },
              price: product.unitPrice,
              disabled: true,
            },
          ]);
        });

        if (rolePermission === "M" && response.data.length > 0) {
          //we set focus on field
          qtyToRef.current.focus();
        }
      })
      .catch((error) => {
        console.log(error);
        // setError(error.response.data.error.error);
        setPageLoad(false);
      });
    //   setPageLoad(false);
    //  const db_rows = [
    //     {
    //       itemPriceID: 1,
    //       item: { itemID: "1", name: "item1" },
    //       price: 10,
    //       disabled: true,
    //     },
    //   ];
    //   setNumberRecords(db_rows.length + 1);
    //   db_rows.forEach((product) => {
    //     return setRows((rows) => [
    //       ...rows,
    //       {
    //         itemPriceID: product.itemPriceID,
    //         agreementID: agreementID,

    //         item: product.item,
    //         price: product.price,
    //         disabled: product.disabled,
    //       },
    //     ]);
    //   });
  };

  /*Fetch Agreement data by ID*/
  const fetchAgreementByID = () => {
    setLoading(true);
    agreementServices
      .getAgreementByID({
        AgreementID: agreementID,
        IsEncrypted: false,
      })
      .then((response) => {
        const agreementData = response.data.agreement;
        setBuilder(agreementData.builder);
        setInstaller(agreementData.installer);
      })
      .catch((error) =>
        setErrorMessage({
          error: true,
          messages: [error.response.data.message],
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };
  /*Initial useEffect*/
  useEffect(() => {
    if (agreementID !== 0) {
      fetchAgreementByID();
    }
  }, []);

  useEffect(() => {
    //we get the data for the rows as well as the filters
    setRows([]);
    window.scrollTo(0, 0);
    setPageLoad(true);
    setError("");
    getBulkProductFromDatabase();
    getDataFromDatabase();
  }, [resubmit, page, rowsPerPage]);
  // useEffect(() => {
  //   //we get the data for the rows
  //   console.log("pagination in page", page, rowsPerPage);
  //   setRows([]);
  //   window.scrollTo(0, 0);
  //   setError("");

  //   getDataFromDatabase();
  // }, [page, rowsPerPage]);

  const deleteValue = (id) => {
    //function item delete a row
    var identification = parseInt(id);
    console.log(id);
    console.log(identification);

    agreementSpecificPricesServices
      .deleteAgreementSpecificPrice(identification)
      .then(() => {
        setResubmit(!resubmit);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setSubmitError(true);
        setErrorMessage({
          error: true,
          messages: [error.response.data.error.error],
        });
        setLoading(false);
      });
    // var array = [];
    // for (var i = 0; i < rows.length; i++) {
    //   if (rows[i].itemPriceID === identification) {
    //     continue;
    //   } else {
    //     console.log("delete item with id  ", identification);
    //   }
    //   array.push(rows[i]);
    // }

    // setRows(array);

    // setNumberRecords(numberRecords - 1);
  };

  const editValue = (editedItem) => {
    var identification = parseInt(editedItem?.itemPriceID);
    console.log(editedItem);
    const data = {
      agreementSpecificPriceID: editedItem?.itemPriceID,
      productID: editedItem.item.itemID,
      unitPrice: editedItem.price,
    };
    agreementSpecificPricesServices
      .updateAgreementSpecificPrices(data)
      .then(() => {
        setResubmit(!resubmit);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setSubmitError(true);
        // setErrorMessage({
        //   error: true,
        //   messages: [error.response.data.error.error],
        // });
        setLoading(false);
      });

    // const updatedRows = rows.map((row) =>
    //   row.itemPriceID === identification ? { ...row, ...editedItem } : row
    // );
    // setRows(updatedRows);
  };

  const addValues = () => {
    //function item add values item the table
    var error = false;
    var msg = [];

    if (item === null || isNaN(price) || price === "") {
      error = true;

      msg.push(
        "You have missing values, make sure you entered the item and the price."
      );
    }
    if (error === true) {
      setSubmitError(true);
    }

    setErrorMessage({ error: error, messages: msg });
    if (!isNaN(price) && item !== null && price !== "" && error === false) {
      const data = {
        agreementID: agreementID,

        productID: item.itemID,
        unitPrice: price,
      };

      agreementSpecificPricesServices
        .addAgreementSpecificPrices(data)
        .then(() => {
          setResubmit(!resubmit);
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          setSubmitError(true);
          setErrorMessage({
            error: true,
            messages: [error.response.data.error.error],
          });
          setLoading(false);
        });

      // setRows((rows) => [...rows, data]);
      // setNumberRecords(numberRecords + 1);

      setItem("");
      setPrice("");
      // setID(id + 1);
    }
    // toRef.current.focus();
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Saved Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />

      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {submitError ? (
        <CustomModal openModal={submitError}>
          <Grid container spacing={3} sx={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" component="span" align="left">
                Error
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {errorMessage.messages.map((error, index) => (
                <>
                  <Typography variant="body1" component="span" align="left">
                    - {error}
                  </Typography>
                  <br />
                </>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <CustomButton
                  toggle={() => {
                    setSubmitError(false);
                    setErrorMessage({});
                  }}
                >
                  Cancel
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </CustomModal>
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Custom pricing for Quick Kits purchased under this agreement
            </Typography>
          </Toolbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                rowCount={numberRecords}
                rolePermission={rolePermission}
              />
              <TableBody>
                {/* if you don't need item support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Prices item display. "}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.itemPriceID}
                        >
                          <TableCell
                            // colSpan="1" width="50%"
                            style={{
                              padding: "0px 5px 12px 10px",
                              colSpan: "1",
                              width: "50%",
                            }}
                          >
                            <Autocomplete
                              disabled={row.disabled}
                              id="combo-box-demo1"
                              options={itemsList}
                              getOptionLabel={(item) =>
                                item.name + ", " + item.description ?? ""
                              }
                              value={rows[index].item}
                              onChange={(event, value) => {
                                rows[index].item = value;
                                // setDirty();
                                // sessionStorage.setItem(
                                //   "unsavedChanges",
                                //   JSON.stringify(true)
                                // );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  disabled={!forEdit}
                                  {...params}
                                  label="Item"
                                  fullWidth
                                  size="small"
                                  required
                                  variant="standard"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              inputProps={{
                                style: { textAlign: "right" },
                                min: 0,
                              }}
                              disabled={row.disabled}
                              type="number"
                              variant="standard"
                              placeholder="Price"
                              defaultValue={
                                parseFloat(row.price).toFixed(2) ?? 0
                              }
                              onChange={(e) => {
                                if (e.target.value.length <= 12) {
                                  // if (e.target.value < 0) {
                                  //   rows[index].price =
                                  //     parseFloat(e.target.value) * -1;
                                  // } else {
                                  rows[index].price = parseFloat(
                                    e.target.value
                                  );
                                  // }
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              disabled={rolePermission === "R" ? true : false}
                              onClick={() => {
                                console.log(row);
                                deleteValue(row.itemPriceID);
                                // sessionStorage.setItem(
                                //   "unsavedChanges",
                                //   JSON.stringify(true)
                                // );
                                // setDirty();
                              }}
                            >
                              <XCircle />
                            </IconButton>
                            {row.disabled == false ? (
                              <IconButton
                                disabled={rolePermission === "R" ? true : false}
                                onClick={() => {
                                  console.log("save row");
                                  console.log(row);
                                  row.disabled = true;
                                  editValue(row);
                                  console.log(row);

                                  // sessionStorage.setItem(
                                  //   "unsavedChanges",
                                  //   JSON.stringify(true)
                                  // );
                                  // setDirty();
                                }}
                              >
                                <Save />
                              </IconButton>
                            ) : (
                              <IconButton
                                disabled={rolePermission === "R" ? true : false}
                                onClick={() => {
                                  console.log("for edit");
                                  console.log(row);

                                  row.disabled = false;
                                  const updatedRows = rows.map((item) =>
                                    item.itemPriceID === row.itemPriceID
                                      ? { ...item, ...row }
                                      : item
                                  );
                                  setRows(updatedRows);
                                  console.log(row);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                )}
                {rolePermission === "M" ? (
                  <>
                    <TableRow>
                      <TableCell
                        style={{
                          padding: "0px 5px 12px 10px",
                          colSpan: "1",
                          width: "50%",
                        }}
                      >
                        <Autocomplete
                          id="combo-box-demo1"
                          options={itemsList}
                          getOptionLabel={(item) =>
                            item.name
                              ? "" + item.name + "," + item.description
                              : ""
                          }
                          value={item ? item : {}}
                          onChange={(event, value) => {
                            setItem(value);
                            // setDirty();
                            // sessionStorage.setItem(
                            //   "unsavedChanges",
                            //   JSON.stringify(true)
                            // );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item"
                              fullWidth
                              size="small"
                              required
                              variant="standard"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          disabled={rolePermission === "R" ? true : false}
                          variant="standard"
                          type="number"
                          placeholder="Price"
                          value={price}
                          inputProps={{
                            style: { textAlign: "right" },
                            min: 0,
                            step: "0.5",
                            display: "decimal",
                          }}
                          onChange={(e) => {
                            // sessionStorage.setItem(
                            //   "unsavedChanges",
                            //   JSON.stringify(true)
                            // );
                            // setDirty();
                            if (e.target.value.length <= 12) {
                              if (e.target.value < 0) {
                                setPrice(parseFloat(e.target.value) * -1);
                              } else {
                                setPrice(parseFloat(e.target.value));
                              }
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {numberRecords < itemsList.length && (
                          <IconButton
                            disabled={rolePermission === "R" ? true : false}
                            onClick={addValues}
                          >
                            <Save />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        ></div>
      </Box>
    </>
  );
}
