import React, { useEffect } from "react";
import UsersTableComponent from "../../../components/UsersTableComponent/UsersTableComponent";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const UsersTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  const clientUsers = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  if (clientUsers === "N") {
    history.push("/dashboard");
  }

  useEffect(() => {
    //for help button
    setPageLink("Users");

    //for drawer menu
    setPageID(7);
  }, []);

  return (
    <div>
      <UsersTableComponent companyID={0} />
    </div>
  );
};

export default UsersTable;
