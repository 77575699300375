import React from "react";

const ReCaptchaText = () => {
  return (
    <p className="recaptcha-text-styles">
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </p>
  );
};

export default ReCaptchaText;
