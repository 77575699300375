import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";
/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/OrderNotes";

const createOrderNote = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateOrderNote", data);
};
const getOrderNoteListByID = (data) => {
  return fetchWrapper.post(baseUrl + "/GetOrderNoteList", data);
};

const updateOrderNote = (data) => {
  return fetchWrapper.post(baseUrl + "/UpdateOrderNotes", data);
};
const deleteOrderNote = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteOrderNotes", data);
};
const getOrderNoteById = (data) => {
  return fetchWrapper.get(baseUrl + "/GetOrderNoteById/" + data);
};

export const orderNotesServices = {
  createOrderNote,
  getOrderNoteListByID,
  updateOrderNote,
  deleteOrderNote,
  getOrderNoteById,
};
