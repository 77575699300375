import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RolesTableComponent from "../../../components/RolesTableComponent/RolesTableComponent";

const MyRolesTable = ({ setPageLink, setPageID }) => {
  const history = useHistory();

  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  const companyID = useSelector((state) =>
    state.user.user.user ? state.user.user.user.companyID : null
  );

  if (ownRoles === "N") {
    history.push("/dashboard");
  }

  useEffect(() => {
    //for help button
    setPageLink("Roles");

    //for drawer menu
    setPageID(2);
  }, []);

  return (
    <div>
      <RolesTableComponent
        myRoles={true}
        companyID={companyID}
        titleText={"My Roles"}
      />
    </div>
  );
};

export default MyRolesTable;
