import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { adminServices } from "../../services/admin.services";
import { companyServices } from "../../services/company.services";
import { rolesServices } from "../../services/roles.services";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

/*Global Variables*/
const permissions = ["Manage", "Read Only", "No Access"];
const specificPermissions = ["Read Only", "No Access"];

const RoleForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      createRole = false,
      companyAdmin = false,
      receivedRoleID = 0,
      receivedCompanyID = 0,
      systemGenerated = false,
      setCreateChangeRole,
      setOpenSnackBar,
      setTabButtonLoading,
      setRoleName,
      setMessage,
      setPageID,
      setFormChanged,
    },
    ref
  ) => {
    /*Companies List*/
    const [companies, setCompanies] = useState([]);
    /*Role*/
    const [role, setRole] = useState();
    /*Company Object*/
    const [company, setCompany] = useState();
    const [companyError, setCompanyError] = useState();
    /*Description*/
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    /*Is Levven Sales*/
    const [isLevvenSales, setIsLevvenSales] = useState(false);
    /*Company permissions*/
    const [ownCompany, setOwnCompany] = useState("Read Only");
    const [ownCompanyError, setOwnCompanyError] = useState(false);
    const [clientCompany, setClientCompany] = useState(
      receivedCompanyID === 1 ? "Read Only" : "No Access"
    );
    const [clientCompanyError, setClientCompanyError] = useState(false);
    /*Users permissions*/
    const [ownUsers, setOwnUsers] = useState("Read Only");
    const [ownUsersError, setOwnUsersError] = useState(false);
    const [clientUsers, setClientUsers] = useState(
      receivedCompanyID === 1 ? "Read Only" : "No Access"
    );
    const [clientUsersError, setClientUsersError] = useState(false);
    /*Roles permissions*/
    const [ownRoles, setOwnRoles] = useState("Read Only");
    const [ownRolesError, setOwnRolesError] = useState(false);
    const [clientRoles, setClientRoles] = useState(
      receivedCompanyID === 1 ? "Read Only" : "No Access"
    );
    const [clientRolesError, setClientRolesError] = useState(false);
    /*Prod Cat*/
    const [prodCat, setProdCat] = useState("Read Only");
    const [prodCatError, setProdCatError] = useState(false);
    /*Prod/Takeoff wizard takeoff*/
    const [wizardTakeoff, setWizardTakeoff] = useState("Read Only");
    const [wizardTakeoffError, setWizardTakeoffError] = useState(false);
    /*Agreement/Prices*/
    const [agreementPrices, setAgreementPrices] = useState(
      receivedCompanyID === 1 ? "Read Only" : "No Access"
    );
    const [agreementPricesError, setAgreementPricesError] = useState(false);
    /*Advanced Agreement*/
    const [advancedAgreement, setAdvancedAgreement] = useState(
      receivedCompanyID === 1 ? "Read Only" : "No Access"
    );
    const [advancedAgreementError, setAdvancedAgreementError] = useState(false);
    /*Takeoff*/
    const [takeoff, setTakeoff] = useState("Read Only");
    const [takeoffError, setTakeoffError] = useState(false);
    /*Order*/
    const [order, setOrder] = useState("Read Only");
    const [orderError, setOrderError] = useState(false);
    /*RMA*/
    const [rma, setRma] = useState("Read Only");
    const [rmaError, setRmaError] = useState(false);
    /*Submit Order*/
    const [submitOrder, setSubmitOrder] = useState(false);
    /*Submit RMA*/
    const [submitRma, setSubmitRma] = useState(false);
    /*Can see status*/
    const [canSeeStatus, setCanSeeStatus] = useState(false);
    /*Can view invoices*/
    const [canViewInvoices, setCanViewInvoices] = useState(false);
    /**Can Create Complementary PP */
    const [canCreateCompPP, setCanCreateCompPP] = useState(false);
    /*Loading*/
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");

    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
    const history = useHistory();
    const location = useLocation();

    /*Get the source from location*/
    const myRoles = location.state?.myRoles ? true : false;
    /*Get current user role ID*/
    const roleID = useSelector((state) =>
      state.user.user ? state.user.user.user.roleID : ""
    );
    /*Get current company ID from Redux*/
    const currentCompanyID = useSelector((state) =>
      state.user.user ? state.user.user.user.company.companyID : ""
    );

    /*Redirection*/
    if (!create && receivedRoleID === "") {
      history.push("/dashboard");
    }

    /*Functions*/

    /*Fetch company data by ID*/
    const fetchRoleDataById = () => {
      rolesServices
        .getRoleByID(receivedRoleID)
        .then((response) => {
          const roleData = response.data.role;
          setRole(roleData);
          setCompany(roleData.company);
          setDescription(roleData.description);
          setOwnCompany(rolePermissionCheck(roleData.ownCompany));
          setClientCompany(rolePermissionCheck(roleData.clientCompany));
          setOwnUsers(rolePermissionCheck(roleData.ownUsers));
          setClientUsers(rolePermissionCheck(roleData.clientUsers));
          setClientRoles(rolePermissionCheck(roleData.clientRoles));
          setOwnRoles(rolePermissionCheck(roleData.ownRoles));
          setProdCat(rolePermissionCheck(roleData.prodCat));
          setWizardTakeoff(rolePermissionCheck(roleData.takeoffWiz));
          setAgreementPrices(rolePermissionCheck(roleData.agreementPrices));
          setAdvancedAgreement(rolePermissionCheck(roleData.advAgreement));
          setTakeoff(rolePermissionCheck(roleData.takeoff));
          setOrder(rolePermissionCheck(roleData.orders));
          setRma(rolePermissionCheck(roleData.rma));
          setSubmitOrder(roleData.submitOrder);
          setSubmitRma(roleData.submitRMA);
          setCanSeeStatus(roleData.canViewACC);
          setCanViewInvoices(roleData.canViewInv);
          setIsLevvenSales(roleData.isLevvenSales);
          setCanCreateCompPP(roleData.canCreateCompPP);
        })
        .catch((error) => setSaveError(error.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Fetch company data by ID*/
    const fetchCompanyDataByID = () => {
      companyServices
        .getCompanyByID(receivedCompanyID)
        .then((response) => {
          setCompany(response.data);
        })
        .catch((error) => setSaveError(error.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Fetch All companies when there is no company ID received*/
    const fetchAllCompanies = () => {
      companyServices
        .getAllCompanies()
        .then((response) => {
          setCompanies(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.message);
        });
    };

    /*Check permission*/
    const rolePermissionCheck = (perm) => {
      if (perm === "M") {
        return "Manage";
      } else if (perm === "R") {
        return "Read Only";
      } else if (perm === "N") {
        return "No Access";
      }
    };

    /*Save Changes*/
    const save = (event) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      /*Conditions to check if there are any errors*/
      if (description === "") setDescriptionError(true);
      if (ownCompany === "" || ownCompany === null) setOwnCompanyError(true);
      if ((clientCompany === "" || clientCompany === null) && adminManage)
        setClientCompanyError(true);
      if (ownUsers === "" || ownUsers === null) setOwnUsersError(true);
      if ((clientUsers === "" || clientUsers === null) && adminManage)
        setClientUsersError(true);
      if (ownRoles === "" || ownRoles === null) setOwnRolesError(true);
      if ((clientRoles === "" || clientRoles === null) && adminManage)
        setClientRolesError(true);
      if (prodCat === "" || prodCat === null) setProdCatError(true);
      if (wizardTakeoff === "" || wizardTakeoff === null)
        setWizardTakeoffError(true);
      if ((agreementPrices === "" || agreementPrices === null) && adminManage)
        setAgreementPricesError(true);
      if (
        (advancedAgreement === "" || advancedAgreement === null) &&
        adminManage
      )
        setAdvancedAgreementError(true);
      if (takeoff === "" || takeoff === null) setTakeoffError(true);
      if (order === "" || order === null) setOrderError(true);
      if (rma === "" || rma === null) setRmaError(true);
      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        description === "" ||
        ownCompany === "" ||
        ownCompany === null ||
        ((clientCompany === "" || clientCompany === null) && adminManage) ||
        ownUsers === "" ||
        ownUsers === null ||
        ((clientUsers === "" || clientUsers === null) && adminManage) ||
        ownRoles === "" ||
        ownRoles === null ||
        ((clientRoles === "" || clientRoles === null) && adminManage) ||
        prodCat === "" ||
        prodCat === null ||
        wizardTakeoff === "" ||
        wizardTakeoff === null ||
        ((agreementPrices === "" || agreementPrices === null) && adminManage) ||
        ((advancedAgreement === "" || advancedAgreement === null) &&
          adminManage) ||
        takeoff === "" ||
        takeoff === null ||
        order === "" ||
        order === null ||
        rma === "" ||
        rma === null
      ) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        if (!create) {
          /*If the page is in edit mode*/
          rolesServices
            .updateRoleData(role.roleID, {
              Description: description,
              CompanyID: company?.companyID,
              OwnCompany: ownCompany.charAt(0),
              ClientCompany:
                clientCompany !== "" ? clientCompany.charAt(0) : "N",
              OwnUsers: ownUsers.charAt(0),
              ClientUsers: clientUsers !== "" ? clientUsers.charAt(0) : "N",
              OwnRoles: ownRoles.charAt(0),
              ClientRoles: clientRoles !== "" ? clientRoles.charAt(0) : "N",
              ProdCat: prodCat.charAt(0),
              TakeoffWiz: wizardTakeoff.charAt(0),
              AgreementPrices:
                agreementPrices !== "" ? agreementPrices.charAt(0) : "N",
              AdvAgreement:
                advancedAgreement !== "" ? advancedAgreement.charAt(0) : "N",
              Takeoff: takeoff.charAt(0),
              Orders: order.charAt(0),
              SubmitOrder: submitOrder,
              RMA: rma.charAt(0),
              SubmitRMA: submitRma,
              CanViewACC: canSeeStatus,
              CanViewInv: canViewInvoices,
              IsLevvenSales: isLevvenSales,
              CanCreateCompPP: canCreateCompPP,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setButtonLoading(false);
              setTabButtonLoading(false);
              setMessage("Saved Successfully");
              setOpenSnackBar(true);
              setRoleName(description);
              setSaveError("");
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        } else {
          /*If the page is in create mode*/
          adminServices
            .createRole({
              Description: description,
              CompanyID: company?.companyID,
              OwnCompany: ownCompany.charAt(0),
              ClientCompany:
                clientCompany !== "" ? clientCompany.charAt(0) : "N",
              OwnUsers: ownUsers.charAt(0),
              ClientUsers: clientUsers !== "" ? clientUsers.charAt(0) : "N",
              OwnRoles: ownRoles.charAt(0),
              ClientRoles: clientRoles !== "" ? clientRoles.charAt(0) : "N",
              ProdCat: prodCat.charAt(0),
              TakeoffWiz: wizardTakeoff.charAt(0),
              AgreementPrices:
                agreementPrices !== "" ? agreementPrices.charAt(0) : "N",
              AdvAgreement:
                advancedAgreement !== "" ? advancedAgreement.charAt(0) : "N",
              Takeoff: takeoff.charAt(0),
              Orders: order.charAt(0),
              SubmitOrder: submitOrder,
              RMA: rma.charAt(0),
              SubmitRMA: submitRma,
              CanViewACC: canSeeStatus,
              CanViewInv: canViewInvoices,
              IsLevvenSales: isLevvenSales,
              CanCreateCompPP: canCreateCompPP,
            })
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setButtonLoading(false);
              setTabButtonLoading(false);
              setSaveError("");
              if (createRole) {
                setMessage("Saved Successfully");
                setOpenSnackBar(true);
                setCreateChangeRole(false);
                setRoleName(response.data.description);
                setRole(response.data);
              } else {
                history.push({
                  pathname: "/role-information",
                  state: {
                    roleID: response.data.roleID,
                    roleName: response.data.description,
                    source:
                      response.data.companyID === currentCompanyID
                        ? "My Roles"
                        : "Roles",
                    linkSource:
                      response.data.companyID === currentCompanyID
                        ? "/my-roles"
                        : "/Roles",
                    titleText:
                      response.data.description +
                      " at " +
                      response.data.company.name,
                    companyID: response.data.companyID,
                    selfRole: response.data.roleID === roleID ? true : false,
                    systemGenerated: response.data.systemGenerated,
                    openSnackBar: true,
                  },
                });
              }
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        }
      }
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    useImperativeHandle(ref, () => ({
      setPristine,
      save,
    }));

    /*Initial useEffect*/
    useEffect(() => {
      if (!create && receivedRoleID !== 0) {
        fetchRoleDataById();
      } else {
        if (receivedCompanyID === 0) fetchAllCompanies();
        else fetchCompanyDataByID();
      }
    }, []);

    return (
      <>
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{ borderRight: "2px solid #c4c4c4", paddingRight: "20px" }}
              >
                {/*Form Grid*/}
                <form
                  onSubmit={
                    create || adminManage || companyAdmin
                      ? systemGenerated
                        ? ""
                        : save
                      : ""
                  }
                >
                  <Grid container spacing={6}>
                    {create && receivedCompanyID === 0 ? (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Company
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                        {/*Company*/}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={companies}
                            value={company ? company : ""}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            onChange={(event, value) => {
                              if (create) {
                                setCompany(value);
                                if (companyError) setCompanyError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }}
                            disabled={create ? false : true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Company Name"
                                fullWidth
                                size="small"
                                required
                                error={companyError}
                                helperText={
                                  companyError ? "Company field is empty" : ""
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    ) : null}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Role Details
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Description*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Description"
                        required
                        fullWidth
                        value={description}
                        error={descriptionError}
                        helperText={
                          descriptionError ? "Description field is empty" : ""
                        }
                        onChange={(e) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setDescription(e.target.value);
                              if (descriptionError) setDescriptionError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    {/*Is Levven Sales*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {adminManage && company?.type === "L" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isLevvenSales}
                              onClick={() => {
                                if (create || adminManage) {
                                  if (!systemGenerated) {
                                    setIsLevvenSales(!isLevvenSales);
                                    setDirty();
                                    setFormChanged(true);
                                    sessionStorage.setItem(
                                      "unsavedChanges",
                                      JSON.stringify(true)
                                    );
                                  }
                                }
                              }}
                              disabled={
                                create || adminManage
                                  ? systemGenerated
                                    ? true
                                    : false
                                  : true
                              }
                            />
                          }
                          label="Is Levven Sales"
                        />
                      ) : (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Permissions
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Own Company*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={permissions}
                        value={ownCompany}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setOwnCompany(value);
                              if (ownCompanyError) setOwnCompanyError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Own Company"
                            fullWidth
                            size="small"
                            required
                            error={ownCompanyError}
                            helperText={
                              ownCompanyError
                                ? "Own Company field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Client Company*/}
                    {adminManage && company?.type === "L" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={permissions}
                          value={clientCompany}
                          onChange={(e, value) => {
                            if (create || adminManage) {
                              if (!systemGenerated) {
                                setClientCompany(value);
                                if (clientCompanyError)
                                  setClientCompanyError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Client Companies"
                              fullWidth
                              size="small"
                              required
                              error={clientCompanyError}
                              helperText={
                                clientCompanyError
                                  ? "Client Company field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Own Users*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={permissions}
                        value={ownUsers}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setOwnUsers(value);
                              if (ownUsersError) setOwnUsersError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Own Users"
                            fullWidth
                            size="small"
                            required
                            error={ownUsersError}
                            helperText={
                              ownUsersError ? "Own Users field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Client Users*/}
                    {adminManage && company?.type === "L" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={permissions}
                          value={clientUsers}
                          onChange={(e, value) => {
                            if (create || adminManage) {
                              if (!systemGenerated) {
                                setClientUsers(value);
                                if (clientUsersError)
                                  setClientUsersError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Client Users"
                              fullWidth
                              size="small"
                              required
                              error={clientUsersError}
                              helperText={
                                clientUsersError
                                  ? "Client Users field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Own Roles*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={permissions}
                        value={ownRoles}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setOwnRoles(value);
                              if (ownRolesError) setOwnRolesError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Own Roles"
                            fullWidth
                            size="small"
                            required
                            error={ownRolesError}
                            helperText={
                              ownRolesError ? "Own Roles field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Client Roles*/}
                    {adminManage && company?.type === "L" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={permissions}
                          value={clientRoles}
                          onChange={(e, value) => {
                            if (create || adminManage) {
                              if (!systemGenerated) {
                                setClientRoles(value);
                                if (clientRolesError)
                                  setClientRolesError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Client Roles"
                              fullWidth
                              size="small"
                              required
                              error={clientRolesError}
                              helperText={
                                clientRolesError
                                  ? "Client Roles field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Prod*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={
                          company?.type !== "L"
                            ? specificPermissions
                            : permissions
                        }
                        value={prodCat}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setProdCat(value);
                              if (prodCatError) setProdCatError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Product"
                            fullWidth
                            size="small"
                            required
                            error={prodCatError}
                            helperText={
                              prodCatError ? "Prod Cat field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/*Prod/Takeoff wizard setup*/}
                    {company?.type === "L" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={permissions}
                          value={wizardTakeoff}
                          onChange={(e, value) => {
                            if (create || adminManage || companyAdmin) {
                              if (!systemGenerated) {
                                setWizardTakeoff(value);
                                if (wizardTakeoffError)
                                  setWizardTakeoffError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Takeoff Setup"
                              fullWidth
                              size="small"
                              required
                              error={wizardTakeoffError}
                              helperText={
                                wizardTakeoffError
                                  ? "Prod/Cat Wizard Takeoff field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}

                    {/*Agreement/Prices*/}
                    {company?.type !== "R" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={
                            company?.type !== "L"
                              ? specificPermissions
                              : permissions
                          }
                          value={agreementPrices}
                          onChange={(e, value) => {
                            if (create || adminManage) {
                              if (!systemGenerated) {
                                setAgreementPrices(value);
                                if (agreementPricesError)
                                  setAgreementPricesError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agreement/Prices"
                              fullWidth
                              size="small"
                              required
                              error={agreementPricesError}
                              helperText={
                                agreementPricesError
                                  ? "Agreement/Prices field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Advanced Agreement*/}
                    {company?.type !== "R" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={
                            company?.type !== "L"
                              ? specificPermissions
                              : permissions
                          }
                          value={advancedAgreement}
                          onChange={(e, value) => {
                            if (create || adminManage) {
                              if (!systemGenerated) {
                                setAdvancedAgreement(value);
                                if (advancedAgreementError)
                                  setAdvancedAgreementError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Advanced Agreement"
                              fullWidth
                              size="small"
                              required
                              error={advancedAgreementError}
                              helperText={
                                advancedAgreementError
                                  ? "Advanced Agreement field is empty"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Takeoff*/}
                    {company?.type !== "R" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={permissions}
                          value={takeoff}
                          onChange={(e, value) => {
                            if (create || adminManage || companyAdmin) {
                              if (!systemGenerated) {
                                setTakeoff(value);
                                if (takeoffError) setTakeoffError(false);
                                setDirty();
                                setFormChanged(true);
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(true)
                                );
                              }
                            }
                          }}
                          disabled={
                            create || adminManage || companyAdmin
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Takeoff"
                              fullWidth
                              size="small"
                              required
                              error={takeoffError}
                              helperText={
                                takeoffError ? "Takeoff field is empty" : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    {/*Order*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={permissions}
                        value={order}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setOrder(value);
                              if (orderError) setOrderError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Order"
                            fullWidth
                            size="small"
                            required
                            error={orderError}
                            helperText={
                              orderError ? "Order field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*RMA*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={permissions}
                        value={rma}
                        onChange={(e, value) => {
                          if (create || adminManage || companyAdmin) {
                            if (!systemGenerated) {
                              setRma(value);
                              if (rmaError) setRmaError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="RMA"
                            fullWidth
                            size="small"
                            required
                            error={rmaError}
                            helperText={rmaError ? "RMA field is empty" : ""}
                          />
                        )}
                      />
                    </Grid>
                    {company?.type !== "R" ? (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} />
                    ) : null}
                    {/*Submit Order*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" component="span">
                        {company?.type !== "L"
                          ? "Submit Order"
                          : "Submit Order on Behalf of Customer"}
                      </Typography>
                      <Switch
                        checked={submitOrder}
                        onChange={() => setSubmitOrder(!submitOrder)}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                      />
                    </Grid>
                    {/*Submit RMA*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" component="span">
                        {company?.type !== "L"
                          ? "Submit RMA"
                          : "Submit RMA on Behalf of Customer"}
                      </Typography>
                      <Switch
                        checked={submitRma}
                        onChange={() => setSubmitRma(!submitRma)}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                      />
                    </Grid>
                    {/*Can see accounting status/available credit*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" component="span">
                        Can see accounting status/available credit
                      </Typography>
                      <Switch
                        checked={canSeeStatus}
                        onChange={() => setCanSeeStatus(!canSeeStatus)}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                      />
                    </Grid>
                    {/*Can see accounting status/available credit*/}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" component="span">
                        Can view invoices
                      </Typography>
                      <Switch
                        checked={canViewInvoices}
                        onChange={() => setCanViewInvoices(!canViewInvoices)}
                        disabled={
                          create || adminManage || companyAdmin
                            ? systemGenerated
                              ? true
                              : false
                            : true
                        }
                      />
                    </Grid>

                    {/**Complementary Orders */}
                    {adminManage && company?.type === "L" ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" component="span">
                          Can Create Complementary Quick Kit
                        </Typography>
                        <Switch
                          checked={canCreateCompPP}
                          onChange={() => setCanCreateCompPP(!canCreateCompPP)}
                          disabled={
                            create || adminManage
                              ? systemGenerated
                                ? true
                                : false
                              : true
                          }
                        />
                      </Grid>
                    ) : null}

                    {/*Save button*/}
                    {create || adminManage || companyAdmin ? (
                      systemGenerated ? null : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          <CustomButton
                            type="submit"
                            loading={buttonLoading}
                            startIcon={<Save />}
                          >
                            Save
                          </CustomButton>
                        </Grid>
                      )
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ height: "10px" }}
                    ></Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Company:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {company ? company.name : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
        {saveError !== "" ? (
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        ) : null}
      </>
    );
  }
);

export default RoleForm;
