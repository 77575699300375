import React, { useState, useEffect, useRef } from "react";
import CardContent from "@mui/material/CardContent";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { Grid } from "@mui/material";
import { systemDataServices } from "../../services/systemData.services";
import { Save } from "react-feather";
import SnackBar from "../SnackBar/SnackBar";
import validator from "validator";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { useSelector } from "react-redux";

export const SystemConfigurations = ({
  setFormChanged,
  setDirty,
  setPristine,
}) => {
  //Page in System Preferences holding most system variables

  //Getting username of user currently logged in
  const userName = useSelector((state) =>
    state.user.user ? state.user.user.user.userName : ""
  );

  //on hold banner text
  const [companyStatusText, setCompanyStatusText] = useState("");
  const [companyStatusTextError, setCompanyStatusTextError] = useState("");

  //Part Number 1
  const [partNb1, setPartNb1] = useState("");
  const [partNb1Error, setPartNb1Error] = useState("");

  //Part Number 2
  const [partNb2, setPartNb2] = useState("");
  const [partNb2Error, setPartNb2Error] = useState("");

  //Part Number 3
  const [partNb3, setPartNb3] = useState("");
  const [partNb3Error, setPartNb3Error] = useState("");

  //Part Number 4
  const [partNb4, setPartNb4] = useState("");
  const [partNb4Error, setPartNb4Error] = useState("");

  //Part Number 5
  const [partNb5, setPartNb5] = useState("");
  const [partNb5Error, setPartNb5Error] = useState("");

  //Part Number 6
  const [partNb6, setPartNb6] = useState("");
  const [partNb6Error, setPartNb6Error] = useState("");

  //Part Number 7
  const [partNb7, setPartNb7] = useState("");
  const [partNb7Error, setPartNb7Error] = useState("");

  //Part Number 8
  const [partNb8, setPartNb8] = useState("");
  const [partNb8Error, setPartNb8Error] = useState("");

  //Model Home PartNbr
  const [partNb, setPartNb] = useState("");
  const [partNbError, setPartNbError] = useState("");

  //Cookies Alert Text
  const [cookiesAlertText, setCookiesAlertText] = useState("");
  const [cookiesAlertTextError, setCookiesAlertTextError] = useState(false);

  //Shipping GL Account
  const [shippingGLAccount, setShippingGLAccount] = useState("");
  const [shippingGLAccountError, setShippingGLAccountError] = useState(false);

  //Shipping Tax Group Code
  const [shippingTaxGroupCode, setShippingTaxGroupCode] = useState("");
  const [shippingTaxGroupCodeError, setShippingTaxGroupCodeError] =
    useState(false);

  //for Levven Contacts for Order Modification
  const [levvenContacts, setLevvenContacts] = useState("");
  const [levvenContactsError, setLevvenContactsError] = useState("");

  //levven accounting email
  const [accountingEmail, setAccountingEmail] = useState("");
  const [accountingEmailError, setAccountingEmailError] = useState(false);
  const [notValidAccountingEmail, setNotValidAccountingEmail] = useState(false);

  //levven Orders email
  const [levvenOrdersEmail, setlevvenOrdersEmail] = useState("");
  const [levvenOrdersEmailError, setlevvenOrdersEmailError] = useState(false);
  const [notValidOrdersEmail, setNotValidOrdersEmail] = useState(false);

  //Surcharge Amount
  const [surchargeAmount, setSurchargeAmount] = useState(0.0);
  const [surchargeAmountError, setSurchargeAmountError] = useState(false);

  //for address Verification
  const [verifyAddress, setVerifyAddress] = useState(false);

  /**For Order Fulfillment */
  const [orderFulfillmentThreshold, setOrderFulfillmentThreshold] = useState(1);
  const [orderFulfillmentThresholdError, setOrderFulfillmentThresholdError] =
    useState(false);
  const [emailToSendTo, setEmailToSendTo] = useState(null);
  const [emailToSendToError, setEmailToSendToError] = useState(false);
  const [InvalidEmailToSendToError, setInvalidEmailToSendToError] =
    useState(false);
  const [bulkEmailToSendTo, setBulkEmailToSendTo] = useState(null);
  const [bulkEmailToSendToError, setBulkEmailToSendToError] = useState(false);
  const [InvalidBulkEmailToSendToError, setInvalidBulkEmailToSendToError] =
    useState(false);

  /**Holds Melissa API License Key */
  const [melissaLicenseKey, setMelissaLicenseKey] = useState("");
  const [melissaLicenseKeyError, setMelissaLicenseKeyError] = useState(false);

  /**Holds Melissa Autocomplete API License Key */
  const [melissaAutocompleteLicenseKey, setMelissaAutocompleteLicenseKey] =
    useState("");
  const [
    melissaAutocompleteLicenseKeyError,
    setMelissaAutocompleteLicenseKeyError,
  ] = useState(false);

  /**Holds Google Recaptcha values */
  const [recaptchaKey, setRecaptchaKey] = useState("");
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [recaptchaSecretKey, setRecaptchaSecretKey] = useState("");
  const [recaptchaSecretError, setRecaptchaSecretError] = useState(false);
  const [recaptchaKeyV2, setRecaptchaKeyV2] = useState("");
  const [recaptchaErrorV2, setRecaptchaErrorV2] = useState(false);
  const [recaptchaSecretKeyV2, setRecaptchaSecretKeyV2] = useState("");
  const [recaptchaSecretErrorV2, setRecaptchaSecretErrorV2] = useState(false);
  const [recaptchaThreshold, setRecaptchaThreshold] = useState(0);
  const [recaptchaThresholdError, setRecaptchaThresholdError] = useState(false);

  //Error
  const [error, setError] = useState("");

  /*Loading*/
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(false);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  useEffect(() => {
    //we initialize the companyStatusText and levven accounting email to an empty string on first render to make sure the value we get is always up to date
    setCompanyStatusText("");
    setAccountingEmail("");
    getAllData();
  }, []);

  //function to save banner value in database
  const updateAllSystemVariables = async (e) => {
    //for page to not reload after submitting form
    if (e) e.preventDefault();
    setButtonLoading(true);
    if (companyStatusText === "") {
      setCompanyStatusTextError(true);
      setError("Company Status Banner cannot be empty");
    }

    if (accountingEmail === "") {
      setAccountingEmailError(true);
      setError("Accounting Email cannot be empty");
    } else if (!validator.isEmail(accountingEmail)) {
      setNotValidAccountingEmail(true);
      setError("Accounting Email is invalid");
    }

    if (levvenContacts === "") {
      setLevvenContactsError(true);
      setError("Levven Contact Text cannot be empty");
    }
    if (levvenOrdersEmail === "") {
      setlevvenOrdersEmailError(true);
      setError("Levven Orders Email cannot be empty");
    } else if (!validator.isEmail(levvenOrdersEmail)) {
      setNotValidOrdersEmail(true);
      setError("Levven Order Email is invalid");
    }

    if (shippingGLAccount === "") {
      setShippingGLAccountError(true);
      setError("Shipping GL Account field cannot be empty");
    }

    if (shippingTaxGroupCode === "") {
      setShippingTaxGroupCodeError(true);

      setError("Shipping Tax Group Code field cannot be empty");
    }

    if (
      orderFulfillmentThreshold === "" ||
      orderFulfillmentThreshold === null
    ) {
      setOrderFulfillmentThresholdError(true);

      setError(
        "Days Prior to Shipment Request Date When Email Notification Is Sent cannot be empty"
      );
    } else if (parseInt(orderFulfillmentThreshold) < 1) {
      setOrderFulfillmentThresholdError(true);

      setError(
        "Days Prior to Shipment Request Date When Email Notification Is Sent must be greater than zero"
      );
    }

    if (emailToSendTo === "" || emailToSendTo === null) {
      setEmailToSendToError(true);

      setError(
        "Email Address of Quick Kit Order Fulfillment Person is empty"
      );
    } else if (!validator.isEmail(emailToSendTo)) {
      setInvalidEmailToSendToError(true);

      setError(
        "Email Address of Quick Kit Order Fulfillment Person is invalid"
      );
    }

    if (bulkEmailToSendTo === "" || bulkEmailToSendTo === null) {
      setBulkEmailToSendToError(true);

      setError("Email Address of Bulk Order Fulfillment Person is empty");
    } else if (!validator.isEmail(bulkEmailToSendTo)) {
      setInvalidBulkEmailToSendToError(true);

      setError("Email Address of Bulk Order Fulfillment Person is invalid");
    }

    if (surchargeAmount === null || surchargeAmount < 0) {
      setSurchargeAmountError(true);
      setError("Invalid Surcharge Amount");
    }

    if (
      melissaLicenseKey === "" ||
      melissaLicenseKey === null ||
      melissaLicenseKey === undefined
    ) {
      setMelissaLicenseKeyError(true);
      setError("Melissa License Key cannot be empty");
    }

    if (
      melissaAutocompleteLicenseKey === "" ||
      melissaAutocompleteLicenseKey === null ||
      melissaAutocompleteLicenseKey === undefined
    ) {
      setMelissaAutocompleteLicenseKeyError(true);
      setError("Melissa Autocomplete License Key cannot be empty");
    }

    if (recaptchaKey === "" || recaptchaKey === null) {
      setRecaptchaError(true);
      setError("Google Recaptcha Key cannot be empty");
    }
    if (recaptchaSecretKey === "" || recaptchaSecretKey === null) {
      setRecaptchaSecretError(true);
      setError("Google Recaptcha Secret Key cannot be empty");
    }

    if (recaptchaKeyV2 === "" || recaptchaKeyV2 === null) {
      setRecaptchaErrorV2(true);
      setError("Google Recaptcha V2 Key cannot be empty");
    }

    if (recaptchaSecretKeyV2 === "" || recaptchaSecretKeyV2 === null) {
      setRecaptchaSecretErrorV2(true);
      setError("Google Recaptcha V2 Secret Key cannot be empty");
    }

    if (
      recaptchaThreshold < 0.0 ||
      recaptchaThreshold > 1.0 ||
      recaptchaThreshold === null
    ) {
      setRecaptchaThresholdError(true);
      setError(
        "Google Recaptcha V3 threshold must be a number between 0.0 and 1.0"
      );
    }
    // if (cookiesAlertText === "") {
    //   setCookiesAlertText(true);
    //   setError("Cookies Alert Text cannot be empty");
    // }

    if (
      recaptchaSecretKey === "" ||
      recaptchaSecretKey === null ||
      recaptchaKeyV2 === "" ||
      recaptchaKeyV2 === null ||
      recaptchaSecretKeyV2 === "" ||
      recaptchaSecretKeyV2 === null ||
      recaptchaThreshold < 0.0 ||
      recaptchaThreshold > 1.0 ||
      recaptchaThreshold === null ||
      recaptchaKey === "" ||
      recaptchaKey === null ||
      companyStatusText === "" ||
      accountingEmail === "" ||
      !validator.isEmail(accountingEmail) ||
      levvenContacts === "" ||
      levvenOrdersEmail === "" ||
      surchargeAmount === null ||
      surchargeAmount < 0 ||
      shippingTaxGroupCodeError ||
      emailToSendTo === "" ||
      orderFulfillmentThreshold === "" ||
      orderFulfillmentThreshold === null ||
      parseInt(orderFulfillmentThreshold) < 1 ||
      emailToSendTo === null ||
      !validator.isEmail(emailToSendTo) ||
      bulkEmailToSendTo === "" ||
      bulkEmailToSendTo === null ||
      !validator.isEmail(bulkEmailToSendTo) ||
      shippingGLAccount === "" ||
      shippingTaxGroupCode === "" ||
      !validator.isEmail(levvenOrdersEmail) ||
      melissaLicenseKey === "" ||
      melissaLicenseKey === null ||
      melissaLicenseKey === undefined ||
      melissaAutocompleteLicenseKey === "" ||
      melissaAutocompleteLicenseKey === null ||
      melissaAutocompleteLicenseKey === undefined
    ) {
      setButtonLoading(false);
      return;
    } else {
      await systemDataServices
        .updateAllSystemVariables({
          OnHoldBannerText: companyStatusText,
          LevvenAccountingEmail: accountingEmail,
          PartNbr1: partNb1,
          PartNbr2: partNb2,
          PartNbr3: partNb3,
          PartNbr4: partNb4,
          PartNbr5: partNb5,
          PartNbr6: partNb6,
          PartNbr7: partNb7,
          PartNbr8: partNb8,
          ModelHomePartNbr: partNb,
          OrderModificationLevvenContacts: levvenContacts,
          LevvenOrderContactEmail: levvenOrdersEmail,
          SurchargeAmount: surchargeAmount,
          ApplyAddressValidation: verifyAddress,
          CookiesAlertText: cookiesAlertText,
          ShippingGLAccount: shippingGLAccount,
          ShippingTaxGroupCode: shippingTaxGroupCode,
          OrderFulfillmentUserEmail: emailToSendTo,
          OrderFulfillmentThreshold: orderFulfillmentThreshold,
          BulkOrderFulfillmentUserEmail: bulkEmailToSendTo,
          MelissaLicenseKey: melissaLicenseKey,
          RecaptchaKey: recaptchaKey,
          SecretRecaptchaKey: recaptchaSecretKey,
          ReCaptchaThreshold: recaptchaThreshold,
          RecaptchaV2Key: recaptchaKeyV2,
          SecretRecaptchaV2Key: recaptchaSecretKeyV2,
          MelissaAutocompleteLicenseKey: melissaAutocompleteLicenseKey,
        })
        .then(() => {
          setButtonLoading(false);
          setOpenSnackBar(true);
          setFormChanged(false);
          sessionStorage.removeItem("unsavedChanges");
          setPristine();
        })
        .catch((error) => {
          setButtonLoading(false);
          setError(error.response.data.error.error);
        });
    }
  };

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  //function to get part nbs and orderModificationLevvenContacts
  const getAllData = () => {
    setLoading(true);
    systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        setlevvenOrdersEmail(response.data.levvenOrderContactEmail);
        setAccountingEmail(response.data.levvenAccountingEmail);
        setPartNb1(response.data.partNbr1);
        setCompanyStatusText(response.data.onHoldBannerText);
        setPartNb2(response.data.partNbr2);
        setPartNb3(response.data.partNbr3);
        setPartNb4(response.data.partNbr4);
        setPartNb5(response.data.partNbr5);
        setPartNb6(response.data.partNbr6);
        setPartNb7(response.data.partNbr7);
        setPartNb8(response.data.partNbr8);
        setPartNb(response.data.modelHomePartNbr);
        setLevvenContacts(response.data.orderModificationLevvenContacts);
        setSurchargeAmount(response.data.surchargeAmount);
        setVerifyAddress(response.data.applyAddressValidation);
        setCookiesAlertText(response.data.cookiesAlertText);
        setShippingGLAccount(response.data.shippingGLAccount);
        setShippingTaxGroupCode(response.data.shippingTaxGroupCode);
        setEmailToSendTo(response.data.orderFulfillmentUserEmail);
        setBulkEmailToSendTo(response.data.bulkOrderFulfillmentUserEmail);
        setOrderFulfillmentThreshold(response.data.orderFulfillmentThreshold);
        setMelissaLicenseKey(response.data.melissaLicenseKey);
        setRecaptchaKey(response.data.recaptchaKey);
        setRecaptchaSecretKey(response.data.secretRecaptchaKey);
        setRecaptchaThreshold(response.data.reCaptchaThreshold);
        setRecaptchaKeyV2(response.data.recaptchaV2Key);
        setRecaptchaSecretKeyV2(response.data.secretRecaptchaV2Key);
        setMelissaAutocompleteLicenseKey(
          response.data.melissaAutocompleteLicenseKey
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.error.error);
      });
  };

  return loading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="80vh"
    >
      <CircularProgress sx={{ color: "#0077c6" }} />
    </Grid>
  ) : (
    <>
      {error ? (
        <>
          <AlertSnackBar
            message={error}
            handleClose={handleCloseAlertSnackbar}
            open={error !== ""}
          />
        </>
      ) : (
        " "
      )}

      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      <form onSubmit={updateAllSystemVariables}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                required
                value={accountingEmail}
                helperText={
                  accountingEmailError
                    ? "Levven Accounting email field is empty"
                    : notValidAccountingEmail
                      ? "Levven Accounting email is not valid "
                      : null
                }
                label="Levven Accounting Email"
                error={accountingEmailError || notValidAccountingEmail}
                fullWidth
                onChange={(e) => {
                  setFormChanged(true);
                  sessionStorage.setItem(
                    "unsavedChanges",
                    JSON.stringify(true)
                  );

                  setDirty();
                  setAccountingEmail(e.target.value);
                  if (accountingEmailError) setAccountingEmailError(false);
                  if (validator.isEmail(accountingEmail))
                    setNotValidAccountingEmail(false);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                sx={{
                  width: "100%",
                  height: "auto",
                }}
                required
                value={companyStatusText}
                helperText={
                  companyStatusTextError
                    ? "Company Status field is empty"
                    : null
                }
                label="Company Status Banner"
                error={companyStatusTextError}
                fullWidth
                onChange={(e) => {
                  setFormChanged(true);
                  sessionStorage.setItem(
                    "unsavedChanges",
                    JSON.stringify(true)
                  );

                  setDirty();
                  setCompanyStatusText(e.target.value);
                  if (companyStatusTextError) setCompanyStatusTextError(false);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>{" "}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
              paddingBottom: "20px",
            }}
          >
            Quick Kit Home Item
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 1"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb1}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb1(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 2"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb2}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb2(e.target.value);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 3"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb3}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb3(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 4"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb4}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb4(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 5"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb5}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb5(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 6"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb6}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb6(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 7"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb7}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb7(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  label="Part Number 8"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb8}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb8(e.target.value);
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Model Home Part Number "
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={partNb}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setPartNb(e.target.value);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
              paddingBottom: "20px",
            }}
          >
            Message for Order Modification After Submission
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Levven Contact Text"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  multiline
                  value={levvenContacts}
                  required
                  helperText={
                    levvenContactsError
                      ? "Model Home PartNbr field is empty"
                      : ""
                  }
                  error={levvenContactsError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setLevvenContacts(e.target.value);
                    if (levvenContactsError) setLevvenContactsError(false);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Levven Orders Email"
                  name="contactField"
                  required
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  value={levvenOrdersEmail}
                  helperText={
                    levvenOrdersEmailError || notValidOrdersEmail
                      ? "Levven Orders Email field is empty"
                      : ""
                  }
                  error={levvenOrdersEmailError || notValidOrdersEmail}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setlevvenOrdersEmail(e.target.value);
                    if (levvenOrdersEmailError)
                      setlevvenOrdersEmailError(false);
                    else if (notValidOrdersEmail) setNotValidOrdersEmail(false);
                  }}
                  inputProps={{ autoComplete: "new-password" }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
              paddingBottom: "20px",
            }}
          >
            Shipping Variables
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label="Shipping G/L Account"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  multiline
                  value={shippingGLAccount}
                  required
                  helperText={
                    shippingGLAccountError
                      ? "Shipping G/L Account field cannot be empty"
                      : ""
                  }
                  error={shippingGLAccountError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setShippingGLAccount(e.target.value);
                    if (shippingGLAccountError)
                      setShippingGLAccountError(false);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label="Shipping Tax Group Code"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  multiline
                  value={shippingTaxGroupCode}
                  required
                  helperText={
                    shippingTaxGroupCodeError
                      ? "Shipping Tax Group Code field cannot be empty"
                      : ""
                  }
                  error={shippingTaxGroupCodeError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setShippingTaxGroupCode(e.target.value);
                    if (shippingTaxGroupCodeError)
                      setShippingTaxGroupCodeError(false);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
            }}
          >
            Order Fulfillment Automatic Email Notification
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Email Address of Quick Kit Order Fulfillment Person"
                  name="EmailAddressofProjectPackOrderFulfillmentPerson"
                  fullWidth
                  required
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  type="email"
                  value={emailToSendTo}
                  helperText={
                    emailToSendToError || InvalidEmailToSendToError
                      ? emailToSendToError
                        ? "Email Address of Quick Kit Order Fulfillment Person is empty"
                        : InvalidEmailToSendToError
                          ? "Email Address of Quick Kit Order Fulfillment Person is not valid"
                          : ""
                      : ""
                  }
                  error={emailToSendToError || InvalidEmailToSendToError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setEmailToSendTo(e.target.value);
                    if (emailToSendToError || InvalidEmailToSendToError) {
                      setEmailToSendToError(false);
                      setInvalidEmailToSendToError(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Email Address of Bulk Order Fulfillment Person"
                  name="EmailAddressofBulkOrderFulfillmentPerson"
                  fullWidth
                  required
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  type="email"
                  value={bulkEmailToSendTo}
                  helperText={
                    bulkEmailToSendToError || InvalidBulkEmailToSendToError
                      ? bulkEmailToSendToError
                        ? "Email Address of Bulk Order Fulfillment Person is empty"
                        : InvalidBulkEmailToSendToError
                          ? "Email Address of Bulk Order Fulfillment Person is not valid"
                          : ""
                      : ""
                  }
                  error={
                    bulkEmailToSendToError || InvalidBulkEmailToSendToError
                  }
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setBulkEmailToSendTo(e.target.value);
                    if (
                      bulkEmailToSendToError ||
                      InvalidBulkEmailToSendToError
                    ) {
                      setBulkEmailToSendToError(false);
                      setInvalidBulkEmailToSendToError(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
            }}
          >
            Order Requested Delivery Date
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Days Prior to Requested Delivery Date"
                  name="DaysPriortoShipmentRequestDateWhenEmailNotificationIsSent"
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  onKeyPress={(event) => {
                    if (event?.key === "-") {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      step: 1,
                    },
                  }}
                  type="number"
                  required
                  value={orderFulfillmentThreshold}
                  helperText={
                    orderFulfillmentThresholdError
                      ? "Invalid Days Prior to Shipment Request Date When Email Notification Is Sent"
                      : ""
                  }
                  error={orderFulfillmentThresholdError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setOrderFulfillmentThreshold(e.target.value);
                    if (orderFulfillmentThresholdError)
                      setOrderFulfillmentThresholdError(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        {/* <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
            }}
          >
            Rush Surcharge Amount
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Surcharge Amount"
                  name="surchargeField"
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  type="number"
                  value={surchargeAmount}
                  required
                  helperText={
                    surchargeAmountError ? "Invalid Surcharge Amount" : ""
                  }
                  error={surchargeAmountError}
                  inputProps={{
                    min: 0,
                    step: "0.01",
                    display: "decimal",
                    autoComplete: "new-password",
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "e" ||
                      event?.key === "*"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
      "unsavedChanges",
      JSON.stringify(true)
    );
                    setDirty();
                    setSurchargeAmount(parseFloat(e.target.value));
                    if (surchargeAmountError) setSurchargeAmountError(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent> */}
        {userName.includes("@entelligence365.com") ||
          userName.includes("@ixia.tech") ? (
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                borderBottom: "1px solid #969696",
              }}
            >
              Google reCaptcha
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Google reCaptcha Site Key V3"
                    name="googleRecaptchaFieldV3"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    type="text"
                    value={recaptchaKey}
                    required
                    helperText={
                      recaptchaError
                        ? "Google ReCaptcha V3 Site Key cannot be empty"
                        : ""
                    }
                    error={recaptchaError}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setRecaptchaKey(e.target.value);
                      if (recaptchaError) setRecaptchaError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Google reCaptcha Secret Key V3"
                    name="googleRecaptchaSecretFieldV3"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    type="text"
                    value={recaptchaSecretKey}
                    required
                    helperText={
                      recaptchaSecretError
                        ? "Google ReCaptcha V3 Secret Key cannot be empty"
                        : ""
                    }
                    error={recaptchaSecretError}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setRecaptchaSecretKey(e.target.value);
                      if (recaptchaSecretError) setRecaptchaSecretError(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Google reCaptcha Site Key V2"
                    name="googleRecaptchaFieldV2"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    type="text"
                    value={recaptchaKeyV2}
                    required
                    helperText={
                      recaptchaErrorV2
                        ? "Google ReCaptcha V2 Site Key cannot be empty"
                        : ""
                    }
                    error={recaptchaErrorV2}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setRecaptchaKeyV2(e.target.value);
                      if (recaptchaErrorV2) setRecaptchaErrorV2(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Google reCaptcha Secret Key V2"
                    name="googleRecaptchaSecretFieldV2"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    type="text"
                    value={recaptchaSecretKeyV2}
                    required
                    helperText={
                      recaptchaSecretErrorV2
                        ? "Google ReCaptcha V2 Secret Key cannot be empty"
                        : ""
                    }
                    error={recaptchaSecretErrorV2}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      setRecaptchaSecretKeyV2(e.target.value);
                      if (recaptchaSecretErrorV2)
                        setRecaptchaSecretErrorV2(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Google reCaptcha Threshold V2"
                    name="googleRecaptchaThresholdFieldV2"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    type="number"
                    value={recaptchaThreshold}
                    required
                    helperText={
                      recaptchaThresholdError
                        ? "Google ReCaptcha Threshold must have a value between 0.0 and 1.0"
                        : ""
                    }
                    error={recaptchaThresholdError}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 1,
                        display: "decimal",
                        step: 0.1,
                      },
                    }}
                    onChange={(e) => {
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                      setDirty();
                      var regex = new RegExp(
                        "^[0-9]$|^[0-9].$|^[0-9].[0-9]$|^.[0-9]$|^$"
                      );

                      if (regex.test(e.target.value) === false) {
                        e.preventDefault();
                        return;
                      }
                      if (e.target.value === "" || e.target.value === null) {
                        setRecaptchaThreshold(0.0);
                      } else {
                        setRecaptchaThreshold(parseFloat(e.target.value));
                      }
                      if (recaptchaThresholdError)
                        setRecaptchaThresholdError(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        ) : null}
        {/* <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
              paddingBottom: "20px",
            }}
          >
            Allow Cookies Alert Message
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Cookies Alert Text"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  multiline
                  value={cookiesAlertText}
                  required
                  helperText={
                    cookiesAlertTextError
                      ? "Cookies Alert Text cannot be empty"
                      : ""
                  }
                  error={cookiesAlertTextError}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
      "unsavedChanges",
      JSON.stringify(true)
    );
                    setDirty();
                    setCookiesAlertText(e.target.value);
                    if (cookiesAlertTextError) setCookiesAlertTextError(false);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </CardContent> */}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              borderBottom: "1px solid #969696",
            }}
          >
            Manage Melissa Address Checking
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Melissa License Key"
                  name="melissaLicenseKey"
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  type="text"
                  value={melissaLicenseKey}
                  required
                  helperText={
                    melissaLicenseKeyError
                      ? "Melissa License Key cannot be empty"
                      : ""
                  }
                  error={melissaLicenseKeyError}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setMelissaLicenseKey(e.target.value);
                    if (melissaLicenseKeyError)
                      setMelissaLicenseKeyError(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Melissa Autocomplete License Key"
                  name="melissaAutocompleteLicenseKey"
                  fullWidth
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: "30px",
                  }}
                  type="text"
                  value={melissaAutocompleteLicenseKey}
                  required
                  helperText={
                    melissaAutocompleteLicenseKeyError
                      ? "Melissa Autocomplete License Key cannot be empty"
                      : ""
                  }
                  error={melissaAutocompleteLicenseKeyError}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  onChange={(e) => {
                    setFormChanged(true);
                    sessionStorage.setItem(
                      "unsavedChanges",
                      JSON.stringify(true)
                    );
                    setDirty();
                    setMelissaAutocompleteLicenseKey(e.target.value);
                    if (melissaAutocompleteLicenseKeyError)
                      setMelissaAutocompleteLicenseKeyError(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "30px",
                    }}
                    checked={verifyAddress}
                    onChange={() => {
                      setVerifyAddress(!verifyAddress);
                      setDirty();
                      setFormChanged(true);
                      sessionStorage.setItem(
                        "unsavedChanges",
                        JSON.stringify(true)
                      );
                    }}
                    control={<Switch />}
                    label={
                      verifyAddress
                        ? "Address Verification is on"
                        : "Address Verification is off"
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <CustomButton
                  loading={buttonLoading}
                  type="submit"
                  size="medium"
                  startIcon={<Save />}
                >
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </>
  );
};
