import { Breadcrumbs, Link, Paper, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import AgreementForm from "../../../components/AgreementForm/AgreementForm";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Save, X } from "react-feather";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateAgreementPage = ({ setPageID, setPageLink }) => {
  const history = useHistory();

  const agreementPrices = useSelector((state) =>
    state.user.user ? state.user.user.user.role.agreementPrices : ""
  );

  // Redirection
  if (agreementPrices === "N") history.push("/dashboard");

  const [adminManage, setAdminManage] = useState(false);
  const [salesManage, setSalesManage] = useState(false);
  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate("/agreements");
  
    } else {
  
      history.push("/agreements");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  /*Agreement Form Ref*/
  const agreementRef = useRef(null);

  const advAgreement = useSelector((state) =>
    state.user.user ? state.user.user.user.role.advAgreement : ""
  );

  useEffect(() => {
    //for help button
    setPageLink("Agreements");

    //for drawer menu
    setPageID(3);
  }, []);

  useEffect(() => {
    if (advAgreement === "M") setAdminManage(true);
    if (agreementPrices === "M") setSalesManage(true);
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            underline="always"
            key="1"
            color="inherit"
            // onClick={() => history.push("/agreements")}
            onClick={() => handleItemClick()}
            sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
          >
            Agreements
          </Link>
          <Typography key="2" color="text.primary" sx={{ fontSize: "24px" }}>
            New Agreement
          </Typography>
        </Breadcrumbs>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CustomButton
            startIcon={<Save />}
            toggle={() => agreementRef.current.save()}
            loading={tabButtonLoading}
          >
            Save
          </CustomButton>
          <div style={{ width: "10px" }} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await agreementRef.current.setPristine();
              history.push("/Agreements");
            }}
          >
            Cancel
          </CustomButton>
        </div>
      </div>

      <div style={{ height: "40px" }} />
      <AgreementForm
        create={true}
        adminManage={adminManage}
        salesManage={salesManage}
        ref={agreementRef}
        setTabButtonLoading={setTabButtonLoading}
        setFormChanged={setFormChanged}
      />
    </Paper></>
  );
};

export default CreateAgreementPage;
