import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import { countryServices } from "../../services/country.services";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { UserPlus, Trash2 } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

//holds columns for table head
const headCells = [
  {
    id: "countryName",
    numeric: false,
    disablePadding: true,
    label: "Country Name",
  },
  {
    id: "countryCode",
    numeric: false,
    disablePadding: false,
    label: "Country Code",
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CountriesManagement({ setCreateCountry = false }) {
  //holds selected rows
  const [selected, setSelected] = React.useState([]);

  //holds page we are in in table
  const [page, setPage] = React.useState(0);

  //holds rows per page to display in table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //holds the rows displayed
  const [rows, setRows] = React.useState([]);

  //holds error if any
  const [error, setError] = React.useState("");

  //holds total number of selected values from table
  var numSelected = selected.length;

  //holds total number of records in database that match the filters
  const [numberRecords, setNumberRecords] = React.useState(0);

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //for button loading
  const [loading, setLoading] = React.useState(false);

  //for page loading
  const [pageLoad, setPageLoad] = React.useState(false);

  //In case of error while deleting
  const [deleteError, setDeleteError] = React.useState("");

  //to refetch table data
  const [resubmit, setResubmit] = React.useState(false);

  //display snackbar on success
  const [success, setSuccess] = React.useState(false);

  //display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageLoad(true);

    setRows([]);

    //we fetch country data from the database
    countryServices
      .getCountries()
      .then((response) => {
        setPageLoad(false);

        setNumberRecords(response.data.length);

        setRows(response.data);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setPageLoad(false);
      });
  }, [page, rowsPerPage, resubmit]);

  const viewCountryData = (CountryID, countryName) => {
    //redirection to view country data
    history.push({
      pathname: "/country-information",
      state: {
        countryID: CountryID,
        create: false,
        countryName: countryName,
      },
    });
  };

  const handleSelectAllClick = (event) => {
    //function that selects all rows in page
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const deleteCountries = () => {
    //delete countries from table
    setLoading(true);
    setDeleteRecord(false);
    countryServices
      .deleteCountries({ Countries: selected })
      .then(() => {
        setLoading(false);
        setResubmit(!resubmit);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setResubmit(!resubmit);
        setDeleteError(error.response.data.error.error);
      });
  };

  const handleChangePage = (event, newPage) => {
    //function that handles the change page in table
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change the rows per page in table
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      countryID: contextMenuData.countryID,
      create: false,
      countryName: contextMenuData.description,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/country-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  //check if row is selected
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <SnackBar
        open={success}
        message="Countrie(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Country Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteCountries}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Countries
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Trash2 />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setDeleteRecord(true)}
                  loading={loading}
                >
                  Delete
                </CustomButton>
              </>
            ) : (
              <>
                {/* <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  Filters
                </CustomButton> */}

                <CustomButton
                  startIcon={<UserPlus />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setCreateCountry(true)}
                >
                  New Country
                </CustomButton>
              </>
            )}
          </Toolbar>

          <TableContainer className={"table-container"}>
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={numberRecords}
              />

              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      It seems there are no Countries to display.
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.countryID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        sx={{ cursor: "pointer" }}
                        key={row.countryID}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            onContextMenu={() => {
                              setContextMenuData(row);
                            }}
                            onClick={(event) =>
                              handleClick(event, row.countryID)
                            }
                            checkedIcon={
                              <CheckBoxIcon sx={{ color: "#badaf1" }} />
                            }
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>

                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCountryData(row.countryID, row.description)
                          }
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCountryData(row.countryID, row.description)
                          }
                        >
                          {row.countryCode}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewCountryData(row.countryID, row.description)
                          }
                        >
                          {row.cur}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}

                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
