import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Modal from "../CustomModal/CustomModal";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, Image, Check } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { IconButton, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import { systemDataServices } from "../../services/systemData.services";
import { productServices } from "../../services/product.service";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import { Copy } from "react-feather";
import CustomAlert from "../CustomAlert/CustomAlert";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    center: false,
  },
  {
    id: "PartNbr",
    numeric: false,
    disablePadding: false,
    label: "Part Number",
    center: false,
  },
  {
    id: "Description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    center: false,
  },
  {
    id: "BusinessUnitCode",
    numeric: false,
    disablePadding: false,
    label: "Business Unit",
    center: false,
  },
  {
    id: "CategoryCode",
    numeric: false,
    disablePadding: false,
    label: "Category",
    center: false,
  },
  {
    id: "SwitchStyleCode",
    numeric: false,
    disablePadding: false,
    label: "Switch Style",
    center: false,
  },
  {
    id: "SwitchColorCode",
    numeric: false,
    disablePadding: false,
    label: "Switch Color",
    center: false,
  },
  {
    id: "ControllerTypeCode",
    numeric: false,
    disablePadding: false,
    label: "Controller Type",
    center: false,
  },
  {
    id: "AmpsCode",
    numeric: false,
    disablePadding: false,
    label: "Amps",
    center: false,
  },
  {
    id: "CoatedSwitch",
    numeric: false,
    disablePadding: false,
    label: "Coated Switch",
    center: true,
  },
  {
    id: "IsValid",
    numeric: false,
    disablePadding: false,
    label: "Status",
    center: true,
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    permission,
  } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead
      style={{
        backgroundColor: "#0077c6",
      }}
    >
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: "#0077c6",
          }}
        >
          {rowCount > 0 && permission === "M" ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.id !== "image" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductsTable({
  titleText = "Products",
  setPageLink,
  setPageID,
}) {
  //used to send data to next page
  const history = useHistory();

  //used to retrieve sent data from previous page
  const location = useLocation();

  //get user permission from redux
  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.prodCat : ""
  );

  if (rolePermission === "N") {
    history.push("/Dashboard");
  }

  //for sorting in table
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const [orderColumn, setOrderColumn] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  const [imageErrorOnLoad, setImageErrorOnLoad] = React.useState("");

  //holds selected rows in table
  const [selected, setSelected] = React.useState([]);

  //holds page in table
  const [page, setPage] = React.useState(0);

  //holds number of rows per page in table
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //makes page load until all data is fetched
  const [pageLoad, setPageLoad] = React.useState(false);

  //holds data to be displayed in table
  const [rows, setRows] = React.useState([]);

  //dispay filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  const [imageArray, setImageArray] = React.useState([]);
  const [imageErrorArray, setImageErrorArray] = React.useState([]);

  //holds error message if any while fetching data from database
  const [error, setError] = React.useState("");

  //holds number of selected values in table
  var numSelected = selected.length;

  //holds number of records in table (without pagination)
  const [numberRecords, setNumberRecords] = React.useState(0);

  //makes delete button loading until the data is deleted
  const [loading, setLoading] = React.useState(false);

  const [imageSpecification, setImageSpecification] = React.useState("");

  //holds error while fetching image
  const [imageError, setImageError] = React.useState("");

  //to refetch data from the database
  const [resubmit, setResubmit] = React.useState(false);

  const [imageLoad, setImageLoad] = React.useState(false);

  //for filters
  const [amps, setAmps] = React.useState([]);
  const [businessUnit, setBusinessUnit] = React.useState([]);
  const [controllerType, setControllerType] = React.useState([]);
  const [coatedSwitch, setCoatedSwitch] = React.useState([
    "Coated Switch",
    "Not Coated Switch",
    "Both",
  ]);
  const [availableToResellers, setAvailableToResellers] = React.useState([
    "Available",
    "Not Available",
    "Both",
  ]);
  const [isValidOptions, setIsValidOptions] = React.useState([
    "Valid",
    "Not Valid",
    "Both",
  ]);
  const [displayImage, setDisplayImage] = React.useState(false);
  const [image, setImage] = React.useState();
  const [imageDescription, setImageDescription] = React.useState("");
  const [productCategory, setProductCategory] = React.useState([]);
  const [switchStyle, setSwitchStyle] = React.useState([]);
  const [switchColor, setSwitchColor] = React.useState([]);
  const [partNumber, setPartNumber] = React.useState("");
  const [description, setDescription] = React.useState("");

  //for selected filters
  const [selectedAmps, setSelectedAmps] = React.useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = React.useState([]);
  const [selectedControllerType, setSelectedControllerType] = React.useState(
    []
  );
  const [selectedAvailableToResellers, setSelectedAvailableToResellers] =
    React.useState([]);
  const [selectedGangNumber, setSelectedGangNumber] = React.useState([]);
  const [selectedCoatedSwitch, setSelectedCoatedSwitch] = React.useState("");
  const [selectedPackaging, setSelectedPackaging] = React.useState([]);
  const [selectedPowerSupply, setSelectedPowerSupply] = React.useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = React.useState(
    []
  );
  const [selectedSwitchAssemblyType, setSelectedSwitchAssemblyType] =
    React.useState([]);
  const [selectedSwitchColor, setSelectedSwitchColor] = React.useState([]);
  const [selectedSwitchStyle, setSelectedSwitchStyle] = React.useState([]);
  const [selectedVolts, setSelectedVolts] = React.useState([]);
  const [isValid, setIsValid] = React.useState(null);

  //holds eroor message after delete if any
  const [deleteError, setDeleteError] = React.useState("");

  //display success snackbar
  const [success, setSuccess] = React.useState(false);

  //display alert dialog before delete
  const [deleteRecord, setDeleteRecord] = React.useState(false);

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //get data from redux
  const [productSuccess, setProductSuccess] = React.useState(
    location.state
      ? location.state.success
        ? location.state.success
        : false
      : false
  );

  const getFilterData = () => {
    //get all amps
    systemDataServices
      .getAllAmps()
      .then((response) => {
        setAmps(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    //get all controller types
    systemDataServices
      .getAllControllerType()
      .then((response) => {
        setControllerType(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    //get all business units
    systemDataServices
      .getAllBusinessUnits()
      .then((response) => {
        setBusinessUnit(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
    //get product categories
    systemDataServices
      .getAllProductCategory()
      .then((response) => {
        setProductCategory(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    //get all switchstyles
    systemDataServices
      .getAllSwitchStyle()
      .then((response) => {
        setSwitchStyle(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });

    //get all switch color
    systemDataServices
      .getAllSwitchColor()
      .then((response) => {
        setSwitchColor(response.data);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
      });
  };

  /*Fetch Image File*/
  const fetchImage = async (id, path, partNbr, index) => {
    var tbr = null;
    if (path) {
      await productServices
        .getImageFile(path, id)
        .then(async (response) => {
          tbr = await URL.createObjectURL(response.data);
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            // setImageErrorOnLoad(
            //   JSON.parse(reader.result).error.error +
            //     " Product Part Number: " +
            //     partNbr +
            //     ""
            // );
          };
          reader.readAsText(error.response.data);
          setImageErrorArray((array) => [...array, index]);
          return tbr;
        });
    }

    return tbr;
  };

  /*Convert base64String (image long text) to file (blob)*/
  const base64StringtoFile = (base64String, filename) => {
    if (base64String !== "" && base64String !== null) {
      var arr = filename.split(".");
      var mime = "image/" + arr[1];
      var bstr = atob(base64String);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    } else return null;
  };

  const getDataFromDatabase = () => {
    //get data from database based on filters

    setImageErrorArray([]);

    var ampsArray = [];
    var businessUnitArray = [];
    var controllerTypeArray = [];
    var gangNumberArray = [];
    var coatedSwitchArray = [];
    var packagingArray = [];
    var powerSupplyArray = [];
    var productCategoryArray = [];
    var switchAssemblyTypeArray = [];
    var switchColorArray = [];
    var switchStyleArray = [];
    var voltsArray = [];
    var availableToResellersArray = [];
    var isValidArray = [];

    selectedAmps.forEach((amps) => {
      return ampsArray.push(amps.ampsCode);
    });

    selectedBusinessUnit.forEach((bu) => {
      return businessUnitArray.push(bu.businessUnitCode);
    });

    selectedControllerType.forEach((ct) => {
      return controllerTypeArray.push(ct.controllerTypeCode);
    });

    selectedGangNumber.forEach((gangNumber) => {
      return gangNumberArray.push(gangNumber.gangNumberCode);
    });

    if (selectedCoatedSwitch !== "" && selectedCoatedSwitch === "Both") {
      coatedSwitchArray.push(true);
      coatedSwitchArray.push(false);
    } else if (
      selectedCoatedSwitch !== "" &&
      selectedCoatedSwitch === "Coated Switch"
    ) {
      coatedSwitchArray.push(true);
    } else if (
      selectedCoatedSwitch !== "" &&
      selectedCoatedSwitch === "Not Coated Switch"
    ) {
      coatedSwitchArray.push(false);
    }

    if (
      selectedAvailableToResellers !== "" &&
      selectedAvailableToResellers === "Both"
    ) {
      availableToResellersArray.push(true);
      availableToResellersArray.push(false);
    } else if (
      selectedAvailableToResellers !== "" &&
      selectedAvailableToResellers === "Available"
    ) {
      availableToResellersArray.push(true);
    } else if (
      selectedAvailableToResellers !== "" &&
      selectedAvailableToResellers === "Not Available"
    ) {
      availableToResellersArray.push(false);
    }

    if (isValid !== "" && isValid === "Both") {
      isValidArray.push(true);
      isValidArray.push(false);
    } else if (isValid !== "" && isValid === "Valid") {
      isValidArray.push(true);
    } else if (isValid !== "" && isValid === "Not Valid") {
      isValidArray.push(false);
    }
    selectedPackaging.forEach((packaging) => {
      return packagingArray.push(packaging.packagingCode);
    });

    selectedPowerSupply.forEach((ps) => {
      return powerSupplyArray.push(ps.powerSupplyCode);
    });

    selectedProductCategory.forEach((pc) => {
      return productCategoryArray.push(pc.categoryCode);
    });

    selectedSwitchAssemblyType.forEach((sat) => {
      return switchAssemblyTypeArray.push(sat.switchAssemblyTypeCode);
    });

    selectedSwitchStyle.forEach((ss) => {
      return switchStyleArray.push(ss.switchStyleCode);
    });

    selectedSwitchColor.forEach((sc) => {
      return switchColorArray.push(sc.switchColorCode);
    });

    selectedVolts.forEach((volts) => {
      return voltsArray.push(volts.voltsCode);
    });

    const data = {
      Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
      Filters: {
        PartNbr: partNumber,
        Description: description,
        BusinessUnit: businessUnitArray,
        ProductCategory: productCategoryArray,
        SwitchStyle: switchStyleArray,
        SwitchColor: switchColorArray,
        ControllerType: controllerTypeArray,
        Packaging: packagingArray,
        Amps: ampsArray,
        Coated: coatedSwitchArray,
        AvailableToResellers: availableToResellersArray,
        IsValid: isValidArray,
      },
      OrderColumn: orderColumn,
      OrderDirection: orderDirection,
    };

    // setImageArray([]);
    setRows([]);
    productServices
      .getProducts(data)
      .then(async (response) => {
        await setNumberRecords(response.data.totalNumberOfRecords);

        await setRows(response.data.products);
        await setPageLoad(false);

        // await response.data.products.forEach(async (product) => {
        //   return setRows((rows) => [
        //     ...rows,
        //     {
        //       id: product.product.productID,
        //       productPartNumber: product.product.partNbr,
        //       productDescription: product.product.description,
        //       specifications: product.product.specifications,
        //       businessUnit: product.product.businessUnit.description,
        //       productCategory: product.product.category.description,
        //       switchStyle: product.product.switchStyle
        //         ? product.product.switchStyle.description
        //         : "",
        //       switchColor: product.product.switchColor
        //         ? product.product.switchColor.description
        //         : "",
        //       coatedSwitch: product.product.coatedSwitch ? "yes" : "no",
        //       controllerType: product.product.controllerType
        //         ? product.product.controllerType.description
        //         : "",
        //       packaging: product.product.packaging
        //         ? product.product.packaging.description
        //         : "",
        //       powerSupply: product.product.powerSupply
        //         ? product.product.powerSupply.description
        //         : "",
        //       amps: product.product.amps
        //         ? product.product.amps.description
        //         : "",
        //       volts: product.product.volts
        //         ? product.product.volts.description
        //         : "",
        //       gatewayType: product.product.gatewayType
        //         ? product.product.gatewayType.description
        //         : "",
        //       imagePath: product.product.imagePath
        //         ? product.product.imagePath
        //         : "",
        //     },
        //   ]);
        // });

        // var tmp = [];

        // for (var i = 0; i < response.data.products.length; i++) {
        //   var file = null;
        //   if (response.data.products[i].productImage !== null) {
        //     file = await base64StringtoFile(
        //       response.data.products[i].productImage.data,
        //       response.data.products[i].productImage.name
        //     );
        //     file = await URL.createObjectURL(file);
        //   }

        //   await tmp.push(file);
        // }
        // await setImageArray(tmp);
      })
      .catch((error) => {
        setError(error.response.data.error.error);
        setPageLoad(false);
      });
  };

  useEffect(() => {
    //for help button
    setPageLink("Products");

    //for drawer menu
    setPageID(9);
  }, []);

  useEffect(() => {
    //to display snackbar when returning from tabs
    if (productSuccess === true) {
      //if product deleted from tabs to display snackbar when we are returned to table
      setSuccess(true);
      setProductSuccess(false);
    }

    setRows([]);

    setError("");
    setSelected([]);
    window.scrollTo(0, 0);
    setPageLoad(true);

    getFilterData();
    getDataFromDatabase();
  }, [page, rowsPerPage, resubmit]);

  const submitFilterData = (event) => {
    //submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  /*Fetch Image File*/
  const fetchImageFile = async (id, path, description, specification) => {
    setImageLoad(true);
    setImageError("");
    if (path === null) return;
    setImageDescription(description);
    setImageSpecification(specification);
    setDisplayImage(true);
    if (path !== "") {
      await productServices
        .getImageFile(path, id)
        .then(async (response) => {
          await setImage(await URL.createObjectURL(response.data));
          await setImageLoad(false);
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setImageError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
          setImageLoad(false);
        });
    } else {
      setImageLoad(false);
    }
  };

  const viewProductData = (id, partNumber) => {
    //go to product information page and send data needed
    history.push({
      pathname: "/product-information",
      state: {
        id: id,
        partNumber: partNumber,
        source: "Products",
      },
    });
  };

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //for selecting all rows in rows
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.product.productID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  //change page in table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const clearFilterData = () => {
    //function to clear form data
    setPartNumber("");
    setDescription("");
    setSelectedAmps([]);
    setSelectedBusinessUnit([]);
    setSelectedControllerType([]);
    setSelectedGangNumber([]);
    setSelectedCoatedSwitch([]);
    setSelectedPackaging([]);
    setSelectedSwitchColor([]);
    setSelectedPowerSupply([]);
    setSelectedProductCategory([]);
    setSelectedSwitchAssemblyType([]);
    setSelectedSwitchColor([]);
    setSelectedSwitchStyle([]);
    setSelectedAvailableToResellers([]);
    setSelectedVolts([]);
    setResubmit(!resubmit);
  };

  //change rowsPerPage of table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteRecords = () => {
    //to delete records
    setLoading(true);
    setDeleteRecord(false);
    productServices
      .deleteProducts({ Products: selected })
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setResubmit(!resubmit);
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
        setResubmit(!resubmit);
      });
  };

  //to check if row is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setImageErrorOnLoad("");
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      id: contextMenuData.product.productID,
      partNumber: contextMenuData.product.partNbr,
      source: "Products",
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/product-information?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Product(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {deleteRecord ? (
        <AlertDialogSlide
          title="Deletion"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteRecords}
          saveText="Yes"
          deleteDialog={true}
          unsave={() => setDeleteRecord(false)}
          unsaveText="No"
        />
      ) : (
        ""
      )}

      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      {displayImage ? (
        <>
          <Modal openModal={displayImage} width="600px">
            <Grid
              container
              spacing={3}
              style={{
                overflow: "auto",
                padding: "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h6" component="div">
                  {imageDescription}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!imageLoad ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {image ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <img
                              src={image}
                              alt="product"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                                margin: "auto",
                                alignSelf: "center",
                                border: "1px solid #c4c4c4",
                                borderRadius: 8,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h7">
                              <b>Specifications</b>
                            </Typography>
                            <div
                              style={{
                                textAlign: "left",
                                textJustify: "inter-word",
                              }}
                            >
                              <Typography variant="body2" component="span">
                                {imageSpecification}
                              </Typography>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {imageError ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <CustomAlert>{imageError}</CustomAlert>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                >
                                  <Typography variant="h7">
                                    <b>Specifications</b>
                                  </Typography>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      textJustify: "inter-word",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      {imageSpecification}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <Typography variant="h7">
                                  <b>Specifications</b>
                                </Typography>
                                <div
                                  style={{
                                    textAlign: "left",
                                    textJustify: "inter-word",
                                  }}
                                >
                                  <Typography variant="body2" component="span">
                                    {imageSpecification}
                                  </Typography>
                                </div>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress sx={{ color: "#0077c6" }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <CustomButton
                    toggle={() => {
                      setImage(null);
                      setDisplayImage(false);
                      setImageDescription("");
                      setImageSpecification("");
                    }}
                  >
                    Close
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </Modal>
        </>
      ) : (
        ""
      )}

      <Box sx={{ width: "100%" }}>
        {imageErrorOnLoad ? (
          <AlertSnackBar
            message={imageErrorOnLoad}
            handleClose={handleCloseAlertSnackbar}
            open={imageErrorOnLoad !== ""}
          />
        ) : null}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {titleText}
              </Typography>
            )}

            {numSelected > 0 ? (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <>
                    {numSelected === 1 ? (
                      <CustomButton
                        startIcon={<Copy />}
                        size="medium"
                        tableButton={true}
                        toggle={() =>
                          history.push({
                            pathname: "/create-product",
                            state: {
                              id: selected[0],
                              duplicate: true,
                            },
                          })
                        }
                      >
                        Duplicate
                      </CustomButton>
                    ) : null}

                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecord(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
                {rolePermission === "M" ? (
                  <Link to="/create-product" style={{ textDecoration: "none" }}>
                    <CustomButton
                      startIcon={<Plus />}
                      size="medium"
                      tableButton={true}
                    >
                      New Product
                    </CustomButton>
                  </Link>
                ) : (
                  ""
                )}
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitFilterData} noValidate>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        label="Part Number"
                        fullWidth
                        value={partNumber}
                        onChange={(e) => setPartNumber(e.target.value)}
                        inputProps={{
                          maxLength: 25,
                        }}
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <TextField
                        size="small"
                        label="Description"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        inputProps={{
                          maxLength: 100,
                        }}
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={businessUnit}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedBusinessUnit}
                        onChange={(event, value) => {
                          setSelectedBusinessUnit(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business Unit"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={productCategory}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedProductCategory}
                        onChange={(event, value) => {
                          setSelectedProductCategory(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Product Category"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={switchStyle}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedSwitchStyle}
                        onChange={(event, value) => {
                          setSelectedSwitchStyle(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch Style"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={switchColor}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedSwitchColor}
                        onChange={(event, value) => {
                          setSelectedSwitchColor(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch Color"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={controllerType}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedControllerType}
                        onChange={(event, value) => {
                          setSelectedControllerType(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Controller Type"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={amps}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={selectedAmps}
                        onChange={(event, value) => {
                          setSelectedAmps(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Amps"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={coatedSwitch}
                        value={selectedCoatedSwitch}
                        onChange={(event, value) => {
                          setSelectedCoatedSwitch(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Coated Switch"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={availableToResellers}
                        value={selectedAvailableToResellers}
                        onChange={(event, value) => {
                          setSelectedAvailableToResellers(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Available To Resellers"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={2}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={isValidOptions}
                        value={isValid}
                        onChange={(event, value) => {
                          setIsValid(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Validity"
                            fullWidth
                            autoComplete="off"
                            inputProps={{
                              ...params.inputProps,
                            }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                permission={rolePermission}
              />

              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="12" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="12"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no products to display. "}
                      {rolePermission === "M" ? (
                        <Typography
                          variant="p"
                          sx={{
                            textDecoration: "underline",
                            color: "#0077c6",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push({ pathname: "/create-product" })
                          }
                        >
                          Would you like to create one?
                        </Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.product.productID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        sx={{ cursor: "pointer", height: "130px" }}
                        key={row.product.productID}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {rolePermission === "R" ? (
                            ""
                          ) : (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.product.productID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {row.productImage ? (
                            <img
                              onClick={() =>
                                fetchImageFile(
                                  row.product.productID,
                                  row.product.imagePath
                                    ? row.product.imagePath
                                    : "",
                                  row.product.description,
                                  row.product.specifications
                                )
                              }
                              src={row.productImage}
                              alt={row.product.partNbr}
                              style={{
                                height: "100px",
                                objectFit: "cover",
                                alignSelf: "center",
                                borderRadius: 8,
                              }}
                            />
                          ) : (
                            <>
                              <IconButton
                                onClick={() =>
                                  fetchImageFile(
                                    row.product.productID,
                                    row.product.imagePath
                                      ? row.product.imagePath
                                      : "",
                                    row.product.description,
                                    row.product.specifications
                                  )
                                }
                              >
                                <Image />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.partNbr}
                        </TableCell>

                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.description}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.businessUnit.description}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.category.description}
                        </TableCell>

                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.switchStyle
                            ? row.product.switchStyle.description
                            : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.switchColor
                            ? row.product.switchColor.description
                            : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.controllerType
                            ? row.product.controllerType.description
                            : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.amps ? row.product.amps.description : ""}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          align="center"
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          {row.product.coatedSwitch ? "yes" : "no"}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          align="center"
                          onClick={() =>
                            viewProductData(
                              row.product.productID,
                              row.product.partNbr
                            )
                          }
                        >
                          <span
                            style={{
                              marginLeft: "-15px",
                              padding: "5px 10px",
                              height: "30px",
                              width: "100px",
                              borderRadius: "25px",
                              backgroundColor: row.product.isValid
                                ? "rgba(29, 148, 75, 0.2)"
                                : "rgba(150, 150, 150, 0.3)",
                              color: row.product.isValid
                                ? "rgba(29, 148, 75, 1)"
                                : "rgba(150, 150, 150, 1)",
                            }}
                          >
                            {row.product.isValid ? "Active" : "Inactive"}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
