import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import Underline from "../Underline/Underline";
import { systemDataServices } from "../../services/systemData.services";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "../CustomModal/CustomModal";
import { Image, Save } from "react-feather";
import { productServices } from "../../services/product.service";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const ProductForm = forwardRef(
  (
    {
      adminManage = false,
      create = false,
      duplicate = false,
      receivedProductID = 0,
      setOpenSnackBar,
      setTabButtonLoading,
      setProductName,
      setIsValid,
      setFormChanged,
      isValid,
    },
    ref
  ) => {
    /*Company Object*/
    const [product, setProduct] = useState();
    /*Selectors data*/
    const [businessUnits, setBusinessUnits] = useState([]);
    const [categories, setCategories] = useState([]);
    const [switchStyles, setSwitchStyles] = useState([]);
    const [switchColors, setSwitchColors] = useState([]);
    const [controllerTypes, setControllerTypes] = useState([]);
    const [switchAssemblyTypes, setSwitchAssemblyTypes] = useState([]);
    const [packagingTypes, setPackagingTypes] = useState([]);
    const [powerSupplyOptions, setPowerSupplyOptions] = useState([]);
    const [ampsOptions, setAmpsOptions] = useState([]);
    const [voltsOptions, setVoltsOptions] = useState([]);
    const [nbOfGangOptions, setNbOfGangOptions] = useState([]);
    const [gateWayTypes, setGateWayTypes] = useState([]);
    const [switches, setSwitches] = useState([]);
    const [controllers, setControllers] = useState([]);

    /*Part Number*/
    const [partNumber, setPartNumber] = useState("");
    const [partNumberError, setPartNumberError] = useState(false);
    /*Description*/
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    /*Specifications*/
    const [specifications, setSpecifications] = useState("");
    const [specificationsError, setSpecificationsError] = useState(false);
    /*Image*/
    const [file, setFile] = useState(false);
    const inputFile = useRef(null);
    const [image, setImage] = useState(null);
    const [imagePath, setImagePath] = useState("");
    const [croppedImg, setCroppedImg] = useState(null);
    const [crop, setCrop] = useState({ aspect: 1 / 1, width: 30, height: 30 });
    const [openImageModal, setOpenImageModal] = useState(false);
    const [receivedImage, setReceivedImage] = useState(null);
    const [modifiedImage, setModifiedImage] = useState(false);
    /*Business Unit*/
    const [businessUnit, setBusinessUnit] = useState();
    const [businessUnitError, setBusinessUnitError] = useState(false);
    /*Category*/
    const [category, setCategory] = useState();
    const [categoryError, setCategoryError] = useState(false);
    /*Switch*/
    const [switchID, setSwitchID] = useState();
    const [switchError, setSwitchError] = useState(false);
    /*Switch Style*/
    const [switchStyle, setSwitchStyle] = useState();
    const [switchStyleError, setSwitchStyleError] = useState(false);
    /*Switch Color*/
    const [switchColor, setSwitchColor] = useState();
    const [switchColorError, setSwitchColorError] = useState(false);
    /*Switch Assembly Type*/
    const [switchAssemblyType, setSwitchAssemblyType] = useState();
    const [switchAssemblyTypeError, setSwitchAssemblyTypeError] =
      useState(false);
    /*Coated Switch*/
    const [coatedSwitch, setCoatedSwitch] = useState(false);
    /*Controller*/
    const [controller, setController] = useState();
    const [controllerError, setControllerError] = useState(false);
    /*Controller Type*/
    const [controllerType, setControllerType] = useState();
    const [controllerTypeError, setControllerTypeError] = useState(false);
    /*Packaging Type*/
    const [packagingType, setPackagingType] = useState();
    const [packagingTypeError, setPackagingTypeError] = useState(false);
    /*Power Supply*/
    const [powerSupply, setPowerSupply] = useState();
    const [powerSupplyError, setPowerSupplyError] = useState(false);
    /*Power Supply*/
    const [amps, setAmps] = useState();
    const [ampsError, setAmpsError] = useState(false);
    /*volts*/
    const [volts, setVolts] = useState();
    const [voltsError, setVoltsError] = useState(false);
    /*Number of gangs*/
    const [nbOfGangs, setNbOfGangs] = useState();
    const [nbOfGangsError, setNbOfGangsError] = useState(false);
    /*Gateway Type*/
    const [gatewayType, setGatewayType] = useState();
    const [gatewayTypeError, setGatewayTypeError] = useState(false);
    /*UPC*/
    const [upc, setUpc] = useState("");
    const [upcError, setUpcError] = useState(false);
    /*Case Quantity*/
    const [caseQty, setCaseQty] = useState("1");
    const [caseQtyError, setCaseQtyError] = useState(false);
    /*Available to resellers*/
    const [availableToResellers, setAvailableToResellers] = useState(false);
    /*Loading*/
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
    /**For product nav number */
    const [productNavNumber, setProductNavNumber] = useState("");

    const history = useHistory();

    /*Get current user ID from Redux*/
    const currentUserID = useSelector((state) =>
      state.user.user ? state.user.user.user.userID : ""
    );

    /*Get current company type from redux*/
    const currentCompanyType = useSelector((state) =>
      state.user.user ? state.user.user.user.company.type : ""
    );

    const roleType = useSelector((state) =>
      state.user.user ? state.user.user.user.role.description : ""
    );

    const isLevvenAdmin = roleType === "Levven Admin";

    /*Functions*/

    /*Fetch company data by ID*/
    const fetchProductDataById = () => {
      productServices
        .getProductByID(receivedProductID)
        .then((response) => {
          const productData = response.data;
          setIsValid(response.data.isValid);
          setProduct(productData);
          setProductNavNumber(productData.navNo);
          setPartNumber(productData.partNbr);
          setDescription(productData.description);
          setSpecifications(productData.specifications);
          setBusinessUnit(productData.businessUnit);
          setCategory(productData.category);
          setSwitchID(productData.switch);
          setController(productData.controller);
          setSwitchAssemblyType(productData.switchAssemblyType);
          setSwitchColor(productData.switchColor);
          setSwitchStyle(productData.switchStyle);
          setCoatedSwitch(productData.coatedSwitch);
          setControllerType(productData.controllerType);
          setPowerSupply(productData.powerSupply);
          setAmps(productData.amps);
          setVolts(productData.volts);
          setPackagingType(productData.packaging);
          setNbOfGangs(productData.gangNbr);
          setGatewayType(productData.gatewayType);
          setUpc(productData.upc !== "null" ? productData.upc : "");
          setCaseQty(productData.eachCaseQty);
          setAvailableToResellers(productData.availableToResellers);
          setImagePath(productData.imagePath);
          fetchImageFile(productData.productID, productData.imagePath);
        })
        .catch((error) => setSaveError(error.response.data.error.error))
        .finally(() => {
          setLoading(false);
        });
    };

    /*Save Changes*/
    const save = (event) => {
      if (event) event.preventDefault();
      setButtonLoading(true);
      setTabButtonLoading(true);
      /*Conditions to check if there are any errors*/
      if (partNumber === "") setPartNumberError(true);
      if (description === "") {
        setDescriptionError(true);
        setSpecificationsError(true);
      }
      if (specifications === "") setSpecifications(description);
      if (!businessUnit || businessUnit === null) setBusinessUnitError(true);
      if (!category || category === null) setCategoryError(true);
      if (
        category?.description === "Assembly" &&
        (!switchID || switchID === null)
      )
        setSwitchError(true);
      if (
        category?.description === "Assembly" &&
        (!controller || controller === null)
      )
        setControllerError(true);
      if (
        category?.description === "Assembly" &&
        (!switchAssemblyType || switchAssemblyType === null)
      )
        setSwitchAssemblyTypeError(true);
      if (
        (category?.description === "Switch" ||
          category?.description === "Assembly") &&
        (!switchColor || switchColor === null)
      )
        setSwitchColorError(true);
      if (
        (category?.description === "Switch" ||
          category?.description === "Assembly") &&
        (!switchStyle || switchStyle === null)
      )
        setSwitchStyleError(true);
      if (
        (category?.description === "Controller" ||
          category?.description === "Assembly") &&
        (!controllerType || controllerType === null)
      )
        setControllerTypeError(true);
      if (
        (category?.description === "Controller" ||
          category?.description === "Assembly") &&
        (!amps || amps === null)
      )
        setAmpsError(true);
      if (
        (category?.description === "Controller" ||
          category?.description === "Assembly") &&
        (!volts || volts === null)
      )
        setVoltsError(true);
      if (
        category?.description !== "Assembly" &&
        packagingType?.description === ""
      )
        setPackagingTypeError(true);
      if (
        category?.description === "Wall Plate" &&
        (!nbOfGangs || nbOfGangs === null)
      )
        setNbOfGangsError(true);
      if (
        category?.description === "Gateway" &&
        (!gatewayType || gatewayType === null)
      )
        setGatewayTypeError(true);
      if (
        (packagingType?.description === "Retail" ||
          packagingType?.description === "Bulk & Retail") &&
        upc === ""
      )
        setUpcError(true);
      if (caseQty === "") setCaseQtyError(true);
      /*If there is any error the function returns and highlights the field containing the error*/
      if (
        partNumber === "" ||
        description === "" ||
        specifications === "" ||
        !businessUnit ||
        businessUnit === null ||
        !category ||
        category === null ||
        (category?.description === "Assembly" &&
          (!switchID || switchID === null)) ||
        (category?.description === "Assembly" &&
          (!controller || controller === null)) ||
        (category?.description === "Assembly" &&
          (!switchAssemblyType || switchAssemblyType === null)) ||
        ((category?.description === "Switch" ||
          category?.description === "Assembly") &&
          (!switchColor || switchColor === null)) ||
        ((category?.description === "Switch" ||
          category?.description === "Assembly") &&
          (!switchStyle || switchStyle === null)) ||
        ((category?.description === "Controller" ||
          category?.description === "Assembly") &&
          (!controllerType || controllerType === null)) ||
        ((category?.description === "Controller" ||
          category?.description === "Assembly") &&
          (!amps || amps === null)) ||
        ((category?.description === "Controller" ||
          category?.description === "Assembly") &&
          (!volts || volts === null)) ||
        (category?.description !== "Assembly" &&
          packagingType?.description === "") ||
        (category?.description === "Wall Plate" &&
          (!nbOfGangs || nbOfGangs === null)) ||
        (category?.description === "Gateway" &&
          (!gatewayType || gatewayType === null)) ||
        ((packagingType?.description === "Retail" ||
          packagingType?.description === "Bulk & Retail") &&
          upc === "")
      ) {
        setButtonLoading(false);
        setTabButtonLoading(false);
        setSaveError("Please Check if All the Fields Are Valid");
        return;
      } else {
        /*If there is no errors*/
        const imageToUpload = modifiedImage
          ? base64StringtoFile(image, imagePath)
          : base64StringtoFile(receivedImage, imagePath);

        const formData = new FormData();
        if (!create) {
          /*If the page is in edit mode*/
          formData.append("ModifiedByID", currentUserID);
          formData.append("PartNbr", partNumber);
          formData.append("Description", description);
          formData.append("Specifications", specifications);
          formData.append(
            "BusinessUnitCode",
            businessUnit ? businessUnit.businessUnitCode : null
          );
          formData.append(
            "CategoryCode",
            category ? category.categoryCode : null
          );
          formData.append(
            "SwitchStyleCode",
            switchStyle ? switchStyle.switchStyleCode : ""
          );
          formData.append(
            "SwitchColorCode",
            switchColor ? switchColor.switchColorCode : ""
          );
          formData.append(
            "ControllerTypeCode",
            controllerType ? controllerType.controllerTypeCode : ""
          );
          formData.append(
            "SwitchID",
            switchID && switchID !== null
              ? switchID.productID !== undefined
                ? switchID.productID
                : ""
              : ""
          );
          formData.append(
            "ControllerID",
            controller && controller !== null
              ? controller.productID !== undefined
                ? controller.productID
                : ""
              : ""
          );
          formData.append(
            "SwitchAssemblyTypeCode",
            switchAssemblyType && switchAssemblyType !== null
              ? switchAssemblyType.switchAssemblyTypeCode !== undefined
                ? switchAssemblyType.switchAssemblyTypeCode
                : ""
              : ""
          );
          formData.append(
            "CoatedSwitch",
            coatedSwitch !== undefined ? coatedSwitch : false
          );
          formData.append(
            "PackagingCode",
            packagingType ? packagingType.packagingCode : 1
          );
          formData.append(
            "PowerSupplyCode",
            powerSupply ? powerSupply.powerSupplyCode : ""
          );
          formData.append("AmpsCode", amps ? amps.ampsCode : "");
          formData.append("VoltsCode", volts ? volts.voltsCode : "");
          formData.append(
            "GangNbrCode",
            nbOfGangs ? nbOfGangs.gangNbrCode : ""
          );
          formData.append(
            "GatewayTypeCode",
            gatewayType ? gatewayType.gatewayTypeCode : ""
          );
          formData.append("UPC", upc);
          formData.append("EachCaseQty", caseQty);
          formData.append("Image", imageToUpload);
          formData.append("AvailableToResellers", availableToResellers);
          formData.append("NAVProductID", "");
          formData.append("NavNo", productNavNumber);

          productServices
            .updateProductData(product.productID, formData)
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setButtonLoading(false);
              setTabButtonLoading(false);
              setOpenSnackBar(true);
              setSaveError("");
              fetchProductDataById(response.data);
              setProductName(partNumber);

              setModifiedImage(false);
            })
            .catch((error) => {
              setSaveError(error.response?.data.message);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        } else if (create || duplicate) {
          /*If the page is in create mode*/
          formData.append("ModifiedByID", currentUserID);
          formData.append("PartNbr", partNumber);
          formData.append("Description", description);
          formData.append("Specifications", specifications);
          formData.append("BusinessUnitCode", businessUnit.businessUnitCode);
          formData.append("CategoryCode", category.categoryCode);
          formData.append(
            "SwitchStyleCode",
            switchStyle ? switchStyle.switchStyleCode : ""
          );
          formData.append(
            "SwitchColorCode",
            switchColor ? switchColor.switchColorCode : ""
          );
          formData.append(
            "ControllerTypeCode",
            controllerType ? controllerType.controllerTypeCode : ""
          );
          formData.append(
            "SwitchID",
            switchID && switchID !== null
              ? switchID.productID !== undefined
                ? switchID.productID
                : ""
              : ""
          );
          formData.append(
            "ControllerID",
            controller && controller !== null
              ? controller.productID !== undefined
                ? controller.productID
                : ""
              : ""
          );
          formData.append(
            "SwitchAssemblyTypeCode",
            switchAssemblyType && switchAssemblyType !== null
              ? switchAssemblyType.switchAssemblyTypeCode !== undefined
                ? switchAssemblyType.switchAssemblyTypeCode
                : ""
              : ""
          );
          formData.append(
            "CoatedSwitch",
            coatedSwitch !== undefined ? coatedSwitch : false
          );
          formData.append(
            "PackagingCode",
            packagingType ? packagingType.packagingCode : 1
          );
          formData.append(
            "PowerSupplyCode",
            powerSupply ? powerSupply.powerSupplyCode : ""
          );
          formData.append("AmpsCode", amps ? amps.ampsCode : "");
          formData.append("VoltsCode", volts ? volts.voltsCode : "");
          formData.append(
            "GangNbrCode",
            nbOfGangs ? nbOfGangs.gangNbrCode : ""
          );
          formData.append(
            "GatewayTypeCode",
            gatewayType ? gatewayType.gatewayTypeCode : ""
          );

          formData.append("UPC", upc);
          formData.append("EachCaseQty", caseQty);
          formData.append("Image", imageToUpload);
          formData.append("AvailableToResellers", availableToResellers);
          formData.append("NAVProductID", "");
          formData.append("DuplicateProduct", duplicate);
          formData.append("OldProductID", receivedProductID);
          formData.append("NavNo", productNavNumber);
          productServices
            .createProduct(formData)
            .then((response) => {
              setPristine();
              setFormChanged(false);
              sessionStorage.removeItem("unsavedChanges");

              setSaveError("");
              setButtonLoading(false);
              setTabButtonLoading(false);
              history.push({
                pathname: "/product-information",
                state: {
                  id: response.data.productID,
                  partNumber: response.data.partNbr,
                  description: response.data.description,
                  businessUnit: response.data.businessUnit,
                  productCategory: response.data.productCategory,
                  switchStyle: response.data.switchStyle,
                  controllerType: response.data.controllerType,
                  packaging: response.data.packaging,
                  powerSupply: response.data.powerSupply,
                  amps: response.data.amps,
                  volts: response.data.volts,
                  gatewayType: response.data.gatewayType,
                  source: "Products",
                  openSnackBar: true,
                },
              });
            })
            .catch((error) => {
              setSaveError(error.response.data.error.error);
              setButtonLoading(false);
              setTabButtonLoading(false);
            });
        }
      }
    };

    /*Convert base64String (image long text) to file (blob)*/
    const base64StringtoFile = (base64String, filename) => {
      if (base64String !== "" && base64String !== null) {
        var arr = base64String.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      } else return null;
    };

    /*Convert UTC to local time*/
    const convertUTC = (utcDate) => {
      var date = new Date(utcDate + "Z");
      var originDate = new Date("January 1, 2000 00:00:00");
      if (date < originDate) {
        return "";
      } else {
        const d = date.toString();
        if (d === "Invalid Date") {
          return "";
        }
        return (
          d.substring(8, 10) +
          "/" +
          d.substring(4, 7) +
          "/" +
          d.substring(13, 15) +
          " at " +
          d.substring(17, 21)
        );
      }
    };

    /*Get the cropped image*/
    const getCroppedImg = () => {
      if (image === null || image === undefined || typeof image !== "object")
        return;
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      // New lines to be added
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setCroppedImg(base64Image);
    };

    /*Fetch Business Units*/
    const fetchAllBusinessUnits = () => {
      systemDataServices
        .getAllBusinessUnits()
        .then((response) => {
          setBusinessUnits(response.data);
          setBusinessUnits((businessUnits) => [
            ...businessUnits,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Controls*/
    const fetchCategories = (buUrl) => {
      systemDataServices
        .getProductCategoryByID(buUrl)
        .then((response) => {
          setCategories(response.data);
          setCategories((categories) => [...categories, { description: "" }]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Switch Assembly Type*/
    const fetchSwitchAssemblyTypes = () => {
      systemDataServices
        .getAllSwitchAssemblyType()
        .then((response) => {
          setSwitchAssemblyTypes(response.data);
          setSwitchAssemblyTypes((switchAssemblyTypes) => [
            ...switchAssemblyTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Switch Colors*/
    const fetchSwitchColors = () => {
      systemDataServices
        .getAllSwitchColor()
        .then((response) => {
          setSwitchColors(response.data);
          setSwitchColors((switchColors) => [
            ...switchColors,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Switch Styles*/
    const fetchSwitchStyles = () => {
      systemDataServices
        .getAllSwitchStyle()
        .then((response) => {
          setSwitchStyles(response.data);
          setSwitchStyles((switchStyles) => [
            ...switchStyles,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Switch Styles*/
    const fetchControllerTypes = () => {
      systemDataServices
        .getAllControllerType()
        .then((response) => {
          setControllerTypes(response.data);
          setControllerTypes((controllerTypes) => [
            ...controllerTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Power Supplies*/
    const fetchPowerSupplies = () => {
      systemDataServices
        .getAllPowerSupply()
        .then((response) => {
          setPowerSupplyOptions(response.data);
          setPowerSupplyOptions((powerSupplyOptions) => [
            ...powerSupplyOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Amps*/
    const fetchAmps = () => {
      systemDataServices
        .getAllAmps()
        .then((response) => {
          setAmpsOptions(response.data);
          setAmpsOptions((ampsOptions) => [
            ...ampsOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Volts*/
    const fetchVolts = () => {
      systemDataServices
        .getAllVolts()
        .then((response) => {
          setVoltsOptions(response.data);
          setVoltsOptions((voltsOptions) => [
            ...voltsOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Pachaging Types*/
    const fetchPackagingTypes = () => {
      systemDataServices
        .getAllPackaging()
        .then((response) => {
          setPackagingTypes(response.data);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Number of Gangs*/
    const fetchNbOFGang = () => {
      systemDataServices
        .getAllGangNumber()
        .then((response) => {
          setNbOfGangOptions(response.data);
          setNbOfGangOptions((nbOfGangOptions) => [
            ...nbOfGangOptions,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Gateway Types*/
    const fetchGatewayTypes = () => {
      systemDataServices
        .getAllGatewayType()
        .then((response) => {
          setGateWayTypes(response.data);
          setLoading(false);
          setGateWayTypes((gatewayTypes) => [
            ...gatewayTypes,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Switches*/
    const fetchSwitches = () => {
      productServices
        .getProductsByCategory(3)
        .then((response) => {
          setSwitches(response.data);
          setSwitches((switches) => [...switches, { description: "" }]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Controllers*/
    const fetchControllers = () => {
      productServices
        .getProductsByCategory("4")
        .then((response) => {
          setControllers(response.data);
          setControllers((controllers) => [
            ...controllers,
            { description: "" },
          ]);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
        });
    };

    /*Fetch Image File after receiving it's path from the getProductById()*/
    const fetchImageFile = (id, path) => {
      if (path === null || path === "") return;
      productServices
        .getImageFile(path, id)
        .then((response) => {
          /*Set image to display*/
          setImage(URL.createObjectURL(response.data));
          /*Convert received blob to image file to send it back to the backend*/
          var reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onloadend = function () {
            var base64String = reader.result;
            setReceivedImage(base64String);
          };
        })
        .catch((error) => {
          var reader = new FileReader();
          reader.onload = function () {
            setSaveError(JSON.parse(reader.result).error.error);
          };
          reader.readAsText(error.response.data);
        });
    };

    /*Handle Close Alert Snackbar*/
    const handleCloseAlertSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSaveError("");
    };

    useImperativeHandle(ref, () => ({
      setPristine,
      save,
    }));

    /*Initial useEffect*/
    useEffect(() => {
      if ((!create || duplicate) && receivedProductID !== 0) {
        fetchProductDataById();
      }
      fetchAllBusinessUnits();
      fetchSwitches();
      fetchControllers();
      fetchSwitchAssemblyTypes();
      fetchSwitchColors();
      fetchSwitchStyles();
      fetchControllerTypes();
      fetchPowerSupplies();
      fetchAmps();
      fetchVolts();
      fetchPackagingTypes();
      fetchNbOFGang();
      fetchGatewayTypes();
    }, []);

    /*Get the value of the cropped image everytime its value changes*/
    useEffect(() => {
      getCroppedImg();
    }, [croppedImg]);

    /*Fetch categories everytime the business unit is changed*/
    useEffect(() => {
      if (businessUnit) fetchCategories(businessUnit.businessUnitCode);
    }, [businessUnit]);

    /*Set switch values automatically after specifying a switch*/
    useEffect(() => {
      if (switchID) {
        setSwitchColor(switchID.switchColor);
        setSwitchColorError(false);
        setSwitchStyle(switchID.switchStyle);
        setSwitchStyleError(false);
        setCoatedSwitch(switchID.coatedSwitch);
      }
    }, [switchID]);

    /*Set controller values automatically after specifying a controller*/
    useEffect(() => {
      if (controller) {
        setControllerType(controller.controllerType);
        setControllerTypeError(false);
        setPowerSupply(controller.powerSupply);
        setPowerSupplyError(false);
        setAmps(controller.amps);
        setAmpsError(false);
        setVolts(controller.volts);
        setVoltsError(false);
      }
    }, [controller]);

    /*Reset switch and controller fields after changing category value*/
    useEffect(() => {
      if (category) {
        if (category.description !== "Assembly") {
          setSwitchID({});
          setController({});
          setSwitchAssemblyType({});
        }
      }
    }, [category]);

    return (
      <Paper elevation={0}>
        {/*Main Grid*/}
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="80vh"
          >
            <CircularProgress sx={{ color: "#0077c6" }} />
          </Grid>
        ) : (
          <>
            {/* {Prompt} */}
            {openImageModal ? (
              <Modal openModal={openImageModal}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Image Crop
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Crop Preview
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ReactCrop
                      src={file}
                      crop={crop}
                      onChange={(newCrop) => {
                        setCrop(newCrop);
                        setDirty();
                        setFormChanged(true);
                        sessionStorage.setItem(
                          "unsavedChanges",
                          JSON.stringify(true)
                        );
                      }}
                      onImageLoaded={(c) => {
                        setImage(c);
                        setCroppedImg(c);
                        getCroppedImg();
                      }}
                      onComplete={(c) => {
                        setCroppedImg(c);
                        getCroppedImg();
                      }}
                      style={{
                        border: "1px solid #c4c4c4",
                        borderRadius: "8px",
                      }}
                      aspec
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    {croppedImg ? (
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid #c4c4c4",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        onClick={() => inputFile.current.click()}
                      >
                        <img
                          src={croppedImg}
                          alt="Cropped Image"
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            margin: "auto",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <CustomButton
                      toggle={() => {
                        setImage(null);
                        setCroppedImg(null);
                        setOpenImageModal(false);
                        setCrop({ aspect: 1 / 1, width: 30, height: 30 });
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <div style={{ width: "20px" }} />
                    <CustomButton
                      toggle={() => {
                        setImage(croppedImg);
                        setCroppedImg(null);
                        setOpenImageModal(false);
                        setCrop({ aspect: 1 / 1, width: 30, height: 30 });
                      }}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
              </Modal>
            ) : null}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={9}
                xl={9}
                sx={{ borderRight: "2px solid #c4c4c4", paddingRight: "20px" }}
              >
                {/*Form Grid*/}
                <form id="form" onSubmit={create || adminManage ? save : ""}>
                  <Grid container spacing={6}>
                    {isLevvenAdmin ? (
                      <>
                        {" "}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography variant="h6" component="span">
                            Nav Connection
                          </Typography>
                          <Underline />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          {product && (
                            <span
                              style={{
                                padding: "5px 10px",
                                width: "100px",
                                borderRadius: "25px",
                                textAlign: "center",
                                backgroundColor: isValid
                                  ? "rgba(29, 148, 75, 0.2)"
                                  : "rgba(150, 150, 150, 0.3)",
                                color: isValid
                                  ? "rgba(29, 148, 75, 1)"
                                  : "rgba(150, 150, 150, 1)",
                              }}
                            >
                              {isValid ? "Active" : "Inactive"}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Product Nav Number"
                            fullWidth
                            value={productNavNumber}
                            onChange={(e) => {
                              if (create || adminManage) {
                                if (isLevvenAdmin) {
                                  setProductNavNumber(e.target.value);

                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            inputProps={{ maxLength: 100 }}
                            disabled={create || adminManage ? false : true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                      </>
                    ) : null}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Overview
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>

                    {/* Image */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          width: "100%",
                          height: "322px",
                          border: "1px solid #c4c4c4",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        onClick={() => inputFile.current.click()}
                      >
                        {!image || image === null ? (
                          <Image
                            color="#c6c6c6"
                            size={50}
                            style={{ alignSelf: "center" }}
                          />
                        ) : (
                          <img
                            src={image}
                            alt=" "
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              margin: "auto",
                              alignSelf: "center",
                              aspectRatio: "1",
                            }}
                          />
                        )}
                      </div>
                      <input
                        type="file"
                        ref={inputFile}
                        disabled={create || adminManage ? false : true}
                        onClick={(e) => (e.target.value = "")}
                        onChange={(e) => {
                          if (create || adminManage) {
                            const image = e.target.files[0];
                            if (!image) {
                              return false;
                            }
                            if (
                              !image.name.match(
                                /([a-zA-Z0-9s_\\.-:() ])+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg)$/
                              )
                            ) {
                              return false;
                            }
                            setOpenImageModal(true);
                            setFile(URL.createObjectURL(image));
                            setImagePath(image.name);
                            setModifiedImage(true);
                          }
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {/* Part Number */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ marginBottom: "55px" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Part Number"
                          required
                          fullWidth
                          value={partNumber}
                          error={partNumberError}
                          helperText={
                            partNumberError ? "Part Number field is empty" : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage) {
                              setPartNumber(e.target.value);
                              if (partNumberError) setPartNumberError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={create || adminManage ? false : true}
                          inputProps={{ maxLength: 25 }}
                        />
                      </Grid>
                      {/* Description */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ marginBottom: "55px" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Description"
                          required
                          fullWidth
                          value={description}
                          error={descriptionError}
                          helperText={
                            descriptionError ? "Description field is empty" : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage) {
                              setDescription(e.target.value);
                              if (descriptionError) setDescriptionError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          onBlur={() => {
                            if (specifications === "")
                              setSpecifications(description);
                            setSpecificationsError(false);
                          }}
                          inputProps={{ maxLength: 100 }}
                          disabled={create || adminManage ? false : true}
                        />
                      </Grid>
                      {/* Specifications*/}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="Specifications"
                          required
                          fullWidth
                          multiline
                          rows={5}
                          value={specifications}
                          error={specificationsError}
                          helperText={
                            specificationsError
                              ? "Specifications field is empty"
                              : ""
                          }
                          onChange={(e) => {
                            if (create || adminManage) {
                              setSpecifications(e.target.value);
                              if (specificationsError)
                                setSpecificationsError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }}
                          disabled={create || adminManage ? false : true}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        General
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Business Unit*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={businessUnits}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={businessUnit ? businessUnit : {}}
                        onChange={(event, value) => {
                          if (create) {
                            setBusinessUnit(value);
                            if (businessUnitError) setBusinessUnitError(false);
                          }
                        }}
                        disabled={create ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business Unit"
                            fullWidth
                            size="small"
                            required
                            error={businessUnitError}
                            helperText={
                              businessUnitError
                                ? "Business Unit field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/*Business Unit*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={categories ? categories : {}}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={category ? category : {}}
                        onChange={(event, value) => {
                          if (create) {
                            setCategory(value);
                            if (categoryError) setCategoryError(false);
                          }
                        }}
                        disabled={create ? false : true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            fullWidth
                            size="small"
                            required
                            error={categoryError}
                            helperText={
                              categoryError ? "Category field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Assembly
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Switch */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={switches}
                        getOptionLabel={(option) =>
                          option.description
                            ? option.partNbr + ": " + option.description
                            : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={switchID ? switchID : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description === "Assembly") {
                              setSwitchID(value);
                              if (switchError) setSwitchError(false);
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Assembly"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch"
                            fullWidth
                            size="small"
                            required={category?.description === "Assembly"}
                            error={switchError}
                            helperText={
                              switchError ? "Switch field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Controller */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={controllers}
                        getOptionLabel={(option) =>
                          option.description
                            ? option.partNbr + ": " + option.description
                            : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={controller ? controller : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description === "Assembly") {
                              setController(value);
                              if (controllerError) setControllerError(false);
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Assembly"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Controller"
                            fullWidth
                            size="small"
                            required={category?.description === "Assembly"}
                            error={controllerError}
                            helperText={
                              controllerError ? "Controller field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Switch Assembly Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={switchAssemblyTypes}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={switchAssemblyType ? switchAssemblyType : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description === "Assembly") {
                              setSwitchAssemblyType(value);
                              if (switchAssemblyTypeError)
                                setSwitchAssemblyTypeError(false);
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Assembly"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch Assembly Type"
                            fullWidth
                            size="small"
                            required={category?.description === "Assembly"}
                            error={switchAssemblyTypeError}
                            helperText={
                              switchAssemblyTypeError
                                ? "Switch Assembly Type field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Switch
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Switch Style */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {/* Switch Style */}
                      <Autocomplete
                        id="combo-box-demo"
                        options={switchStyles}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          (category?.description !== "Switch" &&
                            option.description === "Portable") ||
                          option.description === ""
                        }
                        value={switchStyle ? switchStyle : {}}
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly" ||
                              category?.description !== "Switch"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description !== "Assembly" ||
                              category?.description === "Switch"
                            ) {
                              setSwitchStyle(value);
                              if (switchStyleError) setSwitchStyleError(false);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch Style"
                            fullWidth
                            size="small"
                            required={
                              category?.description === "Assembly" ||
                              category?.description === "Switch"
                            }
                            error={switchStyleError}
                            helperText={
                              switchStyleError
                                ? "Switch Style field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Switch Color */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={switchColors}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={switchColor ? switchColor : {}}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Switch" &&
                              category?.description !== "Wall Plate" &&
                              category?.description !== "Harness"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description === "Switch" ||
                              category?.description === "Wall Plate" ||
                              category?.description === "Harness"
                            ) {
                              setSwitchColor(value);
                              if (switchColorError) setSwitchColorError(false);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Switch Color"
                            fullWidth
                            size="small"
                            required={
                              category?.description === "Assembly" ||
                              category?.description === "Switch" ||
                              category?.description === "Wall Plate" ||
                              category?.description === "Harness"
                            }
                            error={switchColorError}
                            helperText={
                              switchColorError
                                ? "Switch Color field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/*Coated Switch*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={coatedSwitch}
                            onClick={() => {
                              if (create || adminManage) {
                                if (category?.description === "Switch") {
                                  setCoatedSwitch(!coatedSwitch);
                                  setDirty();
                                  setFormChanged(true);
                                  sessionStorage.setItem(
                                    "unsavedChanges",
                                    JSON.stringify(true)
                                  );
                                }
                              }
                            }}
                            disabled={
                              create || adminManage
                                ? category?.description !== "Switch"
                                  ? true
                                  : false
                                : true
                            }
                          />
                        }
                        label="Coated Switch"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Controller
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Controller Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={controllerTypes}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={controllerType ? controllerType : {}}
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly" ||
                              category?.description !== "Controller"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description !== "Assembly" ||
                              category?.description === "Controller"
                            ) {
                              setControllerType(value);
                              if (controllerTypeError)
                                setControllerTypeError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Controller Type"
                            fullWidth
                            size="small"
                            required={
                              category?.description === "Assembly" ||
                              category?.description === "Controller"
                            }
                            error={controllerTypeError}
                            helperText={
                              controllerTypeError
                                ? "Controller Type field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Power Supply */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={powerSupplyOptions}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={powerSupply ? powerSupply : ""}
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly" ||
                              category?.description !== "Controller"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description !== "Assembly" ||
                              category?.description === "Controller"
                            ) {
                              setPowerSupply(value);
                              if (powerSupplyError) setPowerSupplyError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Power Supply"
                            fullWidth
                            size="small"
                            error={powerSupplyError}
                            helperText={
                              powerSupplyError
                                ? "Power Supply field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Amps */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={ampsOptions}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={amps ? amps : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description !== "Assembly" ||
                              category?.description === "Controller"
                            ) {
                              setAmps(value);
                              if (ampsError) setAmpsError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly" ||
                              category?.description !== "Controller"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Amps"
                            fullWidth
                            size="small"
                            required={
                              category?.description === "Assembly" ||
                              category?.description === "Controller"
                            }
                            error={ampsError}
                            helperText={ampsError ? "Amps field is empty" : ""}
                          />
                        )}
                      />
                    </Grid>
                    {/* Volts */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={voltsOptions}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        getOptionDisabled={(option) =>
                          option.description === ""
                        }
                        value={volts ? volts : {}}
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly" ||
                              category?.description !== "Controller"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (
                              category?.description !== "Assembly" ||
                              category?.description === "Controller"
                            ) {
                              setVolts(value);
                              if (voltsError) setVoltsError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Volts"
                            fullWidth
                            size="small"
                            required={
                              category?.description === "Assembly" ||
                              category?.description === "Controller"
                            }
                            error={voltsError}
                            helperText={
                              voltsError ? "Volts field is empty" : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Detailed Specifications
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* Packaging Types */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={packagingTypes}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={
                          packagingType
                            ? packagingType
                            : { description: "Bulk" }
                        }
                        disabled={
                          create || adminManage
                            ? category?.description === "Assembly"
                              ? true
                              : false
                            : true
                        }
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description !== "Assembly") {
                              setPackagingType(value);
                              if (packagingTypeError)
                                setPackagingTypeError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Packaging Type"
                            fullWidth
                            size="small"
                            required={category?.description !== "Assembly"}
                            error={packagingTypeError}
                            helperText={
                              packagingTypeError
                                ? "Packaging Type field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Number Of Gang */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={nbOfGangOptions}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={nbOfGangs ? nbOfGangs : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description === "Wall Plate") {
                              setNbOfGangs(value);
                              if (nbOfGangsError) setNbOfGangs(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Wall Plate"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Number Of Gang"
                            fullWidth
                            size="small"
                            required={category?.description === "Wall Plate"}
                            error={nbOfGangsError}
                            helperText={
                              nbOfGangsError
                                ? "Number Of Gang field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    {/* Gateway Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={gateWayTypes}
                        getOptionLabel={(option) =>
                          option.description ? option.description : ""
                        }
                        value={gatewayType ? gatewayType : {}}
                        onChange={(event, value) => {
                          if (create || adminManage) {
                            if (category?.description === "Gateway") {
                              setGatewayType(value);
                              if (gatewayTypeError) setGatewayTypeError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        disabled={
                          create || adminManage
                            ? category?.description !== "Gateway"
                              ? true
                              : false
                            : true
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gateway Type"
                            fullWidth
                            size="small"
                            required={category?.description === "Gateway"}
                            error={gatewayTypeError}
                            helperText={
                              gatewayTypeError
                                ? "Gateway Type field is empty"
                                : ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="h6" component="span">
                        Resellers
                      </Typography>
                      <Underline />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/* UPC */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="UPC"
                        required={
                          packagingType?.description === "Retail" ||
                          packagingType?.description === "Bulk & Retail"
                        }
                        disabled={
                          create || adminManage
                            ? packagingType?.description === "Bulk"
                              ? true
                              : false
                            : true
                        }
                        fullWidth
                        value={upc}
                        error={upcError}
                        helperText={upcError ? "UPC field is empty" : ""}
                        onChange={(e) => {
                          if (create || adminManage) {
                            if (
                              packagingType?.description === "Retail" ||
                              packagingType?.description === "Bulk & Retail"
                            ) {
                              setUpc(e.target.value);
                              if (upcError) setDescriptionError(false);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }
                          }
                        }}
                        inputProps={{ maxLength: 25 }}
                      />
                    </Grid>
                    {/* Case Quantity */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Each / Case Quantity"
                        required
                        type="number"
                        fullWidth
                        value={caseQty}
                        error={caseQtyError}
                        helperText={
                          caseQtyError
                            ? "Each / Case Quantity field is empty"
                            : ""
                        }
                        onChange={(e) => {
                          if (create || adminManage) {
                            setCaseQty(e.target.value);
                            if (caseQtyError) setCaseQtyError(false);
                            setDirty();
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                          }
                        }}
                        inputProps={{ maxLength: 15 }}
                        disabled={create || adminManage ? false : true}
                      />
                    </Grid>
                    {/*Available to resellers*/}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={availableToResellers}
                            onClick={() => {
                              setAvailableToResellers(!availableToResellers);
                              setDirty();
                              setFormChanged(true);
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(true)
                              );
                            }}
                            disabled={create || adminManage ? false : true}
                          />
                        }
                        label="Available To Resellers"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                    {/*Save button*/}
                    {create || adminManage ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                        }}
                      >
                        <CustomButton
                          type="submit"
                          loading={buttonLoading}
                          startIcon={<Save />}
                        >
                          Save
                        </CustomButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </form>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                xl={3}
                sx={{
                  paddingLeft: "20px",
                  alignSelf: "flex-start",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    border: "1px solid #c4c4c4",
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    {currentCompanyType === "L" ? (
                      <>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            variant="caption"
                            fontWeight={500}
                            component="span"
                          >
                            Product NAV Number:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography variant="caption" component="span">
                            {product && product !== null ? product.navNo : ""}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Sync Date/Time:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && product
                          ? convertUTC(product.syncDateTime)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modification Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && product
                          ? convertUTC(product.modificationDateTime)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        component="span"
                      >
                        Modified By:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="caption" component="span">
                        {!duplicate && product
                          ? product.modifiedBy
                            ? product.modifiedBy.firstName +
                              " " +
                              product.modifiedBy.lastName
                            : ""
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
        {saveError !== "" ? (
          <AlertSnackBar
            message={saveError}
            handleClose={handleCloseAlertSnackbar}
            open={saveError !== ""}
          />
        ) : null}
      </Paper>
    );
  }
);

export default ProductForm;
