import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { countryServices } from "../../services/country.services";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "PhoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: false,
    label: "Address 1",
  },
  {
    id: "City",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => {
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ backgroundColor: "#0077c6" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function LinkUserToContactTable({
  rows,
  selected,
  setSelected,
  numberRecords,
}) {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected !== null &&
                selected !== undefined &&
                selected !== {} && {
                  bgcolor: "#d4e8f6",
                }),
            }}
          >
            {selected !== null && selected !== undefined && selected !== {} ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                1 row selected
              </Typography>
            ) : null}
          </Toolbar>
          <TableContainer
            className={
              //   applyFilters ? "table-container-filters" :
              "table-container"
            }
            sx={{ maxHeight: 320, overflowY: "auto" }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead rowCount={numberRecords} />
              <TableBody>
                {numberRecords === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Contacts to display."}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const itemSelected =
                      selected !== null &&
                      selected !== undefined &&
                      selected !== {} &&
                      selected.no === row.no
                        ? true
                        : false;

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          sx={{ cursor: "pointer" }}
                          tabIndex={-1}
                          key={row.no}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => {
                                itemSelected
                                  ? setSelected(null)
                                  : setSelected(row);
                              }}
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={itemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.phoneNumber}</TableCell>
                          <TableCell>{row.address}</TableCell>
                          <TableCell>{row.city}</TableCell>
                        </TableRow>
                      </>
                    );
                  })
                )}

                <></>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
