import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const OrderTypeStep = ({
  setDirty,
  orderType,
  setOrderType,
  orderTypeError,
  setOrderTypeError,
  setTakeoff,
  setAgreement,
  setInstallerContact,
  setOverDefSwitchColor,
  setForModelHome,
  setSpecialNegotiated,
  setShippingMethod,
  company,
  setShelfInventoryWarning,
  orderID,
}) => {
  const [orderTypes, setOrderTypes] = useState([
    "Project Pack for Home",
    "Bulk Inventory",
  ]);

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
  }, []);

  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            Welcome to the Order Creation Wizard
          </Typography>
          <br />
          <Typography variant="h5" component="span">
            {orderID === 0
              ? "Please start by selecting your order type: "
              : "Your choice is: " + orderType}
          </Typography>
        </Grid>
        {/* Order Type */}
        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Autocomplete
            id="combo-box-demo"
            componentName="Field1"
            options={orderTypes}
            value={orderType ? orderType : ""}
            onChange={(event, value) => {
              if (value !== "Project Pack for Home") {
                setTakeoff();
                setAgreement();
                setInstallerContact();
                setOverDefSwitchColor();
                setForModelHome(false);
                setSpecialNegotiated(false);
              }
              if (company?.type !== "R" && value === "Bulk Inventory") {
                setShelfInventoryWarning(true);
              }
              setOrderType(value);
              if (orderTypeError) setOrderTypeError(false);
              setDirty();
            }}
            disabled={orderID !== 0}
            renderInput={(params) => (
              <TextField
                {...params}
                name="Field1"
                label="Order Type"
                fullWidth
                size="small"
                required
                error={orderTypeError}
                helperText={orderTypeError ? "Order Type field is empty" : ""}
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={0} sm={0} md={6} lg={6} xl={6}></Grid> */}
      </Grid>
    </div>
  );
};

export default OrderTypeStep;
