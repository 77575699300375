import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/root-reducer";
import {
  decryptLocalStorageObject,
  encryptLocalStorageObject,
} from "../helpers/link-variables";
import { decryptObject } from "../helpers/link-variables";

/* This is the global store where the state is saved, this store is kept in the local storage
and updated each time the state is updated */

const saveToLocalStorage = async (state) => {
  try {
    // var stringifiedState = JSON.stringify(state);
    const encryptedData = await encryptLocalStorageObject(state);

    localStorage.setItem("state", encryptedData);
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem("state");
    const returnedData = decryptLocalStorageObject(stateStr, false);
    return returnedData.returnObject ? returnedData.returnObject : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();

export const store = createStore(
  rootReducer,
  persistedStore,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
