import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the agreement */

const baseUrl = apiConfig + "/validation";

const validateAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/ValidateAddress", data);
};

const checkDuplicateAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckDuplicateAddress", data);
};

const checkRecaptchaV3 = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckReCaptchaTokenFirst", data);
};

const checkRecaptchaV2 = (data) => {
  return fetchWrapper.post(baseUrl + "/CheckReCaptchaTokenSecond", data);
};

const getAddressLookup = (data) => {
  return fetchWrapper.post(baseUrl + "/GetValidatedAddresses", data);
};

export const melissaServices = {
  validateAddress,
  checkDuplicateAddress,
  checkRecaptchaV3,
  checkRecaptchaV2,
  getAddressLookup,
};
