import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the user account */

const baseUrl = apiConfig + "/companyshippingaddress";

const getShippingAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/GetCompanyShippingAddressList", data);
};

const deleteShippingAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteCompanyShippingAddress", data);
};

const createShippingAddress = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateCompanyShippingAddress", data);
};

const getShippingAddressByID = (data) => {
  return fetchWrapper.get(baseUrl + "/GetCompanyShippingAddressByID/" + data);
};

const updateShippingAddress = (id, data) => {
  return fetchWrapper.post(
    baseUrl + "/UpdateCompanyShippingAddress/" + id,
    data
  );
};

const getAllCompanyShippingAddressesByCompanyID = (data) => {
  return fetchWrapper.post(
    baseUrl + "/GetAllFilteredCompanyShippingAddresses",
    data
  );
};

export const shippingaddressServices = {
  getShippingAddress,
  deleteShippingAddress,
  createShippingAddress,
  getShippingAddressByID,
  updateShippingAddress,
  getAllCompanyShippingAddressesByCompanyID,
};
