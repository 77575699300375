import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AddProductsTable from "./AddProductsTable";
import ChangeProductsTable from "./ChangeProductsTable";
import OverrideSwitchColorStep from "./OverrideSwitchColorStep";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OrderProductsModification = ({
  arrayFrom,
  arrayTo,
  setFormChanged,
  selectedFrom,
  selectedTo,
  setSelectedFrom,
  setSelectedTo,
  selectedFrom2,
  agreementDefaultSwitch,
  defaultSwitch,
  selectedTo2,
  setSelectedFrom2,
  setSelectedTo2,
  rows,
  arrayFrom2,
  arrayTo2,
  pageLoad,
  setDirty,
  addSwitchRows = [],
  setAddSwitchRows,
  addAssemblyRows = [],
  maxQuantity,
  setAddAssemblyRows,
  setRows,
  valueToAdd,
  setValueToAdd,
  displayAddSwitches = false,
  displayChangeSwitchColor = false,
  displayChangeSwitches = false,
  setSaveError,
  overDefSwitchColor,
  setOverDefSwitchColor,
  agreement,
  orderID,
  switchColors,
}) => {
  const [changed, setChanged] = React.useState(false);
  useEffect(() => {}, [changed]);
  return (
    <>
      {displayAddSwitches === false &&
      displayChangeSwitchColor === false &&
      displayChangeSwitches === false ? (
        <div style={{ marginTop: "50px", width: "100%", marginBottom: "20px" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h4" component="span">
                Making Changes
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h5"
                component="span"
                style={{ whiteSpace: "pre-line" }}
              >
                What do you wish to modify?
              </Typography>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {/**If User chooses to add switch */}
      {displayAddSwitches === true ? (
        <div
          style={{
            width: "100%",
            marginBottom: "20px",
            marginTop: "30px",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h4" component="span">
                Add Switches
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  maxHeight: "48vh",
                  overflowY: "auto",
                  paddingRight: "30px",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {" "}
                  <Accordion key={"Add Switches"} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"Add Switches-content"}
                      id={"Add Switches-id"}
                      style={{ flexDirection: "row-reverse" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        style={{ paddingLeft: "40px" }}
                      >
                        Add extra switches not included in the base plan:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        marginTop: "-10px",
                        marginBottom: "-10px",
                        marginLeft: "-16px",
                        marginRight: "-16px",
                      }}
                    >
                      <AddProductsTable
                        rows={addAssemblyRows}
                        setAddRows={setAddAssemblyRows}
                        setDirty={setDirty}
                        setFormChanged={setFormChanged}
                        changed={changed}
                        setChanged={setChanged}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Accordion key={"Add Assemblies"} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"Add Assemblies-content"}
                      id={"Add Assemblies-id"}
                      style={{ flexDirection: "row-reverse" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        style={{ paddingLeft: "40px" }}
                      >
                        Add extra switches not paired to any controller plan:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        marginTop: "-10px",
                        marginBottom: "-10px",
                        marginLeft: "-16px",
                        marginRight: "-16px",
                      }}
                    >
                      <AddProductsTable
                        rows={addSwitchRows}
                        setAddRows={setAddSwitchRows}
                        setDirty={setDirty}
                        setFormChanged={setFormChanged}
                        changed={changed}
                        setChanged={setChanged}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {/**If User chooses to change a switch */}
      {displayChangeSwitches === true ? (
        <div style={{ width: "100%", marginBottom: "20px", marginTop: "50px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h4" component="span">
                Change Switches
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ChangeProductsTable
                title={
                  "Change or upgrade switches from what was specified in the base plan:"
                }
                selectedFrom2={selectedFrom2}
                agreementDefaultSwitch={agreementDefaultSwitch}
                selectedTo2={selectedTo2}
                setSelectedFrom2={setSelectedFrom2}
                setSelectedTo2={setSelectedTo2}
                arrayFrom={arrayFrom}
                arrayTo={arrayTo}
                defaultSwitch={defaultSwitch}
                arrayFrom2={arrayFrom2}
                arrayTo2={arrayTo2}
                setFormChanged={setFormChanged}
                selectedFrom={selectedFrom}
                setDirty={setDirty}
                selectedTo={selectedTo}
                setSelectedFrom={setSelectedFrom}
                setSelectedTo={setSelectedTo}
                valueToAdd={valueToAdd}
                setValueToAdd={setValueToAdd}
                maxQuantity={maxQuantity}
                rows={rows}
                setRows={setRows}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}

      {/**If User chooses to override default switch color */}
      {displayChangeSwitchColor === true ? (
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <OverrideSwitchColorStep
                switchColors={switchColors}
                setDirty={setDirty}
                setFormChanged={setFormChanged}
                setSaveError={setSaveError}
                overDefSwitchColor={overDefSwitchColor}
                setOverDefSwitchColor={setOverDefSwitchColor}
                agreement={agreement}
                orderID={orderID}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default OrderProductsModification;
