import { Paper, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import RoleForm from "../../../components/RoleForm/RoleForm";
import { useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Save, X } from "react-feather";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";

const CreateRolePage = ({ setPageID, setPageLink }) => {
  const history = useHistory();
  const location = useLocation();

  /*Fetching roles from Redux*/
  const clientRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.clientRoles : ""
  );

  const ownRoles = useSelector((state) =>
    state.user.user ? state.user.user.user.role.ownRoles : ""
  );

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const companyID = location.state
    ? location.state.companyID
      ? location.state.companyID
      : 0
    : 0;

  const myRoles = location.state
    ? location.state.source
      ? location.state.source
      : false
    : false;

  /*Redirection*/
  if (
    (companyID === 0 && clientRoles !== "M") ||
    (companyID !== 0 && ownRoles !== "M")
  ) {
    history.push("/dashboard");
  }

  const [companyAdmin, setCompanyAdmin] = useState(false);
  const [adminManage, setAdminManage] = useState(false);
  const [tabButtonLoading, setTabButtonLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const handleItemClick = () => {

    if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
  
      setOpenDialog(true);
  
      setRouteToNavigate(myRoles === true ? "/my-roles" : "/roles");
  
    } else {
  
      history.push(myRoles === true ? "/my-roles" : "/roles");
    }
  };
  
  const handleAgree = () => {
  
    sessionStorage.removeItem('unsavedChanges');
    setOpenDialog(false); 
  
    history.push(routeToNavigate);
  };
  
  const compID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );

  /*Agreement Form Ref*/
  const roleRef = useRef(null);

  useEffect(() => {
    if ((clientRoles === "M" || ownRoles === "M") && companyType === "L") {
      setAdminManage(true);
    }
    if (ownRoles === "M" && companyType !== "L") {
      setCompanyAdmin(true);
    }
  }, []);

  useEffect(() => {
    //for drawer menu
    if (myRoles === true) {
      setPageID(2);
    } else {
      setPageID(8);
    }

    //for home button
    setPageLink("Roles");
  }, []);

  return (
    <>    <ConfirmationPopup
    openDialog={openDialog}
    handleOpenDialog={(v) => setOpenDialog(v)}
    handleAgree={handleAgree}
  />
    <Paper sx={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              underline="always"
              key="1"
              color="inherit"
              onClick={() => handleItemClick()}

              // onClick={() => history.push("/my-roles")}
              sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
            >
              My Roles
            </Link>
          
          <Typography key="5" color="text.primary" sx={{ fontSize: "24px" }}>
            New Role
          </Typography>
        </Breadcrumbs>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CustomButton
            startIcon={<Save />}
            toggle={() => roleRef.current.save()}
            loading={tabButtonLoading}
          >
            Save
          </CustomButton>
          <div style={{ width: "10px" }} />
          <CustomButton
            startIcon={<X />}
            toggle={async () => {
              await roleRef.current.setPristine();
              history.push(companyID === 0 ? "/Roles" : "/my-roles");
            }}
          >
            Cancel
          </CustomButton>
        </div>
      </div>
      <div style={{ height: "40px" }} />
      <RoleForm
        create={true}
        adminManage={adminManage}
        companyAdmin={companyAdmin}
        receivedCompanyID={companyID}
        setTabButtonLoading={setTabButtonLoading}
        setFormChanged={setFormChanged}
        ref={roleRef}
      />
    </Paper></>
  );
};

export default CreateRolePage;
