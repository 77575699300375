import React from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from "@mui/material";

const ConfirmationPopup = ({ openDialog, handleConfirm, handleClose, title, message, confirmLabel, cancelLabel }) => {

    return (
        <div className="userConfirmationDialog">
            <Dialog
                open={openDialog}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>{cancelLabel}</Button>
                    <Button onClick={handleConfirm}>{confirmLabel}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmationPopup;
