import * as React from "react";
import Typography from "@mui/material/Typography";
import CountryForm from "../../../components/CountryForm/CountryForm";
import { Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

export default function Cards({ setPageID, setPageLink }) {
  const location = useLocation();
  const history = useHistory();

  const create = location.state
    ? location.state.create
      ? location.state.create
      : false
    : false;

  const countryID = location.state
    ? location.state.countryID
      ? location.state.countryID
      : 0
    : 0;

  const role = useSelector((state) => state.user.user.user.jobTitle);

  /*Redirection*/
  if (role !== "Levven Admin") {
    history.push("/Dashboard");
  }

  React.useEffect(() => {
    //for drawer menu select
    setPageID(-1);

    //for help button
    setPageLink("SystemConfig");
  }, []);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          border: "1px rgba(86,87,89, 0.2) solid",
          borderRadius: "3px",
          margin: "20px",
          padding: "40px",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ borderBottom: "1px solid #969696", paddingBottom: "20px" }}
            >
              Country Information
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CountryForm create={create} countryID={countryID} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
