import { CircularProgress, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderServices } from "../../services/orders.service";
import AlertSnackBar from "../../components/AlertSnackBar/AlertSnackBar";
import { companyServices } from "../../services/company.services";

const OrderRedirectionPage = () => {
  const [order, setOrder] = useState();
  const location = useLocation();
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const receivedOrderID = params.get("id")
    ? params.get("id")
    : location.state
    ? location.state.email
    : "";

  const receivedtab = params.get("tab") ? params.get("tab") : -1;

  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const rolePermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.orders : ""
  );

  const submitOrderPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.submitOrder : false
  );

  const submitRMAPermission = useSelector((state) =>
    state.user.user ? state.user.user.user.role.submitRMA : false
  );

  /*Get current company ID from Redux*/
  const currentCompanyID = useSelector((state) =>
    state.user.user ? state.user.user.user.company.companyID : ""
  );

  /* Get values from redux store*/
  const jwtToken = useSelector((state) =>
    state.user.user ? state.user.user.jwtToken : ""
  );

  const userSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.systemGenerated : false
  );
  const [companies, setCompanies] = useState([]);
  const [purchaserCompanyIDExist, setPurchaserCompanyIDExist] = useState(false);

  // fetch parent child companies
  const fetchParentChildCompanies = () => {
    console.log(currentCompanyID);
    companyServices
      .getParentChildCompaniesByCompanyID(currentCompanyID)
      .then((response) => {
        let tempCompanies = [];
        response.data.forEach((element) => {
          tempCompanies.push(element.companyID);
        });
        setCompanies(tempCompanies);

        setPurchaserCompanyIDExist(
          companies.includes(order.purchaser.companyID)
        );
      })
      .catch((error) => {
        console.log(error.response.data.error.error);
      });
  };
  //to change title of page
  useEffect(() => {
    document.title = "Levven Electronics - Customer Portal - Order Redirect";
    fetchParentChildCompanies();
  }, []);

  const fetchOrderDataByID = async () => {
    await orderServices
      .getOrderByID({ OrderID: receivedOrderID, IsEncrypted: true })
      .then((response) => {
        setOrder(response.data.order);
      })
      .catch((error) => {
        history.push({
          pathname: "/dashboard",
          state: {
            redirectionError: error.response.data.error.error,
          },
        });
        // setError(error.response.data.error.error);
      });
  };

  const checkIfOrderCorrespondsToUser = async () => {
    if (
      rolePermission === "N" ||
      (order.isRMA === false && !submitOrderPermission) ||
      (order.isRMA === true && !submitRMAPermission)
    ) {
      console.log(
        "go to dashboard 1 rolePermission:",
        rolePermission,
        " order is rma : ",
        order.isRMA,
        " submission order Persimssion: ",
        submitOrderPermission
      );
      // history.push("/dashboard");
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: "You do not have permission to view this order.",
        },
      });
      return;
    } else if (order?.takeoff && order?.takeoff !== null) {
      if (order.takeoff.agreement && order.takeoff.agreement !== null) {
        if (order.takeoff.agreement.purchasing === currentCompanyType) {
          console.log(
            "companies",
            companies,
            " builder.companyID : ",
            order.takeoff.agreement.builder.companyID,
            " installer.companyID: ",
            order.takeoff.agreement.installer.companyID
          );
          if (
            companies.includes(order.takeoff.agreement.builder.companyID) ||
            companies.includes(order.takeoff.agreement.installer.companyID)
          ) {
            console.log("go to order info page 1 order:", order);
            history.push({
              pathname: "/order-information",
              state: {
                orderID: order.orderID,
                orderName: order.purchaser.company.name,
                orderStatus: order.status,
                purchaserCompanyID: order.purchaser.companyID,
                nextTab: receivedtab,
                orderFromRedirect: order,
              },
            });
          } else {
            console.log(
              "go to dashboard 2 companies:",
              companies,
              " builder.companyID : ",
              order.takeoff.agreement.builder.companyID,
              " installer.companyID: ",
              order.takeoff.agreement.installer.companyID
            );

            history.push({
              pathname: "/dashboard",
              state: {
                redirectionError:
                  "You do not have permission to view this order.",
              },
            });
            return;
          }
        } else if (currentCompanyType === "L") {
          console.log(
            "go to order info page 2 order:",
            order,
            " currentCompanyType ",
            currentCompanyType
          );

          history.push({
            pathname: "/order-information",
            state: {
              orderID: order.orderID,
              orderName: order.purchaser.company.name,
              orderStatus: order.status,
              purchaserCompanyID: order.purchaser.companyID,
              nextTab: receivedtab,
              orderFromRedirect: order,
            },
          });
        } else {
          console.log(
            "go to dashboard 3, currentCompanyType: ",
            currentCompanyType,
            " order.takeoff.agreement.purchasing ",
            order.takeoff.agreement.purchasing
          );
          history.push({
            pathname: "/dashboard",
            state: {
              redirectionError:
                "You do not have permission to view this order.",
            },
          });
          return;
        }
      } else {
        console.log(
          "go to dashboard 4, order?.takeoff: ",
          order?.takeoff,
          " order.takeoff.agreement.purchasing ",
          order.takeoff.agreement.purchasing
        );

        history.push({
          pathname: "/dashboard",
          state: {
            redirectionError: "You do not have permission to view this order.",
          },
        });
        return;
      }
    } else if (order.type === "S") {
      if (
        currentCompanyType === "L" ||
        // call api to get the chldren company and check if order.purchaser.companyID is in the list of children companies id
        companies.includes(order.purchaser.companyID)
        // order.purchaser.companyID === currentCompanyID
      ) {
        /**If belongs to this user */
        console.log(
          "go to order info page 3 order:",
          order,
          "companies ",
          companies,
          " order.purchaser.companyID: ",
          order.purchaser.companyID,
          " companies.includes(order.purchaser.companyID): ",
          companies.includes(order.purchaser.companyID)
        );

        history.push({
          pathname: "/order-information",
          state: {
            orderID: order.orderID,
            orderName: order.purchaser.company.name,
            orderStatus: order.status,
            purchaserCompanyID: order.purchaser.companyID,
            nextTab: receivedtab,
            orderFromRedirect: order,
          },
        });
      } else {
        console.log(
          "go to dashboard 5 currentCompanyType: ",
          currentCompanyType,
          "companies",
          companies,
          " order.purchaser.companyID: ",
          order.purchaser.companyID
        );
        /**If not, then no permission */
        history.push({
          pathname: "/dashboard",
          state: {
            redirectionError: "You do not have permission to view this order.",
          },
        });
        return;
      }
    } else {
      console.log(
        "go to dashboard 6 rolePermission: ",
        rolePermission,
        "companies",
        companies,
        " order.purchaser.companyID: ",
        order.purchaser.companyID
      );
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: "You do not have permission to view this order.",
        },
      });
      return;
    }
  };

  useEffect(() => {
    if (jwtToken === "") {
      history.push({
        pathname: "/login",
        state: { toOrderID: receivedOrderID, toOrderTab: receivedtab },
      });
    } else {
      if (
        receivedOrderID === undefined ||
        receivedOrderID === null ||
        receivedOrderID === ""
      ) {
        history.push({
          pathname: "/dashboard",
          state: {
            redirectionError: "Invalid Order.",
          },
        });
      } else {
        fetchOrderDataByID();
        if (order && order !== null) checkIfOrderCorrespondsToUser();
      }
    }
  }, [order]);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  return (
    <>
      {error !== "" ? (
        <AlertSnackBar
          message={error}
          handleClose={handleCloseAlertSnackbar}
          open={error !== ""}
        />
      ) : null}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="80vh"
      >
        <CircularProgress sx={{ color: "#0077c6" }} />
      </Grid>
    </>
  );
};

export default OrderRedirectionPage;
