import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { accountServices } from "../../../services/account.services";
import AlertDialogSlide from "../../../components/AlertDialogSlide/AlertDialogSlide";
import { userTypes } from "../../../redux/types/userTypes";
import { orderTypes } from "../../../redux/types/orderTypes";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Clock, Home, Plus, Truck } from "react-feather";
import AlertSnackBar from "../../../components/AlertSnackBar/AlertSnackBar";
import DashboardCard from "../../../components/DashboardCard/DashboardCard";
import DashboardComponent from "../../../components/DashboardCard/DashboardComponent";
import { useCookies } from "react-cookie";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { makeStyles } from "@mui/styles";
import { systemDataServices } from "../../../services/systemData.services";
import { resetAgreementURL } from "../../../redux/actions/agreementActions";

/* Styles */
const useStyles = makeStyles({
  buttonStyles: {
    display: "flex",
    justifyContent: "flex-end",

    "@media(max-width: 800px)": {
      display: "block",
    },
  },
});

const DashBoardPage = ({ setPageID, setPageLink, openDrawerMenu }) => {
  const location = useLocation();

  const classes = useStyles();

  const [deviceError, setDeviceError] = useState("");
  const [buttonLoadingAllow, setButtonLoadingAllow] = useState(false);
  const [buttonLoadingDontAllow, setButtonLoadingDontAllow] = useState(false);

  //To Check if Cookie Exists
  const [cookies, setCookie, removeCookie] = useCookies();

  //to display allow cookies prompt
  const [allowCookiesPrompt, setAllowCookiesPrompt] = useState(false);

  //to display save device prompt
  const [displaySaveDevice, setDisplaySaveDevice] = useState(false);

  //holds remember my choice check box for allow cookies
  const [rememberMyChoice, setRememberMyChoice] = useState(true);

  //holds cookie alert text
  const [cookieAlert, setCookieAlert] = useState("");

  const [errorInfo, setErrorInfo] = useState(
    location.state
      ? location.state.redirectionError
        ? location.state.redirectionError
        : ""
      : ""
  );

  const history = useHistory();

  /* Get values from redux store*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );
  /* Get values from redux store*/
  const deviceToken = useSelector((state) =>
    state.user.user ? state.user.user.deviceToken : ""
  );
  /* Get values from redux store*/
  const deviceInfo = useSelector((state) =>
    state.user.user ? state.user.user.deviceInfo : ""
  );

  const jwtToken = useSelector((state) =>
    state.user.user ? state.user.user.jwtToken : ""
  );

  const userEmail = useSelector((state) =>
    state.user.user ? state.user.user.user.userName : ""
  );

  const refreshToken = useSelector((state) =>
    state.user.user ? state.user.user.refreshToken : ""
  );

  const loggedIn = useSelector((state) =>
    state.user.loggedIn ? state.user.loggedIn : false
  );

  const toOrderID = useSelector((state) =>
    state.order.toOrderID ? state.order.toOrderID : 0
  );

  const toAgreementUrlPage = useSelector((state) =>
    state.agreement.ToAgreementUrlPage
      ? state.agreement.ToAgreementUrlPage
      : false
  );

  const agreementUrlPageID = useSelector((state) =>
    state.agreement.AgreementUrlPageID ? state.agreement.AgreementUrlPageID : ""
  );

  const toOrderTab = useSelector((state) =>
    state.order.toOrderTab ? state.order.toOrderTab : -1
  );

  /*Declare dispatch to dispatch redux actions*/
  const dispatch = useDispatch();

  const dispatchOnReload = async (dispatch) => {
    if (refreshToken !== "") accountServices.refreshTokenAsync(dispatch);
  };

  //check if we display allow cookies prompt
  useEffect(() => {
    if (!loggedIn) {
      if (cookies["Levven-Device-Token-" + userEmail]) {
        if (deviceToken !== null && deviceToken !== "") {
          removeCookie("Levven-Device-Token-" + userEmail, { path: "/" });
          setDisplaySaveDevice(true);
        } else return;
      } else {
        if (deviceToken !== null && deviceToken !== "")
          setDisplaySaveDevice(true);
      }
    }
  }, []);

  useEffect(() => {
    systemDataServices
      .getSystemDataVariables()
      .then((response) => {
        setCookieAlert(response.data.cookiesAlertText);
      })
      .catch((error) => {
        setDeviceError(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (deviceToken === "" || deviceToken === null) {
      if (!loggedIn) dispatchOnReload(dispatch);
    }
  }, [deviceToken]);

  useEffect(() => {
    if (loggedIn && (toOrderID !== 0 || toAgreementUrlPage === true)) {
      dispatch({
        type: orderTypes.RESET_TO_ORDER_ID,
        payload: { toOrderID: 0, toOrderTab: -1 },
      });
      dispatch(resetAgreementURL());

      if (toOrderID !== 0) {
        if (toOrderTab >= 0) {
          history.push(`/order-redirection?id=${toOrderID}&tab=${toOrderTab}`);
        } else {
          history.push(`/order-redirection?id=${toOrderID}`);
        }
      } else {
        history.push(`/agreement-paper?id=${encodeURI(agreementUrlPageID)}`);
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    //for drawer menu select
    setPageID(0);

    //for help text
    setPageLink("Dashboard");
  }, []);

  useEffect(() => {
    /**Scroll to top of the page */
    window.scrollTo(0, 0); //scroll to top of page
  }, []);

  /*Save a new device in the DB*/
  const saveNewDevice = (data) => {
    setButtonLoadingAllow(true);

    return (dispatch) => {
      accountServices
        .registerNewDevice(data)
        .then(async (response) => {
          const today = new Date();
          const threeyears = new Date();

          await threeyears.setFullYear(today.getFullYear() + 3);

          var cookieObject = {
            path: "/",
            expires: threeyears,
          };
          setCookie(
            "Levven-Device-Token-" + userEmail,
            deviceToken,
            cookieObject
          );
          setButtonLoadingAllow(false);
          setDisplaySaveDevice(false);
          if (response) {
            setDeviceError("");
            dispatch({
              type: userTypes.REGISTER_NEW_DEVICE,
              payload: { deviceInfo: deviceInfo, deviceToken: deviceToken },
            });
            if (!loggedIn) dispatchOnReload(dispatch);
          }
        })
        .catch((error) => {
          setButtonLoadingAllow(false);
          setDeviceError(error.response.data.message);
        });
    };
  };

  /*Decline saving the new device*/
  const unsaveNewDevice = () => {
    setDisplaySaveDevice(false);
    if (cookies["Levven-Device-Token-" + userEmail]) {
      removeCookie("Levven-Device-Token-" + userEmail, { path: "/" });
    }
    return (dispatch) => {
      dispatch({
        type: userTypes.REGISTER_NEW_DEVICE,
        payload: "",
      });
      if (!loggedIn) dispatchOnReload(dispatch);
    };
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorInfo("");
  };

  return (
    <>
      {jwtToken !== "" && displaySaveDevice ? (
        <AlertDialogSlide
          title="New Device"
          textContent={
            "You are logging from a new device, would you like to save it?\n\n(NB: This website uses cookies to save your device. By clicking Save you are directly accepting the use of cookies to save your device.)"
          }
          devicePopup
          save={() =>
            dispatch(
              saveNewDevice({
                Email: userEmail,
                DeviceInfo: deviceInfo,
                DeviceToken: deviceToken,
              })
            )
          }
          unsave={() => dispatch(unsaveNewDevice())}
          saveText="Save"
          unsaveText="Don't Save"
          error={deviceError}
          saveButtonLoading={buttonLoadingAllow}
        />
      ) : null}
      {errorInfo ? (
        <AlertSnackBar
          message={errorInfo}
          handleClose={handleCloseAlertSnackbar}
          open={errorInfo !== ""}
        />
      ) : null}
      <DashboardComponent />
    </>
  );
};

export default DashBoardPage;
