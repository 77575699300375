import { Alert } from "@mui/material";
import React from "react";

const CustomAlert = ({ children }) => {
  return (
    <Alert
      severity="error"
      style={{
        backgroundColor: "#f9c7c7",
        color: "#7c2932",
        borderRadius: "3px",
        width: "100%",
        textAlign: "left",
      }}
    >
      {children}
    </Alert>
  );
};

export default CustomAlert;
