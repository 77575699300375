import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useHistory } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import { Trash2, X } from "react-feather";
import { agreementServices } from "../../services/agreement.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import SnackBar from "../SnackBar/SnackBar";
import LogDetailsTable from "../LogDetailsTable/LogDetailsTable";
import { useSelector } from "react-redux";
import { logsService } from "../../services/logs.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { decryptObject } from "../../helpers/link-variables";
import { FrontEndErrors } from "../../helpers/error-messages";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  //for tabs

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LogDetailsTab({ setPageID, setPageLink }) {
  const history = useHistory();
  const location = useLocation();

  //we get data from url
  const params = new URLSearchParams(window.location.search);
  const dataObject = params.get("otbd");
  var decryptedObject = null;
  var fromURL = false;

  if (dataObject !== undefined && dataObject !== null) {
    fromURL = true;
    const returnedObject = decryptObject(dataObject);
    if (returnedObject.error === true)
      history.push({
        pathname: "/dashboard",
        state: {
          redirectionError: FrontEndErrors["frontend_error1"],
        },
      });
    else decryptedObject = returnedObject.returnObject;
  }

  useEffect(() => {
    //for help button
    setPageLink("Logs");

    //for side menu select
    setPageID(12);
  }, []);

  //state holding which tab we are in at the moment
  const [value, setValue] = useState(0);

  //make delete button in the tabs load on delete
  const [loading, setLoading] = useState(false);

  //holds the error if encountered while deleting record
  const [deleteError, setDeleteError] = useState("");

  //used to display the alert dialog before deleting
  const [deleteRecord, setDeleteRecord] = useState(false);

  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const roleType = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const roleSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.role.systemGenerated : ""
  );

  //holds permission for the page
  const permission =
    companyType === "L" &&
    roleType === "Levven Admin" &&
    roleSystemGenerated === true
      ? true
      : false;

  //get data from previous page
  const logID = fromURL
    ? decryptedObject
      ? decryptedObject.logID
        ? decryptedObject.logID
        : 0
      : 0
    : location.state
    ? location.state.logID
      ? location.state.logID
      : 0
    : 0;

  if (logID === 0) {
    history.push({
      pathname: "/dashboard",
      state: {
        redirectionError: FrontEndErrors["frontend_error1"],
      },
    });
  }

  //redirect
  if (!permission) {
    history.push("/Dashboard");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (newValue) => {
    //function to change tab
    setValue(newValue);
  };

  const deleteAgreement = () => {
    //function to delete agreement

    setLoading(true);
    setDeleteRecord(false);
    const data = { Logs: [logID] };
    logsService
      .deleteLogsList(data)
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/Logs",
          state: {
            success: true,
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setDeleteError(error.response.data.error.error);
      });
  };

  return (
    <>
      <Paper>
        {deleteRecord ? (
          <AlertDialogSlide
            title="Warning"
            textContent={
              "Are you sure you want to delete this record? Deleting these records will cause their details to be deleted."
            }
            unsave={deleteAgreement}
            unsaveText="Yes"
            deleteDialog={true}
            save={() => setDeleteRecord(false)}
            saveText="No"
          />
        ) : null}
        {deleteError ? (
          <AlertSnackBar
            message={deleteError}
            handleClose={(event, reason) => {
              if (reason !== "clickaway") setDeleteError("");
            }}
            open={deleteError !== ""}
          />
        ) : null}
        <Box
          sx={{
            width: "100%",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "auto",
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                underline="always"
                key="1"
                color="inherit"
                onClick={() => history.push("/Logs")}
                sx={{ fontSize: "24px", color: "#0077c6", cursor: "pointer" }}
              >
                Logs
              </Link>
              <Typography
                key="2"
                color="text.primary"
                sx={{ fontSize: "24px" }}
              >
                Log {logID}
              </Typography>
            </Breadcrumbs>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<Trash2 />}
                toggle={() => setDeleteRecord(true)}
                loading={loading}
              >
                Delete
              </CustomButton>

              <div style={{ width: "10px" }} />
              <CustomButton
                startIcon={<X />}
                toggle={() => {
                  history.push("/Logs");
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "40px" }}
          >
            <Tabs
              variant={"scrollable"}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Details" {...a11yProps(0)} style={{ margin: "50" }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <LogDetailsTable logID={logID} />
          </TabPanel>
        </Box>
      </Paper>
    </>
  );
}
