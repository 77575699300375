import React, { useState, useEffect } from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Paper,
} from "@mui/material";
import Underline from "../Underline/Underline";
import SnackBar from "../SnackBar/SnackBar";
import { useSelector } from "react-redux";
import { orderNotesServices } from "../../services/orderNotes.services";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { Save } from "react-feather";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const OrderNotesForm = ({
  create = false,
  setSaved,
  orderID,
  isRMA,
  setFormChanged,
  noteID,
  setValue,
  setCreatedNote,
  setWarning,
  setCreateOrderNote,
  saveOrderNote,
  setSaveOrderNote,
  setHighlightDate,
  setHighlightPO,
  dateInTabs,
  poInTabs,
}) => {
  /*Loading*/
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  /*SnackBar open handling*/
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [noteObj, setNoteObj] = useState();
  const [note, setNote] = useState();
  const [noteError, setnoteError] = useState(false);
  const [saveError, setSaveError] = useState("");

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  /*Get data from Redux*/
  const currentUserID = useSelector((state) =>
    state.user.user ? state.user.user.user.userID : ""
  );

  const rolePermission = useSelector((state) =>
    state.user.user
      ? isRMA === false
        ? state.user.user.user.role.orders
        : state.user.user.user.role.rma
      : ""
  );

  /*Initial useEffect*/
  useEffect(() => {
    //if not in create mode, we call function that gets the order note for a certain ID
    if (!create) {
      GetOnUpdateOrderNotes();
    }
  }, []);

  //function that gets Order Note by ID
  const GetOnUpdateOrderNotes = () => {
    setLoading(true);
    orderNotesServices
      .getOrderNoteById(noteID)
      .then((response) => {
        setNote(response.data.note);
        setNoteObj(response.data);
        setSaveError("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSaveError(error.response.data.error.error);
      });
  };

  /*Check errors before on save*/
  const errorHandling = () => {
    //function for error handling
    var error = false;
    if (note === "") {
      setnoteError(true);
      error = true;
    }
    return error;
  };

  //function to create or update order note
  const createNote = (e) => {
    if (e !== null) e.preventDefault();
    var error = errorHandling();
    if (error === false) {
      setButtonLoading(true);
      const data = {
        OrderID: orderID,
        Note: note,
        CreatedByID: currentUserID,
      };
      if (create) {
        orderNotesServices
          .createOrderNote(data)
          .then((response) => {
            setFormChanged(false);
            sessionStorage.removeItem("unsavedChanges");

            setPristine();
            setButtonLoading(false);
            setSaved(true);
            setCreatedNote(false);
            setOpenSnackBar(true);

            if (saveOrderNote === true) {
              setSaveOrderNote(false);
              setWarning(false);
              if (
                poInTabs === null ||
                poInTabs === "" ||
                poInTabs === undefined
              ) {
                setHighlightPO(true);
              }

              if (
                dateInTabs === null ||
                dateInTabs === "" ||
                dateInTabs === undefined
              ) {
                setHighlightDate(true);
              }

              setValue(0);
            }
          })
          .catch((error) => {
            setSaveError(error.response.data.error.error);
            setButtonLoading(false);
          });
      } else {
        var data2 = {
          NoteID: noteID,
          OrderID: orderID,
          Note: note,
          ModifiedByID: currentUserID,
        };
        orderNotesServices
          .updateOrderNote(data2)
          .then((response) => {
            setPristine();
            setButtonLoading(false);
            setOpenSnackBar(true);
            if (saveOrderNote === true) {
              setSaveOrderNote(false);
              setWarning(false);
              if (
                poInTabs === null ||
                poInTabs === "" ||
                poInTabs === undefined
              ) {
                setHighlightPO(true);
              }

              if (
                dateInTabs === null ||
                dateInTabs === "" ||
                dateInTabs === undefined
              ) {
                setHighlightDate(true);
              }
              setValue(0);
            } else {
              GetOnUpdateOrderNotes();
            }
          })
          .catch((error) => {
            setButtonLoading(false);
            setSaveError(error.response.data.error.error);
          });
      }
    }
  };

  useEffect(() => {
    if (saveOrderNote === true) createNote(null);
  }, [saveOrderNote]);

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      if (d === "Invalid Date") {
        return "";
      }
      return (
        d.substring(8, 10) +
        "/" +
        d.substring(4, 7) +
        "/" +
        d.substring(13, 15) +
        " at " +
        d.substring(17, 21)
      );
    }
  };

  return (
    <>
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={() => setOpenSnackBar(false)}
        />
      ) : null}
      {/*Main Grid*/}
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="80vh"
        >
          <CircularProgress sx={{ color: "#0077c6" }} />
        </Grid>
      ) : (
        <>
          {/* {Prompt} */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={9}
              xl={9}
              sx={{
                borderRight: "2px solid #c4c4c4",
                paddingRight: "20px",
                alignSelf: "flex-start",
              }}
            >
              {/*Form Grid*/}
              <form onSubmit={createNote} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Underline />
                  </Grid>

                  {/*Note input*/}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Note"
                      fullWidth
                      required
                      rows={5}
                      multiline
                      value={note}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      error={noteError}
                      helperText={noteError ? "Note is empty" : ""}
                      onChange={(e) => {
                        if (
                          rolePermission === "M" &&
                          (create === true ||
                            (create === false &&
                              noteObj &&
                              noteObj.isTwentyAmps === false))
                        ) {
                          if (create) {
                            setFormChanged(true);
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            setDirty();
                          }
                          setNote(e.target.value);
                          if (noteError) setnoteError(false);
                        }
                      }}
                      disabled={
                        rolePermission === "R" ||
                        (create === false &&
                          noteObj &&
                          noteObj.isTwentyAmps === true)
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  {/*Save button*/}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <CustomButton
                        type="submit"
                        loading={buttonLoading}
                        startIcon={<Save />}
                      >
                        Save
                      </CustomButton>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                </Grid>
              </form>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              xl={3}
              sx={{
                paddingLeft: "20px",
                alignSelf: "flex-start",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  border: "1px solid #c4c4c4",
                  padding: "20px",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Company Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="caption" component="span">
                      {noteObj && noteObj !== null
                        ? noteObj.createdBy.company.name
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Creation date:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="caption" component="span">
                      {noteObj && noteObj !== null
                        ? convertUTC(noteObj.creationDate)
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Created by:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="caption" component="span">
                      {noteObj && noteObj !== null
                        ? noteObj.createdBy.firstName +
                          " " +
                          noteObj.createdBy.lastName
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Modification date:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="caption" component="span">
                      {noteObj && noteObj !== null
                        ? convertUTC(noteObj.modificationDate)
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      component="span"
                    >
                      Modified by:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="caption" component="span">
                      {noteObj && noteObj != null
                        ? (noteObj.modifiedBy
                            ? noteObj.modifiedBy.firstName
                            : "") +
                          " " +
                          (noteObj.modifiedBy
                            ? noteObj.modifiedBy.lastName
                            : "")
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      {saveError ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
    </>
  );
};

export default OrderNotesForm;
