import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import SnackBar from "../SnackBar/SnackBar";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Filter, Trash2, Plus, Check, RotateCw } from "react-feather";
import CustomButton from "../CustomButton/CustomButton";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { companyServices } from "../../services/company.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import { agreementServices } from "../../services/agreement.services";
import { logsService } from "../../services/logs.services";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import ContextMenu from "../ContextMenu/ContextMenu";
import { encryptObject } from "../../helpers/link-variables";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";

//holds columns for table header
const headCells = [
  {
    id: "LogID",
    numberic: true,
    disablePadding: false,
    label: "Log ID",
    center: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    center: false,
  },
  {
    id: "LogDate",
    numeric: false,
    disablePadding: false,
    center: false,
    label: "Date",
  },
  {
    id: "LogEndDate",
    numeric: false,
    disablePadding: false,
    center: false,
    label: "End Date",
  },
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    center: true,
    label: "Details",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rolePermission,
  } = props;
  const createSortHandler = (property) => {
    //function that calls the sorting function
    onRequestSort(property);
  };

  return (
    <TableHead style={{ backgroundColor: "#0077c6" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={{ backgroundColor: "#0077c6" }}>
          {rolePermission === true && rowCount > 0 ? (
            <Checkbox
              checkedIcon={
                numSelected === rowCount ? (
                  <CheckBoxIcon sx={{ color: "#badaf1" }} />
                ) : (
                  <IndeterminateCheckBoxIcon sx={{ color: "#005085" }} />
                )
              }
              checked={rowCount > 0 && numSelected > 0}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric ? "right" : headCell.center ? "center" : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ color: "white", backgroundColor: "#0077c6" }}
          >
            {headCell.id !== "details" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function LogsTable({ setPageLink, setPageID }) {
  const history = useHistory();

  //getting data from redux
  const companyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  const roleType = useSelector((state) =>
    state.user.user ? state.user.user.user.role.description : ""
  );

  const roleSystemGenerated = useSelector((state) =>
    state.user.user ? state.user.user.user.role.systemGenerated : ""
  );

  //holds permission for the page
  const permission =
    companyType === "L" &&
    roleType === "Levven Admin" &&
    roleSystemGenerated === true
      ? true
      : false;

  //redirect
  if (!permission) {
    history.push("/Dashboard");
  }

  /*Convert UTC to local time*/
  const convertUTC = (utcDate) => {
    var date = new Date(utcDate + "Z");
    var originDate = new Date("January 1, 2000 00:00:00");
    if (date < originDate) {
      return "";
    } else {
      const d = date.toString();
      const m = date.toLocaleString("default", { month: "long" });
      if (d === "Invalid Date") {
        return "";
      }
      return (
        m +
        " " +
        d.substring(8, 10) +
        ", " +
        d.substring(11, 15) +
        " at " +
        d.substring(16, 21)
      );
    }
  };

  useEffect(() => {
    //for drawer menu
    setPageID(12);
    //for help button
    setPageLink("Logs");
  }, []);

  //states for ordering
  const [orderColumn, setOrderColumn] = React.useState("LogDate");
  const [orderDirection, setOrderDirection] = React.useState("desc");

  //state to save selected values in
  const [selected, setSelected] = React.useState([]);

  //state for the page in the table
  const [page, setPage] = React.useState(0);

  //state to save how many rows in the table to have per page
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  //state that holds all values to be displayed in the table
  const [rows, setRows] = React.useState([]);

  //state to display the filters
  const [applyFilters, setApplyFilters] = React.useState(false);

  //for filters
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);

  //state holding the errors from the database when retreiving data
  const [error, setError] = React.useState("");

  //value holding how many selected values there are in the table
  var numSelected = selected.length;

  //state holding total number of records in the database that can be displayed in the table
  const [numberRecords, setNumberRecords] = React.useState(0);

  //state to make button load on submission
  const [loading, setLoading] = React.useState(false);

  //state used to refetch values from the database in case of update or filter submission
  const [resubmit, setResubmit] = React.useState(false);

  //state to make page load while we fetch the data
  const [pageLoad, setPageLoad] = React.useState(false);

  //state that holds the error to be display in the dialog box when deleting record
  const [deleteError, setDeleteError] = React.useState("");

  const [dateError, setDateError] = React.useState("");

  //state for success in deleting values
  const [success, setSuccess] = React.useState(false);

  //state to display "Are you sure you want to delete these values" dialog box
  const [deleteRecords, setDeleteRecords] = React.useState(false);

  /**For context menu */
  const [contextMenu, setContextMenu] = React.useState(null);

  /**Holds table row that has been right clicked */
  const [contextMenuData, setContextMenuData] = React.useState(null);

  //function to check if the date format is correct
  const handleDateSubmit = async (date) => {
    const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g;

    if (!regex.test(date)) {
      return "Invalid Date Format";
    }

    const searchDate = moment(date).isValid()
      ? moment(date).format("MM/DD/YYYY")
      : "Invalid Date Format";

    return searchDate;
  };

  const getLogData = async () => {
    //function to get log rows
    setPageLoad(true);

    //checking if date filters entered are valid
    var handleDateErrorDateTo = "";
    var handleDateErrorDateFrom = "";

    if (dateTo !== null) handleDateErrorDateTo = await handleDateSubmit(dateTo);
    if (dateFrom !== null)
      handleDateErrorDateFrom = await handleDateSubmit(dateFrom);

    if (
      handleDateErrorDateTo === "Invalid Date Format" ||
      handleDateErrorDateFrom === "Invalid Date Format"
    ) {
      if (handleDateErrorDateTo === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date To. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      if (handleDateErrorDateFrom === "Invalid Date Format") {
        await setDateError(
          "Wrong date format for the field Creation Date From. Please make sure you are entering the date as MM/DD/YYYY."
        );
      }

      await setPageLoad(false);
    } else {
      var data = {
        Keys: { Page: page + 1, PageSize: rowsPerPage, KeyWord: "" },
        Filters: {
          LogDateFrom:
            dateFrom === "" || dateFrom === null
              ? null
              : moment(new Date(dateFrom)).format("MM/DD/YYYY"),
          LogDateTo:
            dateTo === "" || dateTo === null
              ? null
              : moment(new Date(dateFrom)).format("MM/DD/YYYY"),
        },
        orderDirection: orderDirection,
        orderColumn: orderColumn,
      };

      await logsService
        .getLogsList(data)
        .then((response) => {
          setNumberRecords(response.data.totalNumberOfRecords);
          setRows(response.data.logs);
          setPageLoad(false);
        })
        .catch((error) => {
          setError(error.response.data.error.error);
          setPageLoad(false);
        });
    }
  };

  useEffect(() => {
    //we get the data for the rows

    setRows([]);
    window.scrollTo(0, 0); //scroll to top of page

    setError("");
    setSelected([]);

    getLogData();
  }, [page, rowsPerPage, resubmit]);

  const handleRequestSort = (property) => {
    //for sorting
    const isAsc = orderColumn === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderColumn(property);
    setResubmit(!resubmit);
  };

  const handleSelectAllClick = (event) => {
    //select all rows from table
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.log.logID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const viewLogDetails = (id) => {
    //we are taken to a page to view agreement data
    history.push({
      pathname: "/log-details",
      state: {
        logID: id,
      },
    });
  };

  const deleteValues = () => {
    //delete agreements
    setLoading(true);
    setDeleteRecords(false);
    const data = { Logs: selected };
    logsService
      .deleteLogsList(data)
      .then(() => {
        setLoading(false);
        setResubmit(!resubmit);
        setSelected([]);
        setSuccess(true);
      })
      .catch((error) => {
        setDeleteError(error.response.data.error.error);
        setLoading(false);
        setResubmit(!resubmit);
      });
  };

  //Function to select row on click of anywhere on the row
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const clearFilterData = () => {
    //function to clear current filter data
    setDateFrom(null);
    setDateTo(null);
    setResubmit(!resubmit);
  };

  const handleChangePage = (event, newPage) => {
    //function to change page
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //function to change rows per page
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //check if specific record is selected
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const submitForm = (event) => {
    //function to submit filter data
    event.preventDefault();
    setPage(0);
    setResubmit(!resubmit);
  };

  /**Function to open context menu */
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  /**Function to close context menu */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const openInNewTab = async () => {
    const objectToBeSent = {
      logID: contextMenuData.log.logID,
    };

    const encryptedDate = await encryptObject(objectToBeSent);

    window.open(
      "/log-details?otbd=" + encodeURIComponent(encryptedDate),
      "_blank"
    );

    setContextMenu(null);
  };

  return (
    <>
      <SnackBar
        open={success}
        message="Log(s) Deleted Successfully"
        severity="success"
        handleClose={() => setSuccess(false)}
      />
      {dateError ? (
        <AlertSnackBar
          message={dateError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDateError("");
          }}
          open={dateError !== ""}
        />
      ) : (
        ""
      )}
      {deleteRecords ? (
        <AlertDialogSlide
          title="Warning"
          textContent={"Are you sure you want to delete these records?"}
          save={deleteValues}
          deleteDialog={true}
          saveText="Yes"
          unsave={() => setDeleteRecords(false)}
          unsaveText="No"
        />
      ) : null}
      {deleteError ? (
        <AlertSnackBar
          message={deleteError}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setDeleteError("");
          }}
          open={deleteError !== ""}
        />
      ) : null}
      {error ? (
        <AlertSnackBar
          message={error}
          handleClose={(event, reason) => {
            if (reason !== "clickaway") setError("");
          }}
          open={error !== ""}
        />
      ) : null}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              overflow: "auto",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(numSelected > 0 && {
                bgcolor: "#d4e8f6",
              }),
            }}
          >
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="#303030"
                variant="h6"
                component="div"
              >
                {numSelected} row(s) selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Logs
              </Typography>
            )}

            <CustomButton
              startIcon={<RotateCw />}
              size="medium"
              tableButton={true}
              toggle={() => setResubmit((resubmit) => !resubmit)}
            >
              Refresh
            </CustomButton>
            {numSelected > 0 ? (
              <>
                {permission === true ? (
                  <>
                    <CustomButton
                      startIcon={<Filter />}
                      size="medium"
                      tableButton={true}
                      toggle={() => setApplyFilters(!applyFilters)}
                    >
                      {applyFilters ? "Hide Filters" : "Show Filters"}
                    </CustomButton>
                    <CustomButton
                      startIcon={<Trash2 />}
                      size="medium"
                      tableButton={true}
                      loading={loading}
                      toggle={() => setDeleteRecords(true)}
                    >
                      Delete
                    </CustomButton>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <CustomButton
                  startIcon={<Filter />}
                  size="medium"
                  tableButton={true}
                  toggle={() => setApplyFilters(!applyFilters)}
                >
                  {applyFilters ? "Hide Filters" : "Show Filters"}
                </CustomButton>
              </>
            )}
          </Toolbar>

          {/*Filter*/}
          {applyFilters ? (
            <div style={{ backgroundColor: "#EFEFF0", paddingTop: "10px" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, marginLeft: "10px" }}
              >
                Filter Options
              </Typography>
              <br />
              <form onSubmit={submitForm}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={8} md={5} lg={3}>
                      <CustomDatePickerComponent
                        label="Date From"
                        value={dateFrom}
                        setValue={setDateFrom}
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={3}>
                      <CustomDatePickerComponent
                        label="Date To"
                        value={dateTo}
                        setValue={setDateTo}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    backgroundColor: "#EFEFF0",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    clearButton={true}
                    size="medium"
                    type={"submit"}
                  >
                    Apply Filters
                  </CustomButton>
                  <CustomButton
                    filterButton={true}
                    size="medium"
                    toggle={clearFilterData}
                  >
                    Clear Filters
                  </CustomButton>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}

          <TableContainer
            className={
              applyFilters ? "table-container-filters" : "table-container"
            }
          >
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={orderDirection}
                orderBy={orderColumn}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={numberRecords}
                rolePermission={permission}
              />
              <TableBody
                onContextMenu={
                  pageLoad === false && numberRecords > 0
                    ? handleContextMenu
                    : () => {
                        return null;
                      }
                }
              >
                {pageLoad === true ? (
                  <TableRow>
                    <TableCell colSpan="7" style={{ textAlign: "center" }}>
                      <CircularProgress sx={{ color: "#0077c6" }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {numberRecords === 0 && pageLoad === false ? (
                  <TableRow>
                    <TableCell
                      colSpan="7"
                      style={{ color: "grey", textAlign: "center" }}
                    >
                      {"It seems there are no Logs to display. "}
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row, index) => {
                    const isItemSelected = isSelected(row.log.logID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        tabIndex={-1}
                        key={row.log.logID}
                      >
                        <TableCell
                          padding="checkbox"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                        >
                          {permission === true ? (
                            <Checkbox
                              onClick={(event) =>
                                handleClick(event, row.log.logID)
                              }
                              checkedIcon={
                                <CheckBoxIcon sx={{ color: "#badaf1" }} />
                              }
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() => {
                            if (row.hasDetails) {
                              viewLogDetails(row.log.logID);
                            }
                          }}
                        >
                          {row.log.logID}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() => {
                            if (row.hasDetails) {
                              viewLogDetails(row.log.logID);
                            }
                          }}
                        >
                          {row.log.status}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() => {
                            if (row.hasDetails) {
                              viewLogDetails(row.log.logID);
                            }
                          }}
                        >
                          {convertUTC(row.log.logDate)}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          onClick={() => {
                            if (row.hasDetails) {
                              viewLogDetails(row.log.logID);
                            }
                          }}
                        >
                          {convertUTC(row.log.logEndDate)}
                        </TableCell>
                        <TableCell
                          onContextMenu={() => {
                            setContextMenuData(row);
                          }}
                          align="center"
                          onClick={() => {
                            if (row.hasDetails) {
                              viewLogDetails(row.log.logID);
                            }
                          }}
                        >
                          {row.hasDetails ? <Check color="#1D944B" /> : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                <>
                  <ContextMenu
                    contextMenu={contextMenu}
                    handleClose={handleCloseContextMenu}
                    openInNewTab={openInNewTab}
                    logsTable={true}
                    hasDetails={
                      contextMenuData ? contextMenuData.hasDetails : false
                    }
                  />
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
