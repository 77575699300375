import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

/* This components wraps the Material UI Button component and customizes it */
const CustomButton = ({
  children,
  toggle,
  startIcon,
  fullWidth,
  loading = false,
  size = "large",
  disable = false,
  tableButton = false,
  filterButton = false,
  type,
  clearButton = false,
  stepper = false,
  resendButton = false,
  deleteDialog = false,
  focused = false,
  tabIndex,
  sx,
  extraMarginTop = 0,
  extraMarginBottom = 0,
  extraMarginRight = 0,
  extraMarginLeft = 0,
  editTableColumnsButton = false,
}) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    return () => {
      setWindowWidth(0);
    };
  }, []);

  window.addEventListener(
    "resize",
    function (event) {
      setWindowWidth(window.innerWidth);
    },
    true
  );

  return (
    <LoadingButton
      tabIndex={tabIndex}
      variant="contained"
      size={size}
      sx={{
        ...sx,
        height:
          sx &&
          sx.height &&
          (windowWidth < 700 || editTableColumnsButton === true)
            ? sx.height
            : sx &&
              sx.height === "fit" &&
              (windowWidth < 700 || editTableColumnsButton === true)
            ? ""
            : "40px",
        whiteSpace:
          sx &&
          sx.whiteSpace &&
          (windowWidth < 700 || editTableColumnsButton === true)
            ? sx.whiteSpace
            : "nowrap",
        backgroundColor: !focused ? "#0077c6" : "#1D944B",
        borderRadius: "3px",
        textTransform: "none",
        "&:hover": { backgroundColor: "#1D944B" },

        marginRight:
          extraMarginRight && windowWidth < 700
            ? extraMarginRight
            : tableButton
            ? "10px"
            : filterButton
            ? clearButton
              ? ""
              : "30px"
            : "",
        marginLeft:
          extraMarginLeft && windowWidth < 700
            ? extraMarginLeft
            : filterButton || clearButton || stepper
            ? "10px"
            : "",
        marginTop:
          extraMarginTop && windowWidth < 700
            ? extraMarginTop
            : filterButton || clearButton
            ? "20px"
            : "",
        marginBottom:
          extraMarginBottom && windowWidth < 700
            ? extraMarginBottom
            : filterButton || clearButton
            ? "20px"
            : "",
        paddingRight: tableButton ? "40px" : resendButton ? "50px" : "",
        paddingLeft: tableButton ? "40px" : resendButton ? "50px" : "",
        minWidth: startIcon ? "120px" : null,
      }}
      fullWidth={fullWidth}
      disableElevation
      startIcon={startIcon}
      onClick={toggle}
      loading={loading}
      disabled={disable}
      type={type}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomButton;
