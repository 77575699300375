import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Edit, PlusCircle } from "react-feather";
import { shippingaddressServices } from "../../services/shippingaddress.services";
import AlertDialogSlide from "../AlertDialogSlide/AlertDialogSlide";
import CustomModal from "../CustomModal/CustomModal";
import ShippingAddressForm from "../ShippingAddressForm/ShippingAddressForm";
import Underline from "../Underline/Underline";
import moment from "moment";
import { CustomDatePickerComponent } from "../CustomDatePickerComponent/CustomDatePickerComponent";

const PurchasingDetailStep = ({
  setDirty,
  setSaveError,
  purchaseOrderNb,
  setPurchaseOrderNb,
  purchaseOrderNbError,
  shippingAddressIDs,
  setPurchaseOrderNbError,
  requestedDate,
  setRequestedDate,
  requestedDateError,
  minimumRequestedDeliveryDate = null,
  setRequestedDateError,
  shipToAddress,
  setShipToAddress,
  shipToAddressError,
  setShipToAddressError,
  shippingMethod,
  setShippingMethod,
  shippingMethodError,
  setShippingMethodError,
  company,
  toUpdateShipAddID,
  setShipModalUpdateOpen,
  setShipModalOpen,
  setToUpdateShipAddID,
  shipModalOpen,
  shipModalUpdatelOpen,
  shipWithOtherGoods,
  setShipWithOtherGoods,
  orderType,
  orderID,
  canCreateCompPP,
  isCompPP,
  setShippingAddressIDs,
  setIsCompPP,
  openDrawerMenu,
  windowWidth,
  setForModelHome,
  forModelHome,
  setSpecialNegotiated,
  specialNegotiated,
  orderFulfillmentThreshold,
  isRMA = false,
}) => {
  const [shippingMethods, setShippingMethods] = useState([
    "Prepaid and charge",
    "Prepaid",
    "Local pickup",
  ]);

  /*Get current company type from redux*/
  const currentCompanyType = useSelector((state) =>
    state.user.user ? state.user.user.user.company.type : ""
  );

  /*for shipping address*/
  const rolePermissionShippingAddress = useSelector((state) =>
    state.user.user
      ? currentCompanyType !== "L"
        ? state.user.user.user.role.ownCompany
        : state.user.user.user.role.clientCompany
      : ""
  );

  //retrieve data from redux
  const rolePermission = useSelector((state) =>
    state.user.user
      ? isRMA
        ? state.user.user.user.role.orders
        : state.user.user.user.role.rma
      : ""
  );

  const [openDate, setOpenDate] = useState(false);

  const [minimumDeliveryDate, setMinimumDeliveryDate] = useState(null);

  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [formChanged, setFormChanged] = useState(false);

  const [shipToAddressFlag, setShipToAddressFlag] = useState(false);

  /*Fetch all company shipping addresses*/
  const fetchShippingAddresses = () => {
    shippingaddressServices
      .getAllCompanyShippingAddressesByCompanyID({
        OrderID: orderID,
        CompanyID: company?.companyID,
      })
      .then((response) => {
        setShippingAddresses(response.data);
      })
      .catch((error) => setSaveError(error.response.data.error.error));
  };

  /*Initial useEffect*/
  useEffect(() => {
    window.scrollTo(0, 0); //scroll to top of page
    if (company && company !== null) fetchShippingAddresses();
  }, [company]);

  useEffect(() => {
    /*We make the minimum date pickable 14 days from today*/
    if (minimumRequestedDeliveryDate !== null) {
      const RequestedDeliveryDateMin = new Date(
        Date.now() + minimumRequestedDeliveryDate
      );
      setMinimumDeliveryDate(RequestedDeliveryDateMin);
    }
  }, [minimumRequestedDeliveryDate]);

  return (
    <div style={{ marginTop: "50px", width: "100%" }}>
      {shipToAddressFlag ? (
        <AlertDialogSlide
          title="Warning"
          textContent={
            "No shipping addresses have been entered yet. Would you like to add one?"
          }
          unsave={() => setShipToAddressFlag(false)}
          unsaveText="No"
          saveText="Yes"
          save={() => setShipModalOpen(true)}
        />
      ) : (
        ""
      )}
      <Grid container justifyContent="center" alignItems="center" spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4" component="span">
            {isRMA === false ? "Purchasing Details" : "RMA Details"}
          </Typography>
          <br />
          <Typography variant="h5" component="span">
            {isRMA === false
              ? "Please specify your purchasing details:"
              : "Please specify your RMA details:"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography variant="h6" component="span">
            Purchasing Information
          </Typography>
          <Underline />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <TextField
            variant="outlined"
            size="small"
            label={
              isRMA === false
                ? "Purchase Order Number"
                : "Service Case/Ticket Number"
            }
            fullWidth
            value={purchaseOrderNb}
            error={purchaseOrderNbError}
            helperText={
              purchaseOrderNbError
                ? `${
                    isRMA === false
                      ? "Purchase Order Number"
                      : "Service Case/Ticket Number"
                  } field is empty`
                : isRMA === false
                ? `A PO number is required before this order can be sent to Levven. You can save this order as a draft and enter a PO number later.`
                : `A Service Case/Ticket Number is required before this RMA can be sent to Levven. You can save this RMA as a draft and enter a Service Case/Ticket Number later.`
            }
            onChange={(e) => {
              setPurchaseOrderNb(e.target.value);
              if (purchaseOrderNbError) setPurchaseOrderNbError(false);
              setDirty();
            }}
            inputProps={{
              autoComplete: "new-password",
              maxLength: 35,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        {/* Requested Date */}
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <CustomDatePickerComponent
            label={
              isRMA === false ? "Requested Delivery Date" : "Delivery Date"
            }
            value={requestedDate}
            setValue={setRequestedDate}
            inOrder={true}
            inCreateOrder={true}
            helperText={
              requestedDateError
                ? `${
                    isRMA === false ? "Requested" : ""
                  } Delivery Date field is empty`
                : "The earliest allowed " +
                  (isRMA === false ? "Request" : "Delivery") +
                  " Date is " +
                  orderFulfillmentThreshold +
                  " day" +
                  (orderFulfillmentThreshold === 1 ? "" : "s") +
                  " from today."
            }
            defaultCalendarMonth={minimumDeliveryDate}
            minDate={minimumDeliveryDate}
            setDirty={setDirty}
            setFormChanged={setFormChanged}
            error={requestedDateError}
            setError={setRequestedDateError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" component="span">
              Shipping Address
            </Typography>
            {rolePermissionShippingAddress === "M" ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Tooltip arrow title="Edit Selected Shipping Address">
                  <IconButton disabled={toUpdateShipAddID === 0}>
                    <Edit
                      onClick={() => {
                        setShipModalUpdateOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Add a New Shipping Address">
                  <IconButton>
                    <PlusCircle
                      onClick={() => setShipModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <Underline />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        {/* Ship to Address */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip
            arrow
            title={
              shipToAddress && shipToAddress !== null
                ? (shipToAddress.name !== null
                    ? shipToAddress.name + " - "
                    : " ") +
                  shipToAddress?.streetAddress +
                  (shipToAddress?.secondAddress &&
                  shipToAddress?.secondAddress !== null
                    ? ", " + shipToAddress?.secondAddress
                    : "") +
                  ", " +
                  shipToAddress?.city +
                  ", " +
                  shipToAddress?.stateProvince?.description +
                  ", " +
                  shipToAddress?.country?.description +
                  ", " +
                  shipToAddress?.zipPostCode
                : ""
            }
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "50vw",
                  lineHeight: "15px",
                },
              },
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              componentName="Field1"
              options={
                shippingAddresses.length !== 0
                  ? rolePermissionShippingAddress === "M"
                    ? [
                        ...shippingAddresses,
                        {
                          added: true,
                          description: "Add New Shipping Address",
                          display: true,
                        },
                      ]
                    : [...shippingAddresses]
                  : rolePermissionShippingAddress === "M"
                  ? [
                      {
                        added: true,
                        description: "Add New Shipping Address",
                        display: true,
                      },
                    ]
                  : [...shippingAddresses]
              }
              getOptionLabel={(option) =>
                option
                  ? !option.added
                    ? (option.name !== null ? option.name + " - " : " ") +
                      option?.streetAddress +
                      (option?.secondAddress && option?.secondAddress !== null
                        ? ", " + option?.secondAddress
                        : "") +
                      ", " +
                      option?.city +
                      ", " +
                      option?.stateProvince?.description +
                      ", " +
                      option?.country?.description +
                      ", " +
                      option?.zipPostCode
                    : option.display
                    ? option.description
                    : ""
                  : ""
              }
              value={shipToAddress ? shipToAddress : ""}
              onChange={(event, value) => {
                if (value) {
                  if (value.added) {
                    if (value.description === "No Options")
                      setShipToAddressFlag(true);
                    else setShipModalOpen(true);
                  } else {
                    setShipToAddress(value);
                    if (value && value !== null)
                      setToUpdateShipAddID(value.shippingID);
                    else setToUpdateShipAddID(0);
                    if (shipToAddressError) setShipToAddressError(false);
                  }
                } else {
                  setShipToAddress(value);
                  if (value && value !== null)
                    setToUpdateShipAddID(value.shippingID);
                  else setToUpdateShipAddID(0);
                  if (shipToAddressError) setShipToAddressError(false);
                }
                setDirty();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Field1"
                  label="Ship to Address"
                  fullWidth
                  size="small"
                  onKeyDown={(e) => {
                    if (e.code !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                  error={shipToAddressError}
                  helperText={
                    shipToAddressError ? "Ship to Address field is empty" : ""
                  }
                  type="text"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Tooltip>
        </Grid>
        {/* Shipping Method */}
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Autocomplete
            id="combo-box-demo"
            componentName="Field2"
            options={shippingMethods}
            value={shippingMethod ? shippingMethod : ""}
            onChange={(event, value) => {
              setShippingMethod(value);
              if (value === "Local pickup") setShipWithOtherGoods(false);
              if (shippingMethodError) setShippingMethodError(false);
              setDirty();
            }}
            disabled={
              company === null ||
              company === undefined ||
              company.shippingMethod !== "L"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="Field2"
                label="Shipping Method"
                fullWidth
                size="small"
                error={shippingMethodError}
                helperText={
                  shippingMethodError ? "Shipping Method field is empty" : ""
                }
                required
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
        {/*Ship With Other Goods*/}
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={shipWithOtherGoods}
                onClick={() => {
                  setShipWithOtherGoods(!shipWithOtherGoods);
                  setDirty();
                }}
                disabled={
                  rolePermission && shippingMethod === "Local pickup"
                    ? true
                    : false
                }
              />
            }
            label="Ship With Other Goods"
          />
        </Grid>
        {orderType === "Project Pack for Home" ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h6" component="span">
                Special Options
              </Typography>
              <Underline />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      // width: windowWidth > 700 ? "400px" : "",
                      margin: "auto 0",
                      overflow: "auto",
                    }}
                  >
                    {" "}
                    <Checkbox
                      checked={forModelHome}
                      onClick={() => {
                        setForModelHome(!forModelHome);
                        setDirty();
                      }}
                    />
                    <Typography
                      variant="h6"
                      component="span"
                      style={{ fontWeight: 100 }}
                    >
                      Is this order for a Model Home?
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      // width: windowWidth > 700 ? "400px" : "",
                      margin: "auto 0",
                      overflow: "auto",
                    }}
                  >
                    {" "}
                    <Checkbox
                      checked={specialNegotiated}
                      onClick={() => {
                        setSpecialNegotiated(!specialNegotiated);
                        setDirty();
                      }}
                    />
                    <Typography
                      variant="h6"
                      component="span"
                      style={{
                        fontWeight: 100,
                      }}
                    >
                      Apply special negotiatied Project Price?
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
                {canCreateCompPP === true ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        // width: windowWidth > 700 ? "400px" : "",
                        margin: "auto 0",
                        overflow: "auto",
                      }}
                    >
                      <Checkbox
                        checked={isCompPP}
                        onClick={() => {
                          setIsCompPP(!isCompPP);
                          setDirty();
                        }}
                      />
                      <Typography
                        variant="h6"
                        component="span"
                        style={{
                          fontWeight: 100,
                        }}
                      >
                        Is this a complementary Quick Kit?
                      </Typography>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>{" "}
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
          </>
        ) : null}
        {/*Other Orders*/}
        {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Autocomplete
            multiple
            id="size-small-outlined-multi"
            size="small"
            options={[]}
            getOptionLabel={(option) => option.status}
            value={otherOrder ? otherOrder : []}
            sx={{ maxWidth: "100%" }}
            onChange={(event, value) => {
              setOtherOrder(value);
              setDirty();
              if (!otherOrderError) setOtherOrderError(true);
            }}
            disabled={create || adminManage ? false : true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ship With Other Orders?"
                fullWidth
                type="text"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid> */}
      </Grid>
      {shipModalOpen ? (
        <CustomModal openModal={shipModalOpen} width="70vw">
          <ShippingAddressForm
            companyID={company?.companyID}
            fromOrder={true}
            orderID={orderID}
            fromCreateOrder={true}
            setFormChanged={setFormChanged}
            setShipModalOpen={setShipModalOpen}
            setShippingAddressIDs={setShippingAddressIDs}
            fetchShippingAddressesForOrder={fetchShippingAddresses}
            setShipToAddress={setShipToAddress}
            setToUpdateShipAddID={setToUpdateShipAddID}
            companyName={company ? company.name : ""}
          />
        </CustomModal>
      ) : null}
      {shipModalUpdatelOpen ? (
        <CustomModal openModal={shipModalUpdatelOpen} width="70vw">
          <ShippingAddressForm
            create={false}
            companyID={company?.companyID}
            fromOrder={true}
            setFormChanged={setFormChanged}
            setShipModalUpdateOpen={setShipModalUpdateOpen}
            fetchShippingAddressesForOrder={fetchShippingAddresses}
            shippingAddressID={toUpdateShipAddID}
            setShipToAddress={setShipToAddress}
            companyName={company ? company.name : ""}
          />
        </CustomModal>
      ) : null}
    </div>
  );
};

export default PurchasingDetailStep;
