import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import { accountServices } from "../../services/account.services";
import { useSelector } from "react-redux";
import SnackBar from "../SnackBar/SnackBar";
import AlertSnackBar from "../AlertSnackBar/AlertSnackBar";
import { adminServices } from "../../services/admin.services";

const ChangeUserPasswordForm = ({ setFormChanged, userNameInTabs = "" }) => {
  //Component for admin to change a certain User's password

  //For button loading
  const [loading, setLoading] = useState(false);

  //holds new and confirm password
  const [newPass, setNewPass] = useState("");
  const [confNewPass, setConfNewPass] = useState("");

  //holds if there is an error in the new or confirm new password
  const [newPassError, setNewPassError] = useState(false);
  const [confNewPassError, setConfNewPassError] = useState(false);

  //holds error if any from the backend
  const [saveError, setSaveError] = useState("");

  //displays snackbar on success
  const [openSnackBar, setOpenSnackBar] = useState(false);

  /*Get user ID from Redux*/
  const userID = useSelector((state) =>
    state.user ? state.user.user.user.userID : ""
  );

  /*Handle closing snackBar*/
  const handleSnackBarClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  /*Handle Close Alert Snackbar*/
  const handleCloseAlertSnackbar = (event, reason) => {
    setSaveError("");
  };

  /*Calling the reset password API*/
  const resetPassword = (event) => {
    setLoading(true);
    event.preventDefault();

    //if no error, save else display that there is an error on the field (field is empty)
    if (newPass === "" || confNewPass === "") {
      if (newPass === "") {
        setNewPassError(true);
      }

      if (confNewPass === "") {
        setConfNewPassError(true);
      }
      setLoading(false);
    } else {
      const data = {
        AdminID: userID,
        Email: userNameInTabs,
        Password: newPass,
        ConfirmPassword: confNewPass,
      };
      adminServices
        .changeUserPassword(data)
        .then((response) => {
          setLoading(false);
          sessionStorage.removeItem("unsavedChanges");

          setSaveError("");
          setNewPass("");
          setConfNewPass("");
          setOpenSnackBar(true);
        })
        .catch((error) => {
          setSaveError(error.response.data.error.error);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <form onSubmit={resetPassword}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h6">Change Your Password</Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={userNameInTabs}
              inputProps={{
                autoComplete: "new-password",
              }}
              disabled={true}
              label="Username"
              size="small"
              fullWidth
              required
              type="text"
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={newPass}
              autoComplete="off"
              inputProps={{
                autoComplete: "new-password",
              }}
              onChange={(e) => {
                setFormChanged(true);
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                setNewPass(e.target.value);
                if (newPassError) {
                  setNewPassError(false);
                }
              }}
              label="New Password"
              size="small"
              error={newPassError}
              fullWidth
              helperText={newPassError ? "This field is required" : null}
              required
              type="password"
              disabled={loading ? true : false}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              value={confNewPass}
              onChange={(e) => {
                setConfNewPass(e.target.value);
                setFormChanged(true);
                sessionStorage.setItem(
                  "unsavedChanges",
                  JSON.stringify(true)
                );
                if (confNewPassError) {
                  setConfNewPassError(false);
                }
              }}
              label="Confirm New Password"
              size="small"
              autoComplete="off"
              fullWidth
              error={confNewPassError}
              helperText={confNewPassError ? "This field is required" : null}
              inputProps={{
                autoComplete: "new-password",
              }}
              required
              type="password"
              disabled={loading ? true : false}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="left">
            <Typography variant="caption" component="span">
              <span style={{ whiteSpace: "pre-line" }}>
                <strong> Password requirements:</strong> {"\n"} - Minimum 6
                characters
                {"\n"} - At least 1 uppercase and 1 lowercase letter {"\n"} - At
                least 1 number and 1 symbol ($&?) character
              </span>
            </Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton type="submit" loading={loading}>
              Save
            </CustomButton>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}></Grid>
        </Grid>
      </form>
      {openSnackBar ? (
        <SnackBar
          open={openSnackBar}
          message="Saved Successfully"
          severity="success"
          handleClose={handleSnackBarClose}
        />
      ) : null}
      {saveError !== "" ? (
        <AlertSnackBar
          message={saveError}
          handleClose={handleCloseAlertSnackbar}
          open={saveError !== ""}
        />
      ) : null}
    </div>
  );
};

export default ChangeUserPasswordForm;
